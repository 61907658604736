import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import DialogActionLoadingIndicator from "../../components/DialogActionLoadingIndicator";
import NumberEditor from "../../components/NumberEditor";
import ProductAutoComplete from "../common/ProductAutoComplete";
import { openSnackbar } from "../site/siteSlice";
import { loyaltyProgramDefaultValue, CouponProgramReduxState } from "./loyaltyProgramSlice";
import DiscountValueEditor, { DiscountValue } from "./DiscountValueEditor";
import { promotionActions } from "./promotionsSlice";
import { DiscountMode, RewardType } from "../../types/global-types";

export type CouponProgramDialogProps = {
  open: boolean;
  onClose: () => void;
  index: number;
};

const _sx = {
  content: {
    height: "auto",
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    borderBottomWidth: 1,
    borderBottomColor: "#fff",
    borderBottomStyle: "solid",
  },
  formControl: {
    marginTop: 0.5,
    marginBottom: 0.5,
  },
};

function CouponProgramDialog({
  open,
  onClose,
  index
}: CouponProgramDialogProps) {
  const productAutoCompleteRef = React.useRef<HTMLInputElement | null>(null);
  const loyaltyProgram = useSelector((state: RootState) => state.promotion.loyalty_programs[index]);
  const [draft, setDraft] = React.useState<CouponProgramReduxState>(loyaltyProgram ?? { ...loyaltyProgramDefaultValue });
  React.useEffect(() => {
    const newDraft = loyaltyProgram ? loyaltyProgram : { ...loyaltyProgramDefaultValue };
    setDraft(newDraft);
  }, [loyaltyProgram]);
  const [discount, setDiscount] = React.useState<DiscountValue | undefined>({ discount_mode: draft.discount_mode ?? DiscountMode.per_point, discount: draft.discount });
  React.useEffect(() => {
    const { discount, discount_mode } = draft;
    setDiscount({ discount, discount_mode: discount_mode ?? DiscountMode.per_point });
  }, [draft]);

  const selectedProductItem = React.useMemo(() => {
    if (!draft?.product_id)
      return null;
    const displayName = draft?.product_id?.display_name;
    const defaultCode = displayName
      ? displayName.substring(
        displayName.indexOf("[") + 1,
        displayName.indexOf("]")
      )
      : "";
    return {
      id: draft?.product_id?.id,
      default_code: defaultCode,
      name: draft?.product_id?.display_name?.split(
        "] "
      )[1],
    };
  }, [draft]);
  const selectedRewardProductItem = React.useMemo(() => {
    if (!draft?.reward_product_id)
      return null;
    const displayName = draft?.reward_product_id?.display_name;
    const defaultCode = displayName
      ? displayName.substring(
        displayName.indexOf("[") + 1,
        displayName.indexOf("]")
      )
      : "";
    return {
      id: draft?.reward_product_id?.id,
      default_code: defaultCode,
      name: draft?.reward_product_id?.display_name?.split(
        "] "
      )[1],
    };
  }, [draft]);
  const [product, setProduct] = React.useState(selectedProductItem);
  React.useEffect(() => {
    setProduct(selectedProductItem);
  }, [selectedProductItem]);
  const [rewardProduct, setRewardProduct] = React.useState(selectedRewardProductItem);
  React.useEffect(() => {
    setRewardProduct(selectedRewardProductItem);
  }, [selectedRewardProductItem]);
  const dispatch = useDispatch();

  const validationResult = React.useMemo(() => {
    const errors: { [key: string]: string } = {};
    const { minimum_qty, discount_max_amount,reward_type } = draft;
    let isValid = true;
    if (!product) {
      errors.product_id = "Choose product";
      isValid = false;
    }
    if (minimum_qty < 1) {
      errors.minimum_qty = "Min qty must be at least 1.";
      isValid = false;
    }

    switch(reward_type){
      case RewardType.discount:
        if (discount_max_amount < 0) {
          errors.discount_max_amount = "Max discount must be at least 0.";
          isValid = false;
        }    
        if (!discount) {
          errors.discount_value = "Discount value must be set.";
          isValid = false;
        }
        else if (discount.discount <= 0) {
          errors.discount_value = "Discount value must be greater than 0.";
          isValid = false;
        } else if (!discount.discount_mode) {
          errors.discount_value = "Discount value must be set.";
          isValid = false;
        }
        break;
      case RewardType.product:
        if (!rewardProduct) {
          errors.reward_product_id = "Choose free product";
          isValid = false;
        }
        break;
    }    
    return { isValid, errors };
  }, [discount, rewardProduct, draft, product]);

  const handleSubmit = React.useCallback(() => {
    const { isValid } = validationResult;
    if (!isValid)
      return;
    const { minimum_qty, discount_max_amount, active,reward_type } = draft;
    dispatch(
      promotionActions.editCouponProgram({
        program: {
          reward_type,
          reward_product: rewardProduct as any,
          product: product as any,
          discount: discount?.discount,
          minimum_qty,
          discount_max_amount,
          discount_mode: discount?.discount_mode,
          active,
        },
        index,
      })
    );
    if (index >= 0)
      dispatch(
        openSnackbar({ message: `${product?.default_code} updated.`, open: true })
      );
    else {
      dispatch(
        openSnackbar({ message: `${product?.default_code} added.`, open: true })
      );
    }
    if (index === -1) {
      setDraft({ ...loyaltyProgramDefaultValue });
      productAutoCompleteRef.current?.focus();
    } else {
      onClose();
    }
  }, [discount, dispatch, draft, index, onClose, product, validationResult]);

  const handleCommandKeyUp: React.KeyboardEventHandler<HTMLElement> =
    React.useCallback(
      (e) => {
        switch (e.key) {
          case "F1":
          case "F2":
          case "F9":
            e.stopPropagation();
            break;
          case "Escape":
            e.stopPropagation();
            onClose();
            break;
          case "Enter":
            handleSubmit();
            e.stopPropagation();
            break;
        }
      },
      [onClose, handleSubmit]
    );

  return (
    <Dialog open={open} onKeyUp={handleCommandKeyUp} fullWidth maxWidth="sm">
      <DialogTitle>Add Product</DialogTitle>
      <DialogContent sx={_sx.content}>
        <Box flex={1}>
          <Stack maxWidth="sm" spacing={2} marginTop={2}>
            <Grid item container spacing={2}>
              <Grid item xs={12}>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="discount"
                  value={draft.reward_type}
                  name="reward_typeradio-buttons-group"
                  onChange={(e) => { setDraft({ ...draft, reward_type: e.target.value as RewardType }) }}
                >
                  <FormControlLabel value="discount" control={<Radio />} label="Discount" />
                  <FormControlLabel value="product" control={<Radio />} label="Free Product" />
                </RadioGroup>
              </Grid>
              <Grid item xs={12} md={12}>
                <ProductAutoComplete
                  ref={productAutoCompleteRef}
                  autoFocus
                  label="Product"
                  placeholder="Search product"
                  size="small"
                  fullWidth
                  value={product as any}
                  onSelected={setProduct as any}
                  error={!!validationResult.errors.product_id}
                  helperText={validationResult.errors.product_id}
                />
              </Grid>
              {
                draft.reward_type == RewardType.product ? <Grid item xs={12} md={12}>
                  <ProductAutoComplete
                    ref={productAutoCompleteRef}
                    autoFocus
                    label="Free product"
                    placeholder="Search product"
                    size="small"
                    fullWidth
                    value={rewardProduct as any}
                    onSelected={setRewardProduct as any}
                    error={!!validationResult.errors.reward_product_id}
                    helperText={validationResult.errors.reward_product_id}
                  />
                </Grid> : <><Grid item xs={12} md={3}>
                  <DiscountValueEditor
                    label="Discount"
                    placeholder="Discount"
                    value={discount}
                    onValidated={setDiscount}
                    size="small"
                    fullWidth
                    sx={{ mt: 0.5 }}
                    error={!!validationResult.errors.discount_value}
                    helperText={validationResult.errors.discount_value}
                  />
                </Grid>
                  <Grid item xs={12} md={3}>
                    <NumberEditor
                      label="Min Qty"
                      sx={_sx.formControl}
                      value={draft.minimum_qty}
                      numberPrecision={0}
                      size="small"
                      fullWidth
                      inputElementType="TextField"
                      onValidated={(value) => { setDraft({ ...draft, minimum_qty: value ?? 1 }) }}
                      error={!!validationResult.errors.minimum_qty}
                      helperText={validationResult.errors.minimum_qty}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <NumberEditor
                      label="Max Discount"
                      sx={_sx.formControl}
                      value={draft.discount_max_amount}
                      numberPrecision={0}
                      size="small"
                      fullWidth
                      inputElementType="TextField"
                      onValidated={(value) => { setDraft({ ...draft, discount_max_amount: value ?? 0 }) }}
                      error={!!validationResult.errors.discount_max_amount}
                      helperText={validationResult.errors.discount_max_amount}
                    />
                  </Grid></>
              }
            </Grid>
          </Stack>
        </Box>
      </DialogContent>
      <DialogActions sx={{ display: "flex" }}>
        <DialogActionLoadingIndicator loading={false} text={"Loading..."} />
        <Box>
          <Checkbox
            color="primary"
            checked={draft.active}
            onChange={(e, checked) => {
              setDraft({ ...draft, active: checked });
            }}
          />
        </Box>
        <Box>
          <Button
            disabled={!product}
            color="secondary"
            onClick={() => {
              handleSubmit();
            }}
          >
            OK
          </Button>
          <Button onClick={onClose}>Close</Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}

export default CouponProgramDialog;
