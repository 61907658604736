import { useQuery } from "@apollo/client";
import ListItemText from "@mui/material/ListItemText/ListItemText";
import TextField, { TextFieldProps } from "@mui/material/TextField/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete/Autocomplete";
import * as React from "react";
import { townshipsQuery } from "./graphql";
import {
  Townships,
  TownshipsVariables,
  Townships_regionQuery_townships,
} from "./__generated__/Townships";
import ListItem from "@mui/material/ListItem";

export type TownshipAutoCompleteProps = {
  selectedTownship: Townships_regionQuery_townships | null;
  onSelected: (township: Townships_regionQuery_townships|null) => void;
  className?: string;
  disabled?:boolean;
}& Pick<TextFieldProps, "size" | "label" | "sx">;

const filterOptions = createFilterOptions({
  stringify: (option: Townships_regionQuery_townships) => `${option.name_mm} ${option.name_en}`,
});
function TownshipAutoComplete({
  selectedTownship,
  onSelected,
  className,
  disabled,
  size,
  label,
  sx
}: TownshipAutoCompleteProps) {
  const { data, loading } =
    useQuery<Townships, TownshipsVariables>(townshipsQuery);
  return (
    <Autocomplete
      disabled={disabled}
      value={selectedTownship}
      onChange={(_, value: Townships_regionQuery_townships|null) => {
        onSelected(value);
      }}
      filterOptions={filterOptions}
      includeInputInList
      clearOnBlur
      selectOnFocus
      isOptionEqualToValue={(option,value)=> option.id===value.id}
      loading={loading}
      getOptionLabel={(option) => `${option.name_mm} ${option.name_en}`}
      renderOption={(props,option)=><ListItem {...props}><ListItemText primary={option.name_mm} secondary={option.name_en}/></ListItem>}
      options={data?.regionQuery?.townships??(selectedTownship?[selectedTownship]:[])}
      renderInput={(params) => (
        <TextField
          {...params}
          autoComplete="off"
          placeholder="Township"
          label={label??"Township"}
          size={size}
          sx={sx}
          className={className}
        />
      )}
    />
  );
}

export default TownshipAutoComplete;
