import Button, { ButtonProps } from "@mui/material/Button/Button";
import * as React from "react";

function ConfirmButton({ children, onClick, ...props }: ButtonProps) {
    const [count, setCount] = React.useState(3);
    const timeOut = React.useRef<ReturnType<typeof setTimeout> | null>(null);
    const handleOnClick = React.useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (timeOut.current != null) {
            clearTimeout(timeOut.current);
            timeOut.current = null;
        }
        if (count === 1) {
            if (onClick)
                onClick(e);
            setCount(3);
            return;
        }
        setCount(count - 1);
        timeOut.current = setTimeout(() => {
            setCount(3);
        }, 5000);
    }, [count, onClick]);
    return <Button onClick={handleOnClick} {...props}>{children} {`(${count})`}</Button>;
}

export default ConfirmButton;