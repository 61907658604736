import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Optional } from "utility-types";
import {  Employees_partnerQuery_employees_edges } from "../common/__generated__/Employees";
import { ProductItems_productQuery_productItems_edges } from "../common/__generated__/ProductItems";
import { SaleOrder_saleOrderQuery_saleOrder_order_lines } from "./__generated__/SaleOrder";

export const saleOrderLineDefaultValue: Optional<SaleOrder_saleOrderQuery_saleOrder_order_lines,"product_id" | "saleperson_id" | "product_uom"> =
  {
    id: "",
    name: "",
    price_unit: 0,
    product_uom_qty: 1,
    qty_delivered: 0,
    qty_invoiced: 0,
    discount: 0,
    price_subtotal: 0,
  };

export type SaleOrderLinePayload = {
  product: ProductItems_productQuery_productItems_edges;
  saleperson_id?: Employees_partnerQuery_employees_edges;
  qty: number;
};

export type SaleOrderLineUpdateQtyPayload = {
  index: number;
  qty: number;
};

export type SaleOrderLineUpdatePricePayload = {
  index: number;
  price: number;
};

export type SaleOrderLineUpdateSalepersonPayload = {
  index: number;
  saleperson_id?:Employees_partnerQuery_employees_edges;
};

export const saleOrderLineSlice = createSlice({
  name: "saleOrderLine",
  initialState: saleOrderLineDefaultValue,
  reducers: {
    editOrderLinePrice: (
      state,
      action: PayloadAction<SaleOrderLineUpdatePricePayload>
    ) => {
      state.price_unit = action.payload.price;
    },
    editOrderLineQty: (
      state,
      action: PayloadAction<SaleOrderLineUpdateQtyPayload>
    ) => {
      state.product_uom_qty = action.payload.qty;
    },
    editOrderLineSaleperson: (
      state,
      action: PayloadAction<SaleOrderLineUpdateSalepersonPayload>
    ) => {
      state.saleperson_id = action.payload.saleperson_id? {id:action.payload.saleperson_id.id, display_name:action.payload.saleperson_id.name}:null;
    },
    addOrderLine: (state, action: PayloadAction<SaleOrderLinePayload>) => {
      const { id, default_code, name, list_price, uom_id, uom } =
        action.payload.product;
      const display_name = `[${default_code}]${name}`;
      state.name = display_name;
      state.price_unit = list_price ?? 0;
      state.product_id = { id: id?.toString()!, display_name };
      state.product_uom = { id: uom_id?.toString()!, display_name: uom };
      state.product_uom_qty = action.payload.qty;
      state.saleperson_id = action.payload.saleperson_id? {id:action.payload.saleperson_id.id, display_name:action.payload.saleperson_id.name}:null;
      state.price_subtotal = state.price_unit * state.product_uom_qty;
    },
  },
});

export const {  editOrderLinePrice,editOrderLineQty, addOrderLine, editOrderLineSaleperson} = saleOrderLineSlice.actions;
export default saleOrderLineSlice.reducer;
