import * as React from "react";
import update from "immutability-helper";
import TextEditor from "./TextEditor";
import Box from '@mui/material/Box';
import Chip from "@mui/material/Chip/Chip";

interface Props {
  value: ReadonlyArray<string>;
  onChanged?: (value: ReadonlyArray<string>) => void;
  allowDuplicate?: boolean;
}

const _sx = {
  root: {
    borderColor: "divider",
    borderWidth: 1,
    borderStyle: "solid",
    padding: 0.5
  },
  chip: {
    margin: 0.5
  },
  textEditor: {
    width: 150,
    paddingLeft: 0.5,
    paddingRight: 0.5
  },
  alignLeft: {
    textAlign: "left"
  }
};

type State = {
  editingText?: string;
};

class TextListEditor extends React.Component<Props, State> {
  inputEl?: HTMLInputElement;
  state: State = {
    editingText: undefined
  };
  static defaultProps: Props = {
    value: []
  };
  onDelete(index: number) {
    const { onChanged, value } = this.props;
    const newValue = update(value, {
      $splice: [[index, 1]]
    });
    if (onChanged)
      onChanged(newValue);
  }
  addItem(v: string) {
    const { value, onChanged } = this.props;
    const newValue = update(value, {
      $push: [v]
    });
    if (onChanged)
      onChanged(newValue);
  }
  onKeyDown: React.KeyboardEventHandler<HTMLDivElement> = (e) => {
    const { editingText } = this.state;
    const { value } = this.props;
    switch (e.key) {
      case "backspace":
        if (!editingText && value.length > 0) {
          this.onDelete(value.length - 1);
        }
    }
  };
  render() {
    const { value, allowDuplicate } = this.props;
    const { editingText } = this.state;
    return (
      <Box
        sx={_sx.root}
        onClick={() => {
          this.inputEl?.focus();
        }}
      >
        {value.map((v, index) => (
          <Chip
            sx={_sx.chip}
            key={allowDuplicate ? index.toString() : v.toString()}
            label={v.toString()}
            onDelete={() => {
              this.onDelete(index);
            }}
          />
        ))}
        <TextEditor
          value={editingText}
          sx={_sx.textEditor}
          inputElementType="InputBase"
          placeholder="Enter text"
          inputReference={(input: any) => {
            this.inputEl = input;
          }}
          onValidating={(v: string) => {
            if (!v) return "Invalid value";
            if (!allowDuplicate) {
              return value.indexOf(v) === -1 ? "": "Duplicate value";
            } else return "";
          }}
          onChange={(e) => {
            this.setState({ editingText: e.target.value });
          }}
          onValidated={(v: string) => {
            this.addItem(v);
          }}
          onKeyDown={this.onKeyDown.bind(this)}
          validateOnBlur={false}
        />
      </Box>
    );
  }
}
export default TextListEditor;
