import AppBar from "@mui/material/AppBar/AppBar";
import Toolbar from "@mui/material/Toolbar/Toolbar";
import * as React from "react";
import update from "immutability-helper";
import Box from "@mui/material/Box/Box";
import ApolloVirtualizedTable, {
    GridColumn,
    DefaultPageInfoValue,
    ApolloVirtualizedTableProps,
} from "mui-apollo-virtualized-table";
import { ApolloConsumer } from "@apollo/client/react/context/ApolloConsumer";
import { gpDetailReportQuery } from "../graphql";
import format from "date-fns/esm/fp/format/index.js";
import accounting from "accounting";
import Tabs from "@mui/material/Tabs/Tabs";
import Tab from "@mui/material/Tab/Tab";
import Summary from "./Summary";
import { GpReport, GpReportVariables, GpReport_saleReportQuery_gpDetails_edges } from "../__generated__/GpReport";
import startOfMonth from "date-fns/startOfMonth";
import { endOfMonth } from "date-fns/esm";
import Tooltip from "@mui/material/Tooltip/Tooltip";
import IconButton from "@mui/material/IconButton/IconButton";
import Download from "@mui/icons-material/CloudDownload";
import { useQuery } from "@apollo/client/react/hooks/useQuery";
import { GpReportOrderByInput } from "../../../types/global-types";
import CsDateRangeEditor, { DateRangeWithLabel } from "../../../components/CsDateRangeEditor";
import FileDownloader, { FileDownloaderHandle } from "../../../components/FileDownloader";
import DrawerToggleButton from "../../../app/DrawerToggleButton";
import CsSearchBox, { OperandType } from "../../../components/CsSearchBox";
import Sidebar from "./Sidebar";


const GpVirtualizedGrid = ApolloVirtualizedTable as React.ElementType<ApolloVirtualizedTableProps<GpReport_saleReportQuery_gpDetails_edges>>;

const _sx = {
    title: {
        marginRight: 1,
    },
    box: {
        backgroundColor: "rgba(255,255,255,.1)",
        padding: 1
    },
    root: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        flexWrap: "nowrap"
    },
};

function RptGp() {
    const [variables, setVariables] = React.useState<GpReportVariables>({
        pagination: {
            pageSize: 40,
        },
        orderBy: [GpReportOrderByInput.date],
        start_date: format("yyyy-MM-dd", startOfMonth(new Date())),
        end_date: format("yyyy-MM-dd", endOfMonth(new Date()))
    });

    const [columns, setColumns] = React.useState<
        ReadonlyArray<GridColumn<GpReport_saleReportQuery_gpDetails_edges>>
    >([
        {
            label: "Date",
            key: "date_order",
            width: 150,
            format: ({ rowData }) => format("dd/MM/yyyy", new Date(rowData.date)),
            sortable: true,
            sortOrder: 1,
            sortDirection: "ASC",
            hideAt: 1024
        },
        {
            label: "#Ref",
            key: "ref",
            width: 100,
            sortable: true,
        },
        {
            label: "#Model",
            key: "default_code",
            width: 200,
            sortable: true,
        },
        {
            label: "Description",
            key: "product_name",
            width: 200,
            flexGrow: 1,
            sortable: true,
            hideAt: 1024
        },
        {
            label: "Uom",
            key: "uom",
            width: 100,
            sortable: true,
            hideAt: 1280
        },
        {
            label: "Qty",
            key: "quantity",
            width: 100,
            textAlign: "right",
            sortable: true,
            format: ({ rowData }) => rowData?.quantity ? accounting.formatNumber(rowData.quantity, 0) : ""
        },
        {
            label: "Sale",
            key: "price_total",
            width: 100,
            textAlign: "right",
            sortable: true,
            format: ({ rowData }) => rowData.price_total ? accounting.formatNumber(rowData.price_total, 0) : ""
        },
        {
            label: "Delivered",
            key: "product_uom_qty",
            width: 100,
            textAlign: "right",
            sortable: true,
            format: ({ rowData }) => rowData.product_uom_qty ? accounting.formatNumber(rowData.product_uom_qty, 0) : ""
        },
        {
            label: "COGS",
            key: "value",
            width: 100,
            textAlign: "right",
            sortable: true,
            format: ({ rowData }) => rowData.value === 0 ? 0 : (rowData.value ? accounting.formatNumber(rowData.value, 0) : "")
        },
        {
            label: "GP",
            key: "gp",
            width: 100,
            textAlign: "right",
            sortable: true,
            format: ({ rowData }) => rowData.gp ? accounting.formatNumber(rowData.gp, 0) : ""
        },
    ]);
    const {
        data: gpDetailQueryResult
    } = useQuery<GpReport, GpReportVariables>(gpDetailReportQuery, { variables, fetchPolicy: "cache-only" });

    const [selected, setSelected] = React.useState<ReadonlyArray<number>>([]);
    const handleOnRowClick = React.useCallback((_: any, index: number) => {
        setSelected([index]);
    }, [setSelected]);
    const [selectedTab, setSelectedTab] = React.useState("summary");
    const handleSelectedTabChanged = React.useCallback((_: any, value: string) => {
        setSelectedTab(value);
    }, [setSelectedTab]);

    const handleColumnsPropsChanged = React.useCallback((columns: ReadonlyArray<GridColumn<GpReport_saleReportQuery_gpDetails_edges>>, orderBy: string[]) => {
        setColumns(columns);
        setVariables({ ...variables, orderBy: orderBy as any });
    }, [setVariables, setColumns, variables]);

    const [dateRange, setDateRange] = React.useState<DateRangeWithLabel>(new DateRangeWithLabel({ from: startOfMonth(new Date()), to: endOfMonth(new Date()) }, "This Month"));

    const handleChangeDateRange = React.useCallback((value: DateRangeWithLabel) => {
        setDateRange(value);
        setVariables({ ...variables, start_date: format("yyyy-MM-dd", value.value.from), end_date: format("yyyy-MM-dd", value.value.to) });
    }, [variables, setVariables]);
    const handleSearchConditionChanged = React.useCallback((conditions: any) => {
        setVariables(
            update(variables, {
                where: {
                    $set: { ...variables.where, aND: conditions },
                }
            })
        );
    }, [variables, setVariables]);
    const handleCloseSidebar = React.useCallback(() => {
        setSelected([]);
    }, [setSelected]);
    const fileDownloader = React.useRef<FileDownloaderHandle>(null);

    const selectedGpLine = React.useMemo(() => {
        const [index] = selected?.length ? selected : [-1];
        const pgDetails = gpDetailQueryResult?.saleReportQuery?.gpDetails?.edges
        return pgDetails?.length ? pgDetails[index] : null;
    }, [gpDetailQueryResult, selected]);

    const handleExcelFileDownload = React.useCallback(() => {
        fileDownloader?.current?.download();
    }, []);
    return (
        <>
            <Box sx={_sx.root}>
                <AppBar position="static" >
                    <Toolbar sx={(theme) => ({
                        [theme.breakpoints.up("md")]: {
                            minHeight: theme.spacing(6),
                        },
                        paddingLeft: 1.5,
                    })}>
                        <DrawerToggleButton />
                        <CsSearchBox
                            onConditionChanged={handleSearchConditionChanged}
                            operands={{
                                ref: {
                                    name: "#Order",
                                    propName: "ref",
                                    type: OperandType.STRING,
                                },
                                default_code: {
                                    name: "#Model",
                                    propName: "default_code",
                                    type: OperandType.STRING,
                                },
                                product_name: {
                                    name: "Description",
                                    propName: "product_name",
                                    type: OperandType.STRING,
                                },
                                account_name: {
                                    name: "Account",
                                    propName: "account_name",
                                    type: OperandType.STRING,
                                },
                                salezone: {
                                    name: "Sale Zone",
                                    propName: "salezone",
                                    type: OperandType.STRING,
                                },
                                brand: {
                                    name: "Brand",
                                    propName: "brand",
                                    type: OperandType.STRING,
                                },
                                vendor_name: {
                                    name: "Supplier",
                                    propName: "vendor_name",
                                    type: OperandType.STRING
                                },
                                vendor_category: {
                                    name: "Supplier Category",
                                    propName: "vendor_category",
                                    type: OperandType.STRING
                                },
                                quantity: {
                                    name: "Qty",
                                    propName: "quantity",
                                    type: OperandType.NUMBER
                                },
                                price_total: {
                                    name: "Sale",
                                    propName: "price_total",
                                    type: OperandType.NUMBER
                                },
                                product_uom_qty: {
                                    name: "Delivered",
                                    propName: "product_uom_qty",
                                    type: OperandType.NUMBER
                                },
                                value: {
                                    name: "COGS",
                                    propName: "value",
                                    type: OperandType.NUMBER
                                },
                                gp: {
                                    name: "GP",
                                    propName: "gp",
                                    type: OperandType.NUMBER
                                }
                            }}
                        />

                        <CsDateRangeEditor
                            sx={{
                                marginLeft: 1,
                                '& .MuiOutlinedInput-notchedOutline': {
                                    borderColor: "rgb(192, 192, 192)"
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: "#fff"
                                },
                                '& .MuiOutlinedInput-input': {
                                    color: "#fff"
                                },
                                '& button': {
                                    color: "#fff"
                                }
                            }}
                            size="small"
                            value={dateRange}
                            enableTime={false}
                            error={!dateRange}
                            onChange={handleChangeDateRange}
                        />
                        <FileDownloader ref={fileDownloader} url={`/excel/gp/${variables.start_date}/${variables.end_date}`} json={JSON.stringify(variables?.where)}>
                            <Tooltip title="Download Excel">
                                <IconButton
                                    color="inherit"
                                    onClick={handleExcelFileDownload}
                                >
                                    <Download />
                                </IconButton>
                            </Tooltip>
                        </FileDownloader>
                    </Toolbar>
                </AppBar>
                <AppBar position="static" color="default">
                    <Tabs value={selectedTab} onChange={handleSelectedTabChanged} aria-label="Sale Invoices">
                        <Tab label="Summary" value="summary" />
                        <Tab label="Details" value="details" />
                    </Tabs>
                </AppBar>
                {
                    selectedTab === "summary" ? <Summary variables={variables} /> : null
                }
                {
                    selectedTab === "details" ? <Box
                        sx={_sx.box}
                        flex={1}
                        display="flex"
                        flexDirection="row"
                    >
                        <ApolloConsumer>
                            {(client) => (
                                <GpVirtualizedGrid
                                    onColumnPropsChanged={handleColumnsPropsChanged}
                                    apolloClient={client as any}
                                    listItemHeight={72}
                                    listModeBreakPoint={0}
                                    columns={columns}
                                    graphqlQuery={gpDetailReportQuery}
                                    selectedItems={selected}
                                    onRowClick={handleOnRowClick}
                                    setSelectedItems={setSelected}
                                    variables={variables}
                                    pageSize={variables?.pagination?.pageSize!}
                                    parseListFromQueryResult={(queryResult: GpReport) => {
                                        const list = queryResult?.saleReportQuery?.gpDetails ?? {
                                            edges: [],
                                            pageInfo: DefaultPageInfoValue,
                                        };
                                        return list;
                                    }}
                                    onLoadMore={(pageInfo) => {
                                        return {
                                            ...variables,
                                            pagination: {
                                                page: pageInfo.page,
                                                pageSize: pageInfo.pageSize,
                                            },
                                        };
                                    }}
                                />
                            )}
                        </ApolloConsumer>
                        {
                            selectedGpLine ? <Sidebar closeSidebar={handleCloseSidebar} product_id={selectedGpLine.product_id!} order_line_id={Number.parseInt(selectedGpLine?.id ?? "0")} /> : null
                        }
                    </Box> : null
                }
            </Box>
        </>
    );
}

export default RptGp;