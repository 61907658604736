import { useMutation } from "@apollo/client/react/hooks/useMutation";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import * as React from "react";
import DialogActionLoadingIndicator from "../../components/DialogActionLoadingIndicator";
import { changePasswordMutation } from "./graphql";
import { ChangePassword, ChangePasswordVariables } from "./__generated__/ChangePassword";

export type SetPasswordDialogProps = {
    open:boolean,
    onClose:()=>void;
    userId:string;
};
function SetPasswordDialog({open,onClose,userId}:SetPasswordDialogProps){
    const [password,setPassword] = React.useState("");
    const [currentPassword, setCurrentPassword]  = React.useState("");

    const [changePassword, { loading }] = useMutation<ChangePassword, ChangePasswordVariables>(changePasswordMutation);
    const performChangePassword = React.useCallback(async ()=>{
        await changePassword({variables:{id:userId,newPassword:password,loginPassword:currentPassword}});
        onClose();
    },[changePassword, userId, password, currentPassword, onClose]);
    return (<Dialog open={open} onClose={onClose}>
        <DialogTitle>Set Password</DialogTitle>
        <DialogContent>
            <TextField
                type="password"
                margin="dense"
                name="Password"
                label="Password"
                value={password}
                onChange={(e) => {
                    setPassword(e.target.value);
                }}
                fullWidth
            />
            <TextField
            type="password"
                margin="dense"
                name="currentPassword"
                label="Current User Password"
                value={currentPassword}
                onChange={(e) => {
                    setCurrentPassword(e.target.value);
                }}
                fullWidth
            />
        </DialogContent>
        <DialogActions>
            <DialogActionLoadingIndicator loading={loading} text="Changing password..." />
            <Button onClick={onClose} color="primary">
                Cancel
            </Button>
            <Button onClick={performChangePassword} disabled={loading} color="primary">
                OK
            </Button>
        </DialogActions>
    </Dialog>);
}

export default SetPasswordDialog;