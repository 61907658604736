import Box from "@mui/material/Box/Box";
import * as React from "react";
import accounting from "accounting";
import SaleOrderHeader from "./SaleOrderHeader";
import PaymentDialog from "./PaymentDialog";
import CustomerDialog from "./CustomerDialog";
import { useDispatch, useSelector } from "react-redux";
import IconButton from "@mui/material/IconButton/IconButton";
import Close from "@mui/icons-material/Close";
import QtyEditor from "./QtyEditor";
import InputAdornment from "@mui/material/InputAdornment/InputAdornment";
import update from "immutability-helper";
import { CheckBoxColumnMode, VirtualizedTable } from "mui-apollo-virtualized-table";
import {
  cancelOrderMutation,
  createInvoiceMutation,
  saleOrdersQuery,
  saveSaleOrderMutation,
} from "./graphql";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  SaveSaleOrder,
  SaveSaleOrderVariables,
} from "./__generated__/SaveSaleOrder";
import {
  CreateInvoice,
  CreateInvoiceVariables,
} from "./__generated__/CreateInvoice";
import {
  SaleOrders,
  SaleOrdersVariables,
} from "./__generated__/SaleOrders";
import {
  CancelSaleOrder,
  CancelSaleOrderVariables,
} from "./__generated__/CancelSaleOrder";
import DeliveryOrderDialog from "./DeliveryOrderDialog";
import SaleOrderSummaryView from "./SaleOrderSummaryView";
import Dialog from "@mui/material/Dialog/Dialog";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import DialogActions from "@mui/material/DialogActions/DialogActions";
import Button from "@mui/material/Button/Button";
import DialogTitle from "@mui/material/DialogTitle/DialogTitle";
import TextField from "@mui/material/TextField/TextField";
import { format } from "date-fns";
import UnitPriceEditor from "./UnitPriceEditor";
import { RootState } from "../../app/store";
import { saleOrderActions, saleOrderDefaultValue, SaleOrderReduxState } from "./saleOrdersSlice";
import { SaleOrder_saleOrderQuery_saleOrder, SaleOrder_saleOrderQuery_saleOrder_order_lines } from "./__generated__/SaleOrder";
import { GridColumn } from "mui-apollo-virtualized-table";
import { AccountMoveType, InvoiceState, InvoiceStatus, PaymentStatus, SaleOrderInput, SaleOrderState } from "../../types/global-types";
import { Customers_partnerQuery_customers_edges } from "./__generated__/Customers";
import useHasPermission from "../../app/useHasPermission";
import { openSnackbar } from "../site/siteSlice";
import { useNavigate } from "react-router-dom";
import TextEditor from "../../components/TextEditor";
import ErrorMessageDialog from "../../components/ErrorMessageDialog";
import AddOrderLineDialog from "./AddOrderLineDialog";
import DialogActionLoadingIndicator from "../../components/DialogActionLoadingIndicator";
import { productItemsQuery } from "../common/graphql";
import { ProductItems, ProductItemsVariables, ProductItems_productQuery_productItems_edges } from "../common/__generated__/ProductItems";
import { Employees_partnerQuery_employees_edges } from "../common/__generated__/Employees";
import EmployeeAutoComplete from "../common/EmployeeAutoComplete";
export type SaleOrderEditControlProps = {
  loading: boolean;
  saleOrdersVariables?: SaleOrdersVariables;
};

const _sx = {
  searchBox: {
    color: "rgba(0, 0, 0, .8)",
    marginLeft: .5,
    marginRight: .5,
    backgroundColor: "#fff",
    borderRadius: 4,
    paddingLeft: 1,
    paddingRight: 1,
  },
};
function SaleOrderEditControl({
  loading,
  saleOrdersVariables,
}: SaleOrderEditControlProps) {
  const dispatch = useDispatch();
  const saleOrder = useSelector((state: RootState) => state.saleOrder);
  const saleOrderSetting = useSelector((state: RootState) => state.saleSetting);
  const defaultCustomerId = useSelector((state: RootState) => state.saleSetting?.defaultCustomer?.id);
  const deleteOrderLine = React.useCallback((index: number) => {
    dispatch(saleOrderActions.deleteSaleOrderLine(index));
  }, [dispatch]);
  const updatePrice = React.useCallback((price: number, index: number) => {
    dispatch(saleOrderActions.editOrderLinePrice({ price, index }));
  }, [dispatch]);

  const updateSaleperson = React.useCallback((saleperson: Employees_partnerQuery_employees_edges | null, index: number) => {
    dispatch(saleOrderActions.editOrderLineSaleperson({ index, saleperson_id: saleperson ?? undefined }));
  }, [dispatch])

  const navigate = useNavigate();

  const updateQty = React.useCallback((qty: number, index: number) => {
    dispatch(saleOrderActions.editOrderLineQty({ qty, index }));
  }, [dispatch]);
  const addOrderLine = React.useCallback(
    (product: ProductItems_productQuery_productItems_edges) => {
      dispatch(saleOrderActions.addOrderLine({ product, qty: 1 }));
    },
    [dispatch]
  );

  const [doDialogOpen, setDoDialogOpen] = React.useState(false);

  const setSaleOrder = React.useCallback(
    (saleOrder: Partial<SaleOrder_saleOrderQuery_saleOrder>) => {
      dispatch(saleOrderActions.setSaleOrder(saleOrder));
    },
    [dispatch]
  );
  const columns = React.useMemo<
    ReadonlyArray<GridColumn<SaleOrder_saleOrderQuery_saleOrder_order_lines>>
  >(() => {
    const columns: GridColumn<SaleOrder_saleOrderQuery_saleOrder_order_lines>[] = [
      {
        label: "#",
        key: "no",
        width: 50,
        format: ({ index }) => index + 1,
        textAlign: "right"
      },
      {
        label: "Model #",
        key: "name",
        width: 150,
        format: ({ rowData }) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const [_, code] = rowData.name.match(/\[(.*?)\]/) ?? [];
          if (!code){
            const [_,code_] = rowData.product_id?.display_name?.match(/\[(.*?)\]/) ?? [];
            return code_;    
          }
          return code;
        },
      },
      {
        label: "Description",
        key: "description",
        width: 200,
        flexGrow: 1,
        format: ({ rowData }) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const [_, code] = rowData.name.match(/\[(.*?)\]/) ?? [];
          const name = rowData.name.replace(`[${code}]`, "");
          return name;
        },
      },
      {
        label: "Price",
        key: "price_unit",
        width: 150,
        textAlign: "right",
        format: ({
          rowData,
          extraData: { orderState },
          index,
        }) => (
          <UnitPriceEditor
            roundedDecimalPlace={saleOrderSetting.roundedDecimalPlaceForPrice}
            onChanged={(price) => {
              updatePrice(price, index);
            }}
            editable={
              (!rowData.id &&
                (orderState === SaleOrderState.draft ||
                  orderState === SaleOrderState.sale ||
                  orderState === SaleOrderState.sent)) ||
              (!!rowData.id && orderState === SaleOrderState.draft)
            }
            value={rowData.price_unit}
            productId={rowData?.product_id?.id}
          />
        ),
      },
      {
        label: `Ordered(${saleOrder?.totalOrdered})`,
        key: "product_uom_qty",
        width: 100,
        textAlign: "right",
        format: ({ rowData, index, extraData: { orderState, amount_Due } }) => (
          <QtyEditor
            editable={
              (!rowData.id &&
                (orderState === SaleOrderState.draft ||
                  orderState === SaleOrderState.sale ||
                  orderState === SaleOrderState.sent)) ||
              (!!rowData.id &&
                amount_Due === 0 &&
                (orderState === SaleOrderState.draft ||
                  orderState === SaleOrderState.sale ||
                  orderState === SaleOrderState.sent))
            }
            productId={rowData?.product_id?.id}
            value={rowData.product_uom_qty}
            onChanged={(qty) => {
              updateQty(qty, index);
            }}
          />
        ),
      },
      {
        label: `Delivered(${saleOrder?.totalDelivered})`,
        key: "qty_delivered",
        width: 100,
        textAlign: "right",
        format: ({ rowData }) =>
          `${accounting.formatNumber(rowData?.qty_delivered ?? 0, 0)} ${rowData.product_uom.display_name
          }`,
      },
      {
        label: `Invoiced(${saleOrder?.totalInvoiced})`,
        key: "qty_invoiced",
        width: 100,
        textAlign: "right",
        format: ({ rowData }) =>
          `${accounting.formatNumber(rowData.qty_invoiced ?? 0, 0)} ${rowData.product_uom.display_name
          }`,
      },
      {
        label: "Total",
        key: "price_subtotal",
        width: 100,
        textAlign: "right",
        format: ({ rowData }) =>
          accounting.formatNumber(rowData.price_subtotal ?? 0, 0),
      },
      {
        label: "",
        key: "command",
        width: 75,
        format: ({ index, extraData: { orderState }, rowData }) => {
          return (
            <IconButton
              disabled={!(orderState === SaleOrderState.draft || !rowData.id)}
              aria-label="Delete"
              onClick={() => {
                deleteOrderLine(index);
              }}
            >
              <Close color="error" />
            </IconButton>
          );
        },
      },
    ];
    if (saleOrderSetting.recordSoldBy) {
      columns.splice(3, 0,
        {
          label: "Sold by",
          key: "saleperson_id",
          width: 200,
          format: ({
            rowData,
            index,
          }) => (
            <EmployeeAutoComplete
              size="small"
              selectedEmployee={rowData.saleperson_id ? { id: rowData.saleperson_id.id, name: rowData.saleperson_id?.display_name } : null}
              onSelected={(selected) => {
                updateSaleperson(selected, index)
              }}
            />
          ),
        });
      return columns;
    }
    return columns;
  }, [saleOrder?.totalOrdered, saleOrder?.totalDelivered, saleOrder?.totalInvoiced, saleOrderSetting.recordSoldBy, saleOrderSetting.roundedDecimalPlaceForPrice, updatePrice, updateQty, deleteOrderLine, updateSaleperson]);

  const setCustomer = React.useCallback(
    (customer: Customers_partnerQuery_customers_edges) => {
      dispatch(saleOrderActions.setCustomer(customer));
    },
    [dispatch]
  );

  const noDurationLimit = useHasPermission("Sale_NoDurationLimitToEdit");

  const isFreshToEdit = React.useMemo(() => {
    const now = format(new Date(), "yyy-MM-dd");
    if (now === saleOrder.date_order)
      return true;
    if (noDurationLimit)
      return true;
    return false;
  }, [saleOrder.date_order, noDurationLimit]);

  const [operationError, setOperationError] = React.useState<Error | null>(null);
  const [operationErrorMessage, setOperationErrorMessage] =
    React.useState<string>("");
  const [errorMessageDialogOpen, setErrorMessageDialogOpen] =
    React.useState(false);
  const [paymentDialogOpen, setPaymentDialogOpen] = React.useState(false);
  const loaderCacheResetor = React.useRef<(() => void) | null>(null);
  const order_lines = saleOrder?.order_lines;
  const amount_Due = React.useMemo(() => {
    var result = 0;
    saleOrder?.invoices?.forEach((invoice) => {
      result +=
        invoice.amount_residual *
        (invoice.move_type === AccountMoveType.out_refund ? -1 : 1);
    });
    return result;
  }, [saleOrder?.invoices]);



  const disablePayment = React.useMemo(() => {
    const [invoice] = saleOrder?.invoices ?? [];
    if (!invoice) return true;
    if (saleOrder?.invoice_status === InvoiceStatus.toinvoice) return true;
    return !saleOrder.invoices?.some(invoice => {
      const { state, payment_state, move_type } = invoice;
      const result = (
        state !== InvoiceState.posted ||
        [PaymentStatus.not_paid, PaymentStatus.partial].indexOf(payment_state) ===
        -1 ||
        [
          AccountMoveType.out_invoice,
          AccountMoveType.out_refund,
          AccountMoveType.in_invoice,
          AccountMoveType.in_refund,
          AccountMoveType.out_receipt,
          AccountMoveType.in_receipt,
        ].indexOf(move_type) === -1 || !isFreshToEdit
      );
      return !result;
    });
  }, [saleOrder?.invoices, saleOrder?.invoice_status, isFreshToEdit]);

  const disableRenew = React.useMemo(() => {
    return !saleOrder.id
  }, [saleOrder.id]);

  const disableCancel = React.useMemo(() => {
    return (
      [SaleOrderState.sale, SaleOrderState.sent].indexOf(saleOrder.state!) === -1 || !isFreshToEdit || saleOrder.invoice_status === InvoiceStatus.toinvoice
    );
  }, [saleOrder.state, isFreshToEdit, saleOrder.invoice_status]);

  const [cancelSaleOrder, { loading: cancellingOrder }] = useMutation<
    CancelSaleOrder,
    CancelSaleOrderVariables
  >(cancelOrderMutation, {
    onCompleted: (data) => {
      setSaleOrder(data?.saleOrderMutation?.cancelSaleOrder!);
    },
    onError: (error) => {
      setOperationError(error);
      setOperationErrorMessage("Could not cancel sale order.");
      setErrorMessageDialogOpen(true);
    },
  });

  const [openCustomerDialog, setOpenCustomerDialog] = React.useState(false);

  const [openSaleOrderLineDialog, setOpenSaleORderLineDialog] =
    React.useState(false);

  const [searchText, setSearchText] = React.useState("");
  const searchBox = React.useRef<any>(null);

  const [createInvoice, { loading: creatingInvoice }] = useMutation<
    CreateInvoice,
    CreateInvoiceVariables
  >(createInvoiceMutation, {
    onCompleted: (data) => {
      setSaleOrder(data?.saleOrderMutation?.createInvoice!);
    },
    onError: (error) => {
      setOperationError(error);
      setOperationErrorMessage("Could not create invoice.");
      setErrorMessageDialogOpen(true);
    },
  });
  const [saveSaleOrder, { loading: savingSaleOrder }] = useMutation<
    SaveSaleOrder,
    SaveSaleOrderVariables
  >(saveSaleOrderMutation, {
    onCompleted: (data) => {
      const savedOrder = data?.saleOrderMutation?.saveSaleOrder;
      if (saleOrder.id) {
        setSaleOrder(savedOrder!);
      } else {
        navigate(`/sale_orders/${savedOrder?.id}`);
      }
    },
    onError: (error) => {
      setOperationError(error);
      setOperationErrorMessage("Could not save sale order.");
      setErrorMessageDialogOpen(true);
    },
    update: (cache, { data }) => {
      const saved = data?.saleOrderMutation?.saveSaleOrder;
      if (!saleOrder.id) {
        const cached = cache.readQuery<SaleOrders, SaleOrdersVariables>({
          query: saleOrdersQuery,
          variables: saleOrdersVariables,
        });
        if (!cached)
          return;
        const updated = update(cached, {
          saleOrderQuery: {
            saleOrders: {
              edges: {
                $unshift: [saved as any],
              },
              pageInfo: {
                rowCount: {
                  $set:
                    (cached?.saleOrderQuery?.saleOrders?.pageInfo?.rowCount ??
                      0) + 1,
                },
              },
            },
          },
        });
        cache.writeQuery<SaleOrders, SaleOrdersVariables>({
          query: saleOrdersQuery,
          variables: saleOrdersVariables,
          data: updated,
        });
      }
    },
  });

  const [searchProduct] = useLazyQuery<ProductItems, ProductItemsVariables>(productItemsQuery, {
    fetchPolicy: "network-only"
  });

  const [searchingProduct, setSearchingProduct] = React.useState(false);
  const allowedQuants = useHasPermission("Product_ViewOnHand");
  const handleSearchProduct = React.useCallback(async (searchText: string) => {
    setSearchText(searchText);
    const numText = searchText?.substring(3, searchText.length);
    const id = Number.parseInt(numText);
    const productId = isNaN(id) ? -1 : id;
    setSearchingProduct(true);
    try {
      const { data } = await searchProduct({ variables: { quants:allowedQuants,where: { id: productId } } });
      const [product] = data?.productQuery?.productItems?.edges ?? [];
      if (product) { addOrderLine(product); dispatch(openSnackbar({ message: `${product.default_code} added.`, open: true })) }
    } catch (error) {
      setOperationError(error as any);
      setOperationErrorMessage("Could not find product.");
      setErrorMessageDialogOpen(true);
    } finally {
      setSearchingProduct(false);
      searchBox.current.focus();
    }
  }, [searchProduct, addOrderLine, dispatch]);

  const disableNew = React.useMemo(() => {
    return (
      !saleOrder?.id ||
      creatingInvoice ||
      savingSaleOrder ||
      searchingProduct ||
      loading
    );
  }, [
    saleOrder.id,
    creatingInvoice,
    savingSaleOrder,
    searchingProduct,
    loading,
  ]);

  const createNew = React.useCallback(() => {
    if (!disableNew) navigate("/sale_orders/create");
  }, [disableNew, navigate]);

  

  const disableSaveSaleOrder = React.useMemo(() => {
    const { hasChanges, order_lines, deleted_order_lines } = saleOrder;
    return (
      !hasChanges ||
      ((order_lines?.length ?? 0) === 0 && deleted_order_lines.length === 0) ||
      creatingInvoice ||
      savingSaleOrder ||
      searchingProduct ||
      loading
    );
  }, [saleOrder, creatingInvoice, savingSaleOrder, searchingProduct, loading]);

  const disableAddSaleOrderLine = React.useMemo(() => {
    return (
      [SaleOrderState.draft, SaleOrderState.sale, SaleOrderState.sent].indexOf(
        saleOrder?.state!
      ) === -1 ||
      amount_Due !== 0 ||
      searchingProduct ||
      savingSaleOrder ||
      creatingInvoice ||
      loading ||
      !isFreshToEdit
    );
  }, [
    saleOrder.state,
    searchingProduct,
    savingSaleOrder,
    creatingInvoice,
    amount_Due,
    loading,
    isFreshToEdit
  ]);

  const disableCreateInvoice = React.useMemo(() => {
    return (
      saleOrder.hasChanges ||
      amount_Due !== 0 ||
      !saleOrder.id ||
      !(
        saleOrder.invoice_status === InvoiceStatus.toinvoice ||
        saleOrder.state === SaleOrderState.draft
      ) ||
      searchingProduct ||
      savingSaleOrder ||
      creatingInvoice ||
      loading || !isFreshToEdit
    );
  }, [saleOrder.hasChanges, saleOrder.id, saleOrder.invoice_status, saleOrder.state, amount_Due, searchingProduct, savingSaleOrder, creatingInvoice, loading, isFreshToEdit]);


  const remarkTextBox = React.useRef<any>(null);

  const [cancelDialogOpen, setCancelDialogOpen] = React.useState(false);
  const [cancelRemark, setCancelRemark] = React.useState("");

  React.useEffect(() => {
    if (cancelDialogOpen) {
      setTimeout(() => { remarkTextBox.current.focus(); }, 300);
    }
  }, [cancelDialogOpen]);

  const disableDo = React.useMemo(() => {
    return !saleOrder.id || [SaleOrderState.sale, SaleOrderState.sent].indexOf(
      saleOrder?.state!
    ) === -1 || !saleOrder?.order_lines?.length || saleOrder.invoice_status !== InvoiceStatus.invoiced;
  }, [saleOrder.id, saleOrder?.state, saleOrder?.order_lines?.length, saleOrder.invoice_status]);

  const handleSaveSaleOrder = React.useCallback(async () => {
    const input: SaleOrderInput = {
      id: saleOrder.id,
      date_order: saleOrder.date_order,
      warehouse_id: Number.parseInt(saleOrder?.warehouse_id?.id!),
    };
    if (saleOrder.partner) {
      input.partner_id = Number.parseInt(saleOrder.partner.id);
    }
    if (saleOrder.analytic_account_id) {
      input.analytic_account_id = Number.parseInt(
        saleOrder.analytic_account_id.id
      );
    }
    if (saleOrder.team_id) {
      input.team_id = Number.parseInt(saleOrder.team_id.id);
    }
    input.order_line = {
      list: saleOrder.order_lines?.map(
        ({ id, product_uom_qty, price_unit, product_id, name, saleperson_id }) => ({
          id,
          product_uom_qty,
          price_unit,
          product_id: Number.parseInt(product_id.id),
          saleperson_id: saleperson_id?.id ? Number.parseInt(saleperson_id?.id) : undefined,
          name,
        })
      ),
      deleted: saleOrder.deleted_order_lines,
    };
    await saveSaleOrder({ variables: { order: input } });
  }, [saveSaleOrder, saleOrder]);



  const handleCreateInvoice = React.useCallback(() => {
    if (saleOrderSetting.saleTeam?.id)
      createInvoice({
        variables: {
          id: Number.parseInt(saleOrder?.id!),
          team_id: Number.parseInt(saleOrderSetting.saleTeam.id)
        },
      });
    else
      dispatch(openSnackbar({ message: "Please select a sale team in sale setting.", open: true }));
  }, [createInvoice, dispatch, saleOrder?.id, saleOrderSetting?.saleTeam?.id]);

  const performCancelSaleOrder = React.useCallback(async () => {
    await cancelSaleOrder({
      variables: {
        id: Number.parseInt(saleOrder?.id!),
        cancelRemark
      },
    });
    setCancelDialogOpen(false);
    setCancelRemark("");
  }, [cancelRemark, cancelSaleOrder, saleOrder?.id]);

  const handleCommandKeyUp = React.useCallback(
    (e: DocumentEventMap["keyup"]) => {
      switch (e.key) {
        case "F1":
          if (!disablePayment) {
            setPaymentDialogOpen(true);
            e.stopPropagation();
          }
          break;
        case "F2":
          setOpenCustomerDialog(true);
          e.stopPropagation();
          break;
        case "F4":
          if (!disableAddSaleOrderLine) {
            setOpenSaleORderLineDialog(true);
            e.stopPropagation();
          }
          break;
        case "F3":
          searchBox.current.focus();
          e.stopPropagation();
          break;
        case "F8":
          if (!disableCreateInvoice) {
            handleCreateInvoice();
            e.stopPropagation();
          }
          break;
        case "F9":
          if (!disableNew) {
            createNew();
            e.stopPropagation();
          }
          break;
        case "F10":
          if (!disableSaveSaleOrder) {
            handleSaveSaleOrder();
            e.stopPropagation();
          }
          break;
        case "F11":
          if (!disableDo) {
            setDoDialogOpen(true);
            e.stopPropagation();
          }
          break;

        case "Escape":
          if (!saleOrder.hasChanges)
            navigate(`/sale_orders`);
          e.stopPropagation();
          break;
      }
    },
    [disablePayment, disableAddSaleOrderLine, disableCreateInvoice, disableNew, disableSaveSaleOrder, disableDo, saleOrder.hasChanges, navigate, handleCreateInvoice, createNew, handleSaveSaleOrder]
  );

  const handleRenew = React.useCallback(() => {
    const { partner, order_lines } = saleOrder;
    const newSaleOrder: SaleOrderReduxState = {
      ...saleOrderDefaultValue,
      partner,
      order_lines: order_lines?.map(line => {
        const newLine: SaleOrder_saleOrderQuery_saleOrder_order_lines = { ...line, qty_delivered: 0, qty_invoiced: 0, id: "" };
        return newLine;
      }),
      hasChanges: true
    };
    if (saleOrderSetting.analyticAccount) {
      newSaleOrder.analytic_account_id = { id: saleOrderSetting.analyticAccount?.id, display_name: saleOrderSetting.analyticAccount?.name };
    } else {
      navigate("/settings/sale");
    }
    if (saleOrderSetting.saleTeam) {
      newSaleOrder.team_id = { id: saleOrderSetting.saleTeam.id, display_name: saleOrderSetting.saleTeam.name };
    } else {
      navigate("/settings/sale");
    }
    if (saleOrderSetting.warehouse) {
      newSaleOrder.warehouse_id = { id: saleOrderSetting.warehouse.id, display_name: saleOrderSetting.warehouse.name };
    } else {
      navigate("/settings/sale");
    }
    dispatch(saleOrderActions.updateSaleOrder(newSaleOrder));
    navigate("/sale_orders/renew");
  }, [dispatch, navigate, saleOrder, saleOrderSetting.analyticAccount, saleOrderSetting.saleTeam, saleOrderSetting.warehouse]);

  React.useEffect(() => {
    document.addEventListener("keyup", handleCommandKeyUp);
    return () => {
      document.removeEventListener("keyup", handleCommandKeyUp);
    };
  }, [handleCommandKeyUp, saleOrder.id]);

  const [selectedIndices, setSelectedIndices] = React.useState<number[]>([]);
  const [selectAll, setSelectAll] = React.useState(false);
  const performSelectAll = React.useCallback((indices: number[]) => {
    setSelectAll(true);
    setSelectedIndices(indices);
  }, [setSelectAll, setSelectedIndices]);
  const clearSelectAll = React.useCallback(() => {
    setSelectAll(false);
    setSelectedIndices([]);
  }, [setSelectAll, setSelectedIndices]);
  const selectedTotal = React.useMemo(() => {
    return order_lines?.reduce((p, c, index) => {
      return (selectedIndices?.includes(index) ? c.price_subtotal ?? 0 : 0) + p;
    }, 0);
  }, [selectedIndices, order_lines]);
  return (
    <>
      <Box
        flex={1}
        style={{
          display: "flex",
          borderLeftWidth: 1,
          borderLeftColor: "rgba(255,255,255,.3)",
          borderLeftStyle: "solid",
        }}
      >
        {saleOrder ? (
          <VirtualizedTable
            scrollToIndex={(saleOrder?.order_lines?.length ?? 0) - 1}
            extraData={{
              orderState: saleOrder.state,
              invoiceStatus: saleOrder.invoice_status,
              amount_Due,
            }}
            displayRowCount={false}
            registerForLoaderCacheReset={(resetor: () => void) => {
              loaderCacheResetor.current = resetor;
            }}
            selectedItems={selectedIndices}
            setSelectedItems={setSelectedIndices}
            selectededAll={selectAll}
            setSelectedAll={performSelectAll}
            clearSelectedAll={clearSelectAll}
            checkBoxColumnMode={CheckBoxColumnMode.first}
            headerComponent={
              <div className="bg-down-1">
                <SaleOrderHeader
                  disableRenew={disableRenew}
                  receivePayment={() => {
                    setPaymentDialogOpen(true);
                  }}
                  saleOrder={saleOrder}
                  disablePayment={disablePayment}
                  disableNew={disableNew}
                  createNew={createNew}
                  disableAddSaleOrderLine={disableAddSaleOrderLine}
                  addSaleOrderLine={() => {
                    setOpenSaleORderLineDialog(true);
                  }}
                  selectCustomer={() => {
                    setOpenCustomerDialog(true);
                  }}
                  disableSaveSaleOrder={disableSaveSaleOrder}
                  saveSaleOrder={handleSaveSaleOrder}
                  createInvoice={handleCreateInvoice}
                  disableCreateInvoice={disableCreateInvoice}
                  disableCancel={disableCancel}
                  cancelSaleOrder={() => {
                    setCancelDialogOpen(true);
                  }}
                  disableDo={disableDo}
                  performDo={() => {
                    setDoDialogOpen(true);
                  }}
                  performRenew={handleRenew}
                />
              </div>
            }
            loading={loading}
            listModeBreakPoint={0}
            rowGetter={(index: number) => {
              return order_lines?.[index]!;
            }}
            totalRowCount={order_lines?.length ?? 0}
            rowCount={order_lines?.length ?? 0}
            isRowLoaded={(index: number) => !!order_lines?.[index]}
            columns={columns}
            pageSize={1000}
            initialLoading={loading}
            footerComponent={
              <Box sx={{ backgroundColor: "rgba(0,0,0.1)" }}>
                <TextEditor
                  size="small"
                  validateOnBlur={false}
                  skipOnValidatedEventIfValueNotChanged={false}
                  disabled={disableAddSaleOrderLine}
                  inputReference={(r) => {
                    searchBox.current = r;
                  }}
                  fullWidth
                  inputElementType="Input"
                  value={searchText}
                  sx={_sx.searchBox}
                  onValidated={handleSearchProduct}
                  placeholder="Enter barcode(F3)"
                  inputProps={{ style: { color: "#000" } }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          onClick={() => {
                            setSearchText("");
                          }}
                        >
                          <Close color="primary" fontSize="small" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            }
          />
        ) : null}
        <SaleOrderSummaryView selectedTotal={selectedTotal ?? 0} saleOrder={saleOrder as SaleOrder_saleOrderQuery_saleOrder}  />
      </Box>
      <PaymentDialog
        isInitialPayment={
          saleOrder?.state === SaleOrderState.sale &&
          saleOrder?.invoice_status === InvoiceStatus.toinvoice
        }
        amountDue={amount_Due}
        saleOrderId={saleOrder ? Number.parseInt(saleOrder?.id!) : -1}
        onClose={() => {
          setPaymentDialogOpen(false);
        }}
        open={paymentDialogOpen}
        onError={(error) => {
          setOperationError(error);
          setOperationErrorMessage("Could not accept payment.");
          setErrorMessageDialogOpen(true);
        }}
      />
      <CustomerDialog
        open={openCustomerDialog}
        onClose={() => {
          setOpenCustomerDialog(false);
        }}
        onSelected={(customer) => {
          setCustomer(customer);
          setOpenCustomerDialog(false);
        }}
        selectedCustomerId={defaultCustomerId === saleOrder?.partner?.id ? undefined : saleOrder?.partner?.id}
        disableSelection={saleOrder?.state !== SaleOrderState.draft}
      />
      {operationError ? (
        <ErrorMessageDialog
          open={errorMessageDialogOpen}
          onClose={() => {
            setErrorMessageDialogOpen(false);
          }}
          error={{
            title:"Error",
            message: operationErrorMessage,
            detail:operationError.message
        }}
        />
      ) : null}
      {
        openSaleOrderLineDialog ? <AddOrderLineDialog
          open={openSaleOrderLineDialog}
          onClose={() => {
            setOpenSaleORderLineDialog(false);
          }}
        /> : null
      }
      <Dialog open={cancelDialogOpen} maxWidth="sm" fullWidth>
        <DialogTitle>Cancel sale order.</DialogTitle>
        <DialogContent>
          <TextField
            inputRef={remarkTextBox}
            value={cancelRemark}
            fullWidth
            focused
            label="Remark"
            placeholder="Remark"
            multiline
            rows={3}
            onChange={(e) => { setCancelRemark(e.target.value) }}
          />
        </DialogContent>
        <DialogActions>
          <DialogActionLoadingIndicator loading={cancellingOrder} text="Cancelling sale order.." />
          <Button disabled={cancellingOrder} onClick={() => { setCancelDialogOpen(false); }}>Cancel</Button>
          <Button disabled={!cancelRemark || cancellingOrder} onClick={performCancelSaleOrder}>OK</Button>
        </DialogActions>
      </Dialog>
      {
        doDialogOpen ? <DeliveryOrderDialog saleOrderNo={saleOrder?.name ?? ""} open={doDialogOpen} onClose={() => { setDoDialogOpen(false); }} pickingIds={saleOrder?.picking_ids ?? []} /> : null
      }
    </>
  );
}

export default SaleOrderEditControl;
