
import AppBar from "@mui/material/AppBar/AppBar";
import Grid from "@mui/material/Grid";
import formatDate from "date-fns/format";
import Toolbar from "@mui/material/Toolbar/Toolbar";
import accounting from "accounting";
import ApolloVirtualizedGrid, { DefaultPageInfoValue, GridColumn, ListItemRenderProps } from "mui-apollo-virtualized-table";
import format from "date-fns/format";
import * as React from "react";
import Typography from "@mui/material/Typography/Typography";
import update from "immutability-helper";
import Box from "@mui/material/Box/Box";
import { ApolloConsumer } from "@apollo/client/react/context/ApolloConsumer";
import Tooltip from "@mui/material/Tooltip/Tooltip";
import IconButton from "@mui/material/IconButton/IconButton";
import Download from "@mui/icons-material/CloudDownload";
import { StockValuationReportVariables, StockValuationReport, StockValuationReport_stockValuation_valuations_edges } from "../__generated__/StockValuationReport";
import { stockValuationReportQuery } from "../graphql";
import FileDownloader, { FileDownloaderHandle } from "../../../components/FileDownloader";
import DrawerToggleButton from "../../../app/DrawerToggleButton";
import CsSearchBox, { OperandType } from "../../../components/CsSearchBox";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import Divider from "@mui/material/Divider";
import Skeleton from "@mui/material/Skeleton";
import { DatePicker, DatePickerProps } from "@mui/x-date-pickers";
import TextField from "@mui/material/TextField";
import settings from "../../../config.json"
const Picker = (DatePicker) as React.ElementType<DatePickerProps<Date, any>>
export type StockValuationReportProps = {};

const _sx = {
    title: {
        marginRight: 1,
    },
    box: {
        backgroundColor: "rgba(255,255,255,.1)",
    },
    root: {
        flex: 1,
        display: "flex",
    },
    loadingIndicator: {
        width: 150,
        display: "inline-block"
    }
}

function RptStockValuation() {
    const [as_of,setAsOf] = React.useState<Date>(new Date());
    const [columns, setColumns] = React.useState<
        ReadonlyArray<GridColumn<StockValuationReport_stockValuation_valuations_edges>>
    >([
        {
            label: "Date",
            key: "create_date",
            width: 100,
            format: ({ rowData }) => format(new Date(rowData?.create_date), 'dd/MM/yyyy'),
            sortable: true,
        },
        {
            label: "Model No",
            key: "default_code",
            width: 150,
            sortable: true
        },
        {
            label: "Product Name",
            key: "name",
            width: 200,
            flexGrow: 1,
            sortable: true
        },
        {
            label: "Brand",
            key: "brand",
            width: 100,
            sortable: true,
            hideAt: 900
        },
        {
            label: "Sale Zone",
            key: "salezone",
            width: 100,
            sortable: true,
            hideAt: 800
        },
        {
            label: "Uom",
            key: "uom",
            width: 75,
            sortable: true,
            hideAt: 950
        },
        {
            label: "Qty",
            key: "remaining_qty",
            labelAlign: "right",
            width: 75,
            textAlign: "right",
            sortable: true,
            format: ({ rowData }) =>
                `${accounting.formatNumber(rowData.remaining_qty ?? 0, 0)}`,
        },
        {
            label: "Value",
            key: "remaining_value",
            width: 100,
            textAlign: "right",
            labelAlign: "right",
            sortable: true,
            format: ({ rowData }) => accounting.formatNumber(rowData.remaining_value ?? 0, 0),
        },
        {
            label: "Unit Cost",
            key: "final_cost",
            labelAlign: "right",
            width: 100,
            textAlign: "right",
            hideAt: 700,
            sortable: false,
            format: ({ rowData }) => accounting.formatNumber(rowData.final_cost ?? 0, 0),
        },
        {
            label: "Product Move",
            key: "stock_move",
            width: 150,
            hideAt: 1150,
            sortable: true,
        },
        {
            label: "Supplier",
            key: "supplier_name",
            width: 200,
            hideAt: 1050,
            flexGrow: 1,
            sortable: true,
        },
        {
            label: "Supplier Category",
            key: "supplier_category",
            width: 200,
            hideAt: 1050,
            sortable: true,
        },
    ]);
    const [variables, setVariables] = React.useState<StockValuationReportVariables>({ pagination: { pageSize: 40 } });
    const handleColumnsPropsChanged = React.useCallback((columns: ReadonlyArray<GridColumn<StockValuationReport_stockValuation_valuations_edges>>, orderBy: string[]) => {
        setColumns(columns);
        setVariables({ ...variables, orderBy: orderBy as any });
    }, [setVariables, setColumns, variables]);
    const fileDownloader = React.useRef<FileDownloaderHandle>(null);

    const handleExcelFileDownload = React.useCallback(() => {
        fileDownloader?.current?.download();
    }, []);

    const listItemRenderer = React.useCallback(
        (renderProps: ListItemRenderProps<StockValuationReport_stockValuation_valuations_edges>) => {
            const { rowData, key, style, className, onClick } = renderProps;
            if (rowData) {
                const { default_code, name, create_date, remaining_qty, remaining_value } = rowData;
                return (
                    <div onClick={onClick} style={style} className={className} key={key}>
                        <ListItem>
                            <ListItemText
                                primary={default_code}
                                secondary={name}
                            />
                        </ListItem>
                        <ListItemSecondaryAction>
                            <ListItemText
                                primary={`${accounting.formatNumber(remaining_qty ?? 0, 0)} x ${accounting.formatNumber((remaining_value ?? 0) / (remaining_qty ?? 0), 0)} = ${accounting.formatNumber(remaining_value ?? 0, 0)}`}
                                secondary={format(new Date(create_date), 'dd/MM/yyyy')}
                            />
                        </ListItemSecondaryAction>
                        <Divider />
                    </div>
                );
            } else {
                return (
                    <div style={style} key={key}>
                        <ListItem>
                            <ListItemText
                                primary={
                                    <Skeleton sx={_sx.loadingIndicator} />
                                }
                                secondary={
                                    <Skeleton sx={_sx.loadingIndicator} />
                                }
                            />
                        </ListItem>
                        <Divider />
                    </div>
                );
            }
        },
        []
    );


    return (
        <>
            <Grid container direction="column">
                <AppBar position="static">
                    <Toolbar sx={(theme) => ({
                        [theme.breakpoints.up("md")]: {
                            minHeight: theme.spacing(6),
                        },
                        paddingLeft: 1.5,
                    })}>
                        <DrawerToggleButton />
                        <Typography
                            variant="h6"
                            sx={_sx.title}
                            color="inherit"
                            noWrap
                        >
                            Inventory Valuation
                        </Typography>
                        <CsSearchBox
                            onConditionChanged={(conditions: any) => {
                                setVariables(
                                    update(variables, {
                                        where: {
                                            $set: { aND: conditions },
                                        },
                                    })
                                );
                            }}
                            operands={{
                                create_date: {
                                    name: "Date",
                                    propName: "create_date",
                                    type: OperandType.DATE
                                },
                                default_code: {
                                    name: "Model No",
                                    propName: "default_code",
                                    type: OperandType.STRING
                                },
                                name: {
                                    name: "Product Name",
                                    propName: "name",
                                    type: OperandType.STRING
                                },
                                brand: {
                                    name: "Brand",
                                    propName: "brand",
                                    type: OperandType.STRING
                                },
                                salezone: {
                                    name: "Sale Zone",
                                    propName: "salezone",
                                    type: OperandType.STRING,
                                },
                                supplier_name: {
                                    name: "Supplier",
                                    propName: "supplier_name",
                                    type: OperandType.STRING
                                },
                                supplier_category: {
                                    name: "Supplier Category",
                                    propName: "supplier_category",
                                    type: OperandType.STRING
                                },
                                remaining_qty: {
                                    name: "Qty",
                                    propName: "remaining_qty",
                                    type: OperandType.NUMBER
                                },
                                remaining_value: {
                                    name: "Value",
                                    propName: "remaining_value",
                                    type: OperandType.NUMBER
                                },
                                final_cost: {
                                    name: "Unit Cost",
                                    propName: "final_cost",
                                    type: OperandType.NUMBER
                                }
                            }}
                        />
                        <Picker
                            renderInput={(props) => <TextField size="small" {...props} />}
                            label=""
                            value={as_of}
                            inputFormat={settings.shortDateFormat}
                            onChange={setAsOf}
                        />
                        <FileDownloader ref={fileDownloader} url={`/excel/stock_valuation/${formatDate(as_of, "yyyy-MM-dd")}`} json={JSON.stringify(variables?.where)}>
                            <Tooltip title="Download Excel">
                                <IconButton
                                    color="inherit"
                                    onClick={handleExcelFileDownload}
                                >
                                    <Download />
                                </IconButton>
                            </Tooltip>
                        </FileDownloader>
                    </Toolbar>
                </AppBar>
                <Box
                    sx={_sx.box}
                    flex={1}
                    display="flex"
                    flexDirection="row"
                >
                    <ApolloConsumer>
                        {(client) => (
                            <ApolloVirtualizedGrid
                                apolloClient={client as any}
                                listItemHeight={72}
                                listModeBreakPoint={500}
                                listItemRenderer={listItemRenderer}
                                columns={columns}
                                graphqlQuery={stockValuationReportQuery}
                                variables={{...variables,as_of: formatDate(as_of, "yyyy-MM-dd")}}
                                pageSize={variables?.pagination?.pageSize!}
                                onColumnPropsChanged={handleColumnsPropsChanged}
                                parseListFromQueryResult={(queryResult: StockValuationReport) => {
                                    const list = queryResult?.stockValuation?.valuations ?? {
                                        edges: [],
                                        pageInfo: DefaultPageInfoValue,
                                    };
                                    return list;
                                }}
                                onLoadMore={(pageInfo) => {
                                    return {
                                        ...variables,
                                        pagination: {
                                            page: pageInfo.page,
                                            pageSize: pageInfo.pageSize,
                                        },
                                    };
                                }}
                            />
                        )}
                    </ApolloConsumer>
                </Box>
            </Grid>
        </>
    );
}

export default RptStockValuation;