import * as React from "react";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import ZoomIn from "@mui/icons-material/ZoomIn";
import update from "immutability-helper";
import { AutoSizer as ASizer,AutoSizerProps } from "react-virtualized";
import CsSearchBoxPoper from "./CsSearchBoxPoper";
import CsSingleOperandFilterEditor, {
  SingleOperandFilterCondition
} from "./CsSingleOperandFilterEditor";
import CsMultiOperandFilterEditor, {
  MultiOperandFilterCondition
} from "./CsMultiOperandFilterEditor";
import { DateRangeWithLabel } from "./CsDateRangeEditor";
import formatDate from "date-fns/format";
import { EnumSelectOptions } from "./EnumMultiSelectEditor";
import { InputBaseComponentProps } from "@mui/material/InputBase/InputBase";
import Typography from "@mui/material/Typography/Typography";
import ButtonBase from "@mui/material/ButtonBase/ButtonBase";
import Chip from "@mui/material/Chip/Chip";
import InputBase from "@mui/material/InputBase/InputBase";
import IconButton from "@mui/material/IconButton/IconButton";
import settings from "../config.json";
import { SxProps, Theme } from "@mui/material/styles";
import Box from '@mui/material/Box';
const AutoSizer = ASizer as React.ElementType<AutoSizerProps>;
var isFirefox = typeof window.InstallTrigger !== "undefined";
const _sx = {
  layout: {
    flex: 1
  },
  root: {
    display: "flex",
    flexWrap: "nowrap",
    border: 1,
    borderColor: "white",
    backgroundColor: "white",
    borderRadius: 0.5,
    paddingLeft: 0.5,
    paddingRight: 0.5,
    overflow: "hidden"
  },
  chipScroller: {
    overflowX: "scroll",
    marginBottom: -2.5,
    direction: "rtl"
  },
  chips: {
    whiteSpace: "nowrap"
  },
  chip: {
    backgroundColor:"rgba(0,0,0,.5)",
    color:"#fff",
    "&:hover":{
      backgroundColor:"rgba(0,0,0,.2)",
      color:"#000"
    },
    margin: .5,
    direction: "ltr",
    "& .MuiChip-label": {
      display: "flex",
      alignItems: "center"
    }
  },
  input: {
    color: "rgba(0, 0, 0, .8)",
    marginLeft: .5,
    marginRight: .5,
    flex: 1,
    minWidth: 150
  },
  scrollButton: {
    "& .Mui-disabled": {
      color: "#A0A0A0",
      backgroundColor: "#D7D7D7"
    },
    backgroundColor: "primary.main",
    borderRadius: "50%",
    height: 18,
    mt: 6
  },
  scrollButtonIcon: {
    fontSize: 18
  },
  operatorInChip: {
    fontStyle: "italic",
    marginLeft: 1,
    marginRight: 1
  },
  orSeparator: {
    margin: 1
  }
};

export enum OperandType {
  NUMBER = 1,
  BOOLEAN = 2,
  STRING = 3,
  DATE = 4,
  DATETIME = 5,
  TIME = 6,
  ENUM = 7
}

export type GraphqlCondition = { [key: string]: any };

export type FilterOperand = {
  name: string;
  propName: string;
  type: OperandType;
  enumOptions?: EnumSelectOptions;
};

export interface FilterOperands {
  [key: string]: FilterOperand;
}

export enum FilterOperator {
  EQUAL = 1,
  NOT_EQUAL = 2,
  LESS_THAN = 3,
  LESS_THAN_EQUAL = 4,
  GREATER_THAN = 5,
  GREATER_THAN_EQUAL = 6,
  CONTAINS = 7,
  NOT_CONTAIN = 8,
  STARTS_WITH = 9,
  NOT_START_WITH = 10,
  END_WITH = 11,
  NOT_END_WITH = 12,
  BETWEEN = 13,
  NOT_BETWEEN = 14,
  IN = 15,
  NOT_IN = 16,
  BLANK = 17,
  NOT_BLANK = 18
}

export type FilterValue =
  | number
  | string
  | Date
  | Boolean
  | ReadonlyArray<number>
  | ReadonlyArray<string>
  | DateRangeWithLabel;

export type FilterCondition = {
  operand: FilterOperand;
  operator?: FilterOperator;
  value?: FilterValue;
};

const stringifyFilterValue = (
  v: string | number | boolean | Date | any[],
  operand: FilterOperand
) => {
  if(operand.type === OperandType.BOOLEAN)
    return v? "true":"false";
  if (!v) return "";
  switch (operand.type) {
    case OperandType.STRING:
    case OperandType.NUMBER:
      return v.toString();
    case OperandType.DATE:
      return formatDate(v as Date, settings.shortDateFormat);
    case OperandType.DATETIME:
      return formatDate(v as Date, settings.longDateFormat);
    case OperandType.ENUM:
      return operand?.enumOptions?.[v as any]?.text;
  }
};
export const stringifyFilterValues = (condition: FilterCondition) => {
  const { operand, value, operator } = condition;
  let arrayValue = [];
  if (!value) return "";
  switch (operator) {
    case FilterOperator.BETWEEN:
    case FilterOperator.NOT_BETWEEN:
      switch (operand.type) {
        case OperandType.NUMBER:
          arrayValue = value as Array<string | number>;
          return `"${stringifyFilterValue(
            arrayValue[0],
            operand
          )}" and "${stringifyFilterValue(arrayValue[1], operand)}"`;
        case OperandType.DATE:
        case OperandType.DATETIME:
          const dateRange = value as DateRangeWithLabel;
          return dateRange.label;
      }
      break;
    case FilterOperator.BLANK:
    case FilterOperator.NOT_BLANK:
      return "";
    case FilterOperator.IN:
    case FilterOperator.NOT_IN:
      arrayValue = value as Array<string | number | Date>;
      return arrayValue
        .map(v => `"${stringifyFilterValue(v, operand)}"`)
        .join(",");
    default:
      return `"${stringifyFilterValue(
        value as string | number | boolean | Date,
        operand
      )}"`;
  }
};

export const getLabelForOperator = (
  operator?: FilterOperator
) => {
  if (!operator)
    return undefined;
  const svg = `/filter_icons/${FilterOperator[operator]}_B.svg`;
  switch (operator) {
    case FilterOperator.EQUAL:
      return { label: "Equal", svg, shortLabel: "=" };
    case FilterOperator.NOT_EQUAL:
      return { label: "Not equal", svg, shortLabel: "<>" };
    case FilterOperator.LESS_THAN:
      return { label: "Less than", svg, shortLabel: "<" };
    case FilterOperator.LESS_THAN_EQUAL:
      return { label: "Less than or equal", svg, shortLabel: "<=" };
    case FilterOperator.GREATER_THAN:
      return { label: "Greater than", svg, shortLabel: ">" };
    case FilterOperator.GREATER_THAN_EQUAL:
      return { label: "Greater than or equal", svg, shortLabel: ">=" };
    case FilterOperator.CONTAINS:
      return { label: "Contains", svg, shortLabel: "contains" };
    case FilterOperator.NOT_CONTAIN:
      return { label: "Not contain", svg, shortLabel: "not contain" };
    case FilterOperator.STARTS_WITH:
      return { label: "Starts with", svg, shortLabel: "starts with" };
    case FilterOperator.NOT_START_WITH:
      return { label: "Not start with", svg, shortLabel: "not start" };
    case FilterOperator.END_WITH:
      return { label: "Ends with", svg, shortLabel: "ends with" };
    case FilterOperator.NOT_END_WITH:
      return { label: "Not end with", svg, shortLabel: "not end with" };
    case FilterOperator.BETWEEN:
      return { label: "Between", svg, shortLabel: "between" };
    case FilterOperator.NOT_BETWEEN:
      return { label: "Not between", svg, shortLabel: "not between" };
    case FilterOperator.IN:
      return { label: "In", svg, shortLabel: "in" };
    case FilterOperator.NOT_IN:
      return { label: "Not in", svg, shortLabel: "not in" };
    case FilterOperator.BLANK:
      return { label: "Blank", svg, shortLabel: "blank" };
    case FilterOperator.NOT_BLANK:
      return { label: "Not blank", svg, shortLabel: "not blank" };
  }
};

export const getDefaultOperatorForOperandType = (operandType: OperandType) => {
  if (operandType === OperandType.STRING) return FilterOperator.CONTAINS;
  else if (operandType === OperandType.ENUM)
    return FilterOperator.IN;
  else return FilterOperator.EQUAL;
};

export const getOperatorsForOperandType = (
  operandType: OperandType,
  includeMultiOperand: boolean = true
) => {
  let operators: Array<FilterOperator> = [];
  switch (operandType) {
    case OperandType.STRING:
      operators = [
        FilterOperator.CONTAINS,
        FilterOperator.NOT_CONTAIN,
        FilterOperator.EQUAL,
        FilterOperator.NOT_EQUAL,
        FilterOperator.STARTS_WITH,
        FilterOperator.NOT_START_WITH,
        FilterOperator.END_WITH,
        FilterOperator.NOT_END_WITH,
        FilterOperator.LESS_THAN,
        FilterOperator.LESS_THAN_EQUAL,
        FilterOperator.GREATER_THAN,
        FilterOperator.GREATER_THAN_EQUAL,
        FilterOperator.BLANK,
        FilterOperator.NOT_BLANK
      ];
      if (includeMultiOperand) {
        operators = [
          ...operators,
          FilterOperator.IN,
          FilterOperator.NOT_IN,
          FilterOperator.BETWEEN,
          FilterOperator.NOT_BETWEEN
        ];
      }
      break;
    case OperandType.NUMBER:
      operators = [
        FilterOperator.EQUAL,
        FilterOperator.NOT_EQUAL,
        FilterOperator.GREATER_THAN,
        FilterOperator.GREATER_THAN_EQUAL,
        FilterOperator.LESS_THAN,
        FilterOperator.LESS_THAN_EQUAL,
        FilterOperator.BLANK,
        FilterOperator.NOT_BLANK
      ];
      if (includeMultiOperand) {
        operators = [
          ...operators,
          FilterOperator.IN,
          FilterOperator.NOT_IN,
          FilterOperator.BETWEEN,
          FilterOperator.NOT_BETWEEN
        ];
      }
      break;
    case OperandType.DATE:
    case OperandType.DATETIME:
    case OperandType.TIME:
      operators = [
        FilterOperator.EQUAL,
        FilterOperator.NOT_EQUAL,
        FilterOperator.GREATER_THAN,
        FilterOperator.GREATER_THAN_EQUAL,
        FilterOperator.LESS_THAN,
        FilterOperator.LESS_THAN_EQUAL,
        FilterOperator.BLANK,
        FilterOperator.NOT_BLANK
      ];
      if (includeMultiOperand) {
        operators = [
          ...operators,
          FilterOperator.IN,
          FilterOperator.NOT_IN,
          FilterOperator.BETWEEN,
          FilterOperator.NOT_BETWEEN
        ];
      }
      break;
    case OperandType.BOOLEAN:
      operators = [
        FilterOperator.EQUAL,
        FilterOperator.BLANK,
        FilterOperator.NOT_BLANK
      ];
      break;
    case OperandType.ENUM:
      operators = [
        FilterOperator.IN,
        FilterOperator.NOT_IN
      ];
      break;
  }
  return operators;
};

interface FilterConditions {
  [key: string]: ReadonlyArray<FilterCondition>;
}

type State = {
  filterConditions: FilterConditions;
  searchText: string;
  showRightBtn: boolean;
  showLeftBtn: boolean;
  scrollable: boolean;
  poperOpen: boolean;
  selectedFilterCondition: FilterCondition;
  operatorsVisibility: boolean;
  selectedSingleOperandFilterCondition?: SingleOperandFilterCondition;
  selectedChipReference?: any;
  selectedMultiOperandFilterCondition?: MultiOperandFilterCondition;
};

interface Props {
  placeholder?: string;
  inputProps?: InputBaseComponentProps;
  operands: FilterOperands;
  onConditionChanged?: (conditions: any) => void;
  popperSx?: SxProps<Theme>;
}
class CsSearchBox extends React.Component<Props, State> {
  static defaultProps: Props = {
    placeholder: "Search",
    inputProps: {
      "aria-label": "Search"
    },
    operands: {}
  };

  scrollerDiv?: HTMLDivElement;
  scrollingBtnMousePressing: boolean = false;
  searchBoxElement?: HTMLDivElement;
  searchInputElement?: HTMLInputElement;
  state: State = {
    filterConditions: {},
    searchText: "",
    showRightBtn: false,
    showLeftBtn: false,
    scrollable: false,
    poperOpen: false,
    selectedFilterCondition: { operand: this.props.operands[Object.keys(this.props.operands)[0]], operator: undefined },
    operatorsVisibility: false
  };

  constructor(props: Props) {
    super(props);
    this.onSearchBoxPropperClose = this.onSearchBoxPropperClose.bind(this);
    this.onSelectedConditionsChanged = this.onSelectedConditionsChanged.bind(this);
  }

  resolveScrollBtnVisibility() {
    this.setState({
      scrollable: this.isScrollable(),
      showLeftBtn: this.isScrollableToLeft(),
      showRightBtn: this.isScrollableToRight()
    });
  }

  isScrollable() {
    if (!this.scrollerDiv)
      return false;
    let { offsetWidth, scrollWidth } = this.scrollerDiv;
    return offsetWidth < scrollWidth;
  }

  isScrollableToLeft() {
    if (!this.scrollerDiv)
      return false;
    let { offsetWidth, scrollWidth, scrollLeft } = this.scrollerDiv;
    const scrollable = offsetWidth < scrollWidth;
    scrollLeft = !isFirefox
      ? scrollLeft
      : scrollLeft + scrollWidth - offsetWidth;
    return scrollable && scrollLeft > 0;
  }

  isScrollableToRight() {
    if (!this.scrollerDiv)
      return false;
    let { offsetWidth, scrollWidth, scrollLeft } = this.scrollerDiv;
    const scrollable = offsetWidth < scrollWidth;
    scrollLeft = !isFirefox
      ? scrollLeft
      : scrollLeft + scrollWidth - offsetWidth;
    return scrollable && scrollLeft < scrollWidth - offsetWidth;
  }

  createNewCondition(selectedFilterCondition: FilterCondition) {
    const { searchText, filterConditions, poperOpen } = this.state;
    let { operand, operator } = selectedFilterCondition;
    if (!operator) operator = getDefaultOperatorForOperandType(operand.type);
    let value: FilterValue | undefined = searchText;
    if (searchText === "") {
      switch (operator) {
        case FilterOperator.BLANK:
        case FilterOperator.NOT_BLANK:
          break;
        default:
          return;
      }
      switch (operand.type) {
        case OperandType.DATE:
        case OperandType.DATETIME:
        case OperandType.TIME:
        case OperandType.NUMBER:
          value = undefined;
      }
    } else if (operand.type === OperandType.NUMBER) {
      value = Number.parseFloat(searchText);
      if (isNaN(value)) {
        this.searchInputElement?.setSelectionRange(0, searchText.length);
        return;
      }
    }
    const newCondition: FilterCondition = {
      ...selectedFilterCondition,
      operator,
      value
    };
    let oldConditions = filterConditions[newCondition.operand.propName];
    oldConditions = oldConditions ? oldConditions : [];

    const newConditions = {
      ...filterConditions,
      [newCondition.operand.propName]: update(
        filterConditions[newCondition.operand.propName],
        {
          $set: [...oldConditions, newCondition]
        }
      )
    };
    this.setState({
      filterConditions: newConditions,
      searchText: "",
      poperOpen: false,
      operatorsVisibility: false
    });
    this.onFilterStateChanged(newConditions);
    setTimeout(() => {
      this.resolveScrollBtnVisibility();
      this.setState({ poperOpen });
    }, 100);
  }

  deleteCondition(index: number, deleteGroup: boolean = false) {
    const { filterConditions, poperOpen } = this.state;
    if (Object.keys(filterConditions).length === 0) return;
    const key = Object.keys(filterConditions).reverse()[index];
    let newConditions = { ...filterConditions };
    const condition = filterConditions[key];
    if (deleteGroup || condition.length <= 1) delete newConditions[key];
    else {
      newConditions = update(newConditions, {
        [key]: {
          $splice: [[condition.length - 1, 1]]
        }
      });
    }
    this.setState({ filterConditions: newConditions, poperOpen: false });
    this.onFilterStateChanged(newConditions);
    setTimeout(() => {
      this.resolveScrollBtnVisibility();
      this.setState({ poperOpen });
    }, 100);
  }

  handleUpDownKey(key: number, altKey: boolean) {
    const {
      selectedFilterCondition: { operator, operand },
      operatorsVisibility
    } = this.state;

    const { operands } = this.props;
    const operandsKey = Object.keys(operands);
    const operandIndex = operand ? operandsKey.indexOf(operand.propName) : 0;
    let newOperand: FilterOperand = operand;
    let newOperator: FilterOperator | undefined = operator;
    let newOperatorsVisibility = operatorsVisibility;
    if (!operand) {
      newOperand = operands[operandsKey[0]];
      newOperator = getDefaultOperatorForOperandType(newOperand.type);
    } else if (altKey) {
      if (!operand) {
        newOperand = operands[operandsKey[0]];
        newOperator = getDefaultOperatorForOperandType(newOperand.type);
      }
      newOperatorsVisibility = key === 40;
    } else if (!operatorsVisibility) {
      let newOperandIndex = 0;
      if (key === 40) {
        // down
        newOperandIndex =
          operandIndex < operandsKey.length - 1 ? operandIndex + 1 : 0;
      } else {
        // up
        newOperandIndex =
          operandIndex > 0 ? operandIndex - 1 : operandsKey.length - 1;
      }
      newOperand = operands[operandsKey[newOperandIndex]];
      newOperator = getDefaultOperatorForOperandType(newOperand.type);
    } else if (operatorsVisibility && !operator) {
      newOperator = getDefaultOperatorForOperandType(operand.type);
    } else {
      const operators = getOperatorsForOperandType(operand.type, false);
      const operatorIndex = operator ? operators.indexOf(operator) : 0;
      let newOperatorIndex = 0;
      if (key === 40) {
        newOperatorIndex =
          operatorIndex < operators.length - 1 ? operatorIndex + 1 : 0;
      } else {
        newOperatorIndex =
          operatorIndex > 0 ? operatorIndex - 1 : operators.length - 1;
      }
      newOperator = operators[newOperatorIndex];
    }
    this.setState({
      selectedFilterCondition: {
        ...this.state.selectedFilterCondition,
        operand: newOperand,
        operator: newOperator
      },
      operatorsVisibility: newOperatorsVisibility
    });
  }

  renderSingleOperandConditionChip(
    conditions: ReadonlyArray<FilterCondition>,
    propName: string
  ) {
    const { operands } = this.props;
    const operand = operands[propName];
    const values = conditions.map((condition, index) => {
      const { operator, value } = condition;
      return (
        <React.Fragment key={index}>
          <Typography variant="body2" sx={_sx.operatorInChip}>
            {getLabelForOperator(operator)?.shortLabel}
          </Typography>
          <Typography variant="body2">
            {operator === FilterOperator.BLANK || operator === FilterOperator.NOT_BLANK
              ? ""
              : `"${value}"`}
            {index < conditions.length - 1 ? "," : ""}
          </Typography>
        </React.Fragment>
      );
    });
    return (
      <React.Fragment>
        <Typography variant="subtitle2">{operand.name}: </Typography>
        {values}
      </React.Fragment>
    );
  }

  renderMultiOperandConditionChip(conditions: ReadonlyArray<FilterCondition>) {
    return conditions.map((condition, index) => {
      const { operator, operand } = condition;
      let operatorLabel = ":";
      switch (operand.type) {
        case OperandType.DATE:
        case OperandType.DATETIME:
          switch (operator) {
            case FilterOperator.BETWEEN:
              operatorLabel = ":";
              break;
            case FilterOperator.NOT_BETWEEN:
              operatorLabel = "not";
              break;
            default:
              operatorLabel = getLabelForOperator(operator)?.shortLabel ?? "";
              break;
          }
          break;
        default:
          operatorLabel = getLabelForOperator(operator)?.shortLabel ?? "";
          break;
      }

      return (
        <React.Fragment key={index}>
          <Typography variant="body2">{operand.name}</Typography>
          <Typography variant="body2" sx={_sx.operatorInChip}>
            {operatorLabel}
          </Typography>
          <Typography variant="body2">
            {operator === FilterOperator.BLANK || operator === FilterOperator.NOT_BLANK
              ? ""
              : `${stringifyFilterValues(condition)}`}
          </Typography>

          {index < conditions.length - 1 ? (
            <Typography sx={_sx.orSeparator} variant="body2">
              or
            </Typography>
          ) : null}
        </React.Fragment>
      );
    });
  }

  onFilterStateChanged(filterConditions: FilterConditions) {
    const { onConditionChanged } = this.props;
    if (!onConditionChanged) return;
    const keys = Object.keys(filterConditions);
    const andConditions = keys.map(key => {
      const orConditions = filterConditions[key].map(condition => {
        return this.transformFilterConditionToGraphqlCondition(condition);
      });
      return { oR: orConditions };
    });
    onConditionChanged(andConditions);
  }

  transformFilterConditionToGraphqlCondition(condition: FilterCondition) {
    const { operand, operator } = condition;
    var value = condition.value;
    const whereInput: any = {};
    const props = [operand.propName];
    if (value && operand.type === OperandType.DATE) {
      if (operator !== FilterOperator.BETWEEN && operator !== FilterOperator.NOT_BETWEEN) {
        value = formatDate(value as Date, "yyyy-MM-dd");
      }
    }
    const leafProp = operand.propName;
    switch (operator) {
      case FilterOperator.EQUAL:
        whereInput[`${leafProp}`] = value;
        break;
      case FilterOperator.NOT_EQUAL:
        whereInput[`${leafProp}_Not`] = value;
        break;
      case FilterOperator.IN:
        whereInput[`${leafProp}_In`] = value;
        break;
      case FilterOperator.NOT_IN:
        whereInput[`${leafProp}_NotIn`] = value;
        break;
      case FilterOperator.LESS_THAN:
        whereInput[`${leafProp}_Lt`] = value;
        break;
      case FilterOperator.GREATER_THAN:
        whereInput[`${leafProp}_Gt`] = value;
        break;
      case FilterOperator.LESS_THAN_EQUAL:
        whereInput[`${leafProp}_Lte`] = value;
        break;
      case FilterOperator.GREATER_THAN_EQUAL:
        whereInput[`${leafProp}_Gte`] = value;
        break;
      case FilterOperator.CONTAINS:
        whereInput[`${leafProp}_Contains`] = value;
        break;
      case FilterOperator.NOT_CONTAIN:
        whereInput[`${leafProp}_NotContains`] = value;
        break;
      case FilterOperator.STARTS_WITH:
        whereInput[`${leafProp}_StartsWith`] = value;
        break;
      case FilterOperator.NOT_START_WITH:
        whereInput[`${leafProp}_NotStartsWith`] = value;
        break;
      case FilterOperator.END_WITH:
        whereInput[`${leafProp}_EndsWith`] = value;
        break;
      case FilterOperator.NOT_END_WITH:
        whereInput[`${leafProp}_NotEndsWith`] = value;
        break;
      case FilterOperator.BLANK:
        whereInput[`${leafProp}_IsBlank`] = true;
        break;
      case FilterOperator.NOT_BLANK:
        whereInput[`${leafProp}_IsBlank`] = false;
        break;
      case FilterOperator.BETWEEN:
        switch (operand.type) {
          case OperandType.NUMBER:
            whereInput[`${leafProp}_Gte`] = (value as any)[0];
            whereInput[`${leafProp}_Lte`] = (value as any)[1];
            break;
          case OperandType.DATE:
            whereInput[
              `${leafProp}_Gte`
            ] = formatDate((value as DateRangeWithLabel).value.from, "yyyy-MM-dd");
            whereInput[
              `${leafProp}_Lte`
            ] = formatDate( (value as DateRangeWithLabel).value.to,"yyyy-MM-dd");
            break;
          case OperandType.DATETIME:
            whereInput[
              `${leafProp}_Gte`
            ] = (value as DateRangeWithLabel).value.from;
            whereInput[
              `${leafProp}_Lte`
            ] = (value as DateRangeWithLabel).value.to;
            break;
        }
        break;
      case FilterOperator.NOT_BETWEEN:
        switch (operand.type) {
          case OperandType.NUMBER:
            whereInput["OR"] = [
              { [`${leafProp}_Lt`]: (value as any)[0] },
              { [`${leafProp}_Gt`]: (value as any)[1] }
            ];
            break;
          case OperandType.DATE:
            whereInput["OR"] = [
              {
                [`${leafProp}_Lt`]: formatDate((value as DateRangeWithLabel).value.from,"yyyy-MM-dd")
              },
              {
                [`${leafProp}_Gt`]: formatDate((value as DateRangeWithLabel).value.to,"yyyy-MM-dd")
              }
            ];
            break;
          case OperandType.DATETIME:
            whereInput["OR"] = [
              {
                [`${leafProp}_Lt`]: (value as DateRangeWithLabel)
                  .value.from
              },
              {
                [`${leafProp}_Gt`]: (value as DateRangeWithLabel)
                  .value.to
              }
            ];
            break;
        }
        break;
    }

    const buildWhere = (props: string[], leafWhere: any, currentIndex: number): any => {
      if (currentIndex === props.length - 1) {
        return leafWhere;
      } else {
        var p = props[currentIndex];
        p = p.charAt(0).toLowerCase() + p.substring(1);
        return {
          [p]: buildWhere(props, leafWhere, currentIndex + 1)
        };
      }
    };
    return buildWhere(props, whereInput, 0);
  }

  onSelectedConditionsChanged(
    newCondition: FilterCondition,
    operatorsVisibility: boolean,
    createCondition: boolean
  ) {
    const {
      selectedFilterCondition: { value }
    } = this.state;
    const selectedFilterCondition = {
      ...newCondition,
      value
    };
    this.setState({
      selectedFilterCondition,
      operatorsVisibility
    });
    if (createCondition)
      this.createNewCondition(selectedFilterCondition);
  }

  onSearchBoxPropperClose() {
    if (document.activeElement !== this.searchInputElement)
      this.setState({ poperOpen: false });
  }

  render() {
    const {
      placeholder,
      inputProps,
      operands,
      popperSx
    } = this.props;
    const {
      filterConditions,
      searchText,
      showLeftBtn,
      showRightBtn,
      scrollable,
      poperOpen,
      selectedFilterCondition,
      operatorsVisibility,
      selectedSingleOperandFilterCondition,
      selectedMultiOperandFilterCondition
    } = this.state;
    return (
      <Box sx={_sx.layout}>
        <AutoSizer disableHeight>
          {size => (
            <Box style={{ width: size.width }} sx={_sx.root}>
              {scrollable ? (
                <ButtonBase
                  sx={_sx.scrollButton}
                  onMouseDown={() => {
                    this.scrollingBtnMousePressing = true;
                    if (!this.isScrollableToLeft()) return;
                    const scroll = () => {
                      if (this.scrollerDiv)
                        this.scrollerDiv.scrollLeft = this.scrollerDiv.scrollLeft - 20;
                      if (!this.isScrollableToLeft())
                        this.scrollingBtnMousePressing = false;
                      if (this.scrollingBtnMousePressing)
                        setTimeout(scroll, 100);
                    };
                    scroll();
                  }}
                  onMouseUp={() => {
                    this.scrollingBtnMousePressing = false;
                  }}
                  disabled={!showLeftBtn}
                >
                  <ChevronLeft sx={_sx.scrollButtonIcon} />
                </ButtonBase>
              ) : null}
              <Box
                sx={_sx.chipScroller}
                style={{ maxWidth: size.width - 150 }}
                ref={(ref: any) => {
                  this.scrollerDiv = ref;
                }}
                onScroll={this.resolveScrollBtnVisibility.bind(this)}
              >
                <Box sx={_sx.chips}>
                  {Object.keys(filterConditions)
                    .reverse()
                    .map((key, index) => {
                      const conditions = filterConditions[key];
                      if (conditions.length > 0) {
                        return (
                          <Chip
                            color={
                              selectedSingleOperandFilterCondition &&
                                selectedSingleOperandFilterCondition.operand
                                  .propName === key
                                ? "primary"
                                : "default"
                            }
                            onClick={e => {
                              if (isNaN(Number.parseInt(key))) {
                                const condition: SingleOperandFilterCondition = {
                                  operand: operands[key],
                                  conditions
                                };
                                this.setState({
                                  selectedSingleOperandFilterCondition: condition,
                                  selectedChipReference: e.target
                                });
                              } else {
                                const condition: MultiOperandFilterCondition = {
                                  key,
                                  conditions
                                };
                                this.setState({
                                  selectedMultiOperandFilterCondition: condition
                                });
                              }
                            }}
                            key={index}

                            size="small"
                            label={
                              isNaN(Number.parseInt(key))
                                ? this.renderSingleOperandConditionChip(
                                  conditions,
                                  key
                                )
                                : this.renderMultiOperandConditionChip(
                                  conditions
                                )
                            }
                            sx={_sx.chip}
                            onDelete={() => {
                              this.deleteCondition(index, true);
                            }}
                          />
                        );
                      } else return null;
                    })}
                </Box>
              </Box>
              {scrollable ? (
                <ButtonBase
                  sx={_sx.scrollButton}
                  disabled={!showRightBtn}
                  onMouseDown={() => {
                    this.scrollingBtnMousePressing = true;
                    if (!this.isScrollableToRight()) return;
                    const scroll = () => {
                      if (this.scrollerDiv)
                        this.scrollerDiv.scrollLeft = this.scrollerDiv.scrollLeft + 20;
                      if (!this.isScrollableToRight())
                        this.scrollingBtnMousePressing = false;
                      if (this.scrollingBtnMousePressing)
                        setTimeout(scroll, 100);
                    };
                    scroll();
                  }}
                  onMouseUp={() => {
                    this.scrollingBtnMousePressing = false;
                  }}
                >
                  <ChevronRight sx={_sx.scrollButtonIcon} />
                </ButtonBase>
              ) : null}
              <InputBase
                ref={(ref: any) => {
                  this.searchBoxElement = ref;
                }}
                inputRef={ref => {
                  this.searchInputElement = ref;
                }}
                onFocus={() => {
                  setTimeout(() => {
                    this.setState({ poperOpen: true });
                  }, 100);
                }}
                sx={_sx.input}
                placeholder={placeholder}
                inputProps={inputProps}
                value={searchText}
                onChange={e => {
                  this.setState({ searchText: e.target.value });
                }}
                onKeyDown={e => {
                  switch (e.keyCode) {
                    case 13:
                      this.createNewCondition(selectedFilterCondition);
                      break;
                    case 8:
                      if (searchText === "") this.deleteCondition(0);
                      break;
                    case 38: // up
                    case 40: // down
                      this.handleUpDownKey(e.keyCode, e.altKey);
                      break;
                  }
                }}
              />
              <IconButton
                color="primary"
                size="small"
                onClick={() => {
                  const count = Object.keys(filterConditions).length;
                  const key = (count * -1).toString();
                  const operand = operands[Object.keys(operands)[0]];
                  const newConditions: MultiOperandFilterCondition = {
                    key,
                    conditions: [
                      {
                        operand: operand,
                        operator: getDefaultOperatorForOperandType(operand.type)
                      }
                    ]
                  };
                  this.setState({
                    selectedMultiOperandFilterCondition: newConditions
                  });
                }}
              >
                <ZoomIn />
              </IconButton>
            </Box>
          )}
        </AutoSizer>
        <CsSearchBoxPoper
          sx={popperSx}
          onClose={this.onSearchBoxPropperClose}
          operatorsVisibility={operatorsVisibility}
          open={poperOpen}
          operands={operands}
          anchorEl={this.searchBoxElement}
          selectedFilterCondition={selectedFilterCondition}
          onSelectedConditionChanged={this.onSelectedConditionsChanged}
        />
        {selectedMultiOperandFilterCondition ? <CsMultiOperandFilterEditor
          availableOperands={operands}
          filterCondition={selectedMultiOperandFilterCondition}
          open={selectedMultiOperandFilterCondition !== undefined}
          onReject={() => {
            this.setState({ selectedMultiOperandFilterCondition: undefined });
          }}
          onAccept={(value: MultiOperandFilterCondition) => {
            const { key, conditions } = value;
            let newConditions = { ...filterConditions };
            if (conditions.length === 0) {
              delete newConditions[key];
            } else if (filterConditions[key]) {
              newConditions[key] = conditions;
            } else {
              newConditions = {
                ...filterConditions,

                [key]: conditions
              };
            }
            this.setState({
              filterConditions: newConditions,
              selectedMultiOperandFilterCondition: undefined
            });
            this.onFilterStateChanged(newConditions);
          }}
        /> : null}

        {
          selectedSingleOperandFilterCondition ? <CsSingleOperandFilterEditor
            filterCondition={selectedSingleOperandFilterCondition}
            open={selectedSingleOperandFilterCondition !== undefined}
            onReject={() => {
              this.setState({
                selectedSingleOperandFilterCondition: undefined
              });
            }}
            onAccept={(value: SingleOperandFilterCondition) => {
              const { operand, conditions } = value;
              const newConditions = update(filterConditions, {
                [operand.propName]: {
                  $set: conditions
                }
              });
              this.setState({
                filterConditions: newConditions,
                selectedSingleOperandFilterCondition: undefined
              });
              this.onFilterStateChanged(newConditions);
            }}
          /> : null
        }
      </Box>
    );
  }
}

export default CsSearchBox;
