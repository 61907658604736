import { useQuery } from "@apollo/client/react/hooks/useQuery";
import IconButton from "@mui/material/IconButton/IconButton";
import accounting from "accounting";
import * as React from "react";
import { productWithoutHistoryQuery } from "./graphql";
import { ProductWithoutHistory, ProductWithoutHistoryVariables, ProductWithoutHistory_productQuery_product_prices } from "./__generated__/ProductWithoutHistory";
import EditIcon from "@mui/icons-material/Edit";
import TextField from "@mui/material/TextField/TextField";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import { ProductType } from "../../types/global-types";
import NumberEditor from "../../components/NumberEditor";
import { SxProps, Theme } from "@mui/material/styles";
type UnitPriceEditorProps = {
    value: number;
    productId: string;
    onChanged: (price: number) => void;
    editable: boolean;
    sx?: SxProps<Theme>
    roundedDecimalPlace?: number;
};
const _sx = {
    menuItem: {
        textAlign: "right"
    }
};

function UnitPriceEditor({
    value,
    productId,
    onChanged,
    editable,
    roundedDecimalPlace,
    sx
}: UnitPriceEditorProps) {
    const [isEditMode, setIsEditMode] = React.useState(false);
    const { data, loading } = useQuery<ProductWithoutHistory, ProductWithoutHistoryVariables>(productWithoutHistoryQuery, {
        variables: {
            id: productId,
        },
        skip: !isEditMode,
        fetchPolicy: "network-only",
    });
    const product = data?.productQuery?.product;
    const currentValue = React.useMemo(() => {
        const index = product?.prices?.findIndex((v) => v.price === value) ?? -1;
        var currentValue: ProductWithoutHistory_productQuery_product_prices = {
            id: "current",
            price: value,
            price_list: { id: "current", name: "" },
        };
        if (product?.prices[index])
            currentValue = product?.prices[index];
        return currentValue;
    }, [product?.prices, value]);

    const handleSetEditMode = React.useCallback(() => { setIsEditMode(true); }, [setIsEditMode]);

    const options = React.useMemo(() => {
        var options = product?.prices ?? [];
        if (currentValue.id == "current") options = [currentValue, ...options];
        return Array.from(options).reverse();
    }, [product?.prices, currentValue]);
    if (!editable || !isEditMode || loading) {
        return <>{accounting.formatNumber(value, 0)}
            {!isEditMode && !loading && editable ? <IconButton onClick={handleSetEditMode} color="secondary" aria-label="Edit Price" component="span">
                <EditIcon />
            </IconButton> : null}
            {
                loading ? <CircularProgress size={24} color="secondary" /> : null
            }
        </>;
    } else {
        if (!product || product?.type === ProductType.service) {
            return (
                <NumberEditor
                    size="small"
                    onFocus={() => {
                        setIsEditMode(true);
                    }}
                    autoFocus
                    numberPrecision={0}
                    sx={sx}
                    value={value}
                    onValidated={(v) => { onChanged(v ?? 0) }}
                    roundedDecimalPlace={roundedDecimalPlace}
                />
            );
        } else {
            return (
                <TextField
                    size="small"
                    onFocus={() => {
                        setIsEditMode(true);
                    }}
                    fullWidth
                    select
                    value={value}
                    onChange={(e) => {
                        onChanged(Number.parseInt(e.target.value));
                    }}
                    sx={Object.assign({}, sx, _sx.menuItem)}
                >
                    {options.map((option) => (
                        <MenuItem value={option.price ?? 0} key={option.id} >{`${option.price_list?.name
                            } ${accounting.formatNumber(option.price ?? 0, 0)}`}</MenuItem>
                    ))}
                </TextField>
            );
        }
    }

}

export default UnitPriceEditor;