import Container from "@mui/material/Container";
import * as React from "react";
import useHasPermission from "./useHasPermission";
export type AuthorizeProps = {
    policy: string,
    children:JSX.Element
};
function Authorize({ policy, children }: AuthorizeProps) {
    if (useHasPermission(policy))
        return children;
    else
        return <Container><h3>403</h3><h4>Forbidden</h4><p>You are not allowed to view this page.</p></Container>;
}

export default Authorize;