import { useMutation } from "@apollo/client/react/hooks/useMutation";
import * as React from "react";
import EditDialog from "./EditDialog";
import { registerUserMutation, usersQuery } from "./graphql";
import {
  RegisterUser,
  RegisterUserVariables,
} from "./__generated__/RegisterUser";
import {
  Users,
  UsersVariables,
  Users_userQuery_users_edges,
} from "./__generated__/Users";
import update from "immutability-helper";
export type CreateDialogProps = {
  open: boolean;
  onClose: () => void;
  variables: UsersVariables;
};
function CreateDialog({
  onClose,
  variables: fetchVariables,
  ...props
}: CreateDialogProps) {
  const [registerUser, { loading }] = useMutation<
    RegisterUser,
    RegisterUserVariables
  >(registerUserMutation);
  const onSubmit = React.useCallback(
    async ({
      code,
      fullName,
      active,
      odooUserName,
      odooPassword,
      companyId,
      companyName,
    }: Users_userQuery_users_edges) => {
      const variables: RegisterUserVariables = {
        user: {
          code,
          fullName,
          active,
          odooUserName: odooUserName!,
          odooPassword: odooPassword!,
          companyId,
          companyName,
        },
      };
      await registerUser({
        variables,
        update: (cache, { data }) => {
          const saved = data?.userMutation?.registerUser;
          const cached = cache.readQuery<Users, UsersVariables>({
            query: usersQuery,
            variables: fetchVariables,
          });
          const updated = update(cached, {
            userQuery: {
              users: {
                edges: {
                  $push: [saved as any],
                },
                pageInfo: {
                  rowCount: {
                    $set:
                      (cached?.userQuery?.users?.pageInfo?.rowCount ?? 0) + 1,
                  },
                },
              },
            },
          });
          cache.writeQuery<Users, UsersVariables>({
            query: usersQuery,
            variables: fetchVariables,
            data: updated!,
          });
        },
      });
      onClose();
    },
    [registerUser, fetchVariables, onClose]
  );
  return (
    <EditDialog
      onClose={onClose}
      onSubmit={onSubmit}
      loading={loading}
      {...props}
      initialValue={{
        code: "",
        fullName: "",
        odooUserName: "",
        odooPassword: "",
        active: true,
        id: "",
        defaultAdmin: false,
        permissions: [],
        companyId: 0,
        companyName: "",
      }}
    />
  );
}

export default CreateDialog;
