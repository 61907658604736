import * as React from "react";
import { useSaleOrderOutletContext } from ".";
import SaleOrderEditControl from "./SaleOrderEditControl";


function SaleOrderRenew(){
    const {saleOrdersVariables} = useSaleOrderOutletContext();
    return (<SaleOrderEditControl saleOrdersVariables={saleOrdersVariables} loading={false}/>);
}

export default SaleOrderRenew;