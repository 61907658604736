import { InvoiceStatus } from "../../types/global-types";

function resolveInvoiceStatusIndicator(status: InvoiceStatus) {
  switch (status) {
    case InvoiceStatus.invoiced:
      return "Fully Invoiced";

    case InvoiceStatus.no:
      return "Nothing to Invoice";

    case InvoiceStatus.toinvoice:
      return "To Invoice";

    case InvoiceStatus.upselling:
      return "Upselling";
  }
}

export default resolveInvoiceStatusIndicator;
