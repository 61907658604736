import List from "@mui/material/List/List";
import ListItem from "@mui/material/ListItem/ListItem";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction/ListItemSecondaryAction";
import ListItemText from "@mui/material/ListItemText/ListItemText";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select/Select";
import accounting from "accounting";
import * as React from "react";
import { format } from "date-fns/fp";
import ListItemIcon from "@mui/material/ListItemIcon/ListItemIcon";
import IconButton from "@mui/material/IconButton/IconButton";
import EditIcon from '@mui/icons-material/Search';
import WarningIcon from "@mui/icons-material/Warning";
import { ValuationEditDialog } from "../../product/ProductDetailSidebar";
import { useQuery } from "@apollo/client/react/hooks/useQuery";
import { CogsLines, CogsLinesVariables, CogsLines_productQuery_product_purchase_history, CogsLines_productQuery_product_valuation_in, CogsLines_saleOrderQuery_orderLine_costs } from "../__generated__/CogsLines";
import { costLinesQuery,setCogsForOrderLineMutation } from "../graphql";
import Toolbar from "@mui/material/Toolbar/Toolbar";
import Tooltip from "@mui/material/Tooltip/Tooltip";
import ChevronRight from "@mui/icons-material/ChevronRight";
import { SetCogsForOrderLine, SetCogsForOrderLineVariables } from "../__generated__/SetCogsForOrderLine";
import { useMutation } from "@apollo/client/react/hooks/useMutation";
import Dialog from "@mui/material/Dialog/Dialog";
import DialogTitle from "@mui/material/DialogTitle/DialogTitle";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import DialogActions from "@mui/material/DialogActions/DialogActions";
import Button from "@mui/material/Button/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import NumberEditor from "../../../components/NumberEditor";
import ListItemButton from "@mui/material/ListItemButton";
import { RootState } from "../../../app/store";
import { useSelector } from "react-redux";
import Divider from "@mui/material/Divider";
const _sx={
    root: {
        width: 400,
        padding: 2,
        display: "flex",
        flexDirection: "column",
        flexWrap: "nowrap"
    },
    textAlignRight: {
        textAlign: "right"
    },
    fill: {
        display: "flex",
        flexDirection: "column",
        flex: 1
    },
    position_relative: {
        position: "relative"
    },
    historyList: {
        position: "absolute",
        width: "100%"
    },
    scroller: {
        overflow: "auto"
    },
    fieldLabel: {
        fontWeight: "bold",
        marginRight: 1,
        width: 150,
        display: "inline-block",
        color: "inherit"
    },
    fieldValue: {
        display: "inline-block"
    },
    field: {
        padding: .5,
    },
};
function PurchasePriceHistory({ history }: { history: CogsLines_productQuery_product_purchase_history[] }) {
    return (
        <Box sx={{..._sx.fill,..._sx.position_relative,..._sx.scroller}} ><List sx={_sx.historyList}  >
            {history?.map((h) => {
                return (
                    <ListItem key={h.id} >
                        <ListItemText primary={h.order_id.display_name} secondary={format("dd/MM/yyyy", new Date(h.date_order))} />
                        <ListItemSecondaryAction>
                            <ListItemText primary={`${accounting.formatNumber(h.price_unit, 0)} x ${accounting.formatNumber(h.product_uom_qty, 0)}`} />
                        </ListItemSecondaryAction>
                    </ListItem>
                );
            })}
        </List></Box>);
}

type CostEditDialogProps={
    cost: CogsLines_saleOrderQuery_orderLine_costs,
    open: boolean,
    onClose:()=>void,
    order_line_id:number
};

function CostEditDialog({ cost, open, onClose, order_line_id }: CostEditDialogProps) {    
    const odooUrl = useSelector((state:RootState)=>state.site.odooServerUrl);
    if (!cost)
        return null;
    const { product_id: { display_name: product },description,final_cost, create_date, quantity, final_value, stock_move_id,origin_layers } = cost;
    const match = product?.match(/\[([^\]]+)\]/);
    const model_no = match? match[1]: "";
    const product_name = product?.replace(`[${model_no}]`, '');
    return (<Dialog open={open} fullWidth maxWidth="sm">
        <DialogTitle>Cost of Good Sold</DialogTitle>
        <DialogContent>
            <Box sx={_sx.field}>
                <Typography sx={_sx.fieldLabel}>Model No:</Typography>
                <Typography sx={_sx.fieldValue}>{model_no}</Typography>
            </Box>
            <Box sx={_sx.field}>
                <Typography sx={_sx.fieldLabel}>Name:</Typography>
                <Typography  sx={_sx.fieldValue}>{product_name}</Typography>
            </Box>
            <Box sx={_sx.field}>
                <Typography sx={_sx.fieldLabel}>Date:</Typography>
                <Typography sx={_sx.fieldValue}>{format("dd/MM/yyyy", new Date(create_date))}</Typography>
            </Box>
            <Box sx={_sx.field}>
                <Typography sx={_sx.fieldLabel}>Stock Move:</Typography>
                <Typography sx={_sx.fieldValue}>{stock_move_id?.display_name}</Typography>
            </Box>
            <Box sx={_sx.field}>
                <Typography sx={_sx.fieldLabel}></Typography>
                <Typography sx={_sx.fieldValue}>{description}</Typography>
            </Box>
            <Box sx={_sx.field}>
                <Typography sx={_sx.fieldLabel}>Delivered Qty:</Typography>
                <Typography sx={_sx.fieldValue}>{accounting.formatNumber(quantity??0)}</Typography>
            </Box>
            <Box sx={_sx.field}>
                <Typography sx={_sx.fieldLabel}>Unit Cost:</Typography>
                <Typography sx={_sx.fieldValue}>{accounting.formatNumber((final_cost??0) * -1,2)}</Typography>
            </Box>
            <Box sx={_sx.field}>
                <Typography sx={_sx.fieldLabel}>Total Cost:</Typography>
                <Typography sx={_sx.fieldValue}>{accounting.formatNumber(final_value??0,2)}</Typography>
            </Box>
            <List>
                {
                    origin_layers?.map(l=>(<ListItemButton onClick={()=>{
                        window.open(`${odooUrl}/web#id=${l.origin.id}&model=stock.valuation.layer&view_type=form`,"_blank");
                    }} key={l.id} >
                        <ListItemText secondary={l.origin.stock_move_id?.display_name} primary={format("dd/MM/yyyy hh:mm a", new Date(l.origin.create_date))} />
                        <ListItemSecondaryAction>
                            <ListItemText sx={{textAlign:"right"}} primary={`${accounting.formatNumber((l.quantity??0) * -1, 0)} x ${accounting.formatNumber(l.origin.final_cost??0, 2)} = ${accounting.formatNumber((l.origin.final_cost??2) * -1 * (l.quantity??0), 2)}`} secondary={`${accounting.formatNumber(l.origin.quantity??0, 0)} = ${accounting.formatNumber(l.origin.final_value??0, 2)}`}  />
                        </ListItemSecondaryAction>
                    </ListItemButton>))
                }
            </List>
        </DialogContent>
        <DialogActions>    
            <Button onClick={onClose}>OK</Button>
        </DialogActions>
    </Dialog>)
}

function Cogs({ costs,order_line_id }: { costs: CogsLines_saleOrderQuery_orderLine_costs[],order_line_id: number}) {
    const odooUrl = useSelector((state:RootState)=>state.site.odooServerUrl);
    const [selectedIndex, setSelectedIndex] = React.useState(-1);
    const closeDialog = React.useCallback(() => {
        setSelectedIndex(-1);
    }, [setSelectedIndex]);
    if (!costs)
        return null;
    return (
        <Box sx={{..._sx.fill,..._sx.position_relative,..._sx.scroller}} ><List sx={_sx.historyList} >
            {costs?.map((v, i) => {
                return (
                    <ListItemButton onClick={()=>{
                        window.open(`${odooUrl}/web#id=${v.id}&model=stock.valuation.layer&view_type=form`,"_blank");
                    }} key={v.id} >
                        <ListItemIcon>
                            <IconButton aria-label="Edit" onClick={(e) => {  setSelectedIndex(i); e.preventDefault();e.stopPropagation(); }}>
                                <EditIcon />
                            </IconButton>
                        </ListItemIcon>
                        <ListItemText  secondary={v.stock_move_id?.display_name} primary={format("dd/MM/yyyy hh:mm a", new Date(v.create_date))} />
                        <ListItemSecondaryAction>
                            <ListItemText sx={{ textAlign: "right", backgroundColor:"#999", paddingLeft:.5,paddingRight:.5 }} primary={`${accounting.formatNumber((v.final_value??0)/(v.quantity??1), 2)} x ${accounting.formatNumber(v.quantity??0, 0)}`} secondary={`= ${accounting.formatNumber(v.final_value??0, 2)}`} />
                        </ListItemSecondaryAction>
                    </ListItemButton>
                );
            })}
        </List><CostEditDialog order_line_id={order_line_id} cost={costs[selectedIndex]} open={selectedIndex > -1} onClose={closeDialog} /></Box>);
}
function Valuation({ valuation }: { valuation: CogsLines_productQuery_product_valuation_in[] }) {
    const [selectedIndex, setSelectedIndex] = React.useState(-1);
    const closeDialog = React.useCallback(() => {
        setSelectedIndex(-1);
    }, [setSelectedIndex]);
    if (!valuation)
        return null;
    return (
        <Box sx={{..._sx.fill,..._sx.position_relative,..._sx.scroller}}><List sx={_sx.historyList} >
            {valuation?.map((v, i) => {
                return (
                    <>
                    <ListItemButton   key={v.id} onClick={() => { setSelectedIndex(i) }}>
                        <ListItemText  secondary={v.stock_move_name} primary={format("dd/MM/yyyy hh:mm a", new Date(v.create_date))} />
                        <ListItemSecondaryAction>
                            <ListItemText color="secondary" sx={{ textAlign: "right", backgroundColor:"#999", paddingLeft:.5,paddingRight:.5 }} primary={`${accounting.formatNumber(v.remaining_qty ?? 0, 0)} x ${accounting.formatNumber(v.final_cost ?? 0, 2)}`} secondary={`${accounting.formatNumber(v.quantity ?? 0, 0)} = ${accounting.formatNumber(v.final_value ?? 0,2)}`} />
                            {v.no_cost ? <ListItemIcon>
                                <WarningIcon />
                            </ListItemIcon> : null}
                        </ListItemSecondaryAction>
                    </ListItemButton>
                    {!v?.remaining_qty? null: <Divider/>}
                    </>
                );
            })}
        </List><ValuationEditDialog valuation={valuation[selectedIndex]} open={selectedIndex > -1} onClose={closeDialog} /></Box>);
}

type SideBarProps = {
    order_line_id: number,
    product_id: number,
    closeSidebar:()=>void
};

function Sidebar({ order_line_id, product_id, closeSidebar }: SideBarProps) {
    const { data } = useQuery<CogsLines, CogsLinesVariables>(costLinesQuery, {
        variables: {
            order_line_id: order_line_id.toString(),
            product_id: product_id.toString()
        }
    });
    const product = data?.productQuery?.product;
    const saleOrderLine = data?.saleOrderQuery?.orderLine;
    const [selectedTab, setSelectedTab] = React.useState("general");
    const handleSelectedTabChanged = React.useCallback((_: React.ChangeEvent, value: string) => {
        setSelectedTab(value);
    }, [setSelectedTab]);
    const [currentListing, setCurrentListing] = React.useState("cogs");

    const handlePriceListChange = React.useCallback((e:SelectChangeEvent<string>) => { setCurrentListing(e.target.value) }, [setCurrentListing]);
    if (!product)
        return null;
    return (<Box sx={_sx.root}>
        <Toolbar>
            <Tooltip title="Close Sidebar">
                <IconButton
                    onClick={closeSidebar}
                >
                    <ChevronRight />
                </IconButton>
            </Tooltip>
        </Toolbar>
        <Select
            size="small"
            fullWidth
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={currentListing}
            onChange={handlePriceListChange}
        >
            <MenuItem value="cogs">Cost of Sale</MenuItem>
            <MenuItem value="purchase">Purchase Price</MenuItem>
            <MenuItem value="valuation">Valuation</MenuItem>
        </Select>
        {
            (() => {
                switch (currentListing) {
                    case "cogs":
                        return <Cogs costs={saleOrderLine?.costs??[]} order_line_id={order_line_id}/>
                    case "valuation":
                        return <Valuation valuation={product?.valuation_in} />
                    case "purchase":
                        return <PurchasePriceHistory history={product?.purchase_history} />
                }
            })()
        }
    </Box>);
}

export default Sidebar;