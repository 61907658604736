import {  createSlice, PayloadAction } from "@reduxjs/toolkit";
export type UserSession = Readonly<{
    accessToken?: string;
    userId?: string;
    code?: string;
    fullName?: string;
    authMode?: number;
    odooUserName?: string;
    cashAccountIdForCashSale?: number;
    odooUserId?: number;
    userRole?: string[];
    defaultAdmin?: boolean;
    validFrom?: string;
    validTo?: string;
    authenticated: boolean;
}>;

export const userSessionInitialState: UserSession = {
  authenticated: false,
};



export const userSessionSlice = createSlice({
  name: "userSession",
  initialState: userSessionInitialState,
  reducers: {
    setUserSession: (state, action: PayloadAction<UserSession>) => {
      localStorage.setItem("token", action.payload.accessToken ?? "");
      return action.payload;
    },
  },
});
export const { setUserSession } = userSessionSlice.actions;
export default userSessionSlice.reducer;
