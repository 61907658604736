import * as React from "react";
import accounting from "accounting";
import { TextFieldProps } from "@mui/material/TextField";
import TextField from "@mui/material/TextField";

const _sx = {
  input: {
    textAlign: "right",
  },
};

function moveDecimal(n: number, l: number) {
  let v = l > 0 ? n * Math.pow(10, l) : n / Math.pow(10, l * -1);
  return v;
}

type OwnProps = {
  validateOnBlur?: boolean;
  retainFocusOnError?: boolean;
  onValidating?: (value?: number) => string;
  onValidated: (value?: number) => void;
  selectAllOnFocus?: boolean;
  numberPrecision?: number;
  value?: number;
  inputElementType?: "InputBase" | "Input" | "TextField";
  onChanged?: (value: number) => void;
  showErrorAsHelperText?: boolean;
  innerReference?: (ref: React.Ref<HTMLElement>) => void;
  inputReference?: (ref: HTMLInputElement) => void;
  roundedDecimalPlace?: number;
};
function round(num: number, decimalPlaces: number) {
  if (!decimalPlaces)
    return num;
  const factor = Math.pow(10, decimalPlaces);
  return Math.round(num * factor) / factor;
}
export type NumberEditorProps = OwnProps & TextFieldProps;
function NumberEditor(props: NumberEditorProps) {
  const {
    roundedDecimalPlace = 0,
    showErrorAsHelperText,
    error,
    helperText,
    onValidating,
    onValidated,
    selectAllOnFocus,
    numberPrecision,
    onBlur,
    value,
    onFocus,
    onKeyDown,
    onKeyPress,
    inputRef,
    inputReference,
    onChanged,
    inputProps,
    inputElementType,
    retainFocusOnError,
    validateOnBlur,
    ...textFieldProps
  } = props;

  const textField = React.useRef<HTMLInputElement | null>(null);
  const [active, setActive] = React.useState<boolean>(false);
  const [draft, setDraft] = React.useState<string>(
    value === 0 || value ? value.toString() : ""
  );
  const shouldValidateOnBlur = React.useRef<boolean>(true);
  React.useEffect(() => {
    setDraft(value === 0 || value ? value.toString() : "");
  }, [value]);

  const num = React.useMemo(() => {
    if (draft == null || draft === "" || draft === "-") return undefined;
    else {
      const n = Number.parseFloat(draft);
      return isNaN(n) ? undefined : round(n, roundedDecimalPlace);
    }
  }, [draft, roundedDecimalPlace]);
  const text = React.useMemo(() => {
    if (draft == null || draft === "") return "";
    else if (active)
      return draft;
    else if (num != null)
      return accounting.formatNumber(num, numberPrecision);
    else
      return "";
  }, [draft, active, num, numberPrecision]);
  const validate = () => {
    if (num === value) {
      setValidationError("");
      return true;
    }
    if (onValidating) {
      const error = onValidating(num);
      setValidationError(error);
      if (!error) {
        onValidated(num);
        return true;
      } else {
        return false;
      }
    } else {
      onValidated(num);
      return true;
    }
  };
  const [validationError, setValidationError] = React.useState("");
  const handleUpDownKey = (isUp: boolean) => {
    let n = num;
    if (!n) n = 0;
    let dec = text.indexOf(".");
    let sign = isUp ? 1 : -1;
    if (dec > -1) {
      // right of decimal
      let digitsAfterPoint = text.split(".")[1].length;
      let fnum = moveDecimal(n, digitsAfterPoint);
      fnum += sign;
      n = moveDecimal(fnum, digitsAfterPoint * -1);
    } else {
      // no decimal
      n += sign;
    }
    setDraft(n.toString());
  };
  const handleCommandKeyUp: React.KeyboardEventHandler<HTMLElement> =
    React.useCallback(
      (e) => {
        switch (e.key) {
          case "ArrowUp":
          case "ArrowDown":
            e.stopPropagation();
        }
      },
      []
    );
  return (
    <TextField
      helperText={
        validationError && showErrorAsHelperText ? validationError : helperText
      }
      error={!!validationError || error}
      inputRef={(r) => {
        textField.current = r;
        if (inputReference) inputReference(r);
      }}
      inputProps={{ sx: _sx.input, ...inputProps }}
      {...textFieldProps}
      value={text}
      onFocus={(e: any) => {
        setActive(true);
        if (selectAllOnFocus) {
          setTimeout(() => {
            textField.current?.setSelectionRange(
              0,
              textField.current?.value.length
            );
          }, 10);
        }
        if (onFocus) onFocus(e);
      }}
      onKeyUp ={handleCommandKeyUp}
      onKeyPress={(e: any) => {
        if (e.charCode === 13) {
          validate();
          setTimeout(() => {
            textField.current?.setSelectionRange(
              0,
              textField.current?.value.length
            );
          }, 10);
        } else if (
          (e.charCode < 48 || e.charCode > 57) &&
          e.charCode !== 45 &&
          e.charCode !== 46
        ) {
          // digit and minus sign
          e.preventDefault();
        }

        if (onKeyPress) onKeyPress(e);
      }}
      onBlur={(e: any) => {
        setActive(false);
        if (validateOnBlur) {
          if (
            shouldValidateOnBlur.current &&
            !validate() &&
            retainFocusOnError
          ) {
            e.preventDefault();
            setTimeout(() => {
              textField.current?.setSelectionRange(
                0,
                textField.current?.value.length
              );
              textField.current?.focus();
            }, 10);
          }
        }
        shouldValidateOnBlur.current = true;
        if (onBlur) {
          onBlur(e);
        }
      }}
      onChange={(e: any) => {
        let v = e.target.value.toString();
        const n = Number.parseFloat(v);
        if (v === "-") {
          setDraft(v);
        } else
          if (isNaN(n)) {
            setDraft("");
          } else {
            setDraft(v);
          }
      }}
      onKeyDown={(e: any) => {
        switch (e.keyCode) {
          case 38: // up
            e.preventDefault();
            handleUpDownKey(true);
            break;
          case 40: // down
            e.preventDefault();
            handleUpDownKey(false);
            break;
          case 27: // escape key
            shouldValidateOnBlur.current = false;
            textField.current?.blur();
            setDraft(value === 0 || value ? value.toString() : "");
            if (onValidating) {
              const error = onValidating(value);
              setValidationError(error);
            }
            break;
          default:
            break;
        }
        if (onKeyDown) onKeyDown(e);
      }}
    />
  );
}

NumberEditor.defaultProps = {
  numberPrecision: 2,
  retainFocusOnError: true,
  selectAllOnFocus: true,
  inputElementType: "TextField",
  validateOnBlur: true,
  showErrorAsHelperText: true,
};

export default NumberEditor;
