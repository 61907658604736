import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import AppBar from "@mui/material/AppBar";
import Save from "@mui/icons-material/Save";
import Add from "@mui/icons-material/Add";
import CreateNewFolder from "@mui/icons-material/CreateNewFolder";
import NavigationNext from "@mui/icons-material/ChevronRight";
import MoreVerticalIcon from "@mui/icons-material/MoreVert";
import HomeIcon from "@mui/icons-material/Home";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { Outlet, useMatch, useNavigate, useOutletContext, useParams } from "react-router-dom";
import DrawerToggleButton from "../../../app/DrawerToggleButton";
import CategoryEditor from "./CategoryEditor";
import { EcommerceCategory, EcommerceCategoryVariables, EcommerceCategory_ecommerceQuery_category } from "../__generated__/EcommerceCategory";
import { algoliaProducts, deleteEcommerceCategory, ecommerceCategoryQuery } from "../graphql";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import ListItemButton from "@mui/material/ListItemButton";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import useAppContext from "../../../app/AppContextProvider";
import { DeleteEcommerceCategoryMutation, DeleteEcommerceCategoryMutationVariables } from "../__generated__/DeleteEcommerceCategoryMutation";
import CreateProductDialog from "./CreateProductDialog";
import { EcommerceCategoryOptionValue } from "../EcommerceCategoryAutoComplete";
import Download from "@mui/icons-material/Download";
import { AlgoliaProducts } from "../__generated__/AlgoliaProducts";
const _sx = {
    root: {
        backgroundColor: "rgba(255,255,255,.1)"
    },
    paper: {
        backgroundColor: "rgba(0,0,0,.1)"
    },
    title: {
        marginRight: 1,
    },
}
export type ProductBrowserContext = {
    registerSaveProductCallback: (action: () => void) => void;
    current_parent: EcommerceCategoryOptionValue;
};

export function useProductBrowserContext() {
    return useOutletContext<ProductBrowserContext>();
}
type CategoryListProps = { category?: EcommerceCategory_ecommerceQuery_category | null, editCategory: (id: number) => void };
function CategoryList({ category, editCategory }: CategoryListProps) {
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const open = Boolean(anchorEl);
    const [activeId, setActiveId] = React.useState<number | null>(null);
    const handleClick = (e: React.MouseEvent<HTMLElement>, id: string) => {
        e.preventDefault();
        e.stopPropagation();
        setActiveId(Number.parseInt(id));
        setAnchorEl(e.currentTarget);
    };
    const { showActivityDialog, hideActivityDialog, showError, requestComfirmation } = useAppContext();
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleEdit = () => {
        setAnchorEl(null);
        if (activeId)
            editCategory(activeId);
    }
    const [deleteCategory] = useMutation<DeleteEcommerceCategoryMutation, DeleteEcommerceCategoryMutationVariables>(deleteEcommerceCategory, {
        onError: (error) => {
            showError("Could not delete category.", error.message);
        }
    });
    const handleDelete = () => {
        handleClose();
        if (!activeId)
            return;
        requestComfirmation({
            title: "Delete Category",
            message: "Are you sure?",
            callback: async (result: boolean) => {
                if (!result)
                    return;
                showActivityDialog("");
                try {
                    await deleteCategory({ variables: { id: activeId } });
                } catch (e) {
                    hideActivityDialog();
                    showError("Could not delete category", e);
                } finally {
                    hideActivityDialog();
                }
            }
        })
    }
    return <><List>
        {category?.children?.map(c => {
            return (<React.Fragment key={c.id}>
                <ListItemButton onClick={() => { navigate(`/ecommerce/${c.id}`) }}>
                    <ListItemText primary={c.name} />
                    <ListItemSecondaryAction>
                        <IconButton onClick={(e) => { handleClick(e, c.id) }}>
                            <MoreVerticalIcon />
                        </IconButton>
                    </ListItemSecondaryAction>
                </ListItemButton>
                <Divider />
            </React.Fragment>);
        })}
    </List>
        <Menu
            id="category_menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
        >
            <MenuItem onClick={handleEdit}>Edit</MenuItem>
            <MenuItem onClick={handleDelete}>Delete</MenuItem>
            <MenuItem onClick={handleClose}>Publish</MenuItem>
        </Menu>
    </>
}

function CategoryBreadcrumb({ category }: { category?: EcommerceCategory_ecommerceQuery_category | null }) {
    const navigate = useNavigate();
    return (<Breadcrumbs sx={{ paddingLeft: 1 }} aria-label="breadcrumb" separator={<NavigationNext fontSize="small" />}>
        <IconButton onClick={() => { navigate("/ecommerce") }}>
            <HomeIcon />
        </IconButton>
        {
            category?.parents_with_me?.filter(c => c.id !== "-1").map(c => (c.id === category.id ? <Typography color="text.primary" key={c.id}>{c.name}</Typography> : <Link key={c.id} underline="hover" color="inherit" href="#" onClick={(e) => {
                e.preventDefault();
                navigate(`/ecommerce/${c.id}`)
            }}>
                {c.name}
            </Link>))
        }
    </Breadcrumbs>);
}

function Ecommerce() {
    let { id } = useParams<{ id: string }>();
    const currentCategoryId = React.useMemo(() => {
        if (!id)
            return -1;
        const result = Number.parseInt(id);
        if (isNaN(result))
            return null;
        return result;
    }, [id]);

    const [openProductCreateDialog, setOpenProductCreateDialog] = React.useState(false);

    const saveProduct = React.useRef<() => void>();
    const [activeChildId, setActiveChildId] = React.useState<number | null>();
    const [openCategoryDialog, setOpenCategoryDialog] = React.useState(false);
    const queryResult = useQuery<EcommerceCategory, EcommerceCategoryVariables>(ecommerceCategoryQuery, {
        variables: { id: currentCategoryId ?? -1 },
        fetchPolicy: "no-cache"
    });
    const category = queryResult?.data?.ecommerceQuery?.category;
    const isCategoryAndProductRoute = useMatch("ecommerce/:id/product/:product_id");
    const isRootCategoryAndProductRoute = useMatch("ecommerce/product/:product_id");
    const isProductRoute = isCategoryAndProductRoute || isRootCategoryAndProductRoute;
    const graphqlClient = useApolloClient();
    const prepareJsonFileForAlgolia = React.useCallback(async () => {
        const { data } = await graphqlClient.query<AlgoliaProducts, {}>({ query: algoliaProducts });
        const content = data?.algoliaQuery?.products?.map(({ ecategs, prices, quants, ...p }) => {
            delete (p as any).__typename;
            const _quants = quants?.reduce<{[key:number]:number}>((previous,current,index)=>{
                previous[current.id] = current.quantity;
                return previous;
            },{});
            const _prices = prices?.map(({...price}) => { delete (price as any).__typename; return price; });
            const _companies = _prices?.map(({id})=> id);
            const _ecategs = ecategs?.reduce<{ [key: string]: string | string[] }>((previous, current, index) => {
                const splitted = current.split("##");
                previous[`ecategs.lvl${index}`] = splitted.length === 1 ? current : splitted;
                return previous;
            }, {});            
            return {...p,prices:_prices,..._ecategs, companies:_companies, quants:_quants};
        });
        const blob = new Blob([JSON.stringify(content)], { type: "application/json" });
        const url = URL.createObjectURL(blob);
        window.open(url, "_blank");
    }, [graphqlClient]);
    const navigate = useNavigate();
    return (<Grid container direction="column">
        <AppBar position="static">
            <Toolbar
                sx={(theme) => ({
                    [theme.breakpoints.up("md")]: {
                        minHeight: 48,
                    },
                    paddingLeft: 1.5,
                })}
            >
                <DrawerToggleButton />
                <Typography variant="h6" sx={_sx.title} color="inherit" noWrap>
                    Products
                </Typography>
                <Box flex={1} />
                {
                    isProductRoute ? <Tooltip title="Save Product">
                        <IconButton onClick={() => {
                            if (saveProduct.current)
                                saveProduct.current();
                        }}>
                            <Save />
                        </IconButton>
                    </Tooltip> : <Tooltip title="Downlod JSON for Algolia">
                        <IconButton onClick={prepareJsonFileForAlgolia}>
                            <Download />
                        </IconButton>
                    </Tooltip>
                }
                <Tooltip title="New Product">
                    <IconButton onClick={() => {
                        setOpenProductCreateDialog(true);
                    }}>
                        <Add />
                    </IconButton>
                </Tooltip>
                <Tooltip title="New Category">
                    <IconButton onClick={() => {
                        setActiveChildId(null);
                        setOpenCategoryDialog(true);
                    }}>
                        <CreateNewFolder />
                    </IconButton>
                </Tooltip>
            </Toolbar>
        </AppBar>
        <CategoryBreadcrumb category={category} />
        <Box
            sx={_sx.root}
            flex={1}
            display="flex"
            flexDirection="row"
            overflow="hidden"
            flexWrap="nowrap"
        >
            <Box
                sx={{
                    width: 200,
                    maxWidth: 200,
                    ..._sx.paper,
                    overflowY: "auto"
                }}
            >
                <CategoryList editCategory={(id) => { setActiveChildId(id); setOpenCategoryDialog(true); }} category={category} />
            </Box>
            <Box flex={1} >
                <Outlet context={{
                    registerSaveProductCallback: (action: () => void) => {
                        saveProduct.current = action;
                    },
                    current_parent: { id: category?.id ?? "-1", display_name: category?.complete_name ?? "All" }
                }} />
            </Box>
        </Box>
        <CategoryEditor id={activeChildId} current_parent={{ id: category?.id ?? "-1", display_name: category?.complete_name ?? "All" }} open={openCategoryDialog} onClose={() => { setOpenCategoryDialog(false); }} />
        <CreateProductDialog open={openProductCreateDialog} onCancel={() => { setOpenProductCreateDialog(false); }} onAccept={(product_id) => { setOpenProductCreateDialog(false); navigate(`/ecommerce/${id ? `${id}/` : ""}product/${product_id}`); }} />
    </Grid>);
}

export default Ecommerce;