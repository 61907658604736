import { gql } from "@apollo/client";

const saleOrdersQuery = gql`
  # Write your query or mutation here
  query SaleOrders(
    $where: SaleOrderWhereInput
    $orderBy: [SaleOrderOrderBy!]
    $pagination: PaginationInput
  ) {
    saleOrderQuery {
      id
      saleOrders(where: $where, orderBy: $orderBy, pagination: $pagination) {
        edges {
          id
          name
          partner_id {
            id
            display_name
          }
          date_order
          amount_total
          amount_untaxed
          state
          client_order_ref
          origin
          saleperson_name
          cashier_name
        }
        pageInfo {
          currentPage
          hasNextPage
          hasPreviousPage
          pageCount
          pageSize
          rowCount
        }
      }
    }
  }
`;

const saleOrderQuery = gql`
  query SaleOrder($id: ID!) {
    saleOrderQuery {
      id
      saleOrder(id: $id) {
        id
        name
        date_order
        amount_total
        amount_untaxed
        state
        client_order_ref
        note
        saleperson_name
        cashier_name
        origin
        analytic_account_id {
          id
          display_name
        }
        warehouse_id {
          id
          display_name
        }
        team_id {
          id
          display_name
        }
        invoice_status
        pickings {
          id
          name
          state
          show_validate
        }
        partner {
          id
          title {
            id
            display_name
          }
          name
          phone
          email
          township {
            id
            name_mm
            name_en
            township_pcode
            region {
              id
              name_mm
              name_en
            }
          }
          street
          ref
        }
        picking_ids
        order_lines {
          id
          name
          price_unit
          product_id {
            id
            display_name
          }
          saleperson_id{
            id
            display_name
          }
          product_uom {
            id
            display_name
          }
          product_uom_qty
          qty_delivered
          qty_invoiced
          discount
          price_subtotal
        }
        invoices {
          id
          name
          invoice_date
          amount_total
          invoice_payments_widget{
            id
            outstanding
            content{
              id
              name
              journal_name
              ref
              amount
              currency_id
              date
              partial_id
              account_payment_id
              payment_method_name
              move_id
              amount_company_currency
              amount_foreign_currency
            }
            title
          }
          amount_residual
          state
          payment_state
          move_type
        }
      }
    }
  }
`;

const saveSaleOrderMutation = gql`
  mutation SaveSaleOrder($order: SaleOrderInput!) {
    saleOrderMutation {
      id
      saveSaleOrder(order: $order) {
        id
        name
        date_order
        amount_total
        amount_untaxed
        state
        note
        origin
        analytic_account_id {
          id
          display_name
        }
        warehouse_id {
          id
          display_name
        }
        team_id {
          id
          display_name
        }
        invoice_status
        partner {
          id
          title {
            id
            display_name
          }
          name
          phone
          email
          township {
            id
            name_mm
            name_en
            township_pcode
            region {
              id
              name_mm
              name_en
            }
          }
          street
          ref
        }
        order_lines {
          id
          name
          price_unit
          product_id {
            id
            display_name
          }
          saleperson_id{
            id
            display_name
          }
          product_uom {
            id
            display_name
          }
          product_uom_qty
          qty_delivered
          qty_invoiced
          discount
          price_subtotal
        }

        invoices {
          id
          name
          invoice_date
          amount_total
          invoice_payments_widget{
            id
            outstanding
            content{
              id
              name
              journal_name
              ref
              amount
              currency_id
              date
              partial_id
              account_payment_id
              payment_method_name
              move_id
              amount_company_currency
              amount_foreign_currency
            }
            title
          }
          amount_residual
          state
          payment_state
          move_type
        }
      }
    }
  }
`;

export const createInvoiceMutation = gql`
  mutation CreateInvoice(
    $id: Int!
    $team_id: Int!
    $confirmSale: Boolean = true
  ) {
    saleOrderMutation {
      id
      createInvoice(id: $id, team_id: $team_id, confirmSale: $confirmSale) {
        id
        state
        invoice_status
        picking_ids
        order_lines {
          id
          name
          price_unit
          product_id {
            id
            display_name
          }
          product_uom {
            id
            display_name
          }
          saleperson_id{
            id
            display_name
          }
          product_uom_qty
          qty_delivered
          qty_invoiced
          discount
          price_subtotal
        }
        invoices {
          id
          name
          invoice_date
          amount_total
          invoice_payments_widget{
            id
            outstanding
            content{
              id
              name
              journal_name
              ref
              amount
              currency_id
              date
              partial_id
              account_payment_id
              payment_method_name
              move_id
              amount_company_currency
              amount_foreign_currency
            }
            title
          }
          amount_residual
          state
          payment_state
          move_type
        }
      }
    }
  }
`;

export const confirmSaleMutation = gql`
  mutation ConfirmSale($id: Int!) {
    saleOrderMutation {
      id
      confirmSale(id: $id) {
        id
        state
        invoice_status
      }
    }
  }
`;

export const cancelOrderMutation = gql`
  mutation CancelSaleOrder($id: Int!, $cancelRemark: String!) {
    saleOrderMutation {
      id
      cancelSaleOrder(id: $id, cancelRemark: $cancelRemark) {
        id
        name
        date_order
        amount_total
        amount_untaxed
        state
        origin
        analytic_account_id {
          id
          display_name
        }
        warehouse_id {
          id
          display_name
        }
        team_id {
          id
          display_name
        }
        invoice_status
        partner {
          id
          title {
            id
            display_name
          }
          name
          phone
          email
          township {
            id
            name_mm
            name_en
            township_pcode
            region {
              id
              name_mm
              name_en
            }
          }
          street
          ref
        }
        order_lines {
          id
          name
          price_unit
          product_id {
            id
            display_name
          }
          product_uom {
            id
            display_name
          }
          saleperson_id{
            id
            display_name
          }
          product_uom_qty
          qty_delivered
          qty_invoiced
          discount
          price_subtotal
        }

        invoices {
          id
          name
          invoice_date
          amount_total
          invoice_payments_widget{
            id
            outstanding
            content{
              id
              name
              journal_name
              ref
              amount
              currency_id
              date
              partial_id
              account_payment_id
              payment_method_name
              move_id
              amount_company_currency
              amount_foreign_currency
            }
            title
          }
          amount_residual
          state
          payment_state
          move_type
        }
      }
    }
  }
`;

export const makeInitialPaymentMutation = gql`
  mutation MakeInitialPaymentMutation(
    $id: Int!
    $journalId: Int!
    $amount: Float!
    $team_id: Int!
    $date: NodaTimeLocalDate
  ) {
    saleOrderMutation {
      id
      makeInitialPayment(
        id: $id
        journalId: $journalId
        amount: $amount
        team_id: $team_id
        date: $date
      ) {
        id
        state
        invoice_status
        invoices {
          id
          name
          invoice_date
          amount_total
          invoice_payments_widget{
            id
            outstanding
            content{
              id
              name
              journal_name
              ref
              amount
              currency_id
              date
              partial_id
              account_payment_id
              payment_method_name
              move_id
              amount_company_currency
              amount_foreign_currency
            }
            title
          }
          amount_residual
          state
          payment_state
          move_type
        }
      }
    }
  }
`;

export const makePaymentMutation = gql`
  mutation MakePaymentMutation(
    $id: Int!
    $journalId: Int!
    $amount: Float!
    $team_id: Int!
    $date: NodaTimeLocalDate
  ) {
    saleOrderMutation {
      id
      makePayment(
        id: $id
        journalId: $journalId
        amount: $amount
        team_id: $team_id
        date: $date
      ) {
        id
        state
        invoice_status
        invoices {
          id
          name
          invoice_date
          amount_total
          invoice_payments_widget{
            id
            outstanding
            content{
              id
              name
              journal_name
              ref
              amount
              currency_id
              date
              partial_id
              account_payment_id
              payment_method_name
              move_id
              amount_company_currency
              amount_foreign_currency
            }
            title
          }
          amount_residual
          state
          payment_state
          move_type
        }
      }
    }
  }
`;

export const customersQuery = gql`
  query Customers(
    $where: PartnerWhereInput
    $orderBy: [PartnerOrderBy!]
    $pagination: PaginationInput
  ) {
    partnerQuery {
      id
      customers(where: $where, orderBy: $orderBy, pagination: $pagination) {
        edges {
          id
          title {
            id
            display_name
          }
          name
          ref
          phone
          email
          township {
            id
            name_en
            name_mm
            township_pcode
            region {
              id
              name_en
              name_mm
            }
          }
          street
          sale_order_count
          total_invoiced
          credit
          active
        }
        pageInfo {
          currentPage
          hasNextPage
          hasPreviousPage
          pageCount
          pageSize
          rowCount
        }
      }
    }
  }
`;

export const partnerQuery = gql`
  query Partner($id: ID!) {
    partnerQuery {
      id
      partner(id: $id) {
        id
        title {
          id
          display_name
        }
        name
        ref
        phone
        email
        township {
          id
          name_en
          name_mm
          township_pcode
          region {
            id
            name_en
            name_mm
          }
        }
        street
        sale_order_count
        total_invoiced
        credit
        active
      }
    }
  }
`;

export const stockPickingsQuery = gql`
  query StockPickings(
    $where: StockPickingWhereInput
    $orderBy: [StockPickingOrderBy!]
    $pagination: PaginationInput
  ) {
    inventoryQuery {
      id
      pickings(where: $where, orderBy: $orderBy, pagination: $pagination) {
        edges {
          id
          name
          location_id {
            id
            display_name
          }
          location_dest_id {
            id
            display_name
          }
          date_done
          origin
          partner_id {
            id
            display_name
          }
          picking_type_id {
            id
            display_name
          }
          owner_id {
            id
            display_name
          }
          state
        }
        pageInfo {
          hasNextPage
          hasPreviousPage
          rowCount
          pageCount
          currentPage
          pageSize
        }
      }
    }
  }
`;

export const stockPickingQuery = gql`
  query StockPicking(
    $id: Int!
    $pagination: PaginationInput
    $where: StockMoveWhereInput
  ) {
    inventoryQuery {
      id
      picking(id: $id) {
        id
        name
        location_id {
          id
          display_name
        }
        location_dest_id {
          id
          display_name
        }
        date_done
        origin
        partner_id {
          id
          display_name
        }
        picking_type_id {
          id
          display_name
        }
        picking_type_code
        immediate_transfer
        move_type
        show_check_availability
        show_validate
        owner_id {
          id
          display_name
        }
        owner {
          id
          ref
        }
        state
        moves(pagination: $pagination, where: $where) {
          pageInfo {
            pageSize
            pageCount
            currentPage
            hasNextPage
            hasPreviousPage
            rowCount
          }
          edges {
            id
            product_id {
              id
              display_name
            }
            product_uom_qty
            quantity_done
            reserved_availability
            product_qty
            availability
            product_uom {
              id
              display_name
            }
            is_quantity_done_editable
            is_initial_demand_editable
            has_tracking
            show_operations
            move_lines(pagination: { pageSize: 1 }) {
              edges {
                id
                lot_name
                lot_id {
                  id
                  display_name
                }
                qty_done
              }
            }
            state
          }
        }
      }
    }
  }
`;

export const savePartnerMutation = gql`
  mutation SavePartner($partner: PartnerInput!) {
    partnerMutation {
      id
      savePartner(partner: $partner) {
        id
        title {
          id
          display_name
        }
        name
        city
        email
        phone
        ref
        sale_order_count
        total_invoiced
        credit
        active
        street
        write_date
        township {
          id
          name_en
          name_mm
          township_pcode
          region {
            id
            name_en
            name_mm
          }
        }
      }
    }
  }
`;
export const productQuery = gql`
  query Product(
    $id: ID!
    $purchase_history: Boolean!
    $price_history: Boolean!
    $valuation: Boolean!
    $quants:Boolean!
  ) {
    productQuery {
      id
      product(id: $id) {
        id
        default_code
        name
        qty_available @include(if: $quants)
        incoming_qty @include(if: $quants)
        outgoing_qty @include(if: $quants)
        virtual_available @include(if: $quants)
        type
        uom_id {
          id
          display_name
        }
        quants @include(if: $quants) {
          id
          product_id
          location_id
          location_name
          owner_id
          owner_name
          company_id
          company_name
          quantity_sum
        }
        list_price
        valuation_in @include(if: $valuation) {
          id
          create_date
          product_id
          default_code
          product_name
          stock_move_id
          stock_move_name
          quantity
          value
          unit_cost
          final_cost
          final_value
          remaining_qty
          remaining_value
          no_cost
          no_cost_by_id
          no_cost_by_name
          cost_by_id
          cost_by_name
          no_cost_by_name
        }
        incoming @include(if: $valuation){
          id
          date
          quantity_done      
          location_id{
            id
            display_name
          }
          location_dest_id{
            id
            display_name
          }
          picking_id{
            id
            display_name
          }
        }
        purchase_history @include(if: $purchase_history) {
          id
          date_order
          order_id {
            id
            display_name
          }
          product_uom_qty
          price_unit
        }
        prices {
          id
          price
          price_list {
            id
            name
          }
          price_history @include(if: $price_history) {
            id
            on_date
            by_user_id {
              id
              display_name
            }
            new_price
            pricechange_id{
              id
              display_name
            }
          }
        }
      }
    }
  }
`;

export const productWithoutHistoryQuery = gql`
  query ProductWithoutHistory($id: ID!) {
    productQuery {
      id
      product(id: $id) {
        id
        default_code
        name
        qty_available
        incoming_qty
        outgoing_qty
        virtual_available
        type
        uom_id {
          id
          display_name
        }
        list_price
        valuation {
          id
          create_date
          remaining_qty
          remaining_value
        }
        prices {
          id
          price
          price_list {
            id
            name
          }
        }
      }
    }
  }
`;

export const productsQuery = gql`
  query Products(
    $where: ProductWhereInput
    $pagination: PaginationInput
    $orderBy: [ProductOrderBy!]
  ) {
    productQuery {
      id
      products(where: $where, pagination: $pagination, orderBy: $orderBy) {
        edges {
          id
          default_code
          name
          qty_available
          outgoing_qty
          prices(price_list_ids: [1]) {
            id
            price
          }
          type
          uom_id {
            id
            display_name
          }
        }
        pageInfo {
          currentPage
          hasNextPage
          hasPreviousPage
          rowCount
          pageSize
          pageCount
        }
      }
    }
  }
`;

export const saveReservedStockMoveMutation = gql`
  mutation SaveReservedStockMove($stockMove: ReservedStockMoveInput!) {
    inventoryMutation {
      id
      saveReservedStockMove(stockMove: $stockMove) {
        id
        product_uom_qty
        quantity_done
        move_lines {
          edges {
            id
            reserved_uom_qty
            qty_done
            lot_name
            lot_id {
              id
              display_name
            }
          }
        }
      }
    }
  }
`;

export const saveStockMoveMutation = gql`
  mutation SaveStockMove($stockMove: StockMoveInput!) {
    inventoryMutation {
      id
      saveStockMove(stockMove: $stockMove) {
        id
        product_uom_qty
        quantity_done
        move_lines {
          edges {
            id
            reserved_uom_qty
            qty_done
            lot_name
            lot_id {
              id
              display_name
            }
          }
        }
      }
    }
  }
`;

export const validateStockPickingMutation = gql`
  mutation ValidateStockPicking(
    $id: Int!
    $forceBackOrder: Boolean!
    $forceImmediateTransfer: Boolean!
  ) {
    inventoryMutation {
      id
      validatePicking(
        id: $id
        forceBackOrder: $forceBackOrder
        forceImmediateTransfer: $forceImmediateTransfer
      ) {
        stockPicking {
          id
          state
          name
          show_validate
          move_type
          picking_type_code
          show_check_availability
          moves(pagination: { pageSize: 1000 }) {
            pageInfo {
              pageSize
              pageCount
              currentPage
              hasNextPage
              hasPreviousPage
              rowCount
            }
            edges {
              id
              product_id {
                id
                display_name
              }
              product_uom_qty
              quantity_done
              reserved_availability
              product_qty
              availability
              product_uom {
                id
                display_name
              }
              is_quantity_done_editable
              is_initial_demand_editable
              has_tracking
              move_lines(pagination: { pageSize: 1 }) {
                edges {
                  id
                  lot_name
                  lot_id {
                    id
                    display_name
                  }
                  qty_done
                }
              }
              state
            }
          }
        }
        hasBackOrder
        backOrderCreated
      }
    }
  }
`;

export const checkAvailabilityForPickingMutation = gql`
  mutation CheckAvailabilityForPicking($id: Int!) {
    inventoryMutation {
      id
      checkAvailabilityForPicking(id: $id) {
        id
        state
        show_validate
        show_check_availability
        move_type
        picking_type_code
        moves(pagination: { pageSize: 1000 }) {
          pageInfo {
            pageSize
            pageCount
            currentPage
            hasNextPage
            hasPreviousPage
            rowCount
          }
          edges {
            id
            product_id {
              id
              display_name
            }
            product_uom_qty
            quantity_done
            reserved_availability
            product_qty
            availability
            product_uom {
              id
              display_name
            }
            is_quantity_done_editable
            is_initial_demand_editable
            has_tracking
            move_lines(pagination: { pageSize: 1 }) {
              edges {
                id
                lot_name
                lot_id {
                  id
                  display_name
                }
                qty_done
              }
            }
            state
          }
        }
      }
    }
  }
`;

export const unreservePickingMutation = gql`
  mutation UnreservePicking($id: Int!) {
    inventoryMutation {
      id
      unreservePicking(id: $id) {
        id
        state
        show_validate
        show_check_availability
        move_type
        picking_type_code
        moves(pagination: { pageSize: 1000 }) {
          pageInfo {
            pageSize
            pageCount
            currentPage
            hasNextPage
            hasPreviousPage
            rowCount
          }
          edges {
            id
            product_id {
              id
              display_name
            }
            product_uom_qty
            quantity_done
            reserved_availability
            product_qty
            availability
            product_uom {
              id
              display_name
            }
            is_quantity_done_editable
            is_initial_demand_editable
            has_tracking
            move_lines(pagination: { pageSize: 1 }) {
              edges {
                id
                lot_name
                lot_id {
                  id
                  display_name
                }
                qty_done
              }
            }
            state
          }
        }
      }
    }
  }
`;

export const productLotsQuery = gql`
  query ProductLots(
    $where: ProductLotWhereInput
    $pagination: PaginationInput
    $orderBy: [ProductLotOrderBy!]
  ) {
    inventoryQuery {
      id
      productLots(where: $where, pagination: $pagination, orderBy: $orderBy) {
        edges {
          id
          name
        }
        pageInfo {
          currentPage
          hasNextPage
          hasPreviousPage
          rowCount
          pageCount
          pageSize
        }
      }
    }
  }
`;

export { saleOrdersQuery, saleOrderQuery, saveSaleOrderMutation };
