import { Add, ArrowBack, Close } from "@mui/icons-material";
import { AppBar, Box, Grid, Hidden, IconButton, Toolbar, Tooltip, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { Outlet, useMatch, useNavigate, useOutletContext, useParams } from "react-router-dom";
import DrawerToggleButton from "../../app/DrawerToggleButton";
import { RootState } from "../../app/store";
import CsSearchBox, { OperandType } from "../../components/CsSearchBox";
import ErrorMessageDialog from "../../components/ErrorMessageDialog";
import { PromotionOrderBy } from "../../types/global-types";
import { PromotionsVariables } from "./__generated__/Promotions";
import update from "immutability-helper";
import PromotionGrid from "./PromotionGrid";

const _sx = {
  title: {
    marginRight: 1,
  },
  box: {
    backgroundColor: "rgba(255,255,255,.1)",
  },
  root: {
    flex: 1,
    display: "flex",
  },
};

export type PromotionOutletContextType = {
  promotion_id?: number;
  promotionsVariables?: PromotionsVariables
};

export function usePromotionOutletContext() {
  return useOutletContext<PromotionOutletContextType>();
}

function PromotionsBrowser() {
  const { id: promotion_id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [operationError, setOperationError] = React.useState<Error | null>(
    null
  );
  const [errorMessageDialogOpen, setErrorMessageDialogOpen] =
    React.useState(false);
  const [operationErrorMessage, setOperationErrorMessage] =
    React.useState<string>("");
  const promotionNo = useSelector((state: RootState) => state.promotion.name);
  const [variables, setVariables] = React.useState<PromotionsVariables>({
    pagination: { pageSize: 50, page: 1 },
    orderBy: [PromotionOrderBy.name_Desc]
  });
  const isPromotionRoute = useMatch("promotions/:id");
  const isCreateRoute = useMatch("promotions/create");
  const isPromotionsRoute = useMatch("promotions");
  const isViewRoute = isPromotionRoute || isCreateRoute;
  const handleCommandKeyUp = React.useCallback(
    (e: KeyboardEvent) => {
      switch (e.key) {
        case "F9":
          navigate("/promotions/create");
          break;
      }
    },
    [navigate]
  );
  const handleFunctionKeyDown = React.useCallback((e: KeyboardEvent) => {
    switch (e.key) {
      case "F1":
      case "F10":
      case "F3":
      case "F12":
        e.preventDefault();
        break;
    }
  }, []);
  React.useEffect(() => {
    document.addEventListener("keyup", handleCommandKeyUp);
    window.addEventListener("keydown", handleFunctionKeyDown);
    return () => {
      document.removeEventListener("keyup", handleCommandKeyUp);
      window.removeEventListener("keydown", handleFunctionKeyDown);
    };
  }, [handleCommandKeyUp, handleFunctionKeyDown]);

  return (
    <>
      <Grid container direction="column">
        <AppBar position="static" >
          <Toolbar sx={(theme) => ({
            [theme.breakpoints.up("md")]: {
              minHeight: 48,
            },
            paddingLeft: 1.5,
          })}>
            <DrawerToggleButton />
            {
              isPromotionRoute ? (<>
                <Hidden xlUp>
                  <Tooltip title="Back to promotions">
                    <IconButton
                      color="inherit"
                      onClick={() => {
                        navigate("/promotions");
                      }}
                    >
                      <ArrowBack />
                    </IconButton>
                  </Tooltip>
                </Hidden>
                <Typography
                  variant="h6"
                  sx={_sx.title}
                  color="inherit"
                  noWrap
                >
                  {promotionNo ? promotionNo : "New"}
                </Typography>
              </>) : null
            }
            {
              isPromotionsRoute ? (<>
                <Typography
                  variant="h6"
                  sx={_sx.title}
                  color="inherit"
                  noWrap
                >
                  Promotions
                </Typography>
                <CsSearchBox
                  onConditionChanged={(conditions: any) => {
                    setVariables(
                      update(variables, {
                        where: {
                          $set: { aND: conditions },
                        },
                      })
                    );
                  }}
                  operands={{
                    name: {
                      name: "Name",
                      propName: "name",
                      type: OperandType.STRING,
                    },
                    date_from: {
                      name: "From",
                      propName: "date_from",
                      type: OperandType.DATE,
                    },
                    date_to: {
                      name: "To",
                      propName: "date_to",
                      type: OperandType.DATE,
                    },
                    reward_product_income_account_id: {
                      name: "Income Account",
                      propName: "reward_product_income_account_id",
                      type: OperandType.NUMBER,
                    },
                    created_name: {
                      name: "Created by",
                      propName: "created_name",
                      type: OperandType.STRING,
                    },
                    rule_partners_domain: {
                      name: "Customer Domain",
                      propName: "rule_partners_domain",
                      type: OperandType.STRING,
                    },
                  }}
                />
                <Tooltip title="New promotion">
                  <IconButton
                    color="inherit"
                    onClick={() => {
                      navigate("/promotions/create");
                    }}
                  >
                    <Add />
                  </IconButton>
                </Tooltip>
              </>) : null
            }
            {
              isPromotionRoute ? (<Hidden lgDown>
                <CsSearchBox
                  onConditionChanged={(conditions: any) => {
                    setVariables(
                      update(variables, {
                        where: {
                          $set: { aND: conditions },
                        },
                      })
                    );
                  }}
                  operands={{
                    name: {
                      name: "Name",
                      propName: "name",
                      type: OperandType.STRING,
                    },
                    date_from: {
                      name: "From",
                      propName: "date_from",
                      type: OperandType.DATE,
                    },
                    date_to: {
                      name: "To",
                      propName: "date_to",
                      type: OperandType.DATE,
                    },
                    reward_product_income_account_id: {
                      name: "Income Account",
                      propName: "reward_product_income_account_id.display_name",
                      type: OperandType.NUMBER,
                    },
                    created_name: {
                      name: "Created by",
                      propName: "created_name",
                      type: OperandType.STRING,
                    },
                    rule_partners_domain: {
                      name: "Customer Domain",
                      propName: "rule_partners_domain",
                      type: OperandType.STRING,
                    },
                  }}
                />
                <Tooltip title="Close promotion view.">
                  <IconButton
                    color="inherit"
                    onClick={() => {
                      navigate("/promotions");
                    }}
                  >
                    <Close />
                  </IconButton>
                </Tooltip>
              </Hidden>) : null
            }
          </Toolbar>
        </AppBar>
        <Box
          sx={_sx.box}
          flex={1}
          display="flex"
          flexDirection="row"
        >
          <Box
            sx={{
              minWidth: 300,
              flex: isViewRoute ? 0 : 1,
              display: {xs:isViewRoute? "none":"flex", xl:"flex"}
            }}
          >
            <PromotionGrid
              variables={variables}
              onVariablesChanged={setVariables}
            />
          </Box>
          <Outlet context={{ promotion_id, promotionsVariables: variables }} />
        </Box>
      </Grid>
      {operationError ? (
        <ErrorMessageDialog
          open={errorMessageDialogOpen}
          onClose={() => {
            setErrorMessageDialogOpen(false);
          }}
          error={{
            title:"Error",
            message: operationErrorMessage,
            detail:operationError.message
        }}
        />
      ) : null}
    </>
  );
}

export default PromotionsBrowser;
