import { closestCenter, DndContext, DragEndEvent, DragOverlay, DragStartEvent, KeyboardSensor, MouseSensor, TouchSensor, UniqueIdentifier, useSensor, useSensors } from "@dnd-kit/core";
import { arrayMove, SortableContext, sortableKeyboardCoordinates, useSortable } from "@dnd-kit/sortable";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import clsx from "clsx";
import CloseIcon from "@mui/icons-material/Close";
import * as React from "react";
import PhotoAlbum, { Photo, RenderPhotoProps } from "react-photo-album";
import ActivityIndicatorDialog from "../../../components/ActivityIndicatorDialog";
import { ErrorInfo } from "../../../components/ErrorMessageDialog";
import { EcommerceProduct_ecommerceQuery_product_tri_product_variant_images } from "../__generated__/EcommerceProduct";
import PhotoUploader from "./PhotoUploader";


export type AlbumPhoto = EcommerceProduct_ecommerceQuery_product_tri_product_variant_images & { src: string, width: 1000, height: number };

export type ProductPhotoAlbumProps = {
    photos: AlbumPhoto[];
    setPhotos: (photos: AlbumPhoto[]) => void;
    product_id: number;
    onError: (error: ErrorInfo) => void;
    onDelete:(id:number)=>void;
}

interface SortablePhoto extends Photo {
    id: string;
}

type SortablePhotoProps = RenderPhotoProps<SortablePhoto>;

type PhotoFrameProps = SortablePhotoProps & {
    overlay?: boolean;
    active?: boolean;
    insertPosition?: "before" | "after";
    attributes?: Partial<React.HTMLAttributes<HTMLDivElement>>;
    listeners?: Partial<React.HTMLAttributes<HTMLDivElement>>;
    onDelete?:()=>void;
};

const PhotoFrame = React.memo(
    React.forwardRef<HTMLDivElement, PhotoFrameProps>((props, ref) => {
        const { layoutOptions, imageProps, overlay, active, insertPosition, attributes, listeners,onDelete } = props;
        const { alt, style, ...restImageProps } = imageProps;
        const indicator = {
            content: '""',
            position: "absolute",
            backgroundColor: "#2196f3",
            borderRadius: 4
        };
        return (
            <Box
                sx={{ position: "relative", "&.active": { opacity: .3 }, "&.overlay": { cursor: "grabbing" }, "&.insertBefore:before": indicator, "&.insertAfter:after": indicator }}
                ref={ref}
                style={{
                    width: overlay ? `calc(100% - ${2 * layoutOptions.padding}px)` : style.width,
                    padding: style.padding,
                    marginBottom: style.marginBottom,
                }}
                className={
                    clsx("photo-frame", {
                        overlay: overlay,
                        active: active,
                        insertBefore: insertPosition === "before",
                        insertAfter: insertPosition === "after",
                    })}
                {...attributes}
                {...listeners}
            >
                <img
                    alt={alt}
                    style={{
                        ...style,
                        width: "100%",
                        height: "auto",
                        padding: 0,
                        marginBottom: 0,
                        backgroundColor:"#fff"
                    }}
                    {...restImageProps}
                />
                <IconButton onClick={onDelete} sx={{position:"absolute", top:8, right:8}} color="secondary" aria-label="upload picture" component="label">
                    <CloseIcon />
                </IconButton>
            </Box >
        );
    })
);
PhotoFrame.displayName = "PhotoFrame";

const SortablePhotoFrame = (props: SortablePhotoProps & { activeIndex?: number,onDelete?:(id:number)=>void }) => {
    const { photo, activeIndex,onDelete } = props;
    const { attributes, listeners, isDragging, index, over, setNodeRef } = useSortable({ id: photo.id });

    return (
        <PhotoFrame
            ref={setNodeRef}
            active={isDragging}
            insertPosition={
                activeIndex !== undefined && over?.id === photo.id && !isDragging
                    ? index > activeIndex
                        ? "after"
                        : "before"
                    : undefined
            }
            aria-label="sortable image"
            attributes={attributes}
            listeners={listeners}
            {...props}
            onDelete={()=>{if(onDelete) onDelete(Number.parseInt(photo.id))}}
        />
    );
};



function ProductPhotoAlbum({ photos, setPhotos, product_id, onError,onDelete }: ProductPhotoAlbumProps) {
    const sensors = useSensors(
        useSensor(MouseSensor, { activationConstraint: { distance: 5 } }),
        useSensor(TouchSensor, { activationConstraint: { delay: 50, tolerance: 10 } }),
        useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates })
    );
    const setAlbumPhotos = React.useCallback((photos: AlbumPhoto[]) => {
        const source = photos.map(({ sequence, ...p }, index) => ({ ...p, sequence: index }));
        setPhotos(source);
    }, [setPhotos]);
    const renderedPhotos = React.useRef<{ [key: string]: SortablePhotoProps }>({});
    const [activeId, setActiveId] = React.useState<UniqueIdentifier | null>(null);
    const activeIndex = activeId ? photos.findIndex((photo) => photo.id === activeId) : undefined;
    const renderPhoto = React.useCallback(
        (props: SortablePhotoProps) => {
            // capture rendered photos for future use in DragOverlay
            renderedPhotos.current[props.photo.id] = props;
            return <SortablePhotoFrame onDelete={onDelete} activeIndex={activeIndex} {...props} />;
        },
        [activeIndex, onDelete]
    );
    const [uploadingPhotos, setUploadingPhotos] = React.useState<AlbumPhoto[]>([]);
    const handleDragStart = React.useCallback(({ active }: DragStartEvent) => {
        if (uploadingPhotos.length)
            return;
        setActiveId(active.id);
    }, [uploadingPhotos.length]);

    const handleDragEnd = React.useCallback((event: DragEndEvent) => {
        const { active, over } = event;
        if (uploadingPhotos.length)
            return;
        if (over && active.id !== over.id) {
            const items = [...photos];
            const oldIndex = items.findIndex((item) => item.id === active.id);
            const newIndex = items.findIndex((item) => item.id === over.id);
            setAlbumPhotos(arrayMove(items, oldIndex, newIndex));
        }
    }, [photos, setAlbumPhotos, uploadingPhotos.length]);

    const onUploadingError = React.useCallback((error: Error) => {
        onError({ title: "Error", message: "Could not upload photos", detail: error.message });
        setUploadingPhotos([]);
    }, [onError]);

    const onUploading = React.useCallback((photos: AlbumPhoto[]) => {
        setUploadingPhotos(photos);
    }, []);

    const onUploaded = React.useCallback((newPhotos: AlbumPhoto[]) => {
        setUploadingPhotos([]);
        setPhotos([...photos, ...newPhotos]);
    }, [photos, setPhotos]);

    return (
        <>
            <Box sx={{
                "& .react-photo-album--row .photo-frame.insertBefore:before": {
                    top: 0,
                    bottom: 0,
                    width: 4,
                    left: -8
                },
                "& .react-photo-album--row .photo-frame.insertAfter:after": {
                    top: 0,
                    bottom: 0,
                    width: 4,
                    right: -8
                },

                "& .react-photo-album--column .photo-frame.insertBefore:before": {
                    left: 0,
                    right: 0,
                    height: 4,
                    top: -8
                },
                "& .react-photo-album--column .photo-frame.insertAfter:after": {
                    left: 0,
                    right: 0,
                    height: 4,
                    bottom: -8
                }

            }}>
                <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragStart={handleDragStart}
                    onDragEnd={handleDragEnd}
                >
                    <SortableContext items={[...photos, ...uploadingPhotos]}>
                        <PhotoAlbum photos={[...photos, ...uploadingPhotos]} layout="rows" spacing={16} renderPhoto={renderPhoto} />
                    </SortableContext>
                    <DragOverlay>{activeId && <PhotoFrame overlay {...renderedPhotos.current[activeId]} />}</DragOverlay>
                </DndContext>
            </Box>
            <PhotoUploader onUploaded={onUploaded} onUploading={onUploading} product_id={product_id} onError={onUploadingError} />
            <ActivityIndicatorDialog open={!!uploadingPhotos.length} message="Loading photos..." />
        </>
    );
}

export default ProductPhotoAlbum;