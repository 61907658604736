import { useQuery } from "@apollo/client/react/hooks/useQuery";
import makeStyles from "@mui/material/styles/makeStyles";
import accounting from "accounting";
import * as React from "react";
import { productQuery } from "./graphql";
import { Product, ProductVariables } from "./__generated__/Product";
import Popper from "@mui/material/Popper/Popper";
import Paper from "@mui/material/Paper/Paper";
import List from "@mui/material/List/List";
import ListItem from "@mui/material/ListItem/ListItem";
import ListItemText from "@mui/material/ListItemText/ListItemText";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider/Divider";
import NumberEditor from "../../components/NumberEditor";
import useHasPermission from "../../app/useHasPermission";

export type QtyEditorProps = {
  productId: string;
  value: number;
  onChanged: (value: number) => void;
  editable: boolean;
  className?: string;
};

const _sx={
  rootList: {
    maxWidth: 400,
    minWidth: 320,
    maxHeight: 800
  },
  selected: {
    backgroundColor: "rgba(0,0,0,.3)",
  },
  popUp: {
    zIndex: 10000,
  },

  inputRoot: {
    flexWrap: "wrap",
  },
  inputInput: {
    width: "auto",
    flexGrow: 1,
  },
  circularProgress: {
    marginLeft: 16
  }
};
function QtyEditor({
  productId,
  value,
  onChanged,
  editable,
  className,
}: QtyEditorProps) {
  const [focusStatus, setFocusStatus] = React.useState(false);

  const anchorEl = React.useRef<HTMLInputElement | null>(null);

  const allowedQuants = useHasPermission("Product_ViewOnHand");
  const { data, loading } = useQuery<Product, ProductVariables>(productQuery, {
    variables: {
      quants:allowedQuants,
      id: productId,
      valuation:false,
      price_history:false,
      purchase_history:false
    },
    skip: !focusStatus,
    fetchPolicy: "network-only",
  });
  const product = data?.productQuery?.product;
  if (!editable) {
    return <>{accounting.formatNumber(value, 0)}</>;
  } else {
    return (
      <>
        <NumberEditor
          size="small"
          numberPrecision={0}
          className={className}
          value={value}
          onValidated={onChanged as any}
          onValidating={(price) => ((price??0) > -1 ? "" : "Qty is invalid.")}
          inputReference={(r) => {
            anchorEl.current = r;
          }}
          onFocus={() => {
            setFocusStatus(true);
          }}
          onBlur={() => {
            setFocusStatus(false);
          }}
        />
        <Popper
          open={focusStatus}
          anchorEl={anchorEl.current}
          placement="bottom-start"
          sx={_sx.popUp}
        >
          <Paper elevation={5}>
            <List dense sx={_sx.rootList}>
              {
                !loading && product ? <><ListItem><ListItemText primary={`${product.qty_available} + ${product.incoming_qty} - ${product.outgoing_qty} = ${product.virtual_available}`} secondary="Total" /></ListItem><Divider /></> : null
              }
              {loading ? <CircularProgress size={24} sx={_sx.circularProgress} color="secondary" /> :
                product?.quants?.map((q) => (<React.Fragment key={q.id}><ListItem >
                  <ListItemText
                    primary={accounting.formatNumber(q.quantity_sum??0, 0)}
                    secondary={q.location_name}
                  />
                </ListItem><Divider /></React.Fragment>))
              }
              {!product?.quants?.length && !loading ? (
                <ListItem>
                  <ListItemText primary="No qty available." />
                </ListItem>
              ) : null}
            </List>
          </Paper>
        </Popper>
      </>
    );
  }
}

export default QtyEditor;
