import Drawer from '@mui/material/Drawer';
import Grid from '@mui/material/Grid';
import React, { useContext, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { UNSAFE_NavigationContext } from "react-router-dom";
import { addHistory, toggleDrawer } from '../features/site/siteSlice';
import { RootState } from './store';
import { BrowserHistory } from "history";
import {Outlet} from 'react-router-dom';
import DrawerHeader from './DrawerHeader';
import Hidden from '@mui/material/Hidden';
import { styled, Theme } from '@mui/material/styles';
import DrawerContent from './DrawerContent';

const drawerWidth = 240;
const openedMixin = (theme: Theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(9)} + 1px)`,
    },
});
const PersistentDrawer = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => {
        const mixin: any = {
            flexShrink: 0,
            whiteSpace: 'nowrap',
            ...(open && {
                ...openedMixin(theme),
                '& .MuiDrawer-paper': openedMixin(theme),
            }),
            ...(!open && {
                ...closedMixin(theme),
                '& .MuiDrawer-paper': closedMixin(theme),
            }),
        };
        return mixin;
    }
);
function Layout() {
    const open = useSelector((state: RootState) => state.site.isDrawerOpen);
    const dispatch = useDispatch();
    const navigation = useContext(UNSAFE_NavigationContext).navigator as BrowserHistory;

    React.useEffect(() => {
        dispatch(addHistory(
            `${window.location.href.replace(window.location.origin, "")}`
        ));
        if (navigation)
            navigation.listen(() => {
                dispatch(addHistory(
                    `${window.location.href.replace(window.location.origin, "")}`
                ));
            })
    }, [dispatch, navigation]);
    const handleToggleDrawer = useCallback(() => {
        dispatch(toggleDrawer());
    }, [dispatch]);
    return (
        <Grid sx={{height:"100%"}} container direction="row" wrap="nowrap">
            <Hidden mdUp implementation="js">
                <Drawer
                    variant="temporary"
                    sx={{ width: drawerWidth, '& .MuiDrawer-paper': { width: drawerWidth,zIndex:1 } }}
                    onClose={handleToggleDrawer}
                    open={open}
                >
                    <DrawerContent />
                </Drawer>
            </Hidden>
            <Hidden mdDown implementation="js">
                <PersistentDrawer
                    variant="permanent"
                    open={open}
                    onClose={handleToggleDrawer}
                >
                    <DrawerHeader open={open} toggleDrawer={handleToggleDrawer} />
                    <DrawerContent />
                </PersistentDrawer>
            </Hidden>
            <Outlet/>
        </Grid>
    );
}

export default Layout