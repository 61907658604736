import { useQuery } from "@apollo/client/react/hooks/useQuery";
import Button from "@mui/material/Button/Button";
import DialogTitle from "@mui/material/DialogTitle/DialogTitle";
import * as React from "react";
import InputAdornment from "@mui/material/InputAdornment/InputAdornment";
import IconButton from "@mui/material/IconButton/IconButton";
import Close from "@mui/icons-material/Close";
import { useDispatch } from "react-redux";

import { saleOrderActions } from "./saleOrdersSlice";
import { openSnackbar } from "../site/siteSlice";
import Dialog from "@mui/material/Dialog";
import TextEditor from "../../components/TextEditor";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogActionLoadingIndicator from "../../components/DialogActionLoadingIndicator";
import { productItemsQuery } from "../common/graphql";
import ProductGrid from "../common/ProductGrid";
import { ProductItems, ProductItemsVariables } from "../common/__generated__/ProductItems";
import useHasPermission from "../../app/useHasPermission";

export type AddOrderLineDialogProps = {
  open: boolean;
  onClose: () => void;
};

const _sx = {
  content: {
    height: 600,
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    borderBottomWidth: 1,
    borderBottomColor: "#fff",
    borderBottomStyle: "solid",
  },
  searchBox: {
    color: "rgba(0, 0, 0, .8)",
    marginLeft: .5,
    marginRight: .5,
    backgroundColor: "#fff",
    borderRadius: 4,
    paddingLeft: 1,
    paddingRight: 1,
  },
  formControl: {
    marginTop: .5,
    marginBottom: .5,
  },
};


function AddOrderLineDialog({ open, onClose }: AddOrderLineDialogProps) {
  const [searchText, setSearchText] = React.useState("");
  const searchBox = React.useRef<any>(null);
  const [orderBy, setOrderBy] = React.useState<string[]>();
  const allowedQuants = useHasPermission("Product_ViewOnHand");
  const variables = React.useMemo(() => {
    var variables: ProductItemsVariables = {
      where: {},
      quants:allowedQuants,
      pagination: { pageSize: 20 },
      orderBy: orderBy as any
    };
    const productId = Number.parseInt(searchText);
    if (searchText) {
      variables.where!.oR = [
        { search_code_Contains: searchText },
        { default_code_Contains: searchText },
        { barcode_Contains: searchText },
        { name_Contains: searchText },
      ];
    }
    if (!isNaN(productId)) {
      if (!variables.where!.oR) {
        variables.where!.oR = [];
      }
      variables.where!.oR.push({ id: productId });
    }
    return variables;
  }, [orderBy, searchText]);

  const { data } = useQuery<ProductItems, ProductItemsVariables>(productItemsQuery, {
    variables,
    fetchPolicy: "cache-only"
  });



  const [selectedIndex, setSelectedIndex] = React.useState(-1);

  React.useEffect(() => {
    setSelectedIndex(0);
  }, [data?.productQuery?.productItems?.edges]);

  const selectedProduct = React.useMemo(() => {
    const products = data?.productQuery?.productItems?.edges ?? [];
    return products[selectedIndex];
  }, [selectedIndex, data?.productQuery?.productItems?.edges])

  const dispatch = useDispatch();
  const addOrderLine = React.useCallback(() => {
    dispatch(
      saleOrderActions.addOrderLine({ product: selectedProduct, qty: 1 })
    );
    dispatch(openSnackbar({ message: `${selectedProduct.default_code} added.`, open: true }));
  }, [dispatch, selectedProduct]);

  const handleCommandKeyUp: React.KeyboardEventHandler<HTMLElement> =
    React.useCallback(
      (e) => {
        const products = data?.productQuery?.productItems?.edges ?? [];
        switch (e.key) {
          case "F1":
          case "F2":
          case "F9":
            e.stopPropagation();
            break;
          case "F3":
            searchBox.current.focus();
            e.stopPropagation();
            break;
          case "Escape":
            e.stopPropagation();
            onClose();
            break;
          case "F4":
            addOrderLine();
            e.stopPropagation()
            break;
          case "Enter":
            addOrderLine();
            e.stopPropagation();
            onClose();
            break;
          case "ArrowUp":
            setSelectedIndex(Math.max(0, selectedIndex - 1));
            e.stopPropagation();
            break;
          case "ArrowDown":
            setSelectedIndex(Math.min(selectedIndex + 1, products.length));
            e.stopPropagation();
            break;
        }
      },
      [data?.productQuery?.productItems?.edges, onClose, addOrderLine, selectedIndex]
    );
  const handleSearchBoxKeyUp: React.KeyboardEventHandler<HTMLElement> = React.useCallback(e => {
    switch (e.key) {
      case "Enter":
        e.stopPropagation();
        break;
    }
  }, []);
  return (
    <Dialog open={open} onKeyUp={handleCommandKeyUp} fullWidth maxWidth="md">
      <DialogTitle>Add Products</DialogTitle>
      <TextEditor
        onKeyUp={handleSearchBoxKeyUp}
        autoFocus
        inputReference={(r) => {
          searchBox.current = r;
        }}
        inputElementType="Input"
        value={searchText}
        sx={_sx.searchBox}
        onValidated={(text) => { setSearchText(text); searchBox.current?.blur(); }}
        placeholder="Search product(F3)"
        inputProps={{ style: { color: "#000" } }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                size="small"
                onClick={() => {
                  setSearchText("");
                }}
              >
                <Close color="primary" fontSize="small" />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <DialogContent sx={_sx.content}>
        <ProductGrid setOrderBy={setOrderBy} variables={variables} onRowClick={(_, index) => { setSelectedIndex(index); }} selectedIndex={selectedIndex} />
      </DialogContent>
      <DialogActions>
        <DialogActionLoadingIndicator loading={false} text={"Loading..."} />
        <Button
          disabled={!selectedProduct}
          color="secondary"
          onClick={addOrderLine}
        >
          Add(F4)
        </Button>
        <Button
          disabled={!selectedProduct}
          color="secondary"
          onClick={() => {
            addOrderLine();
            onClose();
          }}
        >
          Add & Close
        </Button>
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}
export default AddOrderLineDialog;
