import AppBar from "@mui/material/AppBar/AppBar";
import Toolbar from "@mui/material/Toolbar/Toolbar";
import Typography from "@mui/material/Typography/Typography";
import * as React from "react";
import update from "immutability-helper";
import Box from "@mui/material/Box/Box";
import ApolloVirtualizedTable, {
    GridColumn,
    ApolloListResult,
    DefaultPageInfoValue,
    ListItemRenderProps,
    ApolloVirtualizedTableProps,
    CheckBoxColumnMode,
} from "mui-apollo-virtualized-table";
import accounting from "accounting";
import { ApolloConsumer } from "@apollo/client/react/context/ApolloConsumer";
import { ApolloError, useQuery } from "@apollo/client";
import { useSelector } from "react-redux";
import IconButton from "@mui/material/IconButton/IconButton";
import Tooltip from "@mui/material/Tooltip/Tooltip";
import Print from "@mui/icons-material/Print";
import Download from "@mui/icons-material/CloudDownload";
import { ProductType } from "../../types/global-types";
import { RootState } from "../../app/store";
import useHasPermission from "../../app/useHasPermission";
import LabelPrinter, { LabelData } from "../../app/LabelPrinter";
import DrawerToggleButton from "../../app/DrawerToggleButton";
import CsSearchBox, { OperandType } from "../../components/CsSearchBox";
import ActivityIndicatorDialog from "../../components/ActivityIndicatorDialog";
import ErrorMessageDialog from "../../components/ErrorMessageDialog";
import FileDownloader, { FileDownloaderHandle } from "../../components/FileDownloader";
import CompanyMultiSelectorMenu from "../common/CompanyMultiSelectorMenu";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider/Divider";
import Skeleton from "@mui/material/Skeleton";
import { Outlet, useMatch, useNavigate, useOutletContext, useParams } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from '@mui/material/styles';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { ProductItems, ProductItemsVariables, ProductItems_productQuery_productItems_edges } from "../common/__generated__/ProductItems";
import { productItemsQuery } from "../common/graphql";

const ProductVirtualizedGrid = ApolloVirtualizedTable as React.ElementType<ApolloVirtualizedTableProps<ProductItems_productQuery_productItems_edges>>;


export type ProductOutletContextType = {
    onError: (message: ApolloError, operationErrorMessage: string) => void;
    onPriceChanged: (savedProductId: string) => void;
    company_ids?: number[];
};

export function useProductOutletContext() {
    return useOutletContext<ProductOutletContextType>();
}

function PadLeft(n: string, width: number, z: string = "0") {
    z = z || "0";
    n = n + "";
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}
const _sx = {
    title: {
        marginRight: 1,
        display: { md: "block", xs: "none" }
    },
    box: {
        backgroundColor: "rgba(255,255,255,.1)",
        maxHeight: "calc(100% - 48px)"
    },
    root: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        flexWrap: "nowrap",
    },
    loadingIndicator: {
        width: 150,
        display: "inline-block"
    }
};

const defaultColumns: ReadonlyArray<GridColumn<ProductItems_productQuery_productItems_edges>> = [
    {
        label: "Model No",
        key: "default_code",
        width: 200,
        sortable: true,
    },
    {
        label: "Name",
        key: "name",
        width: 250,
        flexGrow: 1,
        sortable: true,
    },
    {
        label: "Price",
        key: "list_price",
        width: 150,
        textAlign: "right",
        labelAlign: "right",
        sortable: true,
        format: ({ rowData }) => accounting.formatNumber(rowData.list_price ?? 0, 0),
    },
    {
        label: "Act",
        key: "quantity",
        labelAlign: "right",
        width: 100,
        textAlign: "right",
        sortable: true,
        hideAt: 1300,
        format: ({ rowData }) =>
            `${accounting.formatNumber(rowData.quantity ?? 0, 0)}`,
    },
    {
        label: "Res",
        key: "reserved_quantity",
        labelAlign: "right",
        width: 100,
        textAlign: "right",
        sortable: true,
        hideAt: 1300,
        format: ({ rowData }) =>
            `${accounting.formatNumber(rowData.reserved_quantity ?? 0, 0)}`,
    },
    {
        label: "Ava",
        key: "qty_available",
        labelAlign: "right",
        width: 75,
        textAlign: "right",
        sortable: true,
        format: ({ rowData }) =>accounting.formatNumber((rowData.qty_available ?? 0)),
    },
    {
        label: "Unit",
        key: "uom",
        width: 100,
        sortable: true,
        hideAt: 900
    },
    {
        label: "Supplier",
        key: "supplier",
        width: 150,
        hideAt:1000,
        sortable: true,
    }
];

function ProductBrowser() {

    const hasValuationAccess = useHasPermission("Product_Valuation");
    const hasMultiCompanyAccess = useHasPermission("Product_MultiCompany");
    const allowedQuants = useHasPermission("Product_ViewOnHand");
    const [variables, setVariables] = React.useState<ProductItemsVariables>({ pagination: { pageSize: 40 }, includeValuation: hasValuationAccess, quants:allowedQuants, where: { type: ProductType.product } });
    const [operationError, setOperationError] = React.useState<Error | null>(null);
    const [operationErrorMessage, setOperationErrorMessage] =
        React.useState<string>("");
    const [errorMessageDialogOpen, setErrorMessageDialogOpen] =
        React.useState(false);
    const labelPrinterUrl = useSelector((state: RootState) => state.site.labelPrinterUrl)
    const labelPrinter = React.useRef<LabelPrinter>(
        new LabelPrinter(labelPrinterUrl)
    );
    const [changedFlag, setChangedFlag] = React.useState(0);

    const [columns, setColumns] = React.useState<
        ReadonlyArray<GridColumn<ProductItems_productQuery_productItems_edges>>
    >(defaultColumns);

    const { id: productId } = useParams<{ id: string }>();

    React.useEffect(() => {
        if (hasValuationAccess) {
            setColumns([...defaultColumns, {
                label: "Cost",
                key: "current_cost",
                width: 150,
                textAlign: "right",
                labelAlign: "right",
                sortable: true,
                hideAt: 800,
                format: ({ rowData }) => accounting.formatNumber(rowData.current_cost ?? 0, 0),
            }, {
                label: "Margin",
                key: "current_margin",
                width: 150,
                textAlign: "right",
                labelAlign: "right",
                sortable: true,
                hideAt: 900,
                format: ({ rowData }) => accounting.formatNumber(rowData.current_margin ?? 0, 0),
            }]);
        } else {
            setColumns(defaultColumns);
        }
    }, [hasValuationAccess]);

    const {
        data: productsQueryResult
    } = useQuery<ProductItems, ProductItemsVariables>(productItemsQuery, { variables, fetchPolicy: "cache-only" });
    const [selected, setSelected] = React.useState<ReadonlyArray<number>>([]);
    const [selectAll, setSelectAll] = React.useState(false);
    const handleSelectAll = React.useCallback((selected: number[]) => {
        setSelectAll(true);
        setSelected(selected);
    }, [setSelected, setSelectAll]);
    const handleClearSelectAll = React.useCallback(() => {
        setSelectAll(false);
        setSelected([]);
    }, [setSelected, setSelectAll]);
    const navigate = useNavigate();
    const handleOnRowClick = React.useCallback((_: any, index: number) => {
        setSelected([index]);
        setSelectAll(false);
    }, []);
    const selectedProduct = React.useMemo(() => {
        const index = selected?.length ? selected[0] : -1;
        const products = productsQueryResult?.productQuery?.productItems?.edges;
        const selectedProduct = products?.length ? products[index] : null;
        return selectedProduct;
    }, [productsQueryResult?.productQuery?.productItems?.edges, selected]);

    React.useEffect(() => {
        if (selectedProduct && selectedProduct?.id?.toString() !== productId) {
            navigate(`/products/${selectedProduct.id}`)
        }
    }, [navigate, productId, selectedProduct]);

    const [labelPrinting, setLabelPrinting] = React.useState(false);
    const handlePrintLabels = React.useCallback(async () => {
        if (labelPrinting)
            return;
        setLabelPrinting(true);
        try {
            const products = productsQueryResult?.productQuery?.productItems?.edges ?? [];
            if (!products?.length || !selected.length)
                return;
            const labelDatas: LabelData[] = [];
            selected.forEach(selectedIndex => {
                const { default_code, barcode, id, list_price } = products[selectedIndex];
                let ownerId = "999";
                let productId = PadLeft(id?.toString() ?? "", 7);
                const labelData: LabelData = {
                    StockCode: default_code ?? barcode ?? "",
                    PurchaseDate: new Date(),
                    SerialNo: `${ownerId}${productId}`,
                    VoucherNo: `${accounting.formatNumber(list_price ?? 0, 0)} MMK`,
                    Copy: 1
                };
                labelDatas.push(labelData);
            });
            await labelPrinter.current.print(labelDatas, "pricelabel");
        } catch (e: any) {
            setOperationError(e);
            setOperationErrorMessage("Could not print label.");
            setErrorMessageDialogOpen(true);
        } finally {
            setLabelPrinting(false)
        }
    }, [selected, productsQueryResult, setLabelPrinting, labelPrinting]);
    const handleColumnsPropsChanged = React.useCallback((columns: ReadonlyArray<GridColumn<ProductItems_productQuery_productItems_edges>>, orderBy: string[]) => {
        setColumns(columns);
        setVariables({ ...variables, orderBy: orderBy as any });
    }, [setVariables, setColumns, variables]);

    const fileDownloader = React.useRef<FileDownloaderHandle>(null);
    const handleExcelFileDownload = React.useCallback(() => {
        fileDownloader?.current?.download();
    }, []);

    const onCompanySelect = React.useCallback((value: number[]) => {
        const newVariables = update(variables, {
            company_ids: {
                $set: (value.length ? value : null)
            }
        });
        setVariables(newVariables);
    }, [
        variables,
        setVariables
    ]);

    const listItemRenderer = React.useCallback(
        (renderProps: ListItemRenderProps<ProductItems_productQuery_productItems_edges>) => {
            const { rowData, key, style, className, onClick } = renderProps;
            if (rowData) {
                const { default_code, name } = rowData;
                return (
                    <div onClick={onClick} style={style} className={className} key={key}>
                        <ListItem>
                            <ListItemText primary={default_code} secondary={name} />
                        </ListItem>
                        <Divider />
                    </div>
                );
            } else {
                return (
                    <div style={style} key={key}>
                        <ListItem>
                            <ListItemText
                                primary={
                                    <Skeleton sx={_sx.loadingIndicator} />
                                }
                                secondary={
                                    <Skeleton sx={_sx.loadingIndicator} />
                                }
                            />
                        </ListItem>
                        <Divider />
                    </div>
                );
            }
        },
        []
    );

    const isProductDetailRoute = useMatch("products/:id");
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const backToList = React.useCallback(() => {
        navigate("/products", { replace: true });
    }, [navigate]);

    return (
        <>
            <Box sx={_sx.root}>
                <AppBar position="static" >
                    <Toolbar sx={(theme) => ({
                        [theme.breakpoints.up("md")]: {
                            minHeight: 6,
                        },
                        paddingLeft: 1.5,
                    })}>
                        <DrawerToggleButton />
                        {
                            isMobile && isProductDetailRoute ? < Tooltip title="Back to List">
                                <IconButton
                                    color="inherit"
                                    onClick={backToList}
                                >
                                    <ArrowBack />
                                </IconButton>
                            </Tooltip> : null
                        }
                        <Typography
                            variant="h6"
                            sx={_sx.title}
                            color="inherit"
                            noWrap
                        >
                            {
                                isMobile && selectedProduct && isProductDetailRoute ? selectedProduct?.default_code : "Products"
                            }
                        </Typography>
                        {
                            isMobile && isProductDetailRoute ? <Box flex={1} /> : <CsSearchBox
                                onConditionChanged={(conditions: any) => {
                                    setVariables(
                                        update(variables, {
                                            where: {
                                                $set: { ...variables.where, aND: conditions },
                                            },
                                        })
                                    );
                                }}
                                operands={{
                                    default_code: {
                                        name: "Model#",
                                        propName: "default_code",
                                        type: OperandType.STRING,
                                    },
                                    search_code: {
                                        name: "Search",
                                        propName: "search_code",
                                        type: OperandType.STRING,
                                    },
                                    barcode: {
                                        name: "Barcode",
                                        propName: "barcode",
                                        type: OperandType.STRING,
                                    },
                                    name: {
                                        name: "Name",
                                        propName: "name",
                                        type: OperandType.STRING
                                    },
                                    supplier: {
                                        name: "Supplier",
                                        propName: "supplier",
                                        type: OperandType.STRING
                                    },
                                    supplier_category: {
                                        name: "Supplier Category",
                                        propName: "supplier_category",
                                        type: OperandType.STRING
                                    },
                                    id: {
                                        name: "Id",
                                        propName: "id",
                                        type: OperandType.STRING,
                                    },
                                    list_price: {
                                        name: "Price",
                                        propName: "list_price",
                                        type: OperandType.NUMBER,
                                    },
                                    current_cost: {
                                        name: "Cost",
                                        propName: "current_cost",
                                        type: OperandType.NUMBER,
                                    },
                                    current_margin: {
                                        name: "Margin",
                                        propName: "current_margin",
                                        type: OperandType.NUMBER,
                                    },
                                    salezone:{
                                        name:"Sale Zone",
                                        propName:"salezone",
                                        type:OperandType.STRING
                                    },
                                    brand:{
                                        name:"Brand",
                                        propName:"brand",
                                        type:OperandType.STRING
                                    }
                                }}
                            />
                        }
                        {hasMultiCompanyAccess ? <CompanyMultiSelectorMenu value={variables.company_ids ?? []} onSelect={onCompanySelect} /> : null}
                        <FileDownloader ref={fileDownloader} url={`/excel/products/${variables.company_ids?.join(",")}`} json={JSON.stringify(variables?.where)}>
                            <Tooltip title="Download Excel">
                                <IconButton
                                    color="inherit"
                                    onClick={handleExcelFileDownload}
                                >
                                    <Download />
                                </IconButton>
                            </Tooltip>
                        </FileDownloader>
                        {
                            selected?.length ? <Tooltip title="Print Label">
                                <IconButton
                                    color="inherit"
                                    onClick={handlePrintLabels}
                                >
                                    <Print />
                                </IconButton>
                            </Tooltip> : null
                        }

                    </Toolbar>
                </AppBar>
                <Box
                    sx={_sx.box}
                    flex={1}
                    display="flex"
                    flexDirection="row"
                >
                    {
                        isMobile && isProductDetailRoute ? null : (<ApolloConsumer>
                            {(client) => (
                                <ProductVirtualizedGrid
                                    listItemRenderer={listItemRenderer}
                                    extraData={changedFlag}
                                    checkBoxColumnMode={CheckBoxColumnMode.first}
                                    apolloClient={client as any}
                                    listItemHeight={72}
                                    listModeBreakPoint={560}
                                    columns={columns}
                                    graphqlQuery={productItemsQuery}
                                    selectedItems={selected}
                                    onRowClick={handleOnRowClick}
                                    setSelectedItems={setSelected}
                                    selectedAll={selectAll}
                                    setSelectedAll={handleSelectAll}
                                    clearSelectedAll={handleClearSelectAll}
                                    variables={variables}
                                    pageSize={variables?.pagination?.pageSize!}
                                    onColumnPropsChanged={handleColumnsPropsChanged}
                                    parseListFromQueryResult={(queryResult: ProductItems) => {
                                        const list: ApolloListResult<ProductItems_productQuery_productItems_edges> =
                                            queryResult && queryResult.productQuery
                                                ? queryResult.productQuery.productItems
                                                : {
                                                    edges: [],
                                                    pageInfo: DefaultPageInfoValue,
                                                };
                                        return list;
                                    }}
                                    onLoadMore={(pageInfo) => {
                                        return {
                                            ...variables,
                                            pagination: {
                                                page: pageInfo.page,
                                                pageSize: pageInfo.pageSize,
                                            },
                                        };
                                    }}
                                />
                            )}
                        </ApolloConsumer>)
                    }
                    <Outlet context={{
                        company_ids: variables.company_ids ?? undefined, onError: (e: ApolloError, operationErrorMessage: string) => {
                            setOperationError(e);
                            setOperationErrorMessage(operationErrorMessage);
                            setErrorMessageDialogOpen(true);
                        },
                        onPriceChanged: () => { setChangedFlag(changedFlag + 1) }
                    }} />
                </Box>
            </Box>
            {
                operationError ? (
                    <ErrorMessageDialog
                        open={errorMessageDialogOpen}
                        onClose={() => {
                            setErrorMessageDialogOpen(false);
                        }}
                        error={{
                            title:"Error",
                            message: operationErrorMessage,
                            detail:operationError.message
                        }}
                    />
                ) : null
            }
            <ActivityIndicatorDialog open={labelPrinting} message="Printing..." />
        </>
    );
}

export default ProductBrowser;