import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as React from "react";
import PriceListAutoComplete from "./features/common/PriceListAutoComplete";
import ProductAutoComplete from "./features/common/ProductAutoComplete";
import { PriceLists_productQuery_priceLists } from "./features/common/__generated__/PriceLists";
import DiscountValueEditor, { DiscountValue } from "./features/promotion/DiscountValueEditor";

function Testing() {
    const [product, setProduct] = React.useState<any>(null);
    const [discount, setDiscount] = React.useState<DiscountValue | undefined>(undefined);
    const [priceList, setPriceList] = React.useState<PriceLists_productQuery_priceLists | null>(null);
    return <Box flex={1}>
        <Stack width={400} spacing={2} marginTop={2}>
            <ProductAutoComplete label="Product" placeholder="Search product" size="small" fullWidth value={product} onSelected={setProduct} />
            <DiscountValueEditor 
                    label="Discount"
                    placeholder="Discount" value={discount} onValidated={setDiscount} size="small" fullWidth />
            <Box>
                <Typography>{discount?.discount_mode}</Typography>
                <Typography>{discount?.discount}</Typography>
            </Box>
            <PriceListAutoComplete
                label="Price List"
                placeholder="Price List" selectedPriceList={priceList} size="small" onChange={setPriceList} />
        </Stack>
    </Box>
}

export default Testing;