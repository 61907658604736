import { useQuery } from "@apollo/client";
import ListItemText from "@mui/material/ListItemText/ListItemText";
import TextField, { TextFieldProps } from "@mui/material/TextField/TextField";
import {Theme} from "@mui/material/styles";
import Autocomplete, {
  createFilterOptions,
} from "@mui/material/Autocomplete/Autocomplete";
import * as React from "react";
import { journalsQuery } from "./graphql";
import {
  Journals,
  JournalsVariables,
  Journals_commonQuery_journals,
} from "./__generated__/Journals";
import { JournalType } from "../../types/global-types";
import { ListItem, SxProps } from "@mui/material";

export type JournalAutoCompleteProps = {
  selectedJournal: Journals_commonQuery_journals|null;
  onSelected: (jounal: Journals_commonQuery_journals|null) => void;
  className?: string;
  journalTypes?: JournalType[];
  disabled?:boolean;
} & Pick<TextFieldProps, "size" | "label" | "sx" | "error" | "helperText">;;

const filterOptions = createFilterOptions({
  stringify: (option: Journals_commonQuery_journals) =>
    `${option.code} ${option.name}`,
});
function JournalAutoComplete({
  selectedJournal,
  onSelected,
  className,
  journalTypes,
  label,
  disabled,
  sx,
  error,
  helperText,
  size
}: JournalAutoCompleteProps) {
  const { data, loading } = useQuery<Journals, JournalsVariables>(
    journalsQuery,
    {
      variables: {
        journalType: journalTypes,
      },
    }
  );
  return (
    <Autocomplete
      value={selectedJournal}
      onChange={(_, value: Journals_commonQuery_journals|null) => {
        onSelected(value);
      }}
      disabled={disabled}
      filterOptions={filterOptions}
      includeInputInList
      clearOnBlur
      selectOnFocus
      isOptionEqualToValue={(option, value) => option.id === value.id}
      loading={loading}
      getOptionLabel={(option) => `${option.code} ${option.name}`}
      renderOption={(props,option) => (
        <ListItem {...props}><ListItemText primary={option.code} secondary={option.name} /></ListItem>
      )}
      options={
        data?.commonQuery?.journals ??
        (selectedJournal ? [selectedJournal] : [])
      }
      renderInput={(params) => (
        <TextField
          {...params}
          error={error}
          helperText={helperText}
          autoComplete="off"
          size={size}
          label={label}
          className={className}
          sx={sx}
        />
      )}
    />
  );
}

export default JournalAutoComplete;
