import gql from "graphql-tag";

export const makePaymentMutation = gql`
mutation MakePayment($payment:AccountPaymentInput!){
  accountingMutation{
    makePayment(payment:$payment){
      id
      team_id{
        id
        display_name
      }
      date
      payment_type
      partner_type
      amount
      name
      journal_id{
        id
        display_name
      }
      destination_journal_id{
        id
        display_name
      }
      invoice_origin
      ref      
    }
  }
}
`;