import { useQuery } from "@apollo/client";
import ListItemText from "@mui/material/ListItemText/ListItemText";
import TextField, { TextFieldProps } from "@mui/material/TextField/TextField";
import Autocomplete, {
  createFilterOptions,
} from "@mui/material/Autocomplete/Autocomplete";
import * as React from "react";
import { warehousesQuery } from "./graphql";
import {
  Warehouses, Warehouses_inventoryQuery_warehouses
} from "./__generated__/Warehouses";
import ListItem from "@mui/material/ListItem";

export type WarehouseAutoCompleteProps = {
  selectedWarehouse: Warehouses_inventoryQuery_warehouses | null;
  onSelected: (jounal: Warehouses_inventoryQuery_warehouses | null) => void;
  className?: string;
} & Pick<TextFieldProps, "size" | "label" | "sx">;

const filterOptions = createFilterOptions({
  stringify: (option: Warehouses_inventoryQuery_warehouses) =>
    `${!option.code ? "" : option.code + " "}${option.name}`,
});
function WarehouseAutoComplete({
  selectedWarehouse,
  onSelected,
  className,
  label,
  size,
  sx
}: WarehouseAutoCompleteProps) {
  const { data, loading } = useQuery<Warehouses, {}>(
    warehousesQuery
  );
  return (
    <Autocomplete
      value={selectedWarehouse}
      onChange={(_, value: Warehouses_inventoryQuery_warehouses | null) => {
        onSelected(value);
      }}
      filterOptions={filterOptions}
      includeInputInList
      clearOnBlur
      selectOnFocus
      isOptionEqualToValue={(option, value) => option.id === value.id}
      loading={loading}
      getOptionLabel={(option) => `${!option.code ? "" : option.code + " "}${option.name}`}
      renderOption={(props, option) => (
        <ListItem {...props}>
          <ListItemText primary={option.code} secondary={option.name} />
        </ListItem>
      )}
      options={
        data?.inventoryQuery?.warehouses ??
        (selectedWarehouse ? [selectedWarehouse] : [])
      }
      renderInput={(params) => (
        <TextField
          {...params}
          size={size}
          sx={sx}
          autoComplete="off"
          label={label}
          className={className}
        />
      )}
    />
  );
}

export default WarehouseAutoComplete;
