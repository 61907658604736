import { useQuery } from "@apollo/client";
import ListItemText from "@mui/material/ListItemText/ListItemText";
import TextField, { TextFieldProps } from "@mui/material/TextField/TextField";
import Autocomplete, {
  createFilterOptions,
} from "@mui/material/Autocomplete/Autocomplete";
import * as React from "react";
import { saleTeamsQuery } from "./graphql";
import { SaleTeams, SaleTeams_commonQuery_saleTeams } from "./__generated__/SaleTeams";
import ListItem from "@mui/material/ListItem";

export type SaleTeamAutoCompleteProps = {
  selectedSaleTeam: SaleTeams_commonQuery_saleTeams | null;
  onSelected: (jounal: SaleTeams_commonQuery_saleTeams | null) => void;
  className?: string;
} & Pick<TextFieldProps, "size" | "label" | "sx">;

const filterOptions = createFilterOptions({
  stringify: (option: SaleTeams_commonQuery_saleTeams) =>
    option.name,
});
function SaleTeamAutoComplete({
  selectedSaleTeam,
  onSelected,
  className,
  label,
  size,
  sx
}: SaleTeamAutoCompleteProps) {
  const { data, loading } = useQuery<SaleTeams, {}>(
    saleTeamsQuery
  );
  return (
    <Autocomplete
      value={selectedSaleTeam}
      onChange={(_, value: SaleTeams_commonQuery_saleTeams | null) => {
        onSelected(value);
      }}
      filterOptions={filterOptions}
      includeInputInList
      clearOnBlur
      selectOnFocus
      isOptionEqualToValue={(option, value) => option.id === value.id}
      loading={loading}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option) => (
        <ListItem {...props}>
          <ListItemText primary={option.name} />
        </ListItem>
      )}
      options={
        data?.commonQuery?.saleTeams ??
        (selectedSaleTeam ? [selectedSaleTeam] : [])
      }
      renderInput={(params) => (
        <TextField
          {...params}
          autoComplete="off"
          sx={sx}
          size={size}
          label={label}
          className={className}
        />
      )}
    />
  );
}

export default SaleTeamAutoComplete;
