import React, { useState, createContext, useContext } from 'react';
import ActivityIndicatorDialog from '../components/ActivityIndicatorDialog';
import ConfirmDialog from '../components/ConfirmDialog';
import ErrorMessageDialog, { ErrorInfo } from '../components/ErrorMessageDialog';
export type AppContextType = {
    showInfo: (error: ErrorInfo) => void;
    showError: (message: string, error: any) => void;
    showActivityDialog: (message: string) => void;
    hideActivityDialog: () => void;
    requestComfirmation: (request: RequestComfirmationPayload) => void;
}
export type RequestComfirmationPayload = {
    title: string;
    message: string;
    acceptLabel?: string;
    rejectLabel?: string;
    callback: (result: boolean) => void;
}
const AppContext = createContext<AppContextType>({} as any);
export const AppContextProvider = (props: React.PropsWithChildren) => {
    const [errorInfo, setErrorInfo] = useState<ErrorInfo | null>(null)
    const [activityMessage, setActivityMessage] = useState<string | null>(null);
    const [confirmation, setConfirmation] = useState<RequestComfirmationPayload | null>(null);
    return (
        <AppContext.Provider value={{
            showInfo: setErrorInfo,
            showError: (message: string, error: any) => {
                var detail = "";
                if (error instanceof Error) detail = error.message;
                else
                    detail = error.toString();
                setErrorInfo({ title: "Error", message, detail });
            },
            showActivityDialog: (message: string) => { setActivityMessage(message); },
            hideActivityDialog: () => { setActivityMessage(null); },
            requestComfirmation: setConfirmation
        }}>
            {props.children}
            {errorInfo ? <ErrorMessageDialog onClose={() => { setErrorInfo(null); }} open={!!errorInfo} error={errorInfo} /> : null}
            <ActivityIndicatorDialog open={!!activityMessage} message={activityMessage ?? ""} />
            <ConfirmDialog title={confirmation?.title ?? ""} message={confirmation?.message ?? ""} rejectLabel={confirmation?.rejectLabel ?? "Cancel"} acceptLabel={confirmation?.acceptLabel ?? "OK"} open={!!confirmation} onRejected={() => { setConfirmation(null); confirmation?.callback(false); }} onAccepted={() => { setConfirmation(null); confirmation?.callback(true); }} />
        </AppContext.Provider>
    );
}

const useAppContext = () => useContext(AppContext);
export default useAppContext;