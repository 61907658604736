import { useQuery } from "@apollo/client";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import * as React from "react";
import { companyQuery } from "./graphql";
import { Company } from "./__generated__/Company";
import update from "immutability-helper";
import Button from "@mui/material/Button/Button";
import Badge from "@mui/material/Badge/Badge";
import Menu from "@mui/material/Menu/Menu";
import ExpandMore from "@mui/icons-material/ExpandMore";

export type CompanyMultiSelectorMenuProps = {
    value: number[];
    onSelect: (value: number[]) => void;
};
function CompanyMultiSelectorMenu({ value, onSelect }: CompanyMultiSelectorMenuProps) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const handleSelectorClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = React.useCallback(() => {
        setAnchorEl(null);
    }, [setAnchorEl]);
    const { data: companyQueryResult } = useQuery<Company, {}>(companyQuery);
    const menus = React.useMemo(() => {
        return companyQueryResult?.commonQuery?.companies?.map((c) => {
            const { id, name } = c!;
            const newValue = value ? [...value] : [];
            const v = Number.parseInt(id!);
            return (<MenuItem
                key={id}
                selected={newValue?.includes(v)}
                onClick={() => {
                    if (newValue?.includes(v))
                        onSelect(update(newValue, { $splice: [[newValue?.indexOf(v), 1]] }));
                    else
                        onSelect([...newValue, v]);
                }}
            >{name}</MenuItem>);
        });
    }, [companyQueryResult, value, onSelect]);
    return (<><Button
        aria-controls="type-menu"
        aria-haspopup="true"
        color="inherit"
        onClick={handleSelectorClick}
        endIcon={<ExpandMore />}
    >
        <Badge
            color="secondary"
            badgeContent={value?.length === 0 ? null : value?.length}
        >
            Company
        </Badge>
    </Button>
        <Menu
            id="type-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorReference="anchorEl"
        >
            {menus}
        </Menu></>);
}

export default CompanyMultiSelectorMenu;