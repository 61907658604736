import { useQuery } from "@apollo/client";
import ListItemText from "@mui/material/ListItemText/ListItemText";
import TextField, { TextFieldProps } from "@mui/material/TextField/TextField";
import Autocomplete, {
  createFilterOptions,
} from '@mui/material/Autocomplete';
import * as React from "react";
import { accountingAccountsQuery } from "./graphql";
import { Accounts, AccountsVariables, Accounts_accountingQuery_accountingAccounts_edges } from "./__generated__/Accounts";
import { AccountType, AccountingAccountWhereInput } from "../../types/global-types";
import ListItem from "@mui/material/ListItem";

export type AccountingAccountOptionValue =Partial<Omit<Accounts_accountingQuery_accountingAccounts_edges, "id">> &  {
  display_name?: string | null;
  id: string;
}

export type AccountingAccountAutoCompleteProps = {
  selectedAccountingAccount?: AccountingAccountOptionValue | null;
  onSelected: (account: AccountingAccountOptionValue | null) => void;
  className?: string;
  accountTypes?: AccountType[];
  tags?: number[]
} & TextFieldProps;

const filterOptions = createFilterOptions({
  stringify: ({display_name, code, name}: AccountingAccountOptionValue) =>
    display_name??`${code} ${name}`,
});
function AccountingAccountAutoComplete({
  selectedAccountingAccount,
  onSelected,
  className,
  accountTypes,
  label,
  tags,
  sx,
  size,  
}: AccountingAccountAutoCompleteProps) {
  const [search, setSearch] = React.useState("");
  const variables = React.useMemo(() => {
    const variables: AccountsVariables = { where:{} };
    const where: AccountingAccountWhereInput = {};
    if (accountTypes) {
      where.account_type_In = accountTypes
    }
    if (!!tags?.length) {
      variables.tags = tags;
    }
    if(search){
      where.oR = [{code_Contains:search}, {name_Contains:search}]
    }
    if(Object.keys(where).length>0){
      variables.where = where;
    }
    return variables;
  }, [accountTypes, search, tags]);
  const { data, loading } = useQuery<Accounts, AccountsVariables>(
    accountingAccountsQuery,
    {
      variables,
      skip: search === (selectedAccountingAccount?.display_name??`${selectedAccountingAccount?.code} ${selectedAccountingAccount?.name}`)
    }
  );
  return (
    <Autocomplete
      value={selectedAccountingAccount}
      onChange={(_, value: AccountingAccountOptionValue | null) => {
        onSelected(value);
      }}
      filterOptions={filterOptions}
      includeInputInList
      clearOnBlur
      selectOnFocus
      loading={loading}
      isOptionEqualToValue={(option, value) => option.id.toString() === value.id.toString()}
      getOptionLabel={({display_name,code,name}) => display_name??`${code} ${name}`}
      renderOption={(props, option) => (
        <ListItem {...props}><ListItemText primary={option?.code} secondary={option?.name} /></ListItem>
      )}
      options={
        (selectedAccountingAccount ? [selectedAccountingAccount,...(data?.accountingQuery?.accountingAccounts?.edges??[])] : [...(data?.accountingQuery?.accountingAccounts?.edges??[])])
      }
      onInputChange={(_, value) => { setSearch(value); }}
      renderInput={(params) => (
        <TextField
          {...params}
          autoComplete="off"
          sx={sx}
          size={size}
          label={label}
          className={className}
        />
      )}
    />
  );
}

export default AccountingAccountAutoComplete;
