import Button from "@mui/material/Button/Button";
import Dialog from "@mui/material/Dialog/Dialog";
import DialogActions from "@mui/material/DialogActions/DialogActions";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import DialogTitle from "@mui/material/DialogTitle/DialogTitle";
import * as React from "react";
import { SaleTeams_commonQuery_saleTeams } from "../../common/__generated__/SaleTeams";
import SaleTeamAutoComplete from "../../common/SaleTeamAutoComplete";
import { useSelector } from "react-redux";
import format from "date-fns/format";
import { DateRangeWithLabel } from "../../../components/CsDateRangeEditor";
import { RootState } from "../../../app/store";

export type ReportDialogProps = {
    open: boolean;
    onClose: () => void;
    dateRange: DateRangeWithLabel
}
function ReportDialog({ open, onClose, dateRange }: ReportDialogProps) {
    const saleTeamFromSetting = useSelector((state: RootState) => state.saleSetting.saleTeam);
    const [saleTeam, setSaleTeam] = React.useState<SaleTeams_commonQuery_saleTeams | null>(saleTeamFromSetting);
    const openReport = React.useCallback(() => {
        const from = format(dateRange.value.from, "yyyy-MM-dd");
        const to = format(dateRange.value.to, "yyyy-MM-dd");
        const url = `/report/sale/from/${from}/to/${to}/team/${saleTeam?.name}`;
        window.open(url, "_blank");
    }, [saleTeam, dateRange]);
    return (<Dialog open={open} onClose={onClose}>
        <DialogTitle>Print Sale Detail Report</DialogTitle>
        <DialogContent>
            <SaleTeamAutoComplete selectedSaleTeam={saleTeam} onSelected={setSaleTeam} />
        </DialogContent>
        <DialogActions>
            <Button disabled={!saleTeam} onClick={openReport} color="secondary">
                Submit
            </Button>
            <Button onClick={onClose}>
                Cancel
            </Button>
        </DialogActions>
    </Dialog>)
}

export default ReportDialog;