import { ApolloConsumer, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import Button from "@mui/material/Button/Button";
import Dialog from "@mui/material/Dialog/Dialog";
import DialogActions from "@mui/material/DialogActions/DialogActions";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import DialogTitle from "@mui/material/DialogTitle/DialogTitle";
import * as React from "react";
import { Townships_regionQuery_townships } from "../common/__generated__/Townships";
import { customersQuery, savePartnerMutation } from "./graphql";
import update from "immutability-helper";
import {
  Customers,
  CustomersVariables,
  Customers_partnerQuery_customers_edges,
} from "./__generated__/Customers";
import { SavePartner, SavePartnerVariables } from "./__generated__/SavePartner";
import ListItem from "@mui/material/ListItem/ListItem";
import ListItemText from "@mui/material/ListItemText/ListItemText";
import Divider from "@mui/material/Divider/Divider";
import Grid from "@mui/material/Grid/Grid";
import TextField from "@mui/material/TextField/TextField";
import InputAdornment from "@mui/material/InputAdornment/InputAdornment";
import IconButton from "@mui/material/IconButton/IconButton";
import Close from "@mui/icons-material/Close";
import TownshipAutoComplete from "../common/TownshipAutoComplete";
import ApolloVirtualizedTable, { ApolloListResult, ApolloVirtualizedTableProps, DefaultPageInfoValue, GridColumn, ListItemRenderProps } from "mui-apollo-virtualized-table";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import Skeleton from "@mui/material/Skeleton";
import { ContactTitles_commonQuery_contactTitles } from "../common/__generated__/ContactTitles";
import { PaginationInput, PartnerInput, PartnerWhereInput } from "../../types/global-types";
import TextEditor from "../../components/TextEditor";
import Typography from "@mui/material/Typography";
import DialogActionLoadingIndicator from "../../components/DialogActionLoadingIndicator";
const CustomerVirtualizedGrid = ApolloVirtualizedTable as React.ElementType<ApolloVirtualizedTableProps<Customers_partnerQuery_customers_edges>>;
type EditValue<T> = {
  value?: T;
  changed?: boolean;
  error?: string;
};

export type CustomerDialogProps = {
  open: boolean;
  onClose: () => void;
  onSelected: (customer: Customers_partnerQuery_customers_edges) => void;
  selectedCustomerId?: string;
  disableSelection: boolean;
};

const _sx = {
  content: {
    height: 600,
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    borderBottomWidth: 1,
    borderBottomColor: "#fff",
    borderBottomStyle: "solid",
  },
  customerView: {
    width: 400,
    paddingLeft: 3,
    borderLeftWidth: 1,
    borderLeftColor: "#fff",
    borderLeftStyle: "solid",
  },
  searchBox: {
    color: "rgba(0, 0, 0, .8)",
    marginLeft: .5,
    marginRight: .5,
    backgroundColor: "#fff",
    borderRadius: 4,
    paddingLeft: 1,
    paddingRight: 1,
  },
  formControl: {
    marginTop: 1,
    marginBottom: 1,
  },
};

type CustomerGridProps = {
  variables: CustomersVariables;
  onClick: (index: number) => void;
  selectedIndex: number;
};

function CustomerGrid({
  variables = { pagination: { pageSize: 40 } },
  onClick,
  selectedIndex,
}: CustomerGridProps) {
  const [columns] = React.useState<
    ReadonlyArray<GridColumn<Customers_partnerQuery_customers_edges>>
  >([
    {
      label: "Name",
      key: "name",
      width: 100,
      sortable: true,
    },
    {
      label: "Phone No",
      key: "phone",
      width: 150,
      flexGrow: 1,
      sortable: true,
    },
  ]);

  const listItemRenderer = React.useCallback(
    (
      renderProps: ListItemRenderProps<Customers_partnerQuery_customers_edges>
    ) => {
      const { rowData, key, style, className, onClick } = renderProps;
      if (rowData) {
        const { name, phone, ref, township } = rowData;
        return (
          <div onClick={onClick} style={style} className={className} key={key}>
            <ListItem>
              <ListItemText
                primary={`${name} ${ref ?? ""}`}
                secondary={phone ?? "."}
              />
            </ListItem>
            <ListItemSecondaryAction>
              <ListItemText
                primary={township?.name_mm}
                secondary={township?.name_en}
              />
            </ListItemSecondaryAction>
            <Divider />
          </div>
        );
      } else {
        return (
          <div style={style} key={key}>
            <ListItem>
              <ListItemText
                primary={
                  <Skeleton variant="text" width={150} animation="wave" />
                }
                secondary={
                  <Skeleton variant="text" width={100} animation="wave" />
                }
              />
            </ListItem>
            <Divider />
          </div>
        );
      }
    },
    []
  );
  return (
    <ApolloConsumer>
      {(client) => (
        <CustomerVirtualizedGrid
          onRowClick={(item, index) => {
            onClick(index);
          }}
          selectedItems={[selectedIndex]}
          apolloClient={client as any}
          listItemHeight={72}
          listItemRenderer={listItemRenderer}
          listModeBreakPoint={3000}
          columns={columns}
          graphqlQuery={customersQuery}
          variables={variables}
          pageSize={variables?.pagination?.pageSize!}
          parseListFromQueryResult={(queryResult: Customers) => {
            const list: ApolloListResult<Customers_partnerQuery_customers_edges> = queryResult?.partnerQuery?.customers ?? {
              edges: [],
              pageInfo: DefaultPageInfoValue,
            };
            return list;
          }}

          onLoadMore={(pageInfo) => {
            return {
              ...variables,
              pagination: {
                page: pageInfo.page,
                pageSize: pageInfo.pageSize,
              },
            };
          }}
        />
      )}
    </ApolloConsumer>
  );
}



function CustomerDialog({
  open,
  onClose,
  onSelected,
  disableSelection,
  selectedCustomerId,
}: CustomerDialogProps) {
  const [searchText, setSearchText] = React.useState(selectedCustomerId);
  React.useEffect(() => {
    setSearchText(selectedCustomerId);
    if (selectedCustomerId)
      setSelectedIndex(0);
    else
      setSelectedIndex(-1);
  }, [selectedCustomerId, open]);

  const [where, setWhere] = React.useState<PartnerWhereInput>(
    selectedCustomerId
      ? { id: selectedCustomerId }
      : {}
  );

  const variables = React.useMemo(() => {
    const v: CustomersVariables = { where, pagination: { pageSize: 20 } };
    return v;
  }, [where]);

  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const { data: customersQueryResult } = useQuery<
    Customers,
    CustomersVariables
  >(customersQuery, {
    fetchPolicy: "cache-only",
    variables,
    onCompleted: () => {
      if (selectedCustomerId) setSelectedIndex(0);
    },
  });

  const [editable, setEditable] = React.useState(false);

  React.useEffect(() => {
    var previousSelectedIndex = selectedIndex;
    var previousQueryResult =
      customersQueryResult?.partnerQuery?.customers?.edges;
    return () => {
      const curentQueryResult =
        customersQueryResult?.partnerQuery?.customers?.edges;

      if (
        previousSelectedIndex === selectedIndex &&
        previousQueryResult !== curentQueryResult
      ) {
        setSelectedIndex(0);
      }
    };
  }, [customersQueryResult?.partnerQuery?.customers, selectedIndex]);

  React.useEffect(() => {
    if (!isNaN(Number.parseInt(searchText ?? ""))) {
      setWhere({
        id: searchText
      });
    } else if (searchText) {
      setWhere({
        oR: [
          {
            name_Contains: searchText,
          },
          { phone_Contains: searchText },
          { city_Contains: searchText },
          { street_Contains: searchText },
        ],
      });
    } else {
      setWhere({});
    }
  }, [searchText]);

  const [name, setName] = React.useState<EditValue<string>>({});
  const [phone, setPhone] = React.useState<EditValue<string>>({});
  const [email, setEmail] = React.useState<EditValue<string>>({});
  const [street, setStreet] = React.useState<EditValue<string>>({});
  const [city, setCity] = React.useState<
    EditValue<Townships_regionQuery_townships>
  >({});
  const [title, setTitle] = React.useState<
    EditValue<ContactTitles_commonQuery_contactTitles>
  >({});
  const selectedCustomer = React.useMemo(() => {
    const customers =
      customersQueryResult?.partnerQuery?.customers?.edges ?? [];
    const customer =
      selectedIndex > -1 && customers?.length > selectedIndex
        ? customers[selectedIndex]
        : null;
    return customer;
  }, [selectedIndex, customersQueryResult?.partnerQuery?.customers?.edges]);

  const searchBoxRef = React.useRef<any>(null);
  const phoneTextFieldRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (selectedCustomer) {
      const { title, name, phone, street, email, township } =
        selectedCustomer;
      if (title)
        setTitle({ value: { id: title?.id!, name: title?.display_name! } });
      else setTitle({ value: undefined });
      if (name) {
        setName({ value: name });
      } else setName({ value: "" });
      if (phone) setPhone({ value: phone });
      else setPhone({ value: "" });
      if (street) setStreet({ value: street });
      else setStreet({ value: "" });
      if (email) setEmail({ value: email });
      else setEmail({ value: "" });

      if (township) {
        setCity({ value: township });
      } else {
        setCity({ value: undefined });
      }
    } else {
      setTitle({ value: undefined, error: "" });
      setName({ value: "", error: "" });
      setPhone({ value: "", error: "" });
      setStreet({ value: "", error: "" });
      setEmail({ value: "", error: "" });
      setCity({ value: undefined, error: "" });
    }
  }, [selectedCustomer]);

  React.useEffect(() => {
    setError("");
    setEditable(false);
  }, [selectedIndex]);

  const [fetchCustomers] = useLazyQuery<Customers, CustomersVariables>(customersQuery, {
    fetchPolicy: "network-only",
  });

  const [checkingPhoneNo, setCheckingPhoneNo] = React.useState(false);
  const checkPhoneNo = React.useCallback(
    (phone: string) => {
      const variables: CustomersVariables = {
        where: {
          phone,
        },
        pagination: {
          take: 1,
        },
      };
      if (selectedCustomer) {
        variables!.where!.id_Not = selectedCustomer?.id;
      }
      setCheckingPhoneNo(true);
      return fetchCustomers({ variables })
        .then((result) => {
          setCheckingPhoneNo(false);
          return result;
        })
        .catch((e) => {
          setCheckingPhoneNo(false);
          return e;
        });
    },
    [fetchCustomers, selectedCustomer]
  );

  const validate = React.useCallback(async () => {
    var ok = true;
    if (!name.value) {
      setName({ ...name, error: "Name is required" });
      ok = false;
    }

    if (!phone.value) {
      setPhone({ ...phone, error: "Phone no is required!" });
      ok = false;
    } else {
      const { data: checkedResult } = await checkPhoneNo(phone.value);
      const [customer] = checkedResult?.partnerQuery.customers.edges ?? [];
      const hasPhoneNoAlreadyExist =
        customer &&
        customer.phone === phone.value &&
        phone?.value &&
        customer.id !== selectedCustomer?.id;
      if (hasPhoneNoAlreadyExist) {
        ok = false;
      }
    }
    return ok;
  }, [name, phone, setName, setPhone, checkPhoneNo, selectedCustomer]);

  const checkPhoneNoAndLoad = React.useCallback(async () => {
    const { data: checkedResult } = await checkPhoneNo(phone.value ?? "");
    const [customer] = checkedResult?.partnerQuery.customers.edges ?? [];
    const hasPhoneNoAlreadyExist =
      customer &&
      customer.phone === phone.value &&
      phone?.value &&
      customer.id !== selectedCustomer?.id;
    if (hasPhoneNoAlreadyExist)
      setWhere({ id: customer.id });
  }, [checkPhoneNo, phone.value, selectedCustomer?.id]);

  const [error, setError] = React.useState("");

  const [saveCustomer] = useMutation<SavePartner, SavePartnerVariables>(
    savePartnerMutation,
    {
      onError: (e) => {
        setError(e.message);
      },
      update: (cache, { data }) => {
        const saved = data?.partnerMutation?.savePartner;
        if (selectedIndex === -1) {
          const cached = cache.readQuery<Customers, CustomersVariables>({
            query: customersQuery,
            variables,
          });
          const updated = update(cached, {
            partnerQuery: {
              customers: {
                edges: {
                  $unshift: [saved as any],
                },
                pageInfo: {
                  rowCount: {
                    $set:
                      (cached?.partnerQuery?.customers?.pageInfo?.rowCount ??
                        0) + 1,
                  },
                },
              },
            },
          });
          if (!updated)
            return;
          cache.writeQuery<Customers, CustomersVariables>({
            query: customersQuery,
            variables,
            data: updated,
          });
          setSelectedIndex(0);
        }
      },
    }
  );
  const [saving, setSaving] = React.useState(false);

  const handleCreate = React.useCallback(() => {
    setSelectedIndex(-1);
    phoneTextFieldRef.current?.focus();
  }, [setSelectedIndex]);

  const handleEdit = React.useCallback(() => {
    setEditable(true);
  }, [setEditable]);

  const handleCancelEdit = React.useCallback(() => {
    if (!selectedCustomer)
      return;
    const { phone, name, township, street } = selectedCustomer;
    setPhone({ value: phone ?? "" });
    setName({ value: name ?? "" });
    setCity({ value: township ?? undefined });
    setStreet({ value: street ?? "" });
    setEditable(false);
  }, [selectedCustomer, setPhone, setName, setCity, setStreet, setEditable]);

  const handleSave = React.useCallback(async () => {
    setSaving(true);
    setError("");
    try {
      if (!(await validate())) {
        setSaving(false);
        return;
      }
      const input: PartnerInput = {};
      input.id = selectedCustomer?.id;

      if (title.changed) {
        input.title = title.value?.id ? Number.parseInt(title.value.id) : null;
      }

      if (name.changed) {
        input.name = name.value;
      }

      if (phone.changed) {
        input.phone = phone.value;
      }
      if (email.changed) {
        input.email = email.value;
      }
      if (street.changed) {
        input.street = street.value;
      }
      if (city.changed) {
        input.city = city.value
          ? `${city.value.name_en} ${city.value.name_mm} #${city.value.township_pcode}#`
          : null;
      }
      input.customer_rank = 1;
      const { errors, data } = await saveCustomer({
        variables: {
          partner: input,
        },
      });
    } finally {
      setSaving(false);
    }
  }, [validate, selectedCustomer?.id, title.changed, title.value?.id, name.changed, name.value, phone.changed, phone.value, email.changed, email.value, street.changed, street.value, city.changed, city.value, saveCustomer]);

  const handleCommandKeyUp: React.KeyboardEventHandler<HTMLElement> =
    React.useCallback(
      (e) => {
        switch (e.key) {
          case "F1":
          case "F2":
            e.stopPropagation();
            break;
          case "Escape":
            if (!saving) {
              if (
                selectedIndex === -1 &&
                (customersQueryResult?.partnerQuery?.customers?.edges?.length ?? 0) > 0
              )
                setSelectedIndex(0);
              else {
                onClose();
              }
            }
            e.stopPropagation();
            break;
          case "F3":
            searchBoxRef.current.focus();
            searchBoxRef.current.select();
            e.stopPropagation();
            break;
          case "F9":
            if (!(saving || (checkingPhoneNo && selectedIndex === -1))) {
              handleCreate();
            }
            e.stopPropagation();
            break;
          case "F10":
            if (!(saving || checkingPhoneNo)) {
              handleSave();
            }
            e.stopPropagation();
            break;
        }
      },
      [saving, checkingPhoneNo, selectedIndex, customersQueryResult?.partnerQuery?.customers?.edges?.length, onClose, handleCreate, handleSave]
    );

  return (
    <Dialog onKeyUp={handleCommandKeyUp} maxWidth="md" fullWidth open={open}>
      <DialogTitle>Customers{selectedCustomerId}</DialogTitle>
      <TextEditor
        disabled={disableSelection}
        autoFocus
        inputReference={(r) => {
          searchBoxRef.current = r;
        }}
        inputElementType="Input"
        value={searchText}
        sx={_sx.searchBox}
        onValidated={setSearchText}
        placeholder="Enter name, phone no, city or street.(F3)"
        inputProps={{ style: { color: "#000" } }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                size="small"
                onClick={() => {
                  setSearchText("");
                }}
              >
                <Close color="primary" fontSize="small" />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <DialogContent sx={_sx.content}>
        <CustomerGrid
          selectedIndex={selectedIndex}
          variables={variables}
          onClick={setSelectedIndex}
        />
        <Grid sx={_sx.customerView} container direction="column">
          <TextField
            size="small"
            sx={_sx.formControl}
            inputRef={phoneTextFieldRef}
            inputProps={{ readOnly: checkingPhoneNo || saving }}
            label="Phone No"
            error={!!phone.error}
            helperText={phone.error}
            placeholder="Phone No"
            value={phone.value}
            disabled={!(editable || selectedIndex === -1)}
            onChange={(e) => {
              setPhone({
                value: e.target.value,
                changed: true,
                error: "",
              });
            }}
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                e.stopPropagation();
                checkPhoneNoAndLoad();
              }
            }}
            onBlur={checkPhoneNoAndLoad}
          />
          <TextField
            size="small"
            sx={_sx.formControl}
            inputProps={{ readOnly: checkingPhoneNo || saving }}
            label="Name"
            error={!!name.error}
            helperText={name.error}
            placeholder="Name"
            disabled={!(editable || selectedIndex === -1)}
            value={name.value}
            onChange={(e) => {
              setName({
                value: e.target.value,
                changed: true,
                error: "",
              });
            }}
          />
          <TownshipAutoComplete
            size="small"
            sx={_sx.formControl}
            selectedTownship={city?.value ?? null}
            disabled={!(editable || selectedIndex === -1)}
            onSelected={(city) => {
              setCity({ value: city ?? undefined, error: "", changed: true });
            }}
          />
          <TextField
            size="small"
            sx={_sx.formControl}
            multiline
            rows={3}
            disabled={!(editable || selectedIndex === -1)}
            inputProps={{ readOnly: checkingPhoneNo || saving }}
            label="Address"
            error={!!street.error}
            helperText={street.error}
            placeholder="Address"
            value={street.value}
            onChange={(e) => {
              setStreet({
                value: e.target.value,
                changed: true,
                error: "",
              });
            }}
          />
          <div>
            {
              selectedIndex > -1 ? <Button
                color="secondary"
                onClick={editable ? handleCancelEdit : handleEdit}
                disabled={saving || checkingPhoneNo}
              >
                {editable ? "Cancel" : "Edit"}
              </Button> : null
            }
            <Button
              color="secondary"
              onClick={handleCreate}
              disabled={saving || checkingPhoneNo || selectedIndex === -1}
            >
              New(F9)
            </Button>
            <Button
              color="secondary"
              onClick={handleSave}
              disabled={saving || checkingPhoneNo}
            >
              Save(F10)
            </Button>
          </div>
          <Typography color="error">{error}</Typography>
        </Grid>
      </DialogContent>
      <DialogActions>
        <DialogActionLoadingIndicator
          loading={saving || checkingPhoneNo}
          text={
            checkingPhoneNo ? "Checking phone no..." : "Saving customer info..."
          }
        />
        <Button
          disabled={saving || checkingPhoneNo || selectedIndex === -1}
          color="secondary"
          onClick={() => {
            if (selectedCustomer)
              onSelected(selectedCustomer);
          }}
        >
          OK
        </Button>
        <Button disabled={saving} onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CustomerDialog;
