import Grid from "@mui/material/Grid/Grid";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import { useQuery } from "@apollo/client";
import format from "date-fns/esm/fp/format/index.js";
import { stockTransactionHistoryQuery } from "../graphql";
import accounting from "accounting";
import Skeleton from "@mui/material/Skeleton";
import { StockTransactionHistory, StockTransactionHistoryVariables } from "../__generated__/StockTransactionHistory";
import { useParams, useSearchParams } from "react-router-dom";

export type StockTransactionHistoryProps = {
  product_id: string;
  location_id: string | null;
  start_date: string;
  end_date: string;
  show_location: boolean | undefined;
};

const _sx = {
  textAlignRight: {
    textAlign: "right",
  },
  fill: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  position_relative: {
    position: "relative",
  },
  historyList: {
    position: "absolute",
    width: "100%",
  },
  scroller: {
    overflow: "auto",
  },
  price_list_select: {
    marginTop: 1,
    marginLeft: 2,
    marginRight: 2,
  },
  fieldLabel: {
    fontWeight: "bold",
    marginRight: 1,
    width: 150,
    display: "inline-block",
    color: "inherit",
  },
  field: {
    padding: 0.5,
  },
  fieldValue: {
    display: "inline-block",
  },
};

function Loading() {
  return (
    <Box sx={{ ..._sx.fill, ..._sx.scroller, paddingLeft: 2, paddingRight: 2 }}>
      <Grid container item direction="column">
        <Skeleton sx={{ marginTop: 1, width: 150, height: 28 }} />
        <Skeleton sx={{ marginTop: 1, marginBottom: 1, height: 56 }} />
        <Skeleton sx={{ marginTop: 1, marginBottom: 1, height: 56 }} />
        <Skeleton sx={{ marginTop: 1, marginBottom: 1, height: 56 }} />
        <Skeleton sx={{ marginTop: 1, marginBottom: 1, height: 56 }} />
      </Grid>
    </Box>
  );
}

function InventoryTransactionHistory() {
  const params = useParams<{start_date:string, end_date:string, product_id:string, location_id:string}>();
  let [searchParams] = useSearchParams();
  const show_location = searchParams.get("show_location") === "true"; 
  const product_id = params.product_id? Number.parseInt(params.product_id!) : undefined;
  const location_id = params.location_id? Number.parseInt(params.location_id!) : undefined;

  const { data } = useQuery<
    StockTransactionHistory,
    StockTransactionHistoryVariables
  >(stockTransactionHistoryQuery, {
    variables: {
      start_date:params.start_date,
      end_date:params.end_date,
      where:{product_id,location_id},
      show_location,
    },
    skip: !product_id,
    fetchPolicy: "no-cache",
  });
  const stockTransactions = data?.inventoryQuery?.stockTransactions?.edges;
  return (
    <Grid
      item
      container
      direction="column"
      sx={{ padding: 0, width: { md: 300 } }}
    >
      {stockTransactions && stockTransactions.length > 0 && (
        <ListItem>
          <ListItemText
            primary={stockTransactions[0]?.default_code}
            secondary={stockTransactions[0]?.product_name}
          />
        </ListItem>
      )}
      {stockTransactions ? (
        <Box sx={_sx.fill}>
          <Box sx={{ ..._sx.fill, ..._sx.position_relative, ..._sx.scroller }}>
            <List sx={_sx.historyList}>
              {stockTransactions?.map((t, index) => {
                return (
                  <ListItem key={index}>
                    <ListItemText
                      primary={t.counterpart_location_name ? t.origin??t.name : 'Opening Balance'}
                      secondary={t.counterpart_location_name}
                    />
                    <ListItemSecondaryAction>
                      <ListItemText
                        primary={`${accounting.formatNumber((t.counterpart_location_name ? t.cb_sum: t.ob_sum)??0, 0)}`}
                        secondary={format("dd/MM/yyyy", new Date(t.date))}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
            </List>
          </Box>
        </Box>
      ) : (
        <Loading />
      )}
    </Grid>
  );
}

export default InventoryTransactionHistory;
