import { useQuery } from "@apollo/client";
import ListItemText from "@mui/material/ListItemText/ListItemText";
import TextField, { TextFieldProps } from "@mui/material/TextField/TextField";
import Autocomplete, {
  createFilterOptions,
} from '@mui/material/Autocomplete';
import * as React from "react";
import { analyticAccountsQuery } from "./graphql";
import { AnalyticAccounts, AnalyticAccounts_commonQuery_analyticAccounts } from "./__generated__/AnalyticAccounts";
import ListItem from "@mui/material/ListItem";

export type AnalyticAccountAutoCompleteProps = {
  selectedAnalyticAccount: AnalyticAccounts_commonQuery_analyticAccounts| null;
  onSelected: (jounal: AnalyticAccounts_commonQuery_analyticAccounts| null) => void;
  className?: string;
} & Pick<TextFieldProps, "size" | "label" | "sx">;;

const filterOptions = createFilterOptions({
  stringify: (option: AnalyticAccounts_commonQuery_analyticAccounts) =>
    `${!option.code?"": option.code + " "}${option.name}`,
});
function AnalyticAccountAutoComplete({
  selectedAnalyticAccount,
  onSelected,
  className,
  label,
  size,
  sx
}: AnalyticAccountAutoCompleteProps) {
  const { data, loading } = useQuery<AnalyticAccounts, {}>(
    analyticAccountsQuery
  );
  return (
    <Autocomplete
      value={selectedAnalyticAccount}
      onChange={(_, value: AnalyticAccounts_commonQuery_analyticAccounts|null) => {
        onSelected(value);
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      filterOptions={filterOptions}
      includeInputInList
      clearOnBlur
      selectOnFocus
      loading={loading}
      getOptionLabel={(option) => `${!option.code?"": option.code + " "}${option.name}`}
      renderOption={(props,option) => (
        <ListItem {...props}><ListItemText primary={option.code} secondary={option.name} /></ListItem>
      )}
      options={
        data?.commonQuery?.analyticAccounts ??
        (selectedAnalyticAccount ? [selectedAnalyticAccount] : [])
      }
      renderInput={(params) => (
        <TextField
          {...params}
          size={size}          
          autoComplete="off"
          label={label}
          sx={sx}
          className={className}
        />
      )}
    />
  );
}

export default AnalyticAccountAutoComplete;
