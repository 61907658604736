import Dialog from "@mui/material/Dialog/Dialog";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import DialogTitle from "@mui/material/DialogTitle/DialogTitle";
import Grid from "@mui/material/Grid/Grid";
import * as React from "react";
import RadioGroup from "@mui/material/RadioGroup/RadioGroup";
import { Journals_commonQuery_journals } from "../../common/__generated__/Journals";
import FormControlLabel from "@mui/material/FormControlLabel/FormControlLabel";
import Radio from "@mui/material/Radio/Radio";
import JournalAutoComplete from "../../common/JournalAutoComplete";
import DialogActions from "@mui/material/DialogActions/DialogActions";
import Button from "@mui/material/Button/Button";
import { useSelector } from "react-redux";
import { useMutation } from "@apollo/client";
import { MakePayment, MakePaymentVariables } from "./__generated__/MakePayment";
import { makePaymentMutation } from "./graphql";
import PartnerAutoComplete from "../../common/PartnerAutoComplete";
import { Partners_partnerQuery_partners_edges } from "../../common/__generated__/Partners";
import { RootState } from "../../../app/store";
import useHasPermission from "../../../app/useHasPermission";
import CurrencyEditor from "../../../components/CurrencyEditor";
import TextEditor from "../../../components/TextEditor";
import DialogActionLoadingIndicator from "../../../components/DialogActionLoadingIndicator";
import config from "../../../config.json";
import { DatePicker, DatePickerProps } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import { AccountPaymentInput, JournalType, PartnerType, PaymentType } from "../../../types/global-types";
import useDeepPropState from "../../../app/useDeepPropState";
import Stack from "@mui/material/Stack";

const Picker = (DatePicker) as React.ElementType<DatePickerProps<Date, any>>;
export type PaymentDialogProps = {
    open: boolean;
    onClose: () => void;
    allowedPartnerChange: boolean;
    allowedPaymentTypeChange: boolean;
    allowedJournalChange: boolean;
    initialValue: Partial<MakePaymentVariables["payment"]>;
    journal?: Journals_commonQuery_journals
    allowDateChange: boolean;
};

const _sx = {
    content: {
        overflowY: "hidden",
    },
    radioGroup: {
        flexDirection: "row"
    }
};

function PaymentDialog({ open, onClose, initialValue, journal, allowedPartnerChange, allowedPaymentTypeChange, allowedJournalChange }: PaymentDialogProps) {
    const [draft, setDraft] = useDeepPropState<Omit<AccountPaymentInput, "journal_id">>(initialValue, (initialValue) => {
        return {
            payment_type: initialValue.payment_type ?? PaymentType.inbound,
            amount: initialValue.amount ?? 0,
            is_internal_transfer: initialValue.is_internal_transfer ?? false,
            partner_type: initialValue.partner_type ?? PartnerType.customer,
            partner_id: initialValue.partner_id,
            date: new Date(),
        };
    });
    const [errors, setErrors] = React.useState<{ [key: string]: string }>({});
    const savedCashJournal = useSelector(
        (root: RootState) => root.saleSetting.cashJournal
    );
    const savedDestinationCashJournal = useSelector((root: RootState) => root.saleSetting.destinationCashJournal);
    const allowedOtherCashAccount = useHasPermission("Sale_AccessOtherCashAccount");
    const [bankJournal, setBankJournal] = React.useState<Journals_commonQuery_journals | null>(journal?.type === JournalType.bank ? journal : null as any);
    const [cashJournal, setCashJournal] = React.useState<Journals_commonQuery_journals | null>(journal?.type === JournalType.cash ? journal : savedCashJournal);
    const [selectedPartner, setSelectedPartner] = React.useState<Partners_partnerQuery_partners_edges | null>(null);
    const [isCashJournal, setIsCashJournal] = React.useState(journal?.type === JournalType.cash);
    const [destinationJournal, setDestinationJournal] = React.useState<Journals_commonQuery_journals | null>(draft.is_internal_transfer ? savedDestinationCashJournal : null as any);
    React.useEffect(()=>{
        setDraft({
            payment_type: initialValue.payment_type ?? PaymentType.inbound,
            amount: initialValue.amount ?? 0,
            is_internal_transfer: initialValue.is_internal_transfer ?? false,
            partner_type: initialValue.partner_type ?? PartnerType.customer,
            partner_id: initialValue.partner_id,
            date: new Date(),
        });
    }, [initialValue])
    React.useEffect(()=>{
        setDestinationJournal(draft.is_internal_transfer ? savedDestinationCashJournal : null as any);        
    }, [
        draft.is_internal_transfer, savedDestinationCashJournal
    ]);
    React.useEffect(() => {
        switch (journal?.type) {
            case JournalType.cash:
                setIsCashJournal(true);
                setCashJournal(journal);
                setBankJournal(null as any);
                break;
            case JournalType.bank:
                setIsCashJournal(true);
                setCashJournal(null as any);
                setBankJournal(journal);
                break;
            default:
                break;
        }
    }, [journal]);
    const [makePayment, { loading: makingPayment }] = useMutation<MakePayment, MakePaymentVariables>(makePaymentMutation);
    const handleIsCashJournalValueChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setIsCashJournal(JSON.parse(e.target.value));
    }, [setIsCashJournal]);

    const validate = React.useCallback(() => {
        var ok = true;
        const e = { ...errors };
        if (!draft.ref) {
            e.ref = "Memo is required!";
            ok = false;
        }

        if (!draft.date) {
            e.date = "Date is required!";
            ok = false;
        }
        if (!cashJournal && !bankJournal) {
            e.journal = "Select journal!";
            ok = false;
        }

        if (!draft.amount || draft.amount < 0) {
            e.amount = "Invalid value.";
            ok = false;
        }
        if (allowedPartnerChange && !selectedPartner) {
            e.partner = "Select partner";
            ok = false;
        }
        // if (draft.is_internal_transfer && !destinationJournal) {
        //     e.destination_journal_id = "Select destination journal"
        //     ok = false;
        // }
        setErrors(e);
        return ok;
    }, [errors, draft.ref, draft.date, draft.amount, cashJournal, bankJournal, allowedPartnerChange, selectedPartner]);

    const submit = React.useCallback(async () => {
        if (validate()) {
            await makePayment({
                variables: {
                    payment: {
                        ...draft, partner_id: Number.parseInt(allowedPartnerChange ? selectedPartner?.id! : draft?.partner_id!.toString()),
                        journal_id: Number.parseInt(cashJournal?.id ?? bankJournal?.id!),
                        // destination_journal_id: destinationJournal ? Number.parseInt(destinationJournal?.id!) : null
                    }
                }
            });
            onClose();
        }
    }, [validate, makePayment, draft, allowedPartnerChange, selectedPartner?.id, cashJournal?.id, bankJournal?.id, onClose]);

    React.useEffect(() => {
        setSelectedPartner(null as any);
    }, [initialValue, setSelectedPartner]);

    const handleCommandKeyUp: React.KeyboardEventHandler<HTMLElement> =
        React.useCallback(
            (e) => {
                switch (e.key) {
                    case "Escape":
                        if (!(makingPayment)) {
                            onClose();
                        }
                        e.stopPropagation();
                        break;
                    case "Enter":
                        if (
                            !makingPayment &&
                            validate()
                        )
                            submit();
                        e.stopPropagation();
                        break;
                }
            },
            [onClose, submit, makingPayment, validate]
        );

    return (<Dialog onKeyUp={handleCommandKeyUp} maxWidth="sm" fullWidth open={open}>
        <DialogTitle>{!draft.is_internal_transfer ? (draft.payment_type === "inbound" ? "Receive Money" : "Pay Money") : (draft.payment_type === "inbound" ? "Transfer In" : "Transfer Out")}</DialogTitle>
        <DialogContent sx={_sx.content}>
            <Stack spacing={2}>
                <Picker
                    renderInput={(props) => (<TextField {...props} size="small" />)}
                    inputFormat={config.shortDateFormat}
                    label="Payment Date"
                    value={draft.date}
                    onChange={(date: any) => { setDraft({ ...draft, date }); setErrors({ ...errors, date: "" }); }}
                />
                {allowedPartnerChange ? <PartnerAutoComplete error={!!errors["partner"]}
                    helperText={errors["partner"]} size="small" selectedPartner={selectedPartner} onSelected={(partner)=>{
                        setSelectedPartner(partner);
                        setErrors({...errors, partner:""});
                    }} label="Partner" /> : null}
                <CurrencyEditor
                    autoFocus
                    size="small"
                    label="Amount"
                    error={!!errors["amount"]}
                    helperText={errors["amount"]}
                    value={draft.amount}
                    onChanged={(value) => {
                        if (value as any > -1) {
                            setDraft({ ...draft, amount: value ?? 0 });
                            setErrors({ ...errors, amount: "" });
                        }
                    }}
                />
                <TextEditor multiline rows={3} value={draft.ref ?? ""} label="Memo" helperText={errors["ref"]} error={!!errors["ref"]} onValidated={(value) => { setDraft({ ...draft, ref: value }); setErrors({ ...errors, ref: "" }); }} />
                <RadioGroup value={isCashJournal} onChange={handleIsCashJournalValueChange} sx={_sx.radioGroup}>
                    <FormControlLabel disabled={!allowedJournalChange} value={true} control={<Radio />} label="Cash" />
                    <FormControlLabel disabled={!allowedJournalChange} value={false} control={<Radio />} label="Bank" />
                </RadioGroup>
                {
                    !isCashJournal || allowedOtherCashAccount ? <JournalAutoComplete
                        disabled={!allowedJournalChange}
                        size="small"
                        onSelected={(journal)=>{
                            const func = isCashJournal ? setCashJournal : setBankJournal
                            func(journal);
                            setErrors({...errors, journal:""});
                        }}
                        journalTypes={[
                            isCashJournal ? JournalType.cash : JournalType.bank,
                        ]}
                        error={!!errors['journal']}
                        helperText={errors['journal']}
                        label={isCashJournal ? "Cash Account" : "Bank Account"}
                        selectedJournal={isCashJournal ? cashJournal : bankJournal}
                    /> : null
                }
                {/* {
                    draft.is_internal_transfer ? <JournalAutoComplete
                        disabled={!allowedJournalChange}
                        size="small"
                        onSelected={(journal)=>{
                            setDestinationJournal(journal);
                            setErrors({...errors, destination_journal_id:""});
                        }}
                        journalTypes={[JournalType.cash, JournalType.bank]}
                        error={!!errors['destination_journal_id']}
                        helperText={errors['destination_journal_id']}
                        label="Destination Journal"
                        selectedJournal={destinationJournal}
                    /> : null
                } */}
            </Stack>
        </DialogContent>
        <DialogActions>
            <DialogActionLoadingIndicator
                loading={makingPayment}
                text="Saving payment..."
            />
            <Button
                disabled={
                    makingPayment
                }
                onClick={submit}
                color="secondary"
            >
                OK
            </Button>
            <Button disabled={makingPayment} onClick={onClose}>
                Cancel
            </Button>
        </DialogActions>
    </Dialog>);
}

export default PaymentDialog;


