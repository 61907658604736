import Box from "@mui/system/Box";
import Grid from "@mui/material/Grid";
import * as React from "react";
import { EcommerceProduct, EcommerceProductVariables, EcommerceProduct_ecommerceQuery_product, EcommerceProduct_ecommerceQuery_product_available_quants, EcommerceProduct_ecommerceQuery_product_excluded_companies, EcommerceProduct_ecommerceQuery_product_excluded_locations, EcommerceProduct_ecommerceQuery_product_quants, EcommerceProduct_ecommerceQuery_product_related_companies } from "../__generated__/EcommerceProduct";
import TextEditor from "../../../components/TextEditor";
import EcommerceCategoryMultiSelector from "../EcommerceCategoryMultiSelector";
import EnumSingleSelectEditor from "../../../components/EnumSingleSelectEditor";
import { EcommerceProductInput, ProductAvailability, ProductWarranty, WarrantyUnit } from "../../../types/global-types";
import NumberEditor from "../../../components/NumberEditor";
import TextField from "@mui/material/TextField";
import { DateTimePicker, DateTimePickerProps } from "@mui/x-date-pickers";
import settings from "../../../config.json";
import format from "date-fns/format";
import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";
import ListItemButton from "@mui/material/ListItemButton";
import accounting from "accounting";
import ListItemText from "@mui/material/ListItemText";
import FormControlLabel from "@mui/material/FormControlLabel";
import update from 'immutability-helper';
import ProductPhotoAlbum, { AlbumPhoto } from "./ProductPhotoAlbum";
import ErrorMessageDialog, { ErrorInfo } from "../../../components/ErrorMessageDialog";
import { useMutation, useQuery } from "@apollo/client";
import { ecommerceProductQuery, saveEcommerceProductMutation } from "../graphql";
import ActivityIndicatorDialog from "../../../components/ActivityIndicatorDialog";
import { useProductBrowserContext } from ".";
import { SaveEcommerceProduct, SaveEcommerceProductVariables } from "../__generated__/SaveEcommerceProduct";
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import useAppContext from "../../../app/AppContextProvider";
import PlaygroundNodes from "../../../lexical/nodes/PlaygroundNodes";
import PlaygroundEditorTheme from '../../../lexical/themes/PlaygroundEditorTheme';
import { $generateHtmlFromNodes, $generateNodesFromDOM } from '@lexical/html';
import RefPlugin from "../../../lexical/plugins/RefPlugin";
import { $getRoot, EditorState, LexicalEditor } from "lexical";
import { useParams } from "react-router-dom";
import Container from "@mui/material/Container";
import Fab from "@mui/material/Fab";
import Edit from "@mui/icons-material/Edit";
import OverviewEditor from "./OverviewEditor";
const Picker = (DateTimePicker) as React.ElementType<DateTimePickerProps<Date, any>>
export type ProductEditorProps = {
    product: EcommerceProduct_ecommerceQuery_product;
    loadingError?: ErrorInfo;
}
const _sx = {
    fieldLabel: {
        fontWeight: "bold",
        marginRight: 1,
        width: 150,
        display: "inline-block",
        color: "inherit"
    },
    field: {
        padding: .5,
    },
    grid_container: {
        paddingBottom: 4
    },
    subtitle: {
        paddingBottom: 1
    },
    scroller: {
        overflowY: "scroll",
    },
    content: {
        paddingTop: 2,
        paddingBottom: 2,
    },
    paper: {
        backgroundColor: "rgba(0,0,0,.1)"
    },
}

export const ProductAvailabilityOptions = {
    [ProductAvailability.live]: { value: ProductAvailability.live, text: "Live Update" },
    [ProductAvailability.not_available]: { value: ProductAvailability.not_available, text: "Not Available" },
    [ProductAvailability.preorder]: { value: ProductAvailability.preorder, text: "Pre-order Only" }
};
export const ProductWarrantyOptions = {
    [ProductWarranty.full]: { value: ProductWarranty.full, text: "Official Warranty" },
    [ProductWarranty.full_then_service]: { value: ProductWarranty.full_then_service, text: "Offical + Service" },
    [ProductWarranty.service_only]: { value: ProductWarranty.service_only, text: "Service Only" },
    [ProductWarranty.not_available]: { value: ProductWarranty.not_available, text: "Not Available" }
}
export const WarrantyUnitOptions = {
    [WarrantyUnit.days]: { value: WarrantyUnit.days, text: "Day(s)" },
    [WarrantyUnit.months]: { value: WarrantyUnit.months, text: "Month(s)" },
    [WarrantyUnit.years]: { value: WarrantyUnit.years, text: "Year(s)" },
    [WarrantyUnit.lifetime]: { value: WarrantyUnit.lifetime, text: "Lifetime" }
}
type ProductInDraft = Pick<EcommerceProduct_ecommerceQuery_product,
    "ecommerce_caption" |
    "ecommerce_description" |
    "ecommerce_categories" |
    "uom_description" |
    "availability" |
    "min_order_qty" |
    "max_order_qty" |
    "available_date" |
    "preorder_description" |
    "excluded_companies" |
    "excluded_locations" |
    "exclude_consignment" |
    "quants" |
    "available_quants" |
    "product_warranty" |
    "warranty_unit" |
    "warranty_description" |
    "warranty_duration"> & { default_ecommerce_categ_id: string | undefined, photos: Array<AlbumPhoto>, deleted_image_ids: number[], photo_sorted: boolean };
function ProductEditor({ product, loadingError }: ProductEditorProps) {
    const { default_code, barcode, overview, name, zone_id, brand_id, categ_id, related_companies, uom_id, available_quants } = product;
    const [draft, setDraft] = React.useState<Partial<ProductInDraft>>({
        ecommerce_caption: product.ecommerce_caption,
        ecommerce_description: product.ecommerce_description,
        ecommerce_categories: product.ecommerce_categories,
        default_ecommerce_categ_id: product.default_ecommerce_categ_id?.id,
        uom_description: product.uom_description,
        min_order_qty: product.min_order_qty ?? 1,
        max_order_qty: product.max_order_qty ?? 0,
        available_date: product.available_date,
        availability: product.availability,
        preorder_description: product.preorder_description,
        excluded_companies: product.excluded_companies,
        excluded_locations: product.excluded_locations,
        exclude_consignment: product.exclude_consignment,
        quants: product.quants,
        product_warranty: product.product_warranty,
        warranty_unit: product.warranty_unit,
        warranty_description: product.warranty_description,
        warranty_duration: product.warranty_duration,
        photos: product.tri_product_variant_images?.map(({ image, ...p }) => ({ ...p, width: 1000, height: 1000 / (p.aspect_ratio ?? 1), image, src: `${settings.cloudfront_url}/${image}/x1000` })),
        deleted_image_ids: [],
        photo_sorted: false
    });
    const writeDraft = React.useCallback((value: Partial<ProductInDraft>) => {
        setDraft({ ...draft, ...value });
    }, [draft]);
    React.useEffect(() => {
        const { default_ecommerce_categ_id, min_order_qty, max_order_qty, tri_product_variant_images, ...p } = product;
        setDraft({
            ...p,
            default_ecommerce_categ_id: default_ecommerce_categ_id?.id,
            min_order_qty: min_order_qty ?? 1,
            max_order_qty: max_order_qty ?? 0,
            photos: tri_product_variant_images?.map(({ image, ...p }) => ({ ...p, width: 1000, height: 1000 / (p.aspect_ratio ?? 1), image, src: `${settings.cloudfront_url}/${image}/x1000` })),
            deleted_image_ids: []
        });
    }, [product]);

    const {
        ecommerce_caption,
        ecommerce_description,
        ecommerce_categories,
        default_ecommerce_categ_id,
        uom_description,
        availability,
        min_order_qty,
        max_order_qty,
        available_date,
        preorder_description,
        excluded_companies,
        excluded_locations,
        exclude_consignment,
        quants,
        product_warranty,
        warranty_description,
        warranty_duration,
        warranty_unit,
        photos,
        deleted_image_ids,
        photo_sorted
    } = draft;

    const { current_parent } = useProductBrowserContext();
    const initCategories = () => {
        if (!product?.ecommerce_categories?.length) {
            if (current_parent) {
                const { id, complete_name, display_name } = current_parent;
                writeDraft({ ecommerce_categories: [{ id, name: "", complete_name: complete_name ?? display_name ?? "" }], default_ecommerce_categ_id: id });
            }
        }
    };
    React.useEffect(() => {
        initCategories();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [product?.id]);

    const overviewEditor = React.useRef<LexicalEditor>();

    React.useEffect(() => {
        overviewEditor?.current?.update(() => {
            if (!overview)
                return;
            // In the browser you can use the native DOMParser API to parse the HTML string.
            const parser = new DOMParser();
            const dom = parser.parseFromString(overview, "text/html");

            // Once you have the DOM instance it's easy to generate LexicalNodes.
            const nodes = $generateNodesFromDOM(overviewEditor?.current!, dom);
            const root = $getRoot();
            root.clear();
            root.append(...nodes);
        });
    }, [overview]);

    const [overviewInLexical, setOverviewInLexical] = React.useState<EditorState>();

    const { isValid, errors } = React.useMemo(() => {
        var isValid = true;
        const errors: { [key: string]: string } = {};
        if (!ecommerce_caption) {
            errors.ecommerce_caption = "Caption is required!";
            isValid = false;
        }
        if (!ecommerce_description) {
            errors.ecommerce_description = "Description is required!";
            isValid = false;
        }
        if (min_order_qty! <= 0) {
            errors.min_order_qty = "Min order qty should be at least 1";
            isValid = false;
        }
        if (max_order_qty! > 0 && max_order_qty! < min_order_qty!) {
            errors.max_order_qty = "Max order qty should be greater than min order qty!";
            isValid = false;
        }
        if (!availability) {
            errors.availability = "Select availability mode.";
            isValid = false;
        }
        if (!product_warranty) {
            errors.product_warranty = "Select product warranty.";
            isValid = false;
        }
        if (!warranty_unit && product_warranty !== ProductWarranty.not_available) {
            errors.warranty_unit = "Select warranty unit.";
            isValid = false;
        }

        if (!warranty_duration && product_warranty !== ProductWarranty.not_available) {
            errors.warranty_duration = "Warranty duration is required!";
            isValid = false;
        }

        return { isValid, errors };
    }, [availability, ecommerce_caption, ecommerce_description, max_order_qty, min_order_qty, product_warranty, warranty_duration, warranty_unit]);

    const [errorInfo, setErrorInfo] = React.useState<ErrorInfo | undefined>(loadingError);
    React.useEffect(() => {
        setErrorInfo(loadingError);
    }, [loadingError]);
    const handlePhotoDelete = React.useCallback((id: number) => {
        const index = photos?.findIndex(p => p.id === id.toString()) ?? -1;
        if (index > -1 && photos) {
            writeDraft({
                deleted_image_ids: [...(deleted_image_ids ?? []), id],
                photos: update(photos, {
                    $splice: [[index, 1]]
                })
            });
        }
    }, [deleted_image_ids, photos, writeDraft]);
    const { registerSaveProductCallback } = useProductBrowserContext();
    const [saveProduct, { loading: savingProduct }] = useMutation<SaveEcommerceProduct, SaveEcommerceProductVariables>(saveEcommerceProductMutation, {
        onError: (error) => {
            setErrorInfo({ title: "Error", detail: "Could not save product info", message: error.message });
        }
    });
    const { showError } = useAppContext();
    React.useEffect(() => {
        registerSaveProductCallback(() => {
            if (!isValid)
                return;
            const { ecommerce_description, ecommerce_caption, ecommerce_categories, default_ecommerce_categ_id, uom_description, availability, min_order_qty, max_order_qty, available_date, preorder_description, excluded_companies, excluded_locations, exclude_consignment, product_warranty, warranty_duration, warranty_unit, warranty_description, photos } = draft;
            const input: EcommerceProductInput = {
                id: product.id,
                ecommerce_description,
                ecommerce_caption,
                ecommerce_categ_ids: ecommerce_categories?.map(c => Number.parseInt(c.id)),
                default_ecommerce_categ_id: default_ecommerce_categ_id ? Number.parseInt(default_ecommerce_categ_id) : null,
                uom_description,
                availability,
                min_order_qty,
                max_order_qty,
                available_date,
                preorder_description,
                excluded_company_ids: excluded_companies?.map(c => Number.parseInt(c.id)),
                excluded_location_ids: excluded_locations?.map(l => Number.parseInt(l.id)),
                exclude_consignment: exclude_consignment,
                product_warranty,
                warranty_duration,
                warranty_unit,
                warranty_description,
                tri_product_variant_image_ids: {
                    deleted: deleted_image_ids,
                    list: photo_sorted ? photos?.map(({ id, description, image, sequence, aspect_ratio }) => ({ product_variant_id: Number.parseInt(product.id), id, description, image, sequence, aspect_ratio })) : []
                }
            };
            overviewEditor?.current?.update(() => {
                input.overview = $generateHtmlFromNodes(overviewEditor?.current!, null)
                saveProduct({ variables: { product: input } });
            });
        });
    }, [deleted_image_ids, draft, isValid, photo_sorted, product.id, registerSaveProductCallback, saveProduct]);
    return (<><Typography sx={_sx.subtitle} variant="h5">General Information</Typography>
        <Grid container sx={_sx.grid_container}>
            <Grid item xs={12} md={4}>
                <Box sx={_sx.field}>
                    <Typography sx={_sx.fieldLabel}>Model No:</Typography>
                    <Typography>{default_code}</Typography>
                </Box>
                <Box sx={_sx.field}>
                    <Typography sx={_sx.fieldLabel}>Barcode:</Typography>
                    <Typography>{barcode}</Typography>
                </Box>
            </Grid>
            <Grid item xs={12} md={4}>
                <Box sx={_sx.field}>
                    <Typography sx={_sx.fieldLabel}>Name:</Typography>
                    <Typography>{name}</Typography>
                </Box>
                <Box sx={_sx.field}>
                    <Typography sx={_sx.fieldLabel}>Sale Zone:</Typography>
                    <Typography>{zone_id?.display_name}</Typography>
                </Box>
            </Grid>
            <Grid item xs={12} md={4}>
                <Box sx={_sx.field}>
                    <Typography sx={_sx.fieldLabel}>Brand:</Typography>
                    <Typography>{brand_id?.display_name}</Typography>
                </Box>
                <Box sx={_sx.field}>
                    <Typography sx={_sx.fieldLabel}>Internal Category:</Typography>
                    <Typography>{categ_id?.display_name}</Typography>
                </Box>
            </Grid>
            <Grid item xs={12} md={4}>
                <Box sx={_sx.field}>
                    <Typography sx={_sx.fieldLabel}>UOM:</Typography>
                    <Typography>{uom_id?.display_name}</Typography>
                </Box>
                <Box sx={_sx.field}>
                    <Typography sx={_sx.fieldLabel}>Related Companies:</Typography>
                    <Typography>{related_companies?.map(c => c.name).join(",")}</Typography>
                </Box>
            </Grid>
        </Grid>
        <Typography sx={_sx.subtitle} variant="h5">Ecommerce</Typography>
        <Grid item container spacing={2} sx={_sx.grid_container}>
            <Grid item xs={12} md={12}>
                <TextEditor
                    label="Website Caption"
                    value={ecommerce_caption ?? ""}
                    fullWidth
                    size="small"
                    inputElementType="TextField"
                    onValidated={(value) => { writeDraft({ ecommerce_caption: value }) }}
                    error={!!errors.ecommerce_caption}
                    helperText={errors.ecommerce_caption}
                />
            </Grid>
            <Grid item xs={12} md={12}>
                <TextEditor
                    label="Website Description"
                    value={ecommerce_description ?? ""}
                    fullWidth
                    size="small"
                    multiline
                    rows={4}
                    inputElementType="TextField"
                    onValidated={(value) => { writeDraft({ ecommerce_description: value }) }}
                    error={!!errors.ecommerce_description}
                    helperText={errors.ecommerce_description}
                />
            </Grid>
            <Grid item xs={12} >
                <LexicalComposer initialConfig={{
                    namespace: "product_overview_readonly",
                    nodes: [...PlaygroundNodes],
                    editable: false,
                    onError: (error) => {
                        showError("Over view editor error.", error);
                    },
                    theme: PlaygroundEditorTheme,
                }}>
                    <RichTextPlugin contentEditable={<Box position="relative" borderRadius={1} border="1px solid" borderColor="divider" sx={{ paddingLeft: 1, paddingRight: 1, paddingTop: 2, paddingBottom: 2, cursor: "text", outline: 0, resize: "vertical", "& .editor": { outline: 0, minHeight: 150 } }} overflow="auto" display="block"  >
                        <ContentEditable className="editor" />
                        <Fab size="small" sx={{ position: "absolute", right: 16, bottom: 16 }} onClick={() => { setOverviewInLexical(overviewEditor?.current?.getEditorState()); }} color="secondary" aria-label="edit">
                            <Edit />
                        </Fab>
                    </Box>}
                        placeholder={null}
                        ErrorBoundary={LexicalErrorBoundary} />
                    <RefPlugin editorRef={overviewEditor} />
                </LexicalComposer>
            </Grid>
            <Grid item xs={12} md={12}>
                <EcommerceCategoryMultiSelector
                    label="Categories"
                    values={ecommerce_categories ?? []}
                    fullWidth
                    size="small"
                    defaultCategory={default_ecommerce_categ_id}
                    onSelected={(values, default_ecommerce_categ_id) => { writeDraft({ ecommerce_categories: values, default_ecommerce_categ_id }) }}
                />
            </Grid>
            <Grid item xs={12} md={12}>
                <TextEditor
                    label="UOM Description"
                    value={uom_description ?? ""}
                    fullWidth
                    size="small"
                    inputElementType="TextField"
                    onValidated={(value) => { writeDraft({ uom_description: value }) }}
                    error={!!errors.uom_description}
                    helperText={errors.uom_description}
                />
            </Grid>
        </Grid>

        <Typography sx={_sx.subtitle} variant="h5">Availability</Typography>
        <Grid item container spacing={2} sx={_sx.grid_container}>
            <Grid item xs={12} sm={6} md={3}>
                <EnumSingleSelectEditor
                    label="Availability Mode"
                    options={ProductAvailabilityOptions}
                    value={availability ?? ""}
                    size="small"
                    fullWidth
                    onChange={(e) => { writeDraft({ availability: e.target.value as any }); }}
                    error={!!errors.availability}
                    helperText={errors.availability}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <NumberEditor
                    label="Min Order Qty"
                    value={min_order_qty ?? undefined}
                    numberPrecision={0}
                    size="small"
                    fullWidth
                    inputElementType="TextField"
                    onValidated={(value) => { writeDraft({ min_order_qty: value }) }}
                    error={!!errors.min_order_qty}
                    helperText={errors.min_order_qty}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <NumberEditor
                    label="Max Order Qty"
                    value={max_order_qty ?? undefined}
                    numberPrecision={0}
                    size="small"
                    fullWidth
                    inputElementType="TextField"
                    onValidated={(value) => { writeDraft({ max_order_qty: value }) }}
                    error={!!errors.max_order_qty}
                    helperText={errors.max_order_qty}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <Picker
                    minDateTime={new Date()}
                    renderInput={(props) => <TextField size="small" {...props} fullWidth
                        error={!!errors.ruleDateTo}
                        helperText={errors.ruleDateTo} />}
                    label="Available Date"
                    value={available_date}
                    inputFormat={settings.longDateFormat}
                    onChange={(date) => { writeDraft({ available_date: format(date, "yyyy-MM-dd HH:mm:ss") }) }}
                />
            </Grid>
            <Grid item xs={12} md={12}>
                <TextEditor
                    label="Pre-order Message"
                    value={preorder_description ?? ""}
                    fullWidth
                    size="small"
                    inputElementType="TextField"
                    onValidated={(value) => { writeDraft({ preorder_description: value }) }}
                    error={!!errors.preorder_description}
                    helperText={errors.preorder_description}
                />
            </Grid>
            <Grid item xs={12}><FormControlLabel
                label="Include Consignment"
                control={
                    <Checkbox
                        checked={!exclude_consignment}
                        onChange={(_, checked) => { writeDraft({ exclude_consignment: !checked }) }}
                    />
                }
            /></Grid>
            <LocationAvailabilityEditor excluded_companies={excluded_companies ?? []}
                excluded_locations={excluded_locations ?? []}
                quants={quants ?? []}
                related_companies={related_companies}
                available_quants={available_quants}
                onChange={(excluded_companies, excluded_locations) => writeDraft({ excluded_companies, excluded_locations })}
            />
        </Grid>
        <Typography sx={_sx.subtitle} variant="h5">Warranty Information</Typography>
        <Grid item container spacing={2} sx={_sx.grid_container}>
            <Grid item xs={12} sm={6} md={3}>
                <EnumSingleSelectEditor
                    label="Product Warranty"
                    options={ProductWarrantyOptions}
                    value={product_warranty ?? ""}
                    size="small"
                    fullWidth
                    onChange={(e) => { writeDraft({ product_warranty: e.target.value as any }); }}
                    error={!!errors.product_warranty}
                    helperText={errors.product_warranty}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <NumberEditor
                    label="Warranty Duration"
                    value={warranty_duration ?? undefined}
                    numberPrecision={0}
                    size="small"
                    fullWidth
                    inputElementType="TextField"
                    onValidated={(value) => { writeDraft({ warranty_duration: value }) }}
                    error={!!errors.warranty_duration}
                    helperText={errors.warranty_duration}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <EnumSingleSelectEditor
                    label="Warranty Unit"
                    options={WarrantyUnitOptions}
                    value={warranty_unit ?? ""}
                    size="small"
                    fullWidth
                    onChange={(e) => { writeDraft({ warranty_unit: e.target.value as any }); }}
                    error={!!errors.warranty_unit}
                    helperText={errors.warranty_unit}
                />
            </Grid>
            <Grid item xs={12} md={12}>
                <TextEditor
                    label="Warranty Description"
                    value={warranty_description ?? ""}
                    fullWidth
                    size="small"
                    multiline
                    rows={2}
                    inputElementType="TextField"
                    onValidated={(value) => { writeDraft({ warranty_description: value }) }}
                    error={!!errors.warranty_description}
                    helperText={errors.warranty_description}
                />
            </Grid>
        </Grid>
        <Box flex={1}>
            <ProductPhotoAlbum onDelete={handlePhotoDelete} onError={setErrorInfo} photos={photos ?? []} setPhotos={(photos) => { writeDraft({ photos, photo_sorted: true }) }} product_id={Number.parseInt(product.id)} />
        </Box>
        <OverviewEditor namespace="product_overview" editorState={overviewInLexical} open={Boolean(overviewInLexical)} onCancel={() => { setOverviewInLexical(undefined); }} onSubmit={(state) => { if (state) overviewEditor.current?.setEditorState(state);setOverviewInLexical(undefined); }} />
        {savingProduct ? <ActivityIndicatorDialog open={savingProduct} message={"Saving..."} /> : null}

        {errorInfo ? (
            <ErrorMessageDialog
                open={!!errorInfo}
                onClose={() => {
                    setErrorInfo(undefined);
                }}
                error={errorInfo}
            />
        ) : null}
    </>);
}

type LocationAvailabilityEditorProps = {
    excluded_companies: EcommerceProduct_ecommerceQuery_product_excluded_companies[];
    excluded_locations: EcommerceProduct_ecommerceQuery_product_excluded_locations[];
    quants: EcommerceProduct_ecommerceQuery_product_quants[];
    related_companies: EcommerceProduct_ecommerceQuery_product_related_companies[];
    available_quants: EcommerceProduct_ecommerceQuery_product_available_quants[];
    onChange: (excluded_companies: EcommerceProduct_ecommerceQuery_product_excluded_companies[], excluded_locations: EcommerceProduct_ecommerceQuery_product_excluded_locations[]) => void;
}
function LocationAvailabilityEditor({ excluded_companies, excluded_locations, quants, related_companies, available_quants, onChange }: LocationAvailabilityEditorProps) {
    const companiesChanged = React.useCallback((id: EcommerceProduct_ecommerceQuery_product_excluded_companies) => {
        const index = excluded_companies?.findIndex(c => c.id === id.id.toString());
        const newExcluded = index > -1 ? update(excluded_companies, {
            $splice: [[index, 1]]
        }) : update(excluded_companies, { $push: [id] });
        onChange(newExcluded, []);
    }, [excluded_companies, onChange]);
    const locationsChanged = React.useCallback((id: EcommerceProduct_ecommerceQuery_product_excluded_locations) => {
        const index = excluded_locations?.findIndex(c => c.id === id.id.toString());
        const newExcluded = index > -1 ? update(excluded_locations, {
            $splice: [[index, 1]]
        }) : update(excluded_locations, { $push: [id] })
        onChange(excluded_companies, newExcluded);
    }, [excluded_companies, excluded_locations, onChange]);
    return <>
        {related_companies.map(c => {
            const available_quant = available_quants.find(q => q.company_id.toString() === c.id);
            const available_quantity = (available_quant?.quantity_sum ?? 0) - (available_quant?.reserved_quantity_sum ?? 0)
            return (<React.Fragment key={c.id}>
                <Grid item xs={12}>
                    <ListItem>
                        <ListItemButton onClick={() => {
                            companiesChanged(c);
                        }} role={undefined} dense>
                            <ListItemIcon>
                                <Checkbox
                                    checked={!excluded_companies.find(ec => ec.id === c.id)}
                                    edge="start"
                                    tabIndex={-1}
                                    disableRipple
                                />
                            </ListItemIcon>
                            <ListItemText primary={c.name} secondary={accounting.formatNumber(available_quantity, 0)} />
                        </ListItemButton>
                    </ListItem>
                </Grid>
                <Grid borderLeft="1px solid rgba(255,255,255,.5)" spacing={0} item container marginLeft={7}>
                    {
                        quants.filter(q => q.company_id.toString() === c.id).map(q => (<Grid key={q.location_id?.toString()} item xs={12} md={4}>
                            <ListItem dense >
                                <ListItemButton onClick={() => { locationsChanged({ id: q.location_id.toString(), complete_name: q.location_name }) }} role={undefined} dense>
                                    <ListItemIcon>
                                        <Checkbox
                                            checked={!excluded_locations.find(l => l.id === q.location_id.toString())}
                                            edge="start"
                                        />
                                    </ListItemIcon>
                                    <ListItemText primary={q.location_name} secondary={accounting.formatNumber((q.quantity_sum ?? 0) - (q.reserved_quantity_sum ?? 0))} />
                                </ListItemButton>
                            </ListItem>
                        </Grid>))
                    }
                </Grid>
            </React.Fragment>);
        })}
    </>;
}

function ProductLoader() {
    const { product_id: product_id_text } = useParams<{ product_id: string }>();
    const product_id = Number.parseInt(product_id_text ?? "");
    const { data: queryResult, error, loading } = useQuery<EcommerceProduct, EcommerceProductVariables>(ecommerceProductQuery, {
        variables: { id: product_id },
        skip: isNaN(product_id)
    });
    const product = queryResult?.ecommerceQuery?.product;
    return (<Box flex={1} height="100%" sx={_sx.scroller}>
        <Box flex={1} sx={_sx.content}>
            <Container maxWidth="lg" >
                <Box
                    flexDirection="row"
                    boxShadow={1}
                    p={2}
                    display="flex"
                    flexWrap="wrap"
                    sx={_sx.paper}
                >
                    {product ? <ProductEditor product={product} loadingError={error ? {
                        title: "Error",
                        message: "Could not load product info.",
                        detail: error?.message
                    } : undefined} /> : null}
                    {loading ? <ActivityIndicatorDialog open={loading} message={"Loading..."} /> : null}
                </Box>
            </Container>
        </Box>
    </Box>);
}

export default ProductLoader;