import { Typography } from "@mui/material";
import Box from "@mui/material/Box/Box";
import Container from "@mui/material/Container/Container";
import LabelPrintSetting from "./LabelPrintSetting";

const _sx = {
  scroller: {
    flex: 1,
    overflow: "auto",
    backgroundColor:"rgba(0,0,0,.05)"
  },
  content: {
    marginTop: 2,
    paddingBottom: 2,
  },
  appBar: {},
  title: {
    marginRight: 1,
  },
  settingTitle: {
    marginTop: 1,
  },
};
function ProductLabelSetting() {
  return (
    <Box sx={_sx.scroller}>
        <Container maxWidth="lg">
          <Typography sx={_sx.settingTitle} variant="h6">
            Product Label Template
          </Typography>
          <LabelPrintSetting />
        </Container>
      </Box>
  );
}

export default ProductLabelSetting;
