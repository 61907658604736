import * as React from "react";
import { UsersVariables } from "./__generated__/Users";
import update from "immutability-helper";
import CreateDialog from "./CreateDialog";
import {
  Outlet,
  useMatch,
  useOutletContext,
  useParams,
} from "react-router-dom";
import {
  AppBar,
  Box,
  Grid,
  Hidden,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import CsSearchBox, { OperandType } from "../../components/CsSearchBox";
import { Add } from "@mui/icons-material";
import DrawerToggleButton from "../../app/DrawerToggleButton";
import ErrorMessageDialog from "../../components/ErrorMessageDialog";
import { UserOrderBy } from "../../types/global-types";
import UserGrid from "./UserGrid";

const _sx = {
  title: {
    marginRight: 1,
  },
  box: {
    backgroundColor: "rgba(255,255,255,.1)",
  },
  root: {
    flex: 1,
    display: "flex",
  },

  loadingIndicator: {
    backgroundColor: "#DDDDDD",
    color: "#DDDDDD",
    width: 150,
    display: "inline",
  },
  selected: {
    backgroundColor: "rgba(255, 255, 255, 0.14)",
  },
};

export type UserOutletContextType = {
  user_id: number;
  usersVariables: UsersVariables;
};

export function useUserOutletContext() {
  return useOutletContext<UserOutletContextType>();
}

function UserBrowser() {
  const { id: user_id } = useParams<{ id: string }>();
  const [variables, setVariables] = React.useState<UsersVariables>({
    pagination: { pageSize: 50 },
    orderBy: [UserOrderBy.code_Desc],
  });
  const [operationError, setOperationError] = React.useState<Error | null>(
    null
  );
  const [operationErrorMessage, setOperationErrorMessage] =
    React.useState<string>("");
  const [errorMessageDialogOpen, setErrorMessageDialogOpen] =
    React.useState(false);
  const [isCreateDialogOpen, setIsCreateDialogOpen] = React.useState(false);
  const closeCreateDialog = React.useCallback(() => {
    setIsCreateDialogOpen(false);
  }, [setIsCreateDialogOpen]);
  const openCreateDialog = React.useCallback(() => {
    setIsCreateDialogOpen(true);
  }, [setIsCreateDialogOpen]);
  const isUserRoute = useMatch("users/:id");
  const isUsersRoute = useMatch("users");

  return (
    <>
      <CreateDialog
        open={isCreateDialogOpen}
        onClose={closeCreateDialog}
        variables={variables}
      />
      <Grid container direction="column">
        <AppBar position="static">
          <Toolbar
            sx={(theme) => ({
              [theme.breakpoints.up("md")]: {
                minHeight: 48,
              },
              paddingLeft: 1.5,
            })}
          >
            <DrawerToggleButton />
            <Typography variant="h6" sx={_sx.title} color="inherit" noWrap>
              Users
            </Typography>
            {isUserRoute ? (
              <>
                <Hidden xlUp>
                  <CsSearchBox
                    onConditionChanged={(conditions: any) => {
                      setVariables(
                        update(variables, {
                          where: {
                            $set: { aND: conditions },
                          },
                        })
                      );
                    }}
                    operands={{
                      code: {
                        name: "Code#",
                        propName: "code",
                        type: OperandType.STRING,
                      },
                      fullName: {
                        name: "Full Name",
                        propName: "fullName",
                        type: OperandType.STRING,
                      },
                    }}
                  />
                  <IconButton onClick={openCreateDialog}>
                    <Add />
                  </IconButton>
                </Hidden>
              </>
            ) : null}
            {isUsersRoute ? (
              <>
                <CsSearchBox
                  onConditionChanged={(conditions: any) => {
                    setVariables(
                      update(variables, {
                        where: {
                          $set: { aND: conditions },
                        },
                      })
                    );
                  }}
                  operands={{
                    code: {
                      name: "Code#",
                      propName: "code",
                      type: OperandType.STRING,
                    },
                    fullName: {
                      name: "Full Name",
                      propName: "fullName",
                      type: OperandType.STRING,
                    },
                  }}
                />
                <IconButton onClick={openCreateDialog}>
                  <Add />
                </IconButton>
              </>
            ) : null}
          </Toolbar>
        </AppBar>
        <Box
          sx={{
            flex: 1,
            overflow: "hidden",
            display: "flex",
            flexDirection: "row",
            flexWrap: "nowrap",
          }}
        >
          <Hidden smDown>
            <UserGrid variables={variables} onVariablesChanged={setVariables} />
          </Hidden>
          {isUsersRoute && (
            <Hidden smUp>
              <UserGrid
                variables={variables}
                onVariablesChanged={setVariables}
              />
            </Hidden>
          )}
          <Outlet context={{ user_id, usersVariables: variables }} />
        </Box>
      </Grid>
      {operationError ? (
        <ErrorMessageDialog
          open={errorMessageDialogOpen}
          onClose={() => {
            setErrorMessageDialogOpen(false);
          }}
          error={{
            title:"Error",
            message: operationErrorMessage,
            detail:operationError.message
        }}
        />
      ) : null}
    </>
  );
}

export default UserBrowser;
