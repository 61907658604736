import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Optional } from "utility-types";
import { ProductItems_productQuery_productItems_edges } from "../common/__generated__/ProductItems";
import { Promotion_promotionQuery_promotion_loyalty_programs } from "./__generated__/Promotion";
import { DiscountMode, RewardType } from "../../types/global-types";

export type CouponProgramReduxState = Optional<
Promotion_promotionQuery_promotion_loyalty_programs,
"product_id" | "reward_product_id" 
>;
export const loyaltyProgramDefaultValue: CouponProgramReduxState = {
  id: "",
  name: "",
  minimum_qty: 1,
  discount: 0,
  discount_max_amount: 0,
  updated_id: "",
  updated_name: "",
  reward_type: RewardType.discount,
  discount_mode: DiscountMode.per_point,
  active: true,
};

export type CouponProgramPayload = {
  product: ProductItems_productQuery_productItems_edges;
  reward_product?: ProductItems_productQuery_productItems_edges;
  discount_mode?: DiscountMode;
  discount?: number;
  discount_max_amount?:number;
  minimum_qty: number|any;
  reward_type?: RewardType;
  active: boolean;
};



export const loyaltyProgramSlice = createSlice({
  name: "loyaltyPrograms",
  initialState: loyaltyProgramDefaultValue,
  reducers: {    
    initCouponProgram: (state, action: PayloadAction<CouponProgramPayload>) => {
      const { default_code, name, id } = action.payload.product;
      const {discount_mode, discount,reward_type} = action.payload;
      state.name = `${name} ${default_code}`;
      state.discount_mode = discount_mode?? DiscountMode.per_point;
      state.discount = discount??0;
      if (reward_type)
        state.reward_type = reward_type;
      state.minimum_qty = action.payload.minimum_qty;
      state.discount_max_amount = action.payload.discount_max_amount??0;
      state.product_id = {
        id: id?.toString()!,
        display_name: `[${default_code}] ${name}`
      }
      const reward_product = action.payload.reward_product;
      if(reward_product)
        state.reward_product_id = {
          id:reward_product.id?.toString()!,
          display_name: `[${reward_product.default_code}] ${reward_product.name}`
        }
      state.active = action.payload.active;
    },
  },
});

export const {
  initCouponProgram ,
} = loyaltyProgramSlice.actions;
export default loyaltyProgramSlice.reducer;
