import Box from "@mui/material/Box/Box";
import Button from "@mui/material/Button/Button";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import Typography from "@mui/material/Typography/Typography";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "./app/Layout";
import Login from "./app/Login";
import { AppDispatch, RootState } from "./app/store";
import { pingAsync } from "./features/site/siteSlice";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import ProductBrowser from "./features/product";
import ProductDetailSideBar from "./features/product/ProductDetailSidebar";
import RptInvoices from "./features/reports/InvoicesReport";
import RptGp from "./features/reports/GpReport";
import { AccountList, CashBalanceHistory, Transactions } from "./features/reports/CashBalance";
import CashBalance from "./features/reports/CashBalance";
import Settings from "./features/setting";
import RptPurchase from "./features/reports/PurchaseReport";
import RptStockValuation from "./features/reports/StockValuationReport/StockValuationReport";
import SaleOrderBrowser from "./features/sale";
import SaleOrderEdit from "./features/sale/SaleOrderEdit";
import SaleOrderRenew from "./features/sale/SaleOrderRenew";
import SaleOrderCreate from "./features/sale/SaleOrderCreate";
import Testing from "./testing";
import UserBrowser from "./features/user";
import UserDetail from "./features/user/UserDetail";
import SaleSetting from "./features/setting/SaleSetting";
import ProductLabelSetting from "./features/setting/ProductLabelSetting";
import PriceLabelSetting from "./features/setting/PriceLabelSetting";
import Authorize from "./app/Authorize";
import PromotionsBrowser from "./features/promotion";
import PromotionCreate from "./features/promotion/PromotionCreate";
import PromotionEdit from "./features/promotion/PromotionEdit";
import GeneralSetting from "./features/setting/GeneralSetting";
import StockPickingsBrowser from "./features/inventory/StockPicking";
import StockPickingEdit from "./features/inventory/StockPicking/StockPickingEdit";
import LandedCostCheckListsBrowser from "./features/reports/LandedCostCheckList";
import InventoryReport from "./features/reports/InventoryReport";
import InventoryTransactionHistory from "./features/reports/InventoryReport/transaction";
import Ecommerce from "./features/ecommerce/product_browser";
import ProductEditor from "./features/ecommerce/product_browser/ProductEditor";
import ProductMasonry from "./features/ecommerce/product_browser/ProductMasonry";
import PromotionSaleViewer from "./features/promotion/PromotionSaleViewer";
function Ping() {
    const { userSession, pingStatus, pingMessage } = useSelector((state: RootState) => state.site);
    const dispatch: AppDispatch = useDispatch();
    const ping = React.useCallback(() => {
        dispatch(pingAsync());
    }, [dispatch]);
    React.useEffect(() => {
        ping();
    }, [ping]);
    if (pingStatus === "error") {
        return (
            <Box flex={1} display="flex" alignItems="center" justifyContent="center">
                <Box
                    alignItems="center"
                    justifyContent="center"
                    flexDirection="column"
                    display="flex"
                >
                    <Typography padding={2}>{pingMessage}</Typography>
                    <Button
                        color="primary"
                        onClick={() => {
                            ping();
                        }}
                    >
                        Try Again
                    </Button>
                </Box>
            </Box>
        );
    } else if (pingStatus === "loading") {
        return (
            <Box flex={1} display="flex" alignItems="center" justifyContent="center">
                <Box
                    alignItems="center"
                    justifyContent="center"
                    flexDirection="column"
                    display="flex"
                >
                    <CircularProgress />
                    <Typography padding={2}>Loading ....</Typography>
                </Box>
            </Box>
        );
    } else if (
        userSession &&
        userSession.authenticated &&
        userSession.validTo &&
        new Date(userSession.validTo).getTime() > new Date().getTime() &&
        userSession.authenticated
    ) {
        return (<Routes>
            <Route index element={<Navigate to="products" replace />} />
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<Layout />}>
                <Route path="products" element={<ProductBrowser />}>
                    <Route path=":id" element={<ProductDetailSideBar />} />
                </Route>
                <Route path="reports" element={<Outlet />}>
                    <Route path="gp" element={<Authorize policy="GPReport_View"><RptGp /></Authorize>} />
                    <Route path="purchase_detail" element={<Authorize policy="PurchaseDetailReport_View"><RptPurchase /></Authorize>} />
                    <Route path="invoices" element={<Authorize policy="SaleReport_View"><RptInvoices /></Authorize>} />
                    <Route path="stock_valuation" element={<Authorize policy="InventoryValueReport_View"><RptStockValuation /></Authorize>} />
                    <Route path="inventory_report/:start_date/:end_date" element={<InventoryReport />}>
                        <Route path="details/:product_id" element={<InventoryTransactionHistory />} />
                        <Route path="details/:product_id/:location_id" element={<InventoryTransactionHistory />} />
                    </Route>
                    <Route path="cash_book" element={<Authorize policy="CashBook_View"><CashBalance /></Authorize>}>
                        <Route index element={<AccountList />} />
                        <Route path=":id" element={<AccountList />}>
                            <Route index element={<CashBalanceHistory />} />
                            <Route path="transactions" element={<CashBalanceHistory />}>
                                <Route index element={<Transactions />} />
                            </Route>
                        </Route>
                    </Route>
                    <Route path="check_landed_costs" element={<Authorize policy="InventoryValueReport_View"><LandedCostCheckListsBrowser /></Authorize>} />
                </Route>
                <Route path="sale_orders" element={<Authorize policy="Sale_View"><SaleOrderBrowser /></Authorize>}>
                    <Route path=":id" element={<SaleOrderEdit />} />
                    <Route path="renew" element={<SaleOrderRenew />} />
                    <Route path="create" element={<SaleOrderCreate />} />
                </Route>
                <Route path="users" element={<Authorize policy="All_All"><UserBrowser /></Authorize>}>
                    <Route path=":id" element={<UserDetail />} />
                </Route>
                <Route path="settings" element={<Authorize policy="Settings_View"><Settings /></Authorize>}>
                    <Route index element={<GeneralSetting />} />
                    <Route path="sale" element={<Authorize policy="Settings_Sale"><SaleSetting /></Authorize>} />
                    <Route path="product_label" element={<Authorize policy="Settings_LabelFormats"><ProductLabelSetting /></Authorize>} />
                    <Route path="price_label" element={<Authorize policy="Settings_LabelFormats"><PriceLabelSetting /></Authorize>} />
                </Route>
                <Route path="promotions/sales" element={<Authorize policy="Sale_Promotion"><Outlet/></Authorize>}>
                    <Route index element={<PromotionSaleViewer />}/>
                    <Route path=":id" element={<PromotionSaleViewer />}/>
                </Route>
                <Route path="promotions" element={<Authorize policy="Sale_Promotion"><PromotionsBrowser /></Authorize>}>
                    <Route path=":id" element={<PromotionEdit />} />
                    <Route path="create" element={<PromotionCreate />} />
                </Route>
                <Route path="inventory" element={<Outlet />}>
                    <Route path="stock_pickings" element={<StockPickingsBrowser />} >
                        <Route path=":id" element={<StockPickingEdit />} />
                    </Route>
                </Route>
                <Route path="ecommerce" element={<Ecommerce />}>
                    <Route index element={<ProductMasonry />} />
                    <Route path="product/:product_id" element={<ProductEditor />} />
                </Route>
                <Route path="ecommerce/:id" element={<Ecommerce />}>
                    <Route index element={<ProductMasonry />} />
                    <Route path="product/:product_id" element={<ProductEditor />} />
                </Route>
                <Route path="test" element={<Testing />} />
            </Route>
        </Routes>)
    } else {
        return <Login />
    }
}

export default Ping;