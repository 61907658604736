import { AppBar, Box, Grid, Hidden, IconButton, Toolbar, Tooltip, Typography } from "@mui/material";
import React from "react";
import { Outlet, useMatch, useNavigate, useOutletContext, useParams } from "react-router-dom";
import DrawerToggleButton from "../../../app/DrawerToggleButton";
import CsSearchBox, { OperandType } from "../../../components/CsSearchBox";
import ErrorMessageDialog from "../../../components/ErrorMessageDialog";
import { StockPickingOrderBy } from "../../../types/global-types";
import { StockPickingsVariables } from "../../sale/__generated__/StockPickings";
import update from "immutability-helper";
import StockPickingGrid from "./StockPickingGrid";
import { Close } from "@mui/icons-material";
import ArrowBack from "@mui/icons-material/ArrowBack";
import { useQuery } from "@apollo/client/react/hooks";
import { StockPicking, StockPickingVariables } from "../../sale/__generated__/StockPicking";
import { stockPickingQuery } from "../../sale/graphql";
import StockPickingStateIndicator from "../../sale/PickingStateIndicator";
import Print from "@mui/icons-material/Print";
import { ApolloError } from "@apollo/client";

const _sx = {
  title: {
    marginRight: 1,
  },
  box: {
    backgroundColor: "rgba(255,255,255,.1)",
  },
  root: {
    flex: 1,
    display: "flex",
  },
};

export type StockPickingOutletContextType = {
  picking_id?: string;
  registerPrintLabelAction: (printLabel:()=>void) => void;
  unRegisterPrintLabelAction:()=>void;
  setEnablePrintLabel:(enable:boolean)=>void;
  onError:(error:ApolloError, message:string)=>void;
};

export function useStockPickingOutletContext() {
  return useOutletContext<StockPickingOutletContextType>();
}

function StockPickingsBrowser() {
  const { id: picking_id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [variables, setVariables] = React.useState<StockPickingsVariables>({
    pagination: { pageSize: 40, page: 1 },
    orderBy: [StockPickingOrderBy.id_Desc]
  });
  const [enablePrintLabel,setEnablePrintLabel] = React.useState(false);
  const [operationError, setOperationError] = React.useState<Error | null>(null);
  const printLabelAction = React.useRef<null|(()=>void) >(null);
  const registerPrintLabelAction = React.useCallback((action:()=>void)=>{
    printLabelAction.current = action;
  },[]);
  const unRegisterPrintLabelAction = React.useCallback(()=>{
    printLabelAction.current = null;
  },[])
  const handlePrintLabels = ()=>{
    if(printLabelAction.current)
      printLabelAction.current();
  }
  const [operationErrorMessage, setOperationErrorMessage] =
    React.useState<string>("");
  const [errorMessageDialogOpen, setErrorMessageDialogOpen] =
    React.useState(false);

  const onError = React.useCallback((error:ApolloError,message:string)=>{
    setOperationErrorMessage(message);
    setOperationError(error);
    setErrorMessageDialogOpen(true);
  },[]);

  const isStockPickingRoute = useMatch("inventory/stock_pickings/:id");
  const isStockPickingsRoute = useMatch("inventory/stock_pickings");


  const { data: queryResult } = useQuery<StockPicking, StockPickingVariables>(
    stockPickingQuery,
    {
      variables: {
        id: Number.parseInt(picking_id!),
      },
      fetchPolicy: "cache-only"
    }
  );
  return (
    <>
      <Grid container direction="column">
        <AppBar position="static" >
          <Toolbar sx={(theme) => ({
            [theme.breakpoints.up("md")]: {
              minHeight: 48,
            },
            paddingLeft: 1.5,
          })}>
            <DrawerToggleButton />
            {
              isStockPickingRoute ? (<>
                <Hidden xlUp>
                  <Tooltip title="Back to Pickings">
                    <IconButton
                      color="inherit"
                      onClick={() => {
                        navigate("/inventory/stock_pickings");
                      }}
                    >
                      <ArrowBack />
                    </IconButton>
                  </Tooltip>
                </Hidden>
                <Typography
                  variant="h6"
                  sx={_sx.title}
                  color="inherit"
                  noWrap
                >
                  {queryResult?.inventoryQuery?.picking?.name}
                </Typography>
                {
                  queryResult?.inventoryQuery?.picking?.state ? <StockPickingStateIndicator
                    state={queryResult?.inventoryQuery?.picking?.state}
                  /> : null
                }

              </>) : null
            }
            {
              isStockPickingsRoute ? (<>
                <Typography
                  variant="h6"
                  sx={_sx.title}
                  color="inherit"
                  noWrap
                >
                  Stock Picking
                </Typography>
                <CsSearchBox
                  onConditionChanged={(conditions: any) => {
                    setVariables(
                      update(variables, {
                        where: {
                          $set: { aND: conditions },
                        },
                      })
                    );
                  }}
                  operands={{
                    name: {
                      name: "Reference",
                      propName: "name",
                      type: OperandType.STRING,
                    },
                    location_dest_id: {
                      name: "From",
                      propName: "location_dest_id",
                      type: OperandType.STRING,
                    },
                    location_id: {
                      name: "To",
                      propName: "location_id",
                      type: OperandType.STRING,
                    },
                    contact: {
                      name: "Contact",
                      propName: "partner_id",
                      type: OperandType.STRING,
                    },
                    date_done: {
                      name: "Scheduled Date",
                      propName: "date_done",
                      type: OperandType.DATE,
                    },
                    origin: {
                      name: "Source Document",
                      propName: "origin",
                      type: OperandType.STRING,
                    },
                    picking_type_id: {
                      name: "Company",
                      propName: "picking_type_id",
                      type: OperandType.STRING,
                    },
                    state: {
                      name: "Status",
                      propName: "state",
                      type: OperandType.STRING,
                    },
                  }}
                />
              </>) : null
            }
            {
              isStockPickingRoute ? (<Hidden lgDown>
                <CsSearchBox
                  onConditionChanged={(conditions: any) => {
                    setVariables(
                      update(variables, {
                        where: {
                          $set: { aND: conditions },
                        },
                      })
                    );
                  }}
                  operands={{
                    name: {
                      name: "Reference",
                      propName: "name",
                      type: OperandType.STRING,
                    },
                    location_dest_id: {
                      name: "From",
                      propName: "location_dest_id",
                      type: OperandType.STRING,
                    },
                    location_id: {
                      name: "To",
                      propName: "location_id",
                      type: OperandType.STRING,
                    },
                    contact: {
                      name: "Contact",
                      propName: "partner_id",
                      type: OperandType.STRING,
                    },
                    date_done: {
                      name: "Scheduled Date",
                      propName: "date_done",
                      type: OperandType.DATE,
                    },
                    origin: {
                      name: "Source Document",
                      propName: "origin",
                      type: OperandType.STRING,
                    },
                    picking_type_id: {
                      name: "Company",
                      propName: "picking_type_id",
                      type: OperandType.STRING,
                    },
                    state: {
                      name: "Status",
                      propName: "state",
                      type: OperandType.STRING,
                    },
                  }}
                />
                {
                  enablePrintLabel ? <Tooltip title="Print Label">
                    <IconButton
                      color="inherit"
                      onClick={handlePrintLabels}
                    >
                      <Print />
                    </IconButton>
                  </Tooltip> : null
                }
                <Tooltip title="Close stocking picking view.">
                  <IconButton
                    color="inherit"
                    onClick={() => {
                      navigate("/inventory/stock_pickings");
                    }}
                  >
                    <Close />
                  </IconButton>
                </Tooltip>
              </Hidden>) : null
            }
          </Toolbar>
        </AppBar>
        <Box
          sx={_sx.box}
          flex={1}
          display="flex"
          flexDirection="row"
        >
          <Box
            sx={{
              minWidth: 300,
              flex: isStockPickingRoute ? 0 : 1,
              display: { xs: isStockPickingRoute ? "none" : "flex", xl: "flex" }
            }}
          >
            <StockPickingGrid
              variables={variables}
              onVariablesChanged={setVariables}
            />
          </Box>
          <Outlet context={{ picking_id,registerPrintLabelAction,unRegisterPrintLabelAction,setEnablePrintLabel,onError }} />
        </Box>
      </Grid>
      {operationError ? (
        <ErrorMessageDialog
          open={errorMessageDialogOpen}
          onClose={() => {
            setErrorMessageDialogOpen(false);
          }}
          error={{
            title:"Error",
            message: operationErrorMessage,
            detail:operationError.message
        }}
        />
      ) : null}
    </>
  )
}

export default StockPickingsBrowser;
