import AppBar from "@mui/material/AppBar/AppBar";
import Toolbar from "@mui/material/Toolbar/Toolbar";
import Typography from "@mui/material/Typography/Typography";
import * as React from "react";
import update from "immutability-helper";
import Box from "@mui/material/Box/Box";
import { ApolloConsumer } from "@apollo/client/react/context/ApolloConsumer";
import IconButton from "@mui/material/IconButton/IconButton";
import Tooltip from "@mui/material/Tooltip/Tooltip";
import Download from "@mui/icons-material/CloudDownload";
import { format } from "date-fns";
import accounting from "accounting";
import ApolloVirtualizedTable, { ApolloVirtualizedTableProps, DefaultPageInfoValue, GridColumn } from "mui-apollo-virtualized-table";
import { PromotionSales, PromotionSalesVariables, PromotionSales_promotionQuery_promotion_sale_edges } from "./__generated__/PromotionSales";
import FileDownloader, { FileDownloaderHandle } from "../../components/FileDownloader";
import DrawerToggleButton from "../../app/DrawerToggleButton";
import CsSearchBox, { OperandType } from "../../components/CsSearchBox";
import { promotionSalesQuery } from "./graphql";
import { useParams } from "react-router-dom";

const PromotionSalesVirtualizedGrid = ApolloVirtualizedTable as React.ElementType<ApolloVirtualizedTableProps<PromotionSales_promotionQuery_promotion_sale_edges>>;

const _sx = {
    title: {
        marginRight: 1,
    },
    box: {
        backgroundColor: "rgba(255,255,255,.1)",
    },
    root: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        flexWrap: "nowrap",
    },
}

function PromotionSaleViewer() {
    const [columns, setColumns] = React.useState<
        ReadonlyArray<GridColumn<PromotionSales_promotionQuery_promotion_sale_edges>>
    >([
        {
            label: "Date",
            key: "date",
            width: 100,
            format: ({ rowData }: any) => format(new Date(rowData?.date), 'dd/MM/yyyy'),
            sortable: true,
        },
        {
            label: "#Order",
            key: "order_name",
            width: 75,
            sortable: true,
            hideAt:720
        },
        {
            label: "#Invoice",
            key: "move_name",
            width: 150,
            sortable: true,
            hideAt:680
        },

        {
            label: "Promotion",
            key: "name",
            width: 200,
            sortable: true,
            hideAt:1100
        },
        {
            label: "Account",
            key: "account_name",
            width: 200,
            sortable: true,
            hideAt:980
        },
        {
            label: "Customer",
            key: "partner",
            width: 150,
            sortable: true,
        },
        {
            label: "Description",
            key: "description",
            width: 200,
            flexGrow: 1,
            sortable: true
        },
        {
            label: "Qty",
            key: "quantity",
            labelAlign: "right",
            width: 75,
            textAlign: "right",
            sortable: true,
            format: ({ rowData }: any) => accounting.formatNumber(rowData.quantity ?? 0, 0),
        },
        {
            label: "Amount",
            key: "amount",
            labelAlign: "right",
            width: 120,
            textAlign: "right",
            sortable: true,
            format: ({ rowData }: any) => `${accounting.formatNumber(rowData.amount ?? 0, 0)}`,
        }
    ]);
    const [variables, setVariables] = React.useState<PromotionSalesVariables>({ pagination: { pageSize: 40 } });
    const handleColumnsPropsChanged = React.useCallback((columns: ReadonlyArray<GridColumn<PromotionSales_promotionQuery_promotion_sale_edges>>, orderBy: string[]) => {
        setColumns(columns);
        setVariables({ ...variables, orderBy: orderBy as any });
    }, [setVariables, setColumns, variables]);

    const fileDownloader = React.useRef<FileDownloaderHandle>(null);

    const handleExcelFileDownload = React.useCallback(() => {
        fileDownloader?.current?.download();
    }, [fileDownloader?.current?.download]);    

    return (
        <>
            <Box sx={_sx.root}>
                <AppBar position="static">
                    <Toolbar sx={(theme) => ({
                        [theme.breakpoints.up("md")]: {
                            minHeight: 6,
                        },
                        paddingLeft: 1.5,
                    })}>
                        <DrawerToggleButton />
                        <Typography
                            variant="h6"
                            sx={_sx.title}
                            color="inherit"
                            noWrap
                        >
                            Promotion Sales
                        </Typography>
                        <CsSearchBox
                            onConditionChanged={(conditions: any) => {
                                setVariables(
                                    update(variables, {
                                        where: {
                                            $set: { aND: conditions },
                                        },
                                    })
                                );
                            }}
                            popperSx={(theme) => ({
                                marginTop: 2,
                                [theme.breakpoints.up("md")]: {
                                    marginTop: 1,
                                },
                            })}
                            operands={{
                                name:{
                                    name:"Promotion",
                                    propName:"name",
                                    type:OperandType.STRING
                                },
                                order_name: {
                                    name: "#Order",
                                    propName: "order_name",
                                    type: OperandType.STRING,
                                },
                                move_name: {
                                    name: "#Invoice",
                                    propName: "move_name",
                                    type: OperandType.STRING,
                                },
                                date: {
                                    name: "Date",
                                    propName: "date",
                                    type: OperandType.DATE
                                },
                                partner: {
                                    name: "Customer",
                                    propName: "partner",
                                    type: OperandType.STRING
                                },
                                description: {
                                    name: "Description",
                                    propName: "description",
                                    type: OperandType.STRING
                                },
                                account_name: {
                                    name: "Account",
                                    propName: "account_name",
                                    type: OperandType.STRING
                                },
                                quantity: {
                                    name: "Qty",
                                    propName: "quantity",
                                    type: OperandType.NUMBER
                                },
                                amount: {
                                    name: "Amount",
                                    propName: "amount",
                                    type: OperandType.NUMBER
                                }
                            }}
                        />
                        <FileDownloader ref={fileDownloader} url="/excel/promotion_sales" json={JSON.stringify(variables?.where)}>
                            <Tooltip title="Download Excel">
                                <IconButton
                                    color="inherit"
                                    onClick={handleExcelFileDownload}
                                >
                                    <Download />
                                </IconButton>
                            </Tooltip>
                        </FileDownloader>
                    </Toolbar>
                </AppBar>
                <Box
                    sx={_sx.box}
                    flex={1}
                    display="flex"
                    flexDirection="row"
                >
                    <ApolloConsumer>
                        {(client) => (
                            <PromotionSalesVirtualizedGrid
                                onColumnPropsChanged={handleColumnsPropsChanged}
                                apolloClient={client as any}
                                listItemHeight={72}
                                listModeBreakPoint={0}
                                columns={columns}
                                graphqlQuery={promotionSalesQuery}
                                variables={variables}
                                pageSize={variables.pagination?.pageSize!}
                                parseListFromQueryResult={(queryResult: PromotionSales) => {
                                    const list = queryResult?.promotionQuery?.promotion_sale ?? {
                                        edges: [],
                                        pageInfo: DefaultPageInfoValue,
                                    };
                                    return list;
                                }}
                                onLoadMore={(pageInfo) => {
                                    return {
                                        ...variables,
                                        pagination: {
                                            page: pageInfo.page,
                                            pageSize: pageInfo.pageSize,
                                        },
                                    };
                                }}
                            />
                        )}
                    </ApolloConsumer>
                </Box>
            </Box>
        </>
    );
}

export default PromotionSaleViewer;