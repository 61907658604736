import { useQuery } from "@apollo/client";
import ListItemText from "@mui/material/ListItemText/ListItemText";
import TextField, { TextFieldProps } from "@mui/material/TextField/TextField";
import Autocomplete, {
  createFilterOptions,
} from '@mui/material/Autocomplete';
import * as React from "react";
import ListItem from "@mui/material/ListItem";
import { EcommerceCategories, EcommerceCategoriesVariables } from "./__generated__/EcommerceCategories";
import { EcommerceCategoryOrderBy, EcommerceCategoryWhereInput } from "../../types/global-types";
import { ecommerceCategoriesQuery } from "./graphql";

export type EcommerceCategoryOptionValue =  {
  display_name?: string | null;
  complete_name?:string | null;
  id: string;
}

export type EcommerceCategoryAutoCompleteProps = {
  selectedCategory?: EcommerceCategoryOptionValue | null;
  onSelected: (account: EcommerceCategoryOptionValue | null) => void;
  className?: string;
} & TextFieldProps;

const filterOptions = createFilterOptions({
  stringify: ({display_name, complete_name}: EcommerceCategoryOptionValue) =>
    display_name??complete_name??"",
});
function EcommerceCategoryAutoComplete({
  selectedCategory,
  onSelected,
  className,
  label,
  sx,
  size,  
}: EcommerceCategoryAutoCompleteProps) {
  const [search, setSearch] = React.useState("");
  const variables = React.useMemo(() => {
    const variables: EcommerceCategoriesVariables = { where:{} };
    const where: EcommerceCategoryWhereInput = {};
    if(search){
      where.complete_name_Contains = search;
    }
    if(Object.keys(where).length>0){
      variables.where = where;
    }
    variables.orderBy = [EcommerceCategoryOrderBy.complete_name];
    return variables;
  }, [search]);
  const { data, loading } = useQuery<EcommerceCategories, EcommerceCategoriesVariables>(
    ecommerceCategoriesQuery,
    {
      variables,
      skip: search === (selectedCategory?.display_name)
    }
  );
  return (
    <Autocomplete
      value={selectedCategory}
      onChange={(_, value: EcommerceCategoryOptionValue | null) => {
        if(value){
          const {id,display_name,complete_name} = value;
          onSelected({id, display_name: display_name??complete_name, complete_name: display_name??complete_name});
        }
        else 
          onSelected(value);
      }}
      filterOptions={filterOptions}
      includeInputInList
      clearOnBlur
      selectOnFocus
      loading={loading}
      isOptionEqualToValue={(option, value) => option.id.toString() === value.id.toString()}
      getOptionLabel={({display_name,complete_name}) => display_name??complete_name??""}
      renderOption={(props, option) => (
        <ListItem key={option?.id} {...props}><ListItemText primary={option?.display_name?? option?.complete_name}  /></ListItem>
      )}
      options={
        (selectedCategory ? [selectedCategory,...(data?.ecommerceQuery?.categories?.edges??[])] : [...(data?.ecommerceQuery?.categories?.edges??[])])
      }
      onInputChange={(_, value) => { setSearch(value); }}
      renderInput={(params) => (
        <TextField
          {...params}
          autoComplete="off"
          sx={sx}
          size={size}
          label={label}
          className={className}
        />
      )}
    />
  );
}

export default EcommerceCategoryAutoComplete;
