import Button from "@mui/material/Button/Button";
import TextField from "@mui/material/TextField/TextField";
import Typography from "@mui/material/Typography/Typography";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { loginAsync } from "../features/site/siteSlice";
import { AppDispatch, RootState } from "./store";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
function Login() {
    const [credential, setCredential] = React.useState({ userName: "", password: "" });
    const [errors, setErrors] = React.useState<{ [key: string]: string }>({});
    const { loginStatus, loginMessage } = useSelector((state: RootState) => state.site);
    const validate = React.useCallback(() => {
        var isValid = true;
        const newErrors: { [key: string]: string } = {};
        if (!credential.userName) {
            isValid = false;
            newErrors["userName"] = "User name is required";
        }
        setErrors(newErrors);
        return isValid;
    }, [credential, setErrors]);
    React.useEffect(() => { validate() }, [validate]);
    const dispatch: AppDispatch = useDispatch();
    const tryLogin = React.useCallback(() => {        
        dispatch(loginAsync(credential));
        const urlSearchParams = new URLSearchParams(window.location.search);
        const returnUrl = urlSearchParams.get("ReturnUrl");
        if (returnUrl) {
            window.location.href = decodeURI(returnUrl);
        }
    }, [dispatch, credential]);
    const handleKeyDown = React.useCallback( (e:React.KeyboardEvent<HTMLDivElement>)=>{
        if(e.key==="Enter"){
            tryLogin();
        }
    }, [tryLogin]);
    return (
    <Container maxWidth="sm">
        <Stack spacing={2} padding={2} paddingTop={3}>
            <Typography color="primary" variant="h3">Log In</Typography>
            <TextField onKeyDown={handleKeyDown} type="number" autoFocus label="Code" helperText={errors["userName"]} error={!!errors["userName"]} value={credential.userName} onChange={(e) => { setCredential({ ...credential, userName: e.target.value }) }} />
            <TextField onKeyDown={handleKeyDown} type="password" label="Password" helperText={errors["password"]} error={!!errors["password"]} value={credential.password} onChange={(e) => { setCredential({ ...credential, password: e.target.value }) }} />
            <Button fullWidth onClick={tryLogin} disabled={loginStatus === "loading"} variant="contained"><Typography flex={1} paddingTop={1} paddingBottom={1} >Login</Typography></Button>
            {loginStatus === "error" ? <Typography color="error">{loginMessage}</Typography> : null}
        </Stack>
    </Container>);
}

export default Login;
