import * as React from "react";
import ActivityIndicatorDialog from "./ActivityIndicatorDialog";
export type FileDownloaderProps = {
    url: string,
    json: string,
    children: React.ReactNode
};
export type FileDownloaderHandle = {
    download: () => void;
}
const FileDownloader: React.ForwardRefRenderFunction<FileDownloaderHandle, FileDownloaderProps> = ({ url, json, children }, ref) => {
    const [downloading, setDownloading] = React.useState(false);
    const handleExcelDownload = React.useCallback(() => {
        var xhr = new XMLHttpRequest();
        xhr.open('POST', url, true);
        xhr.responseType = 'blob';
        xhr.onload = function () {
            setDownloading(false);
            if (this.status === 200) {
                var blob = this.response;
                var filename = "";
                var disposition = xhr.getResponseHeader('Content-Disposition');
                if (disposition && disposition.indexOf('attachment') !== -1) {
                    var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                    var matches = filenameRegex.exec(disposition);
                    if (matches != null && matches[1]) filename = matches[1].replace(/['"]/g, '');
                }
                const navigator = window.navigator as any;
                if (typeof navigator.msSaveBlob !== 'undefined') {
                    // IE workaround for "HTML7007: One or more blob URLs were revoked by closing the blob for which they were created. These URLs will no longer resolve as the data backing the URL has been freed."
                    navigator.msSaveBlob(blob, filename);
                } else {
                    var URL = window.URL || window.webkitURL;
                    var downloadUrl = URL.createObjectURL(blob);

                    if (filename) {
                        // use HTML5 a[download] attribute to specify filename
                        var a = document.createElement("a");
                        // safari doesn't support this yet
                        if (typeof a.download === 'undefined') {
                            window.location.href = downloadUrl;
                        } else {
                            a.href = downloadUrl;
                            a.download = filename;
                            document.body.appendChild(a);
                            a.click();
                        }
                    } else {
                        window.location.href = downloadUrl;
                    }
                    setTimeout(function () { URL.revokeObjectURL(downloadUrl); }, 100); // cleanup
                }
            }
        };
        xhr.setRequestHeader('Content-type', 'application/json');
        setDownloading(true);
        xhr.send(json);
    }, [json, setDownloading, url]);

    React.useImperativeHandle(ref, () => ({ download: handleExcelDownload }), [handleExcelDownload]);
    return <>{children}<ActivityIndicatorDialog open={downloading} message="Downloading..." /></>
};

export default React.forwardRef(FileDownloader);