import * as React from "react";
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import ToolbarPlugin from "../../../lexical/plugins/ToolbarPlugin";
import PlaygroundNodes from "../../../lexical/nodes/PlaygroundNodes";
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { CheckListPlugin } from '@lexical/react/LexicalCheckListPlugin';
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { HorizontalRulePlugin } from "../../../lexical/plugins/LexicalHorizontalRulePlugin";
import ClickableLinkPlugin from "../../../lexical/plugins/ClickableLinkPlugin";
import { AutoLinkPlugin } from "@lexical/react/LexicalAutoLinkPlugin";
import FloatingLinkEditorPlugin from "../../../lexical/plugins/FloatingLinkEditorPlugin";
import FloatingTextFormatToolbarPlugin from "../../../lexical/plugins/FloatingTextFormatToolbarPlugin";
import PlaygroundEditorTheme from '../../../lexical/themes/PlaygroundEditorTheme';
import RefPlugin from "../../../lexical/plugins/RefPlugin";
import { EditorState, LexicalEditor } from "lexical";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import useAppContext from "../../../app/AppContextProvider";

export type OverviewEditor={
    editorState?:EditorState;
    open:boolean;
    onSubmit:(state?:EditorState)=>void;
    onCancel:()=>void;
    namespace:string;
}
function OverviewEditor({editorState,open,onSubmit,onCancel,namespace}:OverviewEditor) {
    const overviewEditor = React.useRef<LexicalEditor>();
    const { showError } = useAppContext();
    const handleSubmit = React.useCallback(()=>{
        const state  = overviewEditor.current?.getEditorState();
        onSubmit(state);
    },[onSubmit]);
    React.useEffect(()=>{
        if(editorState)
            overviewEditor?.current?.setEditorState(editorState);
    },[editorState]);
    if(!editorState)
        return null;
    return (<Dialog maxWidth="md" fullWidth open={open} onAbort={onCancel}>
        <DialogContent>
        <LexicalComposer initialConfig={{
        namespace,
        editorState,
        nodes: [...PlaygroundNodes],
        onError: (error) => {
            showError("Over view editor error.", error);
        },
        theme: PlaygroundEditorTheme,
    }}>
        <div className="lexical"><ToolbarPlugin /></div>
        <RichTextPlugin contentEditable={<Box borderRadius={1} border="1px solid" borderColor="divider" sx={{ paddingLeft: 1, paddingRight: 1, paddingTop: 2, paddingBottom: 2, cursor: "text", outline: 0, resize: "vertical", "& .editor": { outline: 0, minHeight: 150 } }} overflow="auto" display="block"  >
            <ContentEditable className="editor" />
        </Box>}
            placeholder={null}
            ErrorBoundary={LexicalErrorBoundary} />
        <ListPlugin />
        <CheckListPlugin />
        <LinkPlugin />
        <ClickableLinkPlugin />
        <AutoLinkPlugin matchers={[]} />
        <HorizontalRulePlugin />
        <FloatingLinkEditorPlugin />
        <FloatingTextFormatToolbarPlugin />
        <RefPlugin editorRef={overviewEditor} />
    </LexicalComposer>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleSubmit}>OK</Button>
            <Button onClick={onCancel}>Cancel</Button>
        </DialogActions>
    </Dialog>);
}

export default OverviewEditor;