import { useQuery } from "@apollo/client";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import * as React from "react";
import { ecommerceCategoriesQuery } from "./graphql";
import { EcommerceCategories, EcommerceCategoriesVariables, EcommerceCategories_ecommerceQuery_categories_edges } from "./__generated__/EcommerceCategories";
export type EcommerceCategoryMultiSelectorProps = {
    values: EcommerceCategories_ecommerceQuery_categories_edges[];
    defaultCategory:string|undefined;
    onSelected: (values: EcommerceCategories_ecommerceQuery_categories_edges[],default_ecommerce_categ_id:string) => void;
    className?: string;
} & Pick<TextFieldProps, "size" | "label" | "sx" | "fullWidth">;;
function EcommerceCategoryMultiSelector({values,onSelected, defaultCategory,className,size,sx,label,fullWidth}:EcommerceCategoryMultiSelectorProps) {
    const [search, setSearch] = React.useState("");
    const { data, loading } = useQuery<EcommerceCategories, EcommerceCategoriesVariables>(ecommerceCategoriesQuery, {
        variables: {
            where: {
                complete_name_Contains: search
            }
        }
    });
    return <Autocomplete
        multiple
        fullWidth={fullWidth}
        options={data?.ecommerceQuery?.categories?.edges??[]}
        getOptionLabel={(option) => option.complete_name!}
        value={values}
        onChange={(e,values)=>{
            onSelected(values,defaultCategory!);
        }}
        isOptionEqualToValue={(option,value)=>option.id=== value.id}
        onInputChange={(_, value) => { setSearch(value); }}
        renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Chip
                size="small"
                label={option.complete_name}
                color={option.id === defaultCategory? "primary" : undefined}
                onClick={()=>{onSelected(tagValue,option.id)}}
                {...getTagProps({ index })}
              />
            ))
          }
        renderInput={(params) => (
            <TextField
                {...params}
                size={size}
                sx={sx}
                autoComplete="off"
                label={label}
                className={className}
            />
        )}
    />;
}

export default EcommerceCategoryMultiSelector;