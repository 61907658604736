import Button from "@mui/material/Button/Button";
import Box from "@mui/material/Box";
import { format } from "date-fns";
import { Grid, Typography } from "@mui/material";
import { StockPicking_inventoryQuery_picking } from "../../sale/__generated__/StockPicking";

type StockPickingHeaderProps = {
  picking: StockPicking_inventoryQuery_picking,
  disableUniverse: boolean;
  disableCheckAvailability: boolean;
  disabledProcess: boolean;
  handleUnreserve: () => void;
  handleCheckAvailability: () => void;
  handleValidate: () => void;
};
const _sx = {
  root: {
    paddingTop: 1,
    paddingBottom: 1,
    paddingLeft: 2,
    paddingRight: 2,
    backgroundColor: "rgba(0,0,0,0.1)",
  },
  fieldLabel: {
    fontWeight: "bold",
    marginRight: 1,
    width: 150,
    display: "inline-block",
    color: "inherit"
  },
  field: {
    padding: .5,
  },
};
function StockPickingHeader({
  picking,
  disableUniverse,
  disableCheckAvailability,
  disabledProcess,
  handleUnreserve,
  handleCheckAvailability,
  handleValidate,
}: StockPickingHeaderProps) {
  return (
    <Box sx={_sx.root}>
      <Box>
        <Button
          size="small"
          onClick={handleUnreserve}
          variant="contained"
          disabled={disableUniverse}
        >
          Unreserve
        </Button>
        <Button
          size="small"
          disabled={disableCheckAvailability}
          onClick={handleCheckAvailability}
          variant="contained"
        >
          Check Availability
        </Button>
        <Button
          size="small"
          onClick={handleValidate}
          variant="contained"
          disabled={disabledProcess}
        >
          Procress
        </Button>
      </Box>
      <Grid container>
        <Grid item xs={12} md={4}>
          <Box sx={_sx.field}>
            <Typography sx={_sx.fieldLabel}>From:</Typography>
            <Typography>{picking?.location_id?.display_name}</Typography>
          </Box>
          <Box sx={_sx.field}>
            <Typography sx={_sx.fieldLabel}>To:</Typography>
            <Typography>{picking?.location_dest_id?.display_name}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box sx={_sx.field}>
            <Typography sx={_sx.fieldLabel}>Contact:</Typography>
            <Typography>{picking?.name.includes("/INT/") ? (`${picking?.location_dest_id?.display_name}${(picking?.partner_id?.display_name ? ` - ${picking?.partner_id?.display_name}` : "")}`) : picking?.partner_id?.display_name}</Typography>
          </Box>
          <Box sx={_sx.field}>
            <Typography sx={_sx.fieldLabel}>Date:</Typography>
            <Typography>{picking?.date_done ? format(new Date(picking?.date_done), "dd/MM/yyyy") : ""}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>          
          <Box sx={_sx.field}>
            <Typography sx={_sx.fieldLabel}>Source Document:</Typography>
            <Typography>{picking?.origin}</Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default StockPickingHeader;
