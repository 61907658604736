import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import * as React from "react";
import ProductAutoComplete from "../../common/ProductAutoComplete";
import { ProductItems_productQuery_productItems_edges } from "../../common/__generated__/ProductItems";

export type CreateProductDialogProps = {
    open: boolean;
    onAccept: (id: number) => void;
    onCancel: () => void;
};
function CreateProductDialog({ open, onAccept, onCancel }: CreateProductDialogProps) {
    const [selected, setSelected] = React.useState<ProductItems_productQuery_productItems_edges | undefined>();
    const handleOk = React.useCallback(() => {
        if (selected)
            onAccept(selected.id!);
    }, [onAccept, selected]);
    return <Dialog open={open} onAbort={onCancel} fullWidth maxWidth="sm">
        <DialogTitle>New Product</DialogTitle>
        <DialogContent>
            <ProductAutoComplete sx={{marginTop:1}} size="small" fullWidth label="Search Product" value={selected} onSelected={setSelected} />
        </DialogContent>
        <DialogActions>
            <Button title="OK" onClick={handleOk} >OK</Button>
            <Button title="Cancel" onClick={onCancel} >Cancel</Button>
        </DialogActions>
    </Dialog>
}
export default CreateProductDialog;