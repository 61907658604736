import Checkbox from "@mui/material/Checkbox/Checkbox";
import Divider from "@mui/material/Divider/Divider";
import FormControl from "@mui/material/FormControl/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel/FormControlLabel";
import InputLabel from "@mui/material/InputLabel/InputLabel";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import Select from "@mui/material/Select/Select";
import TextField from "@mui/material/TextField/TextField";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { DeepReadonly } from "utility-types";
import Box from "@mui/material/Box/Box";
import Grid from "@mui/material/Grid/Grid";
import Button from "@mui/material/Button/Button";
import { setLabelPrinterUrl } from "../site/siteSlice";
import { RootState } from "../../app/store";
import TextEditor from "../../components/TextEditor";
import { useShowSnackbar } from "../../app/SnackbarContext";


const _sx = {
    content: {
        marginTop: 2,
        paddingBottom: 2,
        backgroundColor: "background.default"
    },
    xyhw: {
        width: 100,
        marginLeft: 1,
        marginRight: 1
    },
    row: {
        flexDirection: "row",
        flexWrap: "wrap",
        display: "flex",
        marginTop: 1,
        marginBottom: 1
    },
    checkbox: {
        marginLeft: 1,
        marginRight: 1,
        width: 200
    },
    text: {
        width: 200 + 2,
        marginLeft: 1,
        marginRight: 1
    },
};

export type LabelPrintSettingType = DeepReadonly<{
    PrintPIDCode?: boolean;
    PrintPIDCode_X?: number;
    PrintPIDCode_Y?: number;
    PrintPIDCode_W?: number;
    PrintPIDCode_H?: number;
    PrintPIDText?: boolean;
    PIDText_X?: number;
    PIDText_Y?: number;
    PIDText_H?: number;

    PrintStockCodeCode?: boolean;
    StockCode_X?: number;
    StockCode_Y?: number;
    StockCode_W?: number;
    StockCode_H?: number;
    PrintStockCodeText?: boolean;
    StockCodeText_X?: number;
    StockCodeText_Y?: number;
    StockCodeText_H?: number;

    PrintSerialNoCode?: boolean;
    SerialNoCode_X?: number;
    SerialNoCode_Y?: number;
    SerialNoCode_W?: number;
    SerialNoCode_H?: number;
    PrintSerialNoText?: boolean;
    SerialNoText_X?: number;
    SerialNoText_Y?: number;
    SerialNoText_H?: number;

    PrintStockNameText?: boolean;
    StockNameText_X?: number;
    StockNameText_Y?: number;
    StockNameText_H?: number;

    PrintPurchaseDateText?: boolean;
    PurchaseDateText_X?: number;
    PurchaseDateText_Y?: number;
    PurchaseDateText_H?: number;

    PrintVoucherNoText?: boolean;
    VoucherNoText_X?: number;
    VoucherNoText_Y?: number;
    VoucherNoText_H?: number;

    PrintSalePrice?: boolean;
    SalePrice_X?: number;
    SalePrice_Y?: number;
    SalePrice_H?: number;
    PriceBook?: string;

    PrinterType?: string;
    PrinterName?: string;
    PrintSpeed?: number;
    Darkness?: number;
    CustomZPLCommand?: string;
    EnableZPLCommand?: boolean;

    LabelHeight?: number;
    LabelWidth?: number;
    LabelGapH?: number;
    NoOfColumn?: number;
    ColumnWidth?: number;
    LabelGapV?: number;
}>;

export const defaultLabelSetting: LabelPrintSettingType = {
    PrintPIDCode: false,
    PrintPIDCode_X: 0,
    PrintPIDCode_Y: 0,
    PrintPIDCode_W: 0,
    PrintPIDCode_H: 0,
    PrintPIDText: false,
    PIDText_X: 0,
    PIDText_Y: 0,
    PIDText_H: 0,

    PrintStockCodeCode: false,
    StockCode_X: 0,
    StockCode_Y: 0,
    StockCode_W: 0,
    StockCode_H: 0,
    PrintStockCodeText: false,
    StockCodeText_X: 0,
    StockCodeText_Y: 0,
    StockCodeText_H: 0,

    PrintSerialNoCode: false,
    SerialNoCode_X: 0,
    SerialNoCode_Y: 0,
    SerialNoCode_W: 0,
    SerialNoCode_H: 0,
    PrintSerialNoText: false,
    SerialNoText_X: 0,
    SerialNoText_Y: 0,
    SerialNoText_H: 0,

    PrintStockNameText: false,
    StockNameText_X: 0,
    StockNameText_Y: 0,
    StockNameText_H: 0,

    PrintPurchaseDateText: false,
    PurchaseDateText_X: 0,
    PurchaseDateText_Y: 0,
    PurchaseDateText_H: 0,

    PrintVoucherNoText: false,
    VoucherNoText_X: 0,
    VoucherNoText_Y: 0,
    VoucherNoText_H: 0,

    PrintSalePrice: false,
    SalePrice_X: 0,
    SalePrice_Y: 0,
    SalePrice_H: 0,
    PriceBook: "",

    PrinterType: "",
    PrinterName: "",
    PrintSpeed: 0,
    Darkness: 0,
    CustomZPLCommand: "",
    EnableZPLCommand: false,

    LabelHeight: 0,
    LabelWidth: 0,
    LabelGapH: 0,
    NoOfColumn: 0,
    ColumnWidth: 0,
    LabelGapV: 0
};

export type LabelPrintSettingProps = {
    templateName?: string
}

function LabelPrintSetting({ templateName }: LabelPrintSettingProps) {
    const labelTemplate = templateName ?? "";
    const [labelPrintSetting, setLabelPrintSetting] = React.useState(defaultLabelSetting);
    const setPrintSetting = React.useCallback((setting: Partial<LabelPrintSettingType>) => {
        setLabelPrintSetting({ ...labelPrintSetting, ...setting });
    }, [labelPrintSetting, setLabelPrintSetting]);
    const dispatch = useDispatch();
    const { showSnackbar } = useShowSnackbar();

    const printServiceUrl = useSelector((state: RootState) => state.site.labelPrinterUrl);
    const editLabelPrintSericeUrl = React.useCallback((url: string) => {
        dispatch(setLabelPrinterUrl(url));
    }, [dispatch]);
    const loadLabelPrintSetting = React.useCallback(() => {
        fetch(`${printServiceUrl}/api/productserialnolabel/getlabel/${labelTemplate}`, { referrerPolicy: "no-referrer-when-downgrade" })
            .then(response => {
                if (response.status === 200) {
                    response.json().then(setting => {
                        setPrintSetting(setting);
                    });
                } else {
                    showSnackbar({ message: response.statusText });
                }
            })
            .catch(error => {
                showSnackbar({ message: error.message });
            });

    }, [printServiceUrl, labelTemplate, setPrintSetting, showSnackbar]);

    const saveLabelPrintSetting = React.useCallback(() => {
        fetch(`${printServiceUrl}/api/productserialnolabel/savelabelformat/${labelTemplate}`, {
            method: "POST",
            cache: "no-cache",
            headers: {
                "Content-Type": "application/json"
            },
            referrerPolicy: "no-referrer-when-downgrade",
            body: JSON.stringify(labelPrintSetting)
        })
            .then(response => {
                if (response.status === 204)
                    showSnackbar({ message: "Template saved" });
                else
                    showSnackbar({ message: response.statusText });
            })
            .catch(error => {
                showSnackbar({ message: error.message });
            });
    }, [printServiceUrl, labelTemplate, showSnackbar, labelPrintSetting]);
    const [isPriceLabelPrintServiceTesting, setIsPriceLabelPrintServiceTesting] = React.useState(false);
    const testPriceLabelPrintService = React.useCallback(() => {
        if (isPriceLabelPrintServiceTesting)
            return;
        setIsPriceLabelPrintServiceTesting(true);
        fetch(`${printServiceUrl}/api/test/ping`,{referrerPolicy: "no-referrer-when-downgrade"})
            .then(response => {
                if (response.status === 200) {
                    setIsPriceLabelPrintServiceTesting(false);
                    showSnackbar({ message: "Connection success!" });
                } else {
                    setIsPriceLabelPrintServiceTesting(false);
                    showSnackbar(
                        { message: `Testing connection to print service failed! ${response.statusText}` }
                    );
                }
            })
            .catch(error => {
                showSnackbar({ message: `Testing connection to print service failed! ${error}` });
            });
    }, [printServiceUrl, setIsPriceLabelPrintServiceTesting, isPriceLabelPrintServiceTesting, showSnackbar]);


    React.useEffect(() => {
        loadLabelPrintSetting();
    }, [loadLabelPrintSetting]);

    return (
        <Box
            flexDirection="column"
            boxShadow={1}
            p={2}
            display="flex"
            flexWrap="wrap"
            sx={_sx.content}
        >
            <Grid container>
                <Grid item xs={10}><TextEditor size="small" fullWidth value={printServiceUrl} onValidated={editLabelPrintSericeUrl} placeholder="Print service url" label="Print service url" /></Grid>
                <Grid item xs={2}><Button color="primary" onClick={testPriceLabelPrintService}>Test</Button><Button color="primary" onClick={saveLabelPrintSetting}>Save</Button></Grid>
            </Grid>
            <Box sx={_sx.row}>
                <FormControlLabel
                    sx={_sx.checkbox}
                    control={
                        <Checkbox
                            color="primary"
                            checked={labelPrintSetting.PrintPIDCode}
                            onChange={(e, checked) => {
                                setPrintSetting({ PrintPIDCode: checked });
                            }}
                        />
                    }
                    label="Print PID Barcode"
                />
                <TextField
                    size="small"
                    label="X"
                    type="Number"
                    value={labelPrintSetting.PrintPIDCode_X}
                    sx={_sx.xyhw}
                    onChange={e => {
                        setPrintSetting({
                            PrintPIDCode_X: Number.parseInt(e.target.value, 10)
                        });
                    }}
                />
                <TextField
                    size="small"
                    label="Y"
                    type="Number"
                    value={labelPrintSetting.PrintPIDCode_Y}
                    sx={_sx.xyhw}
                    onChange={e => {
                        setPrintSetting({
                            PrintPIDCode_Y: Number.parseInt(e.target.value, 10)
                        });
                    }}
                />
                <TextField
                    size="small"
                    label="W"
                    type="Number"
                    value={labelPrintSetting.PrintPIDCode_W}
                    sx={_sx.xyhw}
                    onChange={e => {
                        setPrintSetting({
                            PrintPIDCode_W: Number.parseInt(e.target.value, 10)
                        });
                    }}
                />
                <TextField
                    size="small"
                    label="H"
                    type="Number"
                    value={labelPrintSetting.PrintPIDCode_H}
                    sx={_sx.xyhw}
                    onChange={e => {
                        setPrintSetting({
                            PrintPIDCode_H: Number.parseInt(e.target.value, 10)
                        });
                    }}
                />
            </Box>
            <Divider />
            <Box sx={_sx.row}>
                <FormControlLabel
                    sx={_sx.checkbox}
                    control={
                        <Checkbox
                            color="primary"
                            checked={labelPrintSetting.PrintPIDText}
                            onChange={(e, checked) => {
                                setPrintSetting({ PrintPIDText: checked });
                            }}
                        />
                    }
                    label="Print PID Text"
                />
                <TextField
                    size="small"
                    label="X"
                    type="Number"
                    value={labelPrintSetting.PIDText_X}
                    onChange={e => {
                        setPrintSetting({
                            PIDText_X: Number.parseInt(e.target.value, 10)
                        });
                    }}
                    sx={_sx.xyhw}
                />
                <TextField
                    size="small"
                    label="Y"
                    type="Number"
                    value={labelPrintSetting.PIDText_Y}
                    onChange={e => {
                        setPrintSetting({
                            PIDText_Y: Number.parseInt(e.target.value, 10)
                        });
                    }}
                    sx={_sx.xyhw}
                />
                <TextField
                    size="small"
                    label="H"
                    type="Number"
                    value={labelPrintSetting.PIDText_H}
                    onChange={e => {
                        setPrintSetting({
                            PIDText_H: Number.parseInt(e.target.value, 10)
                        });
                    }}
                    sx={_sx.xyhw}
                />
            </Box>
            <Divider />

            <Box sx={_sx.row}>
                <FormControlLabel
                    sx={_sx.checkbox}
                    control={
                        <Checkbox
                            color="primary"
                            checked={labelPrintSetting.PrintStockCodeCode}
                            onChange={(e, checked) => {
                                setPrintSetting({ PrintStockCodeCode: checked });
                            }}
                        />
                    }
                    label="Print Stock Code"
                />
                <TextField
                    size="small"
                    label="X"
                    type="Number"
                    value={labelPrintSetting.StockCode_X}
                    sx={_sx.xyhw}
                    onChange={e => {
                        setPrintSetting({
                            StockCode_X: Number.parseInt(e.target.value, 10)
                        });
                    }}
                />
                <TextField
                    size="small"
                    label="Y"
                    type="Number"
                    value={labelPrintSetting.StockCode_Y}
                    sx={_sx.xyhw}
                    onChange={e => {
                        setPrintSetting({
                            StockCode_Y: Number.parseInt(e.target.value, 10)
                        });
                    }}
                />
                <TextField
                    size="small"
                    label="W"
                    type="Number"
                    value={labelPrintSetting.StockCode_W}
                    sx={_sx.xyhw}
                    onChange={e => {
                        setPrintSetting({
                            StockCode_W: Number.parseInt(e.target.value, 10)
                        });
                    }}
                />
                <TextField
                    size="small"
                    label="H"
                    type="Number"
                    value={labelPrintSetting.StockCode_H}
                    sx={_sx.xyhw}
                    onChange={e => {
                        setPrintSetting({
                            StockCode_H: Number.parseInt(e.target.value, 10)
                        });
                    }}
                />
            </Box>
            <Divider />
            <Box sx={_sx.row}>
                <FormControlLabel
                    sx={_sx.checkbox}
                    control={
                        <Checkbox
                            color="primary"
                            checked={labelPrintSetting.PrintStockCodeText}
                            onChange={(e, checked) => {
                                setPrintSetting({ PrintStockCodeText: checked });
                            }}
                        />
                    }
                    label="Print Stock Code Text"
                />
                <TextField
                    label="X"
                    size="small"
                    type="Number"
                    value={labelPrintSetting.StockCodeText_X}
                    onChange={e => {
                        setPrintSetting({
                            StockCodeText_X: Number.parseInt(e.target.value, 10)
                        });
                    }}
                    sx={_sx.xyhw}
                />
                <TextField
                    size="small"
                    label="Y"
                    type="Number"
                    value={labelPrintSetting.StockCodeText_Y}
                    onChange={e => {
                        setPrintSetting({
                            StockCodeText_Y: Number.parseInt(e.target.value, 10)
                        });
                    }}
                    sx={_sx.xyhw}
                />
                <TextField
                    size="small"
                    label="H"
                    type="Number"
                    value={labelPrintSetting.StockCodeText_H}
                    onChange={e => {
                        setPrintSetting({
                            StockCodeText_H: Number.parseInt(e.target.value, 10)
                        });
                    }}
                    sx={_sx.xyhw}
                />
            </Box>
            <Divider />

            <Box sx={_sx.row}>
                <FormControlLabel
                    sx={_sx.checkbox}
                    control={
                        <Checkbox
                            color="primary"
                            checked={labelPrintSetting.PrintSerialNoCode}
                            onChange={(e, checked) => {
                                setPrintSetting({ PrintSerialNoCode: checked });
                            }}
                        />
                    }
                    label="Print Serial No Code"
                />
                <TextField
                    size="small"
                    label="X"
                    type="Number"
                    value={labelPrintSetting.SerialNoCode_X}
                    sx={_sx.xyhw}
                    onChange={e => {
                        setPrintSetting({
                            SerialNoCode_X: Number.parseInt(e.target.value, 10)
                        });
                    }}
                />
                <TextField
                    size="small"
                    label="Y"
                    type="Number"
                    value={labelPrintSetting.SerialNoCode_Y}
                    sx={_sx.xyhw}
                    onChange={e => {
                        setPrintSetting({
                            SerialNoCode_Y: Number.parseInt(e.target.value, 10)
                        });
                    }}
                />
                <TextField
                    size="small"
                    label="W"
                    type="Number"
                    value={labelPrintSetting.SerialNoCode_W}
                    sx={_sx.xyhw}
                    onChange={e => {
                        setPrintSetting({
                            SerialNoCode_W: Number.parseInt(e.target.value, 10)
                        });
                    }}
                />
                <TextField
                    size="small"
                    label="H"
                    type="Number"
                    value={labelPrintSetting.SerialNoCode_H}
                    sx={_sx.xyhw}
                    onChange={e => {
                        setPrintSetting({
                            SerialNoCode_H: Number.parseInt(e.target.value, 10)
                        });
                    }}
                />
            </Box>
            <Divider />
            <Box sx={_sx.row}>
                <FormControlLabel
                    sx={_sx.checkbox}
                    control={
                        <Checkbox
                            color="primary"
                            checked={labelPrintSetting.PrintSerialNoText}
                            onChange={(e, checked) => {
                                setPrintSetting({ PrintSerialNoText: checked });
                            }}
                        />
                    }
                    label="Print Serial No"
                />
                <TextField
                    size="small"
                    label="X"
                    type="Number"
                    value={labelPrintSetting.SerialNoText_X}
                    onChange={e => {
                        setPrintSetting({
                            SerialNoText_X: Number.parseInt(e.target.value, 10)
                        });
                    }}
                    sx={_sx.xyhw}
                />
                <TextField
                    size="small"
                    label="Y"
                    type="Number"
                    value={labelPrintSetting.SerialNoText_Y}
                    onChange={e => {
                        setPrintSetting({
                            SerialNoText_Y: Number.parseInt(e.target.value, 10)
                        });
                    }}
                    sx={_sx.xyhw}
                />
                <TextField
                    size="small"
                    label="H"
                    type="Number"
                    value={labelPrintSetting.SerialNoText_H}
                    onChange={e => {
                        setPrintSetting({
                            SerialNoText_H: Number.parseInt(e.target.value, 10)
                        });
                    }}
                    sx={_sx.xyhw}
                />
            </Box>
            <Divider />

            <Box sx={_sx.row}>
                <FormControlLabel
                    sx={_sx.checkbox}
                    control={
                        <Checkbox
                            color="primary"
                            checked={labelPrintSetting.PrintStockNameText}
                            onChange={(e, checked) => {
                                setPrintSetting({ PrintStockNameText: checked });
                            }}
                        />
                    }
                    label="Print Stock Name"
                />
                <TextField
                    size="small"
                    label="X"
                    type="Number"
                    value={labelPrintSetting.StockNameText_X}
                    onChange={e => {
                        setPrintSetting({
                            StockNameText_X: Number.parseInt(e.target.value, 10)
                        });
                    }}
                    sx={_sx.xyhw}
                />
                <TextField
                    size="small"
                    label="Y"
                    type="Number"
                    value={labelPrintSetting.StockNameText_Y}
                    onChange={e => {
                        setPrintSetting({
                            StockNameText_Y: Number.parseInt(e.target.value, 10)
                        });
                    }}
                    sx={_sx.xyhw}
                />
                <TextField
                    size="small"
                    label="H"
                    type="Number"
                    value={labelPrintSetting.StockNameText_H}
                    onChange={e => {
                        setPrintSetting({
                            StockNameText_H: Number.parseInt(e.target.value, 10)
                        });
                    }}
                    sx={_sx.xyhw}
                />
            </Box>
            <Divider />

            <Box sx={_sx.row}>
                <FormControlLabel
                    sx={_sx.checkbox}
                    control={
                        <Checkbox
                            color="primary"
                            checked={labelPrintSetting.PrintPurchaseDateText}
                            onChange={(e, checked) => {
                                setPrintSetting({ PrintPurchaseDateText: checked });
                            }}
                        />
                    }
                    label="Print Purchase Date"
                />
                <TextField
                    size="small"
                    label="X"
                    type="Number"
                    value={labelPrintSetting.PurchaseDateText_X}
                    onChange={e => {
                        setPrintSetting({
                            PurchaseDateText_X: Number.parseInt(e.target.value, 10)
                        });
                    }}
                    sx={_sx.xyhw}
                />
                <TextField
                    size="small"
                    label="Y"
                    type="Number"
                    value={labelPrintSetting.PurchaseDateText_Y}
                    onChange={e => {
                        setPrintSetting({
                            PurchaseDateText_Y: Number.parseInt(e.target.value, 10)
                        });
                    }}
                    sx={_sx.xyhw}
                />
                <TextField
                    size="small"
                    label="H"
                    type="Number"
                    value={labelPrintSetting.PurchaseDateText_H}
                    onChange={e => {
                        setPrintSetting({
                            PurchaseDateText_H: Number.parseInt(e.target.value, 10)
                        });
                    }}
                    sx={_sx.xyhw}
                />
            </Box>
            <Divider />

            <Box sx={_sx.row}>
                <FormControlLabel
                    sx={_sx.checkbox}
                    control={
                        <Checkbox
                            color="primary"
                            checked={labelPrintSetting.PrintVoucherNoText}
                            onChange={(e, checked) => {
                                setPrintSetting({ PrintVoucherNoText: checked });
                            }}
                        />
                    }
                    label="Print Voucher No"
                />
                <TextField
                    size="small"
                    label="X"
                    type="Number"
                    value={labelPrintSetting.VoucherNoText_X}
                    onChange={e => {
                        setPrintSetting({
                            VoucherNoText_X: Number.parseInt(e.target.value, 10)
                        });
                    }}
                    sx={_sx.xyhw}
                />
                <TextField
                    size="small"
                    label="Y"
                    type="Number"
                    value={labelPrintSetting.VoucherNoText_Y}
                    onChange={e => {
                        setPrintSetting({
                            VoucherNoText_Y: Number.parseInt(e.target.value, 10)
                        });
                    }}
                    sx={_sx.xyhw}
                />
                <TextField
                    size="small"
                    label="H"
                    type="Number"
                    value={labelPrintSetting.VoucherNoText_H}
                    onChange={e => {
                        setPrintSetting({
                            VoucherNoText_H: Number.parseInt(e.target.value, 10)
                        });
                    }}
                    sx={_sx.xyhw}
                />
            </Box>
            <Divider />

            <Box sx={_sx.row}>
                <FormControlLabel
                    sx={_sx.checkbox}
                    control={
                        <Checkbox
                            color="primary"
                            checked={labelPrintSetting.PrintSalePrice}
                            onChange={(e, checked) => {
                                setPrintSetting({ PrintSalePrice: checked });
                            }}
                        />
                    }
                    label="Print Sale Price"
                />
                <TextField
                    size="small"
                    label="X"
                    type="Number"
                    value={labelPrintSetting.SalePrice_X}
                    onChange={e => {
                        setPrintSetting({
                            SalePrice_X: Number.parseInt(e.target.value, 10)
                        });
                    }}
                    sx={_sx.xyhw}
                />
                <TextField
                    size="small"
                    label="Y"
                    type="Number"
                    value={labelPrintSetting.SalePrice_Y}
                    onChange={e => {
                        setPrintSetting({
                            SalePrice_Y: Number.parseInt(e.target.value, 10)
                        });
                    }}
                    sx={_sx.xyhw}
                />
                <TextField
                    size="small"
                    label="H"
                    type="Number"
                    value={labelPrintSetting.SalePrice_H}
                    onChange={e => {
                        setPrintSetting({
                            SalePrice_H: Number.parseInt(e.target.value, 10)
                        });
                    }}
                    sx={_sx.xyhw}
                />
                <TextField
                    size="small"
                    label="Price List"
                    value={labelPrintSetting.PriceBook}
                    onChange={e => {
                        setPrintSetting({
                            PriceBook: e.target.value
                        });
                    }}
                    sx={_sx.xyhw}
                />
            </Box>
            <Divider />

            <Box sx={_sx.row}>
                <FormControl size="small" sx={_sx.checkbox}>
                    <InputLabel htmlFor="printer_type">Printer Type</InputLabel>
                    <Select
                        inputProps={{ name: "printer_type" }}
                        value={labelPrintSetting.PrinterType}
                        onChange={e => {
                            setPrintSetting({ PrinterType: e.target.value as any });
                        }}
                    >
                        <MenuItem value="0">POSTEK</MenuItem>
                        <MenuItem value="1">GODEX</MenuItem>
                        <MenuItem value="2">TSC</MenuItem>
                        <MenuItem value="3">PosteKTXR</MenuItem>
                        <MenuItem value="4">ZEBRA</MenuItem>
                    </Select>
                </FormControl>
                <TextField
                    size="small"
                    label="Printer Name"
                    value={labelPrintSetting.PrinterName}
                    onChange={e => {
                        setPrintSetting({
                            PrinterName: e.target.value
                        });
                    }}
                    sx={_sx.text}
                />
                <TextField
                    size="small"
                    label="Print Speed"
                    value={labelPrintSetting.PrintSpeed}
                    type="Number"
                    onChange={e => {
                        setPrintSetting({
                            PrintSpeed: Number.parseInt(e.target.value, 10)
                        });
                    }}
                    sx={_sx.xyhw}
                />

                <TextField
                    size="small"
                    label="Darkness"
                    value={labelPrintSetting.Darkness}
                    type="Number"
                    onChange={e => {
                        setPrintSetting({
                            Darkness: Number.parseInt(e.target.value, 10)
                        });
                    }}
                    sx={_sx.xyhw}
                />
            </Box>
            <Divider />
            <Box sx={_sx.row}>
                <FormControlLabel
                    sx={_sx.checkbox}
                    control={
                        <Checkbox
                            color="primary"
                            checked={labelPrintSetting.EnableZPLCommand}
                            onChange={(e, checked) => {
                                setPrintSetting({ EnableZPLCommand: checked });
                            }}
                        />
                    }
                    label="Enable ZPL Command"
                />
                <TextField
                    size="small"
                    label="Custom ZPL Command"
                    value={labelPrintSetting.CustomZPLCommand}
                    onChange={e => {
                        setPrintSetting({
                            CustomZPLCommand: e.target.value
                        });
                    }}
                    sx={_sx.text}
                />
            </Box>
            <Divider />
            <Box sx={_sx.row}>
                <TextField
                    size="small"
                    label="Width"
                    value={labelPrintSetting.LabelWidth}
                    type="Number"
                    onChange={e => {
                        setPrintSetting({
                            LabelWidth: Number.parseInt(e.target.value, 10)
                        });
                    }}
                    sx={_sx.xyhw}
                />
                <TextField
                    size="small"
                    label="Height"
                    value={labelPrintSetting.LabelHeight}
                    type="Number"
                    onChange={e => {
                        setPrintSetting({
                            LabelHeight: Number.parseInt(e.target.value, 10)
                        });
                    }}
                    sx={_sx.xyhw}
                />
                <TextField
                    size="small"
                    label="No Of Col"
                    value={labelPrintSetting.NoOfColumn}
                    type="Number"
                    onChange={e => {
                        setPrintSetting({
                            NoOfColumn: Number.parseInt(e.target.value, 10)
                        });
                    }}
                    sx={_sx.xyhw}
                />
                <TextField
                    size="small"
                    label="Col Width"
                    value={labelPrintSetting.ColumnWidth}
                    type="Number"
                    onChange={e => {
                        setPrintSetting({
                            ColumnWidth: Number.parseInt(e.target.value, 10)
                        });
                    }}
                    sx={_sx.xyhw}
                />

                <TextField
                    size="small"
                    label="H Gap"
                    value={labelPrintSetting.LabelGapH}
                    type="Number"
                    onChange={e => {
                        setPrintSetting({
                            LabelGapH: Number.parseInt(e.target.value, 10)
                        });
                    }}
                    sx={_sx.xyhw}
                />
                <TextField
                    size="small"
                    label="V Gap"
                    value={labelPrintSetting.LabelGapV}
                    type="Number"
                    onChange={e => {
                        setPrintSetting({
                            LabelGapV: Number.parseInt(e.target.value, 10)
                        });
                    }}
                    sx={_sx.xyhw}
                />
            </Box>
            <Divider /></Box>);
}

export default LabelPrintSetting;