import AppBar from "@mui/material/AppBar/AppBar";
import Toolbar from "@mui/material/Toolbar/Toolbar";
import Typography from "@mui/material/Typography/Typography";
import * as React from "react";
import update from "immutability-helper";
import Box from "@mui/material/Box/Box";
import { ApolloConsumer } from "@apollo/client/react/context/ApolloConsumer";
import DrawerToggleButton from "../../../app/DrawerToggleButton";
import CsSearchBox, { OperandType } from "../../../components/CsSearchBox";
import startOfMonth from "date-fns/startOfMonth";
import format from "date-fns/esm/fp/format/index.js";
import endOfMonth from "date-fns/endOfMonth";
import accounting from "accounting";
import ApolloVirtualizedTable, {
  ApolloVirtualizedTableProps,
  DefaultPageInfoValue,
  GridColumn,
} from "mui-apollo-virtualized-table";
import CsDateRangeEditor, {
  DateRangeWithLabel,
} from "../../../components/CsDateRangeEditor";
import { LandedCostCheckLists, LandedCostCheckListsVariables, LandedCostCheckLists_purchaseOrderQuery_landedCostCheckList_edges } from "../__generated__/LandedCostCheckLists";
import { landedCostCheckListsQuery } from "../graphql";
import FileDownloader, { FileDownloaderHandle } from "../../../components/FileDownloader";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import { Download } from "@mui/icons-material";
import Checkbox from "@mui/material/Checkbox";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/store";

const LandedCostCheckListsVirtualizedGrid =
  ApolloVirtualizedTable as React.ElementType<
    ApolloVirtualizedTableProps<LandedCostCheckLists_purchaseOrderQuery_landedCostCheckList_edges>
  >;

const _sx = {
  title: {
    marginRight: 1,
  },
  box: {
    backgroundColor: "rgba(255,255,255,.1)",
  },
  root: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
  },
};

function LandedCostCheckListsBrowser() {
  const [columns, setColumns] = React.useState<
    ReadonlyArray<
      GridColumn<LandedCostCheckLists_purchaseOrderQuery_landedCostCheckList_edges>
    >
  >([
    {
      label: "PO",
      key: "po",
      width: 100,
      sortable: true,
      hideAt: 1080
    },
    {
      label: "#Model",
      key: "default_code",
      width: 150,
      sortable: true,
    },
    {
      label: "Description",
      key: "name",
      width: 250,
      flexGrow: 1,
      sortable: true,
    },
    {
      label: "UOM",
      key: "uom",
      labelAlign: "right",
      width: 100,
      textAlign: "right",
      sortable: true,
      hideAt: 1032,
    },
    {
      label: "Ordered",
      key: "ordered_qty",
      labelAlign: "right",
      width: 100,
      textAlign: "right",
      sortable: true,
      format: ({ rowData }: any) =>
        accounting.formatNumber(rowData.ordered_qty ?? 0, 0),
    },
    {
      label: "Billed",
      key: "billed_qty",
      width: 100,
      textAlign: "right",
      labelAlign: "right",
      sortable: true,
      format: ({ rowData }: any) =>
        accounting.formatNumber(rowData.billed_qty ?? 0, 0),
    },
    {
      label: "Received",
      key: "received_qty",
      labelAlign: "right",
      width: 100,
      textAlign: "right",
      sortable: true,
      format: ({ rowData }: any) =>
        accounting.formatNumber(rowData.received_qty ?? 0, 0),
    },
    {
      label: "O Price",
      key: "order_price",
      labelAlign: "right",
      width: 120,
      textAlign: "right",
      sortable: true,
      format: ({ rowData }: any) =>
        accounting.formatNumber(rowData.order_price ?? 0, 0),
    },
    {
      label: "B Price",
      key: "bill_price",
      labelAlign: "right",
      width: 110,
      textAlign: "right",
      sortable: true,
      hideAt: 960,
      format: ({ rowData }: any) =>
        accounting.formatNumber(rowData.bill_price ?? 0, 0),
    },
    {
      label: "Price",
      key: "unit_cost",
      labelAlign: "right",
      width: 110,
      textAlign: "right",
      sortable: true,
      hideAt: 960,
      format: ({ rowData }: any) =>
        accounting.formatNumber(rowData.unit_cost ?? 0, 0),
    },
    {
      label: "Qty Ok",
      key: "qty_matched",
      labelAlign: "right",
      width: 110,
      textAlign: "right",
      sortable: true,
      hideAt: 960,
      format: ({ rowData }: any) => <Checkbox checked={rowData.qty_matched} />
    },
    {
      label: "Price Ok",
      key: "price_matched",
      labelAlign: "right",
      width: 110,
      textAlign: "right",
      sortable: true,
      hideAt: 960,
      format: ({ rowData }: any) => <Checkbox checked={rowData.price_matched} />
    }
  ]);
  const [variables, setVariables] =
    React.useState<LandedCostCheckListsVariables>({
      pagination: { pageSize: 20 },
      start_date: format("yyyy-MM-dd", startOfMonth(new Date())),
      end_date: format("yyyy-MM-dd", endOfMonth(new Date())),
    });
  const [dateRange, setDateRange] = React.useState<DateRangeWithLabel>(
    new DateRangeWithLabel(
      { from: startOfMonth(new Date()), to: endOfMonth(new Date()) },
      "This Month"
    )
  );

  const handleChangeDateRange = React.useCallback(
    (value: DateRangeWithLabel) => {
      setDateRange(value);
      setVariables({
        ...variables,
        start_date: format("yyyy-MM-dd", value.value.from),
        end_date: format("yyyy-MM-dd", value.value.to),
      });
    },
    [variables, setVariables]
  );

  const handleColumnsPropsChanged = React.useCallback(
    (
      columns: ReadonlyArray<
        GridColumn<LandedCostCheckLists_purchaseOrderQuery_landedCostCheckList_edges>
      >,
      orderBy: string[]
    ) => {
      setColumns(columns);
      setVariables({ ...variables, orderBy: orderBy as any });
    },
    [setVariables, setColumns, variables]
  );

  const fileDownloader = React.useRef<FileDownloaderHandle>(null);
  const handleExcelFileDownload = React.useCallback(() => {
    fileDownloader?.current?.download();
  }, []);
  const odooUrl = useSelector((state: RootState) => state.site.odooServerUrl);
  const handleRowClick = React.useCallback((rowData: LandedCostCheckLists_purchaseOrderQuery_landedCostCheckList_edges) => {
    const url = `${odooUrl}/web#id=${rowData.order_id}&menu_id=218&model=purchase.order&view_type=form`
    window.open(
      url, "_blank");
  }, [odooUrl]);
  return (
    <>
      <Box sx={_sx.root}>
        <AppBar position="static">
          <Toolbar
            sx={(theme) => ({
              [theme.breakpoints.up("md")]: {
                minHeight: 6,
              },
              paddingLeft: 1.5,
            })}
          >
            <DrawerToggleButton />
            <Typography variant="h6" sx={_sx.title} color="inherit" noWrap>
              Landed Cost Check Lists
            </Typography>
            <CsSearchBox
              onConditionChanged={(conditions: any) => {
                setVariables(
                  update(variables, {
                    where: {
                      $set: { aND: conditions },
                    },
                  })
                );
              }}
              popperSx={(theme) => ({
                marginTop: 2,
                [theme.breakpoints.up("md")]: {
                  marginTop: 1,
                },
              })}
              operands={{
                po: {
                  name: "PO",
                  propName: "po",
                  type: OperandType.STRING,
                },
                default_code: {
                  name: "#Model",
                  propName: "default_code",
                  type: OperandType.STRING,
                },
                name: {
                  name: "Description",
                  propName: "name",
                  type: OperandType.STRING,
                },
                uom: {
                  name: "UOM",
                  propName: "uom",
                  type: OperandType.STRING,
                },
                ordered_qty: {
                  name: "Ordered",
                  propName: "ordered_qty",
                  type: OperandType.NUMBER,
                },
                billed_qty: {
                  name: "Bill Qty",
                  propName: "billed_qty",
                  type: OperandType.NUMBER,
                },
                received: {
                  name: "Received",
                  propName: "received_qty",
                  type: OperandType.NUMBER,
                },
                order_price: {
                  name: "O Price",
                  propName: "order_price",
                  type: OperandType.NUMBER,
                },
                bill_price: {
                  name: "B Price",
                  propName: "bill_price",
                  type: OperandType.NUMBER,
                },
                unit_cost: {
                  name: "Price",
                  propName: "unit_cost",
                  type: OperandType.NUMBER,
                },
                qty_matched: {
                  name: "Qty Ok",
                  propName: "qty_matched",
                  type: OperandType.BOOLEAN,
                },
                price_matched: {
                  name: "Price Ok",
                  propName: "price_matched",
                  type: OperandType.BOOLEAN,
                },
              }}
            />
            <CsDateRangeEditor
              sx={{
                marginLeft: 1,
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgb(192, 192, 192)",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#fff",
                },
                "& .MuiOutlinedInput-input": {
                  color: "#fff",
                },
                "& button": {
                  color: "#fff",
                },
              }}
              size="small"
              value={dateRange}
              enableTime={false}
              error={!dateRange}
              onChange={handleChangeDateRange}
            />
            <FileDownloader ref={fileDownloader} url={`/excel/check_landed_costs/${variables.start_date}/${variables.end_date}`} json={JSON.stringify(variables?.where)}>
              <Tooltip title="Download Excel">
                <IconButton
                  color="inherit"
                  onClick={handleExcelFileDownload}
                >
                  <Download />
                </IconButton>
              </Tooltip>
            </FileDownloader>
          </Toolbar>
        </AppBar>
        <Box sx={_sx.box} flex={1} display="flex" flexDirection="row">
          <ApolloConsumer>
            {(client) => (
              <LandedCostCheckListsVirtualizedGrid
                onColumnPropsChanged={handleColumnsPropsChanged}
                apolloClient={client as any}
                listItemHeight={72}
                listModeBreakPoint={0}
                columns={columns}
                graphqlQuery={landedCostCheckListsQuery}
                variables={variables}
                pageSize={variables.pagination?.pageSize!}
                parseListFromQueryResult={(
                  queryResult: LandedCostCheckLists
                ) => {
                  const list = queryResult?.purchaseOrderQuery
                    ?.landedCostCheckList ?? {
                    edges: [],
                    pageInfo: DefaultPageInfoValue,
                  };
                  return list;
                }}
                onRowClick={handleRowClick}
                onLoadMore={(pageInfo) => {
                  return {
                    ...variables,
                    pagination: {
                      page: pageInfo.page,
                      pageSize: pageInfo.pageSize,
                    },
                  };
                }}
              />
            )}
          </ApolloConsumer>
        </Box>
      </Box>
    </>
  );
}

export default LandedCostCheckListsBrowser;
