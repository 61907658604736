import Button from "@mui/material/Button/Button";
import Grid from "@mui/material/Grid/Grid";
import * as React from "react";
import { SaleOrderReduxState } from "./saleOrdersSlice";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
type SaleOrderHeaderProps = {
  saleOrder: SaleOrderReduxState;
  receivePayment: () => void;
  createNew: () => void;
  disablePayment: boolean;
  disableNew: boolean;
  selectCustomer: () => void;
  addSaleOrderLine: () => void;
  disableAddSaleOrderLine: boolean;
  disableSaveSaleOrder: boolean;
  saveSaleOrder: () => void;
  disableCreateInvoice: boolean;
  createInvoice: () => void;
  disableCancel: boolean;
  cancelSaleOrder: () => void;
  disableDo: boolean;
  performDo: () => void;
  performRenew:()=>void;
  disableRenew:boolean;
};
const _sx={
  fieldLabel: {
    fontWeight: "bold",
    marginRight: 1,
    width: 100,
    display: "inline-block",
    color: "inherit"
  },
  field: {
    padding: .5,
  },
  root: {
    paddingTop: 1,
    paddingBottom: 1,
    paddingLeft: 2,
    paddingRight: 2,
    backgroundColor:"rgba(0,0,0,.1)"
  },
  inlineBlock: {
    display: "inline-block",
  },
  valignTop: {
    verticalAlign: "top",
  },
  orderNo: {
    display: "flex",
    alignItems: "center",
    marginBottom: 1,
  },
  marginRight: {
    marginRight: 1,
  },
};
function SaleOrderHeader({
  saleOrder,
  receivePayment,
  disablePayment,
  disableNew,
  createNew,
  selectCustomer,
  disableAddSaleOrderLine,
  addSaleOrderLine,
  disableSaveSaleOrder,
  saveSaleOrder,
  disableCreateInvoice,
  createInvoice,
  disableCancel,
  cancelSaleOrder,
  disableDo,
  performDo,
  performRenew,
  disableRenew
}: SaleOrderHeaderProps) {
  const {
    partner,
    orderDate,
    saleperson_name,
    cashier_name,
    warehouse_id
  } = saleOrder;
  const odooServerUrl = useSelector((state:RootState)=> state.site.odooServerUrl);
  return (
    <Box sx={_sx.root}>
      <Box>

        <Button
          size="small"
          onClick={receivePayment}
          variant="contained"
          disabled={disablePayment}
        >
          Payment(F1)
        </Button>
        <Button
          size="small"
          disabled={disableAddSaleOrderLine}
          onClick={addSaleOrderLine}
          variant="contained"
        >
          Add Product(F4)
        </Button>
        <Button
          size="small"
          onClick={createInvoice}
          variant="contained"
          disabled={disableCreateInvoice}
        >
          Create Invoice(F8)
        </Button>
        <Button
          size="small"
          disabled={disableNew}
          onClick={createNew}
          variant="contained"
        >
          New(F9)
        </Button>
        <Button
          size="small"
          disabled={disableSaveSaleOrder}
          onClick={saveSaleOrder}
          variant="contained"
        >
          Save(F10)
        </Button>
        <Button
          size="small"
          disabled={disableDo}
          onClick={performDo}
          variant="contained"
        >
          Delivery(F11)
        </Button>
        <Button
          size="small"
          disabled={disableCancel}
          onClick={cancelSaleOrder}
          variant="contained"
        >
          Cancel
        </Button>
        <Button
          size="small"
          onClick={performRenew}
          variant="contained"
          disabled={disableRenew}
        >
          Renew
        </Button>
        <Button size="small" disabled={!saleOrder.id} variant="contained" href={`${odooServerUrl}/web#id=${saleOrder.id}&model=sale.order&view_type=form`} target="_blank">
          Odoo
        </Button>
      </Box>      
      <Grid container>
        <Grid item xs={12} md={4}>
          <Typography onClick={(e) => {
            selectCustomer();
            e.preventDefault();
          }} sx={Object.assign({},_sx.fieldLabel,_sx.valignTop)}>
            Customer(F2):
          </Typography>
          <Box sx={_sx.inlineBlock}>
            <Box>{partner?.name}</Box>
            <Box>{partner?.phone}</Box>
            <Box>{partner?.street}</Box>
            <Box>
              {partner?.township?.name_mm} {partner?.township?.name_en}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box sx={_sx.field}>
            <Typography sx={_sx.fieldLabel}>Date:</Typography>
            <Typography>{orderDate}</Typography>
          </Box>
          <Box sx={_sx.field}>
            <Typography sx={_sx.fieldLabel}>Warehouse:</Typography>
            <Typography>{warehouse_id?.display_name}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box sx={_sx.field}>
            <Typography sx={_sx.fieldLabel}>Saleperson:</Typography>
            <Typography>{saleperson_name}</Typography>
          </Box>
          <Box sx={_sx.field}>
            <Typography sx={_sx.fieldLabel}>Invoiced By:</Typography>
            <Typography>{cashier_name}</Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default SaleOrderHeader;
