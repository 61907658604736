import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider/Divider";
import List from "@mui/material/List/List";
import ListItem from "@mui/material/ListItem/ListItem";
import ListItemText from "@mui/material/ListItemText/ListItemText";
import accounting from "accounting";
import * as React from "react";
import {
  SaleOrder_saleOrderQuery_saleOrder
} from "./__generated__/SaleOrder";
import { AccountMoveType } from "../../types/global-types";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import Chip from "@mui/material/Chip";
import resolveAccountMoveStateIndicator from "../common/resolveAccountMoveStateIndicator";

export type SaleInvoicesGridProps = {
  saleOrder: SaleOrder_saleOrderQuery_saleOrder;
};

const _sx = {
  listItem: {
    color: "inherit"
  },
  amount:{
    textAlign:"right"
  }
};

function SaleOrderInvoicesGrid({ saleOrder }: SaleInvoicesGridProps) {
  const { invoices } = saleOrder;
  const odooServerUrl = useSelector((state: RootState) => state.site.odooServerUrl);
  const invoiceReportId = useSelector((state:RootState)=> state.site.saleInvoiceReportId)
  if (!invoices || invoices.length === 0) return <p>No invoices yet.</p>;
  return (<><Typography variant="h6">Invoices</Typography><List>
    {
      invoices.map((invoice) => ((
        <React.Fragment key={invoice.id}>
          <ListItem component="a" target="_blank" sx={_sx.listItem} href={`${odooServerUrl}/report/pdf/${invoiceReportId}/${invoice.id}`} >
            <ListItemText primary={invoice.name} secondary={<Chip
                  onClick={()=>{window.open(`${odooServerUrl}/web#id=${invoice.id}&model=account.move&view_type=form`,"_blank")}}
                  size="small"
                  label={resolveAccountMoveStateIndicator(invoice.state)}
                />} />
            <ListItemSecondaryAction><ListItemText sx={_sx.amount} primary={accounting.formatNumber(
              invoice.amount_total *
              (invoice.move_type === AccountMoveType.out_refund ? -1 : 1),
              0
            )} secondary={accounting.formatNumber(
              invoice.amount_residual *
              (invoice.move_type === AccountMoveType.out_refund ? -1 : 1),
              0
            )} />
            </ListItemSecondaryAction>
          </ListItem>
          <Divider />
        </React.Fragment>)))
    }
  </List></>);
}

export default SaleOrderInvoicesGrid;