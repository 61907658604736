import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "./store";

function useHasPermission(policy:string){
    const userRoles = useSelector((state:RootState)=>state.site.userSession.userRole);
    const permissions = useSelector((state:RootState)=> state.site.permissions);
   
    const permissionId = React.useMemo(()=>{
        const [resource,operation] = policy.split("_");
        return permissions.find((p)=>p.operation===operation && p.resource === resource)?.id?.toString();
    },[permissions,policy]);
    return Boolean(userRoles?.includes(permissionId??"") || userRoles?.includes("11111"));
}

export default useHasPermission;