import { SnackbarProps } from "@mui/material/Snackbar";
import * as React from "react";

const defaultSnackbarProps:SnackbarProps = {
  open: false,
  autoHideDuration: 6000,
  message: "",
};

const SnackbarContext = React.createContext({
  showSnackbar: (props: SnackbarProps) => {},
});

const useShowSnackbar = () => React.useContext(SnackbarContext);
const SnackbarProvider = SnackbarContext.Provider;

export { useShowSnackbar, SnackbarProvider, defaultSnackbarProps };