import Chip from "@mui/material/Chip/Chip";
import * as React from "react";
import { StockPickingState } from "../../types/global-types";

type StockPickingStateIndicatorProps = {
  state: StockPickingState | null;
  className?:string;
};
function StockPickingStateIndicator({ state,className }: StockPickingStateIndicatorProps) {
  var text = "draft";
  var color = "rgba(0,0,0,.7)";
  switch (state) {
    case StockPickingState.draft:
      color = "#fbc02d";
      text = "Draft";
      break;
    case StockPickingState.waiting:
      color = "#e64a19";
      text = "Waiting Other";
      break;
    case StockPickingState.confirmed:
        color="#6a1b9a";
        text="Waiting"
        break;
    case StockPickingState.partially_available:
        color="#3f51b5";
        text="Some Ready"
        break;
    case StockPickingState.assigned:
      color = "#303f9f";
      text = "Ready";
      break;
    case StockPickingState.done:
      color = "#388e3c";
      text = "Done";
      break;
    case StockPickingState.cancel:
      color = "#d32f2f";
      text = "Cancelled";
      break;
  }
  return  <Chip className={className} size="small" style={{backgroundColor:color,color:"#fff"}} label={text} />
}

export default StockPickingStateIndicator;
