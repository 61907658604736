import { useQuery } from "@apollo/client";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { usePromotionOutletContext } from ".";
import { RootState } from "../../app/store";
import { promotionQuery } from "./graphql";
import PromotionEditControl from "./PromotionEditControl";
import { promotionActions } from "./promotionsSlice";
import { Promotion, PromotionVariables, Promotion_promotionQuery_promotion } from "./__generated__/Promotion";

function PromotionEdit() {
  const {
    promotion_id,
    promotionsVariables,
  } = usePromotionOutletContext();
  const currentPromotionId = useSelector((state: RootState) => state.promotion.id);
  const dispatch = useDispatch();
  const setPromotion = React.useCallback(
    (promotion: Promotion_promotionQuery_promotion) => {
      dispatch(promotionActions.setPromotion(promotion));
    },
    [dispatch]
  );
  const { loading } = useQuery<Promotion, PromotionVariables>(promotionQuery, {
    variables: {
      id: promotion_id?.toString()!,
    },
    skip: promotion_id?.toString() === currentPromotionId,
    onCompleted: (data) => {
      const promotion = data?.promotionQuery?.promotion;
      if (promotion) {
        setPromotion({ ...promotion });
      }
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });
  return (
    <PromotionEditControl
      promotionsVariables={promotionsVariables}
      loading={loading}
    />
  );
}

export default PromotionEdit;