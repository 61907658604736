import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AccountingAccountOptionValue } from "../common/AccountingAccountAutoComplete";
import { AnalyticAccounts_commonQuery_analyticAccounts } from "../common/__generated__/AnalyticAccounts";
import { Journals_commonQuery_journals } from "../common/__generated__/Journals";
import { Partners_partnerQuery_partners_edges } from "../common/__generated__/Partners";
import { SaleTeams_commonQuery_saleTeams } from "../common/__generated__/SaleTeams";
import { Warehouses_inventoryQuery_warehouses } from "../common/__generated__/Warehouses";

export type SaleSettingState = {
  readonly cashJournal: Journals_commonQuery_journals | null;
  readonly destinationCashJournal: Journals_commonQuery_journals | null;
  readonly warehouse: Warehouses_inventoryQuery_warehouses| null;
  readonly analyticAccount: AnalyticAccounts_commonQuery_analyticAccounts| null;
  readonly saleTeam: SaleTeams_commonQuery_saleTeams| null;
  readonly discountAccount: AccountingAccountOptionValue | null;
  readonly cashAccount: AccountingAccountOptionValue | null;
  readonly defaultCustomer: Partners_partnerQuery_partners_edges| null;
  readonly percentage: number;
  readonly recordSoldBy:boolean;
  roundedDecimalPlaceForPrice: number;
};

const initialState: SaleSettingState = {
  cashJournal: null,
  destinationCashJournal:null,
  cashAccount: null,
  warehouse: null,
  analyticAccount: null,
  saleTeam: null,
  discountAccount: null,
  defaultCustomer: null,
  roundedDecimalPlaceForPrice: 0,
  percentage: 3,
  recordSoldBy:false
};

export const saleSettingSlice = createSlice({
  name: "saleSetting",
  initialState,
  reducers: {
    setWarehouse: (
      state,
      action: PayloadAction<Warehouses_inventoryQuery_warehouses| null>
    ) => {
      state.warehouse = action.payload;
    },
    setCashJournal: (
      state,
      action: PayloadAction<Journals_commonQuery_journals| null>
    ) => {
      state.cashJournal = action.payload;
    },
    setDestinationCashJournal:(state,action: PayloadAction<Journals_commonQuery_journals|null>)=>{
      state.destinationCashJournal = action.payload;
    },
    setAnalyticAccount: (
      state,
      action: PayloadAction<AnalyticAccounts_commonQuery_analyticAccounts| null>
    ) => {
      state.analyticAccount = action.payload;
    },
    setSaleTeam: (
      state,
      action: PayloadAction<SaleTeams_commonQuery_saleTeams| null>
    ) => {
      state.saleTeam = action.payload;
    },
    setDiscountAccount: (
      state,
      action: PayloadAction<AccountingAccountOptionValue| null>
    ) => {
      state.discountAccount = action.payload;
    },
    setDefaultCustomer: (
      state,
      action: PayloadAction<Partners_partnerQuery_partners_edges| null>
    ) => {
      state.defaultCustomer = action.payload;
    },
    setRoundedDecimalPlaceForPrice: (state, action: PayloadAction<number>) => {
      state.roundedDecimalPlaceForPrice = action.payload;
    },
    setPercentage: (state, action: PayloadAction<number>) => {
      state.percentage = action.payload;
    },
    setCashAccount: (
      state,
      action: PayloadAction<AccountingAccountOptionValue| null>
    ) => {
      state.cashAccount = action.payload;
    },
    setRecordSoldby: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.recordSoldBy = action.payload
    },
  },
});

const saleSettingActions =  saleSettingSlice.actions;
export {saleSettingActions};
export default saleSettingSlice.reducer;
