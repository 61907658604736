import { useQuery } from "@apollo/client";
import Autocomplete from "@mui/material/Autocomplete";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import * as React from "react";
import { companyQuery } from "./graphql";
import { Company, Company_commonQuery_companies } from "./__generated__/Company";

export type CompanyAutoCompleteProps = {
  selectedCompany: Company_commonQuery_companies;
  onSelected: (company: Company_commonQuery_companies) => void;
} & TextFieldProps;
function CompanyAutoComplete({
  selectedCompany,
  onSelected,
  ...props
}: CompanyAutoCompleteProps) {
  const { data } = useQuery<Company, {}>(companyQuery);
  return (
    <Autocomplete
      value={selectedCompany}
      onChange={(_, value: Company_commonQuery_companies|null) =>
        onSelected(value!)
      }
      isOptionEqualToValue={(option, value) => option.id === value.id}
      options={data?.commonQuery?.companies??(selectedCompany?[selectedCompany]:[])}
      getOptionLabel={(option) => option?.name??""}
      renderInput={(params) => (
        <TextField
          {...params}
          {...props}
          label="Company"
          placeholder="Company"
        />
      )}
    />
  );
}

export default CompanyAutoComplete;
