import { gql } from "@apollo/client";
export const promotionSalesQuery = gql`
query PromotionSales($where:PromotionSaleWhereInput, $orderBy:[PromotionSaleOrderBy!],$pagination:PaginationInput){
  promotionQuery{
    promotion_sale(where:$where, orderBy:$orderBy,pagination:$pagination){
      edges{
        id
        promotion_id
        date
        order_name
        move_name
        partner
        description
        name
        account_name
        quantity
        amount
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        rowCount
        pageCount
        currentPage
        pageSize
      }
    }
  }
}
`;

export const promotionsQuery = gql`
query Promotions(
  $where: PromotionWhereInput
  $orderBy: [PromotionOrderBy!]
  $pagination: PaginationInput
) {
  promotionQuery {
    id
    promotions(where: $where, orderBy: $orderBy, pagination: $pagination) {
      edges {
        id
        name
        active
        date_from
        date_to
        created_id
        created_name
        reward_product_income_account_id {
          display_name
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        rowCount
        pageCount
        currentPage
        pageSize
      }
    }
  }
}
`;

export const promotionQuery = gql`
query Promotion($id: ID!) {
  promotionQuery {
    id
    promotion(id: $id) {
      id
      active
      name
      company_id {
        id
        display_name
      }
      date_to
      date_from
      reward_product_income_account_id {
        id
        display_name
      }
      created_id
      created_name
      loyalty_programs {
        id
        name
        product_id{
          id
          display_name
        }
        minimum_qty
        discount_mode   
        reward_product_id {
          id
          display_name
        }
        reward_type
        discount
        discount_max_amount
        updated_id
        updated_name
        active
        discount_mode
      }
    }
  }
}
`;

export const savePromotionMutation = gql`
  mutation SavePromotion($promotion: PromotionInput!) {
    promotionMutation {
      id
      savePromotion(promotion: $promotion) {
        id
        active
        name
        company_id {
          id
          display_name
        }
        date_from
        date_to
        reward_product_income_account_id {
          id
          display_name
        }
        created_id
        created_name
        loyalty_programs {
          id
          name
          product_id{
            id
            display_name
          }
          minimum_qty
          discount_mode   
          reward_product_id {
            id
            display_name
          }
          reward_type
          discount
          discount_max_amount
          updated_id
          updated_name
          active
          discount_mode
        }      
      }
    }
  }
`;
