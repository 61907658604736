import Box from "@mui/material/Box";
import * as React from "react";
import { useDropzone } from "react-dropzone";
import { AlbumPhoto } from "./ProductPhotoAlbum";
import { store } from "../../../app/store";
import update from "immutability-helper";
import settings from "../../../config.json";
export type PhotoUploaderProps = {
    onUploading: (photos: AlbumPhoto[]) => void;
    onUploaded: (photos: AlbumPhoto[]) => void;
    product_id: number;
    onError:(error:Error)=>void;
};
const uploadPhoto = async ({ file, ...photo }: AlbumPhoto & { file: File }, product_id: number) => {
    const form = new FormData();
    const data = {
        product_id,
        description: photo.description ?? "",
        sequence: (photo.sequence ?? 10).toString()
    };
    form.append("file", file, `${product_id}.${file.name.split(".")[1]}`);
    form.append("product_id", data.product_id.toString());
    form.append("description", data.description);
    form.append(
        "sequence",
        data.sequence
    );
    form.append(
        "photo",
        JSON.stringify(data)
    );
    const result = await fetch("/FileUpload/ProductPhoto", {
        method: "POST",
        body: form,
        headers: {
            Authorization: `Bearer ${store.getState().site.userSession.accessToken}`,
        },
    });
    const { image } = await result.json();
    const uploadedPhoto: AlbumPhoto = {
        ...photo,
        image: image,
        src: `${settings.cloudfront_url}${image}`,
    };
    return uploadedPhoto;
};
function PhotoUploader({ onUploading, onUploaded, product_id,onError }: PhotoUploaderProps) {
    const [uploading, setUploading] = React.useState(false);
    const performUpload = React.useCallback(
        (photos: Array<AlbumPhoto & { file: File }>) => {
            setUploading(true);
            var snapShop = photos;
            const starterPromise = Promise.resolve<AlbumPhoto | null>(null);
            photos
                .reduce(
                    (p, current, index) =>
                        p.then(() =>
                            current.file
                                ? uploadPhoto({
                                    ...current,
                                    sequence: index
                                }, product_id).then((uploaded) => {
                                    snapShop = update(snapShop, {
                                        [index]: {
                                            $set: {
                                                ...snapShop[index],
                                                id:uploaded.id,
                                                image: uploaded.image,
                                                sequence: uploaded.sequence,
                                                description: uploaded.description,
                                                aspect_ratio:uploaded.aspect_ratio,
                                                height: 1000 / (uploaded.aspect_ratio??1),
                                                src: `${settings.cloudfront_url}/${uploaded.image}/x1000` 
                                            }
                                        },
                                    });
                                    return uploaded;
                                })
                                : Promise.resolve(null)
                        ),
                    starterPromise
                )
                .then(()=>{
                    onUploaded(snapShop);
                })
                .catch((error)=>{onError(error);})
                .finally(() => {
                    setUploading(false);
                });
        },
        [onError, onUploaded, product_id]
    );
    const onDrop = React.useCallback(
        async (acceptedFiles: File[]) => {
            var newPhotos = acceptedFiles.map((file) => {
                const photo: AlbumPhoto & { file: File } = {
                    src: window.URL.createObjectURL(file),
                    width: 1000,
                    height: 1000,
                    description: "",
                    image: "",
                    sequence: 1,
                    id: "",
                    aspect_ratio:1,
                    file: file
                };
                return photo;
            });
            onUploading(newPhotos);
            await performUpload(newPhotos);
        },
        [onUploading, performUpload]
    );
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: {
            'image/png': ['.png'],
            'image/jpeg': ['.jpg', '.jpeg'],
            'image/webp':['.webp']
        }
    });

    const { className: dropZoneClassName, ...dropZoneProps } = getRootProps();
    if (uploading)
        return null;
    return <Box>
        <Box
            flex={1}
            display="flex"
            alignItems="center"
            justifyContent="center"
            height={120}
            border="1px solid"
            {...dropZoneProps}
            className={dropZoneClassName}
        >
            <input {...getInputProps()} />
            {isDragActive ? (
                <p>Drop the photos here ...</p>
            ) : (
                <p>Drag 'n' drop some photos here, or click to select files</p>
            )}
        </Box>
    </Box>;
}

export default PhotoUploader;