import { gql } from "@apollo/client";

export const checkUserCodeExistsQuery = gql`
query CheckUserCodeExists($id:ID,$checkFor:String!){
  userQuery{
    id
    userCodeExists(id:$id,checkFor:$checkFor)
  }
}
`;

export const usersQuery = gql`
  query Users(
    $where: UserWhereInput
    $orderBy: [UserOrderBy]
    $pagination: PaginationInput
  ) {
    userQuery {
      id
      users(where: $where, orderBy: $orderBy, pagination: $pagination) {
        edges {
          id
          code
          fullName
          odooUserName
          odooPassword
          defaultAdmin
          active
          companyId
          companyName
          permissions {
            id
            resource
            operation
          }

        }
        pageInfo {
          currentPage
          hasNextPage
          hasPreviousPage
          pageCount
          pageSize
          rowCount
        }
      }
    }
  }
`;

export const registerUserMutation = gql`
  mutation RegisterUser($user: UserInput!) {
    userMutation {
      id
      registerUser(user: $user) {
        id
        cashAccountIdForCashSale
        active
        code
        fullName
        defaultAdmin
        odooUserName
        odooPassword
        companyId
        companyName
        permissions {
          id
          resource
          operation
        }
      }
    }
  }
`;

export const updateUserPermissionsMutation = gql`
mutation UpdateUserPermission($id:ID!,$permissions:[Int!]!){
  userMutation{
    id
    updateUserPermissions(id:$id,permissions:$permissions){
      id
      permissions{
        id
        resource
        operation
      }
    }
  }
}
`;

export const updateUserMutation = gql`
  mutation UpdateUser($id: ID!, $user: UserInput!) {
    userMutation {
      id
      updateUser(user: $user, id: $id) {
        id
        cashAccountIdForCashSale
        active
        code
        fullName
        defaultAdmin
        odooUserName
        odooPassword
          companyId
          companyName
        permissions {
          id
          resource
          operation
        }
      }
    }
  }
`;

export const changePasswordMutation = gql`
  mutation ChangePassword(
    $newPassword: String
    $loginPassword: String
    $id: ID!
  ) {
    userMutation {
      id
      changePassword(
        newPassword: $newPassword
        logInPassword: $loginPassword
        id: $id
      )
    }
  }
`;

export const permissionsQuery = gql`query Permissions{
  userQuery{
    id
    permissions{
      id
      resource
      operation
    }
  }
}`;

export const userQuery = gql`
query User($id:ID!){
  userQuery{
    id
    user(id:$id){
      id
      cashAccountIdForCashSale
      active
      code
      fullName
      defaultAdmin
      odooUserName
      odooPassword
          companyId
          companyName
      permissions {
        id
        resource
        operation
      }      
    }
  }
}
`;
