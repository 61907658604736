import { format } from "date-fns";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSaleOrderOutletContext } from ".";
import { RootState } from "../../app/store";
import SaleOrderEditControl from "./SaleOrderEditControl";
import { saleOrderActions, saleOrderDefaultValue } from "./saleOrdersSlice";


function SaleOrderCreate(){
    const {saleOrdersVariables} = useSaleOrderOutletContext();
    const dispatch = useDispatch();
    const saleSetting = useSelector((state:RootState)=>state.saleSetting);
    const navigate = useNavigate();
    React.useEffect(()=>{
        const order = {...saleOrderDefaultValue, date_order: format(new Date(),"yyyy-MM-dd")};
        if(saleSetting.analyticAccount){
            order.analytic_account_id= { id: saleSetting.analyticAccount?.id, display_name: saleSetting.analyticAccount?.name};
        }else{
            navigate("/settings/sale");
        }
        if(saleSetting.saleTeam){
            order.team_id = {id:saleSetting.saleTeam.id, display_name: saleSetting.saleTeam.name};
        }else{
            navigate("/settings/sale");
        }
        if(saleSetting.warehouse){
            order.warehouse_id={id:saleSetting.warehouse.id, display_name: saleSetting.warehouse.name};
        }else{
            navigate("/settings/sale");
        }
        if(saleSetting.defaultCustomer){
            order.partner= saleSetting.defaultCustomer as any;
        }
        dispatch(saleOrderActions.setSaleOrder(order));
    },[dispatch, navigate, saleSetting.analyticAccount, saleSetting.defaultCustomer, saleSetting.saleTeam, saleSetting.warehouse]);
    return (<SaleOrderEditControl saleOrdersVariables={saleOrdersVariables} loading={false}/>);
}

export default SaleOrderCreate;