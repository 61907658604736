import Button from "@mui/material/Button/Button";
import Box from "@mui/material/Box";
import { Grid, TextField } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import TextEditor from "../../components/TextEditor";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox  from "@mui/material/Checkbox";
import { DatePicker, DatePickerProps } from "@mui/x-date-pickers";
import settings from "../../config.json";
import format from "date-fns/format";
import { PromotionActionPayload, promotionActions, PromotionReduxState } from "./promotionsSlice";
import AccountingAccountAutoComplete, { AccountingAccountOptionValue } from "../common/AccountingAccountAutoComplete";
import Typography from "@mui/material/Typography";
import { startOfDay } from "date-fns/esm";
const Picker = (DatePicker) as React.ElementType<DatePickerProps<Date, any>>

type PromotionHeaderProps = {
  promotion: PromotionReduxState;
  createCouponProgram: () => void;
  disableCreateCouponProgram: boolean;
  disableSavePromotion: boolean;
  savePromotion: () => void;
};

const _sx = {
  root: {
    paddingTop: 1,
    paddingBottom: 1,
    paddingLeft: 2,
    paddingRight: 2,
    backgroundColor: "rgba(0,0,0,0.1)",
  },
};

function PromotionHeader({
  promotion,
  createCouponProgram,
  disableCreateCouponProgram,
  disableSavePromotion: disableSaveCouponProgram,
  savePromotion: saveCouponProgram,
}: PromotionHeaderProps) {
  const dispatch = useDispatch();
  const editPromotionData = React.useCallback((
    promotion: PromotionActionPayload
  ) => {
    dispatch(promotionActions.updatePromotion(promotion));
  }, [dispatch]);

  const setName = React.useCallback((name: string) => {
    editPromotionData({ name });
  }, [editPromotionData]);

  const setRuleDateFrom = React.useCallback((date: Date) => {
    editPromotionData({ date_from: format(date, "yyyy-MM-dd") });
  }, [editPromotionData]);

  const setRuleDateTo = React.useCallback((date: Date) => {
    editPromotionData({ date_to: format(date, "yyyy-MM-dd") });
  }, [editPromotionData]);

  const setIncomeAccount = React.useCallback((account: AccountingAccountOptionValue | null) => {
    editPromotionData({ reward_product_income_account_id: account as any });
  }, [editPromotionData]);

  const setActive = React.useCallback((active:boolean)=>{
    editPromotionData({active});
  }, [editPromotionData])



 

  const ruleDateFrom = React.useMemo(() => {
    if (promotion?.date_from)
      return startOfDay(new Date(promotion?.date_from));
    else
      return null;
  }, [promotion?.date_from]);
  const ruleDateTo = React.useMemo(() => {
    if (promotion?.date_to)
      return startOfDay(new Date(promotion?.date_to));
    else
      return null;
  }, [promotion?.date_to]);


  const { isValid, errors } = React.useMemo(() => {
    var isValid = true;
    const errors: { [key: string]: string } = {};
    if (ruleDateFrom && ruleDateTo) {
      if (ruleDateFrom > ruleDateTo) {
        errors.ruleDateFrom = "Start date must be earlier than end date.";
        errors.ruleDateTo = "End date must be later than start date.";
        isValid = false;
      }
    }
    if (!promotion.name) {
      errors.name = "Promotion name is required!";
      isValid = false;
    }
    if (!promotion.reward_product_income_account_id) {
      errors.reward_product_income_account_id = "Income account is required!";
      isValid = false;
    }
    return { isValid, errors };
  }, [promotion.name, promotion.reward_product_income_account_id, ruleDateFrom, ruleDateTo]);


  return (
    <Box sx={_sx.root}>
      <Box>
        <Button
          size="small"
          disabled={disableCreateCouponProgram}
          onClick={createCouponProgram}
          variant="contained"
        >
          ADD PRODUCT(F4)
        </Button>
        <Button
          sx={{ ml: 2 }}
          size="small"
          disabled={disableSaveCouponProgram}
          onClick={() => {
            if (isValid)
              saveCouponProgram();
          }}
          variant="contained"
        >
          SAVE(F10)
        </Button>
        <Typography sx={{ display: "inline-block", ml: 2 }}>Created By: {promotion?.created_name}</Typography>
      </Box>
      <Grid item container spacing={2} sx={{ mt: 1, mb: 2 }}>
        <Grid item xs={12} md={12}>
          <TextEditor
            label="Promotion Name"
            value={promotion?.name}
            fullWidth
            size="small"
            inputElementType="TextField"
            onValidated={setName}
            error={!!errors.name}
            helperText={errors.name}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Picker
            renderInput={(props) => <TextField size="small" {...props} fullWidth
              error={!!errors.ruleDateFrom}
              helperText={errors.ruleDateFrom} />}
            label="From"
            value={ruleDateFrom}
            inputFormat={settings.shortDateFormat}
            onChange={setRuleDateFrom}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Picker
            renderInput={(props) => <TextField size="small" {...props} fullWidth
              error={!!errors.ruleDateTo}
              helperText={errors.ruleDateTo} />}
            label="To"
            value={ruleDateTo}
            inputFormat={settings.shortDateFormat}
            onChange={setRuleDateTo}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <AccountingAccountAutoComplete size="small" label="Income Account for Reward" selectedAccountingAccount={promotion?.reward_product_income_account_id} onSelected={setIncomeAccount} error={!!errors.reward_product_income_account_id} helperText={errors.reward_product_income_account_id}/>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <FormControlLabel control={<Checkbox checked={promotion.active} onChange={(e,checked)=>{setActive(checked)}} />} label="Active" />
        </Grid>
      </Grid>
    </Box>
  );
}

export default PromotionHeader;
