import { useQuery } from "@apollo/client/react";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import * as React from "react";
import { productItemsQuery } from "./graphql";
import ProductGrid from "./ProductGrid";
import { ProductItems, ProductItemsVariables, ProductItems_productQuery_productItems_edges } from "./__generated__/ProductItems";
import useHasPermission from "../../app/useHasPermission";

export type ProductAutoCompleteProps = {
    onSelected?: (product: ProductItems_productQuery_productItems_edges) => void;
    value?: ProductItems_productQuery_productItems_edges;
} & TextFieldProps;

const ProductAutoComplete = React.forwardRef<HTMLInputElement | null, ProductAutoCompleteProps>(({ value, onSelected, ...props }, forwardRef) => {
    const anchor = React.useRef<HTMLDivElement | null>(null);
    const [focusStatus, setFocusStatus] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [searchText, setSearchText] = React.useState(value ? `${value?.default_code} - ${value?.name}` : "");
    const inputRef = React.useRef<HTMLInputElement | null>(null);
    const allowedQuants = useHasPermission("Product_ViewOnHand");
    React.useImperativeHandle(forwardRef, () => inputRef.current!);
    React.useEffect(() => {
        setSearchText(value ? `${value?.default_code} - ${value?.name}` : "");
    }, [value]);
    const variables = React.useMemo(() => ({
        quants:allowedQuants,
        where: {
            oR: [
                { search_code_Contains: searchText },
                { default_code_Contains: searchText },
                { barcode_Contains: searchText },
                { name_Contains: searchText },
                { id: Number.parseInt(value?.id?.toString() ?? "0") }
            ]
        }
    }), [searchText, value?.id]);


    const { data } = useQuery<ProductItems, ProductItemsVariables>(productItemsQuery, {
        variables,
        fetchPolicy: "cache-only"
    });
    const [selectedIndex, setSelectedIndex] = React.useState(-1);

    React.useEffect(() => {
        setSelectedIndex(0);
    }, [data?.productQuery?.productItems?.edges]);

    const selectedProduct = React.useMemo(() => {
        const products = data?.productQuery?.productItems?.edges ?? [];
        return products[selectedIndex];
    }, [selectedIndex, data?.productQuery?.productItems?.edges]);

    const handleRowClick = React.useCallback((row: ProductItems_productQuery_productItems_edges, index: number) => {
        setSelectedIndex(index);
        if (onSelected)
            onSelected(row);
        setOpen(false);
    }, [onSelected]);

    const handleCommandKeyUp: React.KeyboardEventHandler<HTMLElement> =
        React.useCallback(
            (e) => {
                const products = data?.productQuery?.productItems?.edges ?? [];
                switch (e.key) {
                    case "Escape":
                        if (open) {
                            e.stopPropagation();
                            setOpen(false);
                        }
                        setSearchText(value ? `${value?.default_code} - ${value?.name}` : "");
                        break;
                    case "Enter":
                        e.stopPropagation();
                        if (onSelected)
                            onSelected(selectedProduct);
                        setOpen(false);
                        break;
                    case "ArrowUp":
                        if (e.altKey)
                            setOpen(false)
                        else
                            setSelectedIndex(Math.max(0, selectedIndex - 1));
                        e.stopPropagation();
                        break;
                    case "ArrowDown":
                        if (e.altKey)
                            setOpen(true);
                        else
                            setSelectedIndex(Math.min(selectedIndex + 1, products.length));
                        e.stopPropagation();
                        break;
                }
            },
            [data?.productQuery?.productItems?.edges, onSelected, open, selectedIndex, selectedProduct, value]
        );

    const handleDropDownBtnClick = React.useCallback(() => {
        if (open && onSelected) {
            onSelected(selectedProduct);
        }
        setOpen(!open);
    }, [onSelected, open, selectedProduct]);

    return <><TextField
        {...props}
        onKeyUp={handleCommandKeyUp}
        ref={anchor}
        inputRef={inputRef}
        variant="outlined"
        value={searchText}
        onChange={(e) => { setSearchText(e.target.value); setOpen(true); }}
        onFocus={e => {
            setFocusStatus(true); setOpen(true); setTimeout(() => {
                inputRef.current?.setSelectionRange(
                    0,
                    inputRef.current?.value?.length ?? 0
                );
            }, 10);
        }}
        onBlur={e => { setFocusStatus(false); }}
        autoComplete="off"
        InputProps={{
            endAdornment: (
                <React.Fragment>
                    <IconButton onBlur={e => { setOpen(false); }} onKeyUp={e => { if (e.key === "Enter") e.stopPropagation(); }} onClick={handleDropDownBtnClick}><ArrowDropDown /></IconButton>
                </React.Fragment>
            ),
        }}
    /><Popper sx={{ zIndex: 2000 }} placement="bottom-start" anchorEl={anchor.current} open={open}><ClickAwayListener onClickAway={() => { if (!focusStatus) setOpen(false); }}>
        <Paper
            onKeyUp={handleCommandKeyUp} sx={{ width: 600, maxWidth: "100%", height: 400, display: "flex" }}>
            <ProductGrid variables={variables} onRowClick={handleRowClick} selectedIndex={selectedIndex} />
        </Paper>
    </ClickAwayListener></Popper></>;
});

export default ProductAutoComplete;