import { gql } from "@apollo/client";
export const ecommerceCategoriesQuery = gql`query EcommerceCategories($where:EcommerceCategoryWhereInput, $orderBy:[EcommerceCategoryOrderBy!], $pagination:PaginationInput){
    ecommerceQuery{
      id
      categories(where:$where,orderBy:$orderBy,pagination:$pagination){
        edges{
          id
          name
          complete_name        
        }
        pageInfo{
          pageSize
          pageCount
          rowCount
          hasNextPage
          hasPreviousPage
          currentPage
        }
      }
    }
  }`
export const ecommerceProductQuery = gql`query EcommerceProduct($id:Int!){
  ecommerceQuery{
    id
  	product(id:$id){
      id
      default_code
      barcode
      name
      list_price
      ecommerce_caption
      ecommerce_description
      zone_id{
        id
        display_name
      }
      brand_id{
        id
        display_name
      }
      categ_id{
        id
        display_name
      }
      related_companies{
        id
        name
      }
      uom_id{
        id
        display_name
      }
      ecommerce_categories{
        id
        name
        complete_name
      }
      overview
      default_ecommerce_categ_id{
        id
        display_name
      }
      image_variant_id{
        id
        display_name
      }
      uom_description
      min_order_qty
      max_order_qty
      available_date
      availability
      free_shipping
      hide_price
      preorder_description
      domain_for_related_products
      product_warranty
      published_on_website
      warranty_unit
      warranty_duration
      warranty_description
      tri_product_variant_images{
        id
        image
        sequence
        description
        aspect_ratio
      }
      excluded_companies{
        id
        name
      }
      excluded_locations{
        id
        complete_name
      }
      exclude_consignment
      quants{
        location_id
        location_name
        company_id
        quantity_sum
        reserved_quantity_sum
      }
      available_quants{
        company_id
        company_name
        quantity_sum
        reserved_quantity_sum
      }
    }
  }
}`;

export const saveEcommerceProductMutation = gql`mutation SaveEcommerceProduct($product:EcommerceProductInput!){
  ecommerceMutation{
    saveProduct(product:$product){
     	id
      default_code
      barcode
      name
      list_price
      ecommerce_caption
      ecommerce_description
      overview
      zone_id{
        id
        display_name
      }
      brand_id{
        id
        display_name
      }
      categ_id{
        id
        display_name
      }
      related_companies{
        id
        name
      }
      uom_id{
        id
        display_name
      }
      ecommerce_categories{
        id
        name
        complete_name
      }
      default_ecommerce_categ_id{
        id
        display_name
      }
      image_variant_id{
        id
        display_name
      }
      uom_description
      min_order_qty
      max_order_qty
      available_date
      availability
      free_shipping
      hide_price
      preorder_description
      domain_for_related_products
      product_warranty
      published_on_website
      warranty_unit
      warranty_duration
      warranty_description
      tri_product_variant_images{
        id
        image
        sequence
        description
        aspect_ratio
      }
      excluded_companies{
        id
        name
      }
      excluded_locations{
        id
        complete_name
      }
      exclude_consignment
      quants{
        location_id
        location_name
        company_id
        quantity_sum
        reserved_quantity_sum
      }
      available_quants{
        company_id
        company_name
        quantity_sum
        reserved_quantity_sum
      }
    }
  }
}`;

export const ecommerceCategoryFragment = gql`
  fragment EcommerceCategoryFragment on EcommerceCategory {
    id
    image
    parent_id{
      id
      display_name
    }
    partner_id{
      id
      display_name
    }
    children{
      id
      name
      image
    }
  }
`;

export const ecommerceCategoryQuery = gql`
query EcommerceCategory($id:Int!,$where:EcommerceProductWhereInput,$pagination:PaginationInput,$includeProducts:Boolean=false){
  ecommerceQuery{
    id
    category(id:$id){
      id
      name
      image
      parent_id{
        id
        display_name
      }
      parents_with_me{
        id
        name
        image
      }
      children{
        id
        name
        image
      }
      complete_name
      partner_id{
        id
        display_name
      }
      products(where:$where,pagination:$pagination) @include(if: $includeProducts){
        edges{
          id
          default_code
          name
          ecommerce_caption
          ecommerce_description
          default_variant_image_ratio
        	image_variant_id{
            id
            display_name
          }
        }
        pageInfo{
          pageSize
          pageCount
          hasNextPage
          hasPreviousPage
          rowCount
          currentPage
        }
      }
    }
  }
}
`;

export const saveEcommerceCategoryMutation = gql`mutation SaveEcommerceCategory($category:EcommerceCategoryInput!){
  ecommerceMutation{
    saveCategory(category:$category){
      id
      name
      image
      parent_id{
        id
        display_name
      }
      parents_with_me{
        id
        name
        image
      }
      children{
        id
        name
        image
      }
      complete_name
      partner_id{
        id
        display_name
      }
    }
  }
}`;


export const deleteEcommerceCategory= gql`
mutation DeleteEcommerceCategoryMutation($id:Int!){
  ecommerceMutation{
    id
    deleteCategory(id:$id){
      id
      children{
        id
        name
        image
      }
    }
  }
}
`;

export const algoliaProducts = gql`query AlgoliaProducts{
  algoliaQuery{
    products{
      id
      objectID:id
      default_code
      name
      ecommerce_caption
      ecommerce_description
      sale_zone
      brand
      uom
      categ
      published_on_website
      image
      default_variant_image_ratio
      uom_description
      available_date
      availability
      free_shipping
      hide_price
      preorder_description
      product_warranty
      warranty_duration
      warranty_unit
      warranty_description
      ecategs
      prices{
        id
        price
      }
      quants{
        id
        quantity
      }
    }
  }
}`;