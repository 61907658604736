import { SaleOrderState } from "../../types/global-types";

const SaleOrderStateOptions: {[ key: string] : SaleOrderStateOptionType} = {
    [SaleOrderState.cancel]: { value: SaleOrderState.cancel, text: "Cancel"},
    [SaleOrderState.draft] : {value:SaleOrderState.draft, text:"Draft"},
    [SaleOrderState.done]: { value:SaleOrderState.done, text:"Done"},
    [SaleOrderState.sale]:{value:SaleOrderState.sale, text:"Sale"},
    [SaleOrderState.sent]: { value:SaleOrderState.sent, text:"Sent"}
};

const SaleOrderStateOptionList = Object.keys(SaleOrderStateOptions).map(e => SaleOrderStateOptions[e]);



export type SaleOrderStateOptionType = {
    value: SaleOrderState,
    text: string
};

export  { SaleOrderStateOptions ,  SaleOrderStateOptionList  };