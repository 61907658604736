import { useLazyQuery } from "@apollo/client/react/hooks/useLazyQuery";
import * as React from "react";
import { checkUserCodeExistsQuery } from "./graphql";
import { CheckUserCodeExists, CheckUserCodeExistsVariables } from "./__generated__/CheckUserCodeExists";
import { Users_userQuery_users_edges } from "./__generated__/Users";
import CompanyAutoComplete from "../common/CompanyAutoComplete";
import { Company_commonQuery_companies } from "../common/__generated__/Company";
import TextEditor from "../../components/TextEditor";
import DialogActionLoadingIndicator from "../../components/DialogActionLoadingIndicator";
import green from "@mui/material/colors/green";
import Checkbox, { CheckboxProps } from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";

export type EditDialogProps = {
    initialValue: Users_userQuery_users_edges,
    onSubmit: (value: Users_userQuery_users_edges) => Promise<void>,
    open: boolean;
    onClose: () => void;
    loading: boolean;
}

const _sx = {
    root: {
        color: green[400],
        '&$checked': {
            color: green[600],
        },
    },
}

const GreenCheckbox = ((props: CheckboxProps) => <Checkbox color="default" {...props} />);

function EditDialog({ initialValue, onSubmit, open, onClose, loading }: EditDialogProps) {
    const [draft, setDraft] = React.useState<Users_userQuery_users_edges>(initialValue);
    React.useEffect(() => { setDraft(initialValue); }, [initialValue]);
    const updateDraft = React.useCallback((value: Partial<Users_userQuery_users_edges>) => {
        setDraft({ ...draft, ...value });
    }, [setDraft, draft]);
    const setCompany = React.useCallback((company: Company_commonQuery_companies) => {
        setDraft({ ...draft, companyId: Number.parseInt(company.id!), companyName: company?.name })
    }, [draft, setDraft]);
    const handleSubmit = React.useCallback(async () => {
        await onSubmit(draft);
        onClose();
    }, [draft, onClose, onSubmit]);
    const selectedCompany: Company_commonQuery_companies = { id: draft.companyId?.toString() || null, name: draft?.companyName?.toString() };

    const [errors, setErrors] = React.useState<{ [key: string]: string }>({});

    const [checkUserCodeExists] = useLazyQuery<
        CheckUserCodeExists,
        CheckUserCodeExistsVariables
    >(checkUserCodeExistsQuery, {
        onCompleted: (data) => {
            if (data.userQuery.userCodeExists) {
                setErrors({ ...errors, code: "User code already exists!" });
            } else {
                setErrors({ ...errors, code: "" });
            }
        },
        fetchPolicy: "network-only",
    });

    return (<Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle>User</DialogTitle>
        <DialogContent>
            <Stack spacing={2}>
                <TextEditor
                    size="small"
                    autoFocus
                    margin="dense"
                    name="code"
                    label="Code"
                    value={draft.code}
                    fullWidth
                    onChange={(e) => {
                        updateDraft({ code: e.target.value });
                    }}
                    error={!!errors.code}
                    helperText={errors.code}
                    onValidated={(v) => {
                        updateDraft({ code: v });
                        setErrors({ ...errors, code: "" });
                        if (v)
                            checkUserCodeExists({
                                variables: { id: draft.id, checkFor: v },
                            });
                    }}
                />
                <TextField
                    size="small"
                    margin="dense"
                    name="fullName"
                    label="Full Name"
                    value={draft.fullName}
                    onChange={(e) => {
                        updateDraft({ fullName: e.target.value });
                    }}
                    fullWidth
                />
                <TextField
                    size="small"
                    name="odooUserName"
                    label="Odoo Name"
                    value={draft.odooUserName}
                    onChange={(e) => {
                        updateDraft({ odooUserName: e.target.value });
                    }}
                    fullWidth
                />
                <TextField
                    size="small"
                    name="odooPassword"
                    label="Odoo Password"
                    value={draft.odooPassword}
                    onChange={(e) => {
                        updateDraft({ odooPassword: e.target.value });
                    }}
                    fullWidth
                />
                <CompanyAutoComplete size="small" selectedCompany={selectedCompany} onSelected={setCompany} />
                <FormControlLabel
                    control={
                        <GreenCheckbox
                            sx={_sx.root}
                            checked={draft.active}
                            onChange={(_, checked) => {
                                updateDraft({ active: checked });
                            }}
                            name="active"
                        />
                    }
                    label="Active"
                />
            </Stack>
        </DialogContent>
        <DialogActions>
            <DialogActionLoadingIndicator loading={loading} text="Saving..." />
            <Button onClick={onClose} color="primary">
                Cancel
            </Button>
            <Button onClick={handleSubmit} disabled={loading} color="primary">
                OK
            </Button>
        </DialogActions>
    </Dialog>);
}

export default EditDialog;