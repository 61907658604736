import { ApolloConsumer } from "@apollo/client/react/context/ApolloConsumer";
import { format } from "date-fns";
import * as React from "react";
import { useQuery } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
import ApolloVirtualizedTable, {
  ApolloListResult,
  ApolloVirtualizedTableProps,
  DefaultPageInfoValue,
  GridColumn,
  ListItemRenderProps,
} from "mui-apollo-virtualized-table";
import {
  Promotions,
  PromotionsVariables,
  Promotions_promotionQuery_promotions_edges,
} from "./__generated__/Promotions";
import { promotionsQuery } from "./graphql";
import {
  Divider,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@mui/material";

const PromotionVirtualizedGrid = ApolloVirtualizedTable as React.ElementType<
  ApolloVirtualizedTableProps<Promotions_promotionQuery_promotions_edges>
>;
export type PromotionGridProps = {
  variables: PromotionsVariables;
  onVariablesChanged: (variables: PromotionsVariables) => void;
};

const _sx = {
  loadingIndicator: {
    backgroundColor: "#DDDDDD",
    color: "#DDDDDD",
    width: 150,
    display: "inline",
  },
};

function PromotionGrid({
  variables = { pagination: { pageSize: 40 } },
  onVariablesChanged,
}: PromotionGridProps) {
  const navigate = useNavigate();
  const [columns, setColumns] = React.useState<
    ReadonlyArray<GridColumn<Promotions_promotionQuery_promotions_edges>>
  >([
    {
      label: "Name",
      key: "name",
      width: 100,
      flexGrow: 1,
      sortable: true,
    },
    {
      label: "From",
      key: "date_from",
      width: 120,
      labelAlign: "right",
      sortable: true,
      format: ({ rowData }: any) =>
        rowData?.date_from? format(new Date(rowData?.date_from), "dd/MM/yyyy") : "",
    },
    {
      label: "To",
      key: "date_to",
      labelAlign: "right",
      width: 120,
      sortable: true,
      format: ({ rowData }: any) =>
        rowData?.date_to? format(new Date(rowData?.date_to), "dd/MM/yyyy") : "",
    },
    {
      label: "Income Account",
      key: "reward_product_income_account_id",
      width: 200,
      flexGrow:1,
      sortable: true,
      format: ({ rowData }) =>
        rowData.reward_product_income_account_id?.display_name,
    },
    {
      label: "Created By",
      key: "created_name",
      width: 150,
      sortable: true,
    },
  ]);
  const [selectedIndex, setSelectedIndex] = React.useState(-1);
  const { data: promotionsQueryResult } = useQuery<
    Promotions,
    PromotionsVariables
  >(promotionsQuery, { fetchPolicy: "cache-only", variables });
  const { id: promotionId } = useParams<{ id: string }>();
  const listItemRenderer = React.useCallback(
    (
      renderProps: ListItemRenderProps<Promotions_promotionQuery_promotions_edges>
    ) => {
      const { rowData, key, style, className, onClick } = renderProps;
      if (rowData) {
        const { name, date_from } = rowData;
        return (
          <div onClick={onClick} style={style} className={className} key={key}>
            <ListItem>
              <ListItemText
                  primary={name}
                  secondary={format(new Date(date_from), "dd/MM/yyyy")}
                />
            </ListItem>
            <ListItemSecondaryAction></ListItemSecondaryAction>
            <Divider />
          </div>
        );
      } else {
        return (
          <div style={style} key={key}>
            <ListItem>
              <ListItemText
                primary={
                  <Typography sx={_sx.loadingIndicator}>
                    A...........................
                  </Typography>
                }
                secondary={
                  <Typography sx={_sx.loadingIndicator}>A..........</Typography>
                }
              />
            </ListItem>
            <Divider />
          </div>
        );
      }
    },
    []
  );

  const handleCommandKeyUp = React.useCallback(
    (e: DocumentEventMap["keyup"]) => {
      const products =
        promotionsQueryResult?.promotionQuery?.promotions?.edges ?? [];
      switch (e.key) {
        case "ArrowUp":
          if (promotionId) {
            setSelectedIndex(Math.max(0, selectedIndex - 1));
            e.stopPropagation();
          }
          break;
        case "ArrowDown":
          if (promotionId) {
            setSelectedIndex(Math.min(selectedIndex + 1, products.length));
            e.stopPropagation();
          }
          break;
      }
    },
    [
      setSelectedIndex,
      selectedIndex,
      promotionsQueryResult?.promotionQuery?.promotions?.edges,
      promotionId,
    ]
  );
  React.useEffect(() => {
    document.addEventListener("keyup", handleCommandKeyUp);
    return () => {
      document.removeEventListener("keyup", handleCommandKeyUp);
    };
  }, [handleCommandKeyUp]);

  return (
    <ApolloConsumer>
      {(client) => (
        <PromotionVirtualizedGrid
          onRowClick={(item, index) => {
            navigate(`/promotions/${item.id}`);
          }}
          apolloClient={client as any}
          listItemHeight={72}
          listItemRenderer={listItemRenderer}
          listModeBreakPoint={320}
          onColumnPropsChanged={(columns, orderBy: any) => {
            const newVariables: PromotionsVariables = {
              ...variables,
              orderBy,
            };
            setColumns(columns);
            onVariablesChanged(newVariables);
          }}
          selectedItems={[selectedIndex]}
          scrollToIndex={selectedIndex}
          columns={columns}
          graphqlQuery={promotionsQuery}
          variables={variables}
          pageSize={variables.pagination?.pageSize!}
          parseListFromQueryResult={(queryResult: Promotions) => {
            const list: ApolloListResult<Promotions_promotionQuery_promotions_edges> =
              queryResult.promotionQuery.promotions ?? {
                edges: [],
                pageInfo: DefaultPageInfoValue,
              };
            return list;
          }}
          onLoadMore={(pageInfo) => {
            return {
              ...variables,
              pagination: {
                page: pageInfo.page,
                pageSize: pageInfo.pageSize,
              },
            };
          }}
        />
      )}
    </ApolloConsumer>
  );
}

export default PromotionGrid;
