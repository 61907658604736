import AppBar from "@mui/material/AppBar/AppBar";
import Grid from "@mui/material/Grid/Grid";
import Toolbar from "@mui/material/Toolbar/Toolbar";
import Typography from "@mui/material/Typography/Typography";
import * as React from "react";
import update from "immutability-helper";
import { SaleOrderStateOptions } from "./saleOrderStateOptions";
import {
  Outlet,
  useMatch,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import Tooltip from "@mui/material/Tooltip/Tooltip";
import IconButton from "@mui/material/IconButton/IconButton";
import Box from "@mui/material/Box/Box";
import SaleOrderGrid from "./SaleOrderGrid";
import Add from "@mui/icons-material/Add";
import ArrowBack from "@mui/icons-material/ArrowBack";
import { useSelector } from "react-redux";
import SaleOrderStateIndicator from "./SaleOrderStateIndicator";
import Chip from "@mui/material/Chip/Chip";
import resolveInvoiceStatusIndicator from "./resolveInvoiceStatusIndicator";
import { SaleOrdersVariables } from "./__generated__/SaleOrders";
import { SaleOrderOrderBy } from "../../types/global-types";
import DrawerToggleButton from "../../app/DrawerToggleButton";
import Hidden from "@mui/material/Hidden";
import CsSearchBox, { OperandType } from "../../components/CsSearchBox";
import ErrorMessageDialog from "../../components/ErrorMessageDialog";
import { RootState } from "../../app/store";
import Close from "@mui/icons-material/Close";
const _sx = {

  title: {
    marginRight: 1,
  },
  box: {
    backgroundColor: "rgba(255,255,255,.1)",
  },
  root: {
    flex: 1,
    display: "flex",
  },
};

export type SaleOrderOutletContextType = {
  order_id?: number;
  saleOrdersVariables?: SaleOrdersVariables
};

export function useSaleOrderOutletContext() {
  return useOutletContext<SaleOrderOutletContextType>();
}

function SaleOrdersBrowser() {
  const { id: order_id } = useParams<{ id: string }>();
  const [variables, setVariables] = React.useState<SaleOrdersVariables>({
    pagination: { pageSize: 50, page: 1 },
    orderBy: [SaleOrderOrderBy.name_Desc]
  });
  const [operationError, setOperationError] = React.useState<Error | null>(null);
  const [operationErrorMessage, setOperationErrorMessage] =
    React.useState<string>("");
  const [errorMessageDialogOpen, setErrorMessageDialogOpen] =
    React.useState(false);
  const navigate = useNavigate();
  const handleCommandKeyUp = React.useCallback(
    (e: KeyboardEvent) => {
      switch (e.key) {
        case "F9":
          navigate("/sale_orders/create");
          break;
      }
    },
    [navigate]
  );
  const handleFunctionKeyDown = React.useCallback((e: KeyboardEvent) => {
    switch (e.key) {
      case "F1":
      case "F10":
      case "F3":
      case "F12":
        e.preventDefault();
        break;
    }
  }, []);
  const orderNo = useSelector((state: RootState) => state.saleOrder.name);
  const saleOrderState = useSelector((state: RootState) => state.saleOrder.state);
  const saleOrderInvoiceStatus = useSelector((state: RootState) => state.saleOrder.invoice_status);
  React.useEffect(() => {
    document.addEventListener("keyup", handleCommandKeyUp);
    window.addEventListener("keydown", handleFunctionKeyDown);
    return () => {
      document.removeEventListener("keyup", handleCommandKeyUp);
      window.removeEventListener("keydown", handleFunctionKeyDown);
    };
  }, [handleCommandKeyUp, handleFunctionKeyDown]);
  const isSaleOrderRoute = useMatch("sale_orders/:id");
  const isCreateRoute = useMatch("sale_orders/create");
  const isRenewRoute = useMatch("sale_orders/renew");
  const isSaleOrdersRoute = useMatch("sale_orders");

  const isViewRoute = isSaleOrderRoute || isCreateRoute || isRenewRoute;
  return (
    <>
      <Grid container direction="column">
        <AppBar position="static" >
          <Toolbar sx={(theme) => ({
            [theme.breakpoints.up("md")]: {
              minHeight: 48,
            },
            paddingLeft: 1.5,
          })}>
            <DrawerToggleButton />
            {
              isSaleOrderRoute ? (<>
                <Hidden xlUp>
                  <Tooltip title="Back to orders">
                    <IconButton
                      color="inherit"
                      onClick={() => {
                        navigate("/sale_orders");
                      }}
                    >
                      <ArrowBack />
                    </IconButton>
                  </Tooltip>
                </Hidden>
                <Typography
                  variant="h6"
                  sx={_sx.title}
                  color="inherit"
                  noWrap
                >
                  {orderNo ? orderNo : "New"}
                </Typography>
                <SaleOrderStateIndicator
                  state={saleOrderState}
                />
                <Chip
                  size="small"
                  label={resolveInvoiceStatusIndicator(saleOrderInvoiceStatus)}
                />
              </>) : null
            }

            {
              isSaleOrdersRoute ? (<>
                <Typography
                  variant="h6"
                  sx={_sx.title}
                  color="inherit"
                  noWrap
                >
                  Sale Orders
                </Typography>
                <CsSearchBox
                  onConditionChanged={(conditions: any) => {
                    setVariables(
                      update(variables, {
                        where: {
                          $set: { aND: conditions },
                        },
                      })
                    );
                  }}
                  operands={{
                    name: {
                      name: "Order #",
                      propName: "name",
                      type: OperandType.STRING,
                    },
                    partner_id: {
                      name: "Customer",
                      propName: "partner_id",
                      type: OperandType.STRING,
                    },
                    date_order: {
                      name: "Date",
                      propName: "date_order",
                      type: OperandType.DATE,
                    },
                    amount_total: {
                      name: "Total Amount",
                      propName: "amount_total",
                      type: OperandType.NUMBER,
                    },
                    state: {
                      name: "Order State",
                      propName: "state",
                      type: OperandType.ENUM,
                      enumOptions: SaleOrderStateOptions,
                    },
                    origin: {
                      name: "Saleperson",
                      propName: "origin",
                      type: OperandType.STRING,
                    },
                  }}
                />
                <Tooltip title="New order">
                  <IconButton
                    color="inherit"
                    onClick={() => {
                      navigate("/sale_orders/create");
                    }}
                  >
                    <Add />
                  </IconButton>
                </Tooltip>
              </>) : null
            }

            {
              isSaleOrderRoute ? (<Hidden lgDown>
                <CsSearchBox
                  onConditionChanged={(conditions: any) => {
                    setVariables(
                      update(variables, {
                        where: {
                          $set: { aND: conditions },
                        },
                      })
                    );
                  }}
                  operands={{
                    name: {
                      name: "Order #",
                      propName: "name",
                      type: OperandType.STRING,
                    },
                    partner_id: {
                      name: "Customer",
                      propName: "partner_id",
                      type: OperandType.STRING,
                    },
                    date_order: {
                      name: "Date",
                      propName: "date_order",
                      type: OperandType.DATE,
                    },
                    amount_total: {
                      name: "Total Amount",
                      propName: "amount_total",
                      type: OperandType.NUMBER,
                    },
                    state: {
                      name: "Order State",
                      propName: "state",
                      type: OperandType.ENUM,
                      enumOptions: SaleOrderStateOptions,
                    },
                    origin: {
                      name: "Saleperson",
                      propName: "origin",
                      type: OperandType.STRING,
                    },
                  }}
                />
                <Tooltip title="Close sale order view.">
                  <IconButton
                    color="inherit"
                    onClick={() => {
                      navigate("/sale_orders");
                    }}
                  >
                    <Close />
                  </IconButton>
                </Tooltip>
              </Hidden>) : null
            }
          </Toolbar>
        </AppBar>
        <Box
          sx={_sx.box}
          flex={1}
          display="flex"
          flexDirection="row"
        >
          <Box
            sx={{
              minWidth: 300,
              flex: isViewRoute ? 0 : 1,
              display: {xs:isViewRoute? "none":"flex", xl:"flex"}
            }}
          >
            <SaleOrderGrid
              variables={variables}
              onVariablesChanged={setVariables}
            />
          </Box>
          <Outlet context={{ order_id, saleOrderVariables: variables }} />
        </Box>
      </Grid>
      {operationError ? (
        <ErrorMessageDialog
          open={errorMessageDialogOpen}
          onClose={() => {
            setErrorMessageDialogOpen(false);
          }}
          error={{
            title:"Error",
            message: operationErrorMessage,
            detail:operationError.message
        }}
        />
      ) : null}
    </>
  );
}


export default SaleOrdersBrowser;