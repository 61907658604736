import { useMutation, useQuery } from "@apollo/client";
import { Check, Close } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import accounting from "accounting";
import {
  CheckBoxColumnMode,
  GridColumn,
  VirtualizedTable,
} from "mui-apollo-virtualized-table";
import React from "react";
import { useSelector } from "react-redux";
import { useStockPickingOutletContext } from ".";
import LabelPrinter, { LabelData } from "../../../app/LabelPrinter";
import { RootState } from "../../../app/store";
import ActivityIndicatorDialog from "../../../components/ActivityIndicatorDialog";
import NumberEditor from "../../../components/NumberEditor";
import TextEditor from "../../../components/TextEditor";
import {
  StockMoveLineInput,
  StockPickingShippingPolicy,
  StockPickingState,
  StockPickingType,
  StockTrackingType,
} from "../../../types/global-types";
import {
  checkAvailabilityForPickingMutation,
  saveStockMoveMutation,
  stockPickingQuery,
  unreservePickingMutation,
  validateStockPickingMutation,
} from "../../sale/graphql";
import {
  CheckAvailabilityForPicking,
  CheckAvailabilityForPickingVariables,
} from "../../sale/__generated__/CheckAvailabilityForPicking";
import {
  SaveStockMove,
  SaveStockMoveVariables,
} from "../../sale/__generated__/SaveStockMove";
import {
  StockPicking,
  StockPickingVariables,
  StockPicking_inventoryQuery_picking,
  StockPicking_inventoryQuery_picking_moves_edges,
} from "../../sale/__generated__/StockPicking";
import {
  UnreservePicking,
  UnreservePickingVariables,
} from "../../sale/__generated__/UnreservePicking";
import {
  ValidateStockPicking,
  ValidateStockPickingVariables,
} from "../../sale/__generated__/ValidateStockPicking";
import StockPickingHeader from "./StockPickingHeader";
function PadLeft(n: string, width: number, z: string = "0") {
  z = z || "0";
  n = n + "";
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}
export type StockPickingEditControlProps = {
  picking_id: string;
  loading: boolean;
};

function StockPickingEditControl({
  picking_id,
  loading,
}: StockPickingEditControlProps) {

  const { onError, setEnablePrintLabel, unRegisterPrintLabelAction, registerPrintLabelAction } = useStockPickingOutletContext();
  const labelPrinterUrl = useSelector((state: RootState) => state.site.labelPrinterUrl)
  const labelPrinter = React.useRef<LabelPrinter>(
    new LabelPrinter(labelPrinterUrl)
  );
  const [printList, setPrintList] = React.useState<{
    [index: number]: number | undefined;
  }>({});
  const columns = React.useRef<
    ReadonlyArray<GridColumn<StockPicking_inventoryQuery_picking_moves_edges>>
  >([
    {
      label: "Label Qty",
      key: "label_qty",
      width: 100,
      textAlign: "right",
      format: ({ rowData, index, extraData: { printList, selected } }) => (
        <NumberEditor
          numberPrecision={0}
          size="small"
          disabled={!selected.includes(index)}
          value={printList[index] ?? 0}
          onValidated={(qty) => {
            setPrintList({ ...printList, [index]: qty });
          }}
          onValidating={(value) => {
            return (value ?? 0) > -1
              ? ""
              : "Value is invalid";
          }}
        />
      ),
    },
    {
      label: "Model #",
      key: "name",
      width: 150,
      format: ({ rowData }) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, code] =
          rowData.product_id.display_name?.match(/\[(.*?)\]/) ?? [];
        return code;
      },
    },
    {
      label: "Description",
      key: "description",
      width: 200,
      flexGrow: 1,
      format: ({ rowData }) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, code] =
          rowData.product_id.display_name?.match(/\[(.*?)\]/) ?? [];
        const name = rowData.product_id.display_name?.replace(`[${code}] `, "");
        return name;
      },
    },
    {
      label: "Serial No",
      key: "serialNo",
      width: 180,
      format: ({ rowData, extraData: { picking } }) => {
        const [moveLine] = rowData?.move_lines?.edges ?? [];
        return (
          <TextEditor
            size="small"
            extraData={moveLine?.lot_name ?? undefined}
            value={moveLine?.lot_id?.display_name ?? ""}
            disabled={rowData?.has_tracking === StockTrackingType.none}
            InputProps={{
              readOnly:
                !rowData.show_operations ||
                picking.state === StockPickingState.cancel ||
                picking.state === StockPickingState.done,
            }}
            onValidated={(text) => {
              if (text) handleUpdateSerialNo(picking, rowData, text);
            }}
          />
        );
      },
    },
    {
      label: "Demand",
      key: "product_uom_qty",
      width: 100,
      textAlign: "right",
      format: ({ rowData }) =>
        `${accounting.formatNumber(rowData.product_uom_qty, 0)} ${rowData.product_uom.display_name
        }`,
    },
    {
      label: "Reserved",
      key: "reserved_availability",
      width: 100,
      textAlign: "right",
      format: ({ rowData }) =>
        `${accounting.formatNumber(rowData.reserved_availability ?? 0, 0)} ${rowData.product_uom.display_name
        }`,
    },
    {
      label: "Done",
      key: "quantity_done",
      width: 150,
      textAlign: "right",
      format: ({ rowData, index, extraData: { picking } }) => (
        <NumberEditor
          numberPrecision={0}
          size="small"
          disabled={!rowData.reserved_availability}
          InputProps={{
            readOnly:
              !rowData.show_operations ||
              picking.state === StockPickingState.cancel ||
              picking.state === StockPickingState.done,
          }}
          value={rowData.quantity_done}
          onValidated={(qty) => {
            handleUpdateDoneQty(picking, rowData, qty ?? 0);
          }}
          onValidating={(value) => {
            return (value ?? 0) <= (rowData.reserved_availability ?? 0) &&
              (value ?? 0) > -1
              ? ""
              : "Value is invalid";
          }}
        />
      ),
    },
    {
      label: "",
      key: "command",
      width: 100,
      textAlign: "left",
      format: ({ rowData, extraData: { picking } }) => (
        <IconButton
          disabled={
            !rowData.show_operations ||
            picking.state === StockPickingState.cancel ||
            picking.state === StockPickingState.done ||
            !rowData.reserved_availability
          }
          onClick={() => {
            if (rowData.quantity_done) {
              handleUpdateDoneQty(picking, rowData, 0);
            } else if (rowData.reserved_availability !== null) {
              handleUpdateDoneQty(
                picking,
                rowData,
                rowData.reserved_availability
              );
            }
          }}
        >
          {rowData.quantity_done ? (
            <Close color="error" />
          ) : (
            <Check color="action" />
          )}
        </IconButton>
      ),
    },
  ]);
  const { data: queryResult } = useQuery<StockPicking, StockPickingVariables>(
    stockPickingQuery,
    {
      variables: {
        id: Number.parseInt(picking_id!),
        pagination: { pageSize: 1000 },
      },
      skip: !picking_id,
    }
  );
  const picking = queryResult?.inventoryQuery?.picking;
  const moves = picking?.moves?.edges ?? [];

  const [selected, setSelected] = React.useState<ReadonlyArray<number>>([]);
  const [selectAll, setSelectAll] = React.useState(false);
  const handleSetSelected = React.useCallback((selected: number[]) => {
    const moves = picking?.moves?.edges ?? [];
    const newPrintList = selected.reduce<{ [index: number]: number | undefined }>((previous, current) => {
      previous[current] = printList[current] ?? moves[current]?.quantity_done ?? 0;
      return previous;
    }, {});
    setSelected(selected);
    setEnablePrintLabel(selected.length > 0)
    setPrintList(newPrintList);
  }, [picking?.moves?.edges, printList, setEnablePrintLabel]);
  const handleSelectAll = React.useCallback((selected: number[]) => {
    setSelectAll(true);
    handleSetSelected(selected);
  }, [handleSetSelected, setSelectAll]);
  const handleClearSelectAll = React.useCallback(() => {
    setSelectAll(false);
    handleSetSelected([]);
  }, [handleSetSelected, setSelectAll]);

  const [checkAvailibility, { loading: checkingAvailability }] = useMutation<
    CheckAvailabilityForPicking,
    CheckAvailabilityForPickingVariables
  >(checkAvailabilityForPickingMutation);
  const [unReserve, { loading: unreserving }] = useMutation<
    UnreservePicking,
    UnreservePickingVariables
  >(unreservePickingMutation);
  const [saveStockMove, { loading: savingStockMove }] = useMutation<
    SaveStockMove,
    SaveStockMoveVariables
  >(saveStockMoveMutation);
  const [validateStockPicking, { loading: validating }] = useMutation<
    ValidateStockPicking,
    ValidateStockPickingVariables
  >(validateStockPickingMutation);
  const handleUpdateDoneQty = React.useCallback(
    (
      picking: StockPicking_inventoryQuery_picking,
      {
        id,
        product_id,
        product_uom,
        move_lines,
      }: StockPicking_inventoryQuery_picking_moves_edges,
      qtyDone: number
    ) => {
      const [firstModeLine] = move_lines?.edges ?? [];
      const { owner_id, location_id, location_dest_id } = picking;
      const moveLine: StockMoveLineInput = {
        owner_id: owner_id ? Number.parseInt(owner_id.id) : undefined,
        location_dest_id: Number.parseInt(location_dest_id.id),
        location_id: Number.parseInt(location_id.id),
        product_id: Number.parseInt(product_id.id),
        product_uom_id: Number.parseInt(product_uom.id),
        qty_done: qtyDone,
      };
      if (firstModeLine?.id) {
        moveLine.id = firstModeLine.id.toString();
      }
      if (firstModeLine?.lot_id?.id) {
        moveLine.location_id = Number.parseInt(firstModeLine.lot_id.id);
      }
      const variables: SaveStockMoveVariables = {
        stockMove: {
          id: Number.parseInt(id),
          move_line_nosuggest_ids: {
            list: [moveLine],
          },
        },
      };
      saveStockMove({ variables });
    },
    [saveStockMove]
  );

  const disableUnReserve = React.useMemo(() => {
    return (
      !picking ||
      picking.picking_type_code === StockPickingType.incoming ||
      picking.immediate_transfer ||
      (picking.move_type === StockPickingShippingPolicy.one &&
        [
          StockPickingState.assigned,
          StockPickingState.partially_available,
          StockPickingState.confirmed,
        ].indexOf(picking.state) === -1) ||
      (picking.move_type !== StockPickingShippingPolicy.one &&
        [
          StockPickingState.assigned,
          StockPickingState.partially_available,
        ].indexOf(picking.state) === -1)
    );
  }, [picking]);

  const handleUpdateSerialNo = React.useCallback(
    (
      picking: StockPicking_inventoryQuery_picking,
      {
        id,
        product_id,
        product_uom,
        move_lines,
        quantity_done,
      }: StockPicking_inventoryQuery_picking_moves_edges,
      lot_name: string
    ) => {
      const [firstModeLine] = move_lines?.edges ?? [];
      const { owner_id, location_id, location_dest_id } = picking;
      const moveLine: StockMoveLineInput = {
        owner_id: owner_id ? Number.parseInt(owner_id.id) : undefined,
        location_dest_id: Number.parseInt(location_dest_id.id),
        location_id: Number.parseInt(location_id.id),
        product_id: Number.parseInt(product_id.id),
        product_uom_id: Number.parseInt(product_uom.id),
        qty_done: quantity_done,
        lot_name,
      };
      if (firstModeLine?.id) {
        moveLine.id = firstModeLine.id.toString();
      }
      const variables: SaveStockMoveVariables = {
        stockMove: {
          id: Number.parseInt(id),
          move_line_nosuggest_ids: {
            list: [moveLine],
          },
        },
      };
      saveStockMove({ variables });
    },
    [saveStockMove]
  );
  const handleValidate = React.useCallback(async () => {
    if (!picking_id) return;
    try {
      await validateStockPicking({
        variables: {
          id: Number.parseInt(picking_id),
          forceBackOrder: true,
          forceImmediateTransfer: true,
        },
      });
    } catch { }
  }, [validateStockPicking, picking_id]);
  const handleCheckAvailability = React.useCallback(() => {
    if (picking?.id)
      checkAvailibility({ variables: { id: Number.parseInt(picking.id) } });
  }, [checkAvailibility, picking?.id]);
  const handleUnreserve = React.useCallback(() => {
    if (picking?.id)
      unReserve({ variables: { id: Number.parseInt(picking.id) } });
  }, [picking?.id, unReserve]);
  const loaderCacheResetor = React.useRef<null | (() => void)>(null);


  const [labelPrinting, setLabelPrinting] = React.useState(false);
  const handlePrintLabels = React.useCallback(async () => {
    if (labelPrinting)
      return;
    const moves = picking?.moves?.edges ?? [];
    if (!moves?.length || !selected.length)
      return;
    const labelDatas: LabelData[] = [];
    try {
      setLabelPrinting(true);
      for (const key in printList) {
        if (Object.prototype.hasOwnProperty.call(printList, key)) {
          const element = printList[key];
          if (element) {
            const { product_id } = moves[key];
            const [_, StockCode] =
              product_id?.display_name?.match(/\[(.*?)\]/) ?? [];
            let ownerId = picking?.owner?.ref ?? "999";
            let productId = PadLeft(product_id.id, 7);
            const labelData: LabelData = {
              StockCode,
              PurchaseDate: new Date(picking?.date_done ?? new Date()),
              SerialNo: `${ownerId}${productId}`,
              VoucherNo: picking?.name ?? "",
              Copy: element ?? 1,
            };
            labelDatas.push(labelData);
          }
        }
      }
      await labelPrinter.current.print(labelDatas);
    } catch (e: any) {
      onError(e, "Could not print label.");
    } finally {
      setLabelPrinting(false);
    }
  }, [labelPrinting, onError, picking?.date_done, picking?.moves?.edges, picking?.name, picking?.owner?.ref, printList, selected.length]);

  React.useEffect(() => {
    setSelected([]);
    setPrintList({});
    setSelectAll(false);
  }, [picking]);

  React.useEffect(() => {
    registerPrintLabelAction(handlePrintLabels);
    return () => { unRegisterPrintLabelAction() }
  }, [handlePrintLabels, registerPrintLabelAction, unRegisterPrintLabelAction]);

  return (
    <>
      <VirtualizedTable
        extraData={{ picking, printList, selected }}
        displayRowCount={false}
        registerForLoaderCacheReset={(resetor: () => void) => {
          loaderCacheResetor.current = resetor;
        }}
        checkBoxColumnMode={CheckBoxColumnMode.first}
        selectedItems={selected}
        setSelectedItems={handleSetSelected}
        selectedAll={selectAll}
        setSelectedAll={handleSelectAll}
        clearSelectedAll={handleClearSelectAll}
        loading={loading}
        listModeBreakPoint={0}
        rowGetter={(index: number) => {
          return moves[index];
        }}
        totalRowCount={moves.length}
        rowCount={moves.length}
        isRowLoaded={(index: number) => !!moves[index]}
        columns={columns.current}
        pageSize={1000}
        initialLoading={loading}
        headerComponent={
          <div className="bg-down-1">
            <StockPickingHeader
              picking={picking!}
              disableUniverse={
                loading ||
                savingStockMove ||
                validating ||
                checkingAvailability ||
                unreserving ||
                disableUnReserve
              }
              disableCheckAvailability={
                loading ||
                savingStockMove ||
                validating ||
                checkingAvailability ||
                unreserving ||
                !picking?.show_check_availability
              }
              disabledProcess={
                loading ||
                savingStockMove ||
                validating ||
                checkingAvailability ||
                unreserving ||
                !picking?.show_validate
              }
              handleUnreserve={handleUnreserve}
              handleCheckAvailability={handleCheckAvailability}
              handleValidate={handleValidate}
            />
          </div>
        }
      />
      <ActivityIndicatorDialog open={labelPrinting} message="Printing..." />
    </>
  );
}

export default StockPickingEditControl;
