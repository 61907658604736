import Button from "@mui/material/Button/Button";
import Dialog from "@mui/material/Dialog/Dialog";
import DialogActions from "@mui/material/DialogActions/DialogActions";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import DialogTitle from "@mui/material/DialogTitle/DialogTitle";
import * as React from "react";
import JournalAutoComplete from "../common/JournalAutoComplete";
import { ApolloError, useMutation } from "@apollo/client";
import config from "../../config.json";
import {
  MakePaymentMutation,
  MakePaymentMutationVariables,
} from "./__generated__/MakePaymentMutation";
import { makeInitialPaymentMutation, makePaymentMutation } from "./graphql";
import Grid from "@mui/material/Grid/Grid";
import { useDispatch, useSelector } from "react-redux";
import {
  MakeInitialPaymentMutation,
  MakeInitialPaymentMutationVariables,
} from "./__generated__/MakeInitialPaymentMutation";
import { SaleOrder_saleOrderQuery_saleOrder } from "./__generated__/SaleOrder";
import { Journals_commonQuery_journals } from "../common/__generated__/Journals";
import FormControlLabel from "@mui/material/FormControlLabel/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup/RadioGroup";
import Radio from "@mui/material/Radio/Radio";
import { format } from "date-fns";
import { RootState } from "../../app/store";
import useHasPermission from "../../app/useHasPermission";
import { saleOrderActions } from "./saleOrdersSlice";
import { Optional } from "utility-types";
import { useNavigate } from "react-router-dom";
import CurrencyEditor from "../../components/CurrencyEditor";
import { JournalType } from "../../types/global-types";
import DialogActionLoadingIndicator from "../../components/DialogActionLoadingIndicator";
import { DatePicker } from "@mui/x-date-pickers";
import TextField from "@mui/material/TextField";

export type PaymentDialogProps = {
  saleOrderId: number;
  open: boolean;
  onClose: () => void;
  onError: (error: ApolloError) => void;
  amountDue: number;
  isInitialPayment: boolean;
};

const _sx={
  formControl: {
    marginTop: 1,
    marginBottom: 1,
  },
  content: {
    overflowY: "hidden",
  },
  radioGroup: {
    flexDirection: "row"
  }
};

function PaymentDialog({
  open,
  saleOrderId,
  onClose,
  onError,
  amountDue,
  isInitialPayment,
}: PaymentDialogProps) {
  const savedCashJournal = useSelector(
    (root: RootState) => root.saleSetting.cashJournal
  );
  const allowedOtherCashAccount = useHasPermission("Sale_AccessOtherCashAccount");
  const [bankJournal, setBankJournal] = React.useState<Journals_commonQuery_journals | null>(null);
  const [cashJournal, setCashJournal] = React.useState<Journals_commonQuery_journals | null>(savedCashJournal);
  const [isCashJournal, setIsCashJournal] = React.useState(!!cashJournal);
  const dispatch = useDispatch();
  const saleTeam = useSelector((state: RootState) => state.saleSetting.saleTeam);
  const setSaleOrder = React.useCallback(
    (saleOrder: Partial<SaleOrder_saleOrderQuery_saleOrder>) => {
      dispatch(saleOrderActions.setSaleOrder(saleOrder));
    },
    [dispatch]
  );
  const [makePayment, { loading: makingPayment }] =
    useMutation<MakePaymentMutation, MakePaymentMutationVariables>(
      makePaymentMutation,
      {
        onCompleted: (data) => {
          const saleOrder = data?.saleOrderMutation?.makePayment;
          if(saleOrder)
            setSaleOrder(saleOrder);
        },
        onError
      }
    );
  const [
    makeInitialPayment,
    { loading: makingInitialPayment },
  ] = useMutation<
    MakeInitialPaymentMutation,
    MakeInitialPaymentMutationVariables
  >(makeInitialPaymentMutation, {
    onCompleted: (data) => {
      const saleOrder = data?.saleOrderMutation?.makeInitialPayment;
      if(saleOrder)
      setSaleOrder(saleOrder);
    },
    onError
  });

  const [amount, setAmount] = React.useState(Math.abs(amountDue));
  const [date, setDate] = React.useState(new Date());
  React.useEffect(() => {
    if (open) {
      setDate(new Date());
      setCashJournal(savedCashJournal)
    }
  }, [open, savedCashJournal]);
  React.useEffect(() => {
    setAmount(Math.abs(amountDue));
  }, [amountDue]);
  const navigate = useNavigate();
  const submit = React.useCallback(async () => {
    const make = (variables: MakePaymentMutationVariables) => {
      if (isInitialPayment) {
        return makeInitialPayment({ variables });
      } else {
        return makePayment({ variables });
      }
    }
    const variables: Optional<MakePaymentMutationVariables,"team_id"|"journalId"> = {
      id: saleOrderId,
      amount: Math.min(Math.abs(amountDue), amount),
      date: format(date, "yyyy-MM-dd")
    };
    if (saleTeam) {
      variables.team_id = Number.parseInt(saleTeam.id);
    } else {
     navigate("/settings/sale");
    }
    if (isCashJournal && cashJournal) {
      variables.journalId = Number.parseInt(cashJournal.id);
      await make(variables as MakePaymentMutationVariables);
      onClose();
    } else if (!isCashJournal && bankJournal) {
      variables.journalId = Number.parseInt(bankJournal.id);
      await make(variables as MakePaymentMutationVariables);
      onClose();
    }
  }, [saleOrderId, amountDue, amount, date, saleTeam, isCashJournal, cashJournal, bankJournal, isInitialPayment, makeInitialPayment, makePayment, navigate, onClose]);
 
  const handleCommandKeyUp: React.KeyboardEventHandler<HTMLElement> =
    React.useCallback(
      (e) => {
        switch (e.key) {
          case "Escape":
            if (!(makingPayment || makingInitialPayment)) {
              onClose();
            }
            e.stopPropagation();
            break;
          case "Enter":
            if (
              !makingPayment &&
              !makingInitialPayment &&
              ((isCashJournal && cashJournal) || (!isCashJournal && bankJournal)) &&
              amount
            )
              submit();
            e.stopPropagation();
            break;
        }
      },
      [makingPayment, makingInitialPayment, isCashJournal, cashJournal, bankJournal, amount, submit, onClose]
    );

  const handleIsCashJournalValueChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setIsCashJournal(JSON.parse(e.target.value));
  }, [setIsCashJournal]);
  return (
    <Dialog onKeyUp={handleCommandKeyUp} maxWidth="sm" fullWidth open={open}>
      <DialogTitle>{amountDue < 0 ? "Refund" : "Receive payment"}</DialogTitle>
      <DialogContent sx={_sx.content}>
        <Grid container direction="column">
          <DatePicker
          renderInput={(props) => (<TextField {...props} size="small" />)}
          label="Payment Date"
          InputProps={{ sx: _sx.formControl }}
          value={date}
          onChange={(date:any)=>{setDate(date)}}
          inputFormat={config.shortDateFormat}
          />
          <CurrencyEditor
            autoFocus
            sx={_sx.formControl}
            label="Amount"
            value={amount}
            onChanged={(value) => {
              if (value !== undefined && value > -1) {
                setAmount(value);
              }
            }}
          />
          <RadioGroup value={isCashJournal} onChange={handleIsCashJournalValueChange} sx={_sx.radioGroup}>
            {cashJournal ? <FormControlLabel value={true} control={<Radio />} label={cashJournal.name} /> : null}
            <FormControlLabel value={false} control={<Radio />} label="Bank" />
          </RadioGroup>
          {
            !isCashJournal || allowedOtherCashAccount ? <JournalAutoComplete
              sx={_sx.formControl}
              onSelected={isCashJournal ? setCashJournal : setBankJournal}
              journalTypes={[
                isCashJournal ? JournalType.cash : JournalType.bank,
              ]}
              label={isCashJournal ? "Cash Account" : "Bank Account"}
              selectedJournal={isCashJournal ? cashJournal : bankJournal}
            /> : null
          }

          <CurrencyEditor
            aria-readonly
            inputProps={{
              readOnly: true,
            }}
            sx={_sx.formControl}
            label="Balance"
            value={Math.abs(amountDue) - amount}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <DialogActionLoadingIndicator
          loading={makingPayment || makingInitialPayment}
          text="Saving payment..."
        />
        <Button
          disabled={
            makingPayment || makingInitialPayment || !((isCashJournal && cashJournal) || (!isCashJournal && bankJournal)) || !amount
          }
          onClick={submit}
          color="secondary"
        >
          OK
        </Button>
        <Button disabled={makingPayment} onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default PaymentDialog;
