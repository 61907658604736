import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box/Box";
import Container from "@mui/material/Container/Container";
import Grid from "@mui/material/Grid/Grid";
import { RootState } from "../../app/store";
import Typography from "@mui/material/Typography";
import { setOdooServerUrl, setSaleInvoiceReportId } from "../site/siteSlice";
import TextEditor from "../../components/TextEditor";
import Stack from "@mui/material/Stack";

const _sx = {
  scroller: {
    flex: 1,
    overflow: "auto",
    backgroundColor: "rgba(0,0,0,.05)"
  },
  content: {
    marginTop: 2,
    paddingBottom: 2,
    backgroundColor: "background.default"
  },
  settingTitle: {
    marginTop: 1,
  },
};
function GeneralSetting() {
  const odooServerUrl = useSelector((state: RootState) => state.site.odooServerUrl);
  const invoiceReportId = useSelector((state: RootState) => state.site.saleInvoiceReportId)
  const dispatch = useDispatch();
  const editOdooServerUrl = React.useCallback(
    (url: string) => {
      dispatch(setOdooServerUrl(url));
    },
    [dispatch]
  );

  const editInvoiceReportId = React.useCallback(
    (id: string) => {
      dispatch(setSaleInvoiceReportId(id));
    },
    [dispatch]
  );

  return (
    <Box sx={_sx.scroller}>
      <Container maxWidth="lg">
        <Typography sx={_sx.settingTitle} variant="h6">
          General
        </Typography>
        <Box
          flexDirection="column"
          boxShadow={1}
          p={2}
          display="flex"
          flexWrap="wrap"
          sx={_sx.content}
        >
          <Stack spacing={2}>
            <TextEditor size="small" fullWidth value={odooServerUrl} onValidated={editOdooServerUrl} placeholder="Odoo server url" label="Odoo server url" />
            <TextEditor size="small" fullWidth value={invoiceReportId} onValidated={editInvoiceReportId} placeholder="Sale invoice report id" label="Sale invoice report id" />
          </Stack>
        </Box>
      </Container>
    </Box>
  );
}

export default GeneralSetting;
