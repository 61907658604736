import { useMutation } from "@apollo/client";
import { Close, Edit } from "@mui/icons-material";
import { Box, Checkbox, IconButton } from "@mui/material";
import accounting from "accounting";
import { GridColumn, VirtualizedTable } from "mui-apollo-virtualized-table";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../app/store";
import ErrorMessageDialog from "../../components/ErrorMessageDialog";
import update from "immutability-helper";
import CouponProgramDialog from "./CouponProgramDialog";
import { savePromotionMutation, promotionsQuery } from "./graphql";
import DiscountValueEditor, { DiscountValue } from "./DiscountValueEditor";
import PromotionHeader from "./PromotionHeader";
import {
  Promotion_promotionQuery_promotion, Promotion_promotionQuery_promotion_loyalty_programs
} from "./__generated__/Promotion";
import { Promotions, PromotionsVariables } from "./__generated__/Promotions";
import {
  SavePromotion,
  SavePromotionVariables,
} from "./__generated__/SavePromotion";
import {
  promotionActions,
} from "./promotionsSlice";
import { DiscountMode, PromotionInput, RewardType } from "../../types/global-types";
import format from "date-fns/format";

export type PromotionEditControlProps = {
  loading: boolean;
  promotionsVariables?: PromotionsVariables;
};

function PromotionEditControl({
  loading,
  promotionsVariables,
}: PromotionEditControlProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const promotion = useSelector((state: RootState) => state.promotion);
  const loyalty_programs = promotion.loyalty_programs;
  const [operationError, setOperationError] = React.useState<Error | null>(
    null
  );
  const [operationErrorMessage, setOperationErrorMessage] =
    React.useState<string>("");
  const [errorMessageDialogOpen, setErrorMessageDialogOpen] =
    React.useState(false);
  const [selectedComponProgramIndex, setSelectedCouponProgramIndex] =
    React.useState(-2); // -2: close, -1: create, >=0 edit
  const loaderCacheResetor = React.useRef<(() => void) | null>(null);

  const setPromotion = React.useCallback(
    (promotion: Partial<Promotion_promotionQuery_promotion>) => {
      dispatch(promotionActions.setPromotion(promotion));
    },
    [dispatch]
  );
  const setCouponProgramDiscount = React.useCallback(
    (index: number, discount: DiscountValue) => {
      dispatch(promotionActions.setCouponProgramDiscount({ index, discount }));
    },
    [dispatch]
  );
  const deleteCouponProgram = React.useCallback(
    (index: number) => {
      dispatch(promotionActions.deleteCouponProgram(index));
    },
    [dispatch]
  );
  const setCouponProgramActive = React.useCallback(
    (index: number, active: boolean) => {
      dispatch(promotionActions.setCouponProgramActive({ index, active }));
    },
    [dispatch]
  );
  const [savePromotion, { loading: savingPromotion }] = useMutation<
    SavePromotion,
    SavePromotionVariables
  >(savePromotionMutation, {
    onCompleted: (data) => {
      const savedPromotion = data?.promotionMutation?.savePromotion;
      if (promotion.id) {
        setPromotion(savedPromotion!);
      } else {
        navigate(`/promotions/${savedPromotion?.id}`);
      }
    },
    onError: (error) => {
      setOperationError(error);
      setOperationErrorMessage("Could not save promotion.");
      setErrorMessageDialogOpen(true);
    },
    update: (cache, { data }) => {
      const saved = data?.promotionMutation?.savePromotion;
      if (!promotion.id) {
        const cached = cache.readQuery<Promotions, PromotionsVariables>({
          query: promotionsQuery,
          variables: promotionsVariables,
        });
        if (!cached) return;
        const updated = update(cached, {
          promotionQuery: {
            promotions: {
              edges: {
                $unshift: [saved as any],
              },
              pageInfo: {
                rowCount: {
                  $set:
                    (cached?.promotionQuery?.promotions?.pageInfo?.rowCount ??
                      0) + 1,
                },
              },
            },
          },
        });
        cache.writeQuery<Promotions, PromotionsVariables>({
          query: promotionsQuery,
          variables: promotionsVariables,
          data: updated,
        });
      }
    },
  });
  const disableCreateCouponProgram = React.useMemo(() => {
    return savingPromotion || loading;
  }, [savingPromotion, loading]);
  const disableSavePromotion = React.useMemo(() => {
    const { hasChanges, loyalty_programs, deleted_loyalty_programs } = promotion;
    return (
      !hasChanges ||
      ((loyalty_programs?.length ?? 0) === 0 &&
        deleted_loyalty_programs.length === 0) ||
      loading || savingPromotion
    );
  }, [promotion, loading, savingPromotion]);
  const handleSavePromotion = React.useCallback(async () => {
    const input: PromotionInput = {
      id: promotion.id,
      active:promotion.active
    };
    if (promotion.name) {
      input.name = promotion.name;
    }
    if (promotion.date_from) {
      input.date_from = format(new Date(promotion.date_from), "yyyy-MM-dd")
    }
    if (promotion.date_to) {
      input.date_to = format(new Date(promotion.date_to), "yyyy-MM-dd")
    }
    if (promotion.reward_product_income_account_id) {
      input.reward_product_income_account_id = Number.parseInt(
        promotion.reward_product_income_account_id.id
      );
    }
    input.loyalty_program_ids = {
      list: promotion.loyalty_programs?.map(
        ({
          id,
          discount_mode,
          product_id,
          discount,
          discount_max_amount,
          reward_product_id,
          minimum_qty,
          reward_type,
          active,
        }) => ({
          id,
          discount_mode,
          product_id: Number.parseInt(
            product_id?.id!
          ),
          reward_product_id: reward_product_id? Number.parseInt(reward_product_id.id): null,
          reward_type,
          discount,
          discount_max_amount,
          minimum_qty,
          active,
        })
      ),
    };
    await savePromotion({ variables: { promotion: input } });
  }, [savePromotion, promotion]);
  const columns = React.useMemo<
    ReadonlyArray<
      GridColumn<Promotion_promotionQuery_promotion_loyalty_programs>
    >
  >(
    () => [
      {
        label: "#",
        key: "no",
        width: 50,
        format: ({ index }) => index + 1,
        textAlign: "right"
      },
      {
        label: "# Model No",
        key: "name",
        width: 150,
        format: ({ rowData }) => {
          const displayName = rowData?.product_id?.display_name ?? "";
          return displayName?.substring(
            displayName.indexOf("[") + 1,
            displayName.indexOf("]")
          );
        },
        textAlign: "left",
      },
      {
        label: "Description",
        key: "name",
        width: 200,
        format: ({ rowData }) => {
          return rowData?.product_id?.display_name?.split(
            "] "
          )[1];
        },
        textAlign: "left",
        flexGrow: 1,
      },
      {
        label: "Discount/Free Product",
        key: "name",
        width: 150,
        textAlign: "left",
        format: ({ rowData, index }) => {
          switch(rowData.reward_type){
            case RewardType.discount:
              return <DiscountValueEditor
              label=""
              placeholder="Discount"
              value={{discount_mode:rowData.discount_mode?? DiscountMode.per_point, discount:rowData.discount}}
              onValidated={(value: any) => setCouponProgramDiscount(index, value)}
              size="small"
              fullWidth
            />;
            case RewardType.product:
              return rowData.reward_product_id?.display_name
          }
        },
      },
      {
        label: "Min Qty",
        key: "minimum_qty",
        width: 120,
        textAlign: "right",
      },
      {
        label: "Max Desc",
        key: "discount_max_amount",
        width: 100,
        textAlign: "right",
        format: ({ rowData }) =>
          accounting.formatNumber(rowData.discount_max_amount, 0),
      },
      {
        label: "Updated By",
        key: "updated_name",
        width: 200,
        textAlign: "left",
      },
      {
        label: "",
        key: "command",
        width: 150,
        format: ({ rowData, index }) => {
          return (
            <Box
              sx={{
                display: "flex",
              }}
            >
              <Checkbox
                color="primary"
                checked={rowData.active}
                value={rowData.active}
                onChange={(e, checked) => {
                  setCouponProgramActive(index, checked);
                }}
              />
              <IconButton
                aria-label="Delete"
                disabled={!!rowData.id}
                onClick={() => {
                  deleteCouponProgram(index);
                }}
              >
                <Close color={rowData.id ? "disabled" : "error"} />
              </IconButton>
              <IconButton
                aria-label="Edit"
                onClick={() => {
                  setSelectedCouponProgramIndex(index);
                }}
              >
                <Edit color="secondary" />
              </IconButton>
            </Box>
          );
        },
      },
    ],
    [setCouponProgramDiscount, setCouponProgramActive, deleteCouponProgram]
  );

  const handleCommandKeyUp = React.useCallback(
    (e: DocumentEventMap["keyup"]) => {
      switch (e.key) {
        case "F4":
          if (!disableCreateCouponProgram) {
            setSelectedCouponProgramIndex(-1);
            e.stopPropagation();
          }
          break;
        case "F10":
          if (!disableSavePromotion) {
            handleSavePromotion();
            e.stopPropagation();
          }
          break;
        case "Escape":
          if (!promotion.hasChanges)
            navigate(`/promotions`,{replace:true});
          e.stopPropagation();
          break;
      }
    },
    [disableCreateCouponProgram, disableSavePromotion, handleSavePromotion, navigate, promotion.hasChanges]
  );

  React.useEffect(() => {
    document.addEventListener("keyup", handleCommandKeyUp);
    return () => {
      document.removeEventListener("keyup", handleCommandKeyUp);
    };
  }, [handleCommandKeyUp, promotion.id]);

  return (
    <>
      <Box
        flex={1}
        style={{
          display: "flex",
          borderLeftWidth: 1,
          borderLeftColor: "rgba(255,255,255,.3)",
          borderLeftStyle: "solid",
        }}
      >
        {promotion ? (
          <VirtualizedTable
            scrollToIndex={(promotion?.loyalty_programs?.length ?? 0) - 1}
            displayRowCount={false}
            registerForLoaderCacheReset={(resetor: () => void) => {
              loaderCacheResetor.current = resetor;
            }}
            headerComponent={
              <Box className="bg-down-1">
                <PromotionHeader
                  promotion={promotion}
                  createCouponProgram={() => {
                    setSelectedCouponProgramIndex(-1);
                  }}
                  disableCreateCouponProgram={disableCreateCouponProgram}
                  disableSavePromotion={disableSavePromotion}
                  savePromotion={handleSavePromotion}
                />
              </Box>
            }
            loading={loading}
            listModeBreakPoint={0}
            rowGetter={(index: number) => {
              return loyalty_programs?.[index]!;
            }}
            totalRowCount={loyalty_programs?.length ?? 0}
            rowCount={loyalty_programs?.length ?? 0}
            isRowLoaded={(index: number) => !!loyalty_programs?.[index]}
            columns={columns}
            pageSize={1000}
            initialLoading={loading}
          />
        ) : null}
      </Box>
      {operationError ? (
        <ErrorMessageDialog
          open={errorMessageDialogOpen}
          onClose={() => {
            setErrorMessageDialogOpen(false);
          }}
          error={{
            title:"Error",
            message: operationErrorMessage,
            detail:operationError.message
        }}
        />
      ) : null}
      {selectedComponProgramIndex > -2 ? (
        <CouponProgramDialog
          index={selectedComponProgramIndex}
          open={selectedComponProgramIndex > -2}
          onClose={() => {
            setSelectedCouponProgramIndex(-2);
          }}
        />
      ) : null}
    </>
  );
}

export default PromotionEditControl;
