import { useQuery } from "@apollo/client/react/hooks/useQuery";
import * as React from "react";
import { useStockPickingOutletContext } from ".";
import { stockPickingQuery } from "../../sale/graphql";
import {
  StockPicking,
  StockPickingVariables,
} from "../../sale/__generated__/StockPicking";
import StockPickingEditControl from "./StockPickingEditControl";

function StockPickingEdit() {
  const { picking_id } = useStockPickingOutletContext();
  const [pickingId, setPickingId] = React.useState<string>(picking_id!);
  React.useEffect(() => {
    setPickingId(picking_id?.toString()!);
  }, [picking_id]);
  const { loading } = useQuery<StockPicking, StockPickingVariables>(
    stockPickingQuery,
    {
      variables: {
        id: Number.parseInt(pickingId!),
      },
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
    }
  );
  return <StockPickingEditControl loading={loading} picking_id={pickingId!} />;
}

export default StockPickingEdit;
