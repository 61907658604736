import {gql} from "@apollo/client"

export const cashAndBankAccountsQuery =  gql`
query CashAndBankAccounts($tags:[Int!],$id:Int){
  accountingQuery{
    id
    accountingAccounts(where:{account_type:asset_cash},id:$id,tags:$tags,pagination:{pageSize:1000}){
      edges{
        id
        code
        name
        account_type
      }
    }
  }
}
`;

export const accountBalanceQuery = gql`
query AccountBalance($duration:Int!, $account_id:Int!, $pagination:PaginationInput,$orderBy:[AccountBalanceOrderByInput!]){
  accountingQuery{
    id
    accountBalance(duration:$duration,account_id:$account_id,pagination:$pagination,orderBy:$orderBy){
      edges{
        id
        account_id
        date
        balance
        total
        end_date
      }
      pageInfo{
        pageSize
        pageCount
        currentPage
        hasNextPage
        hasPreviousPage
        rowCount
      }
    }
  }
}
`;

export const cashTransactionsQuery = gql`
query CashTransaction($where:CashTransactionWhereInput,$account_id:Int!,$orderBy:[CashTransactionOrderByInput!],$pagination:PaginationInput){
  accountingQuery{
    id
    cashTransactionTotal:cashTransactions(account_id:$account_id,where:$where){
      edges{
        balance_sum
      }
    }
    cashTransactions(account_id:$account_id,where:$where, orderBy:$orderBy,pagination:$pagination,counter:1){
      edges{
        id
        date
        name
        move_name
        ref
        balance
        partner_name
        type
      }
      pageInfo{
        pageSize
        pageCount
        currentPage
        hasNextPage
        hasPreviousPage
        rowCount
      }
    }
  }
}
`


export const saleSummaryQuery = gql`
query SaleSummary(
  $where: SaleInvoiceReportWithoutPaymentWhereInput,
  $counter:Int
) {
  saleReportQuery {
    id
    sale:invoicesWithoutPayment (
      where: $where
      counter:$counter
    ) {
      edges {
        amount_total_sum
        amount_residual_sum
      }
    }
  }
}
`;

export const saleByTeamQuery = gql`
query SaleByTeam(
  $where: SaleInvoiceReportWithoutPaymentWhereInput
  $counter:Int
) {
  saleReportQuery {
    id
    saleByTeam:invoicesWithoutPayment (
      where: $where
      counter:$counter
    ) {
      edges {
        team_name
        amount_total_sum
        amount_residual_sum
      }
    }
  }
}
`;

export const cashAndBankQuery = gql`
query CashAndBank(
  $where: SaleInvoiceReportWhereInput,
  $counter:Int!
) {
  saleReportQuery {
    id
    cashAndBank:invoices (
      where: $where,
      counter:$counter
    ) {
      edges {
        journal_code
        bank_sum
        cash_sum
        invoice_sum
      }
      pageInfo{
        pageCount
        pageSize
        hasNextPage
        pageSize
        rowCount
        currentPage
        hasPreviousPage
      }
    }
  }
}
`;

export const cashAndBankByTeamQuery = gql`
query CashAndBankByTeam(
  $where: SaleInvoiceReportWhereInput,
  $counter:Int
) {
  saleReportQuery {
    id
    cashAndBankByTeam:invoices (
      where: $where,
      counter:$counter
    ) {
      edges {
        team_name
        journal_code
        bank_sum
        cash_sum
        invoice_sum
      }
      pageInfo{
        pageCount
        pageSize
        hasNextPage
        pageSize
        rowCount
        currentPage
        hasPreviousPage
      }
    }
  }
}
`;



export const paymentSummaryQuery = gql`
query PaymentSummary(
  $where: SaleInvoiceReportWhereInput,
  $counter:Int
) {
  saleReportQuery {
    id
    payments:invoices (
      where: $where,
      counter:$counter
    ) {
      edges {
        bank_sum
        cash_sum
        invoice_sum
      }
      pageInfo{
        pageCount
        pageSize
        hasNextPage
        pageSize
        rowCount
        currentPage
        hasPreviousPage
      }
    }
  }
}
`;

export const unconciledPaymentSummaryQuery = gql`query UnreconciledPaymentSummary($start_date:NodaTimeLocalDate!,$end_date:NodaTimeLocalDate!,$where:PaymentWithoutInvoiceWhereInput,$orderBy:[PaymentWithoutInvoiceOrderBy!],$counter:Int,$pagination:PaginationInput,$include_journalcode:Boolean! ){
  saleReportQuery{
    id
    unreconciledTotal:paymentsWithoutInvoice(start_date:$start_date,end_date:$end_date,where:$where,orderBy:$orderBy,counter:$counter,pagination:$pagination){
      edges{        
        journal_code @include(if:$include_journalcode)
        amount_sum
        inner_reconciled_amount_sum
        outer_reconciled_amount_sum
        remaining_sum
      }
      pageInfo{
        pageCount
        pageSize
        hasNextPage
        pageSize
        rowCount
        currentPage
        hasPreviousPage
      }
    }
  }
}
`;

export const paymentsWithInvoiceQuery = gql`
query PaymentsWithInvoice($start_date:NodaTimeLocalDate!,$end_date:NodaTimeLocalDate!,$where:PaymentWithInvoiceWhereInput,$orderBy:[PaymentWithInvoiceOrderBy!],$counter:Int,$pagination:PaginationInput ){
  saleReportQuery{
    id
    paymentsWithInvoice(start_date:$start_date,end_date:$end_date,where:$where,orderBy:$orderBy,counter:$counter,pagination:$pagination){
      edges{
        id
        date
        name
        journal_code
        ref
        amount
        inner_reconciled_amount
        outer_reconciled_amount
        counterpart_move_id
        counterpart_move_name
      	counterpart_move_date
        invoice_origin
        remaining
      }
      pageInfo{
        pageCount
        pageSize
        hasNextPage
        pageSize
        rowCount
        currentPage
        hasPreviousPage
      }
    }
  }
}
`;

export const invoiceReportQuery =  gql`
query Invoices(
  $pagination: PaginationInput
  $where: SaleInvoiceReportWhereInput
  $orderBy:[SaleInvoiceReportOrderBy!]
) {
  saleReportQuery {
    id
    invoices (
      pagination: $pagination
      where: $where
      orderBy:$orderBy
    ) {
      edges {
        id
        invoice_date
        invoice_origin
        name
        partner_name
        team_name
        amount_total
        amount_residual
        cash_sum
        bank_sum
        invoice_sum
        counterpart_move_name
      }
      pageInfo{
        pageCount
        pageSize
        hasNextPage
        pageSize
        rowCount
        currentPage
        hasPreviousPage
      }
    }
  }
}
`;

export const purchaseLineReportQuery = gql`
query PurchaseLineReport($where:PurchaseLineReportWhereInput,$orderBy:[PurchaseLineReportOrderBy!],$pagination:PaginationInput){
  purchaseReportQuery{
    id
    purchase_lines(where:$where,orderBy:$orderBy,pagination:$pagination){
      edges{
        id
        move_id
        name
        invoice_origin
        invoice_date
        ref
        product_id
        default_code
        product_name
        brand
        salezone
        uom
        quantity
        price_unit
        price_total
        partner_id
        partner
      }
      pageInfo{
        pageSize
        pageCount
        currentPage
        hasNextPage
        hasPreviousPage
        rowCount
      }
    }
  }
}
`;

export const stockValuationReportQuery = gql`
query StockValuationReport($as_of:NodaTimeLocalDate,$where:StockValuationLayerReportWhereInput,$orderBy:[StockValuationLayerReportOrderBy!],$pagination:PaginationInput){
  stockValuation{
    id
    valuations(where:$where,orderBy:$orderBy,pagination:$pagination,as_of:$as_of){
      edges{
        id
        brand
        create_date
        create_time
        default_code
        description
        salezone
        name
        partner_id
        product_id
        remaining_qty
        remaining_value
        stock_move
        stock_move_id
        supplier_name
        supplier_category
        uom
        quantity
        final_cost
      }
      pageInfo{
        pageSize
        pageCount
        currentPage
        hasNextPage
        hasPreviousPage
        rowCount
      }
    }
  }
}
`;

export const gpDetailReportQuery = gql`
query GpReport($start_date:NodaTimeLocalDate!,$end_date:NodaTimeLocalDate!,$where:GpReportWhereInput,$orderBy:[GpReportOrderByInput!],$pagination:PaginationInput){
  saleReportQuery{
    id
    gpDetails(start_date:$start_date,end_date:$end_date,where:$where,orderBy:$orderBy,pagination:$pagination){
      edges{
        id
        date
        ref
        ref_id
        product_id
        default_code
        product_name
        salezone
        brand
        vendor_id
        vendor_name
        vendor_category
        uom
        quantity
        price_total
        product_uom_qty
        value
        gp
      }
      pageInfo{
        pageSize
        pageCount
        rowCount
        hasNextPage
        hasPreviousPage
        currentPage
      }
    }
  }
}
`;

export const setCogsForOrderLineMutation = gql`
mutation SetCogsForOrderLine($id:Int!, $valuation_layer_id:Int!,$value:Float!){
  saleOrderMutation{
    id
    setCogsForOrderLine(id:$id, valuation_layer_id:$valuation_layer_id, value:$value){
      id
      orderLine{
				id
        product_id{
          id
          display_name
        }
        costs{
          id
          stock_move_id{
            id
            display_name
          }
          quantity
          unit_cost
          value
          cogs
          cogs_by_id{
            id
            display_name
          }
        }        
      }
      item{
        id
        date
        ref
        ref_id
        product_id
        default_code
        product_name
        salezone
        brand
        vendor_id
        vendor_name
        uom
        quantity
        price_total
        product_uom_qty
        value
        gp
      }
    }
  }
}
`;

export const costLinesQuery = gql`
query CogsLines($order_line_id:ID!,$product_id:ID!){
  productQuery{
    id
    product(id:$product_id){
      id
      default_code
      name
      purchase_history {
        id
        date_order
        order_id{
          id
          display_name
        }
        product_uom_qty
        price_unit
      }
      valuation_in {
        id
        create_date
        product_id
        default_code
        product_name
        stock_move_id
        stock_move_name
        quantity
        value
        unit_cost
        final_cost
        final_value
        remaining_qty
        remaining_value
        no_cost
        no_cost_by_id
        no_cost_by_name
        cost_by_id
        cost_by_name
        no_cost_by_name
      }
    }
  }
  saleOrderQuery{
    id
    orderLine(id:$order_line_id){
      id
      product_id{
        id
        display_name
      }
      costs{
        id
        stock_move_id{
          id
          display_name
        }
        product_id{
          id
          display_name
        }
        quantity
        unit_cost
        final_cost
        final_value
        value
        description
        create_date
        origin_layers{
          id
          quantity
          origin{
            id
            stock_move_id{
              id
              display_name
            }
            create_date
            quantity
            final_cost
            final_value
            description
          }
        }
      }
    }
  }
}
`;

export const gpSummaryReportQuery = gql`
query GpSummary($start_date:NodaTimeLocalDate!,$end_date:NodaTimeLocalDate!,$whereForAll:GpReportWhereInput,$whereForOem:GpReportWhereInput,$whereForOther:GpReportWhereInput){
  saleReportQuery{
    id
    all:gpDetails(start_date:$start_date,end_date:$end_date,where:$whereForAll,counter:1){
      edges{
        quantity_sum
        price_total_sum
        product_uom_qty_sum
        value_sum
        gp_sum
      }
    },
    oem:gpDetails(start_date:$start_date,end_date:$end_date,where:$whereForOem,counter:2){
      edges{
        quantity_sum
        price_total_sum
        product_uom_qty_sum
        value_sum
        gp_sum
      }
    },
    other:gpDetails(start_date:$start_date,end_date:$end_date,where:$whereForOther,counter:3){
      edges{
        quantity_sum
        price_total_sum
        product_uom_qty_sum
        value_sum
        gp_sum
      }
    }
  }
}
`;

export const saleReportQuery = gql`query SaleReportQuery($start_date:NodaTimeLocalDate!,$end_date:NodaTimeLocalDate!){
	saleReportQuery{
    netSale(start_date:$start_date,end_date:$end_date){
      id
      name
      amount
      total
    }
    netIncome(start_date:$start_date,end_date:$end_date){
      id
      name
      amount
      total
    }
    incomeBreakdown(start_date:$start_date, end_date:$end_date){
      id
      name
      amount
      total
    }
    paymentReconcillations(start_date:$start_date, end_date:$end_date){
      id
      name
      amount
      total
    }
  }
}`;

export const landedCostCheckListsQuery =  gql`
  query LandedCostCheckLists($where:LandedCostCheckListItemWhere,$start_date:NodaTimeLocalDate!,$end_date:NodaTimeLocalDate!,$orderBy:[LandedCostCheckListItemOrderBy!],$pagination:PaginationInput){
    purchaseOrderQuery{
      id
      landedCostCheckList(where:$where,start_date:$start_date,end_date:$end_date,orderBy:$orderBy,pagination:$pagination){
        edges{
          id
          po
          order_id
          partner_ref
          date_order
          product_id
          default_code
          name
          uom
          ordered_qty
          order_price
          ordered_amount
          billed_qty
          billed_amount
          bill_price
          received_qty
          received_value
          landed_cost
          total_value
          unit_cost
          qty_matched
          price_matched
        }
        pageInfo{
          currentPage
          hasNextPage
          hasPreviousPage
          pageSize
          pageCount
          rowCount
        }
      }
    }
  }
`;
export const stockBalanceHistoryQuery = gql`
  query StockBalanceHistory($show_location:Boolean!=true,$where:StockTransactionHistoryWhereInput, $orderBy:[StockTransactionHistoryOrderBy!],$pagination:PaginationInput,$counter:Int, $start_date:NodaTimeLocalDate!,$end_date:NodaTimeLocalDate!){
    inventoryQuery{
      id
      stockBalance:stockTransactions(where:$where,orderBy:$orderBy, pagination:$pagination, counter:$counter, start_date:$start_date, end_date:$end_date,show_location:$show_location){
        edges{
          product_id
          default_code
          product_name
          brand
          sale_zone
          category
          location_id @include(if: $show_location)
          location_name @include(if: $show_location)
          ob_sum #OB
          incoming_sum #Incoming
          outgoing_sum #Outgoing
          transfer_in_sum  @include(if: $show_location) #Transfer In
          transfer_out_sum @include(if: $show_location) #Transfer Out
          scrap_sum #Scrap
          adjustment_in_sum #Adj In
          adjustment_out_sum #Adj Out
          ccb_sum #CB
        }
        pageInfo{
          pageSize
          pageCount
          hasNextPage
          hasPreviousPage
          currentPage
          rowCount
          pageCount
        }
      }
    }
  }
`;

export const stockTransactionHistoryQuery = gql`
  query StockTransactionHistory($show_location:Boolean!=true,$where:StockTransactionHistoryWhereInput, $orderBy:[StockTransactionHistoryOrderBy!],$pagination:PaginationInput,$counter:Int, $start_date:NodaTimeLocalDate!,$end_date:NodaTimeLocalDate!){
    inventoryQuery{
      id
      stockTransactions(where:$where,orderBy:$orderBy, pagination:$pagination, counter:$counter, start_date:$start_date, end_date:$end_date,show_location:$show_location){
        edges{
          id @include(if: $show_location)
          product_id
          default_code
          product_name
          brand
          sale_zone
          category
          name
          location_id @include(if: $show_location)
          location_name @include(if: $show_location)
          origin
          date
          counterpart_location_id
          counterpart_location_name
          ob_sum
          cb_sum
        }
        pageInfo{
          pageSize
          pageCount
          hasNextPage
          hasPreviousPage
          currentPage
          rowCount
          pageCount
        }
      }
    }
  }
`;