import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box/Box";
import Container from "@mui/material/Container/Container";
import Grid from "@mui/material/Grid/Grid";
import { Warehouses_inventoryQuery_warehouses } from "../common/__generated__/Warehouses";
import { AnalyticAccounts_commonQuery_analyticAccounts } from "../common/__generated__/AnalyticAccounts";
import { Journals_commonQuery_journals } from "../common/__generated__/Journals";
import { SaleTeams_commonQuery_saleTeams } from "../common/__generated__/SaleTeams";
import WarehouseAutoComplete from "../common/WarehouseAutoComplete";
import AnalyticAccountAutoComplete from "../common/AnalyticAccountAutoComplete";
import JournalAutoComplete from "../common/JournalAutoComplete";
import SaleTeamAutoComplete from "../common/SaleTeamAutoComplete";
import AccountingAccountAutoComplete, { AccountingAccountOptionValue } from "../common/AccountingAccountAutoComplete";
import PartnerAutoComplete from "../common/PartnerAutoComplete";
import { RootState } from "../../app/store";
import { saleSettingActions } from "./saleSettingSlice";
import { Partners_partnerQuery_partners_edges } from "../common/__generated__/Partners";
import Typography from "@mui/material/Typography";
import { JournalType, PartnerType } from "../../types/global-types";
import NumberEditor from "../../components/NumberEditor";
import ComponentInListItem from "../../components/ComponentInListItem";
import Checkbox from "@mui/material/Checkbox";

const {
  setCashJournal,
  setWarehouse,
  setAnalyticAccount,
  setSaleTeam,
  setDiscountAccount,
  setDefaultCustomer,
  setRoundedDecimalPlaceForPrice,
  setCashAccount,
  setRecordSoldby,
  setDestinationCashJournal
} = saleSettingActions;
const _sx = {
  scroller: {
    flex: 1,
    overflow: "auto",
    backgroundColor: "rgba(0,0,0,.05)"
  },
  content: {
    marginTop: 2,
    paddingBottom: 2,
    backgroundColor: "background.default"
  },
  settingTitle: {
    marginTop: 1,
  },
};
function SaleSetting() {
  const saleSettings = useSelector((state: RootState) => state.saleSetting);
  const dispatch = useDispatch();
  const editWarehouse = React.useCallback(
    (warehouse: Warehouses_inventoryQuery_warehouses | null) => {
      dispatch(setWarehouse(warehouse));
    },
    [dispatch]
  );

  const editAnalyticAccount = React.useCallback(
    (analyticAccount: AnalyticAccounts_commonQuery_analyticAccounts | null) => {
      dispatch(setAnalyticAccount(analyticAccount));
    },
    [dispatch]
  );

  const editCashJournal = React.useCallback(
    (journal: Journals_commonQuery_journals | null) => {
      dispatch(setCashJournal(journal));
    },
    [dispatch]
  );

  const editDestinationCashJournal = React.useCallback((journal: Journals_commonQuery_journals | null) => {
    dispatch(setDestinationCashJournal(journal))
  }, [dispatch]);

  const editSaleTeam = React.useCallback(
    (saleTeam: SaleTeams_commonQuery_saleTeams | null) => {
      dispatch(setSaleTeam(saleTeam));
    },
    [dispatch]
  );

  const editDiscountAcount = React.useCallback(
    (
      discountAccount: AccountingAccountOptionValue | null
    ) => {
      dispatch(setDiscountAccount(discountAccount));
    },
    [dispatch]
  );

  const editCashAccount = React.useCallback(
    (cashAccount: AccountingAccountOptionValue | null) => {
      dispatch(setCashAccount(cashAccount));
    },
    [dispatch]
  );

  const editDefaultCustomer = React.useCallback(
    (customer: Partners_partnerQuery_partners_edges | null) => {
      dispatch(setDefaultCustomer(customer));
    },
    [dispatch]
  );

  const setDecimalPlaceForPrice = React.useCallback(
    (value?: number) => {
      dispatch(setRoundedDecimalPlaceForPrice(value ?? 2));
    },
    [dispatch]
  );

  const handleSetRecordSoldby = React.useCallback((_: any, value: boolean) => {
    dispatch(setRecordSoldby(value))
  }, [dispatch]);

  return (
    <Box sx={_sx.scroller}>
      <Container maxWidth="lg">
        <Typography sx={_sx.settingTitle} variant="h6">
          Sale
        </Typography>
        <Box
          flexDirection="column"
          boxShadow={1}
          p={2}
          display="flex"
          flexWrap="wrap"
          sx={_sx.content}
        >
          <Grid container>
            <ComponentInListItem
              label="Warehouse"
              component={
                <WarehouseAutoComplete
                  size="small"
                  selectedWarehouse={saleSettings.warehouse}
                  onSelected={editWarehouse}
                />
              }
            />
            <ComponentInListItem
              label="Analytic Account"
              component={
                <AnalyticAccountAutoComplete
                  size="small"
                  selectedAnalyticAccount={saleSettings.analyticAccount}
                  onSelected={editAnalyticAccount}
                />
              }
            />
            <ComponentInListItem
              label="Cash Journal"
              component={
                <JournalAutoComplete
                  size="small"
                  journalTypes={[JournalType.cash]}
                  selectedJournal={saleSettings.cashJournal}
                  onSelected={editCashJournal}
                />
              }
            />
            <ComponentInListItem
              label="Destination Cash Journal"
              component={
                <JournalAutoComplete
                  size="small"
                  journalTypes={[JournalType.cash]}
                  selectedJournal={saleSettings.destinationCashJournal}
                  onSelected={editDestinationCashJournal}
                />
              }
            />
            <ComponentInListItem
              label="Cash Account"
              component={
                <AccountingAccountAutoComplete
                  size="small"
                  tags={[4]}
                  selectedAccountingAccount={saleSettings.cashAccount}
                  onSelected={editCashAccount}
                />
              }
            />
            <ComponentInListItem
              label="Sale Team"
              component={
                <SaleTeamAutoComplete
                  size="small"
                  selectedSaleTeam={saleSettings.saleTeam}
                  onSelected={editSaleTeam}
                />
              }
            />

            <ComponentInListItem
              label="Default Customer"
              component={
                <PartnerAutoComplete
                  size="small"
                  partnerType={PartnerType.customer}
                  selectedPartner={saleSettings.defaultCustomer}
                  onSelected={editDefaultCustomer}
                />
              }
            />
            <ComponentInListItem
              label="Rounded Decimal Place for Price"
              component={
                <NumberEditor
                  size="small"
                  value={saleSettings.roundedDecimalPlaceForPrice}
                  numberPrecision={0}
                  onValidated={setDecimalPlaceForPrice}
                />
              }
            />
            <ComponentInListItem
              label="Record Sold by"
              component={
                <Checkbox checked={saleSettings.recordSoldBy} onChange={handleSetRecordSoldby} />
              }
            />
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}

export default SaleSetting;
