import Divider from "@mui/material/Divider/Divider";
import Grid from "@mui/material/Grid/Grid";
import Typography from "@mui/material/Typography/Typography";
import * as React from "react";



const _sx={
    divider: {
      width: "100%"
    },
    itemContainer: {
      padding: 1
    }
  };

export type ComponentInListItemProps = {
    label: string;
    component: React.ReactNode
  };
  
function ComponentInListItem(props: ComponentInListItemProps) {
    const {label,component} = props;
    return (
      <>
        <Grid item container sx={_sx.itemContainer}>
          <Grid container alignItems="center" item xs={12} md={6}>
            <Typography>{label} </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
           {component}
          </Grid>
        </Grid>
        <Divider sx={_sx.divider} />
      </>
    );
  }

  export default ComponentInListItem;