import * as React from "react";
import { AutoSizer as _AutoSizer, AutoSizerProps, CellMeasurer as _CellMeasurer, CellMeasurerCache, CellMeasurerProps, CellRenderer, createMasonryCellPositioner, Masonry as _Masonry, MasonryProps, Positioner, Size } from "react-virtualized";
import { styled } from '@mui/material/styles';
import { EcommerceCategory, EcommerceCategoryVariables, EcommerceCategory_ecommerceQuery_category_products_edges } from "../__generated__/EcommerceCategory";
import ProductCard from "./ProductCard";
import EmptyProductCard from "./EmptyProductCard";
import { useParams } from "react-router-dom";
import { useApolloClient, useLazyQuery } from "@apollo/client";
import { ecommerceCategoryQuery } from "../graphql";
const AutoSizer = _AutoSizer as React.ElementType<AutoSizerProps>;
const Masonry = _Masonry as React.ElementType<MasonryProps>;
const CellMeasurer = _CellMeasurer as React.ElementType<CellMeasurerProps>;
const MasonryStyled = styled(Masonry)(({ theme }) => ({
    padding: theme.spacing(0.5),
    paddingTop: window.screen.width < 600 ? theme.spacing(1) : theme.spacing(2),
    outline: "none",
}));
export const cardFooterHeight = 66;
export const defaultColumnWidth = 200;
const overscanPixel = 200 + 66;
const pageSize = 30;
type MasonryMeta = {
    columnCount: number;
    columnWidth: number;
    width: number;
    marginLeft: number;
}
const defaultMansonryMeta: MasonryMeta = {
    columnCount: 2,
    columnWidth: defaultColumnWidth,
    width: 2 * defaultColumnWidth + 8,
    marginLeft: 0
};
function ProductMasonryRenderer({ containerDimension }: { containerDimension: Size }) {
    let { id } = useParams<{ id: string }>();
    const currentCategoryId = React.useMemo(() => {
        if (!id)
            return -1;
        const result = Number.parseInt(id);
        if (isNaN(result))
            return null;
        return result;
    }, [id]);
    const cache = React.useRef(
        new CellMeasurerCache({
            defaultHeight: defaultColumnWidth + cardFooterHeight,
            defaultWidth: defaultMansonryMeta.columnWidth,
            fixedWidth: true,
        })
    );
    const cellPositioner = React.useRef<Positioner>(createMasonryCellPositioner({
        cellMeasurerCache: cache.current,
        columnCount: defaultMansonryMeta.columnCount,
        columnWidth: defaultMansonryMeta.columnWidth,
        spacer: 0,
    }));
    const apolloClient = useApolloClient();
    const [masonryMeta, setMasonryMeta] = React.useState<MasonryMeta>(defaultMansonryMeta);

    const masonry = React.useRef<_Masonry>(null);
    const loadProducts = React.useCallback((variables: EcommerceCategoryVariables) => {
        return apolloClient.query<EcommerceCategory, EcommerceCategoryVariables>({ query: ecommerceCategoryQuery, variables, fetchPolicy: "no-cache" });
    }, [apolloClient]);
    const [cellCount, setCellCount] = React.useState(pageSize);
    const resetList = React.useCallback(() => {
        if (masonry.current) {
            cache.current.clearAll();
            cellPositioner.current.reset({
                columnCount: masonryMeta.columnCount,
                columnWidth: masonryMeta.columnWidth,
                spacer: 0,
            });
            masonry.current.clearCellPositions();
        }
    }, [masonryMeta.columnCount, masonryMeta.columnWidth]);
    React.useEffect(resetList, [resetList]);
    React.useEffect(() => {
        const width = containerDimension.width;
        let columnWidth = defaultColumnWidth;
        let columnCount = 2;
        if (width <= 600) {
            columnWidth = width / 2 - 0.5 * 8;
            columnCount = 2;
        } else {
            columnWidth = defaultColumnWidth;
            columnCount = Math.floor(width / columnWidth);
        }
        const mansonaryWidth = columnCount * (columnWidth + 8);
        setMasonryMeta({
            columnCount,
            columnWidth,
            width: mansonaryWidth,
            marginLeft: (width - mansonaryWidth) / 2,
        });
    }, [containerDimension.width]);
    const loadingJobs = React.useRef<{ [page: number]: Promise<EcommerceCategory_ecommerceQuery_category_products_edges[]> | EcommerceCategory_ecommerceQuery_category_products_edges[] }>({});
    const loadPage = React.useCallback((page: number) => loadProducts({
        id: currentCategoryId ?? -1,
        pagination: {
            page,
            pageSize
        },
        includeProducts: true
    }).then((result) => {
        const records = result?.data?.ecommerceQuery?.category?.products?.edges ?? [];
        const pageInfo = result?.data?.ecommerceQuery.category?.products.pageInfo;
        if (pageInfo?.currentPage)
            loadingJobs.current[pageInfo.currentPage] = records;
        if (pageInfo?.currentPage === 1) {
            setCellCount(pageInfo?.rowCount);
        }
        resetList();
        return records;
    }).catch((e) => {
        console.log(e);
        delete loadingJobs.current[page];
        return e;
    }), [currentCategoryId, loadProducts, resetList]);

    React.useEffect(() => {
        loadingJobs.current = {};
        if (masonry.current)
            (masonry.current as any)._scrollingContainer.scrollTop = 0
        loadPage(1);
    }, [currentCategoryId, loadPage, resetList]);
    const loadMoreRows = React.useCallback(
        (page: number) => {
            if (!loadingJobs.current)
                loadingJobs.current = {};
            if (loadingJobs.current[page])
                return loadingJobs.current[page];
            if (!loadingJobs.current[1]) {
                loadingJobs.current[1] = loadPage(1);
            }
            if (!loadingJobs.current[page]) {
                const job = loadPage(page);
                loadingJobs.current[page] = job;
                return job;
            }
        },
        [loadPage]
    );
    const cellRenderer = React.useCallback<CellRenderer>(
        ({ index, key, parent, style }) => (
            <CellMeasurer
                cache={cache.current}
                index={index}
                key={key}
                parent={parent}
            >
                {
                    (() => {
                        const page = Math.ceil((index + 1) / pageSize);
                        const job = loadingJobs.current[page];
                        const isPromise = Boolean(job && typeof (job as any).then === "function");
                        let indexInPage = (index + 1) % pageSize;
                        if (indexInPage === 0)
                            indexInPage = pageSize;
                        indexInPage--;
                        const product = isPromise || !job ? null : (job as EcommerceCategory_ecommerceQuery_category_products_edges[])[indexInPage];
                        if (product) {
                            return (
                                <ProductCard
                                    data={product}
                                    style={style}
                                    width={masonryMeta.columnWidth}
                                />
                            )
                        } else
                            return (
                                <EmptyProductCard
                                    style={style}
                                    width={masonryMeta.columnWidth}
                                    // debug={`${JSON.stringify(Object.keys(loadingJobs.current))}, ${page}, ${indexInPage}`}
                                />
                            );
                    })()
                }
            </CellMeasurer>
        ),
        [masonryMeta.columnWidth]
    );
    return (
        <MasonryStyled
            overscanByPixels={overscanPixel}
            sx={{ marginLeft: `${masonryMeta.marginLeft}px` }}
            ref={masonry}
            cellCount={cellCount}
            cellMeasurerCache={cache.current}
            cellPositioner={cellPositioner.current}
            cellRenderer={cellRenderer}
            height={containerDimension.height}
            width={containerDimension.width - masonryMeta.marginLeft}
            autoHeight={false}
            onCellsRendered={({ stopIndex }) => {
                const page = Math.ceil((stopIndex + 1) / pageSize);
                if (page) {
                    loadMoreRows(page);
                }
            }}
        />
    );
}

const ProductMasonry = () => (<AutoSizer>
    {(size) => (<ProductMasonryRenderer containerDimension={size} />)}
</AutoSizer>);
export default ProductMasonry;