import {
    configureStore,
    ThunkAction,
    Action,
    combineReducers,
  } from "@reduxjs/toolkit";
  import siteReducer from "../features/site/siteSlice";
  import saleOrderReducer from "../features/sale/saleOrdersSlice";
  import promotionReducer from "../features/promotion/promotionsSlice";
  import saleSettingReducer from "../features/setting/saleSettingSlice";
  import {
    loadingBarReducer,
    loadingBarMiddleware,
  } from "react-redux-loading-bar";
  import {
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
    persistReducer,
  } from "redux-persist";
  import { createWhitelistFilter } from "redux-persist-transform-filter";
  
  const storage = require("redux-persist/lib/storage");
  const autoMergeLevel2 = require("redux-persist/lib/stateReconciler/autoMergeLevel2");
  const persistConfig = {
    key: "primary",
    storage: storage.default,
    whitelist: ["site","saleSetting"],
    blacklist: ["loadingBar"],
    transforms: [createWhitelistFilter("site", ["userSession","permissions","labelPrinterUrl","odooServerUrl","saleInvoiceReportId","darkMode","recordSoldBy"])],
    stateReconciler: autoMergeLevel2.default,
  };
  
  const rootReducers = combineReducers({
    site: siteReducer,
    saleSetting: saleSettingReducer,
    loadingBar: loadingBarReducer,
    saleOrder: saleOrderReducer,
    promotion: promotionReducer,
  });
  
  export type RootState = ReturnType<typeof rootReducers>;
  
  const persistedReducer = persistReducer<RootState>(persistConfig, rootReducers);
  
  export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }).concat(
        loadingBarMiddleware({
          promiseTypeSuffixes: ["pending", "fulfilled", "rejected"],
        })
      ),
  });
  
  export type AppDispatch = typeof store.dispatch;
  export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
  >;
  