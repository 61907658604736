import AppBar from "@mui/material/AppBar/AppBar";
import Toolbar from "@mui/material/Toolbar/Toolbar";
import Typography from "@mui/material/Typography/Typography";
import * as React from "react";
import update from "immutability-helper";
import Box from "@mui/material/Box/Box";
import { ApolloConsumer } from "@apollo/client/react/context/ApolloConsumer";
import IconButton from "@mui/material/IconButton/IconButton";
import Tooltip from "@mui/material/Tooltip/Tooltip";
import { purchaseLineReportQuery } from "../graphql";
import DrawerToggleButton from "../../../app/DrawerToggleButton";
import CsSearchBox, { OperandType } from "../../../components/CsSearchBox";
import { PurchaseLineReport, PurchaseLineReportVariables, PurchaseLineReport_purchaseReportQuery_purchase_lines_edges } from "../__generated__/PurchaseLineReport";
import FileDownloader, { FileDownloaderHandle } from "../../../components/FileDownloader";
import Download from "@mui/icons-material/CloudDownload";
import { format } from "date-fns";
import accounting from "accounting";
import ApolloVirtualizedTable, { ApolloVirtualizedTableProps, DefaultPageInfoValue, GridColumn } from "mui-apollo-virtualized-table";

const PurchaseLineVirtualizedGrid = ApolloVirtualizedTable as React.ElementType<ApolloVirtualizedTableProps<PurchaseLineReport_purchaseReportQuery_purchase_lines_edges>>;

const _sx = {
    title: {
        marginRight: 1,
    },
    box: {
        backgroundColor: "rgba(255,255,255,.1)",
    },
    root: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        flexWrap: "nowrap",
    },
}

function PurchaseReport() {
    const [columns, setColumns] = React.useState<
        ReadonlyArray<GridColumn<PurchaseLineReport_purchaseReportQuery_purchase_lines_edges>>
    >([
        {
            label: "Date",
            key: "invoice_date",
            width: 100,
            format: ({ rowData }: any) => format(new Date(rowData?.invoice_date), 'dd/MM/yyyy'),
            sortable: true,
        },
        {
            label: "No",
            key: "name",
            width: 150,
            sortable: true,
        },
        {
            label: "Model No",
            key: "default_code",
            width: 150,
            sortable: true
        },
        {
            label: "Description",
            key: "product_name",
            width: 200,
            flexGrow: 1,
            sortable: true
        },
        {
            label: "Brand",
            key: "brand",
            width: 100,
            sortable: true
        },
        {
            label: "Uom",
            key: "uom",
            width: 75,
            sortable: true
        },
        {
            label: "Qty",
            key: "quantity",
            labelAlign: "right",
            width: 75,
            textAlign: "right",
            sortable: true,
            format: ({ rowData }: any) => accounting.formatNumber(rowData.quantity ?? 0, 0),
        },
        {
            label: "Price",
            key: "price_unit",
            width: 100,
            textAlign: "right",
            labelAlign: "right",
            sortable: true,
            format: ({ rowData }: any) => accounting.formatNumber(rowData.price_unit ?? 0, 0),
        },
        {
            label: "Total",
            key: "price_total",
            labelAlign: "right",
            width: 120,
            textAlign: "right",
            sortable: true,
            format: ({ rowData }: any) => `${accounting.formatNumber(rowData.price_total ?? 0, 0)}`,
        },
        {
            label: "Supplier",
            key: "partner",
            width: 200,
            flexGrow: 1,
            sortable: true,
        },
    ]);
    const [variables, setVariables] = React.useState<PurchaseLineReportVariables>({ pagination: { pageSize: 40 } });
    const handleColumnsPropsChanged = React.useCallback((columns: ReadonlyArray<GridColumn<PurchaseLineReport_purchaseReportQuery_purchase_lines_edges>>, orderBy: string[]) => {
        setColumns(columns);
        setVariables({ ...variables, orderBy: orderBy as any });
    }, [setVariables, setColumns, variables]);

    const fileDownloader = React.useRef<FileDownloaderHandle>(null);

    const handleExcelFileDownload = React.useCallback(() => {
        fileDownloader?.current?.download();
    }, [fileDownloader?.current?.download]);

    return (
        <>
            <Box sx={_sx.root}>
                <AppBar position="static">
                    <Toolbar sx={(theme) => ({
                        [theme.breakpoints.up("md")]: {
                            minHeight: 6,
                        },
                        paddingLeft: 1.5,
                    })}>
                        <DrawerToggleButton />
                        <Typography
                            variant="h6"
                            sx={_sx.title}
                            color="inherit"
                            noWrap
                        >
                            Purchase Details
                        </Typography>
                        <CsSearchBox
                            onConditionChanged={(conditions: any) => {
                                setVariables(
                                    update(variables, {
                                        where: {
                                            $set: { aND: conditions },
                                        },
                                    })
                                );
                            }}
                            popperSx={(theme) => ({
                                marginTop: 2,
                                [theme.breakpoints.up("md")]: {
                                    marginTop: 1,
                                },
                            })}
                            operands={{
                                name: {
                                    name: "No",
                                    propName: "name",
                                    type: OperandType.STRING,
                                },
                                invoice_origin: {
                                    name: "PO",
                                    propName: "invoice_origin",
                                    type: OperandType.STRING,
                                },
                                invoice_date: {
                                    name: "Date",
                                    propName: "invoice_date",
                                    type: OperandType.DATE
                                },
                                ref: {
                                    name: "Ref",
                                    propName: "ref",
                                    type: OperandType.STRING,
                                },
                                default_code: {
                                    name: "Model No",
                                    propName: "default_code",
                                    type: OperandType.STRING
                                },
                                product_name: {
                                    name: "Product Name",
                                    propName: "product_name",
                                    type: OperandType.STRING
                                },
                                brand: {
                                    name: "Brand",
                                    propName: "brand",
                                    type: OperandType.STRING
                                },
                                salezone: {
                                    name: "Sale Zone",
                                    propName: "salezone",
                                    type: OperandType.STRING,
                                },
                                partner: {
                                    name: "Supplier",
                                    propName: "partner",
                                    type: OperandType.STRING
                                },
                                quantity: {
                                    name: "Qty",
                                    propName: "Quantity",
                                    type: OperandType.NUMBER
                                },
                                price_unit: {
                                    name: "Price",
                                    propName: "price_unit",
                                    type: OperandType.NUMBER
                                },
                                price_total: {
                                    name: "Amount",
                                    propName: "price_total",
                                    type: OperandType.NUMBER
                                }
                            }}
                        />
                        <FileDownloader ref={fileDownloader} url="/excel/purchase_detail" json={JSON.stringify(variables?.where)}>
                            <Tooltip title="Download Excel">
                                <IconButton
                                    color="inherit"
                                    onClick={handleExcelFileDownload}
                                >
                                    <Download />
                                </IconButton>
                            </Tooltip>
                        </FileDownloader>
                    </Toolbar>
                </AppBar>
                <Box
                    sx={_sx.box}
                    flex={1}
                    display="flex"
                    flexDirection="row"
                >
                    <ApolloConsumer>
                        {(client) => (
                            <PurchaseLineVirtualizedGrid
                                onColumnPropsChanged={handleColumnsPropsChanged}
                                apolloClient={client as any}
                                listItemHeight={72}
                                listModeBreakPoint={0}
                                columns={columns}
                                graphqlQuery={purchaseLineReportQuery}
                                variables={variables}
                                pageSize={variables.pagination?.pageSize!}
                                parseListFromQueryResult={(queryResult: PurchaseLineReport) => {
                                    const list = queryResult?.purchaseReportQuery?.purchase_lines ?? {
                                        edges: [],
                                        pageInfo: DefaultPageInfoValue,
                                    };
                                    return list;
                                }}
                                onLoadMore={(pageInfo) => {
                                    return {
                                        ...variables,
                                        pagination: {
                                            page: pageInfo.page,
                                            pageSize: pageInfo.pageSize,
                                        },
                                    };
                                }}
                            />
                        )}
                    </ApolloConsumer>
                </Box>
            </Box>
        </>
    );
}

export default PurchaseReport;