import { useQuery } from "@apollo/client/react/hooks/useQuery";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSaleOrderOutletContext } from ".";
import { RootState } from "../../app/store";
import { saleOrderQuery } from "./graphql";
import SaleOrderEditControl from "./SaleOrderEditControl";
import { saleOrderActions } from "./saleOrdersSlice";
import {
  SaleOrder,
  SaleOrderVariables,
  SaleOrder_saleOrderQuery_saleOrder,
} from "./__generated__/SaleOrder";

function SaleOrderEdit() {
  const {
    order_id,
    saleOrdersVariables,
  } = useSaleOrderOutletContext();
  const dispatch = useDispatch();
  const currentOrderId = useSelector((state: RootState) => state.saleOrder.id);
  const setSaleOrder = React.useCallback(
    (saleOrder: SaleOrder_saleOrderQuery_saleOrder) => {
      dispatch(saleOrderActions.setSaleOrder(saleOrder));
    },
    [dispatch]
  );
  const { loading } = useQuery<SaleOrder, SaleOrderVariables>(saleOrderQuery, {
    variables: {
      id: order_id?.toString()!,
    },
    skip: order_id?.toString() === currentOrderId,
    onCompleted: (data) => {
      const saleOrder = data?.saleOrderQuery?.saleOrder;
      if (saleOrder) {
        setSaleOrder({
          ...saleOrder,
          invoices: saleOrder.invoices.slice().sort((a, b) => {
            if (a.id > b.id) return -1;
            if (a.id < b.id) return 1;
            return 0;
          }),
        });
      }
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });
  return (
    <SaleOrderEditControl
      saleOrdersVariables={saleOrdersVariables}
      loading={loading}
    />
  );
}

export default SaleOrderEdit;
