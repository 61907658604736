import { useQuery } from "@apollo/client/react/hooks/useQuery";
import Grid from "@mui/material/Grid/Grid";
import Paper from "@mui/material/Paper/Paper";
import Table from "@mui/material/Table/Table";
import TableBody from "@mui/material/TableBody/TableBody";
import TableCell from "@mui/material/TableCell/TableCell";
import TableContainer from "@mui/material/TableContainer/TableContainer";
import TableRow from "@mui/material/TableRow/TableRow";
import accounting from "accounting";
import * as React from "react";
import { gpSummaryReportQuery } from "../graphql";
import { GpReportVariables } from "../__generated__/GpReport";
import { GpSummary, GpSummaryVariables, GpSummary_saleReportQuery, GpSummary_saleReportQuery_all, GpSummary_saleReportQuery_oem, GpSummary_saleReportQuery_other } from "../__generated__/GpSummary";
import { VictoryPie } from "victory-pie";
import { VictoryBar } from "victory-bar";
import { VictoryLabel } from "victory";
import List from "@mui/material/List/List";
import ListItem from "@mui/material/ListItem/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon/ListItemIcon";
import ListItemText from "@mui/material/ListItemText/ListItemText";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { GpReportWhereInput } from "../../../types/global-types";
export type SummaryProps = {
    variables: GpReportVariables
}
const _sx={
    itemContainer: {
        padding: 1,
    },
    paperTitle: {
        textDecoration: "underline",
        color: "secondary.main",
        paddingTop:2
    },
    paperTitleMargin: {
        marginLeft: 2
    },
    scroller: {
        flex: 1,
        overflow: "auto",
        padding: 1,
        backgroundColor:"rgba(0,0,0.1)"
    },
    paper: {
        flex: 1,
        padding: 2,
        backgroundColor:"rgba(0,0,0,.3)"
    }

};

const _overviewSx = {
    paper: {
        display: "flex",
        flexDirection: "column",
        backgroundColor:"rgba(0,0,0,.3)",
        paddingTop:2
    },
    title: {
        flex: 1,
        paddingLeft: 2,
        textDecoration: "underline",
        color: "secondary.main",
    },
    victoryContainer: {
        flex: 1
    },
    itemContainer: {
        padding: 1
    },
    chartContainer: {
        flex: 1,
        flexDirection: "column",
        flexWrap: "nowrap",
        display: "flex",
        '@media (min-width: 960px) and (max-width:1280px)': {
            flexDirection: "row"
        },
        '@media (min-width: 1920px)': {
            flexDirection: "row"
        }
    }
};

function All({ all }: { all: GpSummary_saleReportQuery_all|null }) {
    var [summary] = all?.edges ?? [];
    return (<Grid item xs={12} md={6} sx={_sx.itemContainer}><TableContainer component={Paper} >
        <Typography variant="subtitle1" sx={{..._sx.paperTitle, ..._sx.paperTitleMargin}}>All</Typography>
        <Table>
            <TableBody>
                <TableRow >
                    <TableCell scope="row">Total Sale</TableCell>
                    <TableCell align="right">{accounting.formatNumber(summary?.price_total_sum??0)}</TableCell>
                </TableRow>
                <TableRow >
                    <TableCell scope="row">Total Cost</TableCell>
                    <TableCell align="right">{accounting.formatNumber(summary?.value_sum??0)}</TableCell>
                </TableRow>
                <TableRow >
                    <TableCell scope="row">Gross Profit</TableCell>
                    <TableCell align="right">{accounting.formatNumber(summary?.gp_sum??0)}</TableCell>
                </TableRow>
                <TableRow >
                    <TableCell scope="row">Gross Profit(%)</TableCell>
                    <TableCell align="right">{summary?.gp_sum && summary?.price_total_sum ? accounting.formatNumber((summary?.gp_sum ?? 0) / summary?.price_total_sum * 100, 2): 0 } %</TableCell>
                </TableRow>
            </TableBody>
        </Table>
    </TableContainer></Grid>);
}

function MT({ oem }: { oem: GpSummary_saleReportQuery_oem | null }) {
    var [summary] = oem?.edges ?? [];
    return (<Grid item xs={12} md={6} sx={_overviewSx.itemContainer}><TableContainer component={Paper} >
        <Typography variant="subtitle1" sx={{..._sx.paperTitle, ..._sx.paperTitleMargin}}>OEM</Typography>
        <Table>
            <TableBody>
                <TableRow >
                    <TableCell scope="row">Total Sale</TableCell>
                    <TableCell align="right">{accounting.formatNumber(summary?.price_total_sum??0)}</TableCell>
                </TableRow>
                <TableRow >
                    <TableCell scope="row">Total Cost</TableCell>
                    <TableCell align="right">{accounting.formatNumber(summary?.value_sum??0)}</TableCell>
                </TableRow>
                <TableRow >
                    <TableCell scope="row">Gross Profit</TableCell>
                    <TableCell align="right">{accounting.formatNumber(summary?.gp_sum??0)}</TableCell>
                </TableRow>
                <TableRow >
                    <TableCell scope="row">Gross Profit(%)</TableCell>
                    <TableCell align="right">{summary?.gp_sum && summary?.price_total_sum ?accounting.formatNumber((summary?.gp_sum ?? 0) / summary?.price_total_sum * 100, 2): 0} %</TableCell>
                </TableRow>
            </TableBody>
        </Table>
    </TableContainer></Grid>);
}

function Company({ other }: { other: GpSummary_saleReportQuery_other| null }) {
    var [summary] = other?.edges ?? [];
    return (<Grid item xs={12} md={6} sx={_overviewSx.itemContainer}><TableContainer component={Paper} >
        <Typography variant="subtitle1" sx={{..._sx.paperTitle, ..._sx.paperTitleMargin}}>Other</Typography>
        <Table>
            <TableBody>
                <TableRow >
                    <TableCell scope="row">Total Sale</TableCell>
                    <TableCell align="right">{accounting.formatNumber(summary?.price_total_sum??0)}</TableCell>
                </TableRow>
                <TableRow >
                    <TableCell scope="row">Total Cost</TableCell>
                    <TableCell align="right">{accounting.formatNumber(summary?.value_sum??0)}</TableCell>
                </TableRow>
                <TableRow >
                    <TableCell scope="row">Gross Profit</TableCell>
                    <TableCell align="right">{accounting.formatNumber(summary?.gp_sum??0)}</TableCell>
                </TableRow>
                <TableRow >
                    <TableCell scope="row">Gross Profit(%)</TableCell>
                    <TableCell align="right">{summary?.gp_sum && summary?.price_total_sum ? accounting.formatNumber((summary?.gp_sum ?? 0) / summary?.price_total_sum * 100, 2) : 0} %</TableCell>
                </TableRow>
            </TableBody>
        </Table>
    </TableContainer></Grid>);
}

function ListItemForDatum({ color, primary, secondary }: { color: string, primary: string, secondary: string }) {
    return (<ListItem>
        <ListItemIcon sx={{width:16,height:16, minWidth:32}}>
            <svg className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><circle cx={12} cy={12} r={12} fill={color} /></svg>
        </ListItemIcon>
        <ListItemText primary={primary} secondary={secondary} />
    </ListItem>)
}


function Overview({ all, oem, other }: Pick<GpSummary_saleReportQuery,"all" | "oem" | "other">) {
    const [oemData] = oem?.edges ?? [];
    const [otherData] = other?.edges ?? [];
    const [allData] = all?.edges ?? [];
    return (<Grid container direction="row">
        <Grid item sm={6} lg={3} container direction="row" sx={_overviewSx.itemContainer}>
            <Paper sx={_overviewSx.paper}>
                <Typography variant="subtitle1" sx={_overviewSx.title}>Sales</Typography>
                <Box sx={_overviewSx.chartContainer}>
                    <Box sx={_overviewSx.victoryContainer}>
                        <VictoryPie labelRadius={40} padAngle={2} labels={({ datum }) => datum.y? datum.x:""} style={{ labels: { fontSize: 24, fill: "rgba(0,0,0,.5)" } }} innerRadius={100} colorScale={["tomato", "orange", "gold", "cyan", "navy"]} data={[{ x: "OEM", y: oemData?.price_total_sum }, { x: "Others", y: otherData?.price_total_sum }]} />
                    </Box>
                    <List>
                        <ListItemForDatum color="tomato" primary={`OEM: ${oemData?.price_total_sum && allData?.price_total_sum ? accounting.formatNumber((oemData?.price_total_sum ?? 0) / (allData?.price_total_sum) * 100, 2): 0}%`} secondary={accounting.formatNumber(oemData?.price_total_sum ?? 0, 0)} />
                        <ListItemForDatum color="orange" primary={`Others: ${otherData?.price_total_sum && allData?.price_total_sum ? accounting.formatNumber((otherData?.price_total_sum ?? 0) / (allData?.price_total_sum) * 100, 2): 0}%`} secondary={accounting.formatNumber(otherData?.price_total_sum ?? 0, 0)} />
                        <ListItemForDatum color="gold" primary="All" secondary={`${accounting.formatNumber(allData?.price_total_sum??0,0)}`} />
                    </List>
                </Box>
            </Paper>
        </Grid>

        <Grid item sm={6} lg={3} container direction="row" sx={_overviewSx.itemContainer}>
            <Paper sx={_overviewSx.paper}>
                <Typography variant="subtitle1" sx={_overviewSx.title}>Profit Margin</Typography>
                <Box sx={_overviewSx.chartContainer}>
                    <Box sx={_overviewSx.victoryContainer}>
                        <VictoryBar horizontal labelComponent={
                            <VictoryLabel textAnchor="end" dx={-16} />
                        } style={{ labels: { fontSize: 24, fill: "rgba(0,0,0,.5)" }, data: { fill: "orange" } }} barWidth={56} barRatio={1} colorScale={["tomato", "orange", "gold", "cyan", "navy"]} labels={({ datum }) => datum.y? `${accounting.formatNumber(datum.y, 2)}%`:""}  data={[{ x: "All", y: ((allData?.gp_sum ?? 0) / (allData?.price_total_sum ?? 0) * 100) },{ x: "Others", y: ((otherData?.gp_sum ?? 0) / (otherData?.price_total_sum ?? 0) * 100) }, { x: "OEM", y: ((oemData?.gp_sum ?? 0) / (oemData?.price_total_sum ?? 0) * 100) }]} />
                    </Box>
                    <List>
                        <ListItemForDatum color="tomato" primary="OEM" secondary={`${accounting.formatNumber((oemData?.gp_sum ?? 0) / (oemData?.price_total_sum ?? 0) * 100, 2)}%`} />
                        <ListItemForDatum color="orange" primary="Others" secondary={`${accounting.formatNumber((otherData?.gp_sum ?? 0) / (otherData?.price_total_sum ?? 0) * 100, 2)}%`} />
                        <ListItemForDatum color="gold" primary="All" secondary={`${accounting.formatNumber((allData?.gp_sum ?? 0) / (allData?.price_total_sum ?? 0) * 100, 2)}%`} />
                    </List>
                </Box>
            </Paper>
        </Grid>
        <Grid item sm={6} lg={3} container direction="row" sx={_overviewSx.itemContainer}>
            <Paper sx={_overviewSx.paper}>
                <Typography variant="subtitle1" sx={_overviewSx.title}>Gross Profit</Typography>
                <Box sx={_overviewSx.chartContainer}>
                    <Box sx={_overviewSx.victoryContainer}>
                        <VictoryPie labelRadius={40} padAngle={2} labels={({ datum }) => datum.y? datum.x:""} style={{ labels: { fontSize: 24, fill: "rgba(0,0,0,.5)" } }} innerRadius={100} colorScale={["tomato", "orange", "gold", "cyan", "navy"]} data={[{ x: "OEM", y: (oemData?.gp_sum) }, { x: "Others", y: (otherData?.gp_sum) }]} />
                    </Box>
                    <List>
                        <ListItemForDatum color="tomato" primary={`OEM: ${oemData?.gp_sum && allData?.gp_sum ? accounting.formatNumber((oemData?.gp_sum ?? 0) / (allData?.gp_sum) * 100, 2): 0}%`} secondary={accounting.formatNumber(oemData?.gp_sum ?? 0, 0)} />
                        <ListItemForDatum color="orange" primary={`Others: ${otherData?.gp_sum && allData?.gp_sum ? accounting.formatNumber((otherData?.gp_sum ?? 0) / (allData?.gp_sum) * 100, 2): 0}%`} secondary={accounting.formatNumber(otherData?.gp_sum ?? 0, 0)} />
                        <ListItemForDatum color="gold" primary="All" secondary={`${accounting.formatNumber(allData?.gp_sum??0,0)}`} />
                    </List>
                </Box>
            </Paper>
        </Grid>
        <Grid item sm={6} lg={3} container direction="row" sx={_overviewSx.itemContainer}>
            <Paper sx={_overviewSx.paper}>
                <Typography variant="subtitle1" sx={_overviewSx.title}>Cost of Sales</Typography>
                <Box sx={_overviewSx.chartContainer}>
                    <Box sx={_overviewSx.victoryContainer}>
                        <VictoryPie labelRadius={40} padAngle={2} labels={({ datum }) => datum.y? datum.x:""} style={{ labels: { fontSize: 24,fill: "rgba(0,0,0,.5)" } }} innerRadius={100} colorScale={["tomato", "orange", "gold", "cyan", "navy"]} data={[{ x: "OEM", y: (oemData?.value_sum??0) * -1 }, { x: "Others", y: (otherData?.value_sum??0) * -1 }]} />
                    </Box>
                    <List>
                        <ListItemForDatum color="tomato" primary={`OEM: ${oemData?.value_sum && allData?.value_sum ? accounting.formatNumber((oemData?.value_sum ?? 0) / (allData?.value_sum) * 100, 2) : 0}%`} secondary={accounting.formatNumber(oemData?.value_sum ?? 0, 0)} />
                        <ListItemForDatum color="orange" primary={`Others: ${otherData?.value_sum && allData?.value_sum ? accounting.formatNumber((otherData?.value_sum ?? 0) / (allData?.value_sum) * 100, 2): 0}%`} secondary={accounting.formatNumber(otherData?.value_sum ?? 0, 0)} />
                        <ListItemForDatum color="gold" primary="All" secondary={`${accounting.formatNumber(allData?.value_sum??0,0)}`} />
                    </List>
                </Box>
            </Paper>
        </Grid>
    </Grid>);
}

function Summary({ variables }: SummaryProps) {
    const whereForOem: GpReportWhereInput = { ...variables.where, vendor_category_In: ["Supplier-MT","Supplier-Audio"] };
    const whereForOther: GpReportWhereInput = { ...variables.where, oR: [{ vendor_category_NotIn:["Supplier-MT","Supplier-Audio"] }, { vendor_id_IsBlank: true }] };
    const whereForAll: GpReportWhereInput = { ...variables.where };
    var { data: summaryResult } = useQuery<GpSummary, GpSummaryVariables>(gpSummaryReportQuery, { variables: { ...variables, whereForAll, whereForOem, whereForOther } });
    const { all, oem, other } = summaryResult?.saleReportQuery ?? {all:null,oem:null,other:null};
    return (<Box sx={_sx.scroller} >
        <Overview all={all} oem={oem} other={other} />
        <Grid direction="row" container>
            <All all={all} />
            <MT oem={oem} />
            <Company other={other} />
        </Grid>
    </Box>)
}

export default Summary;