import * as React from "react";

 function usePrevious<T>(value:T){
    const ref = React.useRef<T>();
 
    React.useEffect(() => {
        ref.current = value;
    }, [value]);
 
    return ref.current;
 }

 function useDeepPropState<T>(prop:any,transform?:(from:any)=>T){
    const [dataset, setDataset] = React.useState<T>(transform? transform(prop): prop);
     const prevDatas = usePrevious(prop);
 
     const handleChangeDataset =( data:T) => setDataset(data);
 
     React.useEffect(() => {
         if (prop !==prevDatas) {
             var d = prop;
            if(transform){
                d = transform(prop);
            }
            setDataset({...prevDatas,...d});
         }
     }, [prop, prevDatas, transform]);
 
     const result:[T,(v:T)=>void]= [
         dataset,
         handleChangeDataset
     ];
     return result;
 }

 export default useDeepPropState;
 
