import * as React from "react";
import accounting from "accounting";
import { TextFieldProps } from "@mui/material/TextField";
import TextField from "@mui/material/TextField";
import { DiscountMode } from "../../types/global-types";

const _sx = {
    input: {
        textAlign: "right",
    },
};

function moveDecimal(n: number, l: number) {
    let v = l > 0 ? n * Math.pow(10, l) : n / Math.pow(10, l * -1);
    return v;
}

export type DiscountValue = {
    discount_mode: DiscountMode;
    discount: number;
}

type OwnProps = {
    validateOnBlur?: boolean;
    retainFocusOnError?: boolean;
    onValidating?: (value?: DiscountValue) => string;
    onValidated: (value?: DiscountValue) => void;
    selectAllOnFocus?: boolean;
    numberPrecision?: number;
    value?: DiscountValue;
    onChanged?: (value: DiscountValue) => void;
    showErrorAsHelperText?: boolean;
    innerReference?: (ref: React.Ref<HTMLElement>) => void;
    inputReference?: (ref: HTMLInputElement) => void;
    roundedDecimalPlace?: number;
};
function round(num: number, decimalPlaces: number) {
    if (!decimalPlaces)
        return num;
    const factor = Math.pow(10, decimalPlaces);
    return Math.round(num * factor) / factor;
}
export type NumberEditorProps = OwnProps & TextFieldProps;
function DiscountValueEditor(props: NumberEditorProps) {
    const {
        roundedDecimalPlace = 0,
        showErrorAsHelperText,
        error,
        helperText,
        onValidating,
        onValidated,
        selectAllOnFocus,
        numberPrecision,
        onBlur,
        value,
        onFocus,
        onKeyDown,
        onKeyPress,
        inputRef,
        inputReference,
        onChanged,
        inputProps,
        retainFocusOnError,
        validateOnBlur,
        ...textFieldProps
    } = props;

    const textField = React.useRef<HTMLInputElement | null>(null);
    const [active, setActive] = React.useState<boolean>(false);
    const [draft, setDraft] = React.useState<string>(
        value?.discount_mode === DiscountMode.percent ? accounting.formatMoney(value?.discount ?? 0, "%", numberPrecision) : accounting.formatNumber(value?.discount ?? 0)
    );
    const shouldValidateOnBlur = React.useRef<boolean>(true);
    React.useEffect(() => {
        setDraft(value?.discount_mode === DiscountMode.percent ? accounting.formatMoney(value?.discount ?? 0, "%", numberPrecision) : accounting.formatNumber(value?.discount ?? 0));
    }, [numberPrecision, value]);

    const num = React.useMemo(() => {
        if (draft === null || draft === "" || draft === undefined) return undefined;
        else {
            const n = Number.parseFloat(draft.replace(" ", "").replace("%", "").replace(",",""));
            return isNaN(n) ? undefined : round(n, roundedDecimalPlace);
        }
    }, [draft, roundedDecimalPlace]);
    const text = React.useMemo(() => {
        if (draft == null || draft === "" || draft === undefined) return "";
        else if (active)
            return draft;
        else if (num != null && draft?.includes("%"))
            return accounting.formatMoney(num, "%", numberPrecision);
        else if (num != null)
            return accounting.formatNumber(num, numberPrecision);
        else
            return "";
    }, [draft, active, num, numberPrecision]);
    const validate = () => {
        if (num === value?.discount) {
            setValidationError("");
            return true;
        }
        const discount_type = text.includes("%") ? DiscountMode.percent : DiscountMode.per_point;
        const newValue: DiscountValue = {
            discount_mode: discount_type,
            discount: num??0
        };
        if (onValidating) {
            const error = onValidating(newValue);
            setValidationError(error);
            if (!error) {
                onValidated(newValue);
                return true;
            } else {
                return false;
            }
        } else {
            onValidated(newValue);
            return true;
        }
    };
    const [validationError, setValidationError] = React.useState("");
    const handleUpDownKey = (isUp: boolean) => {
        let n = num;
        if (!n) n = 0;
        let dec = text.indexOf(".");
        let sign = isUp ? 1 : -1;
        if (dec > -1) {
            // right of decimal
            let digitsAfterPoint = text.split(".")[1].length;
            let fnum = moveDecimal(n, digitsAfterPoint);
            fnum += sign;
            n = moveDecimal(fnum, digitsAfterPoint * -1);
        } else {
            // no decimal
            n += sign;
        }
        setDraft(n.toString());
    };
    return (
        <TextField
            helperText={
                validationError && showErrorAsHelperText ? validationError : helperText
            }
            error={!!validationError || error}
            inputRef={(r) => {
                textField.current = r;
                if (inputReference) inputReference(r);
            }}
            inputProps={{ sx: _sx.input, ...inputProps }}
            {...textFieldProps}
            value={text}
            onFocus={(e: any) => {
                setActive(true);
                if (selectAllOnFocus) {
                    setTimeout(() => {
                        textField.current?.setSelectionRange(
                            0,
                            textField.current?.value.length
                        );
                    }, 10);
                }
                if (onFocus) onFocus(e);
            }}
            onKeyPress={(e: React.KeyboardEvent<HTMLDivElement>) => {
                if (e.key === "Enter") {
                    validate();
                    setTimeout(() => {
                        textField.current?.setSelectionRange(
                            0,
                            textField.current?.value.length
                        );
                    }, 10);
                } else if (
                    !"0123456789%.".includes(e.key)
                ) {
                    // digit and minus sign
                    e.preventDefault();
                }

                if (onKeyPress) onKeyPress(e);
            }}
            onBlur={(e: any) => {
                setActive(false);
                if (validateOnBlur) {
                    if (
                        shouldValidateOnBlur.current &&
                        !validate() &&
                        retainFocusOnError
                    ) {
                        e.preventDefault();
                        setTimeout(() => {
                            textField.current?.setSelectionRange(
                                0,
                                textField.current?.value.length
                            );
                            textField.current?.focus();
                        }, 10);
                    }
                }
                shouldValidateOnBlur.current = true;
                if (onBlur) {
                    onBlur(e);
                }
            }}
            onChange={(e: any) => {
                let v = e.target.value.toString();
                const n = Number.parseFloat(v);
                if (v === "-") {
                    setDraft(v);
                } else
                    if (isNaN(n)) {
                        setDraft("");
                    } else {
                        setDraft(v);
                    }
            }}
            onKeyDown={(e: any) => {
                switch (e.keyCode) {
                    case 38: // up
                        e.preventDefault();
                        handleUpDownKey(true);
                        break;
                    case 40: // down
                        e.preventDefault();
                        handleUpDownKey(false);
                        break;
                    case 27: // escape key
                        shouldValidateOnBlur.current = false;
                        textField.current?.blur();
                        setDraft(value?.discount_mode === DiscountMode.percent ? accounting.formatMoney(value?.discount ?? 0, "%", numberPrecision) : accounting.formatNumber(value?.discount ?? 0));
                        if (onValidating) {
                            const error = onValidating(value);
                            setValidationError(error);
                        }
                        break;
                    default:
                        break;
                }
                if (onKeyDown) onKeyDown(e);
            }}
        />
    );
}

DiscountValueEditor.defaultProps = {
    numberPrecision: 0,
    retainFocusOnError: true,
    selectAllOnFocus: true,
    validateOnBlur: true,
    showErrorAsHelperText: true,
};

export default DiscountValueEditor;
