
import * as React from "react";
import { Invoices, InvoicesVariables, Invoices_saleReportQuery_invoices_edges } from "../__generated__/Invoices";
import update from "immutability-helper";
import { ApolloConsumer } from "@apollo/client/react/context/ApolloConsumer";
import { invoiceReportQuery } from "../graphql";
import format from "date-fns/esm/fp/format/index.js";
import accounting from "accounting";
import Summary from "./Summary";
import { SaleInvoiceReportOrderBy } from "../../../types/global-types";
import ApolloVirtualizedTable, { ApolloVirtualizedTableProps, DefaultPageInfoValue, GridColumn } from "mui-apollo-virtualized-table";
import CsDateRangeEditor, { DateRangeWithLabel } from "../../../components/CsDateRangeEditor";
import useHasPermission from "../../../app/useHasPermission";
import { AppBar, Box, Grid, Hidden, IconButton, ListItemIcon, Menu, MenuItem, Tab, Tabs, Toolbar } from "@mui/material";
import DrawerToggleButton from "../../../app/DrawerToggleButton";
import CsSearchBox, { OperandType } from "../../../components/CsSearchBox";
import { MoreVert, Print } from "@mui/icons-material";
import ReportDialog from "./ReportDialog";
// import ReportDialog from "./ReportDialog";

const InvoiceVirtualizedGrid = ApolloVirtualizedTable as React.ElementType<ApolloVirtualizedTableProps<Invoices_saleReportQuery_invoices_edges>>;


const _sx = {
    appBar: {},
    title: {
        marginRight: 1,
    },
    box: {
        backgroundColor: "rgba(255,255,255,.1)",
        padding: 1
    },
};

function RptInvoices() {
    const [variables, setVariables] = React.useState<InvoicesVariables>({
        pagination: {
            pageSize: 40,
        },
        orderBy: [SaleInvoiceReportOrderBy.invoice_origin, SaleInvoiceReportOrderBy.name],
        where: { invoice_date_Gte: format("yyyy-MM-dd", new Date()), invoice_date_Lte: format("yyyy-MM-dd", new Date()) }
    });

    const [columns, setColumns] = React.useState<
        ReadonlyArray<GridColumn<Invoices_saleReportQuery_invoices_edges>>
    >([
        {
            label: "Date",
            key: "invoice_date",
            width: 150,
            format: ({ rowData }) => format("dd/MM/yyyy", new Date(rowData.invoice_date)),
            sortable: true
        },
        {
            label: "Order#",
            key: "invoice_origin",
            width: 100,
            sortable: true,
            sortDirection: "ASC",
            sortOrder: 1
        },
        {
            label: "Name",
            key: "name",
            width: 200,
            sortable: true,
            sortDirection: "ASC",
            sortOrder: 2
        },
        {
            label: "Partner",
            key: "partner_name",
            width: 100,
            flexGrow: 1,
            sortable: true
        },
        {
            label: "Team",
            key: "team_name",
            width: 150,
            sortable: true
        },
        {
            label: "Total",
            key: "amount_total",
            width: 100,
            textAlign: "right",
            sortable: true,
            format: ({ rowData }: any) => accounting.formatNumber(rowData.amount_total, 0)
        },
        {
            label: "Outstanding",
            key: "amount_residual",
            width: 100,
            textAlign: "right",
            sortable: true,
            format: ({ rowData }: any) => accounting.formatNumber(rowData.amount_residual, 0)
        },
        {
            label: "Cash",
            key: "cash_sum",
            width: 100,
            textAlign: "right",
            format: ({ rowData }: any) => accounting.formatNumber(rowData.cash_sum, 0)
        },
        {
            label: "Bank",
            key: "bank_sum",
            width: 100,
            textAlign: "right",
            format: ({ rowData }: any) => accounting.formatNumber(rowData.bank_sum, 0)
        },
        {
            label: "Invoice",
            key: "invoice_sum",
            width: 100,
            textAlign: "right",
            format: ({ rowData }: any) => accounting.formatNumber(rowData.invoice_sum, 0)
        },
        {
            label: "Payment#",
            key: "counterpart_move_name",
            width: 200,
            sortable: true
        },
    ]);


    const [selectedTab, setSelectedTab] = React.useState("summary");
    const handleSelectedTabChanged = React.useCallback((_: any, value: string) => {
        setSelectedTab(value);
    }, [setSelectedTab]);

    const handleColumnsPropsChanged = React.useCallback((columns: ReadonlyArray<GridColumn<Invoices_saleReportQuery_invoices_edges>>, orderBy: string[]) => {
        setColumns(columns);
        setVariables({ ...variables, orderBy: orderBy as any });
    }, [setVariables, setColumns, variables]);

    const [dateRange, setDateRange] = React.useState<DateRangeWithLabel>(new DateRangeWithLabel({ from: new Date(), to: new Date() }, "Today"));

    const handleChangeDateRange = React.useCallback((value: DateRangeWithLabel) => {
        setDateRange(value);
        setVariables({ ...variables, where: { ...variables.where, invoice_date_Gte: format("yyyy-MM-dd", value.value.from), invoice_date_Lte: format("yyyy-MM-dd", value.value.to) } });
    }, [variables, setVariables]);
    const handleSearchConditionChanged = React.useCallback((conditions: any) => {
        setVariables(
            update(variables, {
                where: {
                    $set: { ...variables.where, aND: conditions },
                }
            })
        );
    }, [variables, setVariables]);
    const [isMoreMenuOpen, setIsMoreMenuOpen] = React.useState(false);
    const closeMoreMenu = React.useCallback(() => { setIsMoreMenuOpen(false); }, [setIsMoreMenuOpen]);
    const openMoreMenu = React.useCallback(() => { setIsMoreMenuOpen(true); }, [setIsMoreMenuOpen]);
    const moreButtonRef = React.useRef<HTMLButtonElement>(null);

    const [isPrintMenuOpen, setIsPrintMenuOpen] = React.useState(false);
    const closePrintMenu = React.useCallback(() => { setIsPrintMenuOpen(false); }, [setIsPrintMenuOpen]);
    const openPrintMenu = React.useCallback(() => { setIsPrintMenuOpen(true); }, [setIsPrintMenuOpen]);
    const [isOpenReportDialog, setIsOpenReportDialog] = React.useState(false);
    const printButtonRef = React.useRef<HTMLButtonElement>(null);
    const closeReportDialog = React.useCallback(() => { setIsOpenReportDialog(false); }, [setIsOpenReportDialog]);
    const openReportDialog = React.useCallback(() => { setIsOpenReportDialog(true); }, [setIsOpenReportDialog]);
    const openSaleDetailReport = React.useCallback(() => {
        const from = format("yyyy-MM-dd", dateRange.value.from);
        const to = format("yyyy-MM-dd", dateRange.value.to);
        const url = `/report/sale_summary/from/${from}/to/${to}`;
        window.open(url, "_blank");
    }, [dateRange]);
    const noDurationLimit = useHasPermission("SaleReport_NoDurationLimit");
    return (
        <>
            <Grid container direction="column">
                <AppBar position="static" sx={_sx.appBar}>
                    <Toolbar sx={(theme) => ({
                        [theme.breakpoints.up("md")]: {
                            minHeight: 48,
                        },
                        paddingLeft: 1.5,
                    })}>
                        <DrawerToggleButton />
                        <CsSearchBox
                            onConditionChanged={handleSearchConditionChanged}
                            operands={{
                                id: {
                                    name: "Id",
                                    propName: "id",
                                    type: OperandType.STRING,
                                },
                                name: {
                                    name: "Name",
                                    propName: "name",
                                    type: OperandType.STRING,
                                },
                                partner_name: {
                                    name: "Partner",
                                    propName: "partner_name",
                                    type: OperandType.STRING,
                                },
                                team_name: {
                                    name: "Team",
                                    propName: "team_name",
                                    type: OperandType.STRING,
                                },
                                invoice_origin: {
                                    name: "Order#",
                                    propName: "invoice_origin",
                                    type: OperandType.STRING,
                                },
                            }}
                        />

                        {
                            noDurationLimit ? <Hidden smDown>
                                <CsDateRangeEditor
                                    sx={{
                                        marginLeft:1,
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            borderColor: "rgb(192, 192, 192)"
                                        },
                                        '&:hover .MuiOutlinedInput-notchedOutline':{
                                            borderColor:"#fff"
                                        },
                                        '& .MuiOutlinedInput-input':{
                                            color:"#fff"
                                        },
                                        '& button':{
                                            color:"#fff"
                                        }
                                    }}
                                    size="small"
                                    value={dateRange}
                                    enableTime={false}
                                    error={!dateRange}
                                    onChange={handleChangeDateRange}
                                />
                            </Hidden> : null
                        }
                        <Hidden mdDown>
                            <IconButton
                                color="inherit"
                                ref={printButtonRef}
                                onClick={openPrintMenu}
                            >
                                <Print />
                            </IconButton>
                        </Hidden>
                        <Hidden mdUp>
                            <IconButton
                                color="inherit"
                                ref={moreButtonRef}
                                onClick={openMoreMenu}
                            >
                                <MoreVert />
                            </IconButton>
                        </Hidden>
                    </Toolbar>
                </AppBar>
                <AppBar position="static" color="inherit">
                    <Tabs value={selectedTab} onChange={handleSelectedTabChanged} aria-label="Sale Invoices">
                        <Tab label="Summary" value="summary" />
                        <Tab label="Invoices" value="invoices" />
                    </Tabs>
                </AppBar>
                <Menu
                    id="more-menu"
                    anchorEl={moreButtonRef?.current}
                    keepMounted
                    open={isMoreMenuOpen}
                    onClose={closeMoreMenu}
                >
                    {
                        noDurationLimit ? <CsDateRangeEditor
                        sx={{marginLeft:1,marginRight:1}}
                            size="small"
                            value={dateRange}
                            enableTime={false}
                            error={!dateRange}
                            onChange={handleChangeDateRange}
                        /> : null
                    }
                    <MenuItem onClick={openReportDialog}>
                        <ListItemIcon>
                            <Print fontSize="small" />
                        </ListItemIcon>
                        Sale Detail Report
                    </MenuItem>
                    <MenuItem onClick={openSaleDetailReport}>
                        <ListItemIcon>
                            <Print fontSize="small" />
                        </ListItemIcon>
                        Sale Summary Report
                    </MenuItem>
                </Menu>
                <Menu id="print-menu"
                    anchorEl={printButtonRef?.current}
                    keepMounted
                    open={isPrintMenuOpen}
                    onClose={closePrintMenu}
                >
                    <MenuItem onClick={openReportDialog}>
                        <ListItemIcon>
                            <Print fontSize="small" />
                        </ListItemIcon>
                        Sale Detail Report
                    </MenuItem>
                    <MenuItem onClick={openSaleDetailReport}>
                        <ListItemIcon>
                            <Print fontSize="small" />
                        </ListItemIcon>
                        Sale Summary Report
                    </MenuItem>
                </Menu>
                {
                    selectedTab === "summary" ? <Summary variables={{ where: variables.where }} start_date={format("yyyy-MM-dd", dateRange.value.from)} end_date={format("yyyy-MM-dd", dateRange.value.to)} /> : null
                }
                {
                    selectedTab === "invoices" ? <Box
                        sx={_sx.box}
                        flex={1}
                        display="flex"
                        flexDirection="row"
                    >
                        <ApolloConsumer>
                            {(client) => (
                                <InvoiceVirtualizedGrid
                                    onColumnPropsChanged={handleColumnsPropsChanged}
                                    apolloClient={client as any}
                                    listItemHeight={72}
                                    listModeBreakPoint={0}
                                    columns={columns}
                                    graphqlQuery={invoiceReportQuery}
                                    variables={variables}
                                    pageSize={variables?.pagination?.pageSize!}
                                    parseListFromQueryResult={(queryResult: Invoices) => {
                                        const list = queryResult?.saleReportQuery?.invoices ?? {
                                            edges: [],
                                            pageInfo: DefaultPageInfoValue,
                                        };
                                        return list;
                                    }}
                                    onLoadMore={(pageInfo) => {
                                        return {
                                            ...variables,
                                            pagination: {
                                                page: pageInfo.page,
                                                pageSize: pageInfo.pageSize,
                                            },
                                        };
                                    }}
                                />
                            )}
                        </ApolloConsumer>
                    </Box> : null
                }
            </Grid>
            <ReportDialog open={isOpenReportDialog} onClose={closeReportDialog} dateRange={dateRange} />
        </>
    );
}

export default RptInvoices;