import { useQuery } from "@apollo/client";
import Autocomplete from "@mui/material/Autocomplete";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import * as React from "react";
import { Product_productQuery_product_prices_price_list } from "../sale/__generated__/Product";
import { priceListsQuery } from "./graphql";
import { PriceLists } from "./__generated__/PriceLists";

export type PriceListAutoCompleteProps = {
    selectedPriceList: Product_productQuery_product_prices_price_list | null;
    onChange: (priceList: Product_productQuery_product_prices_price_list | null) => void;
} & Omit<TextFieldProps, "onChange">;
function PriceListAutoComplete({
    selectedPriceList,
    onChange,
    className,
    ...props
}: PriceListAutoCompleteProps) {
    const { data } = useQuery<PriceLists, {}>(priceListsQuery);
    return (
        <Autocomplete
            value={selectedPriceList}
            onChange={(_, value: Product_productQuery_product_prices_price_list | null) =>
                onChange(value)
            }
            isOptionEqualToValue={(option, value) => option.id === value.id}
            options={data?.productQuery?.priceLists ?? (selectedPriceList ? [selectedPriceList] : [])}
            getOptionLabel={(option) => option?.name ?? ""}
            renderInput={(params) => (
                <TextField
                    {...params}
                    {...props}
                    className={className}
                />
            )}
        />
    );
}

export default PriceListAutoComplete;
