import AppBar from "@mui/material/AppBar/AppBar";
import Toolbar from "@mui/material/Toolbar/Toolbar";
import Typography from "@mui/material/Typography/Typography";
import * as React from "react";
import Download from "@mui/icons-material/CloudDownload";
import Box from "@mui/material/Box/Box";
import format from "date-fns/esm/fp/format/index.js";
import { ApolloConsumer } from "@apollo/client/react/context/ApolloConsumer";
import DrawerToggleButton from "../../../app/DrawerToggleButton";
import CsSearchBox, { OperandType } from "../../../components/CsSearchBox";
import accounting from "accounting";
import ApolloVirtualizedTable, {
  ApolloVirtualizedTableProps,
  CheckBoxColumnMode,
  DefaultPageInfoValue,
  GridColumn,
} from "mui-apollo-virtualized-table";
import { stockBalanceHistoryQuery } from "../graphql";
import CsDateRangeEditor, {
  DateRangeWithLabel,
} from "../../../components/CsDateRangeEditor";
import { Outlet, useNavigate, useParams, useSearchParams } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import { useQuery } from "@apollo/client";
import { StockBalanceHistory, StockBalanceHistoryVariables, StockBalanceHistory_inventoryQuery_stockBalance_edges } from "../__generated__/StockBalanceHistory";
import FileDownloader, { FileDownloaderHandle } from "../../../components/FileDownloader";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";

const StockBalanceHistoryVirtualizedGrid =
  ApolloVirtualizedTable as React.ElementType<
    ApolloVirtualizedTableProps<StockBalanceHistory_inventoryQuery_stockBalance_edges>
  >;

const _sx = {
  title: {
    marginRight: 1,
  },
  box: {
    backgroundColor: "rgba(255,255,255,.1)",
  },
  root: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
  },
};

const initialColumns: ReadonlyArray<
  GridColumn<StockBalanceHistory_inventoryQuery_stockBalance_edges>
> = [
    {
      label: "Code",
      key: "default_code",
      width: 120,
      textAlign: "left",
      labelAlign: "center",
      sortable: true,
    },
    {
      label: "Name",
      key: "product_name",
      width: 200,
      textAlign: "left",
      labelAlign: "center",
      flexGrow: 1,
      sortable: true,
    },
    {
      label: "Sale Zone",
      key: "sale_zone",
      width: 200,
      textAlign: "left",
      labelAlign: "center",
      sortable: true,
    },
    {
      label: "OB",
      key: "ob",
      width: 100,
      labelAlign: "right",
      textAlign: "right",
      sortable: true,
      format: ({ rowData }: any) =>
        accounting.formatNumber(rowData.ob_sum ?? 0, 0),
    },
    {
      label: "In",
      key: "incoming",
      width: 100,
      labelAlign: "right",
      textAlign: "right",
      sortable: true,
      format: ({ rowData }: any) =>
        accounting.formatNumber(rowData.incoming_sum ?? 0, 0),
    },
    {
      label: "Out",
      key: "outgoing",
      width: 100,
      labelAlign: "right",
      textAlign: "right",
      sortable: true,
      format: ({ rowData }: any) =>
        accounting.formatNumber(rowData.outgoing_sum ?? 0, 0),
    },
    {
      label: "TIn",
      key: "transfer_in",
      width: 100,
      labelAlign: "right",
      textAlign: "right",
      sortable: true,
      hideAt: 900,
      format: ({ rowData }: any) =>
        accounting.formatNumber(rowData.transfer_in_sum ?? 0, 0),
    },
    {
      label: "TOut",
      key: "transfer_out",
      width: 100,
      labelAlign: "right",
      textAlign: "right",
      sortable: true,
      hideAt: 900,
      format: ({ rowData }: any) =>
        accounting.formatNumber(rowData.transfer_out_sum ?? 0, 0),
    },
    {
      label: "Scrap",
      key: "scrap",
      width: 100,
      labelAlign: "right",
      textAlign: "right",
      sortable: true,
      hideAt: 900,
      format: ({ rowData }: any) =>
        accounting.formatNumber(rowData.scrap_sum ?? 0, 0),
    },
    {
      label: "AIn",
      key: "adjustment_in",
      width: 100,
      labelAlign: "right",
      textAlign: "right",
      sortable: true,
      hideAt: 900,
      format: ({ rowData }: any) =>
        accounting.formatNumber(rowData.adjustment_in_sum ?? 0, 0),
    },
    {
      label: "AOut",
      key: "adjustment_out",
      width: 100,
      labelAlign: "right",
      textAlign: "right",
      sortable: true,
      hideAt: 900,
      format: ({ rowData }: any) =>
        accounting.formatNumber(rowData.adjustment_out_sum ?? 0, 0),
    },
    {
      label: "CB",
      key: "ccb",
      width: 100,
      labelAlign: "right",
      textAlign: "right",
      sortable: true,
      hideAt: 900,
      format: ({ rowData }: any) =>
        accounting.formatNumber(rowData.ccb_sum ?? 0, 0),
    },
  ];

function InventoryReport() {
  const navigate = useNavigate();
  const { start_date, end_date } = useParams();
  let [searchParams] = useSearchParams();
  const show_location = searchParams.get("show_location") === "true";

  const [columns, setColumns] = React.useState<
    ReadonlyArray<
      GridColumn<StockBalanceHistory_inventoryQuery_stockBalance_edges>
    >
  >(initialColumns);

  const [orderBy, setOrderBy] = React.useState<string[]>([]);
  const [filterConditions, setFilterConditions] = React.useState<any>();

  React.useEffect(() => {
    const newColumns = [...initialColumns];
    if (show_location)
      newColumns.splice(2, 0, {
        label: "Location",
        key: "location_name",
        width: 150,
        textAlign: "left",
        labelAlign: "center",
        sortable: true,
      });
    setColumns(newColumns);
  }, [show_location]);

  const variables = React.useMemo<StockBalanceHistoryVariables>(() => {
    const variables: StockBalanceHistoryVariables = {
      pagination: { pageSize: 40 },
      start_date,
      end_date,
      show_location,
      orderBy: (orderBy as any[]),
      where: { aND: filterConditions }
    }
    return variables;
  }, [end_date, filterConditions, orderBy, show_location, start_date]);

  const { data: inventoryQueryResult } = useQuery<
    StockBalanceHistory,
    StockBalanceHistoryVariables
  >(stockBalanceHistoryQuery, { variables, fetchPolicy: "cache-only" });
  const [selected, setSelected] = React.useState<ReadonlyArray<number>>([]);

  const handleOnRowClick = React.useCallback((_: any, index: number) => {
    setSelected([index]);
    const rows = inventoryQueryResult?.inventoryQuery?.stockBalance?.edges ?? [];
    const row = rows[index];
    if (!row)
      return;
    const { product_id, location_id } = row;
    const url = `/reports/inventory_report/${start_date}/${end_date}/details/${product_id}${location_id ? `/${location_id}` : ""}?show_location=${show_location}`;
    navigate(url);
  }, [end_date, inventoryQueryResult?.inventoryQuery?.stockBalance?.edges, navigate, show_location, start_date]);
  const handleColumnsPropsChanged = React.useCallback(
    (
      columns: ReadonlyArray<
        GridColumn<StockBalanceHistory_inventoryQuery_stockBalance_edges>
      >,
      orderBy: string[]
    ) => {
      setColumns(columns);
      setOrderBy(orderBy);
    },
    [setColumns]
  );

  const dateRange = React.useMemo<DateRangeWithLabel>(() => {
    const sDate = new Date(start_date!);
    const eDate = new Date(end_date!);
    return new DateRangeWithLabel(
      { from: sDate, to: eDate },
      `${format("dd/MM/yyyy", sDate)} - ${format(
        "dd/MM/yyyy",
        eDate
      )}`
    );
  }, [end_date, start_date]);

  const handleChangeDateRange = React.useCallback(
    (value: DateRangeWithLabel) => {
      const from = format("yyyy-MM-dd", value.value.from);
      const to = format("yyyy-MM-dd", value.value.to);
      const url = `/reports/inventory_report/${from}/${to}?show_location=${show_location}`;
      navigate(url, { replace: true });
    },
    [navigate, show_location]
  );


  const handleCheckBoxChanged = React.useCallback(
    (value: boolean) => {
      const url = `/reports/inventory_report/${start_date}/${end_date}?show_location=${value}`;
      navigate(url, { replace: true });
    },
    [end_date, navigate, start_date]
  );
  const handleSearchConditionChanged = React.useCallback(
    (conditions: any) => {
      setFilterConditions(conditions);
    },
    []
  );

  const fileDownloader = React.useRef<FileDownloaderHandle>(null);

  const handleExcelFileDownload = React.useCallback(() => {
      fileDownloader?.current?.download();
  }, []);

  return (
    <>
      <Box sx={_sx.root}>
        <AppBar position="static">
          <Toolbar
            sx={(theme) => ({
              [theme.breakpoints.up("md")]: {
                minHeight: 6,
              },
              paddingLeft: 1.5,
            })}
          >
            <DrawerToggleButton />
            <Typography variant="h6" sx={_sx.title} color="inherit" noWrap>
              Balance History
            </Typography>
            <CsSearchBox
              onConditionChanged={handleSearchConditionChanged}
              popperSx={(theme) => ({
                marginTop: 2,
                [theme.breakpoints.up("md")]: {
                  marginTop: 1,
                },
              })}
              operands={{
                search_code: {
                  name: "Search",
                  propName: "search_code",
                  type: OperandType.STRING,
                },
                default_code: {
                  name: "Default Code",
                  propName: "default_code",
                  type: OperandType.STRING,
                },
                product_name: {
                  name: "Name",
                  propName: "product_name",
                  type: OperandType.STRING,
                },
                sale_zone:{
                  name:"Sale Zone",
                  propName:"sale_zone",
                  type:OperandType.STRING
                },
                category:{
                  name:"Category",
                  propName:"category",
                  type:OperandType.STRING
                },
                ob_sum: {
                  name: "Opening",
                  propName: "ob_sum",
                  type: OperandType.STRING,
                },
                incoming_sum: {
                  name: "Incoming",
                  propName: "incoming_sum",
                  type: OperandType.STRING,
                },
                outgoing_sum: {
                  name: "Outgoing",
                  propName: "outgoing_sum",
                  type: OperandType.STRING,
                },
                transfer_in_sum: {
                  name: "Transfer In",
                  propName: "transfer_in_sum",
                  type: OperandType.STRING,
                },
                transfer_out_sum: {
                  name: "Transfer Out",
                  propName: "transfer_out_sum",
                  type: OperandType.STRING,
                },
                scrap_sum: {
                  name: "Scrap",
                  propName: "scrap_sum",
                  type: OperandType.STRING,
                },
                adjustment_in_sum: {
                  name: "Adjustment In",
                  propName: "adjustment_in_sum",
                  type: OperandType.STRING,
                },
                adjustment_out_sum: {
                  name: "Adjustment Out",
                  propName: "adjustment_out_sum",
                  type: OperandType.STRING,
                },
                ccb_sum: {
                  name: "Closing",
                  propName: "ccb_sum",
                  type: OperandType.STRING,
                },
              }}
            />
            <CsDateRangeEditor
              sx={{
                marginLeft: 1,
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgb(192, 192, 192)",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#fff",
                },
                "& .MuiOutlinedInput-input": {
                  color: "#fff",
                },
                "& button": {
                  color: "#fff",
                },
              }}
              size="small"
              value={dateRange}
              enableTime={false}
              error={!dateRange}
              onChange={handleChangeDateRange}
            />
            <Checkbox
              aria-label="Location"
              sx={{
                "&.Mui-checked": {
                  color: "#fff",
                },
              }}
              checked={show_location}
              onChange={(e, checked) => {
                handleCheckBoxChanged(checked);
              }}
            />
            Location
            <FileDownloader ref={fileDownloader} url={`/excel/inventory/${variables.start_date}/${variables.end_date}${show_location?`/with_location`:''}`} json={JSON.stringify(variables?.where)}>
              <Tooltip title="Download Excel">
                <IconButton
                  color="inherit"
                  onClick={handleExcelFileDownload}
                >
                  <Download />
                </IconButton>
              </Tooltip>
            </FileDownloader>
          </Toolbar>
        </AppBar>
        <Box sx={_sx.box} flex={1} display="flex" flexDirection="row">
          <ApolloConsumer>
            {(client) => (
              <StockBalanceHistoryVirtualizedGrid
                onColumnPropsChanged={handleColumnsPropsChanged}
                checkBoxColumnMode={CheckBoxColumnMode.none}
                apolloClient={client as any}
                listItemHeight={72}
                listModeBreakPoint={0}
                columns={columns}
                graphqlQuery={stockBalanceHistoryQuery}
                selectedItems={selected}
                onRowClick={handleOnRowClick}
                setSelectedItems={setSelected}
                variables={variables}
                pageSize={variables.pagination?.pageSize!}
                parseListFromQueryResult={(
                  queryResult: StockBalanceHistory
                ) => {
                  const list = queryResult?.inventoryQuery?.stockBalance ?? {
                    edges: [],
                    pageInfo: DefaultPageInfoValue,
                  };
                  return list;
                }}
                onLoadMore={(pageInfo) => {
                  return {
                    ...variables,
                    pagination: {
                      page: pageInfo.page,
                      pageSize: pageInfo.pageSize,
                    },
                  };
                }}
              />
            )}
          </ApolloConsumer>
          <Outlet />
        </Box>
      </Box>
    </>
  );
}

export default InventoryReport;
