import Chip from "@mui/material/Chip/Chip";
import * as React from "react";
import { SaleOrderState } from "../../types/global-types";

type SaleOrderStateProps = {
  state: SaleOrderState | null;
};
function SaleOrderStateIndicator({ state }: SaleOrderStateProps) {
  var text = "draft";
  var color = "rgba(0,0,0,.7)";
  switch (state) {
    case SaleOrderState.draft:
      color = "#fbc02d";
      text = "quotation";
      break;
    case SaleOrderState.sent:
      color = "#e64a19";
      text = "sent";
      break;
    case SaleOrderState.sale:
      color = "#303f9f";
      text = "sale";
      break;
    case SaleOrderState.done:
      color = "#388e3c";
      text = "done";
      break;
    case SaleOrderState.cancel:
      color = "#d32f2f";
      text = "cancelled";
      break;
  }
  return  <Chip  size="small" sx={{backgroundColor:color,color:"#fff"}} label={text} />
}

export default SaleOrderStateIndicator;
