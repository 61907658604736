import { useMutation, useQuery } from "@apollo/client";
import * as React from "react";
import {
  permissionsQuery,
  updateUserMutation,
  updateUserPermissionsMutation,
  userQuery,
} from "./graphql";
import { Permissions } from "./__generated__/Permissions";
import { User, UserVariables, User_userQuery_user } from "./__generated__/User";
import EditDialog from "./EditDialog";
import { UpdateUser, UpdateUserVariables } from "./__generated__/UpdateUser";
import { Users_userQuery_users_edges } from "./__generated__/Users";
import {
  UpdateUserPermission,
  UpdateUserPermissionVariables,
} from "./__generated__/UpdateUserPermission";
import SetPasswordDialog from "./SetPasswordDialog";
import {
  Avatar,
  Box,
  Checkbox,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@mui/material";
import { VpnKey } from "@mui/icons-material";
import { useUserOutletContext } from ".";

const _sx = {
  scroller: {
    flex: 1,
    overflow: "auto",
  },
};

function UserDetail() {
  const { user_id, usersVariables } = useUserOutletContext();
  const { data: permissionsQueryResult } = useQuery<Permissions, {}>(
    permissionsQuery
  );
  const { data: userQueryResult } = useQuery<User, UserVariables>(userQuery, {
    variables: {
      id: user_id.toString(),
    },
    fetchPolicy: "network-only",
  });
  const user: User_userQuery_user | null | undefined = userQueryResult?.userQuery?.user;

  const [isEditDialogOpen, setIsEditDialogOpen] = React.useState(false);
  const openEditDialog = React.useCallback(() => {
    setIsEditDialogOpen(true);
  }, [setIsEditDialogOpen]);
  const closeEditDialog = React.useCallback(() => {
    setIsEditDialogOpen(false);
  }, [setIsEditDialogOpen]);

  const [isPasswordDialogOpen, setIsPasswordDialogOpen] = React.useState(false);
  const openPasswordDialog = React.useCallback(() => {
    setIsPasswordDialogOpen(true);
  }, [setIsPasswordDialogOpen]);
  const closePasswordDialog = React.useCallback(() => {
    setIsPasswordDialogOpen(false);
  }, [setIsPasswordDialogOpen]);

  const [updateUser, { loading: updatingUser }] = useMutation<
    UpdateUser,
    UpdateUserVariables
  >(updateUserMutation);
  const handleUpdate = React.useCallback(
    async (value: Users_userQuery_users_edges) => {
      const { code, fullName, odooPassword, odooUserName, active, companyId, companyName, id } = value;
      await updateUser({
        variables: {
          id: id!,
          user: {
            code,
            fullName,
            odooPassword: odooPassword!,
            odooUserName: odooUserName!,
            companyId, 
            companyName,
            active,
          },
        },
      });
    },
    [updateUser]
  );

  const [updatingPermissionId, setUpdatingPermissionId] = React.useState("");
  const [updateUserPermissions, { loading: updatingUserPermissions }] =
    useMutation<UpdateUserPermission, UpdateUserPermissionVariables>(
      updateUserPermissionsMutation
    );
  const handlePermissionUpdate = React.useCallback(
    (permissionId: string, checked: boolean) => {
      const { id, permissions } = user || {};
      const toUpdate: number[] = permissions?.length ? permissions
        .filter((p: any) => checked || (!checked && p.id !== permissionId))
        .map((p: any) => Number.parseInt(p.id)): [];
      if (checked) {
        toUpdate.push(Number.parseInt(permissionId));
      }
      setUpdatingPermissionId(permissionId);
      updateUserPermissions({
        variables: {
          id: id!,
          permissions: toUpdate,
        },
      });
    },
    [user, updateUserPermissions, setUpdatingPermissionId]
  );
  const listItems = React.useMemo(() => {
    return permissionsQueryResult?.userQuery?.permissions?.map((permission) => {
      const checked = user?.permissions?.find((p) => p.id === permission.id);
      return (
        <ListItem
          key={permission.id}
          role={undefined}
          dense
          button
          onClick={() => {
            handlePermissionUpdate(permission.id!, !checked);
          }}
        >
          <ListItemIcon>
            {updatingUserPermissions &&
            updatingPermissionId === permission.id ? (
              <CircularProgress size={24} color="secondary" />
            ) : (
              <Checkbox
                edge="start"
                checked={!!checked}
                tabIndex={-1}
                disableRipple
              />
            )}
          </ListItemIcon>
          <ListItemText
            id={permission.id!}
            primary={permission.resource}
            secondary={permission.operation}
          />
          <ListItemSecondaryAction>
            <Typography>{permission.id}</Typography>
          </ListItemSecondaryAction>
        </ListItem>
      );
    });
  }, [
    permissionsQueryResult?.userQuery?.permissions,
    user?.permissions,
    handlePermissionUpdate,
    updatingUserPermissions,
    updatingPermissionId,
  ]);
  return (
    <Box sx={(theme) => ({
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints.down('sm')]: {
          flex: 1
      },
    })}>
      <List>
        <ListItem button onClick={openEditDialog}>
          <ListItemAvatar>
            <Avatar>{user ? user.fullName[0] : ""}</Avatar>
          </ListItemAvatar>
          <ListItemText primary={user?.code} secondary={user?.fullName} />
          <ListItemSecondaryAction>
            <IconButton
              edge="end"
              aria-label="edit"
              onClick={openPasswordDialog}
            >
              <VpnKey />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      </List>
      <Box sx={_sx.scroller}>
        <List>{listItems}</List>
      </Box>
      {user ? (
        <>
          <EditDialog
            initialValue={user}
            open={isEditDialogOpen}
            onClose={closeEditDialog}
            onSubmit={handleUpdate}
            loading={updatingUser}
          />
          <SetPasswordDialog
            userId={user.id!}
            open={isPasswordDialogOpen}
            onClose={closePasswordDialog}
          />
        </>
      ) : null}
    </Box>
  );
}

export default UserDetail;
