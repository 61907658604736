import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMatch, useNavigate } from "react-router-dom";
import { RootState } from "./store";
import { setUserSession } from "../features/site/userSessionSlice";
import ConfirmDialog from "../components/ConfirmDialog";
import ShoppingCart from "@mui/icons-material/ShoppingCart";
import Build from "@mui/icons-material/Build";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import People from "@mui/icons-material/People";
import Description from "@mui/icons-material/Description";
import AttachMoney from "@mui/icons-material/AttachMoney";
import Settings from "@mui/icons-material/Settings";
import Collapse from "@mui/material/Collapse";
import Star from "@mui/icons-material/Star";
import startOfMonth from "date-fns/startOfMonth";
import format from "date-fns/esm/fp/format/index.js"
import endOfMonth from "date-fns/endOfMonth";
import Switch from "@mui/material/Switch";
import ShoppingBag from "@mui/icons-material/ShoppingBag"
import QrCode from "@mui/icons-material/QrCodeScanner"
import { toggleDarkMode } from "../features/site/siteSlice";

type DrawerMenuProps = {
  icon: React.ReactElement;
  text: string;
  url?: string;
  nested?: boolean;
};

function DrawerMenuItem(props: DrawerMenuProps) {
  const { icon, text } = props;
  return (
    <React.Fragment>
      <ListItemIcon sx={{ color: "inherit" }}>{icon}</ListItemIcon>
      <ListItemText primary={text} />
    </React.Fragment>
  );
}

function SelectableDrawerMenuItem(
  props: DrawerMenuProps & { url: string }
) {
  const { url, nested } = props;
  const match = useMatch(url);
  const navigate = useNavigate();
  return (
    <ListItem
      sx={{ paddingLeft: nested ? 4 : undefined, '&.Mui-selected': { color: "primary.main" } }}
      button
      selected={Boolean(match)}
      onClick={() => {
        navigate(url);
      }}
    >
      <DrawerMenuItem {...props} />
    </ListItem>
  );
}
function ConfigurationDrawerMenuItem() {
  const [open, setOpen] = React.useState(false);
  return (
    <React.Fragment>
      <ListItem
        button
        onClick={() => {
          setOpen(!open);
        }}
      >
        <DrawerMenuItem icon={<Build />} text="Configuration" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <SelectableDrawerMenuItem
            url="/users"
            text="Users"
            nested
            icon={<People />}
          />
          <SelectableDrawerMenuItem
            url="/settings"
            text="Settings"
            nested
            icon={<Settings />}
          />
        </List>
      </Collapse>
    </React.Fragment>
  );
}

function EcommerceDrawerMenuItem()
{
  const [open, setOpen] = React.useState(false);
  return (
    <React.Fragment>
      <ListItem
        button
        onClick={() => {
          setOpen(!open);
        }}
      >
        <DrawerMenuItem icon={<ShoppingBag />} text="Ecommerce" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <SelectableDrawerMenuItem
            url="/ecommerce"
            text="Products"
            nested
            icon={<QrCode />}
          />
        </List>
      </Collapse>
    </React.Fragment>
  );
}
function ReportsDrawerMenuItem() {
  const [open, setOpen] = React.useState(false);
  return (
    <React.Fragment>
      <ListItem
        button
        onClick={() => {
          setOpen(!open);
        }}
      >
        <DrawerMenuItem icon={<Description />} text="Reports" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <SelectableDrawerMenuItem
            url="/reports/invoices"
            text="Invoices"
            nested
            icon={<Description />}
          />
          <SelectableDrawerMenuItem
            url="/reports/gp"
            text="Gross Profit"
            nested
            icon={<Description />}
          />
          <SelectableDrawerMenuItem
            url="/reports/purchase_detail"
            text="Purchase Details"
            nested
            icon={<Description />}
          />
          <SelectableDrawerMenuItem
            url="/reports/stock_valuation"
            text="Inventory Valuation"
            nested
            icon={<Description />}
          />          
          <SelectableDrawerMenuItem
            url="/reports/check_landed_costs"
            text="Check Landed Costs"
            nested
            icon={<Description />}
          />
          <SelectableDrawerMenuItem
            url="/reports/cash_book"
            text="Cash Book"
            nested
            icon={<Description />}
          />
          <SelectableDrawerMenuItem
            url={`/reports/inventory_report/${format("yyyy-MM-dd", startOfMonth(new Date()))}/${format("yyyy-MM-dd", endOfMonth(new Date()))}?show_location=false`}
            text="Inventory"
            nested
            icon={<Description />}
          />
        </List>
      </Collapse>
    </React.Fragment>
  );
}




function SaleDrawerMenuItem() {
  const [open, setOpen] = React.useState(false);
  return (
    <React.Fragment>
      <ListItem
        button
        onClick={() => {
          setOpen(!open);
        }}
      >
        <DrawerMenuItem icon={<Description />} text="Sale" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <SelectableDrawerMenuItem
            url="/sale_orders"
            text="Sale Orders"
            icon={<ShoppingCart />}
            nested
          />
          <SelectableDrawerMenuItem
            url="/price_changes"
            text="Price Changes"
            icon={<Description />}
            nested
          />
          <SelectableDrawerMenuItem
            url="/promotions"
            text="Promotions"
            icon={<Star />}
            nested
          />
          <SelectableDrawerMenuItem
            url="/promotions/sales"
            text="Promotion Sales"
            icon={<Star />}
            nested
          />
        </List>
      </Collapse>
    </React.Fragment>
  );
}

function InventoriesDrawerMenuItem() {
  const [open, setOpen] = React.useState(false);
  return (
    <React.Fragment>
      <ListItem
        button
        onClick={() => {
          setOpen(!open);
        }}
      >
        <DrawerMenuItem icon={<Description />} text="Inventory" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <SelectableDrawerMenuItem
            url="/inventory/stock_pickings"
            text="Stock Picking"
            nested
            icon={<Description />}
          />
        </List>
      </Collapse>
    </React.Fragment>
  )
}

function DrawerContent() {
  const userSession = useSelector((state: RootState) => state.site.userSession);
  const darkMode = useSelector((state:RootState)=>state.site.darkMode);
  const [logoutConfirmDialogOpen, setLogoutConfirmDialog] = React.useState(
    false
  );
  const dispatch = useDispatch();
  return (
    <div>
      <List>
        <ListItem
          button
          onClick={() => {
            setLogoutConfirmDialog(true);
          }}
        >
          <ListItemAvatar>
            <Avatar>{(userSession.fullName ?? " ")[0]}</Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={userSession.code}
            secondary={userSession.fullName}
          />
        </ListItem>
      </List>
      <Divider />
      <List>
        <SaleDrawerMenuItem />
        <SelectableDrawerMenuItem
          url="/products"
          text="Products"
          icon={<AttachMoney />}
        />
        <InventoriesDrawerMenuItem />
        <ReportsDrawerMenuItem />
        <EcommerceDrawerMenuItem/>
        <ConfigurationDrawerMenuItem />
        <ListItem>
          <ListItemIcon>
            <Switch checked={darkMode} onChange={()=>{dispatch(toggleDarkMode())}}/>
          </ListItemIcon>
          <ListItemText primary="Dark Mode" />
        </ListItem>
      </List>
      <ConfirmDialog
        title="Confirm log out!"
        message="Are you sure to log out?"
        acceptLabel="yes"
        rejectLabel="no"
        open={logoutConfirmDialogOpen}
        onAccepted={() => {
          dispatch(
            setUserSession({
              authenticated: false,
              accessToken: "",
            })
          );
          setLogoutConfirmDialog(false);
        }}
        onRejected={() => {
          setLogoutConfirmDialog(false);
        }}
      />
    </div>
  );
}

export default DrawerContent;