import Divider from "@mui/material/Divider/Divider";
import Grid from "@mui/material/Grid/Grid";
import IconButton from "@mui/material/IconButton/IconButton";
import accounting from "accounting";
import * as React from "react";
import SaleOrderInvoicesGrid from "./SaleOrderInvoicesGrid";
import { SaleOrder_saleOrderQuery_saleOrder } from "./__generated__/SaleOrder";
import ClipboardIcon from "@mui/icons-material/FileCopy"
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { AccountMoveType } from "../../types/global-types";
import { saleSettingActions } from "../setting/saleSettingSlice";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import NumberEditor from "../../components/NumberEditor";
const _sx={
    fieldLabel: {
        fontWeight: "bold",
        marginRight: 1,
        width: 150,
        display: "inline-block",
    },
    field: {
        padding: .5,
        display: "flex",
        flexWrap: "nowrap",
    },
    fieldValue: {
        textAlign: "right",
        display: "block",
        width: 90,
    },
    root: {
        flex: 0,
        padding: 1,
        backgroundColor:"rgba(0,0,0,.1)"
    },
    scroller: {
        flex: 1,
        overflow: "auto"
    },
    percentEditor: {
        width: 32,
        padding:0,
        "& .MuiOutlinedInput-input":{
            padding:.5
        }
    },
    selectedTotalLabel: {
        width: 70,
        textAlign: "right",
        display: "inline-block"
    }
};

export type SaleOrderSummaryViewProps = {
    saleOrder: SaleOrder_saleOrderQuery_saleOrder;
    selectedTotal: number;
};

function SaleOrderSummaryView({ saleOrder, selectedTotal }: SaleOrderSummaryViewProps) {
    const { amount_total, amount_untaxed, invoices } = saleOrder;
    const percent = useSelector((state:RootState)=> state.saleSetting.percentage);
    const computed = React.useMemo(() => selectedTotal * percent / 100, [selectedTotal, percent]);
    const amount_Due = React.useMemo(() => {
        var result = 0;
        invoices.forEach((invoice) => {
            result +=
                invoice.amount_residual *
                (invoice.move_type === AccountMoveType.out_refund ? -1 : 1);
        });
        return result;
    }, [invoices]);
    const dispatch = useDispatch();
    const setPercent = React.useCallback((value:number)=>{
        dispatch(saleSettingActions.setPercentage(value));
    },[dispatch]);
    const copyTotal = React.useCallback(() => {
        navigator.clipboard.writeText(accounting.formatNumber(selectedTotal,0).replace(",",""));
    }, [selectedTotal]);
    const copyComputed = React.useCallback(() => {
        navigator.clipboard.writeText(accounting.formatNumber(computed,0).replace(",",""));
    }, [computed]);
    const payments = React.useMemo(() => {
        return invoices?.map((invoice) => {
            if (invoice.invoice_payments_widget) {
                var widget = invoice.invoice_payments_widget;
                var payments = widget.content as any[];
                return payments
                    ?.slice()
                    ?.reverse()
                    ?.map((payment) => (
                        <Box sx={_sx.field} key={payment.id.toString()}>
                            <Typography sx={_sx.fieldLabel}>
                                {(()=>{
                                    if(payment.name){
                                        return `${payment.name.includes("Reimbursement") || payment.ref.includes("RINV/")
                                        ? "Reimburse"
                                        : "Payment"} (${payment.journal_name})`
                                    }else
                                        return payment.ref;
                                })()}
                            </Typography>
                            <Typography sx={_sx.fieldValue}>
                                {payment.name?.includes("Reimbursement") || payment.ref.includes("RINV/")
                                    ? `${accounting.formatNumber(payment.amount, 0)}`
                                    : `-${accounting.formatNumber(payment.amount, 0)}`}
                            </Typography>
                        </Box>
                    ));
            } else {
                return null;
            }
        });
    }, [invoices]);
    return (<Grid container direction="column" sx={_sx.root}>
        <Box>
            <Box sx={_sx.field}>
                <Typography sx={_sx.fieldLabel}>Subtotal:</Typography>
                <Typography sx={_sx.fieldValue}>
                    {accounting.formatNumber(amount_untaxed, 0)}
                </Typography>
            </Box>
            <Box sx={_sx.field}>
                <Typography sx={_sx.fieldLabel}>Grand total:</Typography>
                <Typography sx={_sx.fieldValue}>
                    {accounting.formatNumber(amount_total, 0)}
                </Typography>
            </Box>
            {payments}
            <Box sx={_sx.field}>
                <Typography sx={_sx.fieldLabel}>Amount Due:</Typography>
                <Typography sx={_sx.fieldValue}>
                    {accounting.formatNumber(amount_Due, 0)}
                </Typography>
            </Box>
        </Box>
        <Divider />
        <Box sx={_sx.scroller}>
            <SaleOrderInvoicesGrid saleOrder={saleOrder} />
        </Box>
        <Box>
            <Typography sx={_sx.selectedTotalLabel}>{accounting.formatNumber(selectedTotal, 0)}</Typography><IconButton onClick={copyTotal} color="secondary" size="small" aria-label="copy total" >
                <ClipboardIcon />
            </IconButton>
            <NumberEditor size="small" sx={_sx.percentEditor} value={percent} numberPrecision={0} onValidating={(v)=>v!== undefined && v>=0? "":"Invalid value"} onValidated={setPercent as any} /> 
            <Typography sx={{display:"inline"}}>%</Typography>
            <Typography sx={_sx.selectedTotalLabel}>{accounting.formatNumber(computed, 0)}</Typography>
            <IconButton onClick={copyComputed} color="secondary" size="small" aria-label="copy computed" >
                <ClipboardIcon />
            </IconButton>
        </Box>
    </Grid>);
}

export default SaleOrderSummaryView;