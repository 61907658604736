import { ApolloConsumer } from "@apollo/client/react/context/ApolloConsumer";
import { format } from "date-fns";
import * as React from "react";
import ListItem from "@mui/material/ListItem/ListItem";
import ListItemText from "@mui/material/ListItemText/ListItemText";
import Divider from "@mui/material/Divider/Divider";
import Typography from "@mui/material/Typography";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import { useQuery } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
import ApolloVirtualizedTable, {
  ApolloListResult,
  ApolloVirtualizedTableProps,
  DefaultPageInfoValue,
  GridColumn,
  ListItemRenderProps,
} from "mui-apollo-virtualized-table";
import {
  StockPickings,
  StockPickingsVariables,
  StockPickings_inventoryQuery_pickings_edges,
} from "../../sale/__generated__/StockPickings";
import { stockPickingsQuery } from "../../sale/graphql";
import StockPickingStateIndicator from "../../sale/PickingStateIndicator";

const StockPickingVirtualizedGrid = ApolloVirtualizedTable as React.ElementType<
  ApolloVirtualizedTableProps<StockPickings_inventoryQuery_pickings_edges>
>;
export type StockPickingGridProps = {
  variables: StockPickingsVariables;
  onVariablesChanged: (variables: StockPickingsVariables) => void;
};

const _sx = {
  loadingIndicator: {
    backgroundColor: "#DDDDDD",
    color: "#DDDDDD",
    width: 150,
    display: "inline",
  },
};

function StockPickingGrid({
  variables = { pagination: { pageSize: 50 } },
  onVariablesChanged,
}: StockPickingGridProps) {
  const [columns, setColumns] = React.useState<
    ReadonlyArray<GridColumn<StockPickings_inventoryQuery_pickings_edges>>
  >([
    {
      label: "Reference",
      key: "name",
      width: 150,
      sortable: true
    },

    {
      label: "From",
      key: "location_id",
      width: 200,
      sortable: true,
      hideAt:1000,
      format: ({ rowData }) => rowData.location_id?.display_name,
    },
    {
      label: "To",
      key: "location_dest_id",
      width: 200,
      hideAt:750,
      sortable: true,
      format: ({ rowData }) => rowData.location_dest_id?.display_name,
    },
    {
      label: "Contact",
      key: "partner_id",
      width: 200,
      sortable: true,
      format: ({ rowData }) => rowData.name.includes("/INT/")? (`${rowData?.location_dest_id?.display_name}${(rowData.partner_id?.display_name? ` - ${rowData.partner_id?.display_name}`:"")}`):rowData?.partner_id?.display_name
    },
    {
      label: "Date",
      key: "date_done",
      width: 100,
      sortable: true,
      format: ({ rowData }) => format(new Date(rowData.date_done), "dd/MM/yyyy"),
    },
    {
      label: "Source Document",
      key: "origin",
      width: 150,
      flexGrow:1,
      sortable: true,
    },
    {
      label: "Status",
      key: "state",
      width: 100,
      textAlign: "right",
      sortable: true,
      format: ({ rowData }) => (
        <StockPickingStateIndicator state={rowData.state} />
      ),
    },
  ]);
  const [selectedIndex, setSelectedIndex] = React.useState(-1);
  const listItemRenderer = React.useCallback(
    (
      renderProps: ListItemRenderProps<StockPickings_inventoryQuery_pickings_edges>
    ) => {
      const { rowData, key, style, className, onClick } = renderProps;
      if (rowData) {
        const { state, name, partner_id } = rowData;
        const splitted = rowData.origin?.split("#") ?? [];
        return (
          <div onClick={onClick} style={style} className={className} key={key}>
            <ListItem>
              <ListItemText
                primary={
                  <>
                    {name} <StockPickingStateIndicator state={state} />
                  </>
                }
                secondary={rowData.name.includes("/INT/")? (`${rowData?.location_dest_id?.display_name}${(rowData.partner_id?.display_name? ` - ${rowData.partner_id?.display_name}`:"")}`):rowData?.partner_id?.display_name}
              />
            </ListItem>
            <ListItemSecondaryAction>
              <ListItemText
                primary={splitted?.length > 0 ? splitted[0] : ""}
              />
            </ListItemSecondaryAction>
            <Divider />
          </div>
        );
      } else {
        return (
          <div style={style} key={key}>
            <ListItem>
              <ListItemText
                primary={
                  <Typography sx={_sx.loadingIndicator}>
                    A...........................
                  </Typography>
                }
                secondary={
                  <Typography sx={_sx.loadingIndicator}>A..........</Typography>
                }
              />
            </ListItem>
            <Divider />
          </div>
        );
      }
    },
    []
  );

  const { data: stockPickingsQueryResult } = useQuery<
    StockPickings,
    StockPickingsVariables
  >(stockPickingsQuery, { fetchPolicy: "cache-only", variables });
  const { id: pickingId } = useParams<{ id: string }>();

  const handleCommandKeyUp = React.useCallback(
    (e: DocumentEventMap["keyup"]) => {
      const products =
        stockPickingsQueryResult?.inventoryQuery?.pickings?.edges ?? [];
      switch (e.key) {
        case "ArrowUp":
          if (pickingId) {
            setSelectedIndex(Math.max(0, selectedIndex - 1));
            e.stopPropagation();
          }
          break;
        case "ArrowDown":
          if (pickingId) {
            setSelectedIndex(Math.min(selectedIndex + 1, products.length));
            e.stopPropagation();
          }
          break;
      }
    },
    [
      stockPickingsQueryResult?.inventoryQuery?.pickings?.edges,
      pickingId,
      selectedIndex,
    ]
  );
  React.useEffect(() => {
    document.addEventListener("keyup", handleCommandKeyUp);
    return () => {
      document.removeEventListener("keyup", handleCommandKeyUp);
    };
  }, [handleCommandKeyUp]);

  const navigate = useNavigate();

  return (
    <ApolloConsumer>
      {(client) => (
        <StockPickingVirtualizedGrid
          onRowClick={(item, index) => {
            navigate(`/inventory/stock_pickings/${item.id}`);
          }}
          apolloClient={client as any}
          listItemHeight={72}
          listItemRenderer={listItemRenderer}
          listModeBreakPoint={600}
          onColumnPropsChanged={(columns, orderBy: any) => {
            const newVariables: StockPickingsVariables = {
              ...variables,
              orderBy,
            };
            setColumns(columns);
            onVariablesChanged(newVariables);
          }}
          selectedItems={[selectedIndex]}
          scrollToIndex={selectedIndex}
          columns={columns}
          graphqlQuery={stockPickingsQuery}
          variables={variables}
          pageSize={variables.pagination?.pageSize!}
          parseListFromQueryResult={(queryResult: StockPickings) => {
            const list: ApolloListResult<StockPickings_inventoryQuery_pickings_edges> = queryResult.inventoryQuery.pickings ?? {
                edges: [],
                pageInfo: DefaultPageInfoValue,
              };
            return list;
          }}
          onLoadMore={(pageInfo) => {
            return {
              ...variables,
              pagination: {
                page: pageInfo.page,
                pageSize: pageInfo.pageSize,
              },
            };
          }}
        />
      )}
    </ApolloConsumer>
  );
}

export default StockPickingGrid;
