import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import format from "date-fns/format";
import { InvoiceStatus, SaleOrderState } from "../../types/global-types";
import saleOrderLineSlice, {
  addOrderLine,
  editOrderLinePrice,
  editOrderLineQty,
  editOrderLineSaleperson,
  saleOrderLineDefaultValue,
  SaleOrderLineUpdateSalepersonPayload,
} from "./saleOrderLineSlice";
import { Customers_partnerQuery_customers_edges } from "./__generated__/Customers";
import {
  SaleOrder_saleOrderQuery_saleOrder,
  SaleOrder_saleOrderQuery_saleOrder_order_lines,
} from "./__generated__/SaleOrder";
import { Optional } from "utility-types";
import config from "../../config.json";
export type SaleOrderLineUpdateQtyPayload = {
  index: number;
  qty: number;
};

export type SaleOrderLineUpdatePricePayload = {
  index: number;
  price: number;
};

export type SaleOrderReduxState = Optional<
  SaleOrder_saleOrderQuery_saleOrder,
  "warehouse_id"
> & {
  hasChanges: boolean;
  deleted_order_lines: number[];
  totalOrdered: number;
  totalDelivered: number;
  totalInvoiced: number;
  orderDate: string;
};

export const saleOrderDefaultValue: SaleOrderReduxState = {
  hasChanges: false,
  id: "",
  name: "",
  date_order: format(new Date(), "yyyy-MM-dd"),
  orderDate: format(new Date(), config.shortDateFormat),
  amount_total: 0,
  amount_untaxed: 0,
  state: SaleOrderState.draft,
  client_order_ref: "",
  note: "",
  picking_ids: [],
  saleperson_name: "",
  cashier_name: "",
  origin: "",
  analytic_account_id: null,
  warehouse_id: undefined,
  team_id: null,
  invoice_status: InvoiceStatus.no,
  pickings: [],
  partner: {
    id: "43",
    name: "Showroom Customer",
    phone: ".",
    title: null,
    email: "",
    township: null,
    street: "",
    ref: "",
  },
  order_lines: [],
  invoices: [],
  deleted_order_lines: [],
  totalOrdered: 0,
  totalDelivered: 0,
  totalInvoiced: 0,
};

function calculateSummary(state: SaleOrderReduxState) {
  const order_lines = state.order_lines ?? [];
  const summary = order_lines.reduce(
    ({ amount_total, orderedQty, deliveredQty, invoicedQty }, current) => ({
      amount_total: amount_total + (current.price_subtotal ?? 0),
      orderedQty: orderedQty + (current.product_uom_qty ?? 0),
      deliveredQty: deliveredQty + (current.qty_delivered ?? 0),
      invoicedQty: invoicedQty + (current.qty_invoiced ?? 0),
    }),
    { amount_total: 0, orderedQty: 0, deliveredQty: 0, invoicedQty: 0 }
  );
  state.amount_untaxed = state.amount_total = summary.amount_total;
  state.totalOrdered = summary.orderedQty;
  state.totalInvoiced = summary.invoicedQty;
  state.totalDelivered = summary.deliveredQty;
  return state;
}

export const saleOrderSlice = createSlice({
  name: "saleOrder",
  initialState: saleOrderDefaultValue,
  reducers: {
    setSaleOrder: (
      state,
      action: PayloadAction<Partial<SaleOrder_saleOrderQuery_saleOrder>>
    ) => {
      Object.assign(state, action.payload);
      const orderDate =
        action.payload?.date_order ?? state.date_order
          ? new Date(action.payload?.date_order ?? state.date_order)
          : new Date();
      state.date_order = format(orderDate, "yyyy-MM-dd");
      state.orderDate = format(orderDate, config.shortDateFormat);
      state.deleted_order_lines = [];
      state.hasChanges = false;
      calculateSummary(state);
    },
    updateSaleOrder: (
      state,
      action: PayloadAction<Partial<SaleOrder_saleOrderQuery_saleOrder>>
    ) => {
      Object.assign(state, action.payload);
      const orderDate =
        action.payload?.date_order ?? state.date_order
          ? new Date(action.payload?.date_order ?? state.date_order)
          : new Date();
      state.date_order = format(orderDate, "yyyy-MM-dd");
      state.orderDate = format(orderDate, config.shortDateFormat);
      state.hasChanges = true;
      calculateSummary(state);
    },
    setCustomer: (
      state,
      action: PayloadAction<Customers_partnerQuery_customers_edges>
    ) => {
      state.partner = action.payload;
      state.hasChanges = true;
    },
    deleteSaleOrderLine: (state, action: PayloadAction<number>) => {
      const order_lines = state.order_lines ?? [];
      const deletedOrderLine = order_lines[action.payload];
      order_lines.splice(action.payload, 1);
      state.order_lines = order_lines;
      if (deletedOrderLine.id) {
        const deleted_order_lines = state.deleted_order_lines ?? [];
        deleted_order_lines.push(Number.parseInt(deletedOrderLine.id));
      }
      state.hasChanges = true;
      calculateSummary(state);
    },
    deleteLastOrderLine: (state) => {
      const order_lines = state.order_lines ?? [];
      if (order_lines.length) {
        const index = order_lines.length - 1;
        const deletedOrderLine = order_lines[index];
        order_lines.splice(index, 1);
        state.order_lines = order_lines;
        if (deletedOrderLine.id) {
          const deleted_order_lines = state.deleted_order_lines ?? [];
          deleted_order_lines.push(Number.parseInt(deletedOrderLine.id));
        }
        state.hasChanges = true;
        calculateSummary(state);
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addOrderLine, (state, action) => {
        const order_lines = state.order_lines ?? [];
        const newOrderLine = saleOrderLineSlice(
          saleOrderLineDefaultValue,
          action
        ) as SaleOrder_saleOrderQuery_saleOrder_order_lines;
        order_lines.push(newOrderLine);
        state.order_lines = order_lines;
        state.hasChanges = true;
        calculateSummary(state);
      })
      .addMatcher(
        (action) =>
          [editOrderLinePrice.type, editOrderLineQty.type, editOrderLineSaleperson.type].includes(
            action.type
          ),
        (
          state,
          action: PayloadAction<
            SaleOrderLineUpdateQtyPayload | SaleOrderLineUpdatePricePayload | SaleOrderLineUpdateSalepersonPayload
          >
        ) => {
          const order_lines = state.order_lines ?? [];
          const { index } = action.payload;
          var orderLine = state.order_lines?.[index];
          if (!orderLine) return state;
          const newSaleOrderLine = saleOrderLineSlice(
            orderLine,
            action
          ) as SaleOrder_saleOrderQuery_saleOrder_order_lines;
          newSaleOrderLine.price_subtotal =
            newSaleOrderLine.price_unit *
            (newSaleOrderLine.product_uom_qty ?? 1);
          order_lines[index] = newSaleOrderLine;
          state.order_lines = order_lines;
          state.hasChanges = true;
          calculateSummary(state);
        }
      );
  },
});

export const saleOrderActions = {
  ...saleOrderSlice.actions,
  editOrderLinePrice,
  editOrderLineQty,
  editOrderLineSaleperson,
  addOrderLine,
};
export default saleOrderSlice.reducer;
