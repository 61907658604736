import {gql} from "@apollo/client";

export const valuationBreakdownQuery = gql`
  query ValuationBreakdowns(
    $where: ProductValuationBreakdownWhereInput!
    $orderBy: [ProductValuationBreakdownOrderBy!]
    $where_v_out:StockValuationLayerOutWhereInput!
  ) {
    productQuery {
      id
      valuationBreakdowns(where: $where, orderBy: $orderBy) {
        id
        layer_id
        valuation_type
        create_date
        name
        quantity
        unit_cost
        value
      }
      valuation_out(where:$where_v_out){
        id
        quantity
        outgoing{
          id
          stock_move_id{
            id
            display_name
          }
          create_date
          quantity
          final_cost
          final_value
          description
        }
      }
    }
  }
`;

export const changePriceMutation = gql`
mutation ChangePrice($product_id:Int!,$price_list_id:Int!,$price:Float!,$company_ids:[Int!]){
  productMutation{
    id
    changePrice(product_id:$product_id,price_list_id:$price_list_id,price:$price,company_ids:$company_ids){
      id
      item{
        barcode
        default_code
        id
        list_price
        name
        quantity
        reserved_quantity
        qty_available
        type
        uom
        uom_id
      }
      product{
        id
        prices{
          id
          price
          price_list{
            id
            name        
          }
          product{
            id
            list_price  
          }
          price_history{
            id
            on_date
            by_user_id{
              id
              display_name
            }
            new_price
          }
        }
      }
    }
  }
}
`;

export const setValuationAllowNoCostMutation = gql`
mutation SetValuationAllowNoCost($allowed:Boolean!,$id:ID!){
  productMutation{
    id
    setValuationAllowNoCost(id:$id, allowed:$allowed){
      id
      no_cost
      no_cost_by_id
      no_cost_by_name
    }
  }
}
`;

export const setValuationCostMutation = gql`
mutation SetValuationCost($value:Float!,$id:ID!){
  productMutation{
    id
    setValuationCost(id:$id, value:$value){
      id
      cost_by_id
      cost_by_name
      quantity
      value
      remaining_qty
      remaining_value
    }
  }
}
`;