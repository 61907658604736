import { ApolloError, useMutation, useQuery } from "@apollo/client";
import Grid from "@mui/material/Grid/Grid";
import * as React from "react";
import { Product, ProductVariables, Product_productQuery_product, Product_productQuery_product_incoming, Product_productQuery_product_prices_price_history, Product_productQuery_product_purchase_history, Product_productQuery_product_quants, Product_productQuery_product_valuation_in } from "../sale/__generated__/Product";
import { productQuery } from "../sale/graphql";
import { ChangePrice, ChangePriceVariables } from "./__generated__/ChangePrice";
import { changePriceMutation, setValuationAllowNoCostMutation, setValuationCostMutation, valuationBreakdownQuery } from "./graphql";
import Button from "@mui/material/Button/Button";
import Tab from "@mui/material/Tab/Tab";
import TextField from "@mui/material/TextField/TextField";
import Tabs from "@mui/material/Tabs/Tabs";
import Select, { SelectChangeEvent } from "@mui/material/Select/Select";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import AppBar from "@mui/material/AppBar/AppBar";
import List from "@mui/material/List/List";
import ListItem from "@mui/material/ListItem/ListItem";
import ListItemText from "@mui/material/ListItemText/ListItemText";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction/ListItemSecondaryAction";
import accounting from "accounting";
import { format } from "date-fns/fp";
import ListItemIcon from "@mui/material/ListItemIcon/ListItemIcon";
import WarningIcon from "@mui/icons-material/Warning";
import Dialog from "@mui/material/Dialog/Dialog";
import DialogTitle from "@mui/material/DialogTitle/DialogTitle";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import DialogActions from "@mui/material/DialogActions/DialogActions";
import { SetValuationAllowNoCost, SetValuationAllowNoCostVariables } from "./__generated__/SetValuationAllowNoCost";
import { SetValuationCost, SetValuationCostVariables } from "./__generated__/SetValuationCost";
import useHasPermission from "../../app/useHasPermission";
import Box from "@mui/material/Box";
import NumberEditor from "../../components/NumberEditor";
import Typography from "@mui/material/Typography";
import ConfirmButton from "../../components/ConfirmButton";
import ListItemButton from "@mui/material/ListItemButton";
import { useParams } from "react-router-dom";
import { useProductOutletContext } from ".";
import Skeleton from "@mui/material/Skeleton";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { CogsLines_saleOrderQuery_orderLine_costs } from "../reports/__generated__/CogsLines";
import Divider from "@mui/material/Divider";
import { ValuationBreakdowns, ValuationBreakdownsVariables } from "./__generated__/ValuationBreakdowns";
import { ProductValuationBreakdownType } from "../../types/global-types";

const _sx = {
    textAlignRight: {
        textAlign: "right"
    },
    fill: {
        display: "flex",
        flexDirection: "column",
        flex: 1,
    },
    position_relative: {
        position: "relative"
    },
    historyList: {
        position: "absolute",
        width: "100%"
    },
    scroller: {
        overflow: "auto"
    },
    price_list_select: {
        marginTop: 1,
        marginLeft: 2,
        marginRight: 2
    },
    fieldLabel: {
        fontWeight: "bold",
        marginRight: 1,
        width: 150,
        display: "inline-block",
        color: "inherit"
    },
    field: {
        padding: 0.5,
    },
    fieldValue: {
        display: "inline-block"
    },
    numberFieldValue: {
        display: "inline-block",
        textAlign: "right",
        width: 100
    }
};

function General({ product, onPriceChanged, onError, company_ids }: {
    product: Product_productQuery_product, onError: (error: ApolloError, message: string) => void, onPriceChanged: (savedProductId: string) => void,
    company_ids?: number[]
}) {

    const [readOnly, setReadonly] = React.useState(true);
    const allowedChangePrice = useHasPermission("Product_ChangePrice");
    React.useEffect(() => {
        setReadonly(true);
    }, [product]);
    const validatePrice = React.useCallback((value?: number | null) => { return value && value > 0 ? "" : "Price is invalid." }, []);
    const [changePrice] = useMutation<ChangePrice, ChangePriceVariables>(changePriceMutation, {
        onError: (e) => {
            onError(e, "Cannot change price.")
        },
        onCompleted: (data) => { onPriceChanged(data?.productMutation?.changePrice?.id!); }
    });
    type CompanyQuant = { name: string, quant: Product_productQuery_product_quants[] };
    var quants = React.useMemo(() => {
        var company: { [key: string]: CompanyQuant } = {};
        product.quants?.forEach(q => {
            var c = company[q.company_name];
            if (!c) {
                company[q.company_name] = { name: q.company_name, quant: [] };
                c = company[q.company_name];
            }
            c.quant = [...c.quant, q];
        });
        return company;
    }, [product.quants]);
    return <Box sx={{ ..._sx.fill, ..._sx.scroller, paddingLeft: 2, paddingRight: 2 }}>
        <Grid container item direction="column" >
            <ListItem>
                <ListItemText primary={product?.default_code} secondary={product?.name} />
            </ListItem>
            <Typography variant="subtitle1" sx={{ marginTop: 1 }}>Prices</Typography>
            {
                product?.prices?.map((price) => (<NumberEditor size="small" sx={{ marginTop: 1, marginBottom: 1, "& .MuiInputBase-input": _sx.textAlignRight }} InputProps={{ readOnly }} label={price.price_list?.name} placeholder={price.price_list?.name} numberPrecision={0} onValidated={(value) => {
                    changePrice({
                        variables: {
                            product_id: Number.parseInt(product.id),
                            price_list_id: Number.parseInt(price?.price_list?.id!),
                            price: value!,
                            company_ids
                        }
                    })
                }} key={price.id} value={price?.price ?? 0} onValidating={validatePrice} />))
            }
            {allowedChangePrice ? <Button onClick={() => { setReadonly(false); }}>Edit</Button> : null}

            {
                Object.keys(quants).map((key) => {
                    const q = quants[key];
                    return (<React.Fragment key={key}>
                        <Typography variant="subtitle1">{q.name}</Typography>
                        {
                            q.quant?.map(quant => <React.Fragment key={quant?.id}><TextField size="small" sx={{ marginTop: 1, marginBottom: 1, "& .MuiInputBase-input": _sx.textAlignRight }} key={quant?.id} value={`${quant.quantity_sum} ${product?.uom_id?.display_name}`} label={`${quant?.location_name} ${quant?.owner_name ?? ""}`} /></React.Fragment>)
                        }
                    </React.Fragment>);
                })
            }
        </Grid>
    </Box>;
}

function PriceHistory({ history }: { history: Product_productQuery_product_prices_price_history[] }) {
    const odooUrl = useSelector((state: RootState) => state.site.odooServerUrl);
    return (
        <Box sx={{ ..._sx.fill, ..._sx.position_relative, ..._sx.scroller }}><List sx={_sx.historyList} >
            {history?.map((h) => {
                return (
                    <ListItemButton key={h.id} onClick={()=>{
                        if(h.pricechange_id?.id)
                            window.open(`${odooUrl}/web#id=${h.pricechange_id.id}&model=tri.pricechange&view_type=form`, "_blank");
                    }} >
                        <ListItemText primary={h.by_user_id.display_name} secondary={format("dd/MM/yyyy hh:mm a", new Date(h.on_date))} />
                        <ListItemSecondaryAction>
                            <ListItemText secondary={h.pricechange_id?.display_name} primary={accounting.formatNumber(h.new_price, 0)} />
                        </ListItemSecondaryAction>
                    </ListItemButton>
                );
            })}
        </List></Box>);
}

function PurchasePriceHistory({ history }: { history: Product_productQuery_product_purchase_history[] }) {
    return (
        <Box sx={{ ..._sx.fill, ..._sx.position_relative, ..._sx.scroller }}><List sx={_sx.historyList} >
            {history?.map((h) => {
                return (
                    <ListItem key={h.id} >
                        <ListItemText primary={h.order_id.display_name} secondary={format("dd/MM/yyyy", new Date(h.date_order))} />
                        <ListItemSecondaryAction>
                            <ListItemText primary={`${accounting.formatNumber(h.price_unit, 0)} x ${accounting.formatNumber(h.product_uom_qty, 0)}`} />
                        </ListItemSecondaryAction>
                    </ListItem>
                );
            })}
        </List></Box>);
}

type ValuationEditDialogProps = {
    open: boolean;
    valuation: Product_productQuery_product_valuation_in;
    onClose: () => void;
};

export function ValuationEditDialog({ valuation, open, onClose }: ValuationEditDialogProps) {
    const [draft, setDraft] = React.useState<number | undefined>(valuation?.unit_cost ?? 0);
    React.useEffect(() => {
        setDraft(valuation?.unit_cost ?? 0);
    }, [setDraft, valuation?.unit_cost]);
    const [setValuationAllowNoCost, { loading: allowingNoCost }] = useMutation<SetValuationAllowNoCost, SetValuationAllowNoCostVariables>(setValuationAllowNoCostMutation);
    const [setValuationCost, { loading: settingCost }] = useMutation<SetValuationCost, SetValuationCostVariables>(setValuationCostMutation);
    const { data: breakdownResult, loading } = useQuery<ValuationBreakdowns,ValuationBreakdownsVariables >(valuationBreakdownQuery, { variables: { where_v_out: { origin_id__id: Number.parseInt(valuation?.id ?? "-1") }, where: { layer_id: Number.parseInt(valuation?.id ?? "-1") } }, fetchPolicy: "network-only", skip: !valuation?.id });
    const breakdowns = breakdownResult?.productQuery?.valuationBreakdowns ?? [];
    const valuation_out = breakdownResult?.productQuery?.valuation_out ?? [];
    const handleSetNoCost = React.useCallback(() => {
        if (valuation?.id) {
            setValuationAllowNoCost({
                variables: {
                    id: valuation.id,
                    allowed: !valuation.no_cost
                }
            });
        }
    }, [valuation, setValuationAllowNoCost]);
    const handleSetValuationCost = React.useCallback(() => {
        if (valuation?.id) {
            setValuationCost({
                variables: {
                    id: valuation.id,
                    value: draft ?? 0
                }
            });
        }
    }, [setValuationCost, valuation, draft]);
    const hasAllowNoCostPermission = useHasPermission("Product_MarkAsNoCost");
    const hasSetValuationCostPermission = useHasPermission("Product_SetValuationCost");
    const [selectedTab, setSelectedTab] = React.useState<"edit" | "breakdown" | "outgoing">("edit");
    const odooUrl = useSelector((state: RootState) => state.site.odooServerUrl);
    if (!valuation)
        return null;
    const { id, product_name: product, create_date, cost_by_name, final_cost, final_value, value, quantity, remaining_qty, remaining_value, stock_move_name } = valuation;
    const model_no = (product!.match(/\[([^\]]+)\]/) ?? [""])[1];
    const product_name = product!.replace(`[${model_no}]`, '');
    return (<Dialog open={open} maxWidth="sm" fullWidth>
        <DialogContent>
            <Tabs value={selectedTab} onChange={(_, value) => { setSelectedTab(value); }} >
                <Tab label="Valuation" value="edit" />
                <Tab label="Incoming" value="breakdown" />
                <Tab label="Outgoing" value="outgoing" />
            </Tabs>
            {
                (() => {
                    switch (selectedTab) {
                        case "edit":
                            return <Box padding={1}><Box sx={_sx.field}>
                                <Typography sx={_sx.fieldLabel}>Reference No:</Typography>
                                <Typography sx={_sx.fieldValue}>{id}</Typography>
                            </Box>
                                <Box sx={_sx.field}>
                                    <Typography sx={_sx.fieldLabel}>Model No:</Typography>
                                    <Typography sx={_sx.fieldValue}>{model_no}</Typography>
                                </Box>
                                <Box sx={_sx.field}>
                                    <Typography sx={_sx.fieldLabel}>Name:</Typography>
                                    <Typography sx={_sx.fieldValue}>{product_name}</Typography>
                                </Box>
                                <Box sx={_sx.field}>
                                    <Typography sx={_sx.fieldLabel}>Date:</Typography>
                                    <Typography sx={_sx.fieldValue}>{format("dd/MM/yyyy", new Date(create_date))}</Typography>
                                </Box>
                                <Box sx={_sx.field}>
                                    <Typography sx={_sx.fieldLabel}>Stock Move:</Typography>
                                    <Typography sx={_sx.fieldValue}>{stock_move_name}</Typography>
                                </Box>
                                <Box sx={_sx.field}>
                                    <Typography sx={_sx.fieldLabel}>Stock In Qty:</Typography>
                                    <Typography sx={_sx.numberFieldValue}>{accounting.formatNumber(quantity ?? 0)}</Typography>
                                </Box>
                                <Box sx={_sx.field}>
                                    <Typography sx={_sx.fieldLabel}>Unit Cost:</Typography>
                                    <NumberEditor disabled={!remaining_qty} size="small" value={draft} onValidated={setDraft} numberPrecision={0} />
                                </Box>
                                <Box sx={_sx.field}>
                                    <Typography sx={_sx.fieldLabel}>Stock In Value:</Typography>
                                    <Typography sx={_sx.numberFieldValue}>{accounting.formatNumber(value ?? 0, 2)}</Typography>
                                </Box>
                                <Box sx={_sx.field}>
                                    <Typography sx={_sx.fieldLabel}>Final Cost:</Typography>
                                    <Typography sx={_sx.numberFieldValue}>{accounting.formatNumber(final_cost ?? 0, 2)}</Typography>
                                </Box>
                                <Box sx={_sx.field}>
                                    <Typography sx={_sx.fieldLabel}>Final Value:</Typography>
                                    <Typography sx={_sx.numberFieldValue}>{accounting.formatNumber(final_value ?? 0, 2)}</Typography>
                                </Box>
                                <Box sx={_sx.field}>
                                    <Typography sx={_sx.fieldLabel}>Remaining Qty:</Typography>
                                    <Typography sx={_sx.numberFieldValue}>{accounting.formatNumber(remaining_qty ?? 0)}</Typography>
                                </Box>
                                <Box sx={_sx.field}>
                                    <Typography sx={_sx.fieldLabel}>Remaining Value:</Typography>
                                    <Typography sx={_sx.numberFieldValue}>{accounting.formatNumber(remaining_value ?? 0, 2)}</Typography>
                                </Box>
                                <Box sx={_sx.field}>
                                    <Typography sx={_sx.fieldLabel}>Valued By:</Typography>
                                    <Typography sx={_sx.fieldValue}>{cost_by_name}</Typography>
                                </Box></Box>;
                        case "breakdown":
                            return <>
                                <Box sx={{ padding: 1 }}>
                                    <Box sx={_sx.field}>
                                        <Typography sx={_sx.fieldLabel}>Quantity:</Typography>
                                        <Typography sx={_sx.numberFieldValue}>{accounting.formatNumber(quantity ?? 0)}</Typography>
                                    </Box>
                                    <Box sx={_sx.field}>
                                        <Typography sx={_sx.fieldLabel}>Final Cost:</Typography>
                                        <Typography sx={_sx.numberFieldValue}>{accounting.formatNumber(final_cost ?? 0, 2)}</Typography>
                                    </Box>
                                    <Box sx={_sx.field}>
                                        <Typography sx={_sx.fieldLabel}>Final Value:</Typography>
                                        <Typography sx={_sx.numberFieldValue}>{accounting.formatNumber(final_value ?? 0, 2)}</Typography>
                                    </Box>
                                </Box>
                                <List>
                                    {
                                        breakdowns.map(b => (<ListItemButton onClick={() => {
                                            switch(b.valuation_type){
                                                case ProductValuationBreakdownType.billing:
                                                    window.open(`${odooUrl}/web#id=${b.id}&model=account.move&view_type=form`, "_blank");
                                                    break;
                                                case ProductValuationBreakdownType.entry:
                                                    window.open(`${odooUrl}/web#id=${b.layer_id}&model=stock.valuation.layer&view_type=form`, "_blank");
                                                    break;
                                                case ProductValuationBreakdownType.landed_cost:
                                                    window.open(`${odooUrl}/web#id=${b.id}&model=stock.landed.cost&view_type=form`, "_blank");
                                                    break;
                                            }
                                        }} key={b.id.toString()}>
                                            <ListItemText secondary={b.name} primary={format("dd/MM/yyyy hh:mm a", new Date(b.create_date))} />
                                            <ListItemSecondaryAction>
                                                <ListItemText sx={{ textAlign: "right" }} primary={`${accounting.formatNumber(b.quantity ?? 0, 0)} x ${accounting.formatNumber(b.unit_cost ?? 0, 2)}`} secondary={`= ${accounting.formatNumber(b.value ?? 0, 2)}`} />
                                            </ListItemSecondaryAction>
                                        </ListItemButton>))
                                    }
                                </List>
                            </>;
                        case "outgoing":
                            return <>
                                <Box sx={{ padding: 1 }}>
                                    <Box sx={_sx.field}>
                                        <Typography sx={_sx.fieldLabel}>Remaining Quantity:</Typography>
                                        <Typography sx={_sx.numberFieldValue}>{accounting.formatNumber(remaining_qty ?? 0)}</Typography>
                                    </Box>
                                    <Box sx={_sx.field}>
                                        <Typography sx={_sx.fieldLabel}>Final Cost:</Typography>
                                        <Typography sx={_sx.numberFieldValue}>{accounting.formatNumber(final_cost ?? 0, 2)}</Typography>
                                    </Box>
                                    <Box sx={_sx.field}>
                                        <Typography sx={_sx.fieldLabel}>Remaining Value:</Typography>
                                        <Typography sx={_sx.numberFieldValue}>{accounting.formatNumber(remaining_value ?? 0, 2)}</Typography>
                                    </Box>
                                </Box>
                                <List>
                                    {
                                        valuation_out.map(v => (<ListItemButton onClick={() => {
                                            window.open(`${odooUrl}/web#id=${v.outgoing.id}&model=stock.valuation.layer&view_type=form`, "_blank");

                                        }} key={v.id.toString()}>
                                            <ListItemText secondary={v.outgoing.stock_move_id?.display_name} primary={format("dd/MM/yyyy hh:mm a", new Date(v.outgoing.create_date))} />
                                            <ListItemSecondaryAction>
                                                <ListItemText sx={{ textAlign: "right" }} primary={`${accounting.formatNumber((v.quantity ?? 0) * -1, 0)} x ${accounting.formatNumber((valuation.final_cost ?? 0) * -1, 2)}`} secondary={`= ${accounting.formatNumber((v.quantity ?? 0) * (valuation.final_cost ?? 0), 2)}`} />
                                            </ListItemSecondaryAction>
                                        </ListItemButton>))
                                    }

                                </List>
                            </>
                    }
                })()

            }
        </DialogContent>
        <DialogActions>
            {valuation && (valuation?.remaining_qty ?? 0) > 0 && valuation.remaining_value === 0 && hasAllowNoCostPermission ? <ConfirmButton onClick={handleSetNoCost} disabled={allowingNoCost || settingCost}>{valuation?.no_cost ? "Unmark No Cost" : "Mark No Cost"}</ConfirmButton> : null}
            <Button onClick={handleSetValuationCost} disabled={draft === (valuation?.unit_cost ?? 0) || !hasSetValuationCostPermission}>OK</Button>
            <Button onClick={onClose}>Cancel</Button>
        </DialogActions>
    </Dialog>)
}

function Valuation({ valuation }: { valuation: Product_productQuery_product_valuation_in[] }) {
    const [selectedIndex, setSelectedIndex] = React.useState(-1);
    const closeDialog = React.useCallback(() => {
        setSelectedIndex(-1);
    }, [setSelectedIndex]);
    if (!valuation)
        return null;
    return (
        <Box sx={{ ..._sx.fill, ..._sx.position_relative, ..._sx.scroller }}><List sx={_sx.historyList} >
            {valuation?.map((v, i) => {
                return (
                    <>
                    <ListItemButton   key={v.id} onClick={() => { setSelectedIndex(i) }}>
                        <ListItemText  secondary={v.stock_move_name} primary={format("dd/MM/yyyy hh:mm a", new Date(v.create_date))} />
                        <ListItemSecondaryAction>
                            <ListItemText color="secondary" sx={{ textAlign: "right", backgroundColor:"#999", paddingLeft:.5,paddingRight:.5 }} primary={`${accounting.formatNumber(v.remaining_qty ?? 0, 0)} x ${accounting.formatNumber(v.final_cost ?? 0, 2)}`} secondary={`${accounting.formatNumber(v.quantity ?? 0, 0)} = ${accounting.formatNumber(v.final_value ?? 0,2)}`} />
                            {v.no_cost ? <ListItemIcon>
                                <WarningIcon />
                            </ListItemIcon> : null}
                        </ListItemSecondaryAction>
                    </ListItemButton>
                    {!v?.remaining_qty? null: <Divider/>}
                    </>
                );
            })}
        </List><ValuationEditDialog valuation={valuation[selectedIndex]} open={selectedIndex > -1} onClose={closeDialog} /></Box>);
}

function Incoming({ incoming }: { incoming: Product_productQuery_product_incoming[] }) {
    const [selectedIndex, setSelectedIndex] = React.useState(-1);
    const closeDialog = React.useCallback(() => {
        setSelectedIndex(-1);
    }, [setSelectedIndex]);
    if (!incoming)
        return null;
    return (
        <Box sx={{ ..._sx.fill, ..._sx.position_relative, ..._sx.scroller }}><List sx={_sx.historyList} >
            {incoming?.map((v, i) => {
                return (
                    <>
                    <ListItemButton   key={v.id} onClick={() => { setSelectedIndex(i) }}>
                        <ListItemText  secondary={v.picking_id?.display_name} primary={format("dd/MM/yyyy hh:mm a", new Date(v.date))} />                        
                        <ListItemSecondaryAction>
                            <ListItemText primary={accounting.formatNumber(v.quantity_done, 0)} />
                        </ListItemSecondaryAction>
                    </ListItemButton>
                    </>
                );
            })}
        </List></Box>);
}

function Loading() {
    return <Box sx={{ ..._sx.fill, ..._sx.scroller, paddingLeft: 2, paddingRight: 2 }}>
        <Grid container item direction="column" >
            <Skeleton sx={{ marginTop: 1, width: 150, height: 28 }} />
            <Skeleton sx={{ marginTop: 1, marginBottom: 1, height: 56 }} />
            <Skeleton sx={{ marginTop: 1, marginBottom: 1, height: 56 }} />
            <Skeleton sx={{ marginTop: 1, marginBottom: 1, height: 56 }} />
            <Skeleton sx={{ marginTop: 1, marginBottom: 1, height: 56 }} />
        </Grid>
    </Box>;
}

function ProductDetailSideBar() {
    const { id: productId } = useParams<{ id: string }>();
    const allowedValuation = useHasPermission("Product_Valuation");
    const allowedPriceHistory = useHasPermission("Product_PriceHistory");
    const allowedPurchaseHistory = useHasPermission("Product_PurchasePriceHistory");
    const allowedQuants = useHasPermission("Product_ViewOnHand");
    const { company_ids, onError, onPriceChanged } = useProductOutletContext();
    const { data } = useQuery<Product, ProductVariables>(productQuery, {
        variables: {
            id: productId!,
            valuation: allowedValuation,
            price_history: allowedPriceHistory,
            purchase_history: allowedPurchaseHistory,
            quants:allowedQuants
        },
        skip: !productId,
        fetchPolicy: "cache-and-network"
    });
    const product = data?.productQuery?.product;
    const [selectedTab, setSelectedTab] = React.useState("general");
    const handleSelectedTabChanged = React.useCallback((_: any, value: string) => {
        setSelectedTab(value);
    }, [setSelectedTab]);
    const [priceList, setPriceList] = React.useState("0");

    const handlePriceListChange = React.useCallback((e: SelectChangeEvent<string>) => { setPriceList(e.target.value) }, [setPriceList]);
    return (<Grid item container direction="column" sx={{ padding: 0, width: { md: 300 } }}><AppBar position="static" color="default"><Tabs value={selectedTab} onChange={handleSelectedTabChanged} aria-label="Sale Invoices">
        <Tab label="General" value="general" />
        <Tab label="Price History" value="price_history" />
    </Tabs>
    </AppBar>

        {
            product ? (selectedTab === "general" ? <General product={product} onError={onError} onPriceChanged={onPriceChanged} company_ids={company_ids} /> : <Box sx={_sx.fill}>
                <Select
                    size="small"
                    sx={_sx.price_list_select}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={priceList}
                    onChange={handlePriceListChange}
                >
                    {
                        product?.prices?.map((price, index) => (<MenuItem value={index.toString()} key={price?.price_list?.id}>{price.price_list?.name}</MenuItem>))
                    }
                    <MenuItem value="purchase">Purchase Price</MenuItem>
                    <MenuItem value="valuation">Valuation</MenuItem>
                    <MenuItem value="incoming">Incoming</MenuItem>
                </Select>
                {
                    (() => {
                        if (priceList === "purchase")
                            return <PurchasePriceHistory history={product.purchase_history} />;
                        if (priceList === "valuation")
                            return <Valuation valuation={product.valuation_in} />;
                        if (priceList === "incoming")
                            return <Incoming incoming={product.incoming}/>;
                        const index = Number.parseInt(priceList);
                        if (!isNaN(index) && product.prices[index]) {
                            return <PriceHistory history={product.prices[index].price_history} />
                        } else
                            return null;
                    })()
                }
            </Box>) : <Loading />
        }
    </Grid>);
}

export default ProductDetailSideBar;