import { ApolloConsumer, useQuery } from "@apollo/client";
import Box from "@mui/material/Box/Box";
import Collapse from "@mui/material/Collapse/Collapse";
import List from "@mui/material/List/List";
import ListItem from "@mui/material/ListItem/ListItem";
import ListItemText from "@mui/material/ListItemText/ListItemText";
import ApolloVirtualizedGrid, { ApolloListResult, DefaultPageInfoValue, GridColumn, ListItemRenderProps } from "mui-apollo-virtualized-table";
import * as React from "react";
import { accountBalanceQuery, cashAndBankAccountsQuery, cashTransactionsQuery } from "../graphql";
import { CashAndBankAccounts, CashAndBankAccountsVariables } from "../__generated__/CashAndBankAccounts";
import { CashTransaction, CashTransactionVariables, CashTransaction_accountingQuery_cashTransactions_edges } from "../__generated__/CashTransaction";
import update from "immutability-helper";
import format from "date-fns/format";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction/ListItemSecondaryAction";
import accounting from "accounting";
import Divider from "@mui/material/Divider/Divider";
import Typography from "@mui/material/Typography/Typography";
import { AccountBalance, AccountBalanceVariables, AccountBalance_accountingQuery_accountBalance_edges } from "../__generated__/AccountBalance";
import AppBar from "@mui/material/AppBar/AppBar";
import Toolbar from "@mui/material/Toolbar/Toolbar";
import Grid from "@mui/material/Grid/Grid";
import Button from "@mui/material/Button/Button";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Menu from "@mui/material/Menu/Menu";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import Badge from "@mui/material/Badge/Badge";
import { Journals } from "../../common/__generated__/Journals";
import { JournalsVariables } from "../../common/__generated__/Journals";
import { journalsQuery } from "../../common/graphql";
import { useSelector } from "react-redux";
import { MakePaymentVariables } from "./__generated__/MakePayment";
import { RootState } from "../../../app/store";
import useHasPermission from "../../../app/useHasPermission";
import { Outlet, useLocation, useMatch, useNavigate, useParams } from "react-router-dom";
import { AccountBalanceOrderByInput, CashTransactionType, CashTransactionWhereInput, JournalType, PaymentType } from "../../../types/global-types";
import DrawerToggleButton from "../../../app/DrawerToggleButton";
import PaymentDialog from "./PaymentDialog";

const _sx = {
    nested: {
        paddingLeft: 4,
    },
    box: {
        backgroundColor: "rgba(255,255,255,.1)",
        padding: 1
    },
    loadingIndicator: {
        backgroundColor: "#DDDDDD",
        color: "#DDDDDD",
        width: 150,
        display: "inline",
    },
    navList: {
        width: 300,
        height: "100%",
        overflow: "scroll"
    },
    fullWidth: {
        width: "100%",
        maxWidth: "auto"
    },
    balanceList: {
        maxWidth: 250
    },
    rightListText: {
        top: 38
    },
    listItemParagraph: { height: 42, overflow: "hidden", paddingLeft: 1, paddingRight: 1 },
    selected: {
        backgroundColor: "rgba(255, 255, 255, 0.14)"
    },
    title: {
        marginRight: 1,
    },
    grow: {
        flex: 1,
        textAlign: "center",
        fontSize: "1.5em"
    },
    transactionsToolbar: {
        borderWidth: 0,
        borderBottomWidth: 1,
        borderStyle: "solid",
        borderColor: "rgba(255,255,255,.8)"
    }
};
export function AccountList() {
    const { id: account_id } = useParams<{ id: string }>();
    const savedAccountId = useSelector((state: RootState) => (state.saleSetting.cashAccount ? Number.parseInt(state.saleSetting.cashAccount.id) : undefined));
    const accessOtherCashAccount = useHasPermission("CashBook_AccessOtherCashAccount");
    const { data: cashAccountsResult } = useQuery<CashAndBankAccounts, CashAndBankAccountsVariables>(cashAndBankAccountsQuery, { variables: { tags: [4], id: !accessOtherCashAccount ? savedAccountId : undefined } });
    const { data: bankAccountResult } = useQuery<CashAndBankAccounts, CashAndBankAccountsVariables>(cashAndBankAccountsQuery, { variables: { tags: [5], id: !accessOtherCashAccount ? savedAccountId : undefined } });
    const { search } = useLocation();
    const query = React.useMemo(() => {
        return new URLSearchParams(search)
    }, [search]);
    const duration = React.useMemo(() => {
        if (query.get("duration")) {
            return Number.parseInt(query.get("duration") || '{}');
        }
        return null;
    }, [query]);
    const history = useNavigate();
    if (!savedAccountId && !accessOtherCashAccount) {
        return <h3>You have to select cash account in settings. Please call 09972966450.</h3>
    }
    return (
        <>
            <Box sx={{ ..._sx.navList, ...(!account_id ? _sx.fullWidth : {display:{sm:"none", xs:"none",md:"block"}}) }}>
                <List component="nav" >
                    <ListItem>
                        <ListItemText primary="Cash" />
                    </ListItem>
                    <Collapse in={true} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {
                                cashAccountsResult?.accountingQuery?.accountingAccounts?.edges?.map((account) => (<ListItem key={account.id} button onClick={() => { history(`/reports/cash_book/${account.id}?duration=${duration ?? ""}`); }} sx={_sx.nested} selected={account_id === account.id}>
                                    <ListItemText primary={account.code} secondary={account.name} />
                                </ListItem>))
                            }
                        </List>
                    </Collapse>
                    <ListItem >
                        <ListItemText primary="Bank" />
                    </ListItem>
                    <Collapse in={true} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {
                                bankAccountResult?.accountingQuery?.accountingAccounts?.edges?.map((account) => (<ListItem key={account.id} button onClick={() => { history(`/reports/cash_book/${account.id}?duration=${duration ?? ""}`); }} sx={_sx.nested} selected={account_id === account.id}>
                                    <ListItemText primary={account.code} secondary={account.name} />
                                </ListItem>))
                            }
                        </List>
                    </Collapse>
                </List></Box>
            <Outlet />
        </>)
}

type TransactionTypeSelectorProps = {
    value: CashTransactionType[],
    onSelect: (value: CashTransactionType[]) => void
};

function TransactionTypeSelector({ value, onSelect }: TransactionTypeSelectorProps) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const handleSelectorClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = React.useCallback(() => {
        setAnchorEl(null);
    }, [setAnchorEl]);
    const types = React.useMemo(() => ([{ type: CashTransactionType.inpayment, text: "Customer Payment" }, { type: CashTransactionType.inrefund, text: "Customer Refund" }, { type: CashTransactionType.outpayment, text: "Supplier Payment" }, { type: CashTransactionType.outrefund, text: "Supplier Refund" }, { type: CashTransactionType.internal, text: "Internal Transfer" }, { type: CashTransactionType.other, text: "Other" }]), []);
    const menus = React.useMemo(() => {
        return types.map(({ type, text }) => (<MenuItem
            key={type}
            selected={value.includes(type)}
            onClick={() => {
                if (value.includes(type))
                    onSelect(update(value, { $splice: [[value.indexOf(type), 1]] }));
                else
                    onSelect([...value, type]);
            }}
        >{text}</MenuItem>));
    }, [types, value, onSelect]);
    return (<><Button
        aria-controls="type-menu"
        aria-haspopup="true"
        onClick={handleSelectorClick}
        endIcon={<ExpandMore />}
    >
        <Badge
            color="secondary"
            badgeContent={value.length === 0 ? null : value.length}
        >
            Transaction Type
        </Badge>
    </Button>
        <Menu
            id="type-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorReference="anchorEl"
        >
            {menus}
        </Menu></>);
}

type TransactionDirectionSelectorProps = {
    value: "in" | "out" | "all",
    onSelect: (value: "in" | "out" | "all") => void
};

function TransactionDirectionSelector({ value, onSelect }: TransactionDirectionSelectorProps) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const handleSelectorClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = React.useCallback(() => {
        setAnchorEl(null);
    }, [setAnchorEl]);
    return (<><Button
        aria-controls="duration-menu"
        aria-haspopup="true"
        onClick={handleSelectorClick}
        endIcon={<ExpandMore />}
    >
        {value?.toUpperCase()}
    </Button>
        <Menu
            id="direction-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorReference="anchorEl"
        >
            <MenuItem
                selected={value === "all"}
                onClick={() => {
                    onSelect("all");
                }}
            >ALL</MenuItem>
            <MenuItem
                selected={value === "in"}
                onClick={() => {
                    onSelect("in");
                }}
            >IN</MenuItem>
            <MenuItem
                selected={value === "out"}
                onClick={() => {
                    onSelect("out");
                }}
            >OUT</MenuItem>
        </Menu></>);
}

export function Transactions() {
    const { id: account_id } = useParams<{ id: string }>();
    const [columns, setColumns] = React.useState<
        ReadonlyArray<GridColumn<CashTransaction_accountingQuery_cashTransactions_edges>>
    >([
        {
            label: "id",
            key: "id",
            width: 150,
            sortable: true
        }
    ]);
    const { search } = useLocation();
    const query = React.useMemo(() => {
        return new URLSearchParams(search)
    }, [search]);
    const [direction, setDirection] = React.useState<"in" | "out" | "all">("all");
    const [transactionTypes, setTransactionTypes] = React.useState<CashTransactionType[]>([]);
    const directionWhere: CashTransactionWhereInput = {};
    switch (direction) {
        case "in":
            directionWhere.balance_Gt = 0;
            break;
        case "out":
            directionWhere.balance_Lt = 0;
            break;
    }
    const transactionTypeWhere: CashTransactionWhereInput = {};
    if (transactionTypes?.length) {
        transactionTypeWhere.type_In = transactionTypes
    }
    const variables: CashTransactionVariables = {
        account_id: Number.parseInt(account_id ?? "0"),
        where: {
            date_Gte: query.get("date"),
            date_Lte: query.get("end_date"),
            ...directionWhere,
            ...transactionTypeWhere
        },
        pagination: {
            pageSize: 40
        }
    };

    const { data: cashTransactionQueryResult } = useQuery<CashTransaction, CashTransactionVariables>(cashTransactionsQuery, {
        variables,
        skip: !account_id,
        fetchPolicy: "cache-only"
    });

    const cashTransactionTotal = React.useMemo(() => {
        const [edge] = cashTransactionQueryResult?.accountingQuery?.cashTransactionTotal?.edges ?? [];
        return edge?.balance_sum ?? 0
    }, [cashTransactionQueryResult]);

    const listItemRenderer = React.useCallback((renderProps: ListItemRenderProps<CashTransaction_accountingQuery_cashTransactions_edges>) => {
        const { rowData, key, style, onClick } = renderProps;
        if (rowData) {
            const { name, move_name, ref, balance, date, type, partner_name } = rowData;
            return (
                <Box onClick={onClick} style={style} key={key}>
                    <ListItem >
                        <ListItemText
                            primary={
                                move_name
                            }
                            secondary={format(new Date(date), "dd/MM/yyyy")}
                        />
                    </ListItem>
                    <ListItemSecondaryAction sx={_sx.rightListText}>
                        <ListItemText
                            primary={<Typography style={{ textAlign: "right", display: "block" }}>{accounting.formatNumber(balance ?? 0)}</Typography>}
                            secondary={partner_name}
                        />
                    </ListItemSecondaryAction>
                    <Typography variant="body2" sx={_sx.listItemParagraph}>{(() => {
                        switch (type) {
                            case CashTransactionType.internal:
                                return ref;
                            default:
                                return name;
                        }
                    })()}</Typography>
                    <Divider />
                </Box>
            );
        } else {
            return (
                <Box style={style} key={key}>
                    <ListItem>
                        <ListItemText
                            primary={
                                <Typography sx={_sx.loadingIndicator}>
                                    A...........................
                                </Typography>
                            }
                            secondary={
                                <Typography sx={_sx.loadingIndicator}>A..........</Typography>
                            }
                        />
                    </ListItem>
                    <Typography sx={_sx.loadingIndicator}>A.....................</Typography>
                    <Divider />
                </Box>
            );
        }
    }, []);
    return (<Box
        sx={_sx.box}
        flex={1}
        display="flex"
        flexDirection="row"
    >
        <ApolloConsumer>
            {(client) => (
                <ApolloVirtualizedGrid
                    headerComponent={<Toolbar sx={_sx.transactionsToolbar}><TransactionDirectionSelector value={direction} onSelect={setDirection} /><Box sx={_sx.grow}>{accounting.formatNumber(cashTransactionTotal, 0)}</Box><TransactionTypeSelector value={transactionTypes} onSelect={setTransactionTypes} /></Toolbar>}
                    apolloClient={client as any}
                    listItemHeight={120}
                    listModeBreakPoint={2000}
                    listItemRenderer={listItemRenderer}
                    columns={columns}
                    graphqlQuery={cashTransactionsQuery}
                    variables={variables}
                    pageSize={variables?.pagination?.pageSize!}
                    parseListFromQueryResult={(queryResult: CashTransaction) => {
                        const list: ApolloListResult<CashTransaction_accountingQuery_cashTransactions_edges> =
                            queryResult?.accountingQuery
                                ? queryResult.accountingQuery.cashTransactions
                                : {
                                    edges: [],
                                    pageInfo: DefaultPageInfoValue,
                                };
                        return list;
                    }}
                    onLoadMore={(pageInfo) => {
                        return {
                            ...variables,
                            pagination: {
                                page: pageInfo.page,
                                pageSize: pageInfo.pageSize,
                            },
                        };
                    }}
                />
            )}
        </ApolloConsumer>
    </Box>);
}

export function CashBalanceHistory() {
    const { search } = useLocation();
    const query = React.useMemo(() => {
        return new URLSearchParams(search)
    }, [search]);
    const { id: account_id } = useParams<{ id: string }>();
    const duration = React.useMemo(() => {
        if (query.get("duration")) {
            return Number.parseInt(query.get("duration") || '{}');
        }
        return null;
    }, [query]);
    const [columns, setColumns] = React.useState<
        ReadonlyArray<GridColumn<AccountBalance_accountingQuery_accountBalance_edges>>
    >([
        {
            label: "id",
            key: "id",
            width: 150,
            sortable: true
        }
    ]);
    const history = useNavigate();
    const handleRowClick = React.useCallback((rowData: AccountBalance_accountingQuery_accountBalance_edges) => {
        const { date, end_date } = rowData;
        history(`/reports/cash_book/${account_id}/transactions?date=${date}&end_date=${end_date}&duration=${duration ?? ""}`)
    }, [history, duration, account_id]);
    const variables: AccountBalanceVariables = {
        account_id: Number.parseInt(account_id ?? "0"),
        duration: duration ?? 1,
        pagination: {
            pageSize: 40
        },
        orderBy: [AccountBalanceOrderByInput.date_Desc]
    };
    const listItemRenderer = React.useCallback((renderProps: ListItemRenderProps<AccountBalance_accountingQuery_accountBalance_edges>) => {
        const { rowData, key, style, className, onClick } = renderProps;
        const isSelected = rowData && rowData.end_date === query.get("end_date")
        if (rowData) {
            const { end_date, total, balance } = rowData;
            return (
                <Box onClick={onClick} style={style} sx={{ ..._sx.selected }} key={key}>
                    <ListItem>
                        <ListItemText
                            primary={format(new Date(end_date), "dd/MM/yyyy")}
                            secondary="."
                        />
                    </ListItem>
                    <ListItemSecondaryAction>
                        <ListItemText
                            primary={accounting.formatNumber(total ?? 0)}
                            secondary={<Typography style={{ textAlign: "right", display: "block", fontSize: "1em" }}>{accounting.formatNumber(balance ?? 0)}</Typography>}
                        />
                    </ListItemSecondaryAction>
                    <Divider />
                </Box>
            );
        } else {
            return (
                <Box style={style} key={key}>
                    <ListItem>
                        <ListItemText
                            primary={
                                <Typography sx={_sx.loadingIndicator}>
                                    A...........................
                                </Typography>
                            }
                            secondary={
                                <Typography sx={_sx.loadingIndicator}>A..........</Typography>
                            }
                        />
                    </ListItem>
                    <Divider />
                </Box>
            );
        }
    }, [query]);

    return (
        <>
            <Box
                sx={{ ..._sx.box, ..._sx.balanceList, ...(!query.get("end_date") ? _sx.fullWidth : {display:{xs:"none", sm:"flex"}}) }}
                flex={1}
                display="flex"
                flexDirection="row"
            >
                <ApolloConsumer>
                    {(client) => (
                        <ApolloVirtualizedGrid
                            extraData={{ search }}
                            apolloClient={client as any}
                            listItemHeight={72}
                            listModeBreakPoint={2000}
                            listItemRenderer={listItemRenderer}
                            columns={columns}
                            graphqlQuery={accountBalanceQuery}
                            variables={variables}
                            pageSize={variables?.pagination?.pageSize!}
                            onRowClick={handleRowClick}
                            parseListFromQueryResult={(queryResult: AccountBalance) => {
                                const list: ApolloListResult<AccountBalance_accountingQuery_accountBalance_edges> =
                                    queryResult?.accountingQuery
                                        ? queryResult.accountingQuery.accountBalance
                                        : {
                                            edges: [],
                                            pageInfo: DefaultPageInfoValue,
                                        };
                                return list;
                            }}
                            onLoadMore={(pageInfo) => {
                                return {
                                    ...variables,
                                    pagination: {
                                        page: pageInfo.page,
                                        pageSize: pageInfo.pageSize,
                                    },
                                };
                            }}
                        />
                    )}
                </ApolloConsumer>
            </Box><Outlet /></>);
}

function PaymentMenu() {
    const { id: account_id } = useParams<{ id: string }>();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const handlePaymentSelectorClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = React.useCallback(() => {
        setAnchorEl(null);
    }, [setAnchorEl]);

    type DialogState = { open: boolean, initialValue: Partial<MakePaymentVariables["payment"]> };
    const [dialogState, setDialogState] = React.useState<DialogState>({
        open: false,
        initialValue: {}
    });
    const handleMenuClick = React.useCallback((state: DialogState) => {
        state.initialValue.partner_id = 1;
        setDialogState({ ...dialogState,...state, open: true });
        handleClose();
    }, [dialogState, setDialogState, handleClose]);
    const handleDialogClose = React.useCallback(() => {
        setDialogState({ ...dialogState, open: false });
    }, [dialogState, setDialogState]);
    const allowedOtherCashAccount = useHasPermission("CashBook_AccessOtherCashAccount");
    const allowedTransaction = useHasPermission("CashBook_AllowTransaction");
    const { data: journalQueryResult } = useQuery<Journals, JournalsVariables>(journalsQuery, {
        variables: {
            journalType: [JournalType.cash, JournalType.bank],
            defaultAccountId: account_id ? Number.parseInt(account_id) : null
        }
    });
    const journal = React.useMemo(() => {
        const [journal] = journalQueryResult?.commonQuery?.journals ?? [];
        return journal;
    }, [journalQueryResult]);

    const savedCashAccount = useSelector(
        (root: RootState) => root.saleSetting.cashAccount
    );

    if (!allowedTransaction)
        return null;
    if (!allowedOtherCashAccount && savedCashAccount?.id !== account_id)
        return null;

    return (<><Button
        aria-controls="payment-menu"
        aria-haspopup="true"
        color="inherit"
        onClick={handlePaymentSelectorClick}
        endIcon={<ExpandMore />}
    >
        Make Payment
    </Button>
        <Menu
            id="language-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorReference="anchorEl"
        >
            <MenuItem
                disabled={!allowedOtherCashAccount}
                onClick={() => {
                    handleMenuClick({ initialValue: { is_internal_transfer: false, payment_type: PaymentType.outbound }, open: true });
                }}
            >Pay Money</MenuItem>
            <MenuItem
                disabled={!allowedOtherCashAccount}
                onClick={() => {
                    handleMenuClick({ initialValue: { is_internal_transfer: false, payment_type: PaymentType.inbound }, open: true });
                }}
            >Receive Money</MenuItem>
            <MenuItem
                onClick={() => {
                    handleMenuClick({ initialValue: { is_internal_transfer: true, payment_type: PaymentType.outbound }, open: true });
                }}
            >Transfer Out</MenuItem>
            <MenuItem
                onClick={() => {
                    handleMenuClick({ initialValue: { is_internal_transfer: true, payment_type: PaymentType.inbound }, open: true });
                }}
            >Transfer In</MenuItem>
        </Menu>
        <PaymentDialog initialValue={dialogState.initialValue} open={dialogState.open} journal={journal} onClose={handleDialogClose} allowedPartnerChange={allowedOtherCashAccount} allowDateChange={allowedOtherCashAccount} allowedPaymentTypeChange={false} allowedJournalChange={allowedOtherCashAccount} />
    </>)
}



function DurationSelector() {
    const { id: account_id } = useParams<{ id: string }>();
    const { search } = useLocation();
    const params = React.useMemo(() => {
        return new URLSearchParams(search);
    }, [search]);
    const duration = React.useMemo(() => {
        if (params.get("duration")) {
            return Number.parseInt(params.get("duration") || '{}');
        }
        return null;
    }, [params]);
    const durationText = React.useMemo(() => {
        switch (duration) {
            case 3:
                return "Month";
            case 2:
                return "Week";
            default:
                return "Day";
        }
    }, [duration]);
    const history = useNavigate();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const handleDurationSelectorClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = React.useCallback(() => {
        setAnchorEl(null);
    }, [setAnchorEl]);
    const handleDurationSelect = React.useCallback((duration: Number) => {
        history(`/reports/cash_book/${account_id}?duration=${duration}`);
    }, [account_id, history]);
    return (<><Button
        color="inherit"
        aria-controls="duration-menu"
        aria-haspopup="true"
        onClick={handleDurationSelectorClick}
        endIcon={<ExpandMore />}
    >
        {durationText}
    </Button>
        <Menu
            id="language-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorReference="anchorEl"
        >
            <MenuItem
                selected={duration === 1 || !duration}
                onClick={() => {
                    handleDurationSelect(1);
                }}
            >Day</MenuItem>
            <MenuItem
                selected={duration === 2}
                onClick={() => {
                    handleDurationSelect(2);
                }}
            >Week</MenuItem>
            <MenuItem
                selected={duration === 3}
                onClick={() => {
                    handleDurationSelect(3);
                }}
            >Month</MenuItem>
        </Menu></>)
}

function CashBook() {
    const isCashBookDetailRoute = useMatch("reports/cash_book/:id/*");
    return (<Grid container direction="column" >
        <AppBar position="static">
            <Toolbar sx={(theme) => ({
                [theme.breakpoints.up("md")]: {
                    minHeight: theme.spacing(6),
                },
                paddingLeft: 1.5,
            })}>
                <DrawerToggleButton />
                <Typography
                    variant="h6"
                    sx={_sx.title}
                    color="inherit"
                    noWrap
                >
                    Cash Book
                </Typography>
                <Box sx={_sx.grow} />
                {
                    isCashBookDetailRoute ? <><DurationSelector /><PaymentMenu/></> : null
                }
            </Toolbar>
        </AppBar>
        <Box style={{ flex: 1, overflow: "hidden", display: "flex", flexDirection: "row", flexWrap: "nowrap" }}>
            <Outlet />
        </Box>
    </Grid>);
}

export default CashBook;


