import { ApolloConsumer } from "@apollo/client/react/context/ApolloConsumer";
import accounting from "accounting";
import { format } from "date-fns";
import * as React from "react";
import { saleOrdersQuery } from "./graphql";
import SaleOrderStateIndicator from "./SaleOrderStateIndicator";
import {
  SaleOrders,
  SaleOrdersVariables,
  SaleOrders_saleOrderQuery_saleOrders_edges,
} from "./__generated__/SaleOrders";
import ListItem from "@mui/material/ListItem/ListItem";
import ListItemText from "@mui/material/ListItemText/ListItemText";
import Divider from "@mui/material/Divider/Divider";
import Typography from "@mui/material/Typography";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import { useQuery } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
import ApolloVirtualizedTable, { ApolloListResult, ApolloVirtualizedTableProps, DefaultPageInfoValue, GridColumn, ListItemRenderProps } from "mui-apollo-virtualized-table";

const SaleOrderVirtualizedGrid = ApolloVirtualizedTable as React.ElementType<ApolloVirtualizedTableProps<SaleOrders_saleOrderQuery_saleOrders_edges>>;
export type SaleOrderGridProps = {
  variables: SaleOrdersVariables;
  onVariablesChanged: (variables: SaleOrdersVariables) => void;
};

const _sx={
  loadingIndicator: {
    backgroundColor: "#DDDDDD",
    color: "#DDDDDD",
    width: 150,
    display: "inline",
  },
};

function SaleOrderGrid({ variables={pagination:{pageSize:40}}, onVariablesChanged }: SaleOrderGridProps) {
  const [columns, setColumns] = React.useState<
    ReadonlyArray<GridColumn<SaleOrders_saleOrderQuery_saleOrders_edges>>
  >([
    {
      label: "Order #",
      key: "name",
      width: 100,
      sortable: true,
    },
    {
      label: "Customer",
      key: "partner_id",
      width: 150,
      flexGrow: 1,
      sortable: true,
      format: ({ rowData }) => rowData.partner_id.display_name,
    },
    {
      label: "Date",
      key: "date_order",
      width: 100,
      sortable: true,
      format: ({ rowData }) =>
        format(new Date(rowData.date_order), "dd/MM/yyyy"),
    },
    {
      label: "Status",
      key: "state",
      width: 150,
      sortable: true,
      format: ({ rowData }) => (
        <SaleOrderStateIndicator state={rowData.state} />
      ),
    },
    {
      label: "Saleperson",
      key: "saleperson_name",
      width: 150,
      sortable: true,
    },
    {
      label: "Cashier",
      key: "cashier_name",
      width: 150,
      sortable: true,
    },
    {
      label: "Subtotal",
      key: "amount_untaxed",
      width: 150,
      sortable: true,
      textAlign: "right",
      format: ({ rowData }) =>
        accounting.formatNumber(rowData.amount_untaxed, 0),
    },
    {
      label: "Total",
      key: "amount_total",
      width: 150,
      sortable: true,
      textAlign: "right",
      format: ({ rowData }) =>
        accounting.formatNumber(rowData.amount_total, 0),
    },
  ]);
  const [selectedIndex, setSelectedIndex] = React.useState(-1);
  const listItemRenderer = React.useCallback(
    (
      renderProps: ListItemRenderProps<SaleOrders_saleOrderQuery_saleOrders_edges>
    ) => {
      const { rowData, key, style, className, onClick } = renderProps;
      if (rowData) {
        const { state, name, partner_id, amount_total,saleperson_name } = rowData;
        return (
          <div onClick={onClick} style={style} className={className} key={key}>
            <ListItem>
              <ListItemText
                primary={
                  <>
                    {name} <SaleOrderStateIndicator state={state} />
                  </>
                }
                secondary={partner_id.display_name}
              />
            </ListItem>
            <ListItemSecondaryAction>
              <ListItemText
                primary={saleperson_name}
                secondary={<Typography style={{ textAlign: "right", display: "block" }}>{accounting.formatNumber(amount_total, 0)}</Typography>}
              />
            </ListItemSecondaryAction>
            <Divider />
          </div>
        );
      } else {
        return (
          <div style={style} key={key}>
            <ListItem>
              <ListItemText
                primary={
                  <Typography sx={_sx.loadingIndicator}>
                    A...........................
                  </Typography>
                }
                secondary={
                  <Typography sx={_sx.loadingIndicator}>A..........</Typography>
                }
              />
            </ListItem>
            <Divider />
          </div>
        );
      }
    },
    []
  );


  const { data: saleOrdersQueryResult } = useQuery<SaleOrders, SaleOrdersVariables>(saleOrdersQuery, { fetchPolicy: "cache-only", variables });
  const { id: saleOrderId } = useParams<{ id: string }>();

  const handleCommandKeyUp = React.useCallback(
    (e: DocumentEventMap["keyup"]) => {
      const products = saleOrdersQueryResult?.saleOrderQuery?.saleOrders?.edges??[];
      switch (e.key) {
        case "ArrowUp":
          if (saleOrderId) {
            setSelectedIndex(Math.max(0, selectedIndex - 1));
            e.stopPropagation();
          }
          break;
        case "ArrowDown":
          if (saleOrderId) {
            setSelectedIndex(Math.min(selectedIndex + 1, products.length));
            e.stopPropagation();
          }
          break;
      }
    },
    [setSelectedIndex, selectedIndex, saleOrdersQueryResult?.saleOrderQuery?.saleOrders?.edges, saleOrderId]
  );
  React.useEffect(() => {
    document.addEventListener("keyup", handleCommandKeyUp);
    return () => {
      document.removeEventListener("keyup", handleCommandKeyUp);
    };
  }, [handleCommandKeyUp]);

    const navigate = useNavigate();

  return (
    <ApolloConsumer>
      {(client) => (
        <SaleOrderVirtualizedGrid
          onRowClick={(item, index) => {
            navigate(`/sale_orders/${item.id}`);
          }}
          apolloClient={client as any}
          listItemHeight={72}
          listItemRenderer={listItemRenderer}
          listModeBreakPoint={320}
          onColumnPropsChanged={(columns, orderBy: any) => {
            const newVariables: SaleOrdersVariables = {
              ...variables,
              orderBy,
            };
            setColumns(columns);
            onVariablesChanged(newVariables);
          }}
          selectedItems={[selectedIndex]}
          scrollToIndex={selectedIndex}
          columns={columns}
          graphqlQuery={saleOrdersQuery}
          variables={variables}
          pageSize={variables.pagination?.pageSize!}
          parseListFromQueryResult={(queryResult: SaleOrders) => {
            const list: ApolloListResult<SaleOrders_saleOrderQuery_saleOrders_edges> =queryResult.saleOrderQuery.saleOrders?? {
                  edges: [],
                  pageInfo: DefaultPageInfoValue,
                };
            return list;
          }}
          onLoadMore={(pageInfo) => {
            return {
              ...variables,
              pagination: {
                page: pageInfo.page,
                pageSize: pageInfo.pageSize,
              },
            };
          }}
        />
      )}
    </ApolloConsumer>
  );
}

export default SaleOrderGrid;
