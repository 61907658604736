import { DeepReadonly } from "utility-types";

export type LabelData = DeepReadonly<{
  StockCode: string;
  PurchaseDate: Date;
  SerialNo: string;
  VoucherNo: string;
  Copy: number;
}>;

class LabelPrinter {
  _labelData: LabelData[]=[];
  _url: string|undefined;
  constructor( printUrl?: string) {
    this._url = printUrl;
  }


  

  print(data:LabelData[],template:string=""):Promise<boolean> {
    if(!this._url)
      throw new Error("Label printer url not set.");
    return fetch(`${this._url}/api/productserialnolabel/printlabels/${template}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      referrerPolicy: "no-referrer-when-downgrade",
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.status === 200) {
            return true;
        }
        else return Promise.reject(response.statusText);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
}

export default LabelPrinter;
