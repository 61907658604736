
import accounting from "accounting";
import { ApolloConsumer } from "@apollo/client/react/context/ApolloConsumer";
import * as React from "react";
import { ProductItems, ProductItemsVariables, ProductItems_productQuery_productItems_edges } from "./__generated__/ProductItems";
import ApolloVirtualizedTable, { ApolloListResult, ApolloVirtualizedTableProps, DefaultPageInfoValue, GridColumn } from "mui-apollo-virtualized-table";
import { productItemsQuery } from "./graphql";
import useHasPermission from "../../app/useHasPermission";
const ProductVirtualizedGrid = ApolloVirtualizedTable as React.ElementType<ApolloVirtualizedTableProps<ProductItems_productQuery_productItems_edges>>;
export type ProductGridProps = {
  variables: ProductItemsVariables;
  onRowClick?: (rowData: ProductItems_productQuery_productItems_edges, index: number) => void;
  selectedIndex?: number;
  setOrderBy?: (orderBy: string[]) => void;
};

function ProductGrid({ variables = { quants: false, pagination: { pageSize: 40 } }, setOrderBy, onRowClick, selectedIndex }: ProductGridProps) {
  const allowedQuants = useHasPermission("Product_ViewOnHand");
  variables.quants = allowedQuants;
  const [columns, setColumns] = React.useState<
    ReadonlyArray<GridColumn<ProductItems_productQuery_productItems_edges>>
  >([
    {
      label: "Model No",
      key: "default_code",
      width: 200,
      sortable: true,
    },
    {
      label: "Name",
      key: "name",
      width: 250,
      flexGrow: 1,
      sortable: true,
    },
    {
      label: "Price",
      key: "list_price",
      width: 100,
      textAlign: "right",
      sortable: true,
      format: ({ rowData }) => accounting.formatNumber(rowData.list_price ?? 0, 0),
    },
    {
      label: "On Hand",
      key: "quantity",
      width: 100,
      textAlign: "right",
      sortable: true,
      format: ({ rowData }) =>
        `${accounting.formatNumber(rowData.quantity ?? 0, 0)} - ${accounting.formatNumber(rowData.reserved_quantity ?? 0, 0)} = ${accounting.formatNumber((rowData.quantity ?? 0) - (rowData.reserved_quantity ?? 0), 0)}`,
    },
    {
      label: "Unit",
      key: "uom",
      width: 75,
      sortable: true,
    },
  ]);



  return (
    <ApolloConsumer>
      {(client) => (
        <ProductVirtualizedGrid
          onRowClick={onRowClick}
          scrollToIndex={selectedIndex}
          apolloClient={client as any}
          listItemHeight={72}
          listModeBreakPoint={0}
          columns={columns}
          graphqlQuery={productItemsQuery}
          onColumnPropsChanged={(columns, orderBy) => {
            setColumns(columns);
            if (setOrderBy)
              setOrderBy(orderBy);
          }}
          selectedItems={selectedIndex === undefined ? [] : [selectedIndex]}
          variables={variables}
          pageSize={variables?.pagination?.pageSize!}
          parseListFromQueryResult={(queryResult: ProductItems) => {
            const list: ApolloListResult<ProductItems_productQuery_productItems_edges> = queryResult?.productQuery?.productItems ?? {
              edges: [],
              pageInfo: DefaultPageInfoValue,
            };
            return list;
          }}
          onLoadMore={(pageInfo) => {
            return {
              ...variables,
              pagination: {
                page: pageInfo.page,
                pageSize: pageInfo.pageSize,
              },
            };
          }}
        />
      )}
    </ApolloConsumer>
  );
}

export default ProductGrid;