import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import * as React from "react";
import { EcommerceCategory_ecommerceQuery_category_products_edges } from "../__generated__/EcommerceCategory";
import settings from "../../../config.json";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import {  defaultColumnWidth } from "./ProductMasonry";
import { useNavigate, useParams } from "react-router-dom";
export type ProductCardProps = {
    data: EcommerceCategory_ecommerceQuery_category_products_edges;
    style?: React.CSSProperties;
    width: number;
};

function ProductCard({ data, style, width }: ProductCardProps) {
    const {image_variant_id,default_code,ecommerce_caption, default_variant_image_ratio} = data;
    const padding = width < defaultColumnWidth ? .5 : 1;
    const navigate = useNavigate();
    const {id} = useParams<{id:string}>();
    return (
        <Box
            sx={{ padding: padding, width }}
            style={style}
        >
            <Card sx={{
                width: "100%",
                "&:hover": {
                    cursor: "pointer",
                    backgroundColor: '#eee'
                },
                "&:hover > div.MuiCardMedia-root": {
                    opacity: '.3'
                }
            }} onClick={() => {navigate(`/ecommerce/${id? `${id}/`:""}product/${data.id}`);  }}>
                <CardMedia
                    image={`${settings.cloudfront_url}/${image_variant_id?.display_name}/x220`}
                    sx={{height:((width - padding* 2 * 8)/(default_variant_image_ratio??1)),backgroundColor:"#fff"}}
                >                </CardMedia>
                <ListItem>
                    <ListItemText
                        primary={default_code}
                        secondary={ecommerce_caption}
                    />
                </ListItem>
            </Card>
        </Box>
    );
}

export default ProductCard;