import { useApolloClient, useMutation, useQuery } from "@apollo/client/react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Stack from "@mui/material/Stack";
import * as React from "react";
import DialogActionLoadingIndicator from "../../../components/DialogActionLoadingIndicator";
import TextEditor from "../../../components/TextEditor";
import { EcommerceCategoryInput } from "../../../types/global-types";
import PartnerAutoComplete from "../../common/PartnerAutoComplete";
import { Partners_partnerQuery_partners_edges } from "../../common/__generated__/Partners";
import EcommerceCategoryAutoComplete, { EcommerceCategoryOptionValue } from "../EcommerceCategoryAutoComplete";
import { ecommerceCategoryFragment, ecommerceCategoryQuery, saveEcommerceCategoryMutation } from "../graphql";
import { EcommerceCategory, EcommerceCategoryVariables, EcommerceCategory_ecommerceQuery_category } from "../__generated__/EcommerceCategory";
import { SaveEcommerceCategory, SaveEcommerceCategoryVariables, SaveEcommerceCategory_ecommerceMutation_saveCategory } from "../__generated__/SaveEcommerceCategory";
import update from "immutability-helper";
import { EcommerceCategoryFragment } from "../__generated__/EcommerceCategoryFragment";
import Box from "@mui/system/Box";
import FilePickerHidden from "../../../components/FilePickerHidden";
import { ErrorInfo } from "../../../components/ErrorMessageDialog";
import settings from "../../../config.json";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import DialogTitle from "@mui/material/DialogTitle";
export type CategoryEditorProps = {
    id?: number | null;
    open: boolean;
    onClose: () => void;
    current_parent?: EcommerceCategoryOptionValue
};
type CategoryInDraft = EcommerceCategoryInput;
function valueToDraft(category?: EcommerceCategory_ecommerceQuery_category | null) {
    const value: CategoryInDraft = {
        id: category?.id,
        name: category?.name ?? "",
    };
    return value;
}
function CategoryEditor({ id, open, onClose, current_parent }: CategoryEditorProps) {
    const { loading, data } = useQuery<EcommerceCategory, EcommerceCategoryVariables>(ecommerceCategoryQuery, { variables: { id: id! }, skip: !id });
    const apolloClient = useApolloClient();
    const addNewCategoryToCache = React.useCallback((savedCategory: SaveEcommerceCategory_ecommerceMutation_saveCategory) => {
        const category = apolloClient.readFragment<EcommerceCategoryFragment>({ id: `EcommerceCategory:${savedCategory?.parent_id?.id}`, fragment: ecommerceCategoryFragment });
        if (category) {
            const updated = update(category, { children: { $push: [savedCategory] } });
            apolloClient.writeFragment({ id: `EcommerceCategory:${savedCategory?.parent_id?.id}`, fragment: ecommerceCategoryFragment, data: updated });
            setDraft(valueToDraft(category as any));
            setPhotoUrl(`${settings.cloudfront_url}/${category?.image}`);
        }
    }, [apolloClient]);
    const updateCategoryToCatch = React.useCallback((savedCategory: SaveEcommerceCategory_ecommerceMutation_saveCategory) => {
        const category = apolloClient.readFragment<EcommerceCategoryFragment>({ id: `EcommerceCategory:${savedCategory?.id}`, fragment: ecommerceCategoryFragment });
        if (category) {
            const updated = { ...category, ...savedCategory };
            apolloClient.writeFragment({ id: `EcommerceCategory:${savedCategory?.id}`, fragment: ecommerceCategoryFragment, data: updated });
            setDraft(valueToDraft(category as any));
            setPhotoUrl(`${settings.cloudfront_url}/${category?.image}`);
        }
    }, [apolloClient]);
    const [saveCategory, { loading: saving }] = useMutation<SaveEcommerceCategory, SaveEcommerceCategoryVariables>(saveEcommerceCategoryMutation, {
        update: (cache, result) => {
            const savedCategory = result?.data?.ecommerceMutation?.saveCategory;
            if (!savedCategory)
                return;
            if (!id) {
                addNewCategoryToCache(savedCategory);
            }
        }
    });
    const [errorInfo, setErrorInfo] = React.useState<ErrorInfo | null>(null);
    const category = data?.ecommerceQuery?.category;
    const [draft, setDraft] = React.useState<CategoryInDraft>(valueToDraft(category));
    const [parent, setParent] = React.useState<EcommerceCategoryOptionValue | null | undefined>(current_parent);
    const [partner, setPartner] = React.useState<Partners_partnerQuery_partners_edges | null>();
    const [photoUrl, setPhotoUrl] = React.useState(`${settings.cloudfront_url}/${category?.image}`);

    const writeDraft = React.useCallback((value: Partial<CategoryInDraft>) => {
        setDraft({ ...draft, ...value });
    }, [draft]);

    React.useEffect(() => {
        setDraft(valueToDraft(category));
        setPhotoUrl(`${settings.cloudfront_url}/${category?.image}`);
    }, [category]);

    React.useEffect(() => {
        setParent(current_parent);
    }, [current_parent]);

    const { name } = draft;

    const { isValid, errors } = React.useMemo(() => {
        var isValid = true;
        const errors: { [key: string]: string } = {};
        if (!name) {
            errors.name = "Name is required!";
            isValid = false;
        }
        if (parent?.id === id) {
            errors.parent = "Same category detected.";
            isValid = false;
        }
        return { isValid, errors };
    }, [id, name, parent?.id]);
    const submit = React.useCallback(async () => {
        await saveCategory({
            variables: {
                category: {
                    id: id?.toString(),
                    name,
                    parent_id: parent?.id ? Number.parseInt(parent.id) : -1,
                    partner_id: partner?.id ? Number.parseInt(partner.id) : undefined
                }
            }
        });
        onClose();
    }, [id, name, onClose, parent?.id, partner?.id, saveCategory]);

    const filePickerRef = React.useRef<HTMLInputElement>(null);
    const [photoUploading, setPhotoUploading] = React.useState(false);
    return (<Dialog open={open} maxWidth="sm" fullWidth>
        <DialogTitle>{data?.ecommerceQuery?.category?.name ?? "New Category"}</DialogTitle>
        <DialogContent>
            <Stack spacing={2} paddingTop={.5}>
                <TextEditor
                    label="Name"
                    value={name ?? ""}
                    fullWidth
                    size="small"
                    inputElementType="TextField"
                    onValidated={(value) => { writeDraft({ name: value }) }}
                    error={!!errors.name}
                    helperText={errors.name}
                />
                <EcommerceCategoryAutoComplete size="small" error={!!errors.parent} helperText={errors.parent} selectedCategory={parent} onSelected={setParent} label="Parent Category" />
                <PartnerAutoComplete size="small" selectedPartner={partner} onSelected={setPartner} label="Responsible To" />
                <Grid container>
                    <Grid item>
                        <Box onClick={() => { if (isValid) filePickerRef?.current?.click(); }} width={128} height={128} display="flex" alignItems="center" border="1px solid rgba(0,0,0,.3)" justifyContent="center" textAlign="center" sx={{ backgroundImage: `url("${photoUrl}")`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
                            <Typography variant="body2">Click to upload photo.</Typography>
                        </Box>
                    </Grid>
                </Grid>
                <FilePickerHidden
                    inputRef={filePickerRef}
                    multiple={false}
                    accept="image/*"
                    onFilesAccepted={async (files) => {
                        const [file] = files;
                        if (file) {
                            const image = new Image();
                            image.onload = async (e) => {
                                if (image.width < 128 || image.height < 128) {
                                    setErrorInfo({ title: "Photo Error!", message: "Photo must have at least 128px x 128px width and height.", detail: "" })
                                } else {
                                    setPhotoUrl(image.src);

                                    const form = new FormData();
                                    const fileExt =
                                        file.name.substring(
                                            file.name.lastIndexOf(".") + 1,
                                            file.name.length
                                        ) || file.name;
                                    form.append("file", file, `${category?.id}.${fileExt}`);
                                    form.append("name", name);
                                    if (parent)
                                        form.append("parent_id", parent?.id);
                                    if (partner)
                                        form.append("partner_id", partner.id);
                                    try {
                                        setPhotoUploading(true);
                                        const result = await fetch("/FileUpload/ProductCategoryPhoto", {
                                            method: "POST",
                                            body: form,
                                        });
                                        const uploadedCategory = await result.json();
                                        if (draft.id !== uploadedCategory.id) {
                                            addNewCategoryToCache(uploadedCategory);
                                        } else
                                            updateCategoryToCatch(uploadedCategory);
                                    } finally {
                                        setPhotoUploading(false);
                                    }
                                }
                            };
                            image.src = window.URL.createObjectURL(file);
                        }
                    }}
                />
            </Stack>
        </DialogContent>
        <DialogActions>
            <DialogActionLoadingIndicator
                loading={loading || saving || photoUploading}
                text={loading ? "Loading category..." : (saving ? "Saving category..." : "Uploading photo...")}
            />
            <Button
                disabled={
                    loading || !isValid || saving || photoUploading
                }
                onClick={submit}
                color="secondary"
            >
                OK
            </Button>
            <Button disabled={loading || saving || photoUploading} onClick={onClose}>
                Cancel
            </Button>
        </DialogActions>
    </Dialog>)
}

export default CategoryEditor;