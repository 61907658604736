import {gql} from "@apollo/client";

export const journalsQuery = gql`
query Journals($journalType:[JournalType!],$defaultAccountId:Int){
  commonQuery{
    id
    journals(journalType:$journalType,defaultAccountId:$defaultAccountId){
      id
      code
      name
      type
      default_account_id{
        id
        display_name
      }
    }
  }
}
`;

export const productItemsQuery = gql`
  query ProductItems(
    $where: ProductListItemWhereInput
    $orderBy: [ProductListItemOrderBy!]
    $pagination: PaginationInput
    $includeValuation: Boolean = false
    $company_ids: [Int!]
    $quants:Boolean!
  ) {
    productQuery {
      id
      productItems(
        where: $where
        pagination: $pagination
        orderBy: $orderBy
        company_ids: $company_ids
      ) {
        edges {
          id
          name
          supplier
          supplier_category
          default_code
          barcode
          search_code
          quantity @include(if: $quants)
          reserved_quantity @include(if: $quants)
          qty_available @include(if: $quants)
          list_price
          uom
          uom_id
          current_cost @include(if: $includeValuation)
          current_margin @include(if: $includeValuation)
        }
        pageInfo {
          currentPage
          hasNextPage
          hasPreviousPage
          rowCount
          pageCount
          pageSize
        }
      }
    }
  }
`;

export const priceListsQuery = gql`query PriceLists{
  productQuery{
    priceLists{
      id
      name
    }
  }
}`;

export const employeesQuery = gql`
query Employees($search:String){
  partnerQuery{
    id
    employees(search:$search){
      edges{
        id
        name
      }
      pageInfo{
        hasNextPage
        hasPreviousPage
        rowCount
        pageCount
        currentPage
        pageSize
      }
    }
  }
}
`;

export const analyticAccountsQuery = gql`
query AnalyticAccounts{
  commonQuery{
    id
    analyticAccounts(active:true){
      id
      code
      name
    }
  }
}
`;

export const saleTeamsQuery = gql`
query SaleTeams{
  commonQuery{
    id
   	saleTeams(active:true){
      id
      name
    }
  }
}
`;

export const warehousesQuery = gql`
query Warehouses{
  inventoryQuery{
    id
   	warehouses(active:true){
      id
      code
      name
    }
  }
}
`;

export const accountingAccountsQuery = gql`
query Accounts($where:AccountingAccountWhereInput, $pagination:PaginationInput,$tags:[Int!]){
  accountingQuery{
    id
   	accountingAccounts(where:$where, pagination:$pagination,tags:$tags){
      edges{
        id
        code
        deprecated
        name
        account_type
      }
      pageInfo{
        currentPage
        hasNextPage
        hasPreviousPage
        pageCount
        pageSize
        rowCount
      }
    }
  }
}
`;

export const partnersQuery = gql`
query Partners($where:PartnerWhereInput,$pagination:PaginationInput,$orderBy:[PartnerOrderBy!]){
  partnerQuery{
    id
  	partners(where:$where,pagination:$pagination,orderBy:$orderBy){
      edges{
        id
        name
        phone
        sale_order_count
        purchase_order_count
      }
      pageInfo{
        pageSize
        pageCount
        hasNextPage
        hasPreviousPage
        rowCount  
        currentPage
      }
    }
  }
}
`;


export const townshipsQuery = gql`
query Townships($search:String){
  regionQuery{
    id
    townships(search:$search){
      id
      township_pcode
      name_mm
      name_en
      region{
        id
        name_mm
        name_en
      }
    }
  }
}
`;

export const contactTitlesQuery = gql`
  query ContactTitles {
    commonQuery {
      id
      contactTitles {
        id
        name
      }
    }
  }
`;

export const companyQuery = gql`
query Company{
  commonQuery{
    id
    companies{
      id
      name
    }
  }
}
`;