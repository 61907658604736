import {
  ApolloClient,
  HttpLink,
  ApolloLink,
  InMemoryCache,
  from,
  FieldPolicy,
} from "@apollo/client";
import { store } from "./store";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import { onError } from "@apollo/client/link/error";
import { openSnackbar } from "../features/site/siteSlice";
import update from "immutability-helper";
import { ApolloListResult } from "mui-apollo-virtualized-table";
const httpLink = new HttpLink({
  uri: "/graphql",
  fetch: (input: RequestInfo, init?: RequestInit) => {
    return fetch(input, init).catch((error) => {
      if (error.code === 20 && error.name === "AbortError") {
        store.dispatch(hideLoading());
      } else if (error.code === 401) {
        store.dispatch(hideLoading());
      }
      throw error;
    });
  },
});
const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: `Bearer ${localStorage.getItem("token") || null}`,
    },
  }));

  return forward(operation);
});

const activityMiddleware = new ApolloLink((operation, forward) => {
  store.dispatch(showLoading());
  return forward(operation).map((response) => {
    store.dispatch(hideLoading());
    return response;
  });
});
const errorLink = onError(({ networkError, graphQLErrors }) => {
  if (networkError) {
    store.dispatch(
      openSnackbar({ message: "Can't connect to server. Check connection!" })
    );
  }
  store.dispatch(hideLoading());
});

function buildQueryListResultFieldPolicy<T>({
  keyArgs,
}: Pick<FieldPolicy, "keyArgs">) {
  return {
    keyArgs,
    merge: (existing: ApolloListResult<T>, incoming: ApolloListResult<T>) => {
      if (!existing) return incoming;
      if(incoming?.pageInfo?.currentPage ===1)
        return incoming;
      if(incoming?.pageInfo?.currentPage === existing?.pageInfo?.currentPage)
        return incoming;
      const merged = update(incoming, {
        edges: {
          $set: [...existing.edges, ...incoming.edges],
        },
      });
      return merged;
    },
  };
}

const apolloClient = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      SaleOrderQuery: {
        fields: {
          saleOrders: buildQueryListResultFieldPolicy({
            keyArgs: ["where", "orderBy"],
          }),
        },
      },
      ProductQuery: {
        fields: {
          productItems: buildQueryListResultFieldPolicy({
            keyArgs: ["where", "orderBy", "includeValuation", "company_ids"],
          }),
        },
      },
      SaleReportQuery: {
        fields: {
          gpDetails: buildQueryListResultFieldPolicy({
            keyArgs: ["where", "orderBy", "counter", "start_date", "end_date"],
          }),
          invoices: buildQueryListResultFieldPolicy({
            keyArgs: ["where", "orderBy", "counter"],
          }),
        },
      },
      StockValuationQuery: {
        fields: {
          valuations: buildQueryListResultFieldPolicy({
            keyArgs: ["where", "orderBy"],
          }),
        },
      },
      PurchaseReportQuery: {
        fields: {
          purchase_lines: buildQueryListResultFieldPolicy({keyArgs: ["where", "orderBy", "counter"]}),
        },
      },
      PurchaseOrderQuery: {
        fields: {
          landedCostCheckList: buildQueryListResultFieldPolicy({keyArgs: ["where", "orderBy", "start_date", "end_date"]}),
        },
      },
      InventoryQuery: {
        fields: {
          pickings: buildQueryListResultFieldPolicy({keyArgs: ["where", "orderBy"]}),
          stockTransactions: buildQueryListResultFieldPolicy({keyArgs: ["where", "orderBy", "start_date", "end_date", "show_location"]})
        },
      },
      PartnerQuery: {
        fields: {
          partners: buildQueryListResultFieldPolicy({
            keyArgs: ["where", "orderBy"],
          }),
          customers: buildQueryListResultFieldPolicy({
            keyArgs: ["where", "orderBy"], 
          }),
        },
      },
      AccountingQuery: {
        fields: {
          cashTransactions: buildQueryListResultFieldPolicy({
            keyArgs: ["where", "account_id", "orderBy", "counter"],
          }),
          accountBalance: buildQueryListResultFieldPolicy({keyArgs: ["duration", "account_id", "orderBy"]}),
        },
      },
      PromotionQuery:{
        fields:{
          promotions: buildQueryListResultFieldPolicy({keyArgs:["where","orderBy"]}),
          promotion_sale: buildQueryListResultFieldPolicy({keyArgs:["where","orderBy"]})
        }
      },
      UserQuery:{
        fields:{
          users:buildQueryListResultFieldPolicy({keyArgs:["where","orderBy"]})
        }
      },
      EcommerceCategory:{
        fields:{
          products: buildQueryListResultFieldPolicy({keyArgs:["where","orderBy"]})
        }
      }
    },
  }),
  link: from([errorLink, authMiddleware, activityMiddleware, httpLink]),
});

export default apolloClient;
