
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import {
  Outlet,
  useMatch,
  useNavigate,
} from "react-router-dom";
import DrawerToggleButton from "../../app/DrawerToggleButton";

export function SettingList() {
  const history = useNavigate();
  return (
    <>
      <List component="nav" sx={{ width: 200, borderRightWidth: 1, borderStyle: "solid", borderColor: "rgba(0,0,0,.2)" }}>
        <ListItem
          button
          selected={Boolean(useMatch("/settings"))}
          onClick={() => {
            history("/settings");
          }}
        >
          <ListItemText primary="General" />
        </ListItem>
        <Divider />
        <ListItem
          button
          selected={Boolean(useMatch("/settings/sale"))}
          onClick={() => {
            history("/settings/sale");
          }}
        >
          <ListItemText primary="Sale" />
        </ListItem>
        <Divider />
        <ListItem
          selected={Boolean(useMatch("/settings/product_label"))}
          button
          onClick={() => {
            history("/settings/product_label");
          }}
        >
          <ListItemText primary="Product Label" />
        </ListItem>
        <Divider />
        <ListItem
          button
          selected={Boolean(useMatch("/settings/price_label"))}
          onClick={() => {
            history("/settings/price_label");
          }}
        >
          <ListItemText primary="Price Label" />
        </ListItem>
        <Divider />
      </List>
      <Outlet />
    </>
  );
}

function Settings() {
  return (
    <Grid container direction="column">
      <AppBar position="static">
        <Toolbar
          sx={(theme) => ({
            [theme.breakpoints.up("md")]: {
              minHeight: theme.spacing(6),
            },
            paddingLeft: 1.5,
          })}
        >
          <DrawerToggleButton />
          <Typography variant="h6" color="inherit" noWrap>
            Settings
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        style={{
          flex: 1,
          overflow: "hidden",
          display: "flex",
          flexDirection: "row",
          flexWrap: "nowrap"
        }}
      >
        <SettingList />
      </Box>
    </Grid>
  );
}

export default Settings;
