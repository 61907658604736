/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AccountBalanceOrderByInput {
  account_id = "account_id",
  account_id_Desc = "account_id_Desc",
  balance = "balance",
  balance_Desc = "balance_Desc",
  date = "date",
  date_Desc = "date_Desc",
  end_date = "end_date",
  end_date_Desc = "end_date_Desc",
  id = "id",
  id_Desc = "id_Desc",
  total = "total",
  total_Desc = "total_Desc",
}

export enum AccountMoveType {
  entry = "entry",
  in_invoice = "in_invoice",
  in_receipt = "in_receipt",
  in_refund = "in_refund",
  out_invoice = "out_invoice",
  out_receipt = "out_receipt",
  out_refund = "out_refund",
}

export enum AccountType {
  asset_cash = "asset_cash",
  asset_current = "asset_current",
  asset_fixed = "asset_fixed",
  asset_non_current = "asset_non_current",
  asset_prepayments = "asset_prepayments",
  asset_receivable = "asset_receivable",
  equity = "equity",
  equity_unaffected = "equity_unaffected",
  expense = "expense",
  expense_depreciation = "expense_depreciation",
  expense_direct_cost = "expense_direct_cost",
  income = "income",
  income_other = "income_other",
  liability_credit_card = "liability_credit_card",
  liability_current = "liability_current",
  liability_non_current = "liability_non_current",
  liability_payable = "liability_payable",
  off_balance = "off_balance",
}

export enum CashTransactionOrderByInput {
  balance = "balance",
  balance_Desc = "balance_Desc",
  date = "date",
  date_Desc = "date_Desc",
  id = "id",
  id_Desc = "id_Desc",
  move_name = "move_name",
  move_name_Desc = "move_name_Desc",
  name = "name",
  name_Desc = "name_Desc",
  partner_name = "partner_name",
  partner_name_Desc = "partner_name_Desc",
  ref = "ref",
  ref_Desc = "ref_Desc",
  type = "type",
  type_Desc = "type_Desc",
}

export enum CashTransactionType {
  inpayment = "inpayment",
  inrefund = "inrefund",
  internal = "internal",
  other = "other",
  outpayment = "outpayment",
  outrefund = "outrefund",
}

export enum DiscountMode {
  per_order = "per_order",
  per_point = "per_point",
  percent = "percent",
}

export enum EcommerceCategoryOrderBy {
  child_id = "child_id",
  child_id_Desc = "child_id_Desc",
  complete_name = "complete_name",
  complete_name_Desc = "complete_name_Desc",
  id = "id",
  id_Desc = "id_Desc",
  image = "image",
  image_Desc = "image_Desc",
  name = "name",
  name_Desc = "name_Desc",
  parent_id = "parent_id",
  parent_id_Desc = "parent_id_Desc",
  parent_path = "parent_path",
  parent_path_Desc = "parent_path_Desc",
  parents_and_self = "parents_and_self",
  parents_and_self_Desc = "parents_and_self_Desc",
  partner_id = "partner_id",
  partner_id_Desc = "partner_id_Desc",
}

export enum GpReportOrderByInput {
  account_id = "account_id",
  account_id_Desc = "account_id_Desc",
  account_name = "account_name",
  account_name_Desc = "account_name_Desc",
  brand = "brand",
  brand_Desc = "brand_Desc",
  customer_city = "customer_city",
  customer_city_Desc = "customer_city_Desc",
  customer_name = "customer_name",
  customer_name_Desc = "customer_name_Desc",
  customer_phone = "customer_phone",
  customer_phone_Desc = "customer_phone_Desc",
  date = "date",
  date_Desc = "date_Desc",
  default_code = "default_code",
  default_code_Desc = "default_code_Desc",
  gp = "gp",
  gp_Desc = "gp_Desc",
  id = "id",
  id_Desc = "id_Desc",
  is_oem = "is_oem",
  is_oem_Desc = "is_oem_Desc",
  price_total = "price_total",
  price_total_Desc = "price_total_Desc",
  product_id = "product_id",
  product_id_Desc = "product_id_Desc",
  product_name = "product_name",
  product_name_Desc = "product_name_Desc",
  product_uom_qty = "product_uom_qty",
  product_uom_qty_Desc = "product_uom_qty_Desc",
  quantity = "quantity",
  quantity_Desc = "quantity_Desc",
  ref = "ref",
  ref_Desc = "ref_Desc",
  ref_id = "ref_id",
  ref_id_Desc = "ref_id_Desc",
  salezone = "salezone",
  salezone_35 = "salezone_35",
  salezone_35_Desc = "salezone_35_Desc",
  salezone_Desc = "salezone_Desc",
  uom = "uom",
  uom_Desc = "uom_Desc",
  value = "value",
  value_Desc = "value_Desc",
  vendor_category = "vendor_category",
  vendor_category_Desc = "vendor_category_Desc",
  vendor_id = "vendor_id",
  vendor_id_Desc = "vendor_id_Desc",
  vendor_name = "vendor_name",
  vendor_name_Desc = "vendor_name_Desc",
}

export enum InvoiceState {
  cancel = "cancel",
  draft = "draft",
  posted = "posted",
}

export enum InvoiceStatus {
  invoiced = "invoiced",
  no = "no",
  toinvoice = "toinvoice",
  upselling = "upselling",
}

export enum JournalType {
  bank = "bank",
  cash = "cash",
  general = "general",
  purchase = "purchase",
  sale = "sale",
}

export enum LandedCostCheckListItemOrderBy {
  bill_price = "bill_price",
  bill_price_Desc = "bill_price_Desc",
  billed_amount = "billed_amount",
  billed_amount_Desc = "billed_amount_Desc",
  billed_qty = "billed_qty",
  billed_qty_Desc = "billed_qty_Desc",
  date_order = "date_order",
  date_order_Desc = "date_order_Desc",
  default_code = "default_code",
  default_code_Desc = "default_code_Desc",
  id = "id",
  id_Desc = "id_Desc",
  landed_cost = "landed_cost",
  landed_cost_Desc = "landed_cost_Desc",
  name = "name",
  name_Desc = "name_Desc",
  order_id = "order_id",
  order_id_Desc = "order_id_Desc",
  order_price = "order_price",
  order_price_Desc = "order_price_Desc",
  ordered_amount = "ordered_amount",
  ordered_amount_Desc = "ordered_amount_Desc",
  ordered_qty = "ordered_qty",
  ordered_qty_Desc = "ordered_qty_Desc",
  partner_ref = "partner_ref",
  partner_ref_Desc = "partner_ref_Desc",
  po = "po",
  po_Desc = "po_Desc",
  price_matched = "price_matched",
  price_matched_Desc = "price_matched_Desc",
  product_id = "product_id",
  product_id_Desc = "product_id_Desc",
  qty_matched = "qty_matched",
  qty_matched_Desc = "qty_matched_Desc",
  received_qty = "received_qty",
  received_qty_Desc = "received_qty_Desc",
  received_value = "received_value",
  received_value_Desc = "received_value_Desc",
  total_value = "total_value",
  total_value_Desc = "total_value_Desc",
  unit_cost = "unit_cost",
  unit_cost_Desc = "unit_cost_Desc",
  uom = "uom",
  uom_Desc = "uom_Desc",
}

export enum PartnerOrderBy {
  active = "active",
  active_Desc = "active_Desc",
  city = "city",
  city_Desc = "city_Desc",
  credit = "credit",
  credit_Desc = "credit_Desc",
  customer_rank = "customer_rank",
  customer_rank_Desc = "customer_rank_Desc",
  email = "email",
  email_Desc = "email_Desc",
  id = "id",
  id_Desc = "id_Desc",
  name = "name",
  name_Desc = "name_Desc",
  phone = "phone",
  phone_Desc = "phone_Desc",
  purchase_order_count = "purchase_order_count",
  purchase_order_count_Desc = "purchase_order_count_Desc",
  ref = "ref",
  ref_Desc = "ref_Desc",
  sale_order_count = "sale_order_count",
  sale_order_count_Desc = "sale_order_count_Desc",
  street = "street",
  street_Desc = "street_Desc",
  supplier_invoice_count = "supplier_invoice_count",
  supplier_invoice_count_Desc = "supplier_invoice_count_Desc",
  supplier_rank = "supplier_rank",
  supplier_rank_Desc = "supplier_rank_Desc",
  title = "title",
  title_Desc = "title_Desc",
  total_invoiced = "total_invoiced",
  total_invoiced_Desc = "total_invoiced_Desc",
  write_date = "write_date",
  write_date_Desc = "write_date_Desc",
}

export enum PartnerType {
  customer = "customer",
  supplier = "supplier",
}

export enum PaymentStatus {
  in_payment = "in_payment",
  invoicing_legacy = "invoicing_legacy",
  not_paid = "not_paid",
  paid = "paid",
  partial = "partial",
  reversed = "reversed",
}

export enum PaymentType {
  inbound = "inbound",
  outbound = "outbound",
}

export enum PaymentWithInvoiceOrderBy {
  amount = "amount",
  amount_Desc = "amount_Desc",
  counterpart_move_date = "counterpart_move_date",
  counterpart_move_date_Desc = "counterpart_move_date_Desc",
  counterpart_move_id = "counterpart_move_id",
  counterpart_move_id_Desc = "counterpart_move_id_Desc",
  counterpart_move_name = "counterpart_move_name",
  counterpart_move_name_Desc = "counterpart_move_name_Desc",
  date = "date",
  date_Desc = "date_Desc",
  early_reconciled_amount = "early_reconciled_amount",
  early_reconciled_amount_Desc = "early_reconciled_amount_Desc",
  id = "id",
  id_Desc = "id_Desc",
  inner_reconciled_amount = "inner_reconciled_amount",
  inner_reconciled_amount_Desc = "inner_reconciled_amount_Desc",
  invoice_origin = "invoice_origin",
  invoice_origin_Desc = "invoice_origin_Desc",
  journal_code = "journal_code",
  journal_code_Desc = "journal_code_Desc",
  journal_type = "journal_type",
  journal_type_Desc = "journal_type_Desc",
  late_reconciled_amount = "late_reconciled_amount",
  late_reconciled_amount_Desc = "late_reconciled_amount_Desc",
  name = "name",
  name_Desc = "name_Desc",
  outer_reconciled_amount = "outer_reconciled_amount",
  outer_reconciled_amount_Desc = "outer_reconciled_amount_Desc",
  partner_id = "partner_id",
  partner_id_Desc = "partner_id_Desc",
  partner_name = "partner_name",
  partner_name_Desc = "partner_name_Desc",
  payment_type = "payment_type",
  payment_type_Desc = "payment_type_Desc",
  ref = "ref",
  ref_Desc = "ref_Desc",
  remaining = "remaining",
  remaining_Desc = "remaining_Desc",
}

export enum PaymentWithoutInvoiceOrderBy {
  amount = "amount",
  amount_Desc = "amount_Desc",
  date = "date",
  date_Desc = "date_Desc",
  id = "id",
  id_Desc = "id_Desc",
  inner_reconciled_amount = "inner_reconciled_amount",
  inner_reconciled_amount_Desc = "inner_reconciled_amount_Desc",
  journal_code = "journal_code",
  journal_code_Desc = "journal_code_Desc",
  journal_type = "journal_type",
  journal_type_Desc = "journal_type_Desc",
  name = "name",
  name_Desc = "name_Desc",
  outer_reconciled_amount = "outer_reconciled_amount",
  outer_reconciled_amount_Desc = "outer_reconciled_amount_Desc",
  partner_id = "partner_id",
  partner_id_Desc = "partner_id_Desc",
  partner_name = "partner_name",
  partner_name_Desc = "partner_name_Desc",
  payment_type = "payment_type",
  payment_type_Desc = "payment_type_Desc",
  ref = "ref",
  ref_Desc = "ref_Desc",
  remaining = "remaining",
  remaining_Desc = "remaining_Desc",
}

export enum ProcureMethod {
  make_to_order = "make_to_order",
  make_to_stock = "make_to_stock",
}

export enum ProductAvailability {
  live = "live",
  not_available = "not_available",
  preorder = "preorder",
}

export enum ProductListItemOrderBy {
  barcode = "barcode",
  barcode_Desc = "barcode_Desc",
  brand = "brand",
  brand_Desc = "brand_Desc",
  brand_id = "brand_id",
  brand_id_Desc = "brand_id_Desc",
  current_cost = "current_cost",
  current_cost_Desc = "current_cost_Desc",
  current_margin = "current_margin",
  current_margin_Desc = "current_margin_Desc",
  default_code = "default_code",
  default_code_Desc = "default_code_Desc",
  id = "id",
  id_Desc = "id_Desc",
  list_price = "list_price",
  list_price_Desc = "list_price_Desc",
  name = "name",
  name_Desc = "name_Desc",
  qty_available = "qty_available",
  qty_available_Desc = "qty_available_Desc",
  quantity = "quantity",
  quantity_Desc = "quantity_Desc",
  reserved_quantity = "reserved_quantity",
  reserved_quantity_Desc = "reserved_quantity_Desc",
  salezone = "salezone",
  salezone_Desc = "salezone_Desc",
  search_code = "search_code",
  search_code_Desc = "search_code_Desc",
  supplier = "supplier",
  supplier_Desc = "supplier_Desc",
  supplier_category = "supplier_category",
  supplier_category_Desc = "supplier_category_Desc",
  type = "type",
  type_Desc = "type_Desc",
  uom = "uom",
  uom_Desc = "uom_Desc",
  uom_id = "uom_id",
  uom_id_Desc = "uom_id_Desc",
  zone_id = "zone_id",
  zone_id_Desc = "zone_id_Desc",
}

export enum ProductLotOrderBy {
  id = "id",
  id_Desc = "id_Desc",
  name = "name",
  name_Desc = "name_Desc",
  product_id = "product_id",
  product_id_Desc = "product_id_Desc",
}

export enum ProductOrderBy {
  active = "active",
  active_Desc = "active_Desc",
  attribute_line_ids = "attribute_line_ids",
  attribute_line_ids_Desc = "attribute_line_ids_Desc",
  attribute_value_ids = "attribute_value_ids",
  attribute_value_ids_Desc = "attribute_value_ids_Desc",
  barcode = "barcode",
  barcode_Desc = "barcode_Desc",
  can_be_expensed = "can_be_expensed",
  can_be_expensed_Desc = "can_be_expensed_Desc",
  categ_id = "categ_id",
  categ_id_Desc = "categ_id_Desc",
  cost_method = "cost_method",
  cost_method_Desc = "cost_method_Desc",
  create_date = "create_date",
  create_date_Desc = "create_date_Desc",
  create_uid = "create_uid",
  create_uid_Desc = "create_uid_Desc",
  default_code = "default_code",
  default_code_Desc = "default_code_Desc",
  description = "description",
  description_Desc = "description_Desc",
  description_picking = "description_picking",
  description_picking_Desc = "description_picking_Desc",
  description_pickingin = "description_pickingin",
  description_pickingin_Desc = "description_pickingin_Desc",
  description_pickingout = "description_pickingout",
  description_pickingout_Desc = "description_pickingout_Desc",
  description_purchase = "description_purchase",
  description_purchase_Desc = "description_purchase_Desc",
  description_sale = "description_sale",
  description_sale_Desc = "description_sale_Desc",
  display_name = "display_name",
  display_name_Desc = "display_name_Desc",
  id = "id",
  id_Desc = "id_Desc",
  incoming_qty = "incoming_qty",
  incoming_qty_Desc = "incoming_qty_Desc",
  is_product_variant = "is_product_variant",
  is_product_variant_Desc = "is_product_variant_Desc",
  landed_cost_ok = "landed_cost_ok",
  landed_cost_ok_Desc = "landed_cost_ok_Desc",
  list_price = "list_price",
  list_price_Desc = "list_price_Desc",
  name = "name",
  name_Desc = "name_Desc",
  outgoing_qty = "outgoing_qty",
  outgoing_qty_Desc = "outgoing_qty_Desc",
  product_tmpl_id = "product_tmpl_id",
  product_tmpl_id_Desc = "product_tmpl_id_Desc",
  purchase_count = "purchase_count",
  purchase_count_Desc = "purchase_count_Desc",
  qty_available = "qty_available",
  qty_available_Desc = "qty_available_Desc",
  route_ids = "route_ids",
  route_ids_Desc = "route_ids_Desc",
  sales_count = "sales_count",
  sales_count_Desc = "sales_count_Desc",
  standard_price = "standard_price",
  standard_price_Desc = "standard_price_Desc",
  type = "type",
  type_Desc = "type_Desc",
  uom_id = "uom_id",
  uom_id_Desc = "uom_id_Desc",
  uom_po_id = "uom_po_id",
  uom_po_id_Desc = "uom_po_id_Desc",
  virtual_available = "virtual_available",
  virtual_available_Desc = "virtual_available_Desc",
}

export enum ProductType {
  consu = "consu",
  product = "product",
  service = "service",
}

export enum ProductValuationBreakdownOrderBy {
  create_date = "create_date",
  create_date_Desc = "create_date_Desc",
  id = "id",
  id_Desc = "id_Desc",
  layer_id = "layer_id",
  layer_id_Desc = "layer_id_Desc",
  name = "name",
  name_Desc = "name_Desc",
  quantity = "quantity",
  quantity_Desc = "quantity_Desc",
  unit_cost = "unit_cost",
  unit_cost_Desc = "unit_cost_Desc",
  valuation_type = "valuation_type",
  valuation_type_Desc = "valuation_type_Desc",
  value = "value",
  value_Desc = "value_Desc",
}

export enum ProductValuationBreakdownType {
  billing = "billing",
  entry = "entry",
  landed_cost = "landed_cost",
}

export enum ProductWarranty {
  full = "full",
  full_then_service = "full_then_service",
  not_available = "not_available",
  service_only = "service_only",
}

export enum PromotionOrderBy {
  active = "active",
  active_Desc = "active_Desc",
  company_id = "company_id",
  company_id_Desc = "company_id_Desc",
  created_id = "created_id",
  created_id_Desc = "created_id_Desc",
  created_name = "created_name",
  created_name_Desc = "created_name_Desc",
  date_from = "date_from",
  date_from_Desc = "date_from_Desc",
  date_to = "date_to",
  date_to_Desc = "date_to_Desc",
  id = "id",
  id_Desc = "id_Desc",
  loyalty_program_ids = "loyalty_program_ids",
  loyalty_program_ids_Desc = "loyalty_program_ids_Desc",
  name = "name",
  name_Desc = "name_Desc",
  reward_product_income_account_id = "reward_product_income_account_id",
  reward_product_income_account_id_Desc = "reward_product_income_account_id_Desc",
}

export enum PromotionSaleOrderBy {
  account_name = "account_name",
  account_name_Desc = "account_name_Desc",
  amount = "amount",
  amount_Desc = "amount_Desc",
  date = "date",
  date_Desc = "date_Desc",
  default_code = "default_code",
  default_code_Desc = "default_code_Desc",
  description = "description",
  description_Desc = "description_Desc",
  id = "id",
  id_Desc = "id_Desc",
  move_name = "move_name",
  move_name_Desc = "move_name_Desc",
  name = "name",
  name_Desc = "name_Desc",
  order_name = "order_name",
  order_name_Desc = "order_name_Desc",
  partner = "partner",
  partner_Desc = "partner_Desc",
  product_name = "product_name",
  product_name_Desc = "product_name_Desc",
  promotion_id = "promotion_id",
  promotion_id_Desc = "promotion_id_Desc",
  quantity = "quantity",
  quantity_Desc = "quantity_Desc",
}

export enum PurchaseLineReportOrderBy {
  brand = "brand",
  brand_Desc = "brand_Desc",
  default_code = "default_code",
  default_code_Desc = "default_code_Desc",
  id = "id",
  id_Desc = "id_Desc",
  invoice_date = "invoice_date",
  invoice_date_Desc = "invoice_date_Desc",
  invoice_origin = "invoice_origin",
  invoice_origin_Desc = "invoice_origin_Desc",
  move_id = "move_id",
  move_id_Desc = "move_id_Desc",
  name = "name",
  name_Desc = "name_Desc",
  partner = "partner",
  partner_Desc = "partner_Desc",
  partner_id = "partner_id",
  partner_id_Desc = "partner_id_Desc",
  price_total = "price_total",
  price_total_Desc = "price_total_Desc",
  price_unit = "price_unit",
  price_unit_Desc = "price_unit_Desc",
  product_id = "product_id",
  product_id_Desc = "product_id_Desc",
  product_name = "product_name",
  product_name_Desc = "product_name_Desc",
  quantity = "quantity",
  quantity_Desc = "quantity_Desc",
  ref = "ref",
  ref_Desc = "ref_Desc",
  salezone = "salezone",
  salezone_Desc = "salezone_Desc",
  uom = "uom",
  uom_Desc = "uom_Desc",
}

export enum RewardType {
  discount = "discount",
  product = "product",
}

export enum SaleInvoiceReportOrderBy {
  amount_residual = "amount_residual",
  amount_residual_Desc = "amount_residual_Desc",
  amount_total = "amount_total",
  amount_total_Desc = "amount_total_Desc",
  counterpart_move_id = "counterpart_move_id",
  counterpart_move_id_Desc = "counterpart_move_id_Desc",
  counterpart_move_name = "counterpart_move_name",
  counterpart_move_name_Desc = "counterpart_move_name_Desc",
  id = "id",
  id_Desc = "id_Desc",
  invoice_by = "invoice_by",
  invoice_by_Desc = "invoice_by_Desc",
  invoice_date = "invoice_date",
  invoice_date_Desc = "invoice_date_Desc",
  invoice_origin = "invoice_origin",
  invoice_origin_Desc = "invoice_origin_Desc",
  journal_code = "journal_code",
  journal_code_Desc = "journal_code_Desc",
  journal_type = "journal_type",
  journal_type_Desc = "journal_type_Desc",
  move_id = "move_id",
  move_id_Desc = "move_id_Desc",
  name = "name",
  name_Desc = "name_Desc",
  order_by = "order_by",
  order_by_Desc = "order_by_Desc",
  partner_name = "partner_name",
  partner_name_Desc = "partner_name_Desc",
  payment_state = "payment_state",
  payment_state_Desc = "payment_state_Desc",
  team_name = "team_name",
  team_name_Desc = "team_name_Desc",
}

export enum SaleOrderOrderBy {
  amount_total = "amount_total",
  amount_total_Desc = "amount_total_Desc",
  amount_untaxed = "amount_untaxed",
  amount_untaxed_Desc = "amount_untaxed_Desc",
  analytic_account_id = "analytic_account_id",
  analytic_account_id_Desc = "analytic_account_id_Desc",
  cashier_id = "cashier_id",
  cashier_id_Desc = "cashier_id_Desc",
  cashier_name = "cashier_name",
  cashier_name_Desc = "cashier_name_Desc",
  client_order_ref = "client_order_ref",
  client_order_ref_Desc = "client_order_ref_Desc",
  date_order = "date_order",
  date_order_Desc = "date_order_Desc",
  id = "id",
  id_Desc = "id_Desc",
  invoice_ids = "invoice_ids",
  invoice_ids_Desc = "invoice_ids_Desc",
  invoice_status = "invoice_status",
  invoice_status_Desc = "invoice_status_Desc",
  name = "name",
  name_Desc = "name_Desc",
  note = "note",
  note_Desc = "note_Desc",
  order_line = "order_line",
  order_line_Desc = "order_line_Desc",
  origin = "origin",
  origin_Desc = "origin_Desc",
  partner_id = "partner_id",
  partner_id_Desc = "partner_id_Desc",
  picking_ids = "picking_ids",
  picking_ids_Desc = "picking_ids_Desc",
  pricelist_id = "pricelist_id",
  pricelist_id_Desc = "pricelist_id_Desc",
  saleperson_id = "saleperson_id",
  saleperson_id_Desc = "saleperson_id_Desc",
  saleperson_name = "saleperson_name",
  saleperson_name_Desc = "saleperson_name_Desc",
  state = "state",
  state_Desc = "state_Desc",
  team_id = "team_id",
  team_id_Desc = "team_id_Desc",
  warehouse_id = "warehouse_id",
  warehouse_id_Desc = "warehouse_id_Desc",
}

export enum SaleOrderState {
  cancel = "cancel",
  done = "done",
  draft = "draft",
  sale = "sale",
  sent = "sent",
}

export enum StockPickingOrderBy {
  backorder_id = "backorder_id",
  backorder_id_Desc = "backorder_id_Desc",
  date_done = "date_done",
  date_done_Desc = "date_done_Desc",
  id = "id",
  id_Desc = "id_Desc",
  immediate_transfer = "immediate_transfer",
  immediate_transfer_Desc = "immediate_transfer_Desc",
  location_dest_id = "location_dest_id",
  location_dest_id_Desc = "location_dest_id_Desc",
  location_id = "location_id",
  location_id_Desc = "location_id_Desc",
  move_type = "move_type",
  move_type_Desc = "move_type_Desc",
  name = "name",
  name_Desc = "name_Desc",
  origin = "origin",
  origin_Desc = "origin_Desc",
  owner_id = "owner_id",
  owner_id_Desc = "owner_id_Desc",
  partner_id = "partner_id",
  partner_id_Desc = "partner_id_Desc",
  picking_type_code = "picking_type_code",
  picking_type_code_Desc = "picking_type_code_Desc",
  picking_type_id = "picking_type_id",
  picking_type_id_Desc = "picking_type_id_Desc",
  purchase_id = "purchase_id",
  purchase_id_Desc = "purchase_id_Desc",
  sale_id = "sale_id",
  sale_id_Desc = "sale_id_Desc",
  scheduled_date = "scheduled_date",
  scheduled_date_Desc = "scheduled_date_Desc",
  show_check_availability = "show_check_availability",
  show_check_availability_Desc = "show_check_availability_Desc",
  show_validate = "show_validate",
  show_validate_Desc = "show_validate_Desc",
  state = "state",
  state_Desc = "state_Desc",
}

export enum StockPickingShippingPolicy {
  direct = "direct",
  one = "one",
}

export enum StockPickingState {
  assigned = "assigned",
  cancel = "cancel",
  confirmed = "confirmed",
  done = "done",
  draft = "draft",
  partially_available = "partially_available",
  waiting = "waiting",
}

export enum StockPickingType {
  incoming = "incoming",
  internal = "internal",
  outgoing = "outgoing",
}

export enum StockTrackingType {
  lot = "lot",
  none = "none",
  serial = "serial",
}

export enum StockTransactionHistoryOrderBy {
  adjustment_in = "adjustment_in",
  adjustment_in_Desc = "adjustment_in_Desc",
  adjustment_out = "adjustment_out",
  adjustment_out_Desc = "adjustment_out_Desc",
  brand = "brand",
  brand_Desc = "brand_Desc",
  category = "category",
  category_Desc = "category_Desc",
  cb = "cb",
  cb_Desc = "cb_Desc",
  ccb = "ccb",
  ccb_Desc = "ccb_Desc",
  company_id = "company_id",
  company_id_Desc = "company_id_Desc",
  counterpart_location_id = "counterpart_location_id",
  counterpart_location_id_Desc = "counterpart_location_id_Desc",
  counterpart_location_name = "counterpart_location_name",
  counterpart_location_name_Desc = "counterpart_location_name_Desc",
  date = "date",
  date_Desc = "date_Desc",
  default_code = "default_code",
  default_code_Desc = "default_code_Desc",
  id = "id",
  id_Desc = "id_Desc",
  incoming = "incoming",
  incoming_Desc = "incoming_Desc",
  location_id = "location_id",
  location_id_Desc = "location_id_Desc",
  location_name = "location_name",
  location_name_Desc = "location_name_Desc",
  name = "name",
  name_Desc = "name_Desc",
  ob = "ob",
  ob_Desc = "ob_Desc",
  origin = "origin",
  origin_Desc = "origin_Desc",
  outgoing = "outgoing",
  outgoing_Desc = "outgoing_Desc",
  product_id = "product_id",
  product_id_Desc = "product_id_Desc",
  product_name = "product_name",
  product_name_Desc = "product_name_Desc",
  sale_zone = "sale_zone",
  sale_zone_Desc = "sale_zone_Desc",
  scrap = "scrap",
  scrap_Desc = "scrap_Desc",
  search_code = "search_code",
  search_code_Desc = "search_code_Desc",
  transfer_in = "transfer_in",
  transfer_in_Desc = "transfer_in_Desc",
  transfer_out = "transfer_out",
  transfer_out_Desc = "transfer_out_Desc",
}

export enum StockValuationLayerReportOrderBy {
  brand = "brand",
  brand_Desc = "brand_Desc",
  create_date = "create_date",
  create_date_Desc = "create_date_Desc",
  create_time = "create_time",
  create_time_Desc = "create_time_Desc",
  default_code = "default_code",
  default_code_Desc = "default_code_Desc",
  description = "description",
  description_Desc = "description_Desc",
  final_cost = "final_cost",
  final_cost_Desc = "final_cost_Desc",
  final_value = "final_value",
  final_value_Desc = "final_value_Desc",
  id = "id",
  id_Desc = "id_Desc",
  name = "name",
  name_Desc = "name_Desc",
  partner_id = "partner_id",
  partner_id_Desc = "partner_id_Desc",
  product_id = "product_id",
  product_id_Desc = "product_id_Desc",
  quantity = "quantity",
  quantity_Desc = "quantity_Desc",
  remaining_qty = "remaining_qty",
  remaining_qty_Desc = "remaining_qty_Desc",
  remaining_value = "remaining_value",
  remaining_value_Desc = "remaining_value_Desc",
  salezone = "salezone",
  salezone_Desc = "salezone_Desc",
  stock_move = "stock_move",
  stock_move_Desc = "stock_move_Desc",
  stock_move_id = "stock_move_id",
  stock_move_id_Desc = "stock_move_id_Desc",
  supplier_category = "supplier_category",
  supplier_category_Desc = "supplier_category_Desc",
  supplier_name = "supplier_name",
  supplier_name_Desc = "supplier_name_Desc",
  uom = "uom",
  uom_Desc = "uom_Desc",
}

export enum TriPriceChangeOrderBy {
  company_id = "company_id",
  company_id_Desc = "company_id_Desc",
  create_date = "create_date",
  create_date_Desc = "create_date_Desc",
  create_uid = "create_uid",
  create_uid_Desc = "create_uid_Desc",
  created_id = "created_id",
  created_id_Desc = "created_id_Desc",
  created_name = "created_name",
  created_name_Desc = "created_name_Desc",
  description = "description",
  description_Desc = "description_Desc",
  detail_ids = "detail_ids",
  detail_ids_Desc = "detail_ids_Desc",
  id = "id",
  id_Desc = "id_Desc",
  name = "name",
  name_Desc = "name_Desc",
  price_list_id = "price_list_id",
  price_list_id_Desc = "price_list_id_Desc",
  state = "state",
  state_Desc = "state_Desc",
}

export enum TriPriceChangeState {
  draft = "draft",
  posted = "posted",
}

export enum UserOrderBy {
  code = "code",
  code_Desc = "code_Desc",
  fullName = "fullName",
  fullName_Desc = "fullName_Desc",
  odooUserName = "odooUserName",
  odooUserName_Desc = "odooUserName_Desc",
}

export enum WarrantyUnit {
  days = "days",
  lifetime = "lifetime",
  months = "months",
  years = "years",
}

export interface AccountPaymentInput {
  payment_type: PaymentType;
  partner_type: PartnerType;
  partner_id?: number | null;
  destination_account_id?: number | null;
  destination_journal_id?: number | null;
  is_internal_transfer: boolean;
  amount: number;
  date: any;
  ref?: string | null;
  journal_id: number;
  team_id?: number | null;
  invoice_origin?: string | null;
}

export interface AccountingAccountWhereInput {
  code?: string | null;
  code_Not?: string | null;
  code_In?: (string | null)[] | null;
  code_NotIn?: (string | null)[] | null;
  code_Lt?: string | null;
  code_Lte?: string | null;
  code_Gt?: string | null;
  code_Gte?: string | null;
  code_Contains?: string | null;
  code_NotContains?: string | null;
  code_StartsWith?: string | null;
  code_NotStartsWith?: string | null;
  code_EndsWith?: string | null;
  code_NotEndsWith?: string | null;
  code_IsBlank?: boolean | null;
  aND?: AccountingAccountWhereInput[] | null;
  oR?: AccountingAccountWhereInput[] | null;
  name?: string | null;
  name_Not?: string | null;
  name_In?: (string | null)[] | null;
  name_NotIn?: (string | null)[] | null;
  name_Lt?: string | null;
  name_Lte?: string | null;
  name_Gt?: string | null;
  name_Gte?: string | null;
  name_Contains?: string | null;
  name_NotContains?: string | null;
  name_StartsWith?: string | null;
  name_NotStartsWith?: string | null;
  name_EndsWith?: string | null;
  name_NotEndsWith?: string | null;
  name_IsBlank?: boolean | null;
  account_type?: AccountType | null;
  account_type_Not?: AccountType | null;
  account_type_In?: AccountType[] | null;
  account_type_NotIn?: AccountType[] | null;
  account_type_IsBlank?: boolean | null;
  deprecated?: boolean | null;
  id?: string | null;
  id_Not?: string | null;
  id_In?: (string | null)[] | null;
  id_NotIn?: (string | null)[] | null;
  id_Lt?: string | null;
  id_Lte?: string | null;
  id_Gt?: string | null;
  id_Gte?: string | null;
  id_IsBlank?: boolean | null;
}

export interface CashTransactionWhereInput {
  id?: string | null;
  id_Not?: string | null;
  id_In?: (string | null)[] | null;
  id_NotIn?: (string | null)[] | null;
  id_Lt?: string | null;
  id_Lte?: string | null;
  id_Gt?: string | null;
  id_Gte?: string | null;
  id_IsBlank?: boolean | null;
  aND?: CashTransactionWhereInput[] | null;
  oR?: CashTransactionWhereInput[] | null;
  date?: any | null;
  date_Not?: any | null;
  date_Lt?: any | null;
  date_Lte?: any | null;
  date_Gt?: any | null;
  date_Gte?: any | null;
  date_IsBlank?: any | null;
  move_name?: string | null;
  move_name_Not?: string | null;
  move_name_In?: (string | null)[] | null;
  move_name_NotIn?: (string | null)[] | null;
  move_name_Lt?: string | null;
  move_name_Lte?: string | null;
  move_name_Gt?: string | null;
  move_name_Gte?: string | null;
  move_name_Contains?: string | null;
  move_name_NotContains?: string | null;
  move_name_StartsWith?: string | null;
  move_name_NotStartsWith?: string | null;
  move_name_EndsWith?: string | null;
  move_name_NotEndsWith?: string | null;
  move_name_IsBlank?: boolean | null;
  name?: string | null;
  name_Not?: string | null;
  name_In?: (string | null)[] | null;
  name_NotIn?: (string | null)[] | null;
  name_Lt?: string | null;
  name_Lte?: string | null;
  name_Gt?: string | null;
  name_Gte?: string | null;
  name_Contains?: string | null;
  name_NotContains?: string | null;
  name_StartsWith?: string | null;
  name_NotStartsWith?: string | null;
  name_EndsWith?: string | null;
  name_NotEndsWith?: string | null;
  name_IsBlank?: boolean | null;
  partner_name?: string | null;
  partner_name_Not?: string | null;
  partner_name_In?: (string | null)[] | null;
  partner_name_NotIn?: (string | null)[] | null;
  partner_name_Lt?: string | null;
  partner_name_Lte?: string | null;
  partner_name_Gt?: string | null;
  partner_name_Gte?: string | null;
  partner_name_Contains?: string | null;
  partner_name_NotContains?: string | null;
  partner_name_StartsWith?: string | null;
  partner_name_NotStartsWith?: string | null;
  partner_name_EndsWith?: string | null;
  partner_name_NotEndsWith?: string | null;
  partner_name_IsBlank?: boolean | null;
  ref?: string | null;
  ref_Not?: string | null;
  ref_In?: (string | null)[] | null;
  ref_NotIn?: (string | null)[] | null;
  ref_Lt?: string | null;
  ref_Lte?: string | null;
  ref_Gt?: string | null;
  ref_Gte?: string | null;
  ref_Contains?: string | null;
  ref_NotContains?: string | null;
  ref_StartsWith?: string | null;
  ref_NotStartsWith?: string | null;
  ref_EndsWith?: string | null;
  ref_NotEndsWith?: string | null;
  ref_IsBlank?: boolean | null;
  type?: CashTransactionType | null;
  type_Not?: CashTransactionType | null;
  type_In?: CashTransactionType[] | null;
  type_NotIn?: CashTransactionType[] | null;
  type_IsBlank?: boolean | null;
  balance?: number | null;
  balance_Not?: number | null;
  balance_In?: (number | null)[] | null;
  balance_NotIn?: (number | null)[] | null;
  balance_Lt?: number | null;
  balance_Lte?: number | null;
  balance_Gt?: number | null;
  balance_Gte?: number | null;
  balance_IsBlank?: boolean | null;
}

export interface CouponProgramInputList {
  list?: LoyaltyProgramInput[] | null;
  deleted?: number[] | null;
}

export interface EcommerceCategoryInput {
  id?: string | null;
  name: string;
  parent_id?: number | null;
  partner_id?: number | null;
}

export interface EcommerceCategoryWhereInput {
  name?: string | null;
  name_Not?: string | null;
  name_In?: (string | null)[] | null;
  name_NotIn?: (string | null)[] | null;
  name_Lt?: string | null;
  name_Lte?: string | null;
  name_Gt?: string | null;
  name_Gte?: string | null;
  name_Contains?: string | null;
  name_NotContains?: string | null;
  name_StartsWith?: string | null;
  name_NotStartsWith?: string | null;
  name_EndsWith?: string | null;
  name_NotEndsWith?: string | null;
  name_IsBlank?: boolean | null;
  aND?: EcommerceCategoryWhereInput[] | null;
  oR?: EcommerceCategoryWhereInput[] | null;
  parent_id?: string | null;
  parent_id_Not?: string | null;
  parent_id_In?: (string | null)[] | null;
  parent_id_NotIn?: (string | null)[] | null;
  parent_id_Lt?: string | null;
  parent_id_Lte?: string | null;
  parent_id_Gt?: string | null;
  parent_id_Gte?: string | null;
  parent_id_Contains?: string | null;
  parent_id_NotContains?: string | null;
  parent_id_StartsWith?: string | null;
  parent_id_NotStartsWith?: string | null;
  parent_id_EndsWith?: string | null;
  parent_id_NotEndsWith?: string | null;
  parent_id_IsBlank?: boolean | null;
  parent_id__id?: number | null;
  parent_id__id_Not?: number | null;
  parent_id__id_In?: (number | null)[] | null;
  parent_id__id_NotIn?: (number | null)[] | null;
  parent_id__id_Lt?: number | null;
  parent_id__id_Lte?: number | null;
  parent_id__id_Gt?: number | null;
  parent_id__id_Gte?: number | null;
  parent_id__id_IsBlank?: boolean | null;
  parent_path?: string | null;
  parent_path_Not?: string | null;
  parent_path_In?: (string | null)[] | null;
  parent_path_NotIn?: (string | null)[] | null;
  parent_path_Lt?: string | null;
  parent_path_Lte?: string | null;
  parent_path_Gt?: string | null;
  parent_path_Gte?: string | null;
  parent_path_Contains?: string | null;
  parent_path_NotContains?: string | null;
  parent_path_StartsWith?: string | null;
  parent_path_NotStartsWith?: string | null;
  parent_path_EndsWith?: string | null;
  parent_path_NotEndsWith?: string | null;
  parent_path_IsBlank?: boolean | null;
  image?: string | null;
  image_Not?: string | null;
  image_In?: (string | null)[] | null;
  image_NotIn?: (string | null)[] | null;
  image_Lt?: string | null;
  image_Lte?: string | null;
  image_Gt?: string | null;
  image_Gte?: string | null;
  image_Contains?: string | null;
  image_NotContains?: string | null;
  image_StartsWith?: string | null;
  image_NotStartsWith?: string | null;
  image_EndsWith?: string | null;
  image_NotEndsWith?: string | null;
  image_IsBlank?: boolean | null;
  partner_id?: string | null;
  partner_id_Not?: string | null;
  partner_id_In?: (string | null)[] | null;
  partner_id_NotIn?: (string | null)[] | null;
  partner_id_Lt?: string | null;
  partner_id_Lte?: string | null;
  partner_id_Gt?: string | null;
  partner_id_Gte?: string | null;
  partner_id_Contains?: string | null;
  partner_id_NotContains?: string | null;
  partner_id_StartsWith?: string | null;
  partner_id_NotStartsWith?: string | null;
  partner_id_EndsWith?: string | null;
  partner_id_NotEndsWith?: string | null;
  partner_id_IsBlank?: boolean | null;
  partner_id__id?: number | null;
  partner_id__id_Not?: number | null;
  partner_id__id_In?: (number | null)[] | null;
  partner_id__id_NotIn?: (number | null)[] | null;
  partner_id__id_Lt?: number | null;
  partner_id__id_Lte?: number | null;
  partner_id__id_Gt?: number | null;
  partner_id__id_Gte?: number | null;
  partner_id__id_IsBlank?: boolean | null;
  complete_name?: string | null;
  complete_name_Not?: string | null;
  complete_name_In?: (string | null)[] | null;
  complete_name_NotIn?: (string | null)[] | null;
  complete_name_Lt?: string | null;
  complete_name_Lte?: string | null;
  complete_name_Gt?: string | null;
  complete_name_Gte?: string | null;
  complete_name_Contains?: string | null;
  complete_name_NotContains?: string | null;
  complete_name_StartsWith?: string | null;
  complete_name_NotStartsWith?: string | null;
  complete_name_EndsWith?: string | null;
  complete_name_NotEndsWith?: string | null;
  complete_name_IsBlank?: boolean | null;
  id?: string | null;
  id_Not?: string | null;
  id_In?: (string | null)[] | null;
  id_NotIn?: (string | null)[] | null;
  id_Lt?: string | null;
  id_Lte?: string | null;
  id_Gt?: string | null;
  id_Gte?: string | null;
  id_IsBlank?: boolean | null;
}

export interface EcommerceProductInput {
  id: string;
  ecommerce_caption?: string | null;
  ecommerce_description?: string | null;
  overview?: string | null;
  ecommerce_categ_ids?: number[] | null;
  default_ecommerce_categ_id?: number | null;
  show_on_website?: boolean | null;
  uom_description?: string | null;
  min_order_qty?: number | null;
  max_order_qty?: number | null;
  available_date?: any | null;
  availability?: ProductAvailability | null;
  free_shipping?: boolean | null;
  hide_price?: boolean | null;
  preorder_description?: string | null;
  domain_for_related_products?: string | null;
  product_warranty?: ProductWarranty | null;
  warranty_duration?: number | null;
  warranty_unit?: WarrantyUnit | null;
  warranty_description?: string | null;
  excluded_company_ids?: number[] | null;
  excluded_location_ids?: number[] | null;
  exclude_consignment?: boolean | null;
  tri_product_variant_image_ids?: ProductImageInputList | null;
}

export interface EcommerceProductWhereInput {
  default_code?: string | null;
  default_code_Not?: string | null;
  default_code_In?: (string | null)[] | null;
  default_code_NotIn?: (string | null)[] | null;
  default_code_Lt?: string | null;
  default_code_Lte?: string | null;
  default_code_Gt?: string | null;
  default_code_Gte?: string | null;
  default_code_Contains?: string | null;
  default_code_NotContains?: string | null;
  default_code_StartsWith?: string | null;
  default_code_NotStartsWith?: string | null;
  default_code_EndsWith?: string | null;
  default_code_NotEndsWith?: string | null;
  default_code_IsBlank?: boolean | null;
  aND?: EcommerceProductWhereInput[] | null;
  oR?: EcommerceProductWhereInput[] | null;
  barcode?: string | null;
  barcode_Not?: string | null;
  barcode_In?: (string | null)[] | null;
  barcode_NotIn?: (string | null)[] | null;
  barcode_Lt?: string | null;
  barcode_Lte?: string | null;
  barcode_Gt?: string | null;
  barcode_Gte?: string | null;
  barcode_Contains?: string | null;
  barcode_NotContains?: string | null;
  barcode_StartsWith?: string | null;
  barcode_NotStartsWith?: string | null;
  barcode_EndsWith?: string | null;
  barcode_NotEndsWith?: string | null;
  barcode_IsBlank?: boolean | null;
  name?: string | null;
  name_Not?: string | null;
  name_In?: (string | null)[] | null;
  name_NotIn?: (string | null)[] | null;
  name_Lt?: string | null;
  name_Lte?: string | null;
  name_Gt?: string | null;
  name_Gte?: string | null;
  name_Contains?: string | null;
  name_NotContains?: string | null;
  name_StartsWith?: string | null;
  name_NotStartsWith?: string | null;
  name_EndsWith?: string | null;
  name_NotEndsWith?: string | null;
  name_IsBlank?: boolean | null;
  list_price?: number | null;
  list_price_Not?: number | null;
  list_price_In?: (number | null)[] | null;
  list_price_NotIn?: (number | null)[] | null;
  list_price_Lt?: number | null;
  list_price_Lte?: number | null;
  list_price_Gt?: number | null;
  list_price_Gte?: number | null;
  list_price_IsBlank?: boolean | null;
  ecommerce_caption?: string | null;
  ecommerce_caption_Not?: string | null;
  ecommerce_caption_In?: (string | null)[] | null;
  ecommerce_caption_NotIn?: (string | null)[] | null;
  ecommerce_caption_Lt?: string | null;
  ecommerce_caption_Lte?: string | null;
  ecommerce_caption_Gt?: string | null;
  ecommerce_caption_Gte?: string | null;
  ecommerce_caption_Contains?: string | null;
  ecommerce_caption_NotContains?: string | null;
  ecommerce_caption_StartsWith?: string | null;
  ecommerce_caption_NotStartsWith?: string | null;
  ecommerce_caption_EndsWith?: string | null;
  ecommerce_caption_NotEndsWith?: string | null;
  ecommerce_caption_IsBlank?: boolean | null;
  ecommerce_description?: string | null;
  ecommerce_description_Not?: string | null;
  ecommerce_description_In?: (string | null)[] | null;
  ecommerce_description_NotIn?: (string | null)[] | null;
  ecommerce_description_Lt?: string | null;
  ecommerce_description_Lte?: string | null;
  ecommerce_description_Gt?: string | null;
  ecommerce_description_Gte?: string | null;
  ecommerce_description_Contains?: string | null;
  ecommerce_description_NotContains?: string | null;
  ecommerce_description_StartsWith?: string | null;
  ecommerce_description_NotStartsWith?: string | null;
  ecommerce_description_EndsWith?: string | null;
  ecommerce_description_NotEndsWith?: string | null;
  ecommerce_description_IsBlank?: boolean | null;
  overview?: string | null;
  overview_Not?: string | null;
  overview_In?: (string | null)[] | null;
  overview_NotIn?: (string | null)[] | null;
  overview_Lt?: string | null;
  overview_Lte?: string | null;
  overview_Gt?: string | null;
  overview_Gte?: string | null;
  overview_Contains?: string | null;
  overview_NotContains?: string | null;
  overview_StartsWith?: string | null;
  overview_NotStartsWith?: string | null;
  overview_EndsWith?: string | null;
  overview_NotEndsWith?: string | null;
  overview_IsBlank?: boolean | null;
  show_on_website?: boolean | null;
  zone_id?: string | null;
  zone_id_Not?: string | null;
  zone_id_In?: (string | null)[] | null;
  zone_id_NotIn?: (string | null)[] | null;
  zone_id_Lt?: string | null;
  zone_id_Lte?: string | null;
  zone_id_Gt?: string | null;
  zone_id_Gte?: string | null;
  zone_id_Contains?: string | null;
  zone_id_NotContains?: string | null;
  zone_id_StartsWith?: string | null;
  zone_id_NotStartsWith?: string | null;
  zone_id_EndsWith?: string | null;
  zone_id_NotEndsWith?: string | null;
  zone_id_IsBlank?: boolean | null;
  zone_id__id?: number | null;
  zone_id__id_Not?: number | null;
  zone_id__id_In?: (number | null)[] | null;
  zone_id__id_NotIn?: (number | null)[] | null;
  zone_id__id_Lt?: number | null;
  zone_id__id_Lte?: number | null;
  zone_id__id_Gt?: number | null;
  zone_id__id_Gte?: number | null;
  zone_id__id_IsBlank?: boolean | null;
  brand_id?: string | null;
  brand_id_Not?: string | null;
  brand_id_In?: (string | null)[] | null;
  brand_id_NotIn?: (string | null)[] | null;
  brand_id_Lt?: string | null;
  brand_id_Lte?: string | null;
  brand_id_Gt?: string | null;
  brand_id_Gte?: string | null;
  brand_id_Contains?: string | null;
  brand_id_NotContains?: string | null;
  brand_id_StartsWith?: string | null;
  brand_id_NotStartsWith?: string | null;
  brand_id_EndsWith?: string | null;
  brand_id_NotEndsWith?: string | null;
  brand_id_IsBlank?: boolean | null;
  brand_id__id?: number | null;
  brand_id__id_Not?: number | null;
  brand_id__id_In?: (number | null)[] | null;
  brand_id__id_NotIn?: (number | null)[] | null;
  brand_id__id_Lt?: number | null;
  brand_id__id_Lte?: number | null;
  brand_id__id_Gt?: number | null;
  brand_id__id_Gte?: number | null;
  brand_id__id_IsBlank?: boolean | null;
  categ_id?: string | null;
  categ_id_Not?: string | null;
  categ_id_In?: (string | null)[] | null;
  categ_id_NotIn?: (string | null)[] | null;
  categ_id_Lt?: string | null;
  categ_id_Lte?: string | null;
  categ_id_Gt?: string | null;
  categ_id_Gte?: string | null;
  categ_id_Contains?: string | null;
  categ_id_NotContains?: string | null;
  categ_id_StartsWith?: string | null;
  categ_id_NotStartsWith?: string | null;
  categ_id_EndsWith?: string | null;
  categ_id_NotEndsWith?: string | null;
  categ_id_IsBlank?: boolean | null;
  categ_id__id?: number | null;
  categ_id__id_Not?: number | null;
  categ_id__id_In?: (number | null)[] | null;
  categ_id__id_NotIn?: (number | null)[] | null;
  categ_id__id_Lt?: number | null;
  categ_id__id_Lte?: number | null;
  categ_id__id_Gt?: number | null;
  categ_id__id_Gte?: number | null;
  categ_id__id_IsBlank?: boolean | null;
  uom_id?: string | null;
  uom_id_Not?: string | null;
  uom_id_In?: (string | null)[] | null;
  uom_id_NotIn?: (string | null)[] | null;
  uom_id_Lt?: string | null;
  uom_id_Lte?: string | null;
  uom_id_Gt?: string | null;
  uom_id_Gte?: string | null;
  uom_id_Contains?: string | null;
  uom_id_NotContains?: string | null;
  uom_id_StartsWith?: string | null;
  uom_id_NotStartsWith?: string | null;
  uom_id_EndsWith?: string | null;
  uom_id_NotEndsWith?: string | null;
  uom_id_IsBlank?: boolean | null;
  uom_id__id?: number | null;
  uom_id__id_Not?: number | null;
  uom_id__id_In?: (number | null)[] | null;
  uom_id__id_NotIn?: (number | null)[] | null;
  uom_id__id_Lt?: number | null;
  uom_id__id_Lte?: number | null;
  uom_id__id_Gt?: number | null;
  uom_id__id_Gte?: number | null;
  uom_id__id_IsBlank?: boolean | null;
  default_ecommerce_categ_id?: string | null;
  default_ecommerce_categ_id_Not?: string | null;
  default_ecommerce_categ_id_In?: (string | null)[] | null;
  default_ecommerce_categ_id_NotIn?: (string | null)[] | null;
  default_ecommerce_categ_id_Lt?: string | null;
  default_ecommerce_categ_id_Lte?: string | null;
  default_ecommerce_categ_id_Gt?: string | null;
  default_ecommerce_categ_id_Gte?: string | null;
  default_ecommerce_categ_id_Contains?: string | null;
  default_ecommerce_categ_id_NotContains?: string | null;
  default_ecommerce_categ_id_StartsWith?: string | null;
  default_ecommerce_categ_id_NotStartsWith?: string | null;
  default_ecommerce_categ_id_EndsWith?: string | null;
  default_ecommerce_categ_id_NotEndsWith?: string | null;
  default_ecommerce_categ_id_IsBlank?: boolean | null;
  default_ecommerce_categ_id__id?: number | null;
  default_ecommerce_categ_id__id_Not?: number | null;
  default_ecommerce_categ_id__id_In?: (number | null)[] | null;
  default_ecommerce_categ_id__id_NotIn?: (number | null)[] | null;
  default_ecommerce_categ_id__id_Lt?: number | null;
  default_ecommerce_categ_id__id_Lte?: number | null;
  default_ecommerce_categ_id__id_Gt?: number | null;
  default_ecommerce_categ_id__id_Gte?: number | null;
  default_ecommerce_categ_id__id_IsBlank?: boolean | null;
  image_variant_id?: string | null;
  image_variant_id_Not?: string | null;
  image_variant_id_In?: (string | null)[] | null;
  image_variant_id_NotIn?: (string | null)[] | null;
  image_variant_id_Lt?: string | null;
  image_variant_id_Lte?: string | null;
  image_variant_id_Gt?: string | null;
  image_variant_id_Gte?: string | null;
  image_variant_id_Contains?: string | null;
  image_variant_id_NotContains?: string | null;
  image_variant_id_StartsWith?: string | null;
  image_variant_id_NotStartsWith?: string | null;
  image_variant_id_EndsWith?: string | null;
  image_variant_id_NotEndsWith?: string | null;
  image_variant_id_IsBlank?: boolean | null;
  image_variant_id__id?: number | null;
  image_variant_id__id_Not?: number | null;
  image_variant_id__id_In?: (number | null)[] | null;
  image_variant_id__id_NotIn?: (number | null)[] | null;
  image_variant_id__id_Lt?: number | null;
  image_variant_id__id_Lte?: number | null;
  image_variant_id__id_Gt?: number | null;
  image_variant_id__id_Gte?: number | null;
  image_variant_id__id_IsBlank?: boolean | null;
  uom_description?: string | null;
  uom_description_Not?: string | null;
  uom_description_In?: (string | null)[] | null;
  uom_description_NotIn?: (string | null)[] | null;
  uom_description_Lt?: string | null;
  uom_description_Lte?: string | null;
  uom_description_Gt?: string | null;
  uom_description_Gte?: string | null;
  uom_description_Contains?: string | null;
  uom_description_NotContains?: string | null;
  uom_description_StartsWith?: string | null;
  uom_description_NotStartsWith?: string | null;
  uom_description_EndsWith?: string | null;
  uom_description_NotEndsWith?: string | null;
  uom_description_IsBlank?: boolean | null;
  min_order_qty?: number | null;
  min_order_qty_Not?: number | null;
  min_order_qty_In?: (number | null)[] | null;
  min_order_qty_NotIn?: (number | null)[] | null;
  min_order_qty_Lt?: number | null;
  min_order_qty_Lte?: number | null;
  min_order_qty_Gt?: number | null;
  min_order_qty_Gte?: number | null;
  min_order_qty_IsBlank?: boolean | null;
  max_order_qty?: number | null;
  max_order_qty_Not?: number | null;
  max_order_qty_In?: (number | null)[] | null;
  max_order_qty_NotIn?: (number | null)[] | null;
  max_order_qty_Lt?: number | null;
  max_order_qty_Lte?: number | null;
  max_order_qty_Gt?: number | null;
  max_order_qty_Gte?: number | null;
  max_order_qty_IsBlank?: boolean | null;
  available_date?: any | null;
  available_date_Not?: any | null;
  available_date_Lt?: any | null;
  available_date_Lte?: any | null;
  available_date_Gt?: any | null;
  available_date_Gte?: any | null;
  available_date_IsBlank?: any | null;
  availability?: ProductAvailability | null;
  availability_Not?: ProductAvailability | null;
  availability_In?: ProductAvailability[] | null;
  availability_NotIn?: ProductAvailability[] | null;
  availability_IsBlank?: boolean | null;
  free_shipping?: boolean | null;
  hide_price?: boolean | null;
  preorder_description?: string | null;
  preorder_description_Not?: string | null;
  preorder_description_In?: (string | null)[] | null;
  preorder_description_NotIn?: (string | null)[] | null;
  preorder_description_Lt?: string | null;
  preorder_description_Lte?: string | null;
  preorder_description_Gt?: string | null;
  preorder_description_Gte?: string | null;
  preorder_description_Contains?: string | null;
  preorder_description_NotContains?: string | null;
  preorder_description_StartsWith?: string | null;
  preorder_description_NotStartsWith?: string | null;
  preorder_description_EndsWith?: string | null;
  preorder_description_NotEndsWith?: string | null;
  preorder_description_IsBlank?: boolean | null;
  domain_for_related_products?: string | null;
  domain_for_related_products_Not?: string | null;
  domain_for_related_products_In?: (string | null)[] | null;
  domain_for_related_products_NotIn?: (string | null)[] | null;
  domain_for_related_products_Lt?: string | null;
  domain_for_related_products_Lte?: string | null;
  domain_for_related_products_Gt?: string | null;
  domain_for_related_products_Gte?: string | null;
  domain_for_related_products_Contains?: string | null;
  domain_for_related_products_NotContains?: string | null;
  domain_for_related_products_StartsWith?: string | null;
  domain_for_related_products_NotStartsWith?: string | null;
  domain_for_related_products_EndsWith?: string | null;
  domain_for_related_products_NotEndsWith?: string | null;
  domain_for_related_products_IsBlank?: boolean | null;
  product_warranty?: ProductWarranty | null;
  product_warranty_Not?: ProductWarranty | null;
  product_warranty_In?: ProductWarranty[] | null;
  product_warranty_NotIn?: ProductWarranty[] | null;
  product_warranty_IsBlank?: boolean | null;
  warranty_duration?: number | null;
  warranty_duration_Not?: number | null;
  warranty_duration_In?: (number | null)[] | null;
  warranty_duration_NotIn?: (number | null)[] | null;
  warranty_duration_Lt?: number | null;
  warranty_duration_Lte?: number | null;
  warranty_duration_Gt?: number | null;
  warranty_duration_Gte?: number | null;
  warranty_duration_IsBlank?: boolean | null;
  warranty_unit?: WarrantyUnit | null;
  warranty_unit_Not?: WarrantyUnit | null;
  warranty_unit_In?: WarrantyUnit[] | null;
  warranty_unit_NotIn?: WarrantyUnit[] | null;
  warranty_unit_IsBlank?: boolean | null;
  warranty_description?: string | null;
  warranty_description_Not?: string | null;
  warranty_description_In?: (string | null)[] | null;
  warranty_description_NotIn?: (string | null)[] | null;
  warranty_description_Lt?: string | null;
  warranty_description_Lte?: string | null;
  warranty_description_Gt?: string | null;
  warranty_description_Gte?: string | null;
  warranty_description_Contains?: string | null;
  warranty_description_NotContains?: string | null;
  warranty_description_StartsWith?: string | null;
  warranty_description_NotStartsWith?: string | null;
  warranty_description_EndsWith?: string | null;
  warranty_description_NotEndsWith?: string | null;
  warranty_description_IsBlank?: boolean | null;
  exclude_consignment?: boolean | null;
  default_variant_image_ratio?: number | null;
  default_variant_image_ratio_Not?: number | null;
  default_variant_image_ratio_In?: (number | null)[] | null;
  default_variant_image_ratio_NotIn?: (number | null)[] | null;
  default_variant_image_ratio_Lt?: number | null;
  default_variant_image_ratio_Lte?: number | null;
  default_variant_image_ratio_Gt?: number | null;
  default_variant_image_ratio_Gte?: number | null;
  default_variant_image_ratio_IsBlank?: boolean | null;
  published_on_website?: boolean | null;
  id?: string | null;
  id_Not?: string | null;
  id_In?: (string | null)[] | null;
  id_NotIn?: (string | null)[] | null;
  id_Lt?: string | null;
  id_Lte?: string | null;
  id_Gt?: string | null;
  id_Gte?: string | null;
  id_IsBlank?: boolean | null;
}

export interface GpReportWhereInput {
  id?: string | null;
  id_Not?: string | null;
  id_In?: (string | null)[] | null;
  id_NotIn?: (string | null)[] | null;
  id_Lt?: string | null;
  id_Lte?: string | null;
  id_Gt?: string | null;
  id_Gte?: string | null;
  id_IsBlank?: boolean | null;
  aND?: GpReportWhereInput[] | null;
  oR?: GpReportWhereInput[] | null;
  date?: any | null;
  date_Not?: any | null;
  date_Lt?: any | null;
  date_Lte?: any | null;
  date_Gt?: any | null;
  date_Gte?: any | null;
  date_IsBlank?: any | null;
  ref_id?: number | null;
  ref_id_Not?: number | null;
  ref_id_In?: (number | null)[] | null;
  ref_id_NotIn?: (number | null)[] | null;
  ref_id_Lt?: number | null;
  ref_id_Lte?: number | null;
  ref_id_Gt?: number | null;
  ref_id_Gte?: number | null;
  ref_id_IsBlank?: boolean | null;
  ref?: string | null;
  ref_Not?: string | null;
  ref_In?: (string | null)[] | null;
  ref_NotIn?: (string | null)[] | null;
  ref_Lt?: string | null;
  ref_Lte?: string | null;
  ref_Gt?: string | null;
  ref_Gte?: string | null;
  ref_Contains?: string | null;
  ref_NotContains?: string | null;
  ref_StartsWith?: string | null;
  ref_NotStartsWith?: string | null;
  ref_EndsWith?: string | null;
  ref_NotEndsWith?: string | null;
  ref_IsBlank?: boolean | null;
  customer_name?: string | null;
  customer_name_Not?: string | null;
  customer_name_In?: (string | null)[] | null;
  customer_name_NotIn?: (string | null)[] | null;
  customer_name_Lt?: string | null;
  customer_name_Lte?: string | null;
  customer_name_Gt?: string | null;
  customer_name_Gte?: string | null;
  customer_name_Contains?: string | null;
  customer_name_NotContains?: string | null;
  customer_name_StartsWith?: string | null;
  customer_name_NotStartsWith?: string | null;
  customer_name_EndsWith?: string | null;
  customer_name_NotEndsWith?: string | null;
  customer_name_IsBlank?: boolean | null;
  customer_phone?: string | null;
  customer_phone_Not?: string | null;
  customer_phone_In?: (string | null)[] | null;
  customer_phone_NotIn?: (string | null)[] | null;
  customer_phone_Lt?: string | null;
  customer_phone_Lte?: string | null;
  customer_phone_Gt?: string | null;
  customer_phone_Gte?: string | null;
  customer_phone_Contains?: string | null;
  customer_phone_NotContains?: string | null;
  customer_phone_StartsWith?: string | null;
  customer_phone_NotStartsWith?: string | null;
  customer_phone_EndsWith?: string | null;
  customer_phone_NotEndsWith?: string | null;
  customer_phone_IsBlank?: boolean | null;
  customer_city?: string | null;
  customer_city_Not?: string | null;
  customer_city_In?: (string | null)[] | null;
  customer_city_NotIn?: (string | null)[] | null;
  customer_city_Lt?: string | null;
  customer_city_Lte?: string | null;
  customer_city_Gt?: string | null;
  customer_city_Gte?: string | null;
  customer_city_Contains?: string | null;
  customer_city_NotContains?: string | null;
  customer_city_StartsWith?: string | null;
  customer_city_NotStartsWith?: string | null;
  customer_city_EndsWith?: string | null;
  customer_city_NotEndsWith?: string | null;
  customer_city_IsBlank?: boolean | null;
  account_id?: number | null;
  account_id_Not?: number | null;
  account_id_In?: (number | null)[] | null;
  account_id_NotIn?: (number | null)[] | null;
  account_id_Lt?: number | null;
  account_id_Lte?: number | null;
  account_id_Gt?: number | null;
  account_id_Gte?: number | null;
  account_id_IsBlank?: boolean | null;
  account_name?: string | null;
  account_name_Not?: string | null;
  account_name_In?: (string | null)[] | null;
  account_name_NotIn?: (string | null)[] | null;
  account_name_Lt?: string | null;
  account_name_Lte?: string | null;
  account_name_Gt?: string | null;
  account_name_Gte?: string | null;
  account_name_Contains?: string | null;
  account_name_NotContains?: string | null;
  account_name_StartsWith?: string | null;
  account_name_NotStartsWith?: string | null;
  account_name_EndsWith?: string | null;
  account_name_NotEndsWith?: string | null;
  account_name_IsBlank?: boolean | null;
  product_id?: number | null;
  product_id_Not?: number | null;
  product_id_In?: (number | null)[] | null;
  product_id_NotIn?: (number | null)[] | null;
  product_id_Lt?: number | null;
  product_id_Lte?: number | null;
  product_id_Gt?: number | null;
  product_id_Gte?: number | null;
  product_id_IsBlank?: boolean | null;
  default_code?: string | null;
  default_code_Not?: string | null;
  default_code_In?: (string | null)[] | null;
  default_code_NotIn?: (string | null)[] | null;
  default_code_Lt?: string | null;
  default_code_Lte?: string | null;
  default_code_Gt?: string | null;
  default_code_Gte?: string | null;
  default_code_Contains?: string | null;
  default_code_NotContains?: string | null;
  default_code_StartsWith?: string | null;
  default_code_NotStartsWith?: string | null;
  default_code_EndsWith?: string | null;
  default_code_NotEndsWith?: string | null;
  default_code_IsBlank?: boolean | null;
  product_name?: string | null;
  product_name_Not?: string | null;
  product_name_In?: (string | null)[] | null;
  product_name_NotIn?: (string | null)[] | null;
  product_name_Lt?: string | null;
  product_name_Lte?: string | null;
  product_name_Gt?: string | null;
  product_name_Gte?: string | null;
  product_name_Contains?: string | null;
  product_name_NotContains?: string | null;
  product_name_StartsWith?: string | null;
  product_name_NotStartsWith?: string | null;
  product_name_EndsWith?: string | null;
  product_name_NotEndsWith?: string | null;
  product_name_IsBlank?: boolean | null;
  salezone?: string | null;
  salezone_Not?: string | null;
  salezone_In?: (string | null)[] | null;
  salezone_NotIn?: (string | null)[] | null;
  salezone_Lt?: string | null;
  salezone_Lte?: string | null;
  salezone_Gt?: string | null;
  salezone_Gte?: string | null;
  salezone_Contains?: string | null;
  salezone_NotContains?: string | null;
  salezone_StartsWith?: string | null;
  salezone_NotStartsWith?: string | null;
  salezone_EndsWith?: string | null;
  salezone_NotEndsWith?: string | null;
  salezone_IsBlank?: boolean | null;
  brand?: string | null;
  brand_Not?: string | null;
  brand_In?: (string | null)[] | null;
  brand_NotIn?: (string | null)[] | null;
  brand_Lt?: string | null;
  brand_Lte?: string | null;
  brand_Gt?: string | null;
  brand_Gte?: string | null;
  brand_Contains?: string | null;
  brand_NotContains?: string | null;
  brand_StartsWith?: string | null;
  brand_NotStartsWith?: string | null;
  brand_EndsWith?: string | null;
  brand_NotEndsWith?: string | null;
  brand_IsBlank?: boolean | null;
  vendor_id?: number | null;
  vendor_id_Not?: number | null;
  vendor_id_In?: (number | null)[] | null;
  vendor_id_NotIn?: (number | null)[] | null;
  vendor_id_Lt?: number | null;
  vendor_id_Lte?: number | null;
  vendor_id_Gt?: number | null;
  vendor_id_Gte?: number | null;
  vendor_id_IsBlank?: boolean | null;
  vendor_name?: string | null;
  vendor_name_Not?: string | null;
  vendor_name_In?: (string | null)[] | null;
  vendor_name_NotIn?: (string | null)[] | null;
  vendor_name_Lt?: string | null;
  vendor_name_Lte?: string | null;
  vendor_name_Gt?: string | null;
  vendor_name_Gte?: string | null;
  vendor_name_Contains?: string | null;
  vendor_name_NotContains?: string | null;
  vendor_name_StartsWith?: string | null;
  vendor_name_NotStartsWith?: string | null;
  vendor_name_EndsWith?: string | null;
  vendor_name_NotEndsWith?: string | null;
  vendor_name_IsBlank?: boolean | null;
  vendor_category?: string | null;
  vendor_category_Not?: string | null;
  vendor_category_In?: (string | null)[] | null;
  vendor_category_NotIn?: (string | null)[] | null;
  vendor_category_Lt?: string | null;
  vendor_category_Lte?: string | null;
  vendor_category_Gt?: string | null;
  vendor_category_Gte?: string | null;
  vendor_category_Contains?: string | null;
  vendor_category_NotContains?: string | null;
  vendor_category_StartsWith?: string | null;
  vendor_category_NotStartsWith?: string | null;
  vendor_category_EndsWith?: string | null;
  vendor_category_NotEndsWith?: string | null;
  vendor_category_IsBlank?: boolean | null;
  uom?: string | null;
  uom_Not?: string | null;
  uom_In?: (string | null)[] | null;
  uom_NotIn?: (string | null)[] | null;
  uom_Lt?: string | null;
  uom_Lte?: string | null;
  uom_Gt?: string | null;
  uom_Gte?: string | null;
  uom_Contains?: string | null;
  uom_NotContains?: string | null;
  uom_StartsWith?: string | null;
  uom_NotStartsWith?: string | null;
  uom_EndsWith?: string | null;
  uom_NotEndsWith?: string | null;
  uom_IsBlank?: boolean | null;
  quantity?: number | null;
  quantity_Not?: number | null;
  quantity_In?: (number | null)[] | null;
  quantity_NotIn?: (number | null)[] | null;
  quantity_Lt?: number | null;
  quantity_Lte?: number | null;
  quantity_Gt?: number | null;
  quantity_Gte?: number | null;
  quantity_IsBlank?: boolean | null;
  price_total?: number | null;
  price_total_Not?: number | null;
  price_total_In?: (number | null)[] | null;
  price_total_NotIn?: (number | null)[] | null;
  price_total_Lt?: number | null;
  price_total_Lte?: number | null;
  price_total_Gt?: number | null;
  price_total_Gte?: number | null;
  price_total_IsBlank?: boolean | null;
  product_uom_qty?: number | null;
  product_uom_qty_Not?: number | null;
  product_uom_qty_In?: (number | null)[] | null;
  product_uom_qty_NotIn?: (number | null)[] | null;
  product_uom_qty_Lt?: number | null;
  product_uom_qty_Lte?: number | null;
  product_uom_qty_Gt?: number | null;
  product_uom_qty_Gte?: number | null;
  product_uom_qty_IsBlank?: boolean | null;
  value?: number | null;
  value_Not?: number | null;
  value_In?: (number | null)[] | null;
  value_NotIn?: (number | null)[] | null;
  value_Lt?: number | null;
  value_Lte?: number | null;
  value_Gt?: number | null;
  value_Gte?: number | null;
  value_IsBlank?: boolean | null;
  gp?: number | null;
  gp_Not?: number | null;
  gp_In?: (number | null)[] | null;
  gp_NotIn?: (number | null)[] | null;
  gp_Lt?: number | null;
  gp_Lte?: number | null;
  gp_Gt?: number | null;
  gp_Gte?: number | null;
  gp_IsBlank?: boolean | null;
  is_oem?: boolean | null;
  salezone_35?: string | null;
  salezone_35_Not?: string | null;
  salezone_35_In?: (string | null)[] | null;
  salezone_35_NotIn?: (string | null)[] | null;
  salezone_35_Lt?: string | null;
  salezone_35_Lte?: string | null;
  salezone_35_Gt?: string | null;
  salezone_35_Gte?: string | null;
  salezone_35_Contains?: string | null;
  salezone_35_NotContains?: string | null;
  salezone_35_StartsWith?: string | null;
  salezone_35_NotStartsWith?: string | null;
  salezone_35_EndsWith?: string | null;
  salezone_35_NotEndsWith?: string | null;
  salezone_35_IsBlank?: boolean | null;
}

export interface LandedCostCheckListItemWhere {
  id?: string | null;
  id_Not?: string | null;
  id_In?: (string | null)[] | null;
  id_NotIn?: (string | null)[] | null;
  id_Lt?: string | null;
  id_Lte?: string | null;
  id_Gt?: string | null;
  id_Gte?: string | null;
  id_IsBlank?: boolean | null;
  aND?: LandedCostCheckListItemWhere[] | null;
  oR?: LandedCostCheckListItemWhere[] | null;
  order_id?: number | null;
  order_id_Not?: number | null;
  order_id_In?: (number | null)[] | null;
  order_id_NotIn?: (number | null)[] | null;
  order_id_Lt?: number | null;
  order_id_Lte?: number | null;
  order_id_Gt?: number | null;
  order_id_Gte?: number | null;
  order_id_IsBlank?: boolean | null;
  po?: string | null;
  po_Not?: string | null;
  po_In?: (string | null)[] | null;
  po_NotIn?: (string | null)[] | null;
  po_Lt?: string | null;
  po_Lte?: string | null;
  po_Gt?: string | null;
  po_Gte?: string | null;
  po_Contains?: string | null;
  po_NotContains?: string | null;
  po_StartsWith?: string | null;
  po_NotStartsWith?: string | null;
  po_EndsWith?: string | null;
  po_NotEndsWith?: string | null;
  po_IsBlank?: boolean | null;
  partner_ref?: string | null;
  partner_ref_Not?: string | null;
  partner_ref_In?: (string | null)[] | null;
  partner_ref_NotIn?: (string | null)[] | null;
  partner_ref_Lt?: string | null;
  partner_ref_Lte?: string | null;
  partner_ref_Gt?: string | null;
  partner_ref_Gte?: string | null;
  partner_ref_Contains?: string | null;
  partner_ref_NotContains?: string | null;
  partner_ref_StartsWith?: string | null;
  partner_ref_NotStartsWith?: string | null;
  partner_ref_EndsWith?: string | null;
  partner_ref_NotEndsWith?: string | null;
  partner_ref_IsBlank?: boolean | null;
  date_order?: any | null;
  date_order_Not?: any | null;
  date_order_Lt?: any | null;
  date_order_Lte?: any | null;
  date_order_Gt?: any | null;
  date_order_Gte?: any | null;
  date_order_IsBlank?: any | null;
  product_id?: number | null;
  product_id_Not?: number | null;
  product_id_In?: (number | null)[] | null;
  product_id_NotIn?: (number | null)[] | null;
  product_id_Lt?: number | null;
  product_id_Lte?: number | null;
  product_id_Gt?: number | null;
  product_id_Gte?: number | null;
  product_id_IsBlank?: boolean | null;
  default_code?: string | null;
  default_code_Not?: string | null;
  default_code_In?: (string | null)[] | null;
  default_code_NotIn?: (string | null)[] | null;
  default_code_Lt?: string | null;
  default_code_Lte?: string | null;
  default_code_Gt?: string | null;
  default_code_Gte?: string | null;
  default_code_Contains?: string | null;
  default_code_NotContains?: string | null;
  default_code_StartsWith?: string | null;
  default_code_NotStartsWith?: string | null;
  default_code_EndsWith?: string | null;
  default_code_NotEndsWith?: string | null;
  default_code_IsBlank?: boolean | null;
  name?: string | null;
  name_Not?: string | null;
  name_In?: (string | null)[] | null;
  name_NotIn?: (string | null)[] | null;
  name_Lt?: string | null;
  name_Lte?: string | null;
  name_Gt?: string | null;
  name_Gte?: string | null;
  name_Contains?: string | null;
  name_NotContains?: string | null;
  name_StartsWith?: string | null;
  name_NotStartsWith?: string | null;
  name_EndsWith?: string | null;
  name_NotEndsWith?: string | null;
  name_IsBlank?: boolean | null;
  uom?: string | null;
  uom_Not?: string | null;
  uom_In?: (string | null)[] | null;
  uom_NotIn?: (string | null)[] | null;
  uom_Lt?: string | null;
  uom_Lte?: string | null;
  uom_Gt?: string | null;
  uom_Gte?: string | null;
  uom_Contains?: string | null;
  uom_NotContains?: string | null;
  uom_StartsWith?: string | null;
  uom_NotStartsWith?: string | null;
  uom_EndsWith?: string | null;
  uom_NotEndsWith?: string | null;
  uom_IsBlank?: boolean | null;
  ordered_qty?: number | null;
  ordered_qty_Not?: number | null;
  ordered_qty_In?: (number | null)[] | null;
  ordered_qty_NotIn?: (number | null)[] | null;
  ordered_qty_Lt?: number | null;
  ordered_qty_Lte?: number | null;
  ordered_qty_Gt?: number | null;
  ordered_qty_Gte?: number | null;
  ordered_qty_IsBlank?: boolean | null;
  order_price?: number | null;
  order_price_Not?: number | null;
  order_price_In?: (number | null)[] | null;
  order_price_NotIn?: (number | null)[] | null;
  order_price_Lt?: number | null;
  order_price_Lte?: number | null;
  order_price_Gt?: number | null;
  order_price_Gte?: number | null;
  order_price_IsBlank?: boolean | null;
  ordered_amount?: number | null;
  ordered_amount_Not?: number | null;
  ordered_amount_In?: (number | null)[] | null;
  ordered_amount_NotIn?: (number | null)[] | null;
  ordered_amount_Lt?: number | null;
  ordered_amount_Lte?: number | null;
  ordered_amount_Gt?: number | null;
  ordered_amount_Gte?: number | null;
  ordered_amount_IsBlank?: boolean | null;
  billed_qty?: number | null;
  billed_qty_Not?: number | null;
  billed_qty_In?: (number | null)[] | null;
  billed_qty_NotIn?: (number | null)[] | null;
  billed_qty_Lt?: number | null;
  billed_qty_Lte?: number | null;
  billed_qty_Gt?: number | null;
  billed_qty_Gte?: number | null;
  billed_qty_IsBlank?: boolean | null;
  billed_amount?: number | null;
  billed_amount_Not?: number | null;
  billed_amount_In?: (number | null)[] | null;
  billed_amount_NotIn?: (number | null)[] | null;
  billed_amount_Lt?: number | null;
  billed_amount_Lte?: number | null;
  billed_amount_Gt?: number | null;
  billed_amount_Gte?: number | null;
  billed_amount_IsBlank?: boolean | null;
  bill_price?: number | null;
  bill_price_Not?: number | null;
  bill_price_In?: (number | null)[] | null;
  bill_price_NotIn?: (number | null)[] | null;
  bill_price_Lt?: number | null;
  bill_price_Lte?: number | null;
  bill_price_Gt?: number | null;
  bill_price_Gte?: number | null;
  bill_price_IsBlank?: boolean | null;
  received_qty?: number | null;
  received_qty_Not?: number | null;
  received_qty_In?: (number | null)[] | null;
  received_qty_NotIn?: (number | null)[] | null;
  received_qty_Lt?: number | null;
  received_qty_Lte?: number | null;
  received_qty_Gt?: number | null;
  received_qty_Gte?: number | null;
  received_qty_IsBlank?: boolean | null;
  received_value?: number | null;
  received_value_Not?: number | null;
  received_value_In?: (number | null)[] | null;
  received_value_NotIn?: (number | null)[] | null;
  received_value_Lt?: number | null;
  received_value_Lte?: number | null;
  received_value_Gt?: number | null;
  received_value_Gte?: number | null;
  received_value_IsBlank?: boolean | null;
  landed_cost?: number | null;
  landed_cost_Not?: number | null;
  landed_cost_In?: (number | null)[] | null;
  landed_cost_NotIn?: (number | null)[] | null;
  landed_cost_Lt?: number | null;
  landed_cost_Lte?: number | null;
  landed_cost_Gt?: number | null;
  landed_cost_Gte?: number | null;
  landed_cost_IsBlank?: boolean | null;
  total_value?: number | null;
  total_value_Not?: number | null;
  total_value_In?: (number | null)[] | null;
  total_value_NotIn?: (number | null)[] | null;
  total_value_Lt?: number | null;
  total_value_Lte?: number | null;
  total_value_Gt?: number | null;
  total_value_Gte?: number | null;
  total_value_IsBlank?: boolean | null;
  unit_cost?: number | null;
  unit_cost_Not?: number | null;
  unit_cost_In?: (number | null)[] | null;
  unit_cost_NotIn?: (number | null)[] | null;
  unit_cost_Lt?: number | null;
  unit_cost_Lte?: number | null;
  unit_cost_Gt?: number | null;
  unit_cost_Gte?: number | null;
  unit_cost_IsBlank?: boolean | null;
  qty_matched?: boolean | null;
  price_matched?: boolean | null;
}

export interface LoyaltyProgramInput {
  id?: string | null;
  product_id: number;
  reward_product_id?: number | null;
  reward_type: RewardType;
  discount: number;
  discount_max_amount: number;
  discount_mode?: DiscountMode | null;
  minimum_qty: number;
  active: boolean;
}

export interface PaginationInput {
  page?: number | null;
  pageSize?: number | null;
  skip?: number | null;
  take?: number | null;
}

export interface PartnerInput {
  id?: string | null;
  title?: number | null;
  name?: string | null;
  phone?: string | null;
  street?: string | null;
  email?: string | null;
  ref?: string | null;
  city?: string | null;
  customer_rank?: number | null;
  supplier_rank?: number | null;
}

export interface PartnerWhereInput {
  title?: string | null;
  title_Not?: string | null;
  title_In?: (string | null)[] | null;
  title_NotIn?: (string | null)[] | null;
  title_Lt?: string | null;
  title_Lte?: string | null;
  title_Gt?: string | null;
  title_Gte?: string | null;
  title_Contains?: string | null;
  title_NotContains?: string | null;
  title_StartsWith?: string | null;
  title_NotStartsWith?: string | null;
  title_EndsWith?: string | null;
  title_NotEndsWith?: string | null;
  title_IsBlank?: boolean | null;
  title__id?: number | null;
  title__id_Not?: number | null;
  title__id_In?: (number | null)[] | null;
  title__id_NotIn?: (number | null)[] | null;
  title__id_Lt?: number | null;
  title__id_Lte?: number | null;
  title__id_Gt?: number | null;
  title__id_Gte?: number | null;
  title__id_IsBlank?: boolean | null;
  aND?: PartnerWhereInput[] | null;
  oR?: PartnerWhereInput[] | null;
  name?: string | null;
  name_Not?: string | null;
  name_In?: (string | null)[] | null;
  name_NotIn?: (string | null)[] | null;
  name_Lt?: string | null;
  name_Lte?: string | null;
  name_Gt?: string | null;
  name_Gte?: string | null;
  name_Contains?: string | null;
  name_NotContains?: string | null;
  name_StartsWith?: string | null;
  name_NotStartsWith?: string | null;
  name_EndsWith?: string | null;
  name_NotEndsWith?: string | null;
  name_IsBlank?: boolean | null;
  phone?: string | null;
  phone_Not?: string | null;
  phone_In?: (string | null)[] | null;
  phone_NotIn?: (string | null)[] | null;
  phone_Lt?: string | null;
  phone_Lte?: string | null;
  phone_Gt?: string | null;
  phone_Gte?: string | null;
  phone_Contains?: string | null;
  phone_NotContains?: string | null;
  phone_StartsWith?: string | null;
  phone_NotStartsWith?: string | null;
  phone_EndsWith?: string | null;
  phone_NotEndsWith?: string | null;
  phone_IsBlank?: boolean | null;
  city?: string | null;
  city_Not?: string | null;
  city_In?: (string | null)[] | null;
  city_NotIn?: (string | null)[] | null;
  city_Lt?: string | null;
  city_Lte?: string | null;
  city_Gt?: string | null;
  city_Gte?: string | null;
  city_Contains?: string | null;
  city_NotContains?: string | null;
  city_StartsWith?: string | null;
  city_NotStartsWith?: string | null;
  city_EndsWith?: string | null;
  city_NotEndsWith?: string | null;
  city_IsBlank?: boolean | null;
  street?: string | null;
  street_Not?: string | null;
  street_In?: (string | null)[] | null;
  street_NotIn?: (string | null)[] | null;
  street_Lt?: string | null;
  street_Lte?: string | null;
  street_Gt?: string | null;
  street_Gte?: string | null;
  street_Contains?: string | null;
  street_NotContains?: string | null;
  street_StartsWith?: string | null;
  street_NotStartsWith?: string | null;
  street_EndsWith?: string | null;
  street_NotEndsWith?: string | null;
  street_IsBlank?: boolean | null;
  email?: string | null;
  email_Not?: string | null;
  email_In?: (string | null)[] | null;
  email_NotIn?: (string | null)[] | null;
  email_Lt?: string | null;
  email_Lte?: string | null;
  email_Gt?: string | null;
  email_Gte?: string | null;
  email_Contains?: string | null;
  email_NotContains?: string | null;
  email_StartsWith?: string | null;
  email_NotStartsWith?: string | null;
  email_EndsWith?: string | null;
  email_NotEndsWith?: string | null;
  email_IsBlank?: boolean | null;
  ref?: string | null;
  ref_Not?: string | null;
  ref_In?: (string | null)[] | null;
  ref_NotIn?: (string | null)[] | null;
  ref_Lt?: string | null;
  ref_Lte?: string | null;
  ref_Gt?: string | null;
  ref_Gte?: string | null;
  ref_Contains?: string | null;
  ref_NotContains?: string | null;
  ref_StartsWith?: string | null;
  ref_NotStartsWith?: string | null;
  ref_EndsWith?: string | null;
  ref_NotEndsWith?: string | null;
  ref_IsBlank?: boolean | null;
  sale_order_count?: number | null;
  sale_order_count_Not?: number | null;
  sale_order_count_In?: (number | null)[] | null;
  sale_order_count_NotIn?: (number | null)[] | null;
  sale_order_count_Lt?: number | null;
  sale_order_count_Lte?: number | null;
  sale_order_count_Gt?: number | null;
  sale_order_count_Gte?: number | null;
  sale_order_count_IsBlank?: boolean | null;
  purchase_order_count?: number | null;
  purchase_order_count_Not?: number | null;
  purchase_order_count_In?: (number | null)[] | null;
  purchase_order_count_NotIn?: (number | null)[] | null;
  purchase_order_count_Lt?: number | null;
  purchase_order_count_Lte?: number | null;
  purchase_order_count_Gt?: number | null;
  purchase_order_count_Gte?: number | null;
  purchase_order_count_IsBlank?: boolean | null;
  supplier_invoice_count?: number | null;
  supplier_invoice_count_Not?: number | null;
  supplier_invoice_count_In?: (number | null)[] | null;
  supplier_invoice_count_NotIn?: (number | null)[] | null;
  supplier_invoice_count_Lt?: number | null;
  supplier_invoice_count_Lte?: number | null;
  supplier_invoice_count_Gt?: number | null;
  supplier_invoice_count_Gte?: number | null;
  supplier_invoice_count_IsBlank?: boolean | null;
  total_invoiced?: number | null;
  total_invoiced_Not?: number | null;
  total_invoiced_In?: (number | null)[] | null;
  total_invoiced_NotIn?: (number | null)[] | null;
  total_invoiced_Lt?: number | null;
  total_invoiced_Lte?: number | null;
  total_invoiced_Gt?: number | null;
  total_invoiced_Gte?: number | null;
  total_invoiced_IsBlank?: boolean | null;
  write_date?: any | null;
  write_date_Not?: any | null;
  write_date_Lt?: any | null;
  write_date_Lte?: any | null;
  write_date_Gt?: any | null;
  write_date_Gte?: any | null;
  write_date_IsBlank?: any | null;
  credit?: number | null;
  credit_Not?: number | null;
  credit_In?: (number | null)[] | null;
  credit_NotIn?: (number | null)[] | null;
  credit_Lt?: number | null;
  credit_Lte?: number | null;
  credit_Gt?: number | null;
  credit_Gte?: number | null;
  credit_IsBlank?: boolean | null;
  active?: boolean | null;
  customer_rank?: number | null;
  customer_rank_Not?: number | null;
  customer_rank_In?: (number | null)[] | null;
  customer_rank_NotIn?: (number | null)[] | null;
  customer_rank_Lt?: number | null;
  customer_rank_Lte?: number | null;
  customer_rank_Gt?: number | null;
  customer_rank_Gte?: number | null;
  customer_rank_IsBlank?: boolean | null;
  supplier_rank?: number | null;
  supplier_rank_Not?: number | null;
  supplier_rank_In?: (number | null)[] | null;
  supplier_rank_NotIn?: (number | null)[] | null;
  supplier_rank_Lt?: number | null;
  supplier_rank_Lte?: number | null;
  supplier_rank_Gt?: number | null;
  supplier_rank_Gte?: number | null;
  supplier_rank_IsBlank?: boolean | null;
  id?: string | null;
  id_Not?: string | null;
  id_In?: (string | null)[] | null;
  id_NotIn?: (string | null)[] | null;
  id_Lt?: string | null;
  id_Lte?: string | null;
  id_Gt?: string | null;
  id_Gte?: string | null;
  id_IsBlank?: boolean | null;
}

export interface PaymentWithInvoiceWhereInput {
  id?: string | null;
  id_Not?: string | null;
  id_In?: (string | null)[] | null;
  id_NotIn?: (string | null)[] | null;
  id_Lt?: string | null;
  id_Lte?: string | null;
  id_Gt?: string | null;
  id_Gte?: string | null;
  id_IsBlank?: boolean | null;
  aND?: PaymentWithInvoiceWhereInput[] | null;
  oR?: PaymentWithInvoiceWhereInput[] | null;
  date?: any | null;
  date_Not?: any | null;
  date_Lt?: any | null;
  date_Lte?: any | null;
  date_Gt?: any | null;
  date_Gte?: any | null;
  date_IsBlank?: any | null;
  name?: string | null;
  name_Not?: string | null;
  name_In?: (string | null)[] | null;
  name_NotIn?: (string | null)[] | null;
  name_Lt?: string | null;
  name_Lte?: string | null;
  name_Gt?: string | null;
  name_Gte?: string | null;
  name_Contains?: string | null;
  name_NotContains?: string | null;
  name_StartsWith?: string | null;
  name_NotStartsWith?: string | null;
  name_EndsWith?: string | null;
  name_NotEndsWith?: string | null;
  name_IsBlank?: boolean | null;
  journal_code?: string | null;
  journal_code_Not?: string | null;
  journal_code_In?: (string | null)[] | null;
  journal_code_NotIn?: (string | null)[] | null;
  journal_code_Lt?: string | null;
  journal_code_Lte?: string | null;
  journal_code_Gt?: string | null;
  journal_code_Gte?: string | null;
  journal_code_Contains?: string | null;
  journal_code_NotContains?: string | null;
  journal_code_StartsWith?: string | null;
  journal_code_NotStartsWith?: string | null;
  journal_code_EndsWith?: string | null;
  journal_code_NotEndsWith?: string | null;
  journal_code_IsBlank?: boolean | null;
  ref?: string | null;
  ref_Not?: string | null;
  ref_In?: (string | null)[] | null;
  ref_NotIn?: (string | null)[] | null;
  ref_Lt?: string | null;
  ref_Lte?: string | null;
  ref_Gt?: string | null;
  ref_Gte?: string | null;
  ref_Contains?: string | null;
  ref_NotContains?: string | null;
  ref_StartsWith?: string | null;
  ref_NotStartsWith?: string | null;
  ref_EndsWith?: string | null;
  ref_NotEndsWith?: string | null;
  ref_IsBlank?: boolean | null;
  payment_type?: PaymentType | null;
  payment_type_Not?: PaymentType | null;
  payment_type_In?: PaymentType[] | null;
  payment_type_NotIn?: PaymentType[] | null;
  payment_type_IsBlank?: boolean | null;
  partner_id?: number | null;
  partner_id_Not?: number | null;
  partner_id_In?: (number | null)[] | null;
  partner_id_NotIn?: (number | null)[] | null;
  partner_id_Lt?: number | null;
  partner_id_Lte?: number | null;
  partner_id_Gt?: number | null;
  partner_id_Gte?: number | null;
  partner_id_IsBlank?: boolean | null;
  journal_type?: JournalType | null;
  journal_type_Not?: JournalType | null;
  journal_type_In?: JournalType[] | null;
  journal_type_NotIn?: JournalType[] | null;
  journal_type_IsBlank?: boolean | null;
  partner_name?: string | null;
  partner_name_Not?: string | null;
  partner_name_In?: (string | null)[] | null;
  partner_name_NotIn?: (string | null)[] | null;
  partner_name_Lt?: string | null;
  partner_name_Lte?: string | null;
  partner_name_Gt?: string | null;
  partner_name_Gte?: string | null;
  partner_name_Contains?: string | null;
  partner_name_NotContains?: string | null;
  partner_name_StartsWith?: string | null;
  partner_name_NotStartsWith?: string | null;
  partner_name_EndsWith?: string | null;
  partner_name_NotEndsWith?: string | null;
  partner_name_IsBlank?: boolean | null;
  amount?: number | null;
  amount_Not?: number | null;
  amount_In?: (number | null)[] | null;
  amount_NotIn?: (number | null)[] | null;
  amount_Lt?: number | null;
  amount_Lte?: number | null;
  amount_Gt?: number | null;
  amount_Gte?: number | null;
  amount_IsBlank?: boolean | null;
  inner_reconciled_amount?: number | null;
  inner_reconciled_amount_Not?: number | null;
  inner_reconciled_amount_In?: (number | null)[] | null;
  inner_reconciled_amount_NotIn?: (number | null)[] | null;
  inner_reconciled_amount_Lt?: number | null;
  inner_reconciled_amount_Lte?: number | null;
  inner_reconciled_amount_Gt?: number | null;
  inner_reconciled_amount_Gte?: number | null;
  inner_reconciled_amount_IsBlank?: boolean | null;
  outer_reconciled_amount?: number | null;
  outer_reconciled_amount_Not?: number | null;
  outer_reconciled_amount_In?: (number | null)[] | null;
  outer_reconciled_amount_NotIn?: (number | null)[] | null;
  outer_reconciled_amount_Lt?: number | null;
  outer_reconciled_amount_Lte?: number | null;
  outer_reconciled_amount_Gt?: number | null;
  outer_reconciled_amount_Gte?: number | null;
  outer_reconciled_amount_IsBlank?: boolean | null;
  counterpart_move_id?: number | null;
  counterpart_move_id_Not?: number | null;
  counterpart_move_id_In?: (number | null)[] | null;
  counterpart_move_id_NotIn?: (number | null)[] | null;
  counterpart_move_id_Lt?: number | null;
  counterpart_move_id_Lte?: number | null;
  counterpart_move_id_Gt?: number | null;
  counterpart_move_id_Gte?: number | null;
  counterpart_move_id_IsBlank?: boolean | null;
  counterpart_move_name?: string | null;
  counterpart_move_name_Not?: string | null;
  counterpart_move_name_In?: (string | null)[] | null;
  counterpart_move_name_NotIn?: (string | null)[] | null;
  counterpart_move_name_Lt?: string | null;
  counterpart_move_name_Lte?: string | null;
  counterpart_move_name_Gt?: string | null;
  counterpart_move_name_Gte?: string | null;
  counterpart_move_name_Contains?: string | null;
  counterpart_move_name_NotContains?: string | null;
  counterpart_move_name_StartsWith?: string | null;
  counterpart_move_name_NotStartsWith?: string | null;
  counterpart_move_name_EndsWith?: string | null;
  counterpart_move_name_NotEndsWith?: string | null;
  counterpart_move_name_IsBlank?: boolean | null;
  counterpart_move_date?: any | null;
  counterpart_move_date_Not?: any | null;
  counterpart_move_date_Lt?: any | null;
  counterpart_move_date_Lte?: any | null;
  counterpart_move_date_Gt?: any | null;
  counterpart_move_date_Gte?: any | null;
  counterpart_move_date_IsBlank?: any | null;
  invoice_origin?: string | null;
  invoice_origin_Not?: string | null;
  invoice_origin_In?: (string | null)[] | null;
  invoice_origin_NotIn?: (string | null)[] | null;
  invoice_origin_Lt?: string | null;
  invoice_origin_Lte?: string | null;
  invoice_origin_Gt?: string | null;
  invoice_origin_Gte?: string | null;
  invoice_origin_Contains?: string | null;
  invoice_origin_NotContains?: string | null;
  invoice_origin_StartsWith?: string | null;
  invoice_origin_NotStartsWith?: string | null;
  invoice_origin_EndsWith?: string | null;
  invoice_origin_NotEndsWith?: string | null;
  invoice_origin_IsBlank?: boolean | null;
  remaining?: number | null;
  remaining_Not?: number | null;
  remaining_In?: (number | null)[] | null;
  remaining_NotIn?: (number | null)[] | null;
  remaining_Lt?: number | null;
  remaining_Lte?: number | null;
  remaining_Gt?: number | null;
  remaining_Gte?: number | null;
  remaining_IsBlank?: boolean | null;
  early_reconciled_amount?: number | null;
  early_reconciled_amount_Not?: number | null;
  early_reconciled_amount_In?: (number | null)[] | null;
  early_reconciled_amount_NotIn?: (number | null)[] | null;
  early_reconciled_amount_Lt?: number | null;
  early_reconciled_amount_Lte?: number | null;
  early_reconciled_amount_Gt?: number | null;
  early_reconciled_amount_Gte?: number | null;
  early_reconciled_amount_IsBlank?: boolean | null;
  late_reconciled_amount?: number | null;
  late_reconciled_amount_Not?: number | null;
  late_reconciled_amount_In?: (number | null)[] | null;
  late_reconciled_amount_NotIn?: (number | null)[] | null;
  late_reconciled_amount_Lt?: number | null;
  late_reconciled_amount_Lte?: number | null;
  late_reconciled_amount_Gt?: number | null;
  late_reconciled_amount_Gte?: number | null;
  late_reconciled_amount_IsBlank?: boolean | null;
}

export interface PaymentWithoutInvoiceWhereInput {
  id?: string | null;
  id_Not?: string | null;
  id_In?: (string | null)[] | null;
  id_NotIn?: (string | null)[] | null;
  id_Lt?: string | null;
  id_Lte?: string | null;
  id_Gt?: string | null;
  id_Gte?: string | null;
  id_IsBlank?: boolean | null;
  aND?: PaymentWithoutInvoiceWhereInput[] | null;
  oR?: PaymentWithoutInvoiceWhereInput[] | null;
  date?: any | null;
  date_Not?: any | null;
  date_Lt?: any | null;
  date_Lte?: any | null;
  date_Gt?: any | null;
  date_Gte?: any | null;
  date_IsBlank?: any | null;
  name?: string | null;
  name_Not?: string | null;
  name_In?: (string | null)[] | null;
  name_NotIn?: (string | null)[] | null;
  name_Lt?: string | null;
  name_Lte?: string | null;
  name_Gt?: string | null;
  name_Gte?: string | null;
  name_Contains?: string | null;
  name_NotContains?: string | null;
  name_StartsWith?: string | null;
  name_NotStartsWith?: string | null;
  name_EndsWith?: string | null;
  name_NotEndsWith?: string | null;
  name_IsBlank?: boolean | null;
  journal_code?: string | null;
  journal_code_Not?: string | null;
  journal_code_In?: (string | null)[] | null;
  journal_code_NotIn?: (string | null)[] | null;
  journal_code_Lt?: string | null;
  journal_code_Lte?: string | null;
  journal_code_Gt?: string | null;
  journal_code_Gte?: string | null;
  journal_code_Contains?: string | null;
  journal_code_NotContains?: string | null;
  journal_code_StartsWith?: string | null;
  journal_code_NotStartsWith?: string | null;
  journal_code_EndsWith?: string | null;
  journal_code_NotEndsWith?: string | null;
  journal_code_IsBlank?: boolean | null;
  journal_type?: JournalType | null;
  journal_type_Not?: JournalType | null;
  journal_type_In?: JournalType[] | null;
  journal_type_NotIn?: JournalType[] | null;
  journal_type_IsBlank?: boolean | null;
  ref?: string | null;
  ref_Not?: string | null;
  ref_In?: (string | null)[] | null;
  ref_NotIn?: (string | null)[] | null;
  ref_Lt?: string | null;
  ref_Lte?: string | null;
  ref_Gt?: string | null;
  ref_Gte?: string | null;
  ref_Contains?: string | null;
  ref_NotContains?: string | null;
  ref_StartsWith?: string | null;
  ref_NotStartsWith?: string | null;
  ref_EndsWith?: string | null;
  ref_NotEndsWith?: string | null;
  ref_IsBlank?: boolean | null;
  payment_type?: PaymentType | null;
  payment_type_Not?: PaymentType | null;
  payment_type_In?: PaymentType[] | null;
  payment_type_NotIn?: PaymentType[] | null;
  payment_type_IsBlank?: boolean | null;
  partner_id?: number | null;
  partner_id_Not?: number | null;
  partner_id_In?: (number | null)[] | null;
  partner_id_NotIn?: (number | null)[] | null;
  partner_id_Lt?: number | null;
  partner_id_Lte?: number | null;
  partner_id_Gt?: number | null;
  partner_id_Gte?: number | null;
  partner_id_IsBlank?: boolean | null;
  partner_name?: string | null;
  partner_name_Not?: string | null;
  partner_name_In?: (string | null)[] | null;
  partner_name_NotIn?: (string | null)[] | null;
  partner_name_Lt?: string | null;
  partner_name_Lte?: string | null;
  partner_name_Gt?: string | null;
  partner_name_Gte?: string | null;
  partner_name_Contains?: string | null;
  partner_name_NotContains?: string | null;
  partner_name_StartsWith?: string | null;
  partner_name_NotStartsWith?: string | null;
  partner_name_EndsWith?: string | null;
  partner_name_NotEndsWith?: string | null;
  partner_name_IsBlank?: boolean | null;
  amount?: number | null;
  amount_Not?: number | null;
  amount_In?: (number | null)[] | null;
  amount_NotIn?: (number | null)[] | null;
  amount_Lt?: number | null;
  amount_Lte?: number | null;
  amount_Gt?: number | null;
  amount_Gte?: number | null;
  amount_IsBlank?: boolean | null;
  inner_reconciled_amount?: number | null;
  inner_reconciled_amount_Not?: number | null;
  inner_reconciled_amount_In?: (number | null)[] | null;
  inner_reconciled_amount_NotIn?: (number | null)[] | null;
  inner_reconciled_amount_Lt?: number | null;
  inner_reconciled_amount_Lte?: number | null;
  inner_reconciled_amount_Gt?: number | null;
  inner_reconciled_amount_Gte?: number | null;
  inner_reconciled_amount_IsBlank?: boolean | null;
  outer_reconciled_amount?: number | null;
  outer_reconciled_amount_Not?: number | null;
  outer_reconciled_amount_In?: (number | null)[] | null;
  outer_reconciled_amount_NotIn?: (number | null)[] | null;
  outer_reconciled_amount_Lt?: number | null;
  outer_reconciled_amount_Lte?: number | null;
  outer_reconciled_amount_Gt?: number | null;
  outer_reconciled_amount_Gte?: number | null;
  outer_reconciled_amount_IsBlank?: boolean | null;
  remaining?: number | null;
  remaining_Not?: number | null;
  remaining_In?: (number | null)[] | null;
  remaining_NotIn?: (number | null)[] | null;
  remaining_Lt?: number | null;
  remaining_Lte?: number | null;
  remaining_Gt?: number | null;
  remaining_Gte?: number | null;
  remaining_IsBlank?: boolean | null;
}

export interface ProductImageInput {
  id?: string | null;
  description?: string | null;
  image?: string | null;
  sequence?: number | null;
  aspect_ratio?: number | null;
  product_variant_id?: number | null;
}

export interface ProductImageInputList {
  list?: ProductImageInput[] | null;
  deleted?: number[] | null;
}

export interface ProductListItemWhereInput {
  id?: number | null;
  id_Not?: number | null;
  id_In?: (number | null)[] | null;
  id_NotIn?: (number | null)[] | null;
  id_Lt?: number | null;
  id_Lte?: number | null;
  id_Gt?: number | null;
  id_Gte?: number | null;
  id_IsBlank?: boolean | null;
  aND?: ProductListItemWhereInput[] | null;
  oR?: ProductListItemWhereInput[] | null;
  default_code?: string | null;
  default_code_Not?: string | null;
  default_code_In?: (string | null)[] | null;
  default_code_NotIn?: (string | null)[] | null;
  default_code_Lt?: string | null;
  default_code_Lte?: string | null;
  default_code_Gt?: string | null;
  default_code_Gte?: string | null;
  default_code_Contains?: string | null;
  default_code_NotContains?: string | null;
  default_code_StartsWith?: string | null;
  default_code_NotStartsWith?: string | null;
  default_code_EndsWith?: string | null;
  default_code_NotEndsWith?: string | null;
  default_code_IsBlank?: boolean | null;
  search_code?: string | null;
  search_code_Not?: string | null;
  search_code_In?: (string | null)[] | null;
  search_code_NotIn?: (string | null)[] | null;
  search_code_Lt?: string | null;
  search_code_Lte?: string | null;
  search_code_Gt?: string | null;
  search_code_Gte?: string | null;
  search_code_Contains?: string | null;
  search_code_NotContains?: string | null;
  search_code_StartsWith?: string | null;
  search_code_NotStartsWith?: string | null;
  search_code_EndsWith?: string | null;
  search_code_NotEndsWith?: string | null;
  search_code_IsBlank?: boolean | null;
  barcode?: string | null;
  barcode_Not?: string | null;
  barcode_In?: (string | null)[] | null;
  barcode_NotIn?: (string | null)[] | null;
  barcode_Lt?: string | null;
  barcode_Lte?: string | null;
  barcode_Gt?: string | null;
  barcode_Gte?: string | null;
  barcode_Contains?: string | null;
  barcode_NotContains?: string | null;
  barcode_StartsWith?: string | null;
  barcode_NotStartsWith?: string | null;
  barcode_EndsWith?: string | null;
  barcode_NotEndsWith?: string | null;
  barcode_IsBlank?: boolean | null;
  name?: string | null;
  name_Not?: string | null;
  name_In?: (string | null)[] | null;
  name_NotIn?: (string | null)[] | null;
  name_Lt?: string | null;
  name_Lte?: string | null;
  name_Gt?: string | null;
  name_Gte?: string | null;
  name_Contains?: string | null;
  name_NotContains?: string | null;
  name_StartsWith?: string | null;
  name_NotStartsWith?: string | null;
  name_EndsWith?: string | null;
  name_NotEndsWith?: string | null;
  name_IsBlank?: boolean | null;
  supplier?: string | null;
  supplier_Not?: string | null;
  supplier_In?: (string | null)[] | null;
  supplier_NotIn?: (string | null)[] | null;
  supplier_Lt?: string | null;
  supplier_Lte?: string | null;
  supplier_Gt?: string | null;
  supplier_Gte?: string | null;
  supplier_Contains?: string | null;
  supplier_NotContains?: string | null;
  supplier_StartsWith?: string | null;
  supplier_NotStartsWith?: string | null;
  supplier_EndsWith?: string | null;
  supplier_NotEndsWith?: string | null;
  supplier_IsBlank?: boolean | null;
  supplier_category?: string | null;
  supplier_category_Not?: string | null;
  supplier_category_In?: (string | null)[] | null;
  supplier_category_NotIn?: (string | null)[] | null;
  supplier_category_Lt?: string | null;
  supplier_category_Lte?: string | null;
  supplier_category_Gt?: string | null;
  supplier_category_Gte?: string | null;
  supplier_category_Contains?: string | null;
  supplier_category_NotContains?: string | null;
  supplier_category_StartsWith?: string | null;
  supplier_category_NotStartsWith?: string | null;
  supplier_category_EndsWith?: string | null;
  supplier_category_NotEndsWith?: string | null;
  supplier_category_IsBlank?: boolean | null;
  list_price?: number | null;
  list_price_Not?: number | null;
  list_price_In?: (number | null)[] | null;
  list_price_NotIn?: (number | null)[] | null;
  list_price_Lt?: number | null;
  list_price_Lte?: number | null;
  list_price_Gt?: number | null;
  list_price_Gte?: number | null;
  list_price_IsBlank?: boolean | null;
  current_cost?: number | null;
  current_cost_Not?: number | null;
  current_cost_In?: (number | null)[] | null;
  current_cost_NotIn?: (number | null)[] | null;
  current_cost_Lt?: number | null;
  current_cost_Lte?: number | null;
  current_cost_Gt?: number | null;
  current_cost_Gte?: number | null;
  current_cost_IsBlank?: boolean | null;
  quantity?: number | null;
  quantity_Not?: number | null;
  quantity_In?: (number | null)[] | null;
  quantity_NotIn?: (number | null)[] | null;
  quantity_Lt?: number | null;
  quantity_Lte?: number | null;
  quantity_Gt?: number | null;
  quantity_Gte?: number | null;
  quantity_IsBlank?: boolean | null;
  reserved_quantity?: number | null;
  reserved_quantity_Not?: number | null;
  reserved_quantity_In?: (number | null)[] | null;
  reserved_quantity_NotIn?: (number | null)[] | null;
  reserved_quantity_Lt?: number | null;
  reserved_quantity_Lte?: number | null;
  reserved_quantity_Gt?: number | null;
  reserved_quantity_Gte?: number | null;
  reserved_quantity_IsBlank?: boolean | null;
  qty_available?: number | null;
  qty_available_Not?: number | null;
  qty_available_In?: (number | null)[] | null;
  qty_available_NotIn?: (number | null)[] | null;
  qty_available_Lt?: number | null;
  qty_available_Lte?: number | null;
  qty_available_Gt?: number | null;
  qty_available_Gte?: number | null;
  qty_available_IsBlank?: boolean | null;
  type?: ProductType | null;
  type_Not?: ProductType | null;
  type_In?: ProductType[] | null;
  type_NotIn?: ProductType[] | null;
  type_IsBlank?: boolean | null;
  uom?: string | null;
  uom_Not?: string | null;
  uom_In?: (string | null)[] | null;
  uom_NotIn?: (string | null)[] | null;
  uom_Lt?: string | null;
  uom_Lte?: string | null;
  uom_Gt?: string | null;
  uom_Gte?: string | null;
  uom_Contains?: string | null;
  uom_NotContains?: string | null;
  uom_StartsWith?: string | null;
  uom_NotStartsWith?: string | null;
  uom_EndsWith?: string | null;
  uom_NotEndsWith?: string | null;
  uom_IsBlank?: boolean | null;
  uom_id?: number | null;
  uom_id_Not?: number | null;
  uom_id_In?: (number | null)[] | null;
  uom_id_NotIn?: (number | null)[] | null;
  uom_id_Lt?: number | null;
  uom_id_Lte?: number | null;
  uom_id_Gt?: number | null;
  uom_id_Gte?: number | null;
  uom_id_IsBlank?: boolean | null;
  current_margin?: number | null;
  current_margin_Not?: number | null;
  current_margin_In?: (number | null)[] | null;
  current_margin_NotIn?: (number | null)[] | null;
  current_margin_Lt?: number | null;
  current_margin_Lte?: number | null;
  current_margin_Gt?: number | null;
  current_margin_Gte?: number | null;
  current_margin_IsBlank?: boolean | null;
  zone_id?: number | null;
  zone_id_Not?: number | null;
  zone_id_In?: (number | null)[] | null;
  zone_id_NotIn?: (number | null)[] | null;
  zone_id_Lt?: number | null;
  zone_id_Lte?: number | null;
  zone_id_Gt?: number | null;
  zone_id_Gte?: number | null;
  zone_id_IsBlank?: boolean | null;
  brand_id?: number | null;
  brand_id_Not?: number | null;
  brand_id_In?: (number | null)[] | null;
  brand_id_NotIn?: (number | null)[] | null;
  brand_id_Lt?: number | null;
  brand_id_Lte?: number | null;
  brand_id_Gt?: number | null;
  brand_id_Gte?: number | null;
  brand_id_IsBlank?: boolean | null;
  salezone?: string | null;
  salezone_Not?: string | null;
  salezone_In?: (string | null)[] | null;
  salezone_NotIn?: (string | null)[] | null;
  salezone_Lt?: string | null;
  salezone_Lte?: string | null;
  salezone_Gt?: string | null;
  salezone_Gte?: string | null;
  salezone_Contains?: string | null;
  salezone_NotContains?: string | null;
  salezone_StartsWith?: string | null;
  salezone_NotStartsWith?: string | null;
  salezone_EndsWith?: string | null;
  salezone_NotEndsWith?: string | null;
  salezone_IsBlank?: boolean | null;
  brand?: string | null;
  brand_Not?: string | null;
  brand_In?: (string | null)[] | null;
  brand_NotIn?: (string | null)[] | null;
  brand_Lt?: string | null;
  brand_Lte?: string | null;
  brand_Gt?: string | null;
  brand_Gte?: string | null;
  brand_Contains?: string | null;
  brand_NotContains?: string | null;
  brand_StartsWith?: string | null;
  brand_NotStartsWith?: string | null;
  brand_EndsWith?: string | null;
  brand_NotEndsWith?: string | null;
  brand_IsBlank?: boolean | null;
}

export interface ProductLotWhereInput {
  name?: string | null;
  name_Not?: string | null;
  name_In?: (string | null)[] | null;
  name_NotIn?: (string | null)[] | null;
  name_Lt?: string | null;
  name_Lte?: string | null;
  name_Gt?: string | null;
  name_Gte?: string | null;
  name_Contains?: string | null;
  name_NotContains?: string | null;
  name_StartsWith?: string | null;
  name_NotStartsWith?: string | null;
  name_EndsWith?: string | null;
  name_NotEndsWith?: string | null;
  name_IsBlank?: boolean | null;
  aND?: ProductLotWhereInput[] | null;
  oR?: ProductLotWhereInput[] | null;
  product_id?: string | null;
  product_id_Not?: string | null;
  product_id_In?: (string | null)[] | null;
  product_id_NotIn?: (string | null)[] | null;
  product_id_Lt?: string | null;
  product_id_Lte?: string | null;
  product_id_Gt?: string | null;
  product_id_Gte?: string | null;
  product_id_Contains?: string | null;
  product_id_NotContains?: string | null;
  product_id_StartsWith?: string | null;
  product_id_NotStartsWith?: string | null;
  product_id_EndsWith?: string | null;
  product_id_NotEndsWith?: string | null;
  product_id_IsBlank?: boolean | null;
  product_id__id?: number | null;
  product_id__id_Not?: number | null;
  product_id__id_In?: (number | null)[] | null;
  product_id__id_NotIn?: (number | null)[] | null;
  product_id__id_Lt?: number | null;
  product_id__id_Lte?: number | null;
  product_id__id_Gt?: number | null;
  product_id__id_Gte?: number | null;
  product_id__id_IsBlank?: boolean | null;
  id?: string | null;
  id_Not?: string | null;
  id_In?: (string | null)[] | null;
  id_NotIn?: (string | null)[] | null;
  id_Lt?: string | null;
  id_Lte?: string | null;
  id_Gt?: string | null;
  id_Gte?: string | null;
  id_IsBlank?: boolean | null;
}

export interface ProductValuationBreakdownWhereInput {
  id?: number | null;
  id_Not?: number | null;
  id_In?: (number | null)[] | null;
  id_NotIn?: (number | null)[] | null;
  id_Lt?: number | null;
  id_Lte?: number | null;
  id_Gt?: number | null;
  id_Gte?: number | null;
  id_IsBlank?: boolean | null;
  aND?: ProductValuationBreakdownWhereInput[] | null;
  oR?: ProductValuationBreakdownWhereInput[] | null;
  layer_id?: number | null;
  layer_id_Not?: number | null;
  layer_id_In?: (number | null)[] | null;
  layer_id_NotIn?: (number | null)[] | null;
  layer_id_Lt?: number | null;
  layer_id_Lte?: number | null;
  layer_id_Gt?: number | null;
  layer_id_Gte?: number | null;
  layer_id_IsBlank?: boolean | null;
  valuation_type?: ProductValuationBreakdownType | null;
  valuation_type_Not?: ProductValuationBreakdownType | null;
  valuation_type_In?: ProductValuationBreakdownType[] | null;
  valuation_type_NotIn?: ProductValuationBreakdownType[] | null;
  valuation_type_IsBlank?: boolean | null;
  create_date?: any | null;
  create_date_Not?: any | null;
  create_date_Lt?: any | null;
  create_date_Lte?: any | null;
  create_date_Gt?: any | null;
  create_date_Gte?: any | null;
  create_date_IsBlank?: any | null;
  name?: string | null;
  name_Not?: string | null;
  name_In?: (string | null)[] | null;
  name_NotIn?: (string | null)[] | null;
  name_Lt?: string | null;
  name_Lte?: string | null;
  name_Gt?: string | null;
  name_Gte?: string | null;
  name_Contains?: string | null;
  name_NotContains?: string | null;
  name_StartsWith?: string | null;
  name_NotStartsWith?: string | null;
  name_EndsWith?: string | null;
  name_NotEndsWith?: string | null;
  name_IsBlank?: boolean | null;
  quantity?: number | null;
  quantity_Not?: number | null;
  quantity_In?: (number | null)[] | null;
  quantity_NotIn?: (number | null)[] | null;
  quantity_Lt?: number | null;
  quantity_Lte?: number | null;
  quantity_Gt?: number | null;
  quantity_Gte?: number | null;
  quantity_IsBlank?: boolean | null;
  unit_cost?: number | null;
  unit_cost_Not?: number | null;
  unit_cost_In?: (number | null)[] | null;
  unit_cost_NotIn?: (number | null)[] | null;
  unit_cost_Lt?: number | null;
  unit_cost_Lte?: number | null;
  unit_cost_Gt?: number | null;
  unit_cost_Gte?: number | null;
  unit_cost_IsBlank?: boolean | null;
  value?: number | null;
  value_Not?: number | null;
  value_In?: (number | null)[] | null;
  value_NotIn?: (number | null)[] | null;
  value_Lt?: number | null;
  value_Lte?: number | null;
  value_Gt?: number | null;
  value_Gte?: number | null;
  value_IsBlank?: boolean | null;
}

export interface ProductWhereInput {
  barcode?: string | null;
  barcode_Not?: string | null;
  barcode_In?: (string | null)[] | null;
  barcode_NotIn?: (string | null)[] | null;
  barcode_Lt?: string | null;
  barcode_Lte?: string | null;
  barcode_Gt?: string | null;
  barcode_Gte?: string | null;
  barcode_Contains?: string | null;
  barcode_NotContains?: string | null;
  barcode_StartsWith?: string | null;
  barcode_NotStartsWith?: string | null;
  barcode_EndsWith?: string | null;
  barcode_NotEndsWith?: string | null;
  barcode_IsBlank?: boolean | null;
  aND?: ProductWhereInput[] | null;
  oR?: ProductWhereInput[] | null;
  can_be_expensed?: boolean | null;
  categ_id?: string | null;
  categ_id_Not?: string | null;
  categ_id_In?: (string | null)[] | null;
  categ_id_NotIn?: (string | null)[] | null;
  categ_id_Lt?: string | null;
  categ_id_Lte?: string | null;
  categ_id_Gt?: string | null;
  categ_id_Gte?: string | null;
  categ_id_Contains?: string | null;
  categ_id_NotContains?: string | null;
  categ_id_StartsWith?: string | null;
  categ_id_NotStartsWith?: string | null;
  categ_id_EndsWith?: string | null;
  categ_id_NotEndsWith?: string | null;
  categ_id_IsBlank?: boolean | null;
  categ_id__id?: number | null;
  categ_id__id_Not?: number | null;
  categ_id__id_In?: (number | null)[] | null;
  categ_id__id_NotIn?: (number | null)[] | null;
  categ_id__id_Lt?: number | null;
  categ_id__id_Lte?: number | null;
  categ_id__id_Gt?: number | null;
  categ_id__id_Gte?: number | null;
  categ_id__id_IsBlank?: boolean | null;
  name?: string | null;
  name_Not?: string | null;
  name_In?: (string | null)[] | null;
  name_NotIn?: (string | null)[] | null;
  name_Lt?: string | null;
  name_Lte?: string | null;
  name_Gt?: string | null;
  name_Gte?: string | null;
  name_Contains?: string | null;
  name_NotContains?: string | null;
  name_StartsWith?: string | null;
  name_NotStartsWith?: string | null;
  name_EndsWith?: string | null;
  name_NotEndsWith?: string | null;
  name_IsBlank?: boolean | null;
  cost_method?: string | null;
  cost_method_Not?: string | null;
  cost_method_In?: (string | null)[] | null;
  cost_method_NotIn?: (string | null)[] | null;
  cost_method_Lt?: string | null;
  cost_method_Lte?: string | null;
  cost_method_Gt?: string | null;
  cost_method_Gte?: string | null;
  cost_method_Contains?: string | null;
  cost_method_NotContains?: string | null;
  cost_method_StartsWith?: string | null;
  cost_method_NotStartsWith?: string | null;
  cost_method_EndsWith?: string | null;
  cost_method_NotEndsWith?: string | null;
  cost_method_IsBlank?: boolean | null;
  create_date?: any | null;
  create_date_Not?: any | null;
  create_date_Lt?: any | null;
  create_date_Lte?: any | null;
  create_date_Gt?: any | null;
  create_date_Gte?: any | null;
  create_date_IsBlank?: any | null;
  create_uid?: string | null;
  create_uid_Not?: string | null;
  create_uid_In?: (string | null)[] | null;
  create_uid_NotIn?: (string | null)[] | null;
  create_uid_Lt?: string | null;
  create_uid_Lte?: string | null;
  create_uid_Gt?: string | null;
  create_uid_Gte?: string | null;
  create_uid_IsBlank?: boolean | null;
  default_code?: string | null;
  default_code_Not?: string | null;
  default_code_In?: (string | null)[] | null;
  default_code_NotIn?: (string | null)[] | null;
  default_code_Lt?: string | null;
  default_code_Lte?: string | null;
  default_code_Gt?: string | null;
  default_code_Gte?: string | null;
  default_code_Contains?: string | null;
  default_code_NotContains?: string | null;
  default_code_StartsWith?: string | null;
  default_code_NotStartsWith?: string | null;
  default_code_EndsWith?: string | null;
  default_code_NotEndsWith?: string | null;
  default_code_IsBlank?: boolean | null;
  description?: string | null;
  description_Not?: string | null;
  description_In?: (string | null)[] | null;
  description_NotIn?: (string | null)[] | null;
  description_Lt?: string | null;
  description_Lte?: string | null;
  description_Gt?: string | null;
  description_Gte?: string | null;
  description_Contains?: string | null;
  description_NotContains?: string | null;
  description_StartsWith?: string | null;
  description_NotStartsWith?: string | null;
  description_EndsWith?: string | null;
  description_NotEndsWith?: string | null;
  description_IsBlank?: boolean | null;
  description_picking?: string | null;
  description_picking_Not?: string | null;
  description_picking_In?: (string | null)[] | null;
  description_picking_NotIn?: (string | null)[] | null;
  description_picking_Lt?: string | null;
  description_picking_Lte?: string | null;
  description_picking_Gt?: string | null;
  description_picking_Gte?: string | null;
  description_picking_Contains?: string | null;
  description_picking_NotContains?: string | null;
  description_picking_StartsWith?: string | null;
  description_picking_NotStartsWith?: string | null;
  description_picking_EndsWith?: string | null;
  description_picking_NotEndsWith?: string | null;
  description_picking_IsBlank?: boolean | null;
  description_pickingin?: string | null;
  description_pickingin_Not?: string | null;
  description_pickingin_In?: (string | null)[] | null;
  description_pickingin_NotIn?: (string | null)[] | null;
  description_pickingin_Lt?: string | null;
  description_pickingin_Lte?: string | null;
  description_pickingin_Gt?: string | null;
  description_pickingin_Gte?: string | null;
  description_pickingin_Contains?: string | null;
  description_pickingin_NotContains?: string | null;
  description_pickingin_StartsWith?: string | null;
  description_pickingin_NotStartsWith?: string | null;
  description_pickingin_EndsWith?: string | null;
  description_pickingin_NotEndsWith?: string | null;
  description_pickingin_IsBlank?: boolean | null;
  description_pickingout?: string | null;
  description_pickingout_Not?: string | null;
  description_pickingout_In?: (string | null)[] | null;
  description_pickingout_NotIn?: (string | null)[] | null;
  description_pickingout_Lt?: string | null;
  description_pickingout_Lte?: string | null;
  description_pickingout_Gt?: string | null;
  description_pickingout_Gte?: string | null;
  description_pickingout_Contains?: string | null;
  description_pickingout_NotContains?: string | null;
  description_pickingout_StartsWith?: string | null;
  description_pickingout_NotStartsWith?: string | null;
  description_pickingout_EndsWith?: string | null;
  description_pickingout_NotEndsWith?: string | null;
  description_pickingout_IsBlank?: boolean | null;
  description_purchase?: string | null;
  description_purchase_Not?: string | null;
  description_purchase_In?: (string | null)[] | null;
  description_purchase_NotIn?: (string | null)[] | null;
  description_purchase_Lt?: string | null;
  description_purchase_Lte?: string | null;
  description_purchase_Gt?: string | null;
  description_purchase_Gte?: string | null;
  description_purchase_Contains?: string | null;
  description_purchase_NotContains?: string | null;
  description_purchase_StartsWith?: string | null;
  description_purchase_NotStartsWith?: string | null;
  description_purchase_EndsWith?: string | null;
  description_purchase_NotEndsWith?: string | null;
  description_purchase_IsBlank?: boolean | null;
  description_sale?: string | null;
  description_sale_Not?: string | null;
  description_sale_In?: (string | null)[] | null;
  description_sale_NotIn?: (string | null)[] | null;
  description_sale_Lt?: string | null;
  description_sale_Lte?: string | null;
  description_sale_Gt?: string | null;
  description_sale_Gte?: string | null;
  description_sale_Contains?: string | null;
  description_sale_NotContains?: string | null;
  description_sale_StartsWith?: string | null;
  description_sale_NotStartsWith?: string | null;
  description_sale_EndsWith?: string | null;
  description_sale_NotEndsWith?: string | null;
  description_sale_IsBlank?: boolean | null;
  display_name?: string | null;
  display_name_Not?: string | null;
  display_name_In?: (string | null)[] | null;
  display_name_NotIn?: (string | null)[] | null;
  display_name_Lt?: string | null;
  display_name_Lte?: string | null;
  display_name_Gt?: string | null;
  display_name_Gte?: string | null;
  display_name_Contains?: string | null;
  display_name_NotContains?: string | null;
  display_name_StartsWith?: string | null;
  display_name_NotStartsWith?: string | null;
  display_name_EndsWith?: string | null;
  display_name_NotEndsWith?: string | null;
  display_name_IsBlank?: boolean | null;
  list_price?: number | null;
  list_price_Not?: number | null;
  list_price_In?: (number | null)[] | null;
  list_price_NotIn?: (number | null)[] | null;
  list_price_Lt?: number | null;
  list_price_Lte?: number | null;
  list_price_Gt?: number | null;
  list_price_Gte?: number | null;
  list_price_IsBlank?: boolean | null;
  standard_price?: number | null;
  standard_price_Not?: number | null;
  standard_price_In?: (number | null)[] | null;
  standard_price_NotIn?: (number | null)[] | null;
  standard_price_Lt?: number | null;
  standard_price_Lte?: number | null;
  standard_price_Gt?: number | null;
  standard_price_Gte?: number | null;
  standard_price_IsBlank?: boolean | null;
  incoming_qty?: number | null;
  incoming_qty_Not?: number | null;
  incoming_qty_In?: (number | null)[] | null;
  incoming_qty_NotIn?: (number | null)[] | null;
  incoming_qty_Lt?: number | null;
  incoming_qty_Lte?: number | null;
  incoming_qty_Gt?: number | null;
  incoming_qty_Gte?: number | null;
  incoming_qty_IsBlank?: boolean | null;
  outgoing_qty?: number | null;
  outgoing_qty_Not?: number | null;
  outgoing_qty_In?: (number | null)[] | null;
  outgoing_qty_NotIn?: (number | null)[] | null;
  outgoing_qty_Lt?: number | null;
  outgoing_qty_Lte?: number | null;
  outgoing_qty_Gt?: number | null;
  outgoing_qty_Gte?: number | null;
  outgoing_qty_IsBlank?: boolean | null;
  is_product_variant?: boolean | null;
  landed_cost_ok?: boolean | null;
  uom_id?: string | null;
  uom_id_Not?: string | null;
  uom_id_In?: (string | null)[] | null;
  uom_id_NotIn?: (string | null)[] | null;
  uom_id_Lt?: string | null;
  uom_id_Lte?: string | null;
  uom_id_Gt?: string | null;
  uom_id_Gte?: string | null;
  uom_id_Contains?: string | null;
  uom_id_NotContains?: string | null;
  uom_id_StartsWith?: string | null;
  uom_id_NotStartsWith?: string | null;
  uom_id_EndsWith?: string | null;
  uom_id_NotEndsWith?: string | null;
  uom_id_IsBlank?: boolean | null;
  uom_id__id?: number | null;
  uom_id__id_Not?: number | null;
  uom_id__id_In?: (number | null)[] | null;
  uom_id__id_NotIn?: (number | null)[] | null;
  uom_id__id_Lt?: number | null;
  uom_id__id_Lte?: number | null;
  uom_id__id_Gt?: number | null;
  uom_id__id_Gte?: number | null;
  uom_id__id_IsBlank?: boolean | null;
  uom_po_id?: string | null;
  uom_po_id_Not?: string | null;
  uom_po_id_In?: (string | null)[] | null;
  uom_po_id_NotIn?: (string | null)[] | null;
  uom_po_id_Lt?: string | null;
  uom_po_id_Lte?: string | null;
  uom_po_id_Gt?: string | null;
  uom_po_id_Gte?: string | null;
  uom_po_id_Contains?: string | null;
  uom_po_id_NotContains?: string | null;
  uom_po_id_StartsWith?: string | null;
  uom_po_id_NotStartsWith?: string | null;
  uom_po_id_EndsWith?: string | null;
  uom_po_id_NotEndsWith?: string | null;
  uom_po_id_IsBlank?: boolean | null;
  uom_po_id__id?: number | null;
  uom_po_id__id_Not?: number | null;
  uom_po_id__id_In?: (number | null)[] | null;
  uom_po_id__id_NotIn?: (number | null)[] | null;
  uom_po_id__id_Lt?: number | null;
  uom_po_id__id_Lte?: number | null;
  uom_po_id__id_Gt?: number | null;
  uom_po_id__id_Gte?: number | null;
  uom_po_id__id_IsBlank?: boolean | null;
  product_tmpl_id?: string | null;
  product_tmpl_id_Not?: string | null;
  product_tmpl_id_In?: (string | null)[] | null;
  product_tmpl_id_NotIn?: (string | null)[] | null;
  product_tmpl_id_Lt?: string | null;
  product_tmpl_id_Lte?: string | null;
  product_tmpl_id_Gt?: string | null;
  product_tmpl_id_Gte?: string | null;
  product_tmpl_id_Contains?: string | null;
  product_tmpl_id_NotContains?: string | null;
  product_tmpl_id_StartsWith?: string | null;
  product_tmpl_id_NotStartsWith?: string | null;
  product_tmpl_id_EndsWith?: string | null;
  product_tmpl_id_NotEndsWith?: string | null;
  product_tmpl_id_IsBlank?: boolean | null;
  product_tmpl_id__id?: number | null;
  product_tmpl_id__id_Not?: number | null;
  product_tmpl_id__id_In?: (number | null)[] | null;
  product_tmpl_id__id_NotIn?: (number | null)[] | null;
  product_tmpl_id__id_Lt?: number | null;
  product_tmpl_id__id_Lte?: number | null;
  product_tmpl_id__id_Gt?: number | null;
  product_tmpl_id__id_Gte?: number | null;
  product_tmpl_id__id_IsBlank?: boolean | null;
  sales_count?: number | null;
  sales_count_Not?: number | null;
  sales_count_In?: (number | null)[] | null;
  sales_count_NotIn?: (number | null)[] | null;
  sales_count_Lt?: number | null;
  sales_count_Lte?: number | null;
  sales_count_Gt?: number | null;
  sales_count_Gte?: number | null;
  sales_count_IsBlank?: boolean | null;
  purchase_count?: number | null;
  purchase_count_Not?: number | null;
  purchase_count_In?: (number | null)[] | null;
  purchase_count_NotIn?: (number | null)[] | null;
  purchase_count_Lt?: number | null;
  purchase_count_Lte?: number | null;
  purchase_count_Gt?: number | null;
  purchase_count_Gte?: number | null;
  purchase_count_IsBlank?: boolean | null;
  virtual_available?: number | null;
  virtual_available_Not?: number | null;
  virtual_available_In?: (number | null)[] | null;
  virtual_available_NotIn?: (number | null)[] | null;
  virtual_available_Lt?: number | null;
  virtual_available_Lte?: number | null;
  virtual_available_Gt?: number | null;
  virtual_available_Gte?: number | null;
  virtual_available_IsBlank?: boolean | null;
  qty_available?: number | null;
  qty_available_Not?: number | null;
  qty_available_In?: (number | null)[] | null;
  qty_available_NotIn?: (number | null)[] | null;
  qty_available_Lt?: number | null;
  qty_available_Lte?: number | null;
  qty_available_Gt?: number | null;
  qty_available_Gte?: number | null;
  qty_available_IsBlank?: boolean | null;
  active?: boolean | null;
  route_ids?: number | null;
  route_ids_Not?: number | null;
  route_ids_In?: (number | null)[] | null;
  route_ids_NotIn?: (number | null)[] | null;
  route_ids_Lt?: number | null;
  route_ids_Lte?: number | null;
  route_ids_Gt?: number | null;
  route_ids_Gte?: number | null;
  route_ids_IsBlank?: boolean | null;
  type?: ProductType | null;
  type_Not?: ProductType | null;
  type_In?: ProductType[] | null;
  type_NotIn?: ProductType[] | null;
  type_IsBlank?: boolean | null;
  id?: string | null;
  id_Not?: string | null;
  id_In?: (string | null)[] | null;
  id_NotIn?: (string | null)[] | null;
  id_Lt?: string | null;
  id_Lte?: string | null;
  id_Gt?: string | null;
  id_Gte?: string | null;
  id_IsBlank?: boolean | null;
}

export interface PromotionInput {
  id?: string | null;
  name?: string | null;
  date_from?: any | null;
  date_to?: any | null;
  reward_product_income_account_id?: number | null;
  loyalty_program_ids?: CouponProgramInputList | null;
  active: boolean;
}

export interface PromotionSaleWhereInput {
  id?: string | null;
  id_Not?: string | null;
  id_In?: (string | null)[] | null;
  id_NotIn?: (string | null)[] | null;
  id_Lt?: string | null;
  id_Lte?: string | null;
  id_Gt?: string | null;
  id_Gte?: string | null;
  id_IsBlank?: boolean | null;
  aND?: PromotionSaleWhereInput[] | null;
  oR?: PromotionSaleWhereInput[] | null;
  promotion_id?: number | null;
  promotion_id_Not?: number | null;
  promotion_id_In?: (number | null)[] | null;
  promotion_id_NotIn?: (number | null)[] | null;
  promotion_id_Lt?: number | null;
  promotion_id_Lte?: number | null;
  promotion_id_Gt?: number | null;
  promotion_id_Gte?: number | null;
  promotion_id_IsBlank?: boolean | null;
  default_code?: string | null;
  default_code_Not?: string | null;
  default_code_In?: (string | null)[] | null;
  default_code_NotIn?: (string | null)[] | null;
  default_code_Lt?: string | null;
  default_code_Lte?: string | null;
  default_code_Gt?: string | null;
  default_code_Gte?: string | null;
  default_code_Contains?: string | null;
  default_code_NotContains?: string | null;
  default_code_StartsWith?: string | null;
  default_code_NotStartsWith?: string | null;
  default_code_EndsWith?: string | null;
  default_code_NotEndsWith?: string | null;
  default_code_IsBlank?: boolean | null;
  product_name?: string | null;
  product_name_Not?: string | null;
  product_name_In?: (string | null)[] | null;
  product_name_NotIn?: (string | null)[] | null;
  product_name_Lt?: string | null;
  product_name_Lte?: string | null;
  product_name_Gt?: string | null;
  product_name_Gte?: string | null;
  product_name_Contains?: string | null;
  product_name_NotContains?: string | null;
  product_name_StartsWith?: string | null;
  product_name_NotStartsWith?: string | null;
  product_name_EndsWith?: string | null;
  product_name_NotEndsWith?: string | null;
  product_name_IsBlank?: boolean | null;
  name?: string | null;
  name_Not?: string | null;
  name_In?: (string | null)[] | null;
  name_NotIn?: (string | null)[] | null;
  name_Lt?: string | null;
  name_Lte?: string | null;
  name_Gt?: string | null;
  name_Gte?: string | null;
  name_Contains?: string | null;
  name_NotContains?: string | null;
  name_StartsWith?: string | null;
  name_NotStartsWith?: string | null;
  name_EndsWith?: string | null;
  name_NotEndsWith?: string | null;
  name_IsBlank?: boolean | null;
  date?: any | null;
  date_Not?: any | null;
  date_Lt?: any | null;
  date_Lte?: any | null;
  date_Gt?: any | null;
  date_Gte?: any | null;
  date_IsBlank?: any | null;
  order_name?: string | null;
  order_name_Not?: string | null;
  order_name_In?: (string | null)[] | null;
  order_name_NotIn?: (string | null)[] | null;
  order_name_Lt?: string | null;
  order_name_Lte?: string | null;
  order_name_Gt?: string | null;
  order_name_Gte?: string | null;
  order_name_Contains?: string | null;
  order_name_NotContains?: string | null;
  order_name_StartsWith?: string | null;
  order_name_NotStartsWith?: string | null;
  order_name_EndsWith?: string | null;
  order_name_NotEndsWith?: string | null;
  order_name_IsBlank?: boolean | null;
  partner?: string | null;
  partner_Not?: string | null;
  partner_In?: (string | null)[] | null;
  partner_NotIn?: (string | null)[] | null;
  partner_Lt?: string | null;
  partner_Lte?: string | null;
  partner_Gt?: string | null;
  partner_Gte?: string | null;
  partner_Contains?: string | null;
  partner_NotContains?: string | null;
  partner_StartsWith?: string | null;
  partner_NotStartsWith?: string | null;
  partner_EndsWith?: string | null;
  partner_NotEndsWith?: string | null;
  partner_IsBlank?: boolean | null;
  description?: string | null;
  description_Not?: string | null;
  description_In?: (string | null)[] | null;
  description_NotIn?: (string | null)[] | null;
  description_Lt?: string | null;
  description_Lte?: string | null;
  description_Gt?: string | null;
  description_Gte?: string | null;
  description_Contains?: string | null;
  description_NotContains?: string | null;
  description_StartsWith?: string | null;
  description_NotStartsWith?: string | null;
  description_EndsWith?: string | null;
  description_NotEndsWith?: string | null;
  description_IsBlank?: boolean | null;
  move_name?: string | null;
  move_name_Not?: string | null;
  move_name_In?: (string | null)[] | null;
  move_name_NotIn?: (string | null)[] | null;
  move_name_Lt?: string | null;
  move_name_Lte?: string | null;
  move_name_Gt?: string | null;
  move_name_Gte?: string | null;
  move_name_Contains?: string | null;
  move_name_NotContains?: string | null;
  move_name_StartsWith?: string | null;
  move_name_NotStartsWith?: string | null;
  move_name_EndsWith?: string | null;
  move_name_NotEndsWith?: string | null;
  move_name_IsBlank?: boolean | null;
  account_name?: string | null;
  account_name_Not?: string | null;
  account_name_In?: (string | null)[] | null;
  account_name_NotIn?: (string | null)[] | null;
  account_name_Lt?: string | null;
  account_name_Lte?: string | null;
  account_name_Gt?: string | null;
  account_name_Gte?: string | null;
  account_name_Contains?: string | null;
  account_name_NotContains?: string | null;
  account_name_StartsWith?: string | null;
  account_name_NotStartsWith?: string | null;
  account_name_EndsWith?: string | null;
  account_name_NotEndsWith?: string | null;
  account_name_IsBlank?: boolean | null;
  quantity?: number | null;
  quantity_Not?: number | null;
  quantity_In?: (number | null)[] | null;
  quantity_NotIn?: (number | null)[] | null;
  quantity_Lt?: number | null;
  quantity_Lte?: number | null;
  quantity_Gt?: number | null;
  quantity_Gte?: number | null;
  quantity_IsBlank?: boolean | null;
  amount?: number | null;
  amount_Not?: number | null;
  amount_In?: (number | null)[] | null;
  amount_NotIn?: (number | null)[] | null;
  amount_Lt?: number | null;
  amount_Lte?: number | null;
  amount_Gt?: number | null;
  amount_Gte?: number | null;
  amount_IsBlank?: boolean | null;
}

export interface PromotionWhereInput {
  name?: string | null;
  name_Not?: string | null;
  name_In?: (string | null)[] | null;
  name_NotIn?: (string | null)[] | null;
  name_Lt?: string | null;
  name_Lte?: string | null;
  name_Gt?: string | null;
  name_Gte?: string | null;
  name_Contains?: string | null;
  name_NotContains?: string | null;
  name_StartsWith?: string | null;
  name_NotStartsWith?: string | null;
  name_EndsWith?: string | null;
  name_NotEndsWith?: string | null;
  name_IsBlank?: boolean | null;
  aND?: PromotionWhereInput[] | null;
  oR?: PromotionWhereInput[] | null;
  company_id?: string | null;
  company_id_Not?: string | null;
  company_id_In?: (string | null)[] | null;
  company_id_NotIn?: (string | null)[] | null;
  company_id_Lt?: string | null;
  company_id_Lte?: string | null;
  company_id_Gt?: string | null;
  company_id_Gte?: string | null;
  company_id_Contains?: string | null;
  company_id_NotContains?: string | null;
  company_id_StartsWith?: string | null;
  company_id_NotStartsWith?: string | null;
  company_id_EndsWith?: string | null;
  company_id_NotEndsWith?: string | null;
  company_id_IsBlank?: boolean | null;
  company_id__id?: number | null;
  company_id__id_Not?: number | null;
  company_id__id_In?: (number | null)[] | null;
  company_id__id_NotIn?: (number | null)[] | null;
  company_id__id_Lt?: number | null;
  company_id__id_Lte?: number | null;
  company_id__id_Gt?: number | null;
  company_id__id_Gte?: number | null;
  company_id__id_IsBlank?: boolean | null;
  date_from?: any | null;
  date_from_Not?: any | null;
  date_from_Lt?: any | null;
  date_from_Lte?: any | null;
  date_from_Gt?: any | null;
  date_from_Gte?: any | null;
  date_from_IsBlank?: any | null;
  date_to?: any | null;
  date_to_Not?: any | null;
  date_to_Lt?: any | null;
  date_to_Lte?: any | null;
  date_to_Gt?: any | null;
  date_to_Gte?: any | null;
  date_to_IsBlank?: any | null;
  reward_product_income_account_id?: string | null;
  reward_product_income_account_id_Not?: string | null;
  reward_product_income_account_id_In?: (string | null)[] | null;
  reward_product_income_account_id_NotIn?: (string | null)[] | null;
  reward_product_income_account_id_Lt?: string | null;
  reward_product_income_account_id_Lte?: string | null;
  reward_product_income_account_id_Gt?: string | null;
  reward_product_income_account_id_Gte?: string | null;
  reward_product_income_account_id_Contains?: string | null;
  reward_product_income_account_id_NotContains?: string | null;
  reward_product_income_account_id_StartsWith?: string | null;
  reward_product_income_account_id_NotStartsWith?: string | null;
  reward_product_income_account_id_EndsWith?: string | null;
  reward_product_income_account_id_NotEndsWith?: string | null;
  reward_product_income_account_id_IsBlank?: boolean | null;
  reward_product_income_account_id__id?: number | null;
  reward_product_income_account_id__id_Not?: number | null;
  reward_product_income_account_id__id_In?: (number | null)[] | null;
  reward_product_income_account_id__id_NotIn?: (number | null)[] | null;
  reward_product_income_account_id__id_Lt?: number | null;
  reward_product_income_account_id__id_Lte?: number | null;
  reward_product_income_account_id__id_Gt?: number | null;
  reward_product_income_account_id__id_Gte?: number | null;
  reward_product_income_account_id__id_IsBlank?: boolean | null;
  created_id?: string | null;
  created_id_Not?: string | null;
  created_id_In?: (string | null)[] | null;
  created_id_NotIn?: (string | null)[] | null;
  created_id_Lt?: string | null;
  created_id_Lte?: string | null;
  created_id_Gt?: string | null;
  created_id_Gte?: string | null;
  created_id_Contains?: string | null;
  created_id_NotContains?: string | null;
  created_id_StartsWith?: string | null;
  created_id_NotStartsWith?: string | null;
  created_id_EndsWith?: string | null;
  created_id_NotEndsWith?: string | null;
  created_id_IsBlank?: boolean | null;
  created_name?: string | null;
  created_name_Not?: string | null;
  created_name_In?: (string | null)[] | null;
  created_name_NotIn?: (string | null)[] | null;
  created_name_Lt?: string | null;
  created_name_Lte?: string | null;
  created_name_Gt?: string | null;
  created_name_Gte?: string | null;
  created_name_Contains?: string | null;
  created_name_NotContains?: string | null;
  created_name_StartsWith?: string | null;
  created_name_NotStartsWith?: string | null;
  created_name_EndsWith?: string | null;
  created_name_NotEndsWith?: string | null;
  created_name_IsBlank?: boolean | null;
  loyalty_program_ids?: number | null;
  loyalty_program_ids_Not?: number | null;
  loyalty_program_ids_In?: (number | null)[] | null;
  loyalty_program_ids_NotIn?: (number | null)[] | null;
  loyalty_program_ids_Lt?: number | null;
  loyalty_program_ids_Lte?: number | null;
  loyalty_program_ids_Gt?: number | null;
  loyalty_program_ids_Gte?: number | null;
  loyalty_program_ids_IsBlank?: boolean | null;
  active?: boolean | null;
  id?: string | null;
  id_Not?: string | null;
  id_In?: (string | null)[] | null;
  id_NotIn?: (string | null)[] | null;
  id_Lt?: string | null;
  id_Lte?: string | null;
  id_Gt?: string | null;
  id_Gte?: string | null;
  id_IsBlank?: boolean | null;
}

export interface PurchaseLineReportWhereInput {
  id?: string | null;
  id_Not?: string | null;
  id_In?: (string | null)[] | null;
  id_NotIn?: (string | null)[] | null;
  id_Lt?: string | null;
  id_Lte?: string | null;
  id_Gt?: string | null;
  id_Gte?: string | null;
  id_IsBlank?: boolean | null;
  aND?: PurchaseLineReportWhereInput[] | null;
  oR?: PurchaseLineReportWhereInput[] | null;
  move_id?: number | null;
  move_id_Not?: number | null;
  move_id_In?: (number | null)[] | null;
  move_id_NotIn?: (number | null)[] | null;
  move_id_Lt?: number | null;
  move_id_Lte?: number | null;
  move_id_Gt?: number | null;
  move_id_Gte?: number | null;
  move_id_IsBlank?: boolean | null;
  name?: string | null;
  name_Not?: string | null;
  name_In?: (string | null)[] | null;
  name_NotIn?: (string | null)[] | null;
  name_Lt?: string | null;
  name_Lte?: string | null;
  name_Gt?: string | null;
  name_Gte?: string | null;
  name_Contains?: string | null;
  name_NotContains?: string | null;
  name_StartsWith?: string | null;
  name_NotStartsWith?: string | null;
  name_EndsWith?: string | null;
  name_NotEndsWith?: string | null;
  name_IsBlank?: boolean | null;
  invoice_origin?: string | null;
  invoice_origin_Not?: string | null;
  invoice_origin_In?: (string | null)[] | null;
  invoice_origin_NotIn?: (string | null)[] | null;
  invoice_origin_Lt?: string | null;
  invoice_origin_Lte?: string | null;
  invoice_origin_Gt?: string | null;
  invoice_origin_Gte?: string | null;
  invoice_origin_Contains?: string | null;
  invoice_origin_NotContains?: string | null;
  invoice_origin_StartsWith?: string | null;
  invoice_origin_NotStartsWith?: string | null;
  invoice_origin_EndsWith?: string | null;
  invoice_origin_NotEndsWith?: string | null;
  invoice_origin_IsBlank?: boolean | null;
  invoice_date?: any | null;
  invoice_date_Not?: any | null;
  invoice_date_Lt?: any | null;
  invoice_date_Lte?: any | null;
  invoice_date_Gt?: any | null;
  invoice_date_Gte?: any | null;
  invoice_date_IsBlank?: any | null;
  ref?: string | null;
  ref_Not?: string | null;
  ref_In?: (string | null)[] | null;
  ref_NotIn?: (string | null)[] | null;
  ref_Lt?: string | null;
  ref_Lte?: string | null;
  ref_Gt?: string | null;
  ref_Gte?: string | null;
  ref_Contains?: string | null;
  ref_NotContains?: string | null;
  ref_StartsWith?: string | null;
  ref_NotStartsWith?: string | null;
  ref_EndsWith?: string | null;
  ref_NotEndsWith?: string | null;
  ref_IsBlank?: boolean | null;
  product_id?: number | null;
  product_id_Not?: number | null;
  product_id_In?: (number | null)[] | null;
  product_id_NotIn?: (number | null)[] | null;
  product_id_Lt?: number | null;
  product_id_Lte?: number | null;
  product_id_Gt?: number | null;
  product_id_Gte?: number | null;
  product_id_IsBlank?: boolean | null;
  default_code?: string | null;
  default_code_Not?: string | null;
  default_code_In?: (string | null)[] | null;
  default_code_NotIn?: (string | null)[] | null;
  default_code_Lt?: string | null;
  default_code_Lte?: string | null;
  default_code_Gt?: string | null;
  default_code_Gte?: string | null;
  default_code_Contains?: string | null;
  default_code_NotContains?: string | null;
  default_code_StartsWith?: string | null;
  default_code_NotStartsWith?: string | null;
  default_code_EndsWith?: string | null;
  default_code_NotEndsWith?: string | null;
  default_code_IsBlank?: boolean | null;
  product_name?: string | null;
  product_name_Not?: string | null;
  product_name_In?: (string | null)[] | null;
  product_name_NotIn?: (string | null)[] | null;
  product_name_Lt?: string | null;
  product_name_Lte?: string | null;
  product_name_Gt?: string | null;
  product_name_Gte?: string | null;
  product_name_Contains?: string | null;
  product_name_NotContains?: string | null;
  product_name_StartsWith?: string | null;
  product_name_NotStartsWith?: string | null;
  product_name_EndsWith?: string | null;
  product_name_NotEndsWith?: string | null;
  product_name_IsBlank?: boolean | null;
  brand?: string | null;
  brand_Not?: string | null;
  brand_In?: (string | null)[] | null;
  brand_NotIn?: (string | null)[] | null;
  brand_Lt?: string | null;
  brand_Lte?: string | null;
  brand_Gt?: string | null;
  brand_Gte?: string | null;
  brand_Contains?: string | null;
  brand_NotContains?: string | null;
  brand_StartsWith?: string | null;
  brand_NotStartsWith?: string | null;
  brand_EndsWith?: string | null;
  brand_NotEndsWith?: string | null;
  brand_IsBlank?: boolean | null;
  salezone?: string | null;
  salezone_Not?: string | null;
  salezone_In?: (string | null)[] | null;
  salezone_NotIn?: (string | null)[] | null;
  salezone_Lt?: string | null;
  salezone_Lte?: string | null;
  salezone_Gt?: string | null;
  salezone_Gte?: string | null;
  salezone_Contains?: string | null;
  salezone_NotContains?: string | null;
  salezone_StartsWith?: string | null;
  salezone_NotStartsWith?: string | null;
  salezone_EndsWith?: string | null;
  salezone_NotEndsWith?: string | null;
  salezone_IsBlank?: boolean | null;
  uom?: string | null;
  uom_Not?: string | null;
  uom_In?: (string | null)[] | null;
  uom_NotIn?: (string | null)[] | null;
  uom_Lt?: string | null;
  uom_Lte?: string | null;
  uom_Gt?: string | null;
  uom_Gte?: string | null;
  uom_Contains?: string | null;
  uom_NotContains?: string | null;
  uom_StartsWith?: string | null;
  uom_NotStartsWith?: string | null;
  uom_EndsWith?: string | null;
  uom_NotEndsWith?: string | null;
  uom_IsBlank?: boolean | null;
  quantity?: number | null;
  quantity_Not?: number | null;
  quantity_In?: (number | null)[] | null;
  quantity_NotIn?: (number | null)[] | null;
  quantity_Lt?: number | null;
  quantity_Lte?: number | null;
  quantity_Gt?: number | null;
  quantity_Gte?: number | null;
  quantity_IsBlank?: boolean | null;
  price_unit?: number | null;
  price_unit_Not?: number | null;
  price_unit_In?: (number | null)[] | null;
  price_unit_NotIn?: (number | null)[] | null;
  price_unit_Lt?: number | null;
  price_unit_Lte?: number | null;
  price_unit_Gt?: number | null;
  price_unit_Gte?: number | null;
  price_unit_IsBlank?: boolean | null;
  price_total?: number | null;
  price_total_Not?: number | null;
  price_total_In?: (number | null)[] | null;
  price_total_NotIn?: (number | null)[] | null;
  price_total_Lt?: number | null;
  price_total_Lte?: number | null;
  price_total_Gt?: number | null;
  price_total_Gte?: number | null;
  price_total_IsBlank?: boolean | null;
  partner_id?: number | null;
  partner_id_Not?: number | null;
  partner_id_In?: (number | null)[] | null;
  partner_id_NotIn?: (number | null)[] | null;
  partner_id_Lt?: number | null;
  partner_id_Lte?: number | null;
  partner_id_Gt?: number | null;
  partner_id_Gte?: number | null;
  partner_id_IsBlank?: boolean | null;
  partner?: string | null;
  partner_Not?: string | null;
  partner_In?: (string | null)[] | null;
  partner_NotIn?: (string | null)[] | null;
  partner_Lt?: string | null;
  partner_Lte?: string | null;
  partner_Gt?: string | null;
  partner_Gte?: string | null;
  partner_Contains?: string | null;
  partner_NotContains?: string | null;
  partner_StartsWith?: string | null;
  partner_NotStartsWith?: string | null;
  partner_EndsWith?: string | null;
  partner_NotEndsWith?: string | null;
  partner_IsBlank?: boolean | null;
}

export interface ReservedStockMoveInput {
  id: number;
  product_id: number;
  lot_name?: string | null;
  quantity_done: number;
}

export interface SaleInvoiceReportWhereInput {
  id?: string | null;
  id_Not?: string | null;
  id_In?: (string | null)[] | null;
  id_NotIn?: (string | null)[] | null;
  id_Lt?: string | null;
  id_Lte?: string | null;
  id_Gt?: string | null;
  id_Gte?: string | null;
  id_IsBlank?: boolean | null;
  aND?: SaleInvoiceReportWhereInput[] | null;
  oR?: SaleInvoiceReportWhereInput[] | null;
  move_id?: string | null;
  move_id_Not?: string | null;
  move_id_In?: (string | null)[] | null;
  move_id_NotIn?: (string | null)[] | null;
  move_id_Lt?: string | null;
  move_id_Lte?: string | null;
  move_id_Gt?: string | null;
  move_id_Gte?: string | null;
  move_id_IsBlank?: boolean | null;
  invoice_date?: any | null;
  invoice_date_Not?: any | null;
  invoice_date_Lt?: any | null;
  invoice_date_Lte?: any | null;
  invoice_date_Gt?: any | null;
  invoice_date_Gte?: any | null;
  invoice_date_IsBlank?: any | null;
  invoice_origin?: string | null;
  invoice_origin_Not?: string | null;
  invoice_origin_In?: (string | null)[] | null;
  invoice_origin_NotIn?: (string | null)[] | null;
  invoice_origin_Lt?: string | null;
  invoice_origin_Lte?: string | null;
  invoice_origin_Gt?: string | null;
  invoice_origin_Gte?: string | null;
  invoice_origin_Contains?: string | null;
  invoice_origin_NotContains?: string | null;
  invoice_origin_StartsWith?: string | null;
  invoice_origin_NotStartsWith?: string | null;
  invoice_origin_EndsWith?: string | null;
  invoice_origin_NotEndsWith?: string | null;
  invoice_origin_IsBlank?: boolean | null;
  name?: string | null;
  name_Not?: string | null;
  name_In?: (string | null)[] | null;
  name_NotIn?: (string | null)[] | null;
  name_Lt?: string | null;
  name_Lte?: string | null;
  name_Gt?: string | null;
  name_Gte?: string | null;
  name_Contains?: string | null;
  name_NotContains?: string | null;
  name_StartsWith?: string | null;
  name_NotStartsWith?: string | null;
  name_EndsWith?: string | null;
  name_NotEndsWith?: string | null;
  name_IsBlank?: boolean | null;
  order_by?: string | null;
  order_by_Not?: string | null;
  order_by_In?: (string | null)[] | null;
  order_by_NotIn?: (string | null)[] | null;
  order_by_Lt?: string | null;
  order_by_Lte?: string | null;
  order_by_Gt?: string | null;
  order_by_Gte?: string | null;
  order_by_Contains?: string | null;
  order_by_NotContains?: string | null;
  order_by_StartsWith?: string | null;
  order_by_NotStartsWith?: string | null;
  order_by_EndsWith?: string | null;
  order_by_NotEndsWith?: string | null;
  order_by_IsBlank?: boolean | null;
  invoice_by?: string | null;
  invoice_by_Not?: string | null;
  invoice_by_In?: (string | null)[] | null;
  invoice_by_NotIn?: (string | null)[] | null;
  invoice_by_Lt?: string | null;
  invoice_by_Lte?: string | null;
  invoice_by_Gt?: string | null;
  invoice_by_Gte?: string | null;
  invoice_by_Contains?: string | null;
  invoice_by_NotContains?: string | null;
  invoice_by_StartsWith?: string | null;
  invoice_by_NotStartsWith?: string | null;
  invoice_by_EndsWith?: string | null;
  invoice_by_NotEndsWith?: string | null;
  invoice_by_IsBlank?: boolean | null;
  team_name?: string | null;
  team_name_Not?: string | null;
  team_name_In?: (string | null)[] | null;
  team_name_NotIn?: (string | null)[] | null;
  team_name_Lt?: string | null;
  team_name_Lte?: string | null;
  team_name_Gt?: string | null;
  team_name_Gte?: string | null;
  team_name_Contains?: string | null;
  team_name_NotContains?: string | null;
  team_name_StartsWith?: string | null;
  team_name_NotStartsWith?: string | null;
  team_name_EndsWith?: string | null;
  team_name_NotEndsWith?: string | null;
  team_name_IsBlank?: boolean | null;
  partner_name?: string | null;
  partner_name_Not?: string | null;
  partner_name_In?: (string | null)[] | null;
  partner_name_NotIn?: (string | null)[] | null;
  partner_name_Lt?: string | null;
  partner_name_Lte?: string | null;
  partner_name_Gt?: string | null;
  partner_name_Gte?: string | null;
  partner_name_Contains?: string | null;
  partner_name_NotContains?: string | null;
  partner_name_StartsWith?: string | null;
  partner_name_NotStartsWith?: string | null;
  partner_name_EndsWith?: string | null;
  partner_name_NotEndsWith?: string | null;
  partner_name_IsBlank?: boolean | null;
  payment_state?: PaymentStatus | null;
  payment_state_Not?: PaymentStatus | null;
  payment_state_In?: PaymentStatus[] | null;
  payment_state_NotIn?: PaymentStatus[] | null;
  payment_state_IsBlank?: boolean | null;
  amount_total?: number | null;
  amount_total_Not?: number | null;
  amount_total_In?: (number | null)[] | null;
  amount_total_NotIn?: (number | null)[] | null;
  amount_total_Lt?: number | null;
  amount_total_Lte?: number | null;
  amount_total_Gt?: number | null;
  amount_total_Gte?: number | null;
  amount_total_IsBlank?: boolean | null;
  amount_residual?: number | null;
  amount_residual_Not?: number | null;
  amount_residual_In?: (number | null)[] | null;
  amount_residual_NotIn?: (number | null)[] | null;
  amount_residual_Lt?: number | null;
  amount_residual_Lte?: number | null;
  amount_residual_Gt?: number | null;
  amount_residual_Gte?: number | null;
  amount_residual_IsBlank?: boolean | null;
  counterpart_move_id?: number | null;
  counterpart_move_id_Not?: number | null;
  counterpart_move_id_In?: (number | null)[] | null;
  counterpart_move_id_NotIn?: (number | null)[] | null;
  counterpart_move_id_Lt?: number | null;
  counterpart_move_id_Lte?: number | null;
  counterpart_move_id_Gt?: number | null;
  counterpart_move_id_Gte?: number | null;
  counterpart_move_id_IsBlank?: boolean | null;
  counterpart_move_name?: string | null;
  counterpart_move_name_Not?: string | null;
  counterpart_move_name_In?: (string | null)[] | null;
  counterpart_move_name_NotIn?: (string | null)[] | null;
  counterpart_move_name_Lt?: string | null;
  counterpart_move_name_Lte?: string | null;
  counterpart_move_name_Gt?: string | null;
  counterpart_move_name_Gte?: string | null;
  counterpart_move_name_Contains?: string | null;
  counterpart_move_name_NotContains?: string | null;
  counterpart_move_name_StartsWith?: string | null;
  counterpart_move_name_NotStartsWith?: string | null;
  counterpart_move_name_EndsWith?: string | null;
  counterpart_move_name_NotEndsWith?: string | null;
  counterpart_move_name_IsBlank?: boolean | null;
  journal_code?: string | null;
  journal_code_Not?: string | null;
  journal_code_In?: (string | null)[] | null;
  journal_code_NotIn?: (string | null)[] | null;
  journal_code_Lt?: string | null;
  journal_code_Lte?: string | null;
  journal_code_Gt?: string | null;
  journal_code_Gte?: string | null;
  journal_code_Contains?: string | null;
  journal_code_NotContains?: string | null;
  journal_code_StartsWith?: string | null;
  journal_code_NotStartsWith?: string | null;
  journal_code_EndsWith?: string | null;
  journal_code_NotEndsWith?: string | null;
  journal_code_IsBlank?: boolean | null;
  journal_type?: JournalType | null;
  journal_type_Not?: JournalType | null;
  journal_type_In?: JournalType[] | null;
  journal_type_NotIn?: JournalType[] | null;
  journal_type_IsBlank?: boolean | null;
}

export interface SaleInvoiceReportWithoutPaymentWhereInput {
  id?: string | null;
  id_Not?: string | null;
  id_In?: (string | null)[] | null;
  id_NotIn?: (string | null)[] | null;
  id_Lt?: string | null;
  id_Lte?: string | null;
  id_Gt?: string | null;
  id_Gte?: string | null;
  id_IsBlank?: boolean | null;
  aND?: SaleInvoiceReportWithoutPaymentWhereInput[] | null;
  oR?: SaleInvoiceReportWithoutPaymentWhereInput[] | null;
  invoice_date?: any | null;
  invoice_date_Not?: any | null;
  invoice_date_Lt?: any | null;
  invoice_date_Lte?: any | null;
  invoice_date_Gt?: any | null;
  invoice_date_Gte?: any | null;
  invoice_date_IsBlank?: any | null;
  invoice_origin?: string | null;
  invoice_origin_Not?: string | null;
  invoice_origin_In?: (string | null)[] | null;
  invoice_origin_NotIn?: (string | null)[] | null;
  invoice_origin_Lt?: string | null;
  invoice_origin_Lte?: string | null;
  invoice_origin_Gt?: string | null;
  invoice_origin_Gte?: string | null;
  invoice_origin_Contains?: string | null;
  invoice_origin_NotContains?: string | null;
  invoice_origin_StartsWith?: string | null;
  invoice_origin_NotStartsWith?: string | null;
  invoice_origin_EndsWith?: string | null;
  invoice_origin_NotEndsWith?: string | null;
  invoice_origin_IsBlank?: boolean | null;
  name?: string | null;
  name_Not?: string | null;
  name_In?: (string | null)[] | null;
  name_NotIn?: (string | null)[] | null;
  name_Lt?: string | null;
  name_Lte?: string | null;
  name_Gt?: string | null;
  name_Gte?: string | null;
  name_Contains?: string | null;
  name_NotContains?: string | null;
  name_StartsWith?: string | null;
  name_NotStartsWith?: string | null;
  name_EndsWith?: string | null;
  name_NotEndsWith?: string | null;
  name_IsBlank?: boolean | null;
  order_by?: string | null;
  order_by_Not?: string | null;
  order_by_In?: (string | null)[] | null;
  order_by_NotIn?: (string | null)[] | null;
  order_by_Lt?: string | null;
  order_by_Lte?: string | null;
  order_by_Gt?: string | null;
  order_by_Gte?: string | null;
  order_by_Contains?: string | null;
  order_by_NotContains?: string | null;
  order_by_StartsWith?: string | null;
  order_by_NotStartsWith?: string | null;
  order_by_EndsWith?: string | null;
  order_by_NotEndsWith?: string | null;
  order_by_IsBlank?: boolean | null;
  invoice_by?: string | null;
  invoice_by_Not?: string | null;
  invoice_by_In?: (string | null)[] | null;
  invoice_by_NotIn?: (string | null)[] | null;
  invoice_by_Lt?: string | null;
  invoice_by_Lte?: string | null;
  invoice_by_Gt?: string | null;
  invoice_by_Gte?: string | null;
  invoice_by_Contains?: string | null;
  invoice_by_NotContains?: string | null;
  invoice_by_StartsWith?: string | null;
  invoice_by_NotStartsWith?: string | null;
  invoice_by_EndsWith?: string | null;
  invoice_by_NotEndsWith?: string | null;
  invoice_by_IsBlank?: boolean | null;
  team_name?: string | null;
  team_name_Not?: string | null;
  team_name_In?: (string | null)[] | null;
  team_name_NotIn?: (string | null)[] | null;
  team_name_Lt?: string | null;
  team_name_Lte?: string | null;
  team_name_Gt?: string | null;
  team_name_Gte?: string | null;
  team_name_Contains?: string | null;
  team_name_NotContains?: string | null;
  team_name_StartsWith?: string | null;
  team_name_NotStartsWith?: string | null;
  team_name_EndsWith?: string | null;
  team_name_NotEndsWith?: string | null;
  team_name_IsBlank?: boolean | null;
  partner_name?: string | null;
  partner_name_Not?: string | null;
  partner_name_In?: (string | null)[] | null;
  partner_name_NotIn?: (string | null)[] | null;
  partner_name_Lt?: string | null;
  partner_name_Lte?: string | null;
  partner_name_Gt?: string | null;
  partner_name_Gte?: string | null;
  partner_name_Contains?: string | null;
  partner_name_NotContains?: string | null;
  partner_name_StartsWith?: string | null;
  partner_name_NotStartsWith?: string | null;
  partner_name_EndsWith?: string | null;
  partner_name_NotEndsWith?: string | null;
  partner_name_IsBlank?: boolean | null;
  payment_state?: PaymentStatus | null;
  payment_state_Not?: PaymentStatus | null;
  payment_state_In?: PaymentStatus[] | null;
  payment_state_NotIn?: PaymentStatus[] | null;
  payment_state_IsBlank?: boolean | null;
  amount_total?: number | null;
  amount_total_Not?: number | null;
  amount_total_In?: (number | null)[] | null;
  amount_total_NotIn?: (number | null)[] | null;
  amount_total_Lt?: number | null;
  amount_total_Lte?: number | null;
  amount_total_Gt?: number | null;
  amount_total_Gte?: number | null;
  amount_total_IsBlank?: boolean | null;
  amount_residual?: number | null;
  amount_residual_Not?: number | null;
  amount_residual_In?: (number | null)[] | null;
  amount_residual_NotIn?: (number | null)[] | null;
  amount_residual_Lt?: number | null;
  amount_residual_Lte?: number | null;
  amount_residual_Gt?: number | null;
  amount_residual_Gte?: number | null;
  amount_residual_IsBlank?: boolean | null;
}

export interface SaleOrderInput {
  id?: string | null;
  date_order?: any | null;
  partner_id?: number | null;
  warehouse_id?: number | null;
  note?: string | null;
  analytic_account_id?: number | null;
  team_id?: number | null;
  order_line?: SaleOrderLineInputList | null;
}

export interface SaleOrderLineInput {
  id?: string | null;
  name?: string | null;
  price_unit?: number | null;
  product_id?: number | null;
  product_uom_qty?: number | null;
  discount?: number | null;
  saleperson_id?: number | null;
}

export interface SaleOrderLineInputList {
  list?: SaleOrderLineInput[] | null;
  deleted?: number[] | null;
}

export interface SaleOrderWhereInput {
  name?: string | null;
  name_Not?: string | null;
  name_In?: (string | null)[] | null;
  name_NotIn?: (string | null)[] | null;
  name_Lt?: string | null;
  name_Lte?: string | null;
  name_Gt?: string | null;
  name_Gte?: string | null;
  name_Contains?: string | null;
  name_NotContains?: string | null;
  name_StartsWith?: string | null;
  name_NotStartsWith?: string | null;
  name_EndsWith?: string | null;
  name_NotEndsWith?: string | null;
  name_IsBlank?: boolean | null;
  aND?: SaleOrderWhereInput[] | null;
  oR?: SaleOrderWhereInput[] | null;
  date_order?: any | null;
  date_order_Not?: any | null;
  date_order_Lt?: any | null;
  date_order_Lte?: any | null;
  date_order_Gt?: any | null;
  date_order_Gte?: any | null;
  date_order_IsBlank?: any | null;
  partner_id?: string | null;
  partner_id_Not?: string | null;
  partner_id_In?: (string | null)[] | null;
  partner_id_NotIn?: (string | null)[] | null;
  partner_id_Lt?: string | null;
  partner_id_Lte?: string | null;
  partner_id_Gt?: string | null;
  partner_id_Gte?: string | null;
  partner_id_Contains?: string | null;
  partner_id_NotContains?: string | null;
  partner_id_StartsWith?: string | null;
  partner_id_NotStartsWith?: string | null;
  partner_id_EndsWith?: string | null;
  partner_id_NotEndsWith?: string | null;
  partner_id_IsBlank?: boolean | null;
  partner_id__id?: number | null;
  partner_id__id_Not?: number | null;
  partner_id__id_In?: (number | null)[] | null;
  partner_id__id_NotIn?: (number | null)[] | null;
  partner_id__id_Lt?: number | null;
  partner_id__id_Lte?: number | null;
  partner_id__id_Gt?: number | null;
  partner_id__id_Gte?: number | null;
  partner_id__id_IsBlank?: boolean | null;
  amount_untaxed?: number | null;
  amount_untaxed_Not?: number | null;
  amount_untaxed_In?: (number | null)[] | null;
  amount_untaxed_NotIn?: (number | null)[] | null;
  amount_untaxed_Lt?: number | null;
  amount_untaxed_Lte?: number | null;
  amount_untaxed_Gt?: number | null;
  amount_untaxed_Gte?: number | null;
  amount_untaxed_IsBlank?: boolean | null;
  amount_total?: number | null;
  amount_total_Not?: number | null;
  amount_total_In?: (number | null)[] | null;
  amount_total_NotIn?: (number | null)[] | null;
  amount_total_Lt?: number | null;
  amount_total_Lte?: number | null;
  amount_total_Gt?: number | null;
  amount_total_Gte?: number | null;
  amount_total_IsBlank?: boolean | null;
  client_order_ref?: string | null;
  client_order_ref_Not?: string | null;
  client_order_ref_In?: (string | null)[] | null;
  client_order_ref_NotIn?: (string | null)[] | null;
  client_order_ref_Lt?: string | null;
  client_order_ref_Lte?: string | null;
  client_order_ref_Gt?: string | null;
  client_order_ref_Gte?: string | null;
  client_order_ref_Contains?: string | null;
  client_order_ref_NotContains?: string | null;
  client_order_ref_StartsWith?: string | null;
  client_order_ref_NotStartsWith?: string | null;
  client_order_ref_EndsWith?: string | null;
  client_order_ref_NotEndsWith?: string | null;
  client_order_ref_IsBlank?: boolean | null;
  state?: SaleOrderState | null;
  state_Not?: SaleOrderState | null;
  state_In?: SaleOrderState[] | null;
  state_NotIn?: SaleOrderState[] | null;
  state_IsBlank?: boolean | null;
  pricelist_id?: string | null;
  pricelist_id_Not?: string | null;
  pricelist_id_In?: (string | null)[] | null;
  pricelist_id_NotIn?: (string | null)[] | null;
  pricelist_id_Lt?: string | null;
  pricelist_id_Lte?: string | null;
  pricelist_id_Gt?: string | null;
  pricelist_id_Gte?: string | null;
  pricelist_id_Contains?: string | null;
  pricelist_id_NotContains?: string | null;
  pricelist_id_StartsWith?: string | null;
  pricelist_id_NotStartsWith?: string | null;
  pricelist_id_EndsWith?: string | null;
  pricelist_id_NotEndsWith?: string | null;
  pricelist_id_IsBlank?: boolean | null;
  pricelist_id__id?: number | null;
  pricelist_id__id_Not?: number | null;
  pricelist_id__id_In?: (number | null)[] | null;
  pricelist_id__id_NotIn?: (number | null)[] | null;
  pricelist_id__id_Lt?: number | null;
  pricelist_id__id_Lte?: number | null;
  pricelist_id__id_Gt?: number | null;
  pricelist_id__id_Gte?: number | null;
  pricelist_id__id_IsBlank?: boolean | null;
  note?: string | null;
  note_Not?: string | null;
  note_In?: (string | null)[] | null;
  note_NotIn?: (string | null)[] | null;
  note_Lt?: string | null;
  note_Lte?: string | null;
  note_Gt?: string | null;
  note_Gte?: string | null;
  note_Contains?: string | null;
  note_NotContains?: string | null;
  note_StartsWith?: string | null;
  note_NotStartsWith?: string | null;
  note_EndsWith?: string | null;
  note_NotEndsWith?: string | null;
  note_IsBlank?: boolean | null;
  warehouse_id?: string | null;
  warehouse_id_Not?: string | null;
  warehouse_id_In?: (string | null)[] | null;
  warehouse_id_NotIn?: (string | null)[] | null;
  warehouse_id_Lt?: string | null;
  warehouse_id_Lte?: string | null;
  warehouse_id_Gt?: string | null;
  warehouse_id_Gte?: string | null;
  warehouse_id_Contains?: string | null;
  warehouse_id_NotContains?: string | null;
  warehouse_id_StartsWith?: string | null;
  warehouse_id_NotStartsWith?: string | null;
  warehouse_id_EndsWith?: string | null;
  warehouse_id_NotEndsWith?: string | null;
  warehouse_id_IsBlank?: boolean | null;
  warehouse_id__id?: number | null;
  warehouse_id__id_Not?: number | null;
  warehouse_id__id_In?: (number | null)[] | null;
  warehouse_id__id_NotIn?: (number | null)[] | null;
  warehouse_id__id_Lt?: number | null;
  warehouse_id__id_Lte?: number | null;
  warehouse_id__id_Gt?: number | null;
  warehouse_id__id_Gte?: number | null;
  warehouse_id__id_IsBlank?: boolean | null;
  analytic_account_id?: string | null;
  analytic_account_id_Not?: string | null;
  analytic_account_id_In?: (string | null)[] | null;
  analytic_account_id_NotIn?: (string | null)[] | null;
  analytic_account_id_Lt?: string | null;
  analytic_account_id_Lte?: string | null;
  analytic_account_id_Gt?: string | null;
  analytic_account_id_Gte?: string | null;
  analytic_account_id_Contains?: string | null;
  analytic_account_id_NotContains?: string | null;
  analytic_account_id_StartsWith?: string | null;
  analytic_account_id_NotStartsWith?: string | null;
  analytic_account_id_EndsWith?: string | null;
  analytic_account_id_NotEndsWith?: string | null;
  analytic_account_id_IsBlank?: boolean | null;
  analytic_account_id__id?: number | null;
  analytic_account_id__id_Not?: number | null;
  analytic_account_id__id_In?: (number | null)[] | null;
  analytic_account_id__id_NotIn?: (number | null)[] | null;
  analytic_account_id__id_Lt?: number | null;
  analytic_account_id__id_Lte?: number | null;
  analytic_account_id__id_Gt?: number | null;
  analytic_account_id__id_Gte?: number | null;
  analytic_account_id__id_IsBlank?: boolean | null;
  team_id?: string | null;
  team_id_Not?: string | null;
  team_id_In?: (string | null)[] | null;
  team_id_NotIn?: (string | null)[] | null;
  team_id_Lt?: string | null;
  team_id_Lte?: string | null;
  team_id_Gt?: string | null;
  team_id_Gte?: string | null;
  team_id_Contains?: string | null;
  team_id_NotContains?: string | null;
  team_id_StartsWith?: string | null;
  team_id_NotStartsWith?: string | null;
  team_id_EndsWith?: string | null;
  team_id_NotEndsWith?: string | null;
  team_id_IsBlank?: boolean | null;
  team_id__id?: number | null;
  team_id__id_Not?: number | null;
  team_id__id_In?: (number | null)[] | null;
  team_id__id_NotIn?: (number | null)[] | null;
  team_id__id_Lt?: number | null;
  team_id__id_Lte?: number | null;
  team_id__id_Gt?: number | null;
  team_id__id_Gte?: number | null;
  team_id__id_IsBlank?: boolean | null;
  origin?: string | null;
  origin_Not?: string | null;
  origin_In?: (string | null)[] | null;
  origin_NotIn?: (string | null)[] | null;
  origin_Lt?: string | null;
  origin_Lte?: string | null;
  origin_Gt?: string | null;
  origin_Gte?: string | null;
  origin_Contains?: string | null;
  origin_NotContains?: string | null;
  origin_StartsWith?: string | null;
  origin_NotStartsWith?: string | null;
  origin_EndsWith?: string | null;
  origin_NotEndsWith?: string | null;
  origin_IsBlank?: boolean | null;
  order_line?: number | null;
  order_line_Not?: number | null;
  order_line_In?: (number | null)[] | null;
  order_line_NotIn?: (number | null)[] | null;
  order_line_Lt?: number | null;
  order_line_Lte?: number | null;
  order_line_Gt?: number | null;
  order_line_Gte?: number | null;
  order_line_IsBlank?: boolean | null;
  invoice_ids?: number | null;
  invoice_ids_Not?: number | null;
  invoice_ids_In?: (number | null)[] | null;
  invoice_ids_NotIn?: (number | null)[] | null;
  invoice_ids_Lt?: number | null;
  invoice_ids_Lte?: number | null;
  invoice_ids_Gt?: number | null;
  invoice_ids_Gte?: number | null;
  invoice_ids_IsBlank?: boolean | null;
  invoice_status?: InvoiceStatus | null;
  invoice_status_Not?: InvoiceStatus | null;
  invoice_status_In?: InvoiceStatus[] | null;
  invoice_status_NotIn?: InvoiceStatus[] | null;
  invoice_status_IsBlank?: boolean | null;
  picking_ids?: number | null;
  picking_ids_Not?: number | null;
  picking_ids_In?: (number | null)[] | null;
  picking_ids_NotIn?: (number | null)[] | null;
  picking_ids_Lt?: number | null;
  picking_ids_Lte?: number | null;
  picking_ids_Gt?: number | null;
  picking_ids_Gte?: number | null;
  picking_ids_IsBlank?: boolean | null;
  saleperson_id?: string | null;
  saleperson_id_Not?: string | null;
  saleperson_id_In?: (string | null)[] | null;
  saleperson_id_NotIn?: (string | null)[] | null;
  saleperson_id_Lt?: string | null;
  saleperson_id_Lte?: string | null;
  saleperson_id_Gt?: string | null;
  saleperson_id_Gte?: string | null;
  saleperson_id_Contains?: string | null;
  saleperson_id_NotContains?: string | null;
  saleperson_id_StartsWith?: string | null;
  saleperson_id_NotStartsWith?: string | null;
  saleperson_id_EndsWith?: string | null;
  saleperson_id_NotEndsWith?: string | null;
  saleperson_id_IsBlank?: boolean | null;
  saleperson_name?: string | null;
  saleperson_name_Not?: string | null;
  saleperson_name_In?: (string | null)[] | null;
  saleperson_name_NotIn?: (string | null)[] | null;
  saleperson_name_Lt?: string | null;
  saleperson_name_Lte?: string | null;
  saleperson_name_Gt?: string | null;
  saleperson_name_Gte?: string | null;
  saleperson_name_Contains?: string | null;
  saleperson_name_NotContains?: string | null;
  saleperson_name_StartsWith?: string | null;
  saleperson_name_NotStartsWith?: string | null;
  saleperson_name_EndsWith?: string | null;
  saleperson_name_NotEndsWith?: string | null;
  saleperson_name_IsBlank?: boolean | null;
  cashier_id?: string | null;
  cashier_id_Not?: string | null;
  cashier_id_In?: (string | null)[] | null;
  cashier_id_NotIn?: (string | null)[] | null;
  cashier_id_Lt?: string | null;
  cashier_id_Lte?: string | null;
  cashier_id_Gt?: string | null;
  cashier_id_Gte?: string | null;
  cashier_id_Contains?: string | null;
  cashier_id_NotContains?: string | null;
  cashier_id_StartsWith?: string | null;
  cashier_id_NotStartsWith?: string | null;
  cashier_id_EndsWith?: string | null;
  cashier_id_NotEndsWith?: string | null;
  cashier_id_IsBlank?: boolean | null;
  cashier_name?: string | null;
  cashier_name_Not?: string | null;
  cashier_name_In?: (string | null)[] | null;
  cashier_name_NotIn?: (string | null)[] | null;
  cashier_name_Lt?: string | null;
  cashier_name_Lte?: string | null;
  cashier_name_Gt?: string | null;
  cashier_name_Gte?: string | null;
  cashier_name_Contains?: string | null;
  cashier_name_NotContains?: string | null;
  cashier_name_StartsWith?: string | null;
  cashier_name_NotStartsWith?: string | null;
  cashier_name_EndsWith?: string | null;
  cashier_name_NotEndsWith?: string | null;
  cashier_name_IsBlank?: boolean | null;
  id?: string | null;
  id_Not?: string | null;
  id_In?: (string | null)[] | null;
  id_NotIn?: (string | null)[] | null;
  id_Lt?: string | null;
  id_Lte?: string | null;
  id_Gt?: string | null;
  id_Gte?: string | null;
  id_IsBlank?: boolean | null;
}

export interface StockMoveInput {
  id?: number | null;
  move_line_nosuggest_ids?: StockMoveLineInputList | null;
}

export interface StockMoveLineInput {
  id?: string | null;
  qty_done?: number | null;
  owner_id?: number | null;
  product_uom_id?: number | null;
  location_dest_id?: number | null;
  location_id?: number | null;
  reserved_uom_qty?: number | null;
  product_id?: number | null;
  lot_id?: number | null;
  lot_name?: string | null;
}

export interface StockMoveLineInputList {
  list?: StockMoveLineInput[] | null;
  deleted?: number[] | null;
}

export interface StockMoveWhereInput {
  date?: any | null;
  date_Not?: any | null;
  date_Lt?: any | null;
  date_Lte?: any | null;
  date_Gt?: any | null;
  date_Gte?: any | null;
  date_IsBlank?: any | null;
  aND?: StockMoveWhereInput[] | null;
  oR?: StockMoveWhereInput[] | null;
  product_id?: string | null;
  product_id_Not?: string | null;
  product_id_In?: (string | null)[] | null;
  product_id_NotIn?: (string | null)[] | null;
  product_id_Lt?: string | null;
  product_id_Lte?: string | null;
  product_id_Gt?: string | null;
  product_id_Gte?: string | null;
  product_id_Contains?: string | null;
  product_id_NotContains?: string | null;
  product_id_StartsWith?: string | null;
  product_id_NotStartsWith?: string | null;
  product_id_EndsWith?: string | null;
  product_id_NotEndsWith?: string | null;
  product_id_IsBlank?: boolean | null;
  product_id__id?: number | null;
  product_id__id_Not?: number | null;
  product_id__id_In?: (number | null)[] | null;
  product_id__id_NotIn?: (number | null)[] | null;
  product_id__id_Lt?: number | null;
  product_id__id_Lte?: number | null;
  product_id__id_Gt?: number | null;
  product_id__id_Gte?: number | null;
  product_id__id_IsBlank?: boolean | null;
  product_uom_qty?: number | null;
  product_uom_qty_Not?: number | null;
  product_uom_qty_In?: (number | null)[] | null;
  product_uom_qty_NotIn?: (number | null)[] | null;
  product_uom_qty_Lt?: number | null;
  product_uom_qty_Lte?: number | null;
  product_uom_qty_Gt?: number | null;
  product_uom_qty_Gte?: number | null;
  product_uom_qty_IsBlank?: boolean | null;
  quantity_done?: number | null;
  quantity_done_Not?: number | null;
  quantity_done_In?: (number | null)[] | null;
  quantity_done_NotIn?: (number | null)[] | null;
  quantity_done_Lt?: number | null;
  quantity_done_Lte?: number | null;
  quantity_done_Gt?: number | null;
  quantity_done_Gte?: number | null;
  quantity_done_IsBlank?: boolean | null;
  product_uom?: string | null;
  product_uom_Not?: string | null;
  product_uom_In?: (string | null)[] | null;
  product_uom_NotIn?: (string | null)[] | null;
  product_uom_Lt?: string | null;
  product_uom_Lte?: string | null;
  product_uom_Gt?: string | null;
  product_uom_Gte?: string | null;
  product_uom_Contains?: string | null;
  product_uom_NotContains?: string | null;
  product_uom_StartsWith?: string | null;
  product_uom_NotStartsWith?: string | null;
  product_uom_EndsWith?: string | null;
  product_uom_NotEndsWith?: string | null;
  product_uom_IsBlank?: boolean | null;
  product_uom__id?: number | null;
  product_uom__id_Not?: number | null;
  product_uom__id_In?: (number | null)[] | null;
  product_uom__id_NotIn?: (number | null)[] | null;
  product_uom__id_Lt?: number | null;
  product_uom__id_Lte?: number | null;
  product_uom__id_Gt?: number | null;
  product_uom__id_Gte?: number | null;
  product_uom__id_IsBlank?: boolean | null;
  location_id?: string | null;
  location_id_Not?: string | null;
  location_id_In?: (string | null)[] | null;
  location_id_NotIn?: (string | null)[] | null;
  location_id_Lt?: string | null;
  location_id_Lte?: string | null;
  location_id_Gt?: string | null;
  location_id_Gte?: string | null;
  location_id_Contains?: string | null;
  location_id_NotContains?: string | null;
  location_id_StartsWith?: string | null;
  location_id_NotStartsWith?: string | null;
  location_id_EndsWith?: string | null;
  location_id_NotEndsWith?: string | null;
  location_id_IsBlank?: boolean | null;
  location_id__id?: number | null;
  location_id__id_Not?: number | null;
  location_id__id_In?: (number | null)[] | null;
  location_id__id_NotIn?: (number | null)[] | null;
  location_id__id_Lt?: number | null;
  location_id__id_Lte?: number | null;
  location_id__id_Gt?: number | null;
  location_id__id_Gte?: number | null;
  location_id__id_IsBlank?: boolean | null;
  location_dest_id?: string | null;
  location_dest_id_Not?: string | null;
  location_dest_id_In?: (string | null)[] | null;
  location_dest_id_NotIn?: (string | null)[] | null;
  location_dest_id_Lt?: string | null;
  location_dest_id_Lte?: string | null;
  location_dest_id_Gt?: string | null;
  location_dest_id_Gte?: string | null;
  location_dest_id_Contains?: string | null;
  location_dest_id_NotContains?: string | null;
  location_dest_id_StartsWith?: string | null;
  location_dest_id_NotStartsWith?: string | null;
  location_dest_id_EndsWith?: string | null;
  location_dest_id_NotEndsWith?: string | null;
  location_dest_id_IsBlank?: boolean | null;
  location_dest_id__id?: number | null;
  location_dest_id__id_Not?: number | null;
  location_dest_id__id_In?: (number | null)[] | null;
  location_dest_id__id_NotIn?: (number | null)[] | null;
  location_dest_id__id_Lt?: number | null;
  location_dest_id__id_Lte?: number | null;
  location_dest_id__id_Gt?: number | null;
  location_dest_id__id_Gte?: number | null;
  location_dest_id__id_IsBlank?: boolean | null;
  procure_method?: ProcureMethod | null;
  procure_method_Not?: ProcureMethod | null;
  procure_method_In?: ProcureMethod[] | null;
  procure_method_NotIn?: ProcureMethod[] | null;
  procure_method_IsBlank?: boolean | null;
  date_expected?: any | null;
  date_expected_Not?: any | null;
  date_expected_Lt?: any | null;
  date_expected_Lte?: any | null;
  date_expected_Gt?: any | null;
  date_expected_Gte?: any | null;
  date_expected_IsBlank?: any | null;
  weight?: number | null;
  weight_Not?: number | null;
  weight_In?: (number | null)[] | null;
  weight_NotIn?: (number | null)[] | null;
  weight_Lt?: number | null;
  weight_Lte?: number | null;
  weight_Gt?: number | null;
  weight_Gte?: number | null;
  weight_IsBlank?: boolean | null;
  string_availability_info?: string | null;
  string_availability_info_Not?: string | null;
  string_availability_info_In?: (string | null)[] | null;
  string_availability_info_NotIn?: (string | null)[] | null;
  string_availability_info_Lt?: string | null;
  string_availability_info_Lte?: string | null;
  string_availability_info_Gt?: string | null;
  string_availability_info_Gte?: string | null;
  string_availability_info_Contains?: string | null;
  string_availability_info_NotContains?: string | null;
  string_availability_info_StartsWith?: string | null;
  string_availability_info_NotStartsWith?: string | null;
  string_availability_info_EndsWith?: string | null;
  string_availability_info_NotEndsWith?: string | null;
  string_availability_info_IsBlank?: boolean | null;
  availability?: number | null;
  availability_Not?: number | null;
  availability_In?: (number | null)[] | null;
  availability_NotIn?: (number | null)[] | null;
  availability_Lt?: number | null;
  availability_Lte?: number | null;
  availability_Gt?: number | null;
  availability_Gte?: number | null;
  availability_IsBlank?: boolean | null;
  reserved_availability?: number | null;
  reserved_availability_Not?: number | null;
  reserved_availability_In?: (number | null)[] | null;
  reserved_availability_NotIn?: (number | null)[] | null;
  reserved_availability_Lt?: number | null;
  reserved_availability_Lte?: number | null;
  reserved_availability_Gt?: number | null;
  reserved_availability_Gte?: number | null;
  reserved_availability_IsBlank?: boolean | null;
  product_qty?: number | null;
  product_qty_Not?: number | null;
  product_qty_In?: (number | null)[] | null;
  product_qty_NotIn?: (number | null)[] | null;
  product_qty_Lt?: number | null;
  product_qty_Lte?: number | null;
  product_qty_Gt?: number | null;
  product_qty_Gte?: number | null;
  product_qty_IsBlank?: boolean | null;
  backorder_id?: string | null;
  backorder_id_Not?: string | null;
  backorder_id_In?: (string | null)[] | null;
  backorder_id_NotIn?: (string | null)[] | null;
  backorder_id_Lt?: string | null;
  backorder_id_Lte?: string | null;
  backorder_id_Gt?: string | null;
  backorder_id_Gte?: string | null;
  backorder_id_Contains?: string | null;
  backorder_id_NotContains?: string | null;
  backorder_id_StartsWith?: string | null;
  backorder_id_NotStartsWith?: string | null;
  backorder_id_EndsWith?: string | null;
  backorder_id_NotEndsWith?: string | null;
  backorder_id_IsBlank?: boolean | null;
  backorder_id__id?: number | null;
  backorder_id__id_Not?: number | null;
  backorder_id__id_In?: (number | null)[] | null;
  backorder_id__id_NotIn?: (number | null)[] | null;
  backorder_id__id_Lt?: number | null;
  backorder_id__id_Lte?: number | null;
  backorder_id__id_Gt?: number | null;
  backorder_id__id_Gte?: number | null;
  backorder_id__id_IsBlank?: boolean | null;
  picking_id?: string | null;
  picking_id_Not?: string | null;
  picking_id_In?: (string | null)[] | null;
  picking_id_NotIn?: (string | null)[] | null;
  picking_id_Lt?: string | null;
  picking_id_Lte?: string | null;
  picking_id_Gt?: string | null;
  picking_id_Gte?: string | null;
  picking_id_Contains?: string | null;
  picking_id_NotContains?: string | null;
  picking_id_StartsWith?: string | null;
  picking_id_NotStartsWith?: string | null;
  picking_id_EndsWith?: string | null;
  picking_id_NotEndsWith?: string | null;
  picking_id_IsBlank?: boolean | null;
  picking_id__id?: number | null;
  picking_id__id_Not?: number | null;
  picking_id__id_In?: (number | null)[] | null;
  picking_id__id_NotIn?: (number | null)[] | null;
  picking_id__id_Lt?: number | null;
  picking_id__id_Lte?: number | null;
  picking_id__id_Gt?: number | null;
  picking_id__id_Gte?: number | null;
  picking_id__id_IsBlank?: boolean | null;
  has_move_lines?: boolean | null;
  is_quantity_done_editable?: boolean | null;
  is_initial_demand_editable?: boolean | null;
  note?: string | null;
  note_Not?: string | null;
  note_In?: (string | null)[] | null;
  note_NotIn?: (string | null)[] | null;
  note_Lt?: string | null;
  note_Lte?: string | null;
  note_Gt?: string | null;
  note_Gte?: string | null;
  note_Contains?: string | null;
  note_NotContains?: string | null;
  note_StartsWith?: string | null;
  note_NotStartsWith?: string | null;
  note_EndsWith?: string | null;
  note_NotEndsWith?: string | null;
  note_IsBlank?: boolean | null;
  state?: StockPickingState | null;
  state_Not?: StockPickingState | null;
  state_In?: StockPickingState[] | null;
  state_NotIn?: StockPickingState[] | null;
  state_IsBlank?: boolean | null;
  move_line_ids?: number | null;
  move_line_ids_Not?: number | null;
  move_line_ids_In?: (number | null)[] | null;
  move_line_ids_NotIn?: (number | null)[] | null;
  move_line_ids_Lt?: number | null;
  move_line_ids_Lte?: number | null;
  move_line_ids_Gt?: number | null;
  move_line_ids_Gte?: number | null;
  move_line_ids_IsBlank?: boolean | null;
  has_tracking?: StockTrackingType | null;
  has_tracking_Not?: StockTrackingType | null;
  has_tracking_In?: StockTrackingType[] | null;
  has_tracking_NotIn?: StockTrackingType[] | null;
  has_tracking_IsBlank?: boolean | null;
  show_operations?: boolean | null;
  sale_line_id?: number | null;
  sale_line_id_Not?: number | null;
  sale_line_id_In?: (number | null)[] | null;
  sale_line_id_NotIn?: (number | null)[] | null;
  sale_line_id_Lt?: number | null;
  sale_line_id_Lte?: number | null;
  sale_line_id_Gt?: number | null;
  sale_line_id_Gte?: number | null;
  sale_line_id_IsBlank?: boolean | null;
  partner_id?: string | null;
  partner_id_Not?: string | null;
  partner_id_In?: (string | null)[] | null;
  partner_id_NotIn?: (string | null)[] | null;
  partner_id_Lt?: string | null;
  partner_id_Lte?: string | null;
  partner_id_Gt?: string | null;
  partner_id_Gte?: string | null;
  partner_id_Contains?: string | null;
  partner_id_NotContains?: string | null;
  partner_id_StartsWith?: string | null;
  partner_id_NotStartsWith?: string | null;
  partner_id_EndsWith?: string | null;
  partner_id_NotEndsWith?: string | null;
  partner_id_IsBlank?: boolean | null;
  partner_id__id?: number | null;
  partner_id__id_Not?: number | null;
  partner_id__id_In?: (number | null)[] | null;
  partner_id__id_NotIn?: (number | null)[] | null;
  partner_id__id_Lt?: number | null;
  partner_id__id_Lte?: number | null;
  partner_id__id_Gt?: number | null;
  partner_id__id_Gte?: number | null;
  partner_id__id_IsBlank?: boolean | null;
  id?: string | null;
  id_Not?: string | null;
  id_In?: (string | null)[] | null;
  id_NotIn?: (string | null)[] | null;
  id_Lt?: string | null;
  id_Lte?: string | null;
  id_Gt?: string | null;
  id_Gte?: string | null;
  id_IsBlank?: boolean | null;
}

export interface StockPickingWhereInput {
  name?: string | null;
  name_Not?: string | null;
  name_In?: (string | null)[] | null;
  name_NotIn?: (string | null)[] | null;
  name_Lt?: string | null;
  name_Lte?: string | null;
  name_Gt?: string | null;
  name_Gte?: string | null;
  name_Contains?: string | null;
  name_NotContains?: string | null;
  name_StartsWith?: string | null;
  name_NotStartsWith?: string | null;
  name_EndsWith?: string | null;
  name_NotEndsWith?: string | null;
  name_IsBlank?: boolean | null;
  aND?: StockPickingWhereInput[] | null;
  oR?: StockPickingWhereInput[] | null;
  location_id?: string | null;
  location_id_Not?: string | null;
  location_id_In?: (string | null)[] | null;
  location_id_NotIn?: (string | null)[] | null;
  location_id_Lt?: string | null;
  location_id_Lte?: string | null;
  location_id_Gt?: string | null;
  location_id_Gte?: string | null;
  location_id_Contains?: string | null;
  location_id_NotContains?: string | null;
  location_id_StartsWith?: string | null;
  location_id_NotStartsWith?: string | null;
  location_id_EndsWith?: string | null;
  location_id_NotEndsWith?: string | null;
  location_id_IsBlank?: boolean | null;
  location_id__id?: number | null;
  location_id__id_Not?: number | null;
  location_id__id_In?: (number | null)[] | null;
  location_id__id_NotIn?: (number | null)[] | null;
  location_id__id_Lt?: number | null;
  location_id__id_Lte?: number | null;
  location_id__id_Gt?: number | null;
  location_id__id_Gte?: number | null;
  location_id__id_IsBlank?: boolean | null;
  location_dest_id?: string | null;
  location_dest_id_Not?: string | null;
  location_dest_id_In?: (string | null)[] | null;
  location_dest_id_NotIn?: (string | null)[] | null;
  location_dest_id_Lt?: string | null;
  location_dest_id_Lte?: string | null;
  location_dest_id_Gt?: string | null;
  location_dest_id_Gte?: string | null;
  location_dest_id_Contains?: string | null;
  location_dest_id_NotContains?: string | null;
  location_dest_id_StartsWith?: string | null;
  location_dest_id_NotStartsWith?: string | null;
  location_dest_id_EndsWith?: string | null;
  location_dest_id_NotEndsWith?: string | null;
  location_dest_id_IsBlank?: boolean | null;
  location_dest_id__id?: number | null;
  location_dest_id__id_Not?: number | null;
  location_dest_id__id_In?: (number | null)[] | null;
  location_dest_id__id_NotIn?: (number | null)[] | null;
  location_dest_id__id_Lt?: number | null;
  location_dest_id__id_Lte?: number | null;
  location_dest_id__id_Gt?: number | null;
  location_dest_id__id_Gte?: number | null;
  location_dest_id__id_IsBlank?: boolean | null;
  scheduled_date?: any | null;
  scheduled_date_Not?: any | null;
  scheduled_date_Lt?: any | null;
  scheduled_date_Lte?: any | null;
  scheduled_date_Gt?: any | null;
  scheduled_date_Gte?: any | null;
  scheduled_date_IsBlank?: any | null;
  date_done?: any | null;
  date_done_Not?: any | null;
  date_done_Lt?: any | null;
  date_done_Lte?: any | null;
  date_done_Gt?: any | null;
  date_done_Gte?: any | null;
  date_done_IsBlank?: any | null;
  origin?: string | null;
  origin_Not?: string | null;
  origin_In?: (string | null)[] | null;
  origin_NotIn?: (string | null)[] | null;
  origin_Lt?: string | null;
  origin_Lte?: string | null;
  origin_Gt?: string | null;
  origin_Gte?: string | null;
  origin_Contains?: string | null;
  origin_NotContains?: string | null;
  origin_StartsWith?: string | null;
  origin_NotStartsWith?: string | null;
  origin_EndsWith?: string | null;
  origin_NotEndsWith?: string | null;
  origin_IsBlank?: boolean | null;
  partner_id?: string | null;
  partner_id_Not?: string | null;
  partner_id_In?: (string | null)[] | null;
  partner_id_NotIn?: (string | null)[] | null;
  partner_id_Lt?: string | null;
  partner_id_Lte?: string | null;
  partner_id_Gt?: string | null;
  partner_id_Gte?: string | null;
  partner_id_Contains?: string | null;
  partner_id_NotContains?: string | null;
  partner_id_StartsWith?: string | null;
  partner_id_NotStartsWith?: string | null;
  partner_id_EndsWith?: string | null;
  partner_id_NotEndsWith?: string | null;
  partner_id_IsBlank?: boolean | null;
  partner_id__id?: number | null;
  partner_id__id_Not?: number | null;
  partner_id__id_In?: (number | null)[] | null;
  partner_id__id_NotIn?: (number | null)[] | null;
  partner_id__id_Lt?: number | null;
  partner_id__id_Lte?: number | null;
  partner_id__id_Gt?: number | null;
  partner_id__id_Gte?: number | null;
  partner_id__id_IsBlank?: boolean | null;
  picking_type_id?: string | null;
  picking_type_id_Not?: string | null;
  picking_type_id_In?: (string | null)[] | null;
  picking_type_id_NotIn?: (string | null)[] | null;
  picking_type_id_Lt?: string | null;
  picking_type_id_Lte?: string | null;
  picking_type_id_Gt?: string | null;
  picking_type_id_Gte?: string | null;
  picking_type_id_Contains?: string | null;
  picking_type_id_NotContains?: string | null;
  picking_type_id_StartsWith?: string | null;
  picking_type_id_NotStartsWith?: string | null;
  picking_type_id_EndsWith?: string | null;
  picking_type_id_NotEndsWith?: string | null;
  picking_type_id_IsBlank?: boolean | null;
  picking_type_id__id?: number | null;
  picking_type_id__id_Not?: number | null;
  picking_type_id__id_In?: (number | null)[] | null;
  picking_type_id__id_NotIn?: (number | null)[] | null;
  picking_type_id__id_Lt?: number | null;
  picking_type_id__id_Lte?: number | null;
  picking_type_id__id_Gt?: number | null;
  picking_type_id__id_Gte?: number | null;
  picking_type_id__id_IsBlank?: boolean | null;
  owner_id?: string | null;
  owner_id_Not?: string | null;
  owner_id_In?: (string | null)[] | null;
  owner_id_NotIn?: (string | null)[] | null;
  owner_id_Lt?: string | null;
  owner_id_Lte?: string | null;
  owner_id_Gt?: string | null;
  owner_id_Gte?: string | null;
  owner_id_Contains?: string | null;
  owner_id_NotContains?: string | null;
  owner_id_StartsWith?: string | null;
  owner_id_NotStartsWith?: string | null;
  owner_id_EndsWith?: string | null;
  owner_id_NotEndsWith?: string | null;
  owner_id_IsBlank?: boolean | null;
  owner_id__id?: number | null;
  owner_id__id_Not?: number | null;
  owner_id__id_In?: (number | null)[] | null;
  owner_id__id_NotIn?: (number | null)[] | null;
  owner_id__id_Lt?: number | null;
  owner_id__id_Lte?: number | null;
  owner_id__id_Gt?: number | null;
  owner_id__id_Gte?: number | null;
  owner_id__id_IsBlank?: boolean | null;
  backorder_id?: string | null;
  backorder_id_Not?: string | null;
  backorder_id_In?: (string | null)[] | null;
  backorder_id_NotIn?: (string | null)[] | null;
  backorder_id_Lt?: string | null;
  backorder_id_Lte?: string | null;
  backorder_id_Gt?: string | null;
  backorder_id_Gte?: string | null;
  backorder_id_Contains?: string | null;
  backorder_id_NotContains?: string | null;
  backorder_id_StartsWith?: string | null;
  backorder_id_NotStartsWith?: string | null;
  backorder_id_EndsWith?: string | null;
  backorder_id_NotEndsWith?: string | null;
  backorder_id_IsBlank?: boolean | null;
  backorder_id__id?: number | null;
  backorder_id__id_Not?: number | null;
  backorder_id__id_In?: (number | null)[] | null;
  backorder_id__id_NotIn?: (number | null)[] | null;
  backorder_id__id_Lt?: number | null;
  backorder_id__id_Lte?: number | null;
  backorder_id__id_Gt?: number | null;
  backorder_id__id_Gte?: number | null;
  backorder_id__id_IsBlank?: boolean | null;
  state?: StockPickingState | null;
  state_Not?: StockPickingState | null;
  state_In?: StockPickingState[] | null;
  state_NotIn?: StockPickingState[] | null;
  state_IsBlank?: boolean | null;
  show_check_availability?: boolean | null;
  show_validate?: boolean | null;
  purchase_id?: number | null;
  purchase_id_Not?: number | null;
  purchase_id_In?: (number | null)[] | null;
  purchase_id_NotIn?: (number | null)[] | null;
  purchase_id_Lt?: number | null;
  purchase_id_Lte?: number | null;
  purchase_id_Gt?: number | null;
  purchase_id_Gte?: number | null;
  purchase_id_IsBlank?: boolean | null;
  sale_id?: number | null;
  sale_id_Not?: number | null;
  sale_id_In?: (number | null)[] | null;
  sale_id_NotIn?: (number | null)[] | null;
  sale_id_Lt?: number | null;
  sale_id_Lte?: number | null;
  sale_id_Gt?: number | null;
  sale_id_Gte?: number | null;
  sale_id_IsBlank?: boolean | null;
  move_type?: StockPickingShippingPolicy | null;
  move_type_Not?: StockPickingShippingPolicy | null;
  move_type_In?: StockPickingShippingPolicy[] | null;
  move_type_NotIn?: StockPickingShippingPolicy[] | null;
  move_type_IsBlank?: boolean | null;
  picking_type_code?: StockPickingType | null;
  picking_type_code_Not?: StockPickingType | null;
  picking_type_code_In?: StockPickingType[] | null;
  picking_type_code_NotIn?: StockPickingType[] | null;
  picking_type_code_IsBlank?: boolean | null;
  immediate_transfer?: boolean | null;
  id?: string | null;
  id_Not?: string | null;
  id_In?: (string | null)[] | null;
  id_NotIn?: (string | null)[] | null;
  id_Lt?: string | null;
  id_Lte?: string | null;
  id_Gt?: string | null;
  id_Gte?: string | null;
  id_IsBlank?: boolean | null;
}

export interface StockTransactionHistoryWhereInput {
  id?: number | null;
  id_Not?: number | null;
  id_In?: (number | null)[] | null;
  id_NotIn?: (number | null)[] | null;
  id_Lt?: number | null;
  id_Lte?: number | null;
  id_Gt?: number | null;
  id_Gte?: number | null;
  id_IsBlank?: boolean | null;
  aND?: StockTransactionHistoryWhereInput[] | null;
  oR?: StockTransactionHistoryWhereInput[] | null;
  product_id?: number | null;
  product_id_Not?: number | null;
  product_id_In?: (number | null)[] | null;
  product_id_NotIn?: (number | null)[] | null;
  product_id_Lt?: number | null;
  product_id_Lte?: number | null;
  product_id_Gt?: number | null;
  product_id_Gte?: number | null;
  product_id_IsBlank?: boolean | null;
  default_code?: string | null;
  default_code_Not?: string | null;
  default_code_In?: (string | null)[] | null;
  default_code_NotIn?: (string | null)[] | null;
  default_code_Lt?: string | null;
  default_code_Lte?: string | null;
  default_code_Gt?: string | null;
  default_code_Gte?: string | null;
  default_code_Contains?: string | null;
  default_code_NotContains?: string | null;
  default_code_StartsWith?: string | null;
  default_code_NotStartsWith?: string | null;
  default_code_EndsWith?: string | null;
  default_code_NotEndsWith?: string | null;
  default_code_IsBlank?: boolean | null;
  search_code?: string | null;
  search_code_Not?: string | null;
  search_code_In?: (string | null)[] | null;
  search_code_NotIn?: (string | null)[] | null;
  search_code_Lt?: string | null;
  search_code_Lte?: string | null;
  search_code_Gt?: string | null;
  search_code_Gte?: string | null;
  search_code_Contains?: string | null;
  search_code_NotContains?: string | null;
  search_code_StartsWith?: string | null;
  search_code_NotStartsWith?: string | null;
  search_code_EndsWith?: string | null;
  search_code_NotEndsWith?: string | null;
  search_code_IsBlank?: boolean | null;
  product_name?: string | null;
  product_name_Not?: string | null;
  product_name_In?: (string | null)[] | null;
  product_name_NotIn?: (string | null)[] | null;
  product_name_Lt?: string | null;
  product_name_Lte?: string | null;
  product_name_Gt?: string | null;
  product_name_Gte?: string | null;
  product_name_Contains?: string | null;
  product_name_NotContains?: string | null;
  product_name_StartsWith?: string | null;
  product_name_NotStartsWith?: string | null;
  product_name_EndsWith?: string | null;
  product_name_NotEndsWith?: string | null;
  product_name_IsBlank?: boolean | null;
  sale_zone?: string | null;
  sale_zone_Not?: string | null;
  sale_zone_In?: (string | null)[] | null;
  sale_zone_NotIn?: (string | null)[] | null;
  sale_zone_Lt?: string | null;
  sale_zone_Lte?: string | null;
  sale_zone_Gt?: string | null;
  sale_zone_Gte?: string | null;
  sale_zone_Contains?: string | null;
  sale_zone_NotContains?: string | null;
  sale_zone_StartsWith?: string | null;
  sale_zone_NotStartsWith?: string | null;
  sale_zone_EndsWith?: string | null;
  sale_zone_NotEndsWith?: string | null;
  sale_zone_IsBlank?: boolean | null;
  brand?: string | null;
  brand_Not?: string | null;
  brand_In?: (string | null)[] | null;
  brand_NotIn?: (string | null)[] | null;
  brand_Lt?: string | null;
  brand_Lte?: string | null;
  brand_Gt?: string | null;
  brand_Gte?: string | null;
  brand_Contains?: string | null;
  brand_NotContains?: string | null;
  brand_StartsWith?: string | null;
  brand_NotStartsWith?: string | null;
  brand_EndsWith?: string | null;
  brand_NotEndsWith?: string | null;
  brand_IsBlank?: boolean | null;
  category?: string | null;
  category_Not?: string | null;
  category_In?: (string | null)[] | null;
  category_NotIn?: (string | null)[] | null;
  category_Lt?: string | null;
  category_Lte?: string | null;
  category_Gt?: string | null;
  category_Gte?: string | null;
  category_Contains?: string | null;
  category_NotContains?: string | null;
  category_StartsWith?: string | null;
  category_NotStartsWith?: string | null;
  category_EndsWith?: string | null;
  category_NotEndsWith?: string | null;
  category_IsBlank?: boolean | null;
  location_id?: number | null;
  location_id_Not?: number | null;
  location_id_In?: (number | null)[] | null;
  location_id_NotIn?: (number | null)[] | null;
  location_id_Lt?: number | null;
  location_id_Lte?: number | null;
  location_id_Gt?: number | null;
  location_id_Gte?: number | null;
  location_id_IsBlank?: boolean | null;
  location_name?: string | null;
  location_name_Not?: string | null;
  location_name_In?: (string | null)[] | null;
  location_name_NotIn?: (string | null)[] | null;
  location_name_Lt?: string | null;
  location_name_Lte?: string | null;
  location_name_Gt?: string | null;
  location_name_Gte?: string | null;
  location_name_Contains?: string | null;
  location_name_NotContains?: string | null;
  location_name_StartsWith?: string | null;
  location_name_NotStartsWith?: string | null;
  location_name_EndsWith?: string | null;
  location_name_NotEndsWith?: string | null;
  location_name_IsBlank?: boolean | null;
  name?: string | null;
  name_Not?: string | null;
  name_In?: (string | null)[] | null;
  name_NotIn?: (string | null)[] | null;
  name_Lt?: string | null;
  name_Lte?: string | null;
  name_Gt?: string | null;
  name_Gte?: string | null;
  name_Contains?: string | null;
  name_NotContains?: string | null;
  name_StartsWith?: string | null;
  name_NotStartsWith?: string | null;
  name_EndsWith?: string | null;
  name_NotEndsWith?: string | null;
  name_IsBlank?: boolean | null;
  origin?: string | null;
  origin_Not?: string | null;
  origin_In?: (string | null)[] | null;
  origin_NotIn?: (string | null)[] | null;
  origin_Lt?: string | null;
  origin_Lte?: string | null;
  origin_Gt?: string | null;
  origin_Gte?: string | null;
  origin_Contains?: string | null;
  origin_NotContains?: string | null;
  origin_StartsWith?: string | null;
  origin_NotStartsWith?: string | null;
  origin_EndsWith?: string | null;
  origin_NotEndsWith?: string | null;
  origin_IsBlank?: boolean | null;
  ob?: number | null;
  ob_Not?: number | null;
  ob_In?: (number | null)[] | null;
  ob_NotIn?: (number | null)[] | null;
  ob_Lt?: number | null;
  ob_Lte?: number | null;
  ob_Gt?: number | null;
  ob_Gte?: number | null;
  ob_IsBlank?: boolean | null;
  incoming?: number | null;
  incoming_Not?: number | null;
  incoming_In?: (number | null)[] | null;
  incoming_NotIn?: (number | null)[] | null;
  incoming_Lt?: number | null;
  incoming_Lte?: number | null;
  incoming_Gt?: number | null;
  incoming_Gte?: number | null;
  incoming_IsBlank?: boolean | null;
  outgoing?: number | null;
  outgoing_Not?: number | null;
  outgoing_In?: (number | null)[] | null;
  outgoing_NotIn?: (number | null)[] | null;
  outgoing_Lt?: number | null;
  outgoing_Lte?: number | null;
  outgoing_Gt?: number | null;
  outgoing_Gte?: number | null;
  outgoing_IsBlank?: boolean | null;
  transfer_in?: number | null;
  transfer_in_Not?: number | null;
  transfer_in_In?: (number | null)[] | null;
  transfer_in_NotIn?: (number | null)[] | null;
  transfer_in_Lt?: number | null;
  transfer_in_Lte?: number | null;
  transfer_in_Gt?: number | null;
  transfer_in_Gte?: number | null;
  transfer_in_IsBlank?: boolean | null;
  transfer_out?: number | null;
  transfer_out_Not?: number | null;
  transfer_out_In?: (number | null)[] | null;
  transfer_out_NotIn?: (number | null)[] | null;
  transfer_out_Lt?: number | null;
  transfer_out_Lte?: number | null;
  transfer_out_Gt?: number | null;
  transfer_out_Gte?: number | null;
  transfer_out_IsBlank?: boolean | null;
  scrap?: number | null;
  scrap_Not?: number | null;
  scrap_In?: (number | null)[] | null;
  scrap_NotIn?: (number | null)[] | null;
  scrap_Lt?: number | null;
  scrap_Lte?: number | null;
  scrap_Gt?: number | null;
  scrap_Gte?: number | null;
  scrap_IsBlank?: boolean | null;
  adjustment_in?: number | null;
  adjustment_in_Not?: number | null;
  adjustment_in_In?: (number | null)[] | null;
  adjustment_in_NotIn?: (number | null)[] | null;
  adjustment_in_Lt?: number | null;
  adjustment_in_Lte?: number | null;
  adjustment_in_Gt?: number | null;
  adjustment_in_Gte?: number | null;
  adjustment_in_IsBlank?: boolean | null;
  adjustment_out?: number | null;
  adjustment_out_Not?: number | null;
  adjustment_out_In?: (number | null)[] | null;
  adjustment_out_NotIn?: (number | null)[] | null;
  adjustment_out_Lt?: number | null;
  adjustment_out_Lte?: number | null;
  adjustment_out_Gt?: number | null;
  adjustment_out_Gte?: number | null;
  adjustment_out_IsBlank?: boolean | null;
  cb?: number | null;
  cb_Not?: number | null;
  cb_In?: (number | null)[] | null;
  cb_NotIn?: (number | null)[] | null;
  cb_Lt?: number | null;
  cb_Lte?: number | null;
  cb_Gt?: number | null;
  cb_Gte?: number | null;
  cb_IsBlank?: boolean | null;
  company_id?: number | null;
  company_id_Not?: number | null;
  company_id_In?: (number | null)[] | null;
  company_id_NotIn?: (number | null)[] | null;
  company_id_Lt?: number | null;
  company_id_Lte?: number | null;
  company_id_Gt?: number | null;
  company_id_Gte?: number | null;
  company_id_IsBlank?: boolean | null;
  date?: any | null;
  date_Not?: any | null;
  date_Lt?: any | null;
  date_Lte?: any | null;
  date_Gt?: any | null;
  date_Gte?: any | null;
  date_IsBlank?: any | null;
  counterpart_location_id?: number | null;
  counterpart_location_id_Not?: number | null;
  counterpart_location_id_In?: (number | null)[] | null;
  counterpart_location_id_NotIn?: (number | null)[] | null;
  counterpart_location_id_Lt?: number | null;
  counterpart_location_id_Lte?: number | null;
  counterpart_location_id_Gt?: number | null;
  counterpart_location_id_Gte?: number | null;
  counterpart_location_id_IsBlank?: boolean | null;
  counterpart_location_name?: string | null;
  counterpart_location_name_Not?: string | null;
  counterpart_location_name_In?: (string | null)[] | null;
  counterpart_location_name_NotIn?: (string | null)[] | null;
  counterpart_location_name_Lt?: string | null;
  counterpart_location_name_Lte?: string | null;
  counterpart_location_name_Gt?: string | null;
  counterpart_location_name_Gte?: string | null;
  counterpart_location_name_Contains?: string | null;
  counterpart_location_name_NotContains?: string | null;
  counterpart_location_name_StartsWith?: string | null;
  counterpart_location_name_NotStartsWith?: string | null;
  counterpart_location_name_EndsWith?: string | null;
  counterpart_location_name_NotEndsWith?: string | null;
  counterpart_location_name_IsBlank?: boolean | null;
  ccb?: number | null;
  ccb_Not?: number | null;
  ccb_In?: (number | null)[] | null;
  ccb_NotIn?: (number | null)[] | null;
  ccb_Lt?: number | null;
  ccb_Lte?: number | null;
  ccb_Gt?: number | null;
  ccb_Gte?: number | null;
  ccb_IsBlank?: boolean | null;
}

export interface StockValuationLayerOutWhereInput {
  quantity?: number | null;
  quantity_Not?: number | null;
  quantity_In?: (number | null)[] | null;
  quantity_NotIn?: (number | null)[] | null;
  quantity_Lt?: number | null;
  quantity_Lte?: number | null;
  quantity_Gt?: number | null;
  quantity_Gte?: number | null;
  quantity_IsBlank?: boolean | null;
  aND?: StockValuationLayerOutWhereInput[] | null;
  oR?: StockValuationLayerOutWhereInput[] | null;
  origin_id?: string | null;
  origin_id_Not?: string | null;
  origin_id_In?: (string | null)[] | null;
  origin_id_NotIn?: (string | null)[] | null;
  origin_id_Lt?: string | null;
  origin_id_Lte?: string | null;
  origin_id_Gt?: string | null;
  origin_id_Gte?: string | null;
  origin_id_Contains?: string | null;
  origin_id_NotContains?: string | null;
  origin_id_StartsWith?: string | null;
  origin_id_NotStartsWith?: string | null;
  origin_id_EndsWith?: string | null;
  origin_id_NotEndsWith?: string | null;
  origin_id_IsBlank?: boolean | null;
  origin_id__id?: number | null;
  origin_id__id_Not?: number | null;
  origin_id__id_In?: (number | null)[] | null;
  origin_id__id_NotIn?: (number | null)[] | null;
  origin_id__id_Lt?: number | null;
  origin_id__id_Lte?: number | null;
  origin_id__id_Gt?: number | null;
  origin_id__id_Gte?: number | null;
  origin_id__id_IsBlank?: boolean | null;
  outgoing_id?: string | null;
  outgoing_id_Not?: string | null;
  outgoing_id_In?: (string | null)[] | null;
  outgoing_id_NotIn?: (string | null)[] | null;
  outgoing_id_Lt?: string | null;
  outgoing_id_Lte?: string | null;
  outgoing_id_Gt?: string | null;
  outgoing_id_Gte?: string | null;
  outgoing_id_Contains?: string | null;
  outgoing_id_NotContains?: string | null;
  outgoing_id_StartsWith?: string | null;
  outgoing_id_NotStartsWith?: string | null;
  outgoing_id_EndsWith?: string | null;
  outgoing_id_NotEndsWith?: string | null;
  outgoing_id_IsBlank?: boolean | null;
  outgoing_id__id?: number | null;
  outgoing_id__id_Not?: number | null;
  outgoing_id__id_In?: (number | null)[] | null;
  outgoing_id__id_NotIn?: (number | null)[] | null;
  outgoing_id__id_Lt?: number | null;
  outgoing_id__id_Lte?: number | null;
  outgoing_id__id_Gt?: number | null;
  outgoing_id__id_Gte?: number | null;
  outgoing_id__id_IsBlank?: boolean | null;
  id?: string | null;
  id_Not?: string | null;
  id_In?: (string | null)[] | null;
  id_NotIn?: (string | null)[] | null;
  id_Lt?: string | null;
  id_Lte?: string | null;
  id_Gt?: string | null;
  id_Gte?: string | null;
  id_IsBlank?: boolean | null;
}

export interface StockValuationLayerReportWhereInput {
  id?: string | null;
  id_Not?: string | null;
  id_In?: (string | null)[] | null;
  id_NotIn?: (string | null)[] | null;
  id_Lt?: string | null;
  id_Lte?: string | null;
  id_Gt?: string | null;
  id_Gte?: string | null;
  id_IsBlank?: boolean | null;
  aND?: StockValuationLayerReportWhereInput[] | null;
  oR?: StockValuationLayerReportWhereInput[] | null;
  create_date?: any | null;
  create_date_Not?: any | null;
  create_date_Lt?: any | null;
  create_date_Lte?: any | null;
  create_date_Gt?: any | null;
  create_date_Gte?: any | null;
  create_date_IsBlank?: any | null;
  create_time?: any | null;
  create_time_Not?: any | null;
  create_time_Lt?: any | null;
  create_time_Lte?: any | null;
  create_time_Gt?: any | null;
  create_time_Gte?: any | null;
  create_time_IsBlank?: any | null;
  product_id?: number | null;
  product_id_Not?: number | null;
  product_id_In?: (number | null)[] | null;
  product_id_NotIn?: (number | null)[] | null;
  product_id_Lt?: number | null;
  product_id_Lte?: number | null;
  product_id_Gt?: number | null;
  product_id_Gte?: number | null;
  product_id_IsBlank?: boolean | null;
  default_code?: string | null;
  default_code_Not?: string | null;
  default_code_In?: (string | null)[] | null;
  default_code_NotIn?: (string | null)[] | null;
  default_code_Lt?: string | null;
  default_code_Lte?: string | null;
  default_code_Gt?: string | null;
  default_code_Gte?: string | null;
  default_code_Contains?: string | null;
  default_code_NotContains?: string | null;
  default_code_StartsWith?: string | null;
  default_code_NotStartsWith?: string | null;
  default_code_EndsWith?: string | null;
  default_code_NotEndsWith?: string | null;
  default_code_IsBlank?: boolean | null;
  name?: string | null;
  name_Not?: string | null;
  name_In?: (string | null)[] | null;
  name_NotIn?: (string | null)[] | null;
  name_Lt?: string | null;
  name_Lte?: string | null;
  name_Gt?: string | null;
  name_Gte?: string | null;
  name_Contains?: string | null;
  name_NotContains?: string | null;
  name_StartsWith?: string | null;
  name_NotStartsWith?: string | null;
  name_EndsWith?: string | null;
  name_NotEndsWith?: string | null;
  name_IsBlank?: boolean | null;
  brand?: string | null;
  brand_Not?: string | null;
  brand_In?: (string | null)[] | null;
  brand_NotIn?: (string | null)[] | null;
  brand_Lt?: string | null;
  brand_Lte?: string | null;
  brand_Gt?: string | null;
  brand_Gte?: string | null;
  brand_Contains?: string | null;
  brand_NotContains?: string | null;
  brand_StartsWith?: string | null;
  brand_NotStartsWith?: string | null;
  brand_EndsWith?: string | null;
  brand_NotEndsWith?: string | null;
  brand_IsBlank?: boolean | null;
  salezone?: string | null;
  salezone_Not?: string | null;
  salezone_In?: (string | null)[] | null;
  salezone_NotIn?: (string | null)[] | null;
  salezone_Lt?: string | null;
  salezone_Lte?: string | null;
  salezone_Gt?: string | null;
  salezone_Gte?: string | null;
  salezone_Contains?: string | null;
  salezone_NotContains?: string | null;
  salezone_StartsWith?: string | null;
  salezone_NotStartsWith?: string | null;
  salezone_EndsWith?: string | null;
  salezone_NotEndsWith?: string | null;
  salezone_IsBlank?: boolean | null;
  partner_id?: number | null;
  partner_id_Not?: number | null;
  partner_id_In?: (number | null)[] | null;
  partner_id_NotIn?: (number | null)[] | null;
  partner_id_Lt?: number | null;
  partner_id_Lte?: number | null;
  partner_id_Gt?: number | null;
  partner_id_Gte?: number | null;
  partner_id_IsBlank?: boolean | null;
  supplier_name?: string | null;
  supplier_name_Not?: string | null;
  supplier_name_In?: (string | null)[] | null;
  supplier_name_NotIn?: (string | null)[] | null;
  supplier_name_Lt?: string | null;
  supplier_name_Lte?: string | null;
  supplier_name_Gt?: string | null;
  supplier_name_Gte?: string | null;
  supplier_name_Contains?: string | null;
  supplier_name_NotContains?: string | null;
  supplier_name_StartsWith?: string | null;
  supplier_name_NotStartsWith?: string | null;
  supplier_name_EndsWith?: string | null;
  supplier_name_NotEndsWith?: string | null;
  supplier_name_IsBlank?: boolean | null;
  supplier_category?: string | null;
  supplier_category_Not?: string | null;
  supplier_category_In?: (string | null)[] | null;
  supplier_category_NotIn?: (string | null)[] | null;
  supplier_category_Lt?: string | null;
  supplier_category_Lte?: string | null;
  supplier_category_Gt?: string | null;
  supplier_category_Gte?: string | null;
  supplier_category_Contains?: string | null;
  supplier_category_NotContains?: string | null;
  supplier_category_StartsWith?: string | null;
  supplier_category_NotStartsWith?: string | null;
  supplier_category_EndsWith?: string | null;
  supplier_category_NotEndsWith?: string | null;
  supplier_category_IsBlank?: boolean | null;
  description?: string | null;
  description_Not?: string | null;
  description_In?: (string | null)[] | null;
  description_NotIn?: (string | null)[] | null;
  description_Lt?: string | null;
  description_Lte?: string | null;
  description_Gt?: string | null;
  description_Gte?: string | null;
  description_Contains?: string | null;
  description_NotContains?: string | null;
  description_StartsWith?: string | null;
  description_NotStartsWith?: string | null;
  description_EndsWith?: string | null;
  description_NotEndsWith?: string | null;
  description_IsBlank?: boolean | null;
  uom?: string | null;
  uom_Not?: string | null;
  uom_In?: (string | null)[] | null;
  uom_NotIn?: (string | null)[] | null;
  uom_Lt?: string | null;
  uom_Lte?: string | null;
  uom_Gt?: string | null;
  uom_Gte?: string | null;
  uom_Contains?: string | null;
  uom_NotContains?: string | null;
  uom_StartsWith?: string | null;
  uom_NotStartsWith?: string | null;
  uom_EndsWith?: string | null;
  uom_NotEndsWith?: string | null;
  uom_IsBlank?: boolean | null;
  stock_move_id?: number | null;
  stock_move_id_Not?: number | null;
  stock_move_id_In?: (number | null)[] | null;
  stock_move_id_NotIn?: (number | null)[] | null;
  stock_move_id_Lt?: number | null;
  stock_move_id_Lte?: number | null;
  stock_move_id_Gt?: number | null;
  stock_move_id_Gte?: number | null;
  stock_move_id_IsBlank?: boolean | null;
  stock_move?: string | null;
  stock_move_Not?: string | null;
  stock_move_In?: (string | null)[] | null;
  stock_move_NotIn?: (string | null)[] | null;
  stock_move_Lt?: string | null;
  stock_move_Lte?: string | null;
  stock_move_Gt?: string | null;
  stock_move_Gte?: string | null;
  stock_move_Contains?: string | null;
  stock_move_NotContains?: string | null;
  stock_move_StartsWith?: string | null;
  stock_move_NotStartsWith?: string | null;
  stock_move_EndsWith?: string | null;
  stock_move_NotEndsWith?: string | null;
  stock_move_IsBlank?: boolean | null;
  remaining_qty?: number | null;
  remaining_qty_Not?: number | null;
  remaining_qty_In?: (number | null)[] | null;
  remaining_qty_NotIn?: (number | null)[] | null;
  remaining_qty_Lt?: number | null;
  remaining_qty_Lte?: number | null;
  remaining_qty_Gt?: number | null;
  remaining_qty_Gte?: number | null;
  remaining_qty_IsBlank?: boolean | null;
  remaining_value?: number | null;
  remaining_value_Not?: number | null;
  remaining_value_In?: (number | null)[] | null;
  remaining_value_NotIn?: (number | null)[] | null;
  remaining_value_Lt?: number | null;
  remaining_value_Lte?: number | null;
  remaining_value_Gt?: number | null;
  remaining_value_Gte?: number | null;
  remaining_value_IsBlank?: boolean | null;
  quantity?: number | null;
  quantity_Not?: number | null;
  quantity_In?: (number | null)[] | null;
  quantity_NotIn?: (number | null)[] | null;
  quantity_Lt?: number | null;
  quantity_Lte?: number | null;
  quantity_Gt?: number | null;
  quantity_Gte?: number | null;
  quantity_IsBlank?: boolean | null;
  final_cost?: number | null;
  final_cost_Not?: number | null;
  final_cost_In?: (number | null)[] | null;
  final_cost_NotIn?: (number | null)[] | null;
  final_cost_Lt?: number | null;
  final_cost_Lte?: number | null;
  final_cost_Gt?: number | null;
  final_cost_Gte?: number | null;
  final_cost_IsBlank?: boolean | null;
  final_value?: number | null;
  final_value_Not?: number | null;
  final_value_In?: (number | null)[] | null;
  final_value_NotIn?: (number | null)[] | null;
  final_value_Lt?: number | null;
  final_value_Lte?: number | null;
  final_value_Gt?: number | null;
  final_value_Gte?: number | null;
  final_value_IsBlank?: boolean | null;
}

export interface TriPriceChangeDetailInput {
  id?: string | null;
  product_id: number;
  old_price: number;
  new_price: number;
}

export interface TriPriceChangeDetailInputList {
  list?: TriPriceChangeDetailInput[] | null;
  deleted?: number[] | null;
}

export interface TriPriceChangeInput {
  id?: string | null;
  description?: string | null;
  pricelist_id: number;
  pricechange_details?: TriPriceChangeDetailInputList | null;
}

export interface TriPriceChangeWhereInput {
  create_date?: any | null;
  create_date_Not?: any | null;
  create_date_Lt?: any | null;
  create_date_Lte?: any | null;
  create_date_Gt?: any | null;
  create_date_Gte?: any | null;
  create_date_IsBlank?: any | null;
  aND?: TriPriceChangeWhereInput[] | null;
  oR?: TriPriceChangeWhereInput[] | null;
  company_id?: string | null;
  company_id_Not?: string | null;
  company_id_In?: (string | null)[] | null;
  company_id_NotIn?: (string | null)[] | null;
  company_id_Lt?: string | null;
  company_id_Lte?: string | null;
  company_id_Gt?: string | null;
  company_id_Gte?: string | null;
  company_id_Contains?: string | null;
  company_id_NotContains?: string | null;
  company_id_StartsWith?: string | null;
  company_id_NotStartsWith?: string | null;
  company_id_EndsWith?: string | null;
  company_id_NotEndsWith?: string | null;
  company_id_IsBlank?: boolean | null;
  company_id__id?: number | null;
  company_id__id_Not?: number | null;
  company_id__id_In?: (number | null)[] | null;
  company_id__id_NotIn?: (number | null)[] | null;
  company_id__id_Lt?: number | null;
  company_id__id_Lte?: number | null;
  company_id__id_Gt?: number | null;
  company_id__id_Gte?: number | null;
  company_id__id_IsBlank?: boolean | null;
  create_uid?: string | null;
  create_uid_Not?: string | null;
  create_uid_In?: (string | null)[] | null;
  create_uid_NotIn?: (string | null)[] | null;
  create_uid_Lt?: string | null;
  create_uid_Lte?: string | null;
  create_uid_Gt?: string | null;
  create_uid_Gte?: string | null;
  create_uid_Contains?: string | null;
  create_uid_NotContains?: string | null;
  create_uid_StartsWith?: string | null;
  create_uid_NotStartsWith?: string | null;
  create_uid_EndsWith?: string | null;
  create_uid_NotEndsWith?: string | null;
  create_uid_IsBlank?: boolean | null;
  create_uid__id?: number | null;
  create_uid__id_Not?: number | null;
  create_uid__id_In?: (number | null)[] | null;
  create_uid__id_NotIn?: (number | null)[] | null;
  create_uid__id_Lt?: number | null;
  create_uid__id_Lte?: number | null;
  create_uid__id_Gt?: number | null;
  create_uid__id_Gte?: number | null;
  create_uid__id_IsBlank?: boolean | null;
  created_id?: string | null;
  created_id_Not?: string | null;
  created_id_In?: (string | null)[] | null;
  created_id_NotIn?: (string | null)[] | null;
  created_id_Lt?: string | null;
  created_id_Lte?: string | null;
  created_id_Gt?: string | null;
  created_id_Gte?: string | null;
  created_id_Contains?: string | null;
  created_id_NotContains?: string | null;
  created_id_StartsWith?: string | null;
  created_id_NotStartsWith?: string | null;
  created_id_EndsWith?: string | null;
  created_id_NotEndsWith?: string | null;
  created_id_IsBlank?: boolean | null;
  created_name?: string | null;
  created_name_Not?: string | null;
  created_name_In?: (string | null)[] | null;
  created_name_NotIn?: (string | null)[] | null;
  created_name_Lt?: string | null;
  created_name_Lte?: string | null;
  created_name_Gt?: string | null;
  created_name_Gte?: string | null;
  created_name_Contains?: string | null;
  created_name_NotContains?: string | null;
  created_name_StartsWith?: string | null;
  created_name_NotStartsWith?: string | null;
  created_name_EndsWith?: string | null;
  created_name_NotEndsWith?: string | null;
  created_name_IsBlank?: boolean | null;
  description?: string | null;
  description_Not?: string | null;
  description_In?: (string | null)[] | null;
  description_NotIn?: (string | null)[] | null;
  description_Lt?: string | null;
  description_Lte?: string | null;
  description_Gt?: string | null;
  description_Gte?: string | null;
  description_Contains?: string | null;
  description_NotContains?: string | null;
  description_StartsWith?: string | null;
  description_NotStartsWith?: string | null;
  description_EndsWith?: string | null;
  description_NotEndsWith?: string | null;
  description_IsBlank?: boolean | null;
  name?: string | null;
  name_Not?: string | null;
  name_In?: (string | null)[] | null;
  name_NotIn?: (string | null)[] | null;
  name_Lt?: string | null;
  name_Lte?: string | null;
  name_Gt?: string | null;
  name_Gte?: string | null;
  name_Contains?: string | null;
  name_NotContains?: string | null;
  name_StartsWith?: string | null;
  name_NotStartsWith?: string | null;
  name_EndsWith?: string | null;
  name_NotEndsWith?: string | null;
  name_IsBlank?: boolean | null;
  state?: TriPriceChangeState | null;
  state_Not?: TriPriceChangeState | null;
  state_In?: TriPriceChangeState[] | null;
  state_NotIn?: TriPriceChangeState[] | null;
  state_IsBlank?: boolean | null;
  detail_ids?: number | null;
  detail_ids_Not?: number | null;
  detail_ids_In?: (number | null)[] | null;
  detail_ids_NotIn?: (number | null)[] | null;
  detail_ids_Lt?: number | null;
  detail_ids_Lte?: number | null;
  detail_ids_Gt?: number | null;
  detail_ids_Gte?: number | null;
  detail_ids_IsBlank?: boolean | null;
  price_list_id?: string | null;
  price_list_id_Not?: string | null;
  price_list_id_In?: (string | null)[] | null;
  price_list_id_NotIn?: (string | null)[] | null;
  price_list_id_Lt?: string | null;
  price_list_id_Lte?: string | null;
  price_list_id_Gt?: string | null;
  price_list_id_Gte?: string | null;
  price_list_id_Contains?: string | null;
  price_list_id_NotContains?: string | null;
  price_list_id_StartsWith?: string | null;
  price_list_id_NotStartsWith?: string | null;
  price_list_id_EndsWith?: string | null;
  price_list_id_NotEndsWith?: string | null;
  price_list_id_IsBlank?: boolean | null;
  price_list_id__id?: number | null;
  price_list_id__id_Not?: number | null;
  price_list_id__id_In?: (number | null)[] | null;
  price_list_id__id_NotIn?: (number | null)[] | null;
  price_list_id__id_Lt?: number | null;
  price_list_id__id_Lte?: number | null;
  price_list_id__id_Gt?: number | null;
  price_list_id__id_Gte?: number | null;
  price_list_id__id_IsBlank?: boolean | null;
  id?: string | null;
  id_Not?: string | null;
  id_In?: (string | null)[] | null;
  id_NotIn?: (string | null)[] | null;
  id_Lt?: string | null;
  id_Lte?: string | null;
  id_Gt?: string | null;
  id_Gte?: string | null;
  id_IsBlank?: boolean | null;
}

export interface UserInput {
  code: string;
  fullName: string;
  odooUserName: string;
  odooPassword: string;
  cashAccountIdForCashSale?: number | null;
  active: boolean;
  companyId: number;
  companyName: string;
}

export interface UserWhereInput {
  aND?: UserWhereInput[] | null;
  oR?: UserWhereInput[] | null;
  id?: string | null;
  id_Not?: string | null;
  id_In?: string[] | null;
  id_NotIn?: string[] | null;
  id_Lt?: string | null;
  id_Lte?: string | null;
  id_Gt?: string | null;
  id_Gte?: string | null;
  id_Contains?: string | null;
  id_NotContains?: string | null;
  id_StartsWith?: string | null;
  id_NotStartsWith?: string | null;
  id_EndsWith?: string | null;
  id_NotEndsWith?: string | null;
  code?: string | null;
  code_Not?: string | null;
  code_In?: string[] | null;
  code_NotIn?: string[] | null;
  code_Lt?: string | null;
  code_Lte?: string | null;
  code_Gt?: string | null;
  code_Gte?: string | null;
  code_Contains?: string | null;
  code_NotContains?: string | null;
  code_StartsWith?: string | null;
  code_NotStartsWith?: string | null;
  code_EndsWith?: string | null;
  code_NotEndsWith?: string | null;
  fullName?: string | null;
  fullName_Not?: string | null;
  fullName_In?: string[] | null;
  fullName_NotIn?: string[] | null;
  fullName_Lt?: string | null;
  fullName_Lte?: string | null;
  fullName_Gt?: string | null;
  fullName_Gte?: string | null;
  fullName_Contains?: string | null;
  fullName_NotContains?: string | null;
  fullName_StartsWith?: string | null;
  fullName_NotStartsWith?: string | null;
  fullName_EndsWith?: string | null;
  fullName_NotEndsWith?: string | null;
  odooUserName?: string | null;
  odooUserName_Not?: string | null;
  odooUserName_In?: string[] | null;
  odooUserName_NotIn?: string[] | null;
  odooUserName_Lt?: string | null;
  odooUserName_Lte?: string | null;
  odooUserName_Gt?: string | null;
  odooUserName_Gte?: string | null;
  odooUserName_Contains?: string | null;
  odooUserName_NotContains?: string | null;
  odooUserName_StartsWith?: string | null;
  odooUserName_NotStartsWith?: string | null;
  odooUserName_EndsWith?: string | null;
  odooUserName_NotEndsWith?: string | null;
  cashAccountIdForCashSale?: number | null;
  cashAccountIdForCashSale_Not?: number | null;
  cashAccountIdForCashSale_Lt?: number | null;
  cashAccountIdForCashSale_Lte?: number | null;
  cashAccountIdForCashSale_Gt?: number | null;
  cashAccountIdForCashSale_Gte?: number | null;
  cashAccountIdForCashSale_In?: number[] | null;
  cashAccountIdForCashSale_NotIn?: number[] | null;
  cashAccountIdForCashSale_IsBlank?: boolean | null;
  defaultAdmin?: boolean | null;
  password_IsBlank?: boolean | null;
  odooPassword_IsBlank?: boolean | null;
  active?: boolean | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
