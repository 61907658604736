import { InvoiceState } from "../../types/global-types";

function resolveAccountMoveStateIndicator(status: InvoiceState) {
  switch (status) {
    case InvoiceState.draft:
      return "Draft";

    case InvoiceState.cancel:
      return "Cancelled";

    case InvoiceState.posted:
      return "Posted";
  }
}

export default resolveAccountMoveStateIndicator;
