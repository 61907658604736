import { useQuery } from "@apollo/client";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText/ListItemText";
import TextField, { TextFieldProps } from "@mui/material/TextField/TextField";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { PartnerType, PartnerWhereInput } from "../../types/global-types";
import { partnersQuery } from "./graphql";
import { Partners, PartnersVariables, Partners_partnerQuery_partners_edges } from "./__generated__/Partners";

export type PartnerAutoCompleteProps = {
  selectedPartner?: Partners_partnerQuery_partners_edges | null;
  onSelected: (customer: Partners_partnerQuery_partners_edges | null) => void;
  className?: string;
  partnerType?: PartnerType
} & Pick<TextFieldProps, "size" | "label" | "sx" | "error" | "helperText">;;

function PartnerAutoComplete({
  selectedPartner,
  onSelected,
  className,
  label,
  size,
  sx,
  error,
  helperText,
  partnerType
}: PartnerAutoCompleteProps) {

  const [search, setSearch] = React.useState("");
  const rank: Partial<PartnerWhereInput> = {};
  switch (partnerType) {
    case "customer":
      rank.customer_rank_Gt = 0;
      break;
    case "supplier":
      rank.supplier_rank_Gt = 0;
      break;
    default:
  }
  const { data, loading } = useQuery<Partners, PartnersVariables>(
    partnersQuery,
    {
      variables: {
        where: {
          oR: [{
            name_Contains: search
          }, {
            phone_Contains: search
          }],
          ...rank
        }
      },
      skip: search === `${selectedPartner?.name}-${selectedPartner?.phone}`
    }
  );
  return (
    <Autocomplete
      value={selectedPartner}
      onChange={(_, value: Partners_partnerQuery_partners_edges | null) => {
        onSelected(value);
      }}
      includeInputInList
      clearOnBlur
      selectOnFocus
      isOptionEqualToValue={(option, value) => option.id === value.id}
      loading={loading}
      getOptionLabel={(option) => `${option.name}-${option.phone ?? ""}`}
      renderOption={(props: any, option) => (
        <Box {...props} display="flex">
          <ListItem >
            <ListItemText primary={option.name} secondary={option.phone} />
          </ListItem>
          <Box>
            <ListItemText
              primary={`Sale: ${option.sale_order_count}`}
              secondary={<Typography style={{ textAlign: "right", display: "block" }}>{`Purchase:${option.purchase_order_count}`}</Typography>}
            />
          </Box>
          <Divider />
        </Box>
      )}
      options={
        data?.partnerQuery?.partners?.edges ??
        (selectedPartner ? [selectedPartner] : [])
      }
      onInputChange={(_, value) => { setSearch(value); }}
      renderInput={(params) => (
        <TextField
          {...params}
          size={size}
          sx={sx}
          error={error}
          helperText={helperText}
          autoComplete="off"
          label={label}
          className={className}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}

export default PartnerAutoComplete;
