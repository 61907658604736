import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import format from "date-fns/format";
import loyaltyProgramSlice, {
  initCouponProgram,
  loyaltyProgramDefaultValue,
  CouponProgramPayload,
} from "./loyaltyProgramSlice";
import { DiscountValue } from "./DiscountValueEditor";
import {
  Promotion_promotionQuery_promotion,
  Promotion_promotionQuery_promotion_loyalty_programs,
} from "./__generated__/Promotion";

export type PromotionActionPayload = Partial<
  Omit<Promotion_promotionQuery_promotion, "company_id">
>;
export type CouponProgramUpdatePayload = {
  index: number;
  program: CouponProgramPayload;
};

export type CouponProgramUpdateDiscountPayload = {
  index: number;
  discount: DiscountValue | undefined;
};

export type CouponProgramUpdateActivePayload = {
  index: number;
  active: boolean;
};

export type PromotionReduxState = Omit<
  Promotion_promotionQuery_promotion,
  "company_id"
> & {
  hasChanges: boolean;
  deleted_loyalty_programs: number[];
};

export const promotionDefaultValue: PromotionReduxState = {
  hasChanges: false,
  id: "",
  name: "",
  date_from: format(new Date(), "yyyy-MM-dd"),
  date_to: null,
  reward_product_income_account_id: null,
  created_id: "",
  created_name: "",
  loyalty_programs: [],
  deleted_loyalty_programs: [],
  active:true
};

export const promotionSlice = createSlice({
  name: "promotion",
  initialState: promotionDefaultValue,
  reducers: {
    setPromotion: (state, action: PayloadAction<PromotionActionPayload>) => {
      Object.assign(state, action.payload);
      state.deleted_loyalty_programs = [];
      state.hasChanges = false;
    },
    updatePromotion: (state, action: PayloadAction<PromotionActionPayload>) => {
      Object.assign(state, action.payload);
      state.hasChanges = true;
    },
    setCouponProgramDiscount: (
      state,
      action: PayloadAction<CouponProgramUpdateDiscountPayload | any>
    ) => {
      const loyalty_programs = state.loyalty_programs ?? [];
      loyalty_programs[action.payload.index].discount_mode =
        action?.payload.discount.discount_type;
      loyalty_programs[action.payload.index].discount =
        action?.payload.discount.discount_percentage?? action?.payload.discount.discount_fixed_amount;
      state.hasChanges = true;
    },
    setCouponProgramActive: (
      state,
      action: PayloadAction<CouponProgramUpdateActivePayload>
    ) => {
      const loyalty_programs = state.loyalty_programs ?? [];
      loyalty_programs[action.payload.index].active = action.payload.active;
      state.hasChanges = true;
    },
    deleteCouponProgram: (state, action: PayloadAction<number>) => {
      const loyalty_programs = state.loyalty_programs ?? [];
      const deletedCouponProgram = loyalty_programs[action.payload];
      loyalty_programs.splice(action.payload, 1);
      state.loyalty_programs = loyalty_programs;
      const deleted_loyalty_programs = state.deleted_loyalty_programs ?? [];
      deleted_loyalty_programs.push(Number.parseInt(deletedCouponProgram.id!));
      state.hasChanges = true;
    },
    deleteLastCouponProgram: (state) => {
      const loyalty_programs = state.loyalty_programs ?? [];
      if (loyalty_programs.length) {
        const index = loyalty_programs.length - 1;
        const deletedCouponProgram = loyalty_programs[index];
        loyalty_programs.splice(index, 1);
        state.loyalty_programs = loyalty_programs;
        const deleted_loyalty_programs = state.deleted_loyalty_programs ?? [];
        deleted_loyalty_programs.push(Number.parseInt(deletedCouponProgram.id!));
        state.hasChanges = true;
      }
    },
    editCouponProgram: (
      state,
      action: PayloadAction<CouponProgramUpdatePayload>
    ) => {
      const { index, program } = action.payload;
      const loyalty_programs = state.loyalty_programs ?? [];
      const new_program = loyaltyProgramSlice(loyalty_programs[index]??loyaltyProgramDefaultValue, {
        ...initCouponProgram,
        payload: program,
      }) as Promotion_promotionQuery_promotion_loyalty_programs;
      if(index <0){
        loyalty_programs.push(new_program);
      }
      state.hasChanges = true;
    },
  },
});

export const promotionActions = {
  ...promotionSlice.actions,
};
export default promotionSlice.reducer;
