import { ApolloConsumer, useQuery } from "@apollo/client";
import {
  Avatar,
  Checkbox,
  Divider,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@mui/material";
import ApolloVirtualizedTable, {
  ApolloListResult,
  ApolloVirtualizedTableProps,
  DefaultPageInfoValue,
  GridColumn,
  ListItemRenderProps,
} from "mui-apollo-virtualized-table";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { usersQuery } from "./graphql";
import {
  Users,
  UsersVariables,
  Users_userQuery_users_edges,
} from "./__generated__/Users";

const UserVirtualizedGrid = ApolloVirtualizedTable as React.ElementType<
  ApolloVirtualizedTableProps<Users_userQuery_users_edges>
>;

const _sx = {
  loadingIndicator: {
    backgroundColor: "#DDDDDD",
    color: "#DDDDDD",
    width: 150,
    display: "inline",
  },
  selected: {
    backgroundColor: "rgba(255, 255, 255, 0.14)",
  },
};

export type UserProps = {
  variables: UsersVariables;
  onVariablesChanged: (variables: UsersVariables) => void;
};

function UserGrid({
  variables = { pagination: { pageSize: 40 } },
  onVariablesChanged,
}: UserProps) {
  const [columns, setColumns] = React.useState<
    ReadonlyArray<GridColumn<Users_userQuery_users_edges>>
  >([
    {
      label: "Code",
      key: "code",
      width: 100,
      sortable: true,
      sortDirection: "DESC",
      sorted: true,
    },
    {
      label: "Full Name",
      key: "fullName",
      width: 100,
      sortable: true,
      flexGrow: 1,
    },
    {
      label: "Default Admin",
      key: "defaultAdmin",
      width: 100,
      format: ({ rowData }) => <Checkbox checked={rowData.defaultAdmin}/>,
    },
    {
      label: "Active",
      key: "active",
      width: 100,
      format: ({ rowData }) => <Checkbox checked={rowData.active}/>
    },
  ]);
  const navigate = useNavigate();
  const { id: userId } = useParams<{ id: string }>();
  const { data: usersQueryResult } = useQuery<Users, UsersVariables>(
    usersQuery,
    { fetchPolicy: "cache-only", variables }
  );
  const [selectedIndex, setSelectedIndex] = React.useState(-1);
  const listItemRenderer = React.useCallback(
    (renderProps: ListItemRenderProps<Users_userQuery_users_edges>) => {
      const { rowData, key, style, className, onClick } = renderProps;
      const isSelected = rowData && rowData.id === userId;
      if (rowData) {
        const { code, fullName, active } = rowData;
        return (
          <div
            onClick={onClick}
            style={style}
            className={`${className} ${isSelected ? _sx.selected : null}`}
            key={key}
          >
            <ListItem>
              <ListItemAvatar>
                <Avatar>{fullName[0]}</Avatar>
              </ListItemAvatar>
              <ListItemText primary={code} secondary={fullName} />
            </ListItem>
            <ListItemSecondaryAction>
              <Checkbox checked={active} />
            </ListItemSecondaryAction>
            <Divider />
          </div>
        );
      } else {
        return (
          <div style={style} key={key}>
            <ListItem>
              <ListItemText
                primary={
                  <Typography sx={_sx.loadingIndicator}>
                    A...........................
                  </Typography>
                }
                secondary={
                  <Typography sx={_sx.loadingIndicator}>A..........</Typography>
                }
              />
            </ListItem>
            <Divider />
          </div>
        );
      }
    },
    [userId]
  );
  const handleCommandKeyUp = React.useCallback(
    (e: DocumentEventMap["keyup"]) => {
      const products = usersQueryResult?.userQuery?.users?.edges ?? [];
      switch (e.key) {
        case "ArrowUp":
          if (userId) {
            setSelectedIndex(Math.max(0, selectedIndex - 1));
            e.stopPropagation();
          }
          break;
        case "ArrowDown":
          if (userId) {
            setSelectedIndex(Math.min(selectedIndex + 1, products.length));
            e.stopPropagation();
          }
          break;
      }
    },
    [usersQueryResult?.userQuery?.users?.edges, userId, selectedIndex]
  );
  React.useEffect(() => {
    document.addEventListener("keyup", handleCommandKeyUp);
    return () => {
      document.removeEventListener("keyup", handleCommandKeyUp);
    };
  }, [handleCommandKeyUp]);

  return (
    <ApolloConsumer>
      {(client) => (
        <UserVirtualizedGrid
          onRowClick={(item, index) => {
            navigate(`/users/${item.id}`);
          }}
          apolloClient={client as any}
          listItemHeight={72}
          listItemRenderer={listItemRenderer}
          listModeBreakPoint={320}
          onColumnPropsChanged={(columns, orderBy: any) => {
            const newVariables: UsersVariables = {
              ...variables,
              orderBy,
            };
            setColumns(columns);
            onVariablesChanged(newVariables);
          }}
          selectedItems={[selectedIndex]}
          scrollToIndex={selectedIndex}
          columns={columns}
          graphqlQuery={usersQuery}
          variables={variables}
          pageSize={variables.pagination?.pageSize!}
          parseListFromQueryResult={(queryResult: Users) => {
            const list: ApolloListResult<Users_userQuery_users_edges> =
              queryResult.userQuery.users ?? {
                edges: [],
                pageInfo: DefaultPageInfoValue,
              };
            return list;
          }}
          onLoadMore={(pageInfo) => {
            return {
              ...variables,
              pagination: {
                page: pageInfo.page,
                pageSize: pageInfo.pageSize,
              },
            };
          }}
        />
      )}
    </ApolloConsumer>
  );
}

export default UserGrid;
