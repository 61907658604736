import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Skeleton from "@mui/material/Skeleton";
import * as React from "react";
import { defaultColumnWidth } from "./ProductMasonry";
function EmptyProductCard({ width, style,debug }: {
    width: number,
    style?: React.CSSProperties,
    debug?:string
}) {

    const padding = width < defaultColumnWidth ? .5 : 1;
    return (
        <Box
            sx={{ padding}}
            style={style}
        >
            <Card sx={{width: "100%"}}>
                <Skeleton variant="rectangular" animation="wave" height={(width - padding* 2 * 8)} />
                <ListItem>
                    <ListItemText
                        secondary={debug}
                        primary={<Skeleton animation="wave" width={130} />}
                    />
                </ListItem>
            </Card>
        </Box>
    );
}

export default EmptyProductCard;