import { useQuery } from "@apollo/client";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText/ListItemText";
import TextField, { TextFieldProps } from "@mui/material/TextField/TextField";
import * as React from "react";
import { employeesQuery } from "./graphql";
import { Employees, EmployeesVariables, Employees_partnerQuery_employees_edges } from "./__generated__/Employees";

export type EmployeeAutoCompleteProps = {
  selectedEmployee: Employees_partnerQuery_employees_edges | null;
  onSelected: (employee: Employees_partnerQuery_employees_edges | null) => void;
  className?: string;
} & Pick<TextFieldProps, "size" | "label" | "sx">;;

function EmployeeAutoComplete({
  selectedEmployee,
  onSelected,
  className,
  label,
  size,
  sx,
}: EmployeeAutoCompleteProps) {

  const [search, setSearch] = React.useState("");
  
  const { data, loading } = useQuery<Employees, EmployeesVariables>(
    employeesQuery,
    {
      variables: {
        search
      },
      skip: search === selectedEmployee?.name
    }
  );
  return (
    <Autocomplete
      value={selectedEmployee}
      onChange={(_, value: Employees_partnerQuery_employees_edges | null) => {
        onSelected(value);
      }}
      includeInputInList
      clearOnBlur
      selectOnFocus
      isOptionEqualToValue={(option, value) => option.id === value.id}
      loading={loading}
      getOptionLabel={(option) => option?.name??""}
      renderOption={(props: any, option) => (
        <Box {...props} display="flex">
          <ListItem >
            <ListItemText primary={option.name}  />
          </ListItem>
          <Divider />
        </Box>
      )}
      options={
        data?.partnerQuery?.employees?.edges ??
        (selectedEmployee ? [selectedEmployee] : [])
      }
      onInputChange={(_, value) => { setSearch(value); }}
      renderInput={(params) => (
        <TextField
          {...params}
          size={size}
          sx={sx}
          autoComplete="off"
          label={label}
          className={className}
          fullWidth
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}

export default EmployeeAutoComplete;
