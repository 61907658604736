import { useQuery } from "@apollo/client";
import { Box, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import accounting from "accounting";
import { format } from "date-fns";
import * as React from "react";
import { cashAndBankByTeamQuery, cashAndBankQuery, paymentSummaryQuery, paymentsWithInvoiceQuery, saleByTeamQuery, saleReportQuery, saleSummaryQuery, unconciledPaymentSummaryQuery } from "../graphql";
import { CashAndBank, CashAndBankVariables } from "../__generated__/CashAndBank";
import { CashAndBankByTeam, CashAndBankByTeamVariables } from "../__generated__/CashAndBankByTeam";
import { InvoiceSummaryVariables } from "../__generated__/InvoiceSummary";
import { PaymentSummary, PaymentSummaryVariables } from "../__generated__/PaymentSummary";
import { PaymentsWithInvoice, PaymentsWithInvoiceVariables } from "../__generated__/PaymentsWithInvoice";
import { SaleByTeam, SaleByTeamVariables } from "../__generated__/SaleByTeam";
import { SaleReportQuery, SaleReportQueryVariables } from "../__generated__/SaleReportQuery";
import { SaleSummary, SaleSummaryVariables } from "../__generated__/SaleSummary";
import { UnreconciledPaymentSummary, UnreconciledPaymentSummaryVariables } from "../__generated__/UnreconciledPaymentSummary";
export type SummaryProps = {
    variables: InvoiceSummaryVariables,
    start_date: string,
    end_date: string
};
const _sx = {
    itemContainer: {
        padding: 1
    },
    itemMargin: {
        margin: 1,
        paddingTop: 1
    },
    paperTitle: {
        textDecoration: "underline",
        marginLeft: 2,
        marginTop: 1,
        marginBottom: 1
    },
    paperTitleMargin: {
        marginLeft: 2
    },
    scroller: {
        flex: 1,
        overflow: "auto",
        padding: 1,
        maxWidth: "100%",
        backgroundColor: "rgba(0,0,0,0.1)"
    },
    paper: {
        flex: 1,
        padding: 2
    },
    label: {
        margin: 0,
    },
    value: {
        margin: 0,
        marginTop: 1,
        textAlign: "center"
    }
};

function CashAndBankComponent({ variables }: Partial<SummaryProps>) {
    var { data: cashAndBankResult } = useQuery<CashAndBank, CashAndBankVariables>(cashAndBankQuery, { variables: { ...variables, counter: 1 } });
    var cashAndBanks = cashAndBankResult?.saleReportQuery?.cashAndBank?.edges?.filter(cb => cb?.journal_code && (cb?.cash_sum || cb?.invoice_sum || cb?.bank_sum)) ?? [];
    return (<Grid item xs={12} md={6} sx={_sx.itemContainer}><TableContainer component={Paper} >
        <Typography variant="h4" sx={_sx.paperTitle}>Cash & Bank</Typography>
        <Table>
            <TableBody>
                {
                    cashAndBanks?.map(cb => (<TableRow key={cb.journal_code}>

                        <TableCell scope="row">{cb.journal_code}</TableCell>
                        <TableCell align="right">{accounting.formatNumber((cb?.bank_sum ?? 0) * -1 + (cb?.cash_sum ?? 0) * -1 + cb?.invoice_sum! ?? 0,2)}</TableCell>
                    </TableRow>))
                }
            </TableBody>
        </Table>
    </TableContainer></Grid>);
}

function UnreconciledPaymentSummaryComponent({ start_date, end_date }: Partial<SummaryProps>) {
    var { data: unconciledPaymentResult } = useQuery<UnreconciledPaymentSummary, UnreconciledPaymentSummaryVariables>(unconciledPaymentSummaryQuery, { variables: { start_date, end_date, counter: 2, include_journalcode: true } });
    return (<Paper sx={_sx.itemMargin}>
        <Typography variant="h4" sx={_sx.paperTitle}>Payments</Typography><TableContainer >
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell >Cash/Bank</TableCell>
                        <TableCell width={120} align="right">Total</TableCell>
                        <TableCell width={120} align="right">Inner</TableCell>
                        <TableCell width={120} align="right">Outer</TableCell>
                        <TableCell width={120} align="right">Unreconciled</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        unconciledPaymentResult?.saleReportQuery?.unreconciledTotal?.edges?.map(cb => (<TableRow key={cb.journal_code}>
                            <TableCell >{cb.journal_code}</TableCell>
                            <TableCell align="right">{accounting.formatNumber(cb?.amount_sum!,2)}</TableCell>
                            <TableCell align="right">{accounting.formatNumber(cb?.inner_reconciled_amount_sum!,2)}</TableCell>
                            <TableCell align="right">{accounting.formatNumber(cb?.outer_reconciled_amount_sum!,2)}</TableCell>
                            <TableCell align="right">{accounting.formatNumber(cb?.remaining_sum!,2)}</TableCell>
                        </TableRow>))
                    }
                </TableBody>
            </Table>
        </TableContainer></Paper>);
}

function UnreconciledPayments({ start_date, end_date }: Partial<SummaryProps>) {
    var { data: paymentsWithInvoiceResult } = useQuery<PaymentsWithInvoice, PaymentsWithInvoiceVariables>(paymentsWithInvoiceQuery, {
        variables: {
            start_date, end_date, counter: 1, where: {
                oR: [{ outer_reconciled_amount_Not: 0 }, { counterpart_move_id_IsBlank: true }, { remaining_Not: 0 }]
            }
        }
    });
    return (<Paper sx={_sx.itemMargin}>
        <Typography variant="h4" sx={_sx.paperTitle}>Unconciled Payments</Typography><TableContainer  >
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell width={100}>Date</TableCell>
                        <TableCell width={120}>Name</TableCell>
                        <TableCell scope="row">Memo</TableCell>
                        <TableCell width={150} align="right">Payment</TableCell>
                        <TableCell width={120}>Invoice</TableCell>
                        <TableCell width={100}>Inv Date</TableCell>
                        <TableCell width={120}>Origin</TableCell>
                        <TableCell width={100} align="right">Reconciled</TableCell>
                        <TableCell width={100} align="right">Unreconciled</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        paymentsWithInvoiceResult?.saleReportQuery?.paymentsWithInvoice?.edges?.map(cb => (<TableRow key={cb.name}>
                            <TableCell>{format(new Date(cb.date), "dd/MM/yyyy")}</TableCell>
                            <TableCell>{cb.name}</TableCell>
                            <TableCell scope="row">{cb.ref}</TableCell>
                            <TableCell align="right">{accounting.formatNumber(cb.amount || 0,2)}</TableCell>
                            <TableCell>{cb.counterpart_move_name}</TableCell>
                            <TableCell><Typography variant="inherit" color={cb.outer_reconciled_amount != 0 ? "secondary" : "inherit"}>{format(new Date(cb.counterpart_move_date), "dd/MM/yyyy")}</Typography></TableCell>
                            <TableCell>{cb.invoice_origin}</TableCell>
                            <TableCell align="right">{accounting.formatNumber(cb.outer_reconciled_amount || 0 + cb.inner_reconciled_amount!,2)}</TableCell>
                            <TableCell align="right">{accounting.formatNumber(cb.remaining || 0,2)}</TableCell>
                        </TableRow>))
                    }
                </TableBody>
            </Table>
        </TableContainer></Paper>);
}

function SaleTeam({ variables }: Partial<SummaryProps>) {
    var { data: cashAndBankByTeamResult } = useQuery<CashAndBankByTeam, CashAndBankByTeamVariables>(cashAndBankByTeamQuery, { variables: { ...variables, counter: 3 } });
    var { data: saleByTeamResult } = useQuery<SaleByTeam, SaleByTeamVariables>(saleByTeamQuery, { variables: { ...variables, counter: 4 } });
    const data = React.useMemo(() => {
        var row: { [key: string]: { [key: string]: number } } = {};
        saleByTeamResult?.saleReportQuery?.saleByTeam?.edges?.map((item: any) => {
            const { team_name, amount_residual_sum, amount_total_sum } = item;
            if (!row[team_name]) {
                row[team_name] = {};
            }
            const team = row[team_name!];
            team["Total Sale"] = amount_total_sum;
            team["Receivable"] = amount_residual_sum;
        });
        cashAndBankByTeamResult?.saleReportQuery?.cashAndBankByTeam?.edges?.map((item: any) => {
            const { team_name, journal_code, bank_sum, cash_sum, invoice_sum } = item;
            if (!journal_code)
                return;
            if (!row[team_name]) {
                row[team_name] = {};
            }
            const team = row[team_name!];
            team[journal_code] = 0;
            team[journal_code] += bank_sum ?? 0;
            team[journal_code] += cash_sum ?? 0;
            team[journal_code] += invoice_sum ?? 0;
            if (!journal_code.includes("INV"))
                team[journal_code] *= -1;
        });

        return row;
    }, [cashAndBankByTeamResult, saleByTeamResult]);
    return (<>{
        Object.keys(data).map(key => {
            const team = data[key];
            return (<Grid item xs={12} sm={6} sx={_sx.itemContainer}><TableContainer component={Paper} >
                <Typography variant="h4" sx={_sx.paperTitle}>{key}</Typography>
                <Table>
                    <TableBody>
                        {
                            Object.keys(team).map(kkey => (<TableRow key={kkey}>

                                <TableCell scope="row">{kkey}</TableCell>
                                <TableCell align="right">{accounting.formatNumber(team[kkey],2)}</TableCell>
                            </TableRow>))
                        }
                    </TableBody>
                </Table>
            </TableContainer></Grid>);
        })
    }</>);
}
function Overview({ variables, start_date, end_date }: SummaryProps) {
    var { data: saleResult } = useQuery<SaleSummary, SaleSummaryVariables>(saleSummaryQuery, { variables: { ...variables, counter: 2 } });
    var { data: paymentResult } = useQuery<PaymentSummary, PaymentSummaryVariables>(paymentSummaryQuery, { variables: { ...variables, counter: 4 } });
    var { data: unconciledPaymentResult } = useQuery<UnreconciledPaymentSummary, UnreconciledPaymentSummaryVariables>(unconciledPaymentSummaryQuery, { variables: { start_date, end_date, counter: 1, include_journalcode: false } });
    var [sale] = saleResult?.saleReportQuery?.sale?.edges ?? [];
    var [payment] = paymentResult?.saleReportQuery?.payments?.edges ?? [];
    var [unreconciledSummary] = unconciledPaymentResult?.saleReportQuery?.unreconciledTotal?.edges ?? [];
    return (<Grid container direction="row">
        <Grid container item xs={6} md={4} lg={3} xl={2} sx={_sx.itemContainer}>
            <Paper sx={_sx.paper}>
                <Typography variant="h5" sx={_sx.label}>Total Sale</Typography>
                <Typography variant="h6" sx={_sx.value}>{accounting.formatNumber(sale?.amount_total_sum || 0,2)}</Typography>
            </Paper>
        </Grid>
        <Grid container item xs={6} md={4} lg={3} xl={2} sx={_sx.itemContainer}>
            <Paper sx={_sx.paper}>
                <Typography variant="h5" sx={_sx.label}>Cash Down</Typography>
                <Typography variant="h6" sx={_sx.value}>{accounting.formatNumber(payment?.cash_sum || 0 * -1,2)}</Typography>
            </Paper>
        </Grid>
        <Grid container item xs={6} md={4} lg={3} xl={2} sx={_sx.itemContainer}>
            <Paper sx={_sx.paper}>
                <Typography variant="h5" sx={_sx.label}>Bank Transfer</Typography>
                <Typography variant="h6" sx={_sx.value}>{accounting.formatNumber(payment?.bank_sum || 0 * -1,2)}</Typography>
            </Paper>
        </Grid>
        <Grid container item xs={6} md={4} lg={3} xl={2} sx={_sx.itemContainer}>
            <Paper sx={_sx.paper}>
                <Typography variant="h5" sx={_sx.label}>Contra</Typography>
                <Typography variant="h6" sx={_sx.value}>{accounting.formatNumber(payment?.invoice_sum || 0 * -1,2)}</Typography>
            </Paper>
        </Grid>
        <Grid container item xs={6} md={4} lg={3} xl={2} sx={_sx.itemContainer}>
            <Paper sx={_sx.paper}>
                <Typography variant="h5" sx={_sx.label}>Receivable</Typography>
                <Typography variant="h6" sx={_sx.value}>{accounting.formatNumber(sale?.amount_residual_sum || 0,2)}</Typography>
            </Paper>
        </Grid>
        <Grid container item xs={6} md={4} lg={3} xl={2} sx={_sx.itemContainer}>
            <Paper sx={_sx.paper}>
                <Typography variant="h5" sx={_sx.label}>Total Payment</Typography>
                <Typography variant="h6" sx={_sx.value}>{accounting.formatNumber(unreconciledSummary?.amount_sum || 0,2)}</Typography>
            </Paper>
        </Grid>
        <Grid container item xs={6} md={4} lg={3} xl={2} sx={_sx.itemContainer}>
            <Paper sx={_sx.paper}>
                <Typography variant="h5" sx={_sx.label}>Inner Reconciled</Typography>
                <Typography variant="h6" sx={_sx.value}>{accounting.formatNumber(unreconciledSummary?.inner_reconciled_amount_sum || 0,2)}</Typography>
            </Paper>
        </Grid>
        <Grid container item xs={6} md={4} lg={3} xl={2} sx={_sx.itemContainer}>
            <Paper sx={_sx.paper}>
                <Typography variant="h5" sx={_sx.label}>Outer Reconciled</Typography>
                <Typography variant="h6" sx={_sx.value}>{accounting.formatNumber(unreconciledSummary?.outer_reconciled_amount_sum || 0,2)}</Typography>
            </Paper>
        </Grid>
        <Grid container item xs={6} md={4} lg={3} xl={2} sx={_sx.itemContainer}>
            <Paper sx={_sx.paper}>
                <Typography variant="h5" sx={_sx.label}>Unreconciled</Typography>
                <Typography variant="h6" sx={_sx.value}>{accounting.formatNumber(unreconciledSummary?.remaining_sum || 0,2)}</Typography>
            </Paper>
        </Grid>
    </Grid>);
}

function Top({ start_date, end_date }: {start_date:string, end_date:string}) {
    const { data: result } = useQuery<SaleReportQuery, SaleReportQueryVariables>(saleReportQuery, { variables: { start_date, end_date } });
    if (!result)
        return null;
    const { netSale, netIncome, incomeBreakdown, paymentReconcillations } = result.saleReportQuery;
    return (<Grid container direction="row">
        <SaleSummaryGrid data = {netSale} title="Net Sale"/>
        <SaleSummaryGrid data = {netIncome} title="Net Income"/>
        <SaleSummaryGrid data={incomeBreakdown} title="Receivable"/>
        <SaleSummaryGrid data={paymentReconcillations} title="Payment Reconcillation"/>
    </Grid>);
}

function SaleSummaryGrid({ data, title }: { data: { id: string|null, name: string|null, amount: number|null, total: number|null }[], title: string }) {
    return (<Grid item xs={12} md={6} sx={_sx.itemContainer}><TableContainer component={Paper} >
        <Typography variant="h4" sx={_sx.paperTitle}>{title}</Typography>
        <Table>
            <TableBody>
                {
                    data?.map(cb => (<TableRow key={cb.id}>
                        <TableCell scope="row">{cb.name}</TableCell>
                        <TableCell align="right">{accounting.formatNumber(cb.amount??0,2)}</TableCell>
                        <TableCell align="right">{accounting.formatNumber(cb.total??0,2)}</TableCell>
                    </TableRow>))
                }
            </TableBody>
        </Table>
    </TableContainer></Grid>);
}


function Summary({ variables, start_date, end_date }: SummaryProps) {
    return (
        <Box sx={_sx.scroller}>
            {/* <Overview variables={variables} start_date={start_date} end_date={end_date} /> */}
            <Top start_date={start_date} end_date={end_date}/>
            <Grid direction="row" container>
                <CashAndBankComponent variables={variables} />
                <SaleTeam variables={variables} />
            </Grid>
            <UnreconciledPaymentSummaryComponent start_date={start_date} end_date={end_date} />
            <UnreconciledPayments start_date={start_date} end_date={end_date} />
        </Box>
    )
}

export default Summary;
