import * as React from "react";
import accounting from "accounting";
import { TextFieldProps } from "@mui/material/TextField/TextField";
import TextField from "@mui/material/TextField/TextField";
import Input from "@mui/material/Input/Input";
import InputBase from "@mui/material/InputBase/InputBase";




type OwnProps = {
  validateOnBlur?: boolean;
  retainFocusOnError?: boolean;
  onValidating?: (value: string) => string;
  onValidated: (value: string) => void;
  selectAllOnFocus?: boolean;
  value?: string;
  inputElementType?: "InputBase" | "Input" | "TextField";
  onChanged?: (value: string) => void;
  showErrorAsHelperText?: boolean;
  innerReference?: React.Ref<HTMLDivElement>;
  inputReference?: (ref: HTMLInputElement) => void;
  skipOnValidatedEventIfValueNotChanged?: boolean;
  extraData?: string;
};

export type TextEditorProps = OwnProps & TextFieldProps;
function TextEditor(props: TextEditorProps) {
  const {
    showErrorAsHelperText,
    error,
    helperText,
    onValidating,
    onValidated,
    selectAllOnFocus,
    onBlur,
    value,
    onFocus,
    onKeyDown,
    onKeyPress,
    inputRef,
    inputReference,
    onChanged,
    inputProps,
    inputElementType,
    retainFocusOnError,
    validateOnBlur,
    extraData,
    skipOnValidatedEventIfValueNotChanged,
    innerReference,
    ...textFieldProps
  } = props;

  const textField = React.useRef<HTMLInputElement | null>(null);
  const [draft, setDraft] = React.useState<string>(
    value ?? ""
  );
  const shouldValidateOnBlur = React.useRef<boolean>(true);
  React.useEffect(() => {
    setDraft(value ?? "");
  }, [value, extraData]);


  const validate = () => {
    if (value === draft) {
      if (!skipOnValidatedEventIfValueNotChanged)
        onValidated(value);
      return true;
    }
    if (onValidating) {
      const error = onValidating(draft);
      setValidationError(error);
      if (!error) {
        onValidated(draft);
        return true;
      } else {
        return false;
      }
    } else {
      onValidated(draft);
      return true;
    }
  };
  const [validationError, setValidationError] = React.useState("");
  return (
    <TextField
      ref={innerReference}
      helperText={
        validationError && showErrorAsHelperText ? validationError : helperText
      }
      error={!!validationError || error}
      inputRef={(r) => {
        textField.current = r;
        if (inputReference) inputReference(r);
      }}
      inputProps={{ ...inputProps }}
      {...textFieldProps}
      value={draft}
      onFocus={(e: any) => {
        if (selectAllOnFocus) {
          setTimeout(() => {
            textField.current?.setSelectionRange(
              0,
              textField.current?.value?.length ?? 0
            );
          }, 10);
        }
        if (onFocus) onFocus(e);
      }}
      onKeyPress={(e: any) => {
        if (e.charCode === 13) {
          validate();
          if (value !== draft)
            e.stopPropagation();
          setTimeout(() => {
            textField.current?.setSelectionRange(
              0,
              textField.current?.value?.length ?? 0
            );
          }, 10);
        }
        if (onKeyPress) onKeyPress(e);
      }}
      onBlur={(e: any) => {
        if (validateOnBlur) {
          if (
            shouldValidateOnBlur.current &&
            !validate() &&
            retainFocusOnError
          ) {
            e.preventDefault();
            setTimeout(() => {
              textField.current?.setSelectionRange(
                0,
                textField.current?.value?.length ?? 0
              );
              textField.current?.focus();
            }, 10);
          }
        }
        shouldValidateOnBlur.current = true;
        if (onBlur) {
          onBlur(e);
        }
      }}
      onChange={e => {
        setDraft(e.target.value);
      }}
      onKeyDown={(e: any) => {
        switch (e.keyCode) {
          case 27: // escape key
            shouldValidateOnBlur.current = false;
            textField.current?.blur();
            setDraft(value ?? "");
            if (onValidating) {
              const error = onValidating(value ?? "");
              setValidationError(error);
            }
            break;
          default:
            break;
        }
        if (onKeyDown) onKeyDown(e);
      }}
    />
  );
}

TextEditor.defaultProps = {
  retainFocusOnError: true,
  selectAllOnFocus: true,
  inputElementType: "TextField",
  validateOnBlur: true,
  showErrorAsHelperText: true,
  skipOnValidatedEventIfValueNotChanged: true
};
export default React.forwardRef<HTMLDivElement, TextEditorProps>((props, ref) => { return (<TextEditor {...props} innerReference={ref} />); });
