import { useMutation, useQuery } from "@apollo/client";
import Button from "@mui/material/Button/Button";
import Dialog from "@mui/material/Dialog/Dialog";
import DialogActions from "@mui/material/DialogActions/DialogActions";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import DialogTitle from "@mui/material/DialogTitle/DialogTitle";
import accounting from "accounting";
import * as React from "react";
import { checkAvailabilityForPickingMutation, saveReservedStockMoveMutation, stockPickingQuery, stockPickingsQuery, unreservePickingMutation, validateStockPickingMutation } from "./graphql";
import { StockPicking, StockPickingVariables, StockPicking_inventoryQuery_picking, StockPicking_inventoryQuery_picking_moves_edges } from "./__generated__/StockPicking";
import { ValidateStockPicking, ValidateStockPickingVariables } from "./__generated__/ValidateStockPicking";
import IconButton from "@mui/material/IconButton/IconButton";
import Close from "@mui/icons-material/Close";
import Check from "@mui/icons-material/Check";
import { CheckAvailabilityForPicking, CheckAvailabilityForPickingVariables } from "./__generated__/CheckAvailabilityForPicking";
import { UnreservePicking, UnreservePickingVariables } from "./__generated__/UnreservePicking";
import { StockPickings, StockPickingsVariables } from "./__generated__/StockPickings";
import ListItem from "@mui/material/ListItem/ListItem";
import ListItemText from "@mui/material/ListItemText/ListItemText";
import StockPickingStateIndicator from "./PickingStateIndicator";
import Divider from "@mui/material/Divider/Divider";
import { StockMoveLineInput, StockPickingOrderBy, StockPickingShippingPolicy, StockPickingState, StockPickingType, StockTrackingType } from "../../types/global-types";
import  {  CheckBoxColumnMode, GridColumn, VirtualizedTable } from "mui-apollo-virtualized-table";
import TextEditor from "../../components/TextEditor";
import NumberEditor from "../../components/NumberEditor";
import List from "@mui/material/List";
import DialogActionLoadingIndicator from "../../components/DialogActionLoadingIndicator";
import { SaveReservedStockMove, SaveReservedStockMoveVariables } from "./__generated__/SaveReservedStockMove";

export type DeliveryOrderDialogProps = {
  open: boolean;
  onClose: () => void;
  pickingIds: number[];
  saleOrderNo: string;
};

const _sx={
  content: {
    height: 600,
    display: "flex",
  },
  list: {
    borderRightWidth: 1,
    borderRightColor: "rgba(255,255,255,.3)",
    borderRightStyle: "solid"
  }
};

function DeliveryOrderDialog({ open, onClose, pickingIds, saleOrderNo }: DeliveryOrderDialogProps) {
  const [pickingId, setPickingId] = React.useState(pickingIds?.length ? pickingIds.reduce((a,b)=> Math.max(a,b)) : null);
  React.useEffect(() => {
    setPickingId(pickingIds?.length ? pickingIds.reduce((a,b)=> Math.max(a,b)) : null);
  }, [pickingIds])
  const { data: queryResult, loading } = useQuery<StockPicking, StockPickingVariables>(stockPickingQuery, {
    variables: { id: pickingId!, pagination: { pageSize: 1000 } },
    skip: !pickingId,
  });
  const { data: pickingsQueryResult } = useQuery<StockPickings, StockPickingsVariables>(stockPickingsQuery, {
    variables: {
      where: {
        id_In: pickingIds.map(id => id.toString())
      }, orderBy: [StockPickingOrderBy.id_Desc]
    },
    skip: !pickingIds?.length
  });
  const picking = queryResult?.inventoryQuery?.picking;
  const moves = picking?.moves?.edges ?? [];
  const [checkAvailibility, { loading: checkingAvailability }] = useMutation<CheckAvailabilityForPicking, CheckAvailabilityForPickingVariables>(checkAvailabilityForPickingMutation);
  const [unReserve, { loading: unreserving }] = useMutation<UnreservePicking, UnreservePickingVariables>(unreservePickingMutation);
  const [saveStockMove, { loading: savingStockMove }] = useMutation<SaveReservedStockMove, SaveReservedStockMoveVariables>(saveReservedStockMoveMutation);
  const [validateStockPicking, { loading: validating }] = useMutation<ValidateStockPicking, ValidateStockPickingVariables>(validateStockPickingMutation);
  const handleUpdateDoneQty = React.useCallback(
    (picking: StockPicking_inventoryQuery_picking, { id, product_id, product_uom, move_lines }: StockPicking_inventoryQuery_picking_moves_edges, qtyDone: number) => {      
      const variables: SaveReservedStockMoveVariables = {
        stockMove: {
          id: Number.parseInt(id),
          product_id:Number.parseInt(product_id.id),
          quantity_done:qtyDone
        },
      };
      saveStockMove({ variables });
    },
    [saveStockMove]
  );

  const disableUnReserve = React.useMemo(() => {
    return !picking || picking.picking_type_code === StockPickingType.incoming ||
      picking.immediate_transfer ||
      (picking.move_type === StockPickingShippingPolicy.one && [StockPickingState.assigned, StockPickingState.partially_available, StockPickingState.confirmed].indexOf(picking.state) === -1) ||
      (picking.move_type !== StockPickingShippingPolicy.one && [StockPickingState.assigned, StockPickingState.partially_available].indexOf(picking.state) === -1);
  }, [picking]);

  const handleUpdateSerialNo = React.useCallback(
    (picking: StockPicking_inventoryQuery_picking, { id, product_id, quantity_done }: StockPicking_inventoryQuery_picking_moves_edges, lot_name: string) => {      
      const variables: SaveReservedStockMoveVariables = {
        stockMove: {
          id: Number.parseInt(id),
          product_id:Number.parseInt(product_id.id),
          lot_name,
          quantity_done
        },
      };
      saveStockMove({ variables });
    },
    [saveStockMove]
  );
  const handleValidate = React.useCallback(async () => {
    if (!pickingId)
      return;
    try {
      await validateStockPicking({
        variables: {
          id: pickingId,
          forceBackOrder: true,
          forceImmediateTransfer: true
        }
      });
      onClose();
    } catch { }
  }, [validateStockPicking, pickingId, onClose]);
  const handleCheckAvailability = React.useCallback(() => {
    if (picking?.id)
      checkAvailibility({ variables: { id: Number.parseInt(picking.id) } });
  }, [checkAvailibility, picking?.id]);
  const handleUnreserve = React.useCallback(() => {
    if (picking?.id)
      unReserve({ variables: { id: Number.parseInt(picking.id) } });
  }, [picking?.id, unReserve]);
  const loaderCacheResetor = React.useRef<null | (() => void)>(null);
  const columns = React.useRef<
    ReadonlyArray<GridColumn<StockPicking_inventoryQuery_picking_moves_edges>>
  >([
    {
      label: "Model #",
      key: "name",
      width: 150,
      format: ({ rowData }) => {
        const [_, code] = rowData.product_id.display_name?.match(/\[(.*?)\]/) ?? [];
        return code;
      },
    },
    {
      label: "Description",
      key: "description",
      width: 200,
      flexGrow: 1,
      format: ({ rowData }) => {
        const [_, code] = rowData.product_id.display_name?.match(/\[(.*?)\]/) ?? [];
        const name = rowData.product_id.display_name?.replace(`[${code}] `, "");
        return name;
      },
    },
    {
      label: "Serial No",
      key: "serialNo",
      width: 180,
      format: ({ rowData, extraData: picking }) => {
        const [moveLine] = rowData?.move_lines?.edges ?? [];
        return <TextEditor extraData={moveLine?.lot_name??undefined} value={moveLine?.lot_id?.display_name ?? ""} disabled={rowData?.has_tracking === StockTrackingType.none} InputProps={{ readOnly: !rowData.show_operations || picking.state == StockPickingState.cancel || picking.state == StockPickingState.done }} onValidated={(text) => {
          if (text)
            handleUpdateSerialNo(picking, rowData, text);
        }} />
      }
    },
    {
      label: "Demand",
      key: "product_uom_qty",
      width: 100,
      textAlign: "right",
      format: ({ rowData }) =>
        `${accounting.formatNumber(rowData.product_uom_qty, 0)} ${rowData.product_uom.display_name
        }`,
    },
    {
      label: "Reserved",
      key: "reserved_availability",
      width: 100,
      textAlign: "right",
      format: ({ rowData }) =>
        `${accounting.formatNumber(rowData.reserved_availability??0, 0)} ${rowData.product_uom.display_name
        }`,
    },
    {
      label: "Done",
      key: "quantity_done",
      width: 100,
      textAlign: "right",
      format: ({ rowData, index, extraData: picking }) => (
        <NumberEditor
          disabled={!rowData.reserved_availability}
          InputProps={{ readOnly:  picking.state === StockPickingState.cancel || picking.state === StockPickingState.done }}
          value={rowData.quantity_done}
          onValidated={(qty) => {
            handleUpdateDoneQty(picking, rowData, qty??0);
          }}
          onValidating={(value) => {
            return (value??0) <= (rowData.reserved_availability??0) && (value??0) > -1 ? "" : "Value is invalid";
          }}
        />
      ),
    },
    {
      label: "",
      key: "command",
      width: 100,
      textAlign: "left",
      format: ({ rowData, extraData: picking }) => (<IconButton
        disabled={picking.state === StockPickingState.cancel || picking.state === StockPickingState.done || !rowData.reserved_availability}
        onClick={() => {
          if (rowData.quantity_done) {
            handleUpdateDoneQty(picking, rowData, 0);
          } else if(rowData.reserved_availability !== null) {
            handleUpdateDoneQty(picking, rowData, rowData.reserved_availability);
          }
        }}
      >
        {
          rowData.quantity_done ? <Close color="error" /> : <Check color="action" />
        }
      </IconButton>)
    }
  ]);
  return (<Dialog open={open} fullWidth maxWidth="lg">
    <DialogTitle>Delivery Order for: {saleOrderNo} </DialogTitle>
    <DialogContent sx={_sx.content}>
      <List sx={_sx.list}>
        {
          pickingsQueryResult?.inventoryQuery?.pickings?.edges?.map(({ id, name, state }) => (<div key={id}>
            <ListItem selected={id === pickingId?.toString()} onClick={() => { setPickingId(Number.parseInt(id)); }}>
              <ListItemText
                primary={name
                }
                secondary={<StockPickingStateIndicator state={state} />}
              />
            </ListItem>
            <Divider />
          </div>))
        }
      </List>
      {picking ? (
        <VirtualizedTable
          extraData={picking}
          displayRowCount={false}
          registerForLoaderCacheReset={(resetor: () => void) => {
            loaderCacheResetor.current = resetor;
          }}
          checkBoxColumnMode={CheckBoxColumnMode.none}
          loading={loading}
          listModeBreakPoint={0}
          rowGetter={(index: number) => {
            return moves[index];
          }}
          totalRowCount={moves.length}
          rowCount={moves.length}
          isRowLoaded={(index: number) => !!moves[index]}
          columns={columns.current}
          pageSize={1000}
          initialLoading={loading}
        />
      ) : null}
    </DialogContent>
    <DialogActions>
      <DialogActionLoadingIndicator
        loading={loading || savingStockMove || validating}
        text={loading ? "Loading..." : (savingStockMove ? "Saving..." : "Validating...")}
      />
      <Button disabled={
        loading || savingStockMove || validating || checkingAvailability || unreserving || disableUnReserve
      } onClick={handleUnreserve}>Unreserve</Button>

      <Button disabled={
        loading || savingStockMove || validating || checkingAvailability || unreserving || !picking?.show_check_availability
      } onClick={handleCheckAvailability}>Check Availability</Button>
      <Button
        disabled={
          loading || savingStockMove || validating || checkingAvailability || unreserving || !picking?.show_validate
        }
        onClick={handleValidate}
        color="secondary"
      >
        Procress
      </Button>
      <Button disabled={savingStockMove || validating} onClick={onClose}>
        Close
      </Button>
    </DialogActions>
  </Dialog>);
}


export default DeliveryOrderDialog;