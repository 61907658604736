import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { usePromotionOutletContext } from ".";
import { RootState } from "../../app/store";
import PromotionEditControl from "./PromotionEditControl";
import { promotionActions, promotionDefaultValue } from "./promotionsSlice";

function PromotionCreate(){
    const {promotionsVariables} = usePromotionOutletContext();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {userId,fullName} = useSelector((state:RootState)=> state.site.userSession)
    React.useEffect(()=>{
        const promotion = {...promotionDefaultValue };
        promotion.created_id = userId??"";
        promotion.created_name = fullName??"";
        dispatch(promotionActions.setPromotion(promotion));
    },[dispatch, fullName, navigate, userId]);
    return (<PromotionEditControl promotionsVariables={promotionsVariables} loading={false}/>);
}

export default PromotionCreate;